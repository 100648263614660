export const consultationActionTypes = {
    BOOK_CONSULTATION_REQUEST: 'BOOK_CONSULTATION_REQUEST',
    BOOK_CONSULTATION_SUCCESS: 'BOOK_CONSULTATION_SUCCESS',
    BOOK_CONSULTATION_FAILURE: 'BOOK_CONSULTATION_FAILURE',
    SELECT_CONSULTATION_FOR_BILLING: 'SELECT_CONSULTATION_FOR_BILLING',
    MODIFY_LINE_ITEM: 'MODIFY_LINE_ITEM',
    ADD_LINE_ITEM: 'ADD_LINE_ITEM',
    FLUSH_LINE_ITEMS_OF_TYPE: 'FLUSH_LINE_ITEMS_OF_TYPE',
    OPD_AMOUNT_UTILISED: 'OPD_AMOUNT_UTILISED',
    ADD_MULTIPLE_LINE_ITEMS: 'ADD_MULTIPLE_LINE_ITEMS',
    REMOVE_LINE_ITEM: 'REMOVE_LINE_ITEM',
    SET_FILTER_DATE_FROM: 'SET_FILTER_DATE_FROM',
    SET_FILTER_DATE_TO: 'SET_FILTER_DATE_TO',
    SEARCH_ITEM_FOR_BILLING_REQUEST: 'SEARCH_ITEM_FOR_BILLING_REQUEST',
    SEARCH_ITEM_FOR_BILLING_SUCCESS: 'SEARCH_ITEM_FOR_BILLING_SUCCESS',
    SEARCH_ITEM_FOR_BILLING_FAILURE: 'SEARCH_ITEM_FOR_BILLING_FAILURE',
    GENERATE_BILL_REQUEST: 'GENERATE_BILL_REQUEST',
    GENERATE_BILL_SUCCESS: 'GENERATE_BILL_SUCCESS',
    GENERATE_BILL_FAILURE: 'GENERATE_BILL_FAILURE',
    RESET_BILLING: 'RESET_BILLING',
    GET_BILLING_HISTORY_REQUEST: 'GET_BILLING_HISTORY_REQUEST',
    GET_BILLING_HISTORY_SUCCESS: 'GET_BILLING_HISTORY_SUCCESS',
    GET_BILLING_HISTORY_FAILURE: 'GET_BILLING_HISTORY_FAILURE',
    RESET_BILLING_HISTORY: 'RESET_BILLING_HISTORY',
    // set up the redux state for non-consultation billing
    SET_GENERAL_BILLING: 'SET_GENERAL_BILLING',
    MODIFY_BILLED_CUSTOMER: 'MODIFY_BILLED_CUSTOMER',
    MODIFY_BILLED_SUBSCRIPTION: 'MODIFY_BILLED_SUBSCRIPTION',
    GET_PRODUCT_CATEGORIES_REQUEST: 'GET_PRODUCT_CATEGORIES_REQUEST',
    GET_PRODUCT_CATEGORIES_SUCCESS: 'GET_PRODUCT_CATEGORIES_SUCCESS',
    GET_PRODUCT_CATEGORIES_FAILURE: 'GET_PRODUCT_CATEGORIES_FAILURE',
    UNLINK_EVITAL_SALE_ORDER: 'UNLINK_EVITAL_SALE_ORDER',
    LINK_EVITAL_SALE_ORDER: 'LINK_EVITAL_SALE_ORDER',
};

/**
 * The types of items that a user may add to bills.
 * Used to calculate discounts in billing.
 */
export const itemTypes = {
    // lab tests
    LAB_TEST: 'lab_test',
    LAB_TEST_IMAGING: 'lab_test_imaging',
    // medcines
    MEDICINE: 'medicine',
    MEDICINE_COSMETICS: 'medicine_cosmetics',
    MEDICINE_NUTRITION: 'medicine_nutrition',
    EVITAL_MEDICINE: 'evital_medicine',
    // other product types
    SUBSCRIPTION: 'subscription',
    CONSULTATION: 'consultation',
    WELLNESS: 'wellness',
    PROCEDURE: 'procedure',
    CONSUMABLES: 'consumables',
    MEDICAL_EQUIPMENT: 'medical_equipment',
    // placeholder for items that are not in the database
    OTHER: 'other',
    // utility item type for frontend
    GENERIC: 'generic',
};

export const consultationTypes = {
    FREE: 'free',
    UNPAID: 'unpaid',
    PAID: 'paid',
};

/**
 * ### An object describing the different record types that can be uploaded.
 * Each record type has a `value` and `label` sub-properties,
 * where:
 * - `value` is what the backend expects for this file type
 * - `label` is the user-friendly text to be used across the frontend
 */
export const healthVaultRecordTypes = [
    {
        value: 'diagnostic_record',
        label: 'Diagnostic Report'
    },
    {
        value: 'discharge_summary',
        label: 'Discharge Summary',
    },
    {
        value: 'health_document',
        label: 'Health Document',
    },
    {
        value: 'immunization',
        label: 'Immunization Record',
    },
    {
        value: 'outpatient_consult',
        label: 'Outpatient Consult Record',
    },
    {
        value: 'prescription',
        label: 'Prescription Record',
    },
    {
        value: 'wellness',
        label: 'Wellness Record',
    },
    {
        value: 'media',
        label: 'Media',
    },
    {
        value: 'other',
        label: 'Other'
    }
];

export const healthVaultRecordTypesMap = new Map();
healthVaultRecordTypes.forEach(record => healthVaultRecordTypesMap.set(record.value, record.label));

export const orderActionTypes = {
	CREATE_ORDER_FOR_FAMILY_ID_REQUEST: 'CREATE_ORDER_FOR_FAMILY_ID_REQUEST',
	CREATE_ORDER_FOR_FAMILY_ID_SUCCESS: 'CREATE_ORDER_FOR_FAMILY_ID_SUCCESS',
	CREATE_ORDER_FOR_FAMILY_ID_FAILURE: 'CREATE_ORDER_FOR_FAMILY_ID_FAILURE',
    ADD_DEFAULT_EVITAL_LINE_ITEM_TO_ORDER_REQUEST: 'ADD_DEFAULT_EVITAL_LINE_ITEM_TO_ORDER_REQUEST',
    ADD_DEFAULT_EVITAL_LINE_ITEM_TO_ORDER_SUCCESS: 'ADD_DEFAULT_EVITAL_LINE_ITEM_TO_ORDER_SUCCESS',
    ADD_DEFAULT_EVITAL_LINE_ITEM_TO_ORDER_FAILURE: 'ADD_DEFAULT_EVITAL_LINE_ITEM_TO_ORDER_FAILURE',
};