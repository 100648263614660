import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { CircularProgress } from "@material-ui/core";
import { getAvailableServicesInProduct } from '../../common/utils';
import ServiceCard from './ServiceCard';


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
});

class ProductDetail extends React.Component {

    getCircularProgress = () => {
        return <CircularProgress style={{
            color: 'orange',
            left: '50%',
            top: '55%',
            position: 'absolute',
        }} disableShrink />;
    }

    render() {
        const { classes, product } = this.props;
        const availableServicesList = getAvailableServicesInProduct(product);
        
        return (
            <div>
                <div>
                    <div className={classes.root}>
                        <Grid container spacing={0}>
                            {availableServicesList.map((service) => {
                                return (
                                    <Grid item xs key={service.type}>
                                        <div>
                                            <ServiceCard service={service} />
                                        </div>
                                    </Grid>)
                            })}
                        </Grid>
                    </div>
                </div>

            </div>
        )
    }
}

ProductDetail.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error, familyDetails: state.familyDetails, newPlan: state.newPlan };
};

export default connect(mapStateToProps)(withStyles(styles)(ProductDetail));