import React from 'react';
import { connect } from 'react-redux';
import { withStyles, TextField, Button } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from '@material-ui/pickers';
import useStyles from './style';
import moment from 'moment';
import { consultationStatus } from '../../../common/constants';
import { consultationActions } from '../../../actions/consultationAction';
import {
    getConsultationsByClinicId,
    setPresentableConsultationQueue,
    setPresentableConsultations,
    resetConsultationQueue,
} from '../../../actions';

function Filter(props) {
    const {
        classes, consultationDetails, setFilterDateFrom, setFilterDateTo,
        auth, getConsultationsByClinicId, resetConsultationQueue,
    } = props;
    return (
        <div className={classes.container}>
            <div className={classes.row}>
                <span className={classes.header}>
                    Filter By
                </span>
            </div>
            <div className={`${classes.row} ${classes.filled}`}>
                <div className={classes.filterControls}>
                    <div className={classes.control}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                maxDate={consultationDetails?.completed?.to ?? moment().endOf('day').valueOf()}
                                value={consultationDetails?.completed?.from ?? moment().startOf('day').valueOf()}
                                onChange={(date) => {
                                    setFilterDateFrom(moment(date).startOf('day').valueOf());
                                }}
                                renderInput={(params) => <TextField style={{fontFamily: 'Rubik'}} {...params} />}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <span>to</span>
                    <div className={classes.control}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                minDate={consultationDetails?.completed?.from ?? moment().startOf('day').valueOf()}
                                value={consultationDetails?.completed?.to ?? moment().endOf('day').valueOf()}
                                renderInput={(params) => <TextField style={{fontFamily: 'Rubik'}} {...params} />}
                                onChange={(date) => {
                                    setFilterDateTo(moment(date).endOf('day').valueOf())
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
                <div className={classes.filterControls}>
                    <div className={classes.control}>
                        <Button
                            variant="filled"
                            className={classes.searchBtn}
                            onClick={() => {
                                resetConsultationQueue();
                                getConsultationsByClinicId(
                                    auth.currentClinicLocation.id,
                                    consultationStatus.COMPLETED,
                                    1,
                                    'desc',
                                    consultationDetails.completed.from,
                                    consultationDetails.completed.to, 
                                    "completed_at",
                                    "completedStartsAt", 
                                    "completedEndsAt"
                                )
                            }}
                        >
                            Search
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = {
    setFilterDateFrom: consultationActions.setFilterDateFrom,
    setFilterDateTo: consultationActions.setFilterDateTo,
    getConsultationsByClinicId,
    setPresentableConsultationQueue,
    setPresentableConsultations,
    resetConsultationQueue,
}

const mapStateToProps = ({ consultationDetails, auth }) => {
    return { consultationDetails, auth };
};


const stylesWrapper = withStyles(useStyles, { withTheme: true });
const reduxWrapper = connect(mapStateToProps, mapDispatchToProps);

/**
 * 
 * @param {*} props 
 * @returns React Component to set filters for dates;
 */
export default stylesWrapper((reduxWrapper(Filter)));