export const ticketType = {
    PENDING: 'PENDING',
    RESOLVED: 'RESOLVED',
};

export const ticketObjective = {
    PATIENT_FOLLOW_UP: 'patient_follow_up',
    ONBOARD_CUSTOMER: 'onboard_customer',
    PATIENT_FEEDBACK: 'patient_feedback'
}

export const preferredLanguageList = [
    'hindi', 'english',
    'kannada',
    'tamil',
    'telugu',
    'malayalam',
    'bangla',
    'gujarati',
    'marathi',
    'punjabi',
    'odia',
];

export const sortType = {
    ASCENDING: 'asc',
    DESCENDING: 'desc',
};

export const dateType = {
    TODAY: 'today',
    YESTERDAY: 'yesterday',
    LAST_WEEK: 'last_week',
    LAST_MONTH: 'last_month',
    CUSTOM: 'custom',
}


export const ticketingActionTypes = {
    GET_TICKETS_COUNT_STATUS_REQUEST: 'GET_TICKETS_COUNT_STATUS_REQUEST',
    GET_TICKETS_COUNT_STATUS_SUCCESS: 'GET_TICKETS_COUNT_STATUS_SUCCESS',
    GET_TICKETS_COUNT_STATUS_FAILURE: 'GET_TICKETS_COUNT_STATUS_FAILURE',

    GET_TICKETS_REQUEST: 'GET_TICKETS_REQUEST',
    GET_TICKETS_SUCCESS: 'GET_TICKETS_SUCCESS',
    GET_TICKETS_FAILURE: 'GET_TICKETS_FAILURE',

    UPDATE_LOCAL_TICKET_FILTER_OPTIONS_REQUEST: 'UPDATE_LOCAL_TICKET_FILTER_OPTIONS_REQUEST',
    UPDATE_LOCAL_TICKET_FILTER_OPTIONS_SUCCESS: 'UPDATE_LOCAL_TICKET_FILTER_OPTIONS_SUCCESS',
    UPDATE_LOCAL_TICKET_FILTER_OPTIONS_FAILURE: 'UPDATE_LOCAL_TICKET_FILTER_OPTIONS_FAILURE',

    SELECT_CURRENT_TICKET: 'SELECT_CURRENT_TICKET',

    ADD_COMMENTS_TO_TICKET_REQUEST: 'ADD_COMMENTS_TO_TICKET_REQUEST',
    ADD_COMMENTS_TO_TICKET_SUCCESS: 'ADD_COMMENTS_TO_TICKET_SUCCESS',
    ADD_COMMENTS_TO_TICKET_FAILURE: 'ADD_COMMENTS_TO_TICKET_FAILURE',

    RESET_ADD_COMMENT: 'RESET_ADD_COMMENT',
    REST_BOOK_CONSULTATION: 'REST_BOOK_CONSULTATION',
    RESET_MARK_RESOLVE: 'RESET_MARK_RESOLVE',

    EXTEND_TICKET_DEADLINE_REQUEST: 'EXTEND_TICKET_DEADLINE_REQUEST',
    EXTEND_TICKET_DEADLINE_SUCCESS: 'EXTEND_TICKET_DEADLINE_SUCCESS',
    EXTEND_TICKET_DEADLINE_FAILURE: 'EXTEND_TICKET_DEADLINE_FAILURE',

    MARK_RESOLVE_TICKET_REQUEST: 'MARK_RESOLVE_TICKET_REQUEST',
    MARK_RESOLVE_TICKET_SUCCESS: 'MARK_RESOLVE_TICKET_SUCCESS',
    MARK_RESOLVE_TICKET_FAILURE: 'MARK_RESOLVE_TICKET_FAILURE',

    SET_FEEDBACK_RATING: 'SET_FEEDBACK_RATING',
    RESET_FEEDBACK_RATING: 'RESET_FEEDBACK_RATING',

    SET_FEEDBACK_COMMENT: 'SET_FEEDBACK_COMMENT',
    RESET_FEEDBACK_COMMENT: 'RESET_FEEDBACK_COMMENT',

    RESET_FEEDBACK_FORM: 'RESET_FEEDBACK_FORM',

    POST_FEEDBACK_REQUEST: 'POST_FEEDBACK_REQUEST',
    POST_FEEDBACK_SUCCESS: 'POST_FEEDBACK_SUCCESS',
    POST_FEEDBACK_FAILURE: 'POST_FEEDBACK_FAILURE',
    RESET_POST_FEEDBACK_API_STATUS: 'RESET_POST_FEEDBACK_API_STATUS',

    GET_CUSTOMER_PREFERENCES_REQUEST: 'GET_CUSTOMER_PREFERENCES_REQUEST',
    GET_CUSTOMER_PREFERENCES_SUCCESS: 'GET_CUSTOMER_PREFERENCES_SUCCESS',
    GET_CUSTOMER_PREFERENCES_FAILURE: 'GET_CUSTOMER_PREFERENCES_FAILURE',
    RESET_GET_CUSTOMER_PREFRENCES_API_STATUS: 'RESET_GET_CUSTOMER_PREFRENCES_API_STATUS',
    SET_PREFERENCES_NOT_FOUND: 'SET_PREFERENCES_NOT_FOUND',

    SEND_PRESCRIPTION_LINK_SMS_REQUEST: 'SEND_PRESCRIPTION_LINK_SMS_REQUEST',
    SEND_PRESCRIPTION_LINK_SMS_SUCCESS: 'SEND_PRESCRIPTION_LINK_SMS_SUCCESS',
    SEND_PRESCRIPTION_LINK_SMS_FAILURE: 'SEND_PRESCRIPTION_LINK_SMS_FAILURE',
    RESET_SEND_PRESCRIPTION_LINK_SMS_API_STATUS: 'RESET_PRESCRIPTION_LINK_SMS_API_STATUS',
    
    SEND_PRESCRIPTION_LINK_WHATSAPP_REQUEST: 'SEND_PRESCRIPTION_LINK_WHATSAPP_REQUEST',
    SEND_PRESCRIPTION_LINK_WHATSAPP_SUCCESS: 'SEND_PRESCRIPTION_LINK_WHATSAPP_SUCCESS',
    SEND_PRESCRIPTION_LINK_WHATSAPP_FAILURE: 'SEND_PRESCRIPTION_LINK_WHATSAPP_FAILURE',
    RESET_SEND_PRESCRIPTION_LINK_WHATSAPP_API_STATUS: 'RESET_PRESCRIPTION_LINK_SMS_API_STATUS',

    SEND_PLAYSTORE_LINK_WHATSAPP_REQUEST: 'SEND_PLAYSTORE_LINK_WHATSAPP_REQUEST',
    SEND_PLAYSTORE_LINK_WHATSAPP_SUCCESS: 'SEND_PLAYSTORE_LINK_WHATSAPP_SUCCESS',
    SEND_PLAYSTORE_LINK_WHATSAPP_FAILURE: 'SEND_PLAYSTORE_LINK_WHATSAPP_FAILURE',
    RESET_SEND_PLAYSTORE_LINK_WHATSAPP_API_STATUS: 'RESET_PLAYSTORE_LINK_SMS_API_STATUS',

    SEND_PLAYSTORE_LINK_SMS_REQUEST: 'SEND_PLAYSTORE_LINK_SMS_REQUEST',
    SEND_PLAYSTORE_LINK_SMS_SUCCESS: 'SEND_PLAYSTORE_LINK_SMS_SUCCESS',
    SEND_PLAYSTORE_LINK_SMS_FAILURE: 'SEND_PLAYSTORE_LINK_SMS_FAILURE',
    RESET_SEND_PLAYSTORE_LINK_SMS_API_STATUS: 'RESET_PLAYSTORE_LINK_SMS_API_STATUS',

};

export const followUpStep = {
    CALL_RECIEVAL: 'CALL_RECIEVAL',
    CALL_RESPONSE: 'CALL_RESPONSE',
    BOOK_CONSULTATION: 'BOOK_CONSULTATION',
    ADD_COMMENT: 'ADD_COMMENT',
    PLACE_CONSULTATION: 'PLACE_CONSULTATION',
}

export const affirmativeReason = {
    FEELING_BETTER_NOW: "Feeling better now",
    MEDICINE_NOT_USED: "Medicine not used",
    LAB_TEST_NOT_DONE: "Lab test not done",
    LAB_TEST_REPORT_AWAITED: "Lab test reported awaited",
    OTHER_HEALTH_RELATED: "Other health related",
    CALL_BACK_ON_SPECIFIC_DATE: "Call Back on specific date",
}

export const nonAffirmativeReason = {
    RNR: "RNR",
    BUSY: "Busy",
    CALLBACK: "Callback",
    NOT_CONNECTED: "Not Connected",
    NOT_REACHABLE: "Not reachable",
    SWITCH_OFF: "Switch Off",
    DISCONNECTED: "Disconnected"
}

export const apiStatus = {
    SUCCESS: 'success',
    FAILURE: 'failure',
}


