const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    card: {
        padding: theme.spacing(2),
        height: '34vh',
        width: '50vw',
        overflowY: 'scroll',
        color: theme.palette.text.secondary,
    },
    element: {
        padding: theme.spacing(0.5),
    },
    textField: {
        color: 'orange',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: '10vh',
    },
    avatarButton: {
        height: '12vh',
        width: '6vw',
    },
    avatar: {
        height: '46px',
        width: '46px',
    },
    relationButton: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
});

export default styles;