import { color } from '../../../constants/colors';
const styles = () => ({
    header: {
        display: 'flex',
        padding: '30px 24px 10px',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${color.ROYAL_ORANGE}`
    },
    bold: {
        fontWeight: 500
    },
    closeIcon: {
        cursor: 'pointer'
    },
    businessContainer: {
        padding: '24px',
        display: 'flex',
        flexDirection: 'column'
    },
    businessFormContainer: {
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${color.ROYAL_ORANGE}`,
        borderRadius: '8px',
        padding: '8px 16px 24px',
        marginBottom: '16px'
    },
    businesses: {
        width: '100%',
    },
    businessBox: {
        display: 'flex',
        padding: '12px 16px',
        backgroundColor: color.PEACH,
        borderRadius: '8px',
        justifyContent: 'space-between',
        marginBottom: '16px'
    },
    businessDetails: {
        flex: '0.75',
    },
    delete: {
        flex: '0.08',
        alignSelf: 'center',
        height: '20%',
        cursor: 'pointer'
    },
    businessName: {
        fontWeight: '500',
        color: color.BLACK,
    },
    greyColor: {
        color: color.GREY
    },
    royalOrangeColor: {
        color: color.ROYAL_ORANGE
    },
    addBusinessButton: {
        marginTop: '24px',
        backgroundColor: color.ROYAL_ORANGE,
        color: color.WHITE,
        '&:hover': {
            backgroundColor: color.ROYAL_ORANGE,
        },
        '&:disabled': {
            opacity: '70%',
            color: color.WHITE,
        }
    },
    addButton: {
        display: 'flex',
        alignSelf: 'flex-end',
        width: 'fit-content',
        backgroundColor: color.ROYAL_ORANGE,
        color: color.WHITE,
        padding: '8px 32px',
        '&:hover': {
            backgroundColor: color.ROYAL_ORANGE,
        }
    },
    mL10: {
        marginLeft: '5px'
    },
    left: {
        alignSelf: 'flex-end'
    },
    deleteConfirmationModal: {
        position: 'absolute',
        height: '100%',
        width: '100%',
    },
    overlay: {
        background: color.BLACK,
        opacity: '0.5',
        width: '100%',
        height: '100%'
    },
    deleteModalContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        width: '80%',
        padding: '20px 12px',
        borderRadius: '8px',
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
        backgroundColor: color.ANTI_FLASH_WHITE
    },
    modalActionButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '16px'
    },
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    warningBox: {
        display: 'flex',
        alignItems: 'center'
    },
    modalText: {
        display: 'flex',
        flexDirection:'column',
        padding: '0px 75px 0 0'
    },
    visibilityVisible: {
        visibility: 'visible'
    },
    visibilityhidden: {
        visibility: 'hidden'
    }
})

export default styles;