import { VERIFY_OTP, LOG_OUT, INITIATE_OTP, GET_CUSTOMER_DETAILS, FETCH_BENEFIT_CARDS_BY_CUSTOMER_ID, GET_CREDIT_DETAILS_BY_FAMILY_ID, GET_NACH_DETAILS_BY_FAMILY_ID, RESET_ERRORS, FETCH_CONSULTATIONS_BY_CUSTOMER_ID, FIND_ADDRESS_BY_ZIPCODE, FIND_CUSTOMER_BY_PHONE_NUMBER, CREATE_NEW_CUSTOMER, CREATE_FREE_SUBSCRIPTION, ADD_NEW_MEMBER_BY_FAMILY_ID, UPDATE_PRIMARY_NUMBER, SET_ERROR_IN_UPDATE_COHORT, SET_ERROR_IN_GETTING_BILLING_PREF,
    SET_ERROR_PLAN_CANCELATION, SET_ERROR_IN_UPDATE_TNC, UPDATE_ALTERNATE_NUMBER } from '../actions/types';
import { errorActionTypes } from '../constants/errorConstant';

const INITIAL_STATE = {
};

const errorReducer = (state = { INITIAL_STATE }, action) => {
    switch (action.type) {
        case INITIATE_OTP:
            return { ...state, loginErrorCode: action.payload.initateOtpErrorCode, isNotAuthorizedRole: null, verifyOtpErrorCode: null };
        case VERIFY_OTP:
            return { ...state, loginErrorCode: null, verifyOtpErrorCode: action.payload.verifyOtpErrorCode, isNotAuthorizedRole: action.payload.isNotAuthorizedRole };
        case LOG_OUT:
            return { errorCode: null, verifyOtpErrorCode: null, isNotAuthorizedRole: null, loginErrorCode: null, creditErrorCode: null, nachErrorCode: null, benefitCardErrorCode: null, currentCustomerErrorCode: null, subscriptionDetailsErrorCode: null, allMembersErrorCode: null, creatingPrimaryCustomer: null, findCustomerByPhoneNumberError: null };
        case GET_CUSTOMER_DETAILS:
            return { ...state, currentCustomerErrorCode: action.payload.currentCustomerErrorCode, subscriptionDetailsErrorCode: action.payload.subscriptionDetailsErrorCode, allMembersErrorCode: action.payload.allMembersErrorCode }
        case FETCH_BENEFIT_CARDS_BY_CUSTOMER_ID:
            return { ...state, benefitCardErrorCode: action.payload.benefitCardErrorDetails };
        case GET_NACH_DETAILS_BY_FAMILY_ID:
            return { ...state, nachErrorCode: action.payload.nachErrorDetails };
        case GET_CREDIT_DETAILS_BY_FAMILY_ID:
            return { ...state, creditErrorCode: action.payload.creditErrorDetails };
        case FETCH_CONSULTATIONS_BY_CUSTOMER_ID:
            return { ...state, consultationsErrorCode: action.payload.getConsultationsErrorDetails };
        case FIND_ADDRESS_BY_ZIPCODE:
            return { ...state, getAddressByZipcodeErrorCode: action.payload.getAddressByZipcodeError };
        case FIND_CUSTOMER_BY_PHONE_NUMBER:
            return { ...state, findCustomerByPhoneNumberError: action.payload.currentCustomerErrorCode };
        case CREATE_NEW_CUSTOMER:
            return { ...state, creatingPrimaryCustomer: action.payload }
        case CREATE_FREE_SUBSCRIPTION:
            return { ...state, creatingFreeSubscriptionError: action.payload }
        case RESET_ERRORS:
            return { ...state,
                creditErrorCode: null,
                verifyOtpErrorCode: null,
                loginErrorCode: null,
                nachErrorCode: null,
                benefitCardErrorCode: null,
                currentCustomerErrorCode: null,
                subscriptionDetailsErrorCode: null,
                allMembersErrorCode: null,
                creatingPrimaryCustomer: null,
                findCustomerByPhoneNumberError: null,
                invoicesOfSubscription: null,
                bookConsultationDrawerStatus: false,
                visitBookDrawerStatus: false,
                error: null,
            };
        case ADD_NEW_MEMBER_BY_FAMILY_ID:
            return { ...state, addingNewMemberError: action.payload }
        case UPDATE_PRIMARY_NUMBER:
            return { ...state, }
        case UPDATE_ALTERNATE_NUMBER:
            return { ...state, }
        case SET_ERROR_IN_UPDATE_TNC:
            return { ...state, updateTNCError: action.payload, }
        case SET_ERROR_IN_UPDATE_COHORT:
            return { ...state, updateCohortError: action.payload, }
        case SET_ERROR_IN_GETTING_BILLING_PREF:
            return { ...state, getBillPrefError: action.payload, }
        case SET_ERROR_PLAN_CANCELATION:
            return { ...state, updateSubscriptionTnC: action.payload, }
        case errorActionTypes.SET_ERROR: {
            return {
                ...state, error: action.error,
            }
        }
            default:
            return state;
    }
};

export default errorReducer;
