import React from "react";
import _ from "lodash";
import { round, isEmpty, has } from "lodash";
import { connect } from "react-redux";
import { resetErrors, setError, getOpdBalanceEvent } from "../../../actions";
import { color } from "../../../constants/colors";
import AddProducts from "../AddProducts/AddProducts";
import ApplyCoupon from "../applyCoupon";
import {
  buildCustomError,
  getDiscountOnLineItem,
  getDiscountOnUpdatedLineItem,
  getOrderTotal,
  getTotalOpd,
} from "../../../common/utils";
import ProductUtils from "../../../common/ProductUtil";
import {
  Checkbox,
  FormControlLabel,
  FormControl,
  Select,
  withStyles,
  MenuItem,
  Snackbar,
  Button,
  Typography,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import { ReactComponent as PaymentsIcon } from "../../../assets/icons2/common/payments.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons2/common/delete.svg";
import ConfirmationDrawer from "./ConfirmationDrawer";
import { discountType, offlinePaymentSources } from "../../../common/constants";
import { settleBill } from "../../../actions/paymentAction";
import {
  resetBilling,
  searchBillingItems,
  consultationActions,
} from "../../../actions/consultationAction";
import useStyles from "./style";
import { resetAddressesEvent } from "../../../actions/addressAction";
import { itemTypes } from "../../../constants";
import { policyStatus, subscriptionStatus } from "../../../common/constants";
import { getOrderLineItems, modifyLineItemInOrder } from "../../../clients/omsService";
import { v4 } from "uuid";
import { careWalletUrl, careWalletApiKey } from "../../../config";

const utils = new ProductUtils();

// setting up the methods available for offline payments;
const availablePaymentMethods = [
  {
    type: offlinePaymentSources.CASH,
    label: "Cash",
  },
  {
    type: offlinePaymentSources.POS,
    label: "POS",
  },
  {
    type: offlinePaymentSources.QR_CODE,
    label: "QR Code",
  },
  {
    type: offlinePaymentSources.WALLET,
    label: "Care Wallet",
  },
  {
    //not an actual payment method; used only in code as placeholder
    type: "generic",
    label: "",
  },
];

const genericPaymentMethod =
  availablePaymentMethods[availablePaymentMethods.length - 1];

function Alert(props) {
  return <MuiAlert severity="info" elevation={6} variant="filled" {...props} />;
}
class Billing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSnackBar: false,
      snackbarMessage: "Something went wrong!",
      drawerOpen: false,
      couponCode: {
        label: "Coupon",
        applied: false,
      },
      markConsent: false,
      consentClicked: false,
      credit: {
        label: "Clinikk Cash",
        applied: false,
        // value of credit is essentially the credit applicable to this order;
      },
      // will get populated as payment methods are added to the break-up;
      instruments: [],
      walletTransactionId: "",
    };

    this.eVitalRxTab = React.createRef(null);

    this.getLineItemQuantity = (item) => {
      const { available_quantity = 0, prescribed_quantity } = item;
      if (available_quantity > prescribed_quantity) {
        return prescribed_quantity;
      }
      return available_quantity;
    };
  }

  /**
   *
   * @param {Number} orderId The order ID for which line items must be synced
   * @param {Boolean} syncOnlyEvitalRxItems A flag to indicate whether _only_ eVitalRx items must be synced.
   *
   * This function flushes currently added line items and re-populates them with the
   * data returned by the backend. One can optionally sync _just_ eVitalRx items, or _all_ items.
   * Syncing non-eVitalRx items _only_ is not possible currently.
   */
  syncLineItems = async (orderId, syncOnlyEvitalRxItems = false) => {
    const {
      auth,
      addLineItem,
      flushLineItemsOfType,
      consultationDetails,
      unlinkEvitalSaleOrder,
    } = this.props;
    const accessToken = auth.authDetails.data.access_token;
    const localOrder = consultationDetails.billing.items;
    getOrderLineItems(accessToken, orderId, syncOnlyEvitalRxItems)
      .then(async (res) => {
        const { items: incomingItemsToSync } = res.data;
        if (localOrder.length !== 0 && incomingItemsToSync.length === 0) {
          unlinkEvitalSaleOrder();
        }
        if (localOrder.length === 0) {
          // first load, add all line items;
          incomingItemsToSync.forEach((item) => {
            const lineItem = {
              id: item.id,
              external_id: item.external_id,
              type: item.type,
              quantity: Number(item.quantity),
              rate: Number(item.price_per_quantity),
              discount: {
                value: item.total_discount,
                type: discountType.PERCENTAGE,
              },
              error: {
                name: null,
                rate: null,
              },
              available_quantity: null,
              prescribed_quantity: Number(item.quantity),
              formulation: "Unit",
              name: item.metadata.display_name,
              batch_number: item.metadata.batch_id,
              sync: true,
              opd_utilised: item?.metadata?.opd_utilised,
              opd_amount: item?.metadata?.opd_amount,
              uiTrackingId: v4(),
            };
            lineItem.total = lineItem.rate * lineItem.quantity;
            addLineItem(lineItem.type, lineItem);
          });
        } else {
          console.log('hit 171', syncOnlyEvitalRxItems);
          flushLineItemsOfType(itemTypes.EVITAL_MEDICINE);
          if (syncOnlyEvitalRxItems) {
            incomingItemsToSync.forEach((item) => {
              const lineItem = {
                id: item.id,
                name: item.display_name,
                quantity: Number(item.quantity),
                available_quantity: null,
                prescribed_quantity: null,
                discount: {
                  value: Number(item.total_discount),
                  type: discountType.PERCENTAGE,
                },
                rate: Number(item.price_per_quantity),
                total: Number(item.quantity) * Number(item.price_per_quantity),
                external_id: item.external_id ?? "",
                opd_utilised: item?.metadata?.opd_utilised,
                opd_amount: item?.metadata?.opd_amount,
                batch_number: item.metadata.batch_id,
                error: {
                  name: null,
                  rate: null,
                },
                type: itemTypes.EVITAL_MEDICINE,
                uiTrackingId: v4(),
              };
              lineItem.discount.computedValue = getDiscountOnLineItem(lineItem);
              lineItem.amount =
                lineItem.total - lineItem.discount.computedValue;
              addLineItem(lineItem.type, lineItem);
              if (this.evitalItemUpdated(localOrder, lineItem) && item?.metadata?.opd_utilised) {
                console.log('modify Item for opd line-205')
                this.updateLineItem(lineItem, true);
              }
            });
          } else {
            flushLineItemsOfType(itemTypes.GENERIC);
            flushLineItemsOfType(itemTypes.OTHER);
            flushLineItemsOfType(itemTypes.CONSULTATION);
            flushLineItemsOfType(itemTypes.LAB_TEST);
            flushLineItemsOfType(itemTypes.MEDICINE);
            flushLineItemsOfType(itemTypes.PROCEDURE);
            incomingItemsToSync.forEach((item) => {
              const lineItem = {
                id: item.id,
                name: item.display_name ?? "Placeholder",
                quantity: Number(item.quantity),
                available_quantity: null,
                prescribed_quantity: null,
                discount: {
                  value: Number(item.total_discount),
                  type: discountType.PERCENTAGE,
                },
                rate: Number(item.price_per_quantity),
                total: Number(item.quantity) * Number(item.price_per_quantity),
                external_id: item.external_id ?? "",
                batch_number: item.metadata.batch_id,
                opd_amount: item?.metadata?.opd_amount,
                opd_utilised: item?.metadata?.opd_utilised,
                error: {
                  name: null,
                  rate: null,
                },
                type: item.type,
                sync: true,
                uiTrackingId: v4(),
              };
              lineItem.discount.computedValue = getDiscountOnLineItem(lineItem);
              lineItem.amount =
                lineItem.total - lineItem.discount.computedValue;
              addLineItem(lineItem.type, lineItem);
            });
          }
        }
      })
      .catch((err) => {
        const error = buildCustomError(err);
        this.setState({
          showSnackBar: true,
          snackbarMessage: error.message,
        });
      });
  };

  async componentDidMount() {
    const {
      consultationDetails,
      setGeneralBilling,
      familyDetails,
      getOpdAmounts
    } = this.props;
    const headSubscriber = familyDetails?.currentCustomer;
    const orderId = consultationDetails.billing?.order?.id;

    if (consultationDetails.billing === null) {
      setGeneralBilling({
        id: headSubscriber.id,
      });
    }
    if (orderId) {
      this.syncLineItems(orderId, false);
    }
    const activeSubscriptions = familyDetails?.allSubscriptions?.subscriptions?.filter((subscription) => subscription?.status === 'active');
    // if (activeSubscriptions[0]) {
    //   console.log(activeSubscriptions[0].id, '---activeSubscriptions[0].id');
    //   getOpdAmounts(activeSubscriptions[0].id);
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    const { error, consultationDetails, modifyLineItem } = this.props;
    const { instruments, credit, couponCode, markConsent } = this.state;
    const prevMemberSelected = prevProps.consultationDetails?.billing?.customer;
    const currentMemberSelected = consultationDetails.billing?.customer;
    const itemList = consultationDetails?.billing?.items ?? [];

    if (itemList.length === 0 && this.state.markConsent) {
      this.setState({
          markConsent: false,
      });
    }

    if (
      currentMemberSelected?.id !== prevMemberSelected?.id &&
      itemList.length !== 0
    ) {
      // customer being billed was changed - the discounts must be recomputed;
      itemList.forEach((item) => {
        const recomputedDiscount = this.computeDiscountOnLineItem(item);
        modifyLineItem(item.id, {
          discount: recomputedDiscount,
        });
      });
    }

    const prevError = prevProps.error;
    if (error !== prevError && error.error && error.error !== prevError.error) {
      this.setState({
        showSnackBar: true,
        snackbarMessage:
          typeof error.error === "object"
            ? `${error.error.statusCode}: ${error.error.message}`
            : error.error,
      });
    }

    // recalculate payment breakup amounts when any one changes
    // or coupons/credit are applied
    if (prevProps.consultationDetails.billing !== null) {
      const prevBillTotal = getOrderTotal(
        prevProps.consultationDetails?.billing?.items
      );
      const newBillTotal = getOrderTotal(consultationDetails?.billing?.items);
      if (newBillTotal !== prevBillTotal) {
        const orderTotal = getOrderTotal(consultationDetails.billing.items);
        let discount = 0;
        if (credit.applied) {
          discount = this.getAvailableCreditAmount("credit");
        } else if (couponCode.applied) {
          discount = this.getAvailableCreditAmount("couponCode");
        }
        const balancePaymentAmount = round(
          Number(
            orderTotal - (discount + this.getInstrumentTotal(instruments))
          ),
          2
        );
        if (instruments.length === 0 && balancePaymentAmount !== 0) {
          // just initialise
          const newInstrumentList = [...instruments];
          newInstrumentList.push({
            type: genericPaymentMethod.type,
            label: genericPaymentMethod.label,
            value: balancePaymentAmount,
          });
          this.setState({
            instruments: newInstrumentList,
          });
        } else {
          // reset payment breakup to full amount on one instrument;
          const newInstrumentList = [];
          if (
            consultationDetails.billing?.items.length !== 0 &&
            round(Number(orderTotal - discount), 2) > 0
          ) {
            newInstrumentList.push({
              type: genericPaymentMethod.type,
              label: genericPaymentMethod.label,
              value: round(Number(orderTotal - discount), 2),
            });
          }
          this.setState({
            instruments: newInstrumentList,
          });
        }
      }
    }

    // sync all items if a new order was created from the frontend;
    if (
      prevProps.consultationDetails.billing?.order?.id !==
        consultationDetails.billing?.order?.id &&
      consultationDetails.billing.order !== null &&
      prevProps.consultationDetails.billing.order === null
    ) {
      this.syncLineItems(consultationDetails.billing.order.id, false);
    }

    // sync only evital items if an evital sale order is linked to our existing order
    if (
      !prevProps.consultationDetails.billing?.evital_redirect_fetch_call && prevProps.consultationDetails.billing?.evital_redirect_url !==
        consultationDetails.billing?.evital_redirect_url &&
      consultationDetails.billing.evital_redirect_url !== null &&
      prevProps.consultationDetails.billing.evital_redirect_url === null
    ) {
      this.syncLineItems(consultationDetails.billing.order.id, true);
    }
  }

  componentWillUnmount() {
    const { resetBilling, resetAddress } = this.props;
    const hash = window.location.hash.split("#");
    if (hash.includes("reset")) {
      resetBilling();
      resetAddress();
    }
  }

  /**
   * Handler for the Clinikk Cash checkbox.
   * It resets the payment breakup, since the
   * payable amount changed.
   */
  toggleCreditCheckbox = () => {
    const { credit, couponCode } = this.state;
    const { consultationDetails } = this.props;
    this.setState(
      {
        credit: {
          applied: !credit.applied,
          label: credit.label,
        },
        instruments: [],
      },
      () => {
        const orderTotal = getOrderTotal(consultationDetails.billing.items);
        const availableCredit = this.getAvailableCreditAmount("credit");
        if (orderTotal <= availableCredit) {
          this.setState({
            instruments: [],
          });
        }
      }
    );
    if (couponCode.applied) {
      this.setState({
        couponCode: {
          applied: false,
          label: couponCode.label,
        },
        instruments: [],
      });
    }
  };

  getCreditUIClasses = () => {
    const { couponCode, credit } = this.state;
    const { classes } = this.props;
    if (this.getAvailableCreditAmount("credit") === 0)
      return classes.card_disabled;
    else {
      if (couponCode.applied) return classes.card_disabled;
      if (!couponCode.applied) {
        if (credit.applied) return classes.card_applied;
        else return classes.card;
      }
    }
  };

  /**
   * @returns {Number} The amount that can be charged to Clinikk Cash,
   * either due to coupon applied or due to available credit.
   */
  getAvailableCreditAmount = (source) => {
    const { consultationDetails, coupon } = this.props;
    const totalAvailableCredit = Number(
      consultationDetails?.creditDetails?.amount
    );
    if (source === "credit") {
      // iterate over every item in the bill and compute the clinikk cash that can be
      // applied against it;
      if (totalAvailableCredit === 0) return 0;
      else {
        return Math.min(
          totalAvailableCredit,
          getOrderTotal(consultationDetails?.billing?.items)
        );
      }
    } else if (source === "couponCode") {
      // once validated, return the discount value that the coupon enables;
      // TODO: Check for `applicable_to = line_items`
      if (!coupon.isLoading && coupon.valid) {
        return coupon.order.total_discount_amount;
      } else return 0;
    }
    return 0;
  };

  /**
   * @returns Sum total of currently entered payment breakup
   * amounts.
   */
  getInstrumentTotal = (instruments) => {
    return instruments.reduce((total, method) => {
      return total + round(Number(method.value), 2);
    }, 0);
  };

  /**
   *
   * @param {*} event Handle the case when a payment option is selected from the
   * dropdown.
   *
   * **2** cases exist:
   * 1. Target method is not in the list:
   * In this case, change the source method's label and type to that of the target.
   * 2. Target method is already in the list:
   * In this case, swap the 'source' and 'target' payment methods' `label` and `type``.
   */
  handlePaymentOptionOnChange = (event, instrument) => {
    const { instruments } = this.state;
    const targetMethodType = event.target.value;
    const sourceMethodType = instrument.type;
    const sourceMethodLabel = availablePaymentMethods.find(
      (paymentMethod) => paymentMethod.type === sourceMethodType
    ).label;
    const targetMethodLabel = availablePaymentMethods.find(
      (paymentMethod) => paymentMethod.type === targetMethodType
    ).label;
    const targetMethodIndex = instruments.findIndex(
      (paymentMethod) => paymentMethod.type === targetMethodType
    );
    const sourceMethodIndex = instruments.findIndex(
      (paymentMethod) => paymentMethod.type === sourceMethodType
    );
    const newInstrumentList = [...instruments];

    // case 1: target is already in the list;
    if (targetMethodIndex === -1) {
      newInstrumentList[sourceMethodIndex].type = targetMethodType;
      newInstrumentList[sourceMethodIndex].label = targetMethodLabel;
      this.setState({
        instruments: newInstrumentList,
      });
    } else if (targetMethodIndex !== -1) {
      const temp = {
        type: sourceMethodType,
        label: sourceMethodLabel,
      };
      newInstrumentList[sourceMethodIndex].type =
        newInstrumentList[targetMethodIndex].type;
      newInstrumentList[sourceMethodIndex].label =
        newInstrumentList[targetMethodIndex].label;
      newInstrumentList[targetMethodIndex].type = temp.type;
      newInstrumentList[targetMethodIndex].label = temp.label;
      this.setState({
        instruments: newInstrumentList,
      });
    }
  };

  /**
   *
   * @returns Functional component for the Clinikk Cash checkbox.
   *
   * **TODO**: separate into independent functional component;
   */
  renderClinikkCashCheckbox = () => {
    const { classes, familyDetails } = this.props;
    const { couponCode, credit } = this.state;
    return (
      <div
        className={this.getCreditUIClasses()}
        onClick={() => {
          const { couponCode } = this.state;
          if (couponCode.applied) {
            this.setState({
              showSnackBar: true,
              snackbarMessage:
                "Clinikk Cash can't be applied when coupon is applied",
            });
          }
        }}
      >
        <div className={classes.cardRow}>
          <div className={classes.cardCol}>
            <FormControlLabel
              label="Clinikk Cash"
              classes={{
                label: classes.creditLabel,
              }}
              control={
                <Checkbox
                  disabled={
                    couponCode.applied ||
                    this.getAvailableCreditAmount("credit") === 0
                  }
                  indeterminate={
                    couponCode.applied ||
                    this.getAvailableCreditAmount("credit") === 0
                  }
                  style={{
                    color:
                      couponCode.applied ||
                      this.getAvailableCreditAmount("credit") === 0
                        ? color.GREY
                        : color.ROYAL_ORANGE,
                  }}
                  checked={credit.applied}
                  onChange={this.toggleCreditCheckbox}
                  value="primary"
                  aria-label="Clinikk Cash"
                />
              }
            />
          </div>
          <div className={classes.cardCol}>
            <div className={classes.cardRow}>
              <strong>{"₹" + familyDetails?.creditDetail?.amount ?? 0}</strong>
            </div>
          </div>
        </div>
      </div>
    );
  };

  /**
   *
   * @returns {Boolean} A boolean indicating whether or not
   * the + Add button for adding an instrument to the breakup
   * is enabled or disabled.
   *
   * The cases checked are:
   * 1. Entered breakup > order amount: `true`
   * 2. Two instruments already added to the breakup: `true`
   * 3. At least one entry is still incomplete (method not selected): `true`
   */
  shouldAddInstrumentButtonBeDisabled = () => {
    const { instruments, credit, couponCode } = this.state;
    const { consultationDetails } = this.props;
    const orderTotal = getOrderTotal(consultationDetails.billing.items);
    const instrumentTotal = this.getInstrumentTotal(instruments);
    let discount = 0;
    if (consultationDetails.billing.customer.id === "") return true;
    if (credit.applied) {
      discount = this.getAvailableCreditAmount("credit");
    } else if (couponCode.applied) {
      discount = this.getAvailableCreditAmount("couponCode");
    }
    if (orderTotal === 0) {
      return true;
    }
    if (orderTotal - discount < instrumentTotal) {
      return true;
    }
    if (instruments.length === 2) {
      return true;
    }
    if (
      instruments.some(
        (instrument) => instrument.type === genericPaymentMethod.type
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * onClick handler for Add Instrument button.
   * Inserts a new, blank entry into the breakup, initialised to Rs. 0.
   */
  handleAddInstrumentButtonClick = () => {
    const { instruments, couponCode, credit } = this.state;
    const { consultationDetails } = this.props;
    const newInstrumentList = [...instruments];
    const lineItems = consultationDetails.billing.items;
    const orderTotal = getOrderTotal(lineItems);
    let discount = 0;
    if (credit.applied) {
      discount = this.getAvailableCreditAmount("credit");
    }
    if (couponCode.applied) {
      discount = this.getAvailableCreditAmount("couponCode");
    }
    const payableAmount = orderTotal - discount;
    const paymentAccountedFor = this.getInstrumentTotal(instruments);
    const balanceAmount = round(payableAmount - paymentAccountedFor, 2);
    newInstrumentList.push({
      type: genericPaymentMethod.type,
      label: genericPaymentMethod.label,
      value: balanceAmount,
    });
    this.setState({
      instruments: newInstrumentList,
    });
  };

  /**
   *
   * @returns Functional component for the payment method break-up.
   *
   * **TODO**: separate into independent functional component;
   */
  renderPaymentMethods = () => {
    const { classes, consultationDetails } = this.props;
    const { instruments, couponCode, credit } = this.state;
    return (
      <div className={classes.card}>
        <div className={`${classes.cardRow} ${classes.headerRow}`}>
          <div className={classes.logo}>
            <PaymentsIcon />
          </div>
          <div>
            <div className={classes.header}>Payment Method</div>
          </div>
        </div>
        <div className={classes.cardRow}>
          <div className={`${classes.instrumentColumn}`}>
            {instruments.map((instrument, index) => {
              if (instrument.type !== 'opd')
              return (
                <div className={classes.instrumentRow}>
                  <div className={classes.instrumentRowItem}>
                    <DeleteIcon
                      tabIndex="1"
                      className={classes.deleteIcon}
                      onClick={() => {
                        const orderTotal = getOrderTotal(
                          consultationDetails.billing?.items
                        );
                        let discount = 0;
                        if (couponCode.applied) {
                          discount = this.getAvailableCreditAmount("coupon");
                        } else if (credit.applied) {
                          discount = this.getAvailableCreditAmount(
                            "couponCode"
                          );
                        }
                        const payableTotal = orderTotal - discount;
                        const filteredInstrumentList = instruments.filter(
                          (item) => item.type !== instrument.type
                        );
                        if (filteredInstrumentList.length !== 0) {
                          filteredInstrumentList[0].value = payableTotal;
                        }
                        this.setState({
                          instruments: filteredInstrumentList,
                        });
                      }}
                    />
                  </div>
                  <div className={classes.instrumentRowItem}>
                    <FormControl fullWidth>
                      <Select
                        classes={{
                          outlined: classes.instrumentDropdown,
                        }}
                        value={instrument.type}
                        variant="outlined"
                        placeholder="Select"
                        id="demo-customized-select-native"
                        onChange={(event) => {
                          this.handlePaymentOptionOnChange(event, instrument);
                        }}
                      >
                        <MenuItem
                          value={genericPaymentMethod.type}
                          hidden={true}
                          style={{ display: "none" }}
                          disabled
                        ></MenuItem>
                        {availablePaymentMethods &&
                        getTotalOpd(consultationDetails?.billing?.items) !== 0
                          ? availablePaymentMethods
                              .slice(0, -1)
                              .map((option) => {
                                return (
                                  <MenuItem
                                    disabled={instrument.type === option.type}
                                    value={option.type}
                                  >
                                    {option.label}
                                  </MenuItem>
                                );
                              })
                          : availablePaymentMethods
                              .filter((method) => method.label !== "OPD")
                              .slice(0, -1)
                              .map((option) => {
                                return (
                                  <MenuItem
                                    disabled={instrument.type === option.type}
                                    value={option.type}
                                  >
                                    {option.label}
                                  </MenuItem>
                                );
                              })}
                      </Select>
                    </FormControl>
                  </div>
                  <div className={classes.instrumentRowItem}>
                    <span>
                      <strong>₹</strong>
                    </span>
                    <input
                      className={classes.instrumentValueInput}
                      value={instrument.value}
                      type="number"
                      placeholder="Enter"
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-", ","].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      min={0}
                      onChange={(event) => {
                        const updatedValue = event.target.value;
                        const indexModifiedByUser = index;
                        const indexToAdjust = indexModifiedByUser === 0 ? 1 : 0;
                        const updateRegExp = new RegExp(
                          /^[1-9][0-9]*\.?[0-9]{0,2}$/
                        );
                        if (
                          updateRegExp.test(updatedValue) ||
                          updatedValue === ""
                        ) {
                          const newInstrumentList = [...instruments];
                          newInstrumentList[
                            indexModifiedByUser
                          ].value = updatedValue;
                          const orderTotal = getOrderTotal(
                            consultationDetails.billing.items
                          );
                          let discount = 0;
                          if (couponCode.applied) {
                            discount = this.getAvailableCreditAmount(
                              "couponCode"
                            );
                          } else if (credit.applied) {
                            discount = this.getAvailableCreditAmount("credit");
                          }
                          const payableTotal = orderTotal - discount;
                          if (instruments.length > 1) {
                            const balanceAmount =
                              payableTotal - (updatedValue ?? 0);
                            newInstrumentList[indexToAdjust].value = round(
                              balanceAmount,
                              2
                            );
                          }
                          if (updatedValue !== "") {
                            // now that all calculations pass, save the updated value as a number
                            newInstrumentList[
                              indexModifiedByUser
                            ].value = Number(updatedValue);
                          }
                          if (updatedValue <= payableTotal) {
                            this.setState({
                              instruments: newInstrumentList,
                            });
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={classes.cardRow}>
          <button
            className={classes.addPaymentMethodBtn}
            disabled={this.shouldAddInstrumentButtonBeDisabled()}
            onClick={() => {
              this.handleAddInstrumentButtonClick();
            }}
          >
            +Add
          </button>
        </div>
      </div>
    );
  };

  renderAllItemsMarkedConsent = () => {
    const { classes } = this.props;
    return (
        <div className={classes.card} style={{display:`${this.shouldGenerateBtnBeDisabled() ? 'none' : 'block'}`, border: '1px solid #FFA300' , marginTop: '-67px', position: 'relative', top:'33%', boxShadow: '0px 12px 24px 0px rgba(0, 0, 0, 0.15)',  height: '134px', backgroundColor: "#FFE6B1" }}>
        <Typography style={{ fontFamily: 'rubik', fontWeight: 400, paddingTop: '12px', paddingBottom: '12px', paddingLeft: '8px', paddingRight: '8px' }}>
        Please confirm, if you marked all eligible items for OPD limit?
        </Typography>
        <div className={classes.cardRow}>
        <Button disabled={this.shouldGenerateBtnBeDisabled()} onClick={this.handleGenerateBillOnClick} variant="contained" style={{ marginBottom: '16px', marginLeft: '8px' }} className={classes.ConsentButton}>
            Yes I Confirm
        </Button>
        </div>
      </div>
    )
  }

  /**
   *
   * @returns {Boolean} Indicating whether the button to confirm and submit the bill
   * should be enabled or not. This accounts for:
   * 1. Errors in any of the line items (name, rate, etc.)
   * 2. Entered payment breakup not matching with the payable bill amount
   * 3. If the bill settlement has started
   */
  shouldGenerateBtnBeDisabled = () => {
    const { consultationDetails, payment } = this.props;
    const { credit, couponCode, instruments } = this.state;
    const billTotal = getOrderTotal(consultationDetails.billing.items);
    if (consultationDetails.billing.customer.id === "") {
      return true;
    }
    // disabling during ongoing bill settlement;
    const listHasErrors = consultationDetails.billing.items.reduce(
      (error, lineItem) => {
        return (
          error ||
          Boolean(
            lineItem.error.name ||
              lineItem.error.rate ||
              lineItem.error.discount
          ) ||
          lineItem.type === itemTypes.GENERIC ||
          (lineItem.available_quantity !== 0 && lineItem.rate === 0) ||
          instruments.some(
            (instrument) => instrument.type === "" || instrument.label === ""
          )
        );
      },
      false
    );
    if (listHasErrors) {
      return true;
    } else {
      if (consultationDetails?.bill && consultationDetails?.bill?.isLoading) {
        return true;
      }
      if (payment.creditDeductionResponseLoading) {
        return true;
      }
      if (consultationDetails.billing.items.length === 0) {
        return true;
      }
      let discount = 0;
      if (credit.applied) {
        discount = this.getAvailableCreditAmount("credit");
      } else if (couponCode.applied) {
        discount = this.getAvailableCreditAmount("couponCode");
      }
      if (
        instruments.some(
          (instrument) => instrument.value === "" || instrument.value === 0
        )
      ) {
        return true;
      }
      const instrumentTotal = this.getInstrumentTotal(instruments);
      return (
        round(Number(instrumentTotal), 2) !==
        round(Number(billTotal - discount), 2)
      );
    }
  };

  shouldYesBtnBeDisabled = () => {
    const { consultationDetails, payment } = this.props;
    const { instruments } = this.state;
    if (consultationDetails.billing.customer.id === "") {
      return true;
    }
    // disabling during ongoing bill settlement;
    const listHasErrors = consultationDetails.billing.items.reduce(
      (error, lineItem) => {
        return (
          error ||
          Boolean(
            lineItem.error.name ||
              lineItem.error.rate ||
              lineItem.error.discount
          ) ||
          lineItem.type === itemTypes.GENERIC ||
          (lineItem.available_quantity !== 0 && lineItem.rate === 0) ||
          instruments.some(
            (instrument) => instrument.type === "" || instrument.label === ""
          )
        );
      },
      false
    );
    if (listHasErrors) {
      return true;
    } else {
      if (consultationDetails?.bill && consultationDetails?.bill?.isLoading) {
        return true;
      }
      if (payment.creditDeductionResponseLoading) {
        return true;
      }
      if (consultationDetails.billing.items.length === 0) {
        return true;
      }
      if (
        instruments.some(
          (instrument) => instrument.value === "" || instrument.value === 0
        )
      ) {
        return true;
      }
      
    }
  };

  evitalItemUpdated = (oldItems, newItem) => {
    const updatedItem = _.filter(oldItems, item => {
      if (item.id === newItem.id){
        if (newItem.amount !== item.amount) {
          return true;
        } else { 
          return false;
        }
      } else {
        return false;
      }
    })
    console.log(updatedItem.length, '---updatedItem.length')
    return updatedItem.length;
  }

  updateOpdAmountForLineItem = (oldItemDetails, updateItem) => {
    const { familyDetails, consultationDetails } = this.props;
    let currentOPDUtilised = consultationDetails.billing.opd_amount_utilised || 0;
    let opdBalance = parseFloat((familyDetails.opdAmounts.data.data.current_amount - currentOPDUtilised).toFixed(2));
    let currentItemOpdUsed;
    const oldItemOPDUtilisation = oldItemDetails.opd_amount || 0;
    const discountedPrice = parseFloat(getDiscountOnUpdatedLineItem(updateItem).toFixed(2));
    console.log(discountedPrice, currentOPDUtilised, opdBalance, '----discount percentage before update');
    if (discountedPrice >= oldItemOPDUtilisation) {
      const amountDiff = parseFloat(Math.abs(discountedPrice - oldItemOPDUtilisation).toFixed(2));
      if (opdBalance >= amountDiff) {
        currentOPDUtilised = parseFloat((currentOPDUtilised + amountDiff).toFixed(2));
        opdBalance = parseFloat((opdBalance - currentOPDUtilised).toFixed(2))
        currentItemOpdUsed = discountedPrice;
      } else {
        currentOPDUtilised = parseFloat((currentOPDUtilised + opdBalance).toFixed(2));
        currentItemOpdUsed = parseFloat((oldItemOPDUtilisation + opdBalance).toFixed(2));
        opdBalance = 0;
      }
    } else {
      const amountDiff = parseFloat(Math.abs(discountedPrice - oldItemOPDUtilisation).toFixed(2));
      currentOPDUtilised = parseFloat((currentOPDUtilised - amountDiff).toFixed(2));
      opdBalance = parseFloat((opdBalance + amountDiff).toFixed(2));
      

      currentItemOpdUsed = parseFloat((oldItemOPDUtilisation - amountDiff).toFixed(2));
    }
    console.log(discountedPrice, currentItemOpdUsed, currentOPDUtilised, opdBalance, '----discount percentage after update');
    return { currentItemOpdUsed, currentOPDUtilised, opdBalance }
  };

  updateLineItem = (item, isOPDUtilize) => {
    console.log(item, '------item');
    const { auth, updateOPDUtilisedValue, consultationDetails, modifyLineItem } = this.props;
    const accessToken = auth.authDetails.data.access_token;
    const updatedLineItemPayload = {
      external_id: String(item.external_id),
      type: item.type,
      quantity: item.quantity,
      price: String(item.rate),
      total_discount: String(item.discount.value),
      total_discount_type: discountType.PERCENTAGE,
      metadata: {
        batch_id: item.batch_number,
        display_name: item.name,
        opd_utilised: false,
      },
    };
    if (isOPDUtilize) {
      const opdDetails = this.updateOpdAmountForLineItem(item, updatedLineItemPayload);
      _.assign(updatedLineItemPayload.metadata, { opd_utilised: true, opd_amount: opdDetails.currentItemOpdUsed });
      console.log({ opd_utilised: true, opd_amount: opdDetails.currentItemOpdUsed }, '-----295')
      modifyLineItem(item.id, { opd_utilised: true, opd_amount: opdDetails.currentItemOpdUsed });
      updateOPDUtilisedValue(opdDetails.currentOPDUtilised);
    } else {
      const opdUsedForItem = item.opd_amount;
      modifyLineItem(item.id, { opd_utilised: false, opd_amount: null });
      updateOPDUtilisedValue((consultationDetails.billing.opd_amount_utilised || 0) - opdUsedForItem);
    }
    modifyLineItemInOrder(
      accessToken,
      item.id,
      updatedLineItemPayload
    );
  }

  /**
   *
   * Handling onClick events on the generate bill button
   */
  handleGenerateBillOnClick = () => {
    const {
      consultationDetails,
      settleBill,
      auth,
      flushLineItemsOfType,
      addLineItem,
    } = this.props;
    const { instruments, credit, couponCode, walletTransactionId } = this.state;
    const orderTotal = getOrderTotal(consultationDetails.billing.items);
    const availableCredit = this.getAvailableCreditAmount("credit");
    const eVitalRxUrl = consultationDetails.billing?.evital_redirect_url;
    const orderId = consultationDetails.billing?.order.id;
    const accessToken = auth.authDetails.data.access_token;
    this.setState({ consentClicked: true });
    if (eVitalRxUrl) {
      getOrderLineItems(accessToken, orderId, true).then((res) => {
        const { items: evitalItems } = res.data;
        const localItemIds = consultationDetails.billing.items
          .filter((item) => item.type === itemTypes.EVITAL_MEDICINE)
          .map((item) => item.id);
        const evitalItemIds = evitalItems.map((item) => item.id);
        if (
          evitalItemIds.length !== localItemIds.length &&
          (evitalItemIds.some((itemId) => !localItemIds.includes(itemId)) ||
            localItemIds.some((itemId) => !evitalItemIds.includes(itemId)))
        ) {
          this.setState({
            showSnackBar: true,
            snackbarMessage:
              "New eVitalRx items were added to the bill. Please check & confirm the payment again.",
          });
          flushLineItemsOfType(itemTypes.EVITAL_MEDICINE);
          evitalItems.forEach((item) => {
            const lineItem = {
              id: item.id,
              name: item.display_name,
              quantity: Number(item.quantity),
              available_quantity: null,
              prescribed_quantity: null,
              discount: {
                value: Number(item.total_discount),
                type: discountType.PERCENTAGE,
              },
              rate: Number(item.price_per_quantity),
              total: Number(item.quantity) * Number(item.price_per_quantity),
              external_id: item.external_id ?? "",
              batch_number: item.metadata.batch_id,
              error: {
                name: null,
                rate: null,
              },
              type: itemTypes.EVITAL_MEDICINE,
              uiTrackingId: v4(),
            };
            lineItem.discount.computedValue = getDiscountOnLineItem(lineItem);
            lineItem.amount = lineItem.total - lineItem.discount.computedValue;
            addLineItem(lineItem.type, lineItem);
          });
        } else {
          // check for the case where the entire bill can be settled using Clinikk Cash
          // in that case, no payment was collected from the user, so no amount needs to be verified
          // in the drawer.
          if (
            (availableCredit >= orderTotal && credit.applied) ||
            orderTotal === 0 ||
            (couponCode.applied &&
              this.getAvailableCreditAmount("couponCode") === orderTotal)
          ) {
            let paymentModes = instruments;
            console.log(consultationDetails.billing, '----consultationDetails.billing');
            if (consultationDetails.billing.opd_amount_utilised) {
              paymentModes = [...instruments, { label: 'OPD', type: 'opd', value: consultationDetails.billing.opd_amount_utilised}]
            }
            settleBill({
              instruments: paymentModes,
              credit,
              couponCode,
              walletTransactionId,
            });
          } else {
            let paymentModes;
            console.log(consultationDetails.billing, '----consultationDetails.billing 1021');
            if (consultationDetails.billing.opd_amount_utilised) {
              paymentModes = [...instruments, { label: 'OPD', type: 'opd', value: consultationDetails.billing.opd_amount_utilised}]
            }
            this.setState({
              drawerOpen: true,
              ...(paymentModes && {instruments: paymentModes})
            });
          }
        }
      });
    } else {
      // check for the case where the entire bill can be settled using Clinikk Cash
      // in that case, no payment was collected from the user, so no amount needs to be verified
      // in the drawer.
      if (
        (availableCredit >= orderTotal && credit.applied) ||
        orderTotal === 0 ||
        (couponCode.applied &&
          this.getAvailableCreditAmount("couponCode") === orderTotal)
      ) {
        // settleBill({ instruments, credit, couponCode, walletTransactionId });
        let paymentModes = instruments;
        if (consultationDetails.billing.opd_amount_utilised) {
          paymentModes = [...instruments, { label: 'OPD', type: 'opd', value: consultationDetails.billing.opd_amount_utilised}]
        }
        // console.log("handleGenerateBillOnClick ELSE -> IF =====> ", paymentModes, credit);
        settleBill({
          instruments: paymentModes,
          credit,
          couponCode,
          walletTransactionId,
        });
      } else {
        console.log("handleGenerateBillOnClick ELSE -> ELSE =====> ")
        // console.log(instruments, '----instruments')
        let paymentModes;
        console.log(consultationDetails.billing, '----consultationDetails.billing');
        if (consultationDetails.billing.opd_amount_utilised) {
          paymentModes = [...instruments, { label: 'OPD', type: 'opd', value: consultationDetails.billing.opd_amount_utilised}]
        }
        this.setState({
          drawerOpen: true,
          ...(paymentModes && {instruments: paymentModes})
        });
      }
    }
  };

  getConfirmButtonText = () => {
    const { consultationDetails, payment } = this.props;
    if (
      (consultationDetails.bill && consultationDetails.bill.isLoading) ||
      (payment && payment.creditDeductionResponseLoading)
    ) {
      return "Loading";
    } else {
      return "Confirm Bill";
    }
  };

  /**
   *
   * @returns Functional component for the final bill-breakup.
   *
   */
  renderBillSummary = () => {
    const { classes, consultationDetails } = this.props;
    const { credit, couponCode, markConsent } = this.state;

    let applicableCredit = 0;
    const totalItemDiscounts =
      consultationDetails.billing.items?.reduce(
        (totalDiscount, lineItem) =>
          totalDiscount + lineItem.discount.computedValue,
        0
      ) ?? 0;
    if (credit.applied) {
      applicableCredit = this.getAvailableCreditAmount("credit");
    } else if (couponCode.applied) {
      applicableCredit = this.getAvailableCreditAmount("couponCode");
    }
    return (
      <>
        {markConsent ? this.renderAllItemsMarkedConsent() : ''}
        <div className={classes.cardRow}>
          <div
            className={classes.savingsContainer}
            style={{
              backgroundImage: `url("../../../assets/icons2/common/savings.svg")`,
            }}
          >
            <div className={classes.cardCol}>
              <span>Today's total Savings</span>
            </div>
            <div className={classes.cardCol}>
              <span>
                <strong>
                  {applicableCredit + totalItemDiscounts === 0
                    ? "-"
                    : "₹" +
                      parseFloat(
                        Number(applicableCredit + totalItemDiscounts).toFixed(2)
                      )}
                </strong>
              </span>
            </div>
          </div>
        </div>
        <div style={{ paddingBottom: '-150px' }} className={classes.cardRow}>
          <Button
            className={classes.generateButton}
            variant="contained"
            disabled={this.shouldGenerateBtnBeDisabled() || markConsent}
            onClick={()=>{this.setState({ markConsent: true })}}
          >
            {this.getConfirmButtonText()}
          </Button>
        </div>
      </>
    );
  };

  /**
   * Open and close the amount confirmation drawer.
   */
  toggleDrawer = () => {
    const { drawerOpen, consentClicked } = this.state;
    this.setState({
      drawerOpen: !drawerOpen,
      consentClicked: !consentClicked,
      instruments: []
    },()=>{
      this.handleAddInstrumentButtonClick();
    });
  };

  walletTransactionIdOnChange = (event) => {
    const updatedValue = event.target.value;
    this.setState({
      walletTransactionId: updatedValue,
    });
  };

  /**
   *
   * @param {*} selectedValue The object selected for addition to the list
   * Returns the discount to be given on the selected item depending on the
   * subscription mapped to the user;
   *
   * @return Object with discount value and type
   */
  computeDiscountOnLineItem = (selectedValue) => {
    const { familyDetails, consultationDetails } = this.props;
    const customerToBeCharged = consultationDetails?.billing?.customer;
    // we need to ocompute item discount based on subscription data below;
    let computedItemDiscount = {
      type: discountType.PERCENTAGE,
      value: 0,
    };
    const purchasedSubscriptions = familyDetails?.allSubscriptions?.subscriptions.filter(
      (subscription) => {
        if (
          subscription.policy_status === policyStatus.ACTIVE &&
          subscription.status === subscriptionStatus.ACTIVE
        ) {
          return true;
        } else return false;
        // filter the members list of each subscription and check if the user is in it;
      }
    );
    const subscriptionsMappedToUser = purchasedSubscriptions.filter(
      (subscription) => {
        // subscriber details are not added to subscriptions with primary care only products,
        const memberList =
          subscription.version === 0
            ? subscription?.primary_care?.members.map(
                (member) => member.customer.id
              )
            : subscription.members.map((member) => member.customer);
        // so we skip the check for these and assume that the user is covered under this plan;
        if (utils.isPrimaryCareOnlyProduct(subscription.product)) {
          return true;
        }
        // non primary-care-only cases
        if (memberList !== null) {
          if (memberList.includes(customerToBeCharged.id)) {
            return true;
          } else {
            return false;
          }
        }
        return false;
      }
    );
    if (isEmpty(subscriptionsMappedToUser)) {
      computedItemDiscount = {
        type: discountType.PERCENTAGE,
        value: 0,
      };
    } else {
      // since at least 1 subscription mapped to this user is active,
      // they are entitled to 20% discount on this item;
      computedItemDiscount = {
        type: discountType.PERCENTAGE,
        value: 20,
      };
      // check if any subscriptions are HPP Plus;
      const hppPlusSubscriptions = subscriptionsMappedToUser.filter(
        (subscription) => {
          return has(subscription, "is_hpp") && subscription.is_hpp;
        }
      );
      const hppLiteSubscriptions = subscriptionsMappedToUser.filter(
        (subscription) => {
          return has(subscription, "is_hp_lite") && subscription.is_hp_lite;
        }
      );
      // before checking for HPP Plus check for Lite;
      // this means - if a user is covered by both Lite and Plus (unlikely)
      // they get 0% discount
      if (!isEmpty(hppLiteSubscriptions)) {
        computedItemDiscount = {
          type: discountType.PERCENTAGE,
          value: 0,
        };
      } else if (!isEmpty(hppPlusSubscriptions)) {
        const subscriptionsBelowThreshold = hppPlusSubscriptions.filter(
          (subscription) => subscription.is_hpp === true
        );
        computedItemDiscount = !isEmpty(subscriptionsBelowThreshold)
          ? {
              type: discountType.PERCENTAGE,
              value: 100,
            }
          : {
              type: discountType.PERCENTAGE,
              value: 50,
            };
      }
      if (selectedValue.type === itemTypes.CONSULTATION) {
        computedItemDiscount = {
          type: discountType.PERCENTAGE,
          value: 100,
        };
      }
      if (selectedValue.type === itemTypes.OTHER) {
        computedItemDiscount = {
          type: discountType.PERCENTAGE,
          value: 0,
        };
      }
    }
    return computedItemDiscount;
  };

  render() {
    const {
      consultationDetails,
      classes,
      resetErrors,
      familyDetails,
    } = this.props;
    const {
      couponCode,
      credit,
      drawerOpen,
      instruments,
      showSnackBar,
      snackbarMessage,
      markConsent,
      consentClicked,
    } = this.state;
    const cashIndex = instruments.findIndex(
      (instrument) => instrument.type === offlinePaymentSources.CASH
    );
    const qrIndex = instruments.findIndex(
      (instrument) => instrument.type === offlinePaymentSources.QR_CODE
    );
    const posIndex = instruments.findIndex(
      (instrument) => instrument.type === offlinePaymentSources.POS
    );
    const walletIndex = instruments.findIndex(
      (instrument) => instrument.type === offlinePaymentSources.WALLET
    );
    const opdIndex = instruments.findIndex(
      (instrument) => instrument.type === offlinePaymentSources.OPD
    );
    const familyId = familyDetails.currentCustomer.family.id;
    const careWalletMiniCardUrl = `${careWalletUrl}/widgets/mini-balance/${familyId}?access_token=${careWalletApiKey}`;
    const billingItemForOPD = consultationDetails?.billing?.items?.filter((item) => item?.opd_utilised === true);
    // Forcing the journey of selecting a particular consultation to bill;
    if (consultationDetails.billing === null) {
      return (
        <div className={classes.container}>
          <div className={classes.fullPanel}>
            <div className={classes.placeholder}>
              <span className={classes.placeholderItem}>Please select</span>
              <Button variant="contained" className={classes.placeholderButton}>
                Generate Bill
              </Button>
              <span className={classes.placeholderItem}>
                from the <strong>Completed Consultations</strong> list, to
                generate a bill for a consultation.
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      if (consultationDetails?.productCategories?.isLoading) {
        return (
          <div className={classes.container}>
            <div className={classes.fullPanel}>
              <div className={classes.placeholder}>
                <span className={classes.placeholderItem}>
                  Fetching product categories for billing...
                </span>
              </div>
            </div>
          </div>
        );
      } else {
        if (
          !consultationDetails?.productCategories?.isLoading &&
          consultationDetails?.productCategories?.status === "failure"
        ) {
          return (
            <div className={classes.container}>
              <div className={classes.fullPanel}>
                <div className={classes.placeholder}>
                  <span className={classes.placeholderItem}>
                    A bill can't be generated because the product categories
                    couldn't be fetched. Please try reloading this tab.
                  </span>
                </div>
              </div>
            </div>
          );
        }
      }
      return (
        <div className={classes.container}>
          <ConfirmationDrawer
            getAvailableCreditAmount={this.getAvailableCreditAmount}
            toggleDrawer={this.toggleDrawer}
            paymentBreakup={{ credit, couponCode, instruments, opdUsed: consultationDetails.billing.opd_amount_utilised }}
            open={drawerOpen && consentClicked}
            setWalletTransactionId={this.walletTransactionIdOnChange}
            walletTransactionId={this.state.walletTransactionId}
            getInstrumentTotal={this.getInstrumentTotal}
            cash={instruments[cashIndex]?.value ?? 0}
            pos={instruments[posIndex]?.value ?? 0}
            qr={instruments[qrIndex]?.value ?? 0}
            wallet={instruments[walletIndex]?.value ?? 0}
            opd={consultationDetails.billing.opd_amount_utilised}
          />
          <div className={classes.leftPanel}>
            {showSnackBar ? (
              <Snackbar
                open={showSnackBar}
                onClose={() => {
                  this.setState((prevState) => ({
                    showSnackBar: !prevState.showSnackBar,
                    snackbarMessage: "",
                  }));
                  resetErrors();
                }}
                autoHideDuration={2400}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              >
                <Alert
                  style={{
                    margin: "auto",
                    background: color.reds[100],
                    color: color.WHITE,
                    borderRadius: "8px",
                    fontFamily: "Rubik",
                  }}
                >
                  {snackbarMessage}
                </Alert>
              </Snackbar>
            ) : null}
            <AddProducts
              discountComputation={this.computeDiscountOnLineItem}
              eVitalRxTab={this.props.eVitalRxTab}
              syncLineItems={this.syncLineItems}
            />
          </div>
          <div className={classes.rightPanel}>
            <div className={classes.rightPanelRow}>
              <div className={classes.couponWrapper}>
                {billingItemForOPD.length >= 1 && consultationDetails?.billing?.opd_amount_utilised !==0 ? <div
                  style={{
                    padding: "1vh",
                    fontFamily: "Rubik",
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "calc(100% - 2vh)",
                  }}
                >
                  <div>Today's OPD Utilisation</div>
                  <div>
                    {"₹" + round(consultationDetails?.billing?.opd_amount_utilised || 0, 2)}
                  </div>
                </div> : ''}
                <div
                  onClick={() => {
                    const { credit } = this.state;
                    if (credit.applied) {
                      this.setState({
                        showSnackBar: true,
                        snackbarMessage:
                          "Coupon can't be used when Clinikk Cash is applied.",
                      });
                    }
                  }}
                  style={{
                    padding: "1vh",
                    fontFamily: "Rubik",
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "calc(100% - 2vh)",
                  }}
                >
                  <div>Total amount</div>
                  <div>
                    {"₹" + getOrderTotal(consultationDetails?.billing?.items)}
                  </div>
                </div>

                <ApplyCoupon
                  disabledOnClickHandler={() => {
                    this.setState({
                      showSnackBar: true,
                      snackbarMessage:
                        "Coupon code can't be used if Clinikk Cash is applied",
                    });
                  }}
                  creditApplied={credit.applied}
                  couponDetailUpdate={(applied) => {
                    const { couponCode, credit } = this.state;
                    this.setState({
                      couponCode: {
                        applied: applied,
                        label: couponCode.label,
                      },
                      instruments: [],
                    });
                    if (credit.applied) {
                      this.setState({
                        credit: {
                          applied: false,
                          label: credit.label,
                        },
                      });
                    }
                  }}
                  disabled={credit.applied}
                />
              </div>
              {this.renderClinikkCashCheckbox()}
              {this.renderPaymentMethods()}
              <iframe
                className={classes.miniCard}
                src={careWalletMiniCardUrl}
                title="Care First Wallet"
              />
            </div>
            <div className={classes.rightPanelRow}>
              {this.renderBillSummary()}
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = ({
  auth,
  familyDetails,
  consultationDetails,
  payment,
  coupon,
  error,
}) => {
  return { auth, familyDetails, consultationDetails, payment, error, coupon };
};

const mapDispatchToProps = (dispatch) => ({
  getOpdAmounts: (subscription) => { dispatch(getOpdBalanceEvent(subscription)); },
  settleBill: (paymentBreakup) => {
    dispatch(settleBill(paymentBreakup));
  },
  resetBilling: () => {
    dispatch(resetBilling());
  },
  resetAddress: () => {
    dispatch(resetAddressesEvent());
  },
  resetErrors: () => {
    dispatch(resetErrors());
  },
  searchBillingItems: (query, category, clinic) => {
    dispatch(searchBillingItems(query, category, clinic));
  },
  addLineItem: (itemType, item) => {
    dispatch(consultationActions.addLineItem(itemType, item));
  },
  addMultipleLineItems: (items) => {
    dispatch(consultationActions.addMultipleLineItems(items));
  },
  flushLineItemsOfType: (type) => {
    dispatch(consultationActions.flushLineItemsOfType(type));
  },
  setError: (error) => {
    dispatch(setError(error));
  },
  modifyLineItem: (itemId, modifications) => {
    dispatch(consultationActions.modifyLineItem(itemId, modifications));
  },
  setGeneralBilling: (customer) => {
    dispatch(consultationActions.setGeneralBilling(customer));
  },
  getProductCategories: () => {
    dispatch(consultationActions.getProductCategories());
  },
  createOrderForFamilyId: (familyId, orderData, linkToEvitalRx = false) => {
    dispatch(
      consultationActions.createOrderForFamilyIdWithLineItem(
        familyId,
        orderData,
        linkToEvitalRx
      )
    );
  },
  addDefaultEvitalLineItemToOrder: (orderId) => {
    dispatch(consultationActions.addDefaulteVitalLineItemToOrder(orderId));
  },
  unlinkEvitalSaleOrder: () => {
    dispatch(consultationActions.unlinkEvitalSaleOrder());
  },
  updateOPDUtilisedValue: (balance) => {
    dispatch(consultationActions.updateOPDUtilisedValue(balance));
  },
});

const reduxWrapper = connect(mapStateToProps, mapDispatchToProps);
const styleWrapper = withStyles(useStyles, { withTheme: true });

export default styleWrapper(reduxWrapper(Billing));
