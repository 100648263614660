import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { TextField, Typography, ButtonGroup, Button, Table, TableBody, TableCell, TableRow, CircularProgress, MenuItem } from "@material-ui/core";
import 'date-fns';
import { orange } from "@material-ui/core/colors";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from '@material-ui/pickers';
import { ThemeProvider } from "@material-ui/styles";

import { createMuiTheme } from '@material-ui/core/styles';
import { getCustomerByPhoneNumber, resetCustomerByPhoneNumber, resetCreateHeadSubscriber, findAddressByZipcode, resetAddressByZipcode, resetUpdateMember } from '../../../../../actions';
import { getISTFormat2 } from '../../../../../common/utils';


const motherTongue = [
    {
        value: 'hindi',
        label: 'Hindi'
    },
    {
        value: 'english',
        label: 'English',
    },
    {
        value: 'kannada',
        label: 'Kannada',
    },
    {
        value: 'tamil',
        label: 'Tamil',
    },
    {
        value: 'telugu',
        label: 'Telugu',
    },
    {
        value: 'malayalam',
        label: 'Malayalam',
    },
    {
        value: 'bangla',
        label: 'Bangla',
    },
    {
        value: 'gujarati',
        label: 'Gujarati',
    },
    {
        value: 'marathi',
        label: 'Marathi',
    },
    {
        value: 'punjabi',
        label: 'Punjabi',
    },
    {
        value: 'odia',
        label: 'Odia',
    },
];



const materialTheme = createMuiTheme({
    palette: {
        primary: orange,
    },
});

function createData(name, value) {
    return { name, value };
}

function disableNextDates(startDate) {
    const startSeconds = Date.parse(startDate);
    return (date) => {
        return Date.parse(date) > startSeconds;
    }
}

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    element: {
        padding: theme.spacing(2),
    },
    textField: {
        color: 'orange',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },

    floatingLabelFocusStyle: {
        color: "somecolor"
    }
});

const GENDER = {
    MALE: 'male',
    FEMALE: 'female',
    OTHER: 'other',
}

class AddPrimaryDetails extends React.Component {

    state = {
        pincode: '', 
         insideDobField: false, insideFullNameField: false
    }

    componentDidMount() {
        const { mobileNumber } = this.props;
        const customerPhoneNumber = mobileNumber;
        this.props.resetUpdateMember();
        this.props.resetAddressByZipcode();
        this.props.resetCreateHeadSubscriber();
        this.props.resetCustomerByPhoneNumber();
        this.props.getCustomerByPhoneNumber(customerPhoneNumber);
    }

    componentDidUpdate(prevProps, preState) {
        if (!_.isNull(this.props.newPlan) && this.props.newPlan.customerByPhoneNumber && this.props.newPlan.customerByPhoneNumber.first_name && !this.props.first_name) {
            let name = this.props.newPlan.customerByPhoneNumber.last_name ? this.props.newPlan.customerByPhoneNumber.first_name + ' ' + this.props.newPlan.customerByPhoneNumber.last_name : this.props.newPlan.customerByPhoneNumber.first_name;
            this.props.handleNameChange(name);
            if (this.props.newPlan.customerByPhoneNumber.dob) {
                this.props.setParentState('dob', new Date(this.props.newPlan.customerByPhoneNumber.dob))
            }
            if (this.props.newPlan.customerByPhoneNumber.lop) {
                this.props.setParentState('lop',this.props.newPlan.customerByPhoneNumber.lop)
            }
            if (this.props.newPlan.customerByPhoneNumber.gender) {
                this.props.setParentState('gender',this.props.newPlan.customerByPhoneNumber.gender)
            }
        }
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value }, () => this.props.setParentState(name, event.target.value));
    };

    handleDateChange = date => {
        this.props.setParentState('dob', date)
    };


    handleNameChange = (event) => {
        this.props.handleNameChange(event.target.value);
    }

    getCircularProgress = () => {
        return <CircularProgress style={{
            color: 'orange',
            left: '50%',
            top: '55%',
            position: 'absolute',
        }} disableShrink />;
    }

    setGender = (gender) => {
        this.props.setParentState('gender', gender);
    }

    handlePincodeChange = (e) => {
        this.setState({ pincode: e.target.value }, () => { this.props.setParentState('pincode', this.state.pincode); if (this.state.pincode.length === 6) { this.props.findAddressByZipcode(this.state.pincode) } else { this.props.resetAddressByZipcode() } });

    };

    renderEnterPrimaryDetails = () => {
        const { classes } = this.props;
        return (
            <div>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Typography className={classes.element} style={{ fontSize: '1.2rem', color: '#172a3a', fontFamily: 'Rubik-Regular', marginTop: '3vh', marginBottom: '3vh', marginLeft: '3.5vw' }}>Collect the following primary details from the customer:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            onMouseLeave={() => { this.setState({ insideFullNameField: false, insideDobField: false }) }}
                            onMouseOver={() => { this.setState({ insideFullNameField: true, insideDobField: false }) }} 
                            error={!this.props.text && !this.state.insideFullNameField}
                            helperText={!this.props.text && !this.state.insideFullNameField ? 'This field is mandatory.' : ""}
                            style={{ marginLeft: '5vw', marginTop: '-2vh', width: '35vh' }}
                            id="full-name"
                            label="Full Name*"
                            className={classes.textField}
                            value={this.props.text}
                            onChange={this.handleNameChange}
                            margin="normal"
                            InputLabelProps={{
                                style: {
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    width: '100%',
                                    color: '#9d9d9d',
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            style={{ marginLeft: '-10vw', marginTop: '-2vh', width: '35vh' }}
                            select
                            id="lop"
                            label="Preferred Language"
                            className={classes.textField}
                            value={this.props.lop}
                            onChange={this.handleChange('lop')}
                            margin="normal"
                            InputLabelProps={{
                                style: {
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    width: '100%',
                                    color: '#9d9d9d'
                                }
                            }}>
                            {motherTongue.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={materialTheme}>
                                <DatePicker
                                    onMouseLeave={() => { this.setState({ insideDobField: false, insideFullNameField: false }) }}
                                    onMouseOver={() => { this.setState({ insideDobField: true, insideFullNameField: false }) }} 
                                    error={!this.props.dob && !this.state.insideDobField}
                                    helperText={!this.props.dob && !this.state.insideDobField ? 'This field is mandatory.' : " Ex: 16-12-1950"}
                                    style={{ marginLeft: '5vw', width: '35vh' }}
                                    id="dob"
                                    label="Date of Birth*"
                                    className={classes.textField}
                                    value={this.props.dob}
                                   
                                    onChange={this.handleDateChange}
                                    margin="normal"
                                    InputLabelProps={{
                                        style: {
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            width: '100%',
                                            color: '#9d9d9d',
                                        }
                                    }}
                                    format="dd-MM-yyyy"
                                    shouldDisableDate={disableNextDates(new Date())}
                                />
                            </ThemeProvider>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            style={{ marginLeft: '-10vw', width: '35vh' }}
                            id="pincode"
                            label="Pincode"
                            className={classes.textField}
                            value={this.props.pincode}
                            inputProps={{
                                maxLength: 6,
                            }}
                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                            onChange={this.handlePincodeChange}
                            margin="normal"
                            InputLabelProps={{
                                style: {
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    width: '100%',
                                    color: '#9d9d9d',
                                }
                            }}
                            helperText={this.props.newCustomer && this.props.newCustomer.zipcodeDetails && this.props.newCustomer.zipcodeDetails.city ? this.props.newCustomer.zipcodeDetails.city + ' , ' + this.props.newCustomer.zipcodeDetails.state : ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.element} style={{ fontSize: '0.9rem', color: '#908f8f', fontFamily: 'Rubik-Regular', marginTop: '2vh', marginBottom: '1vh', marginLeft: '3.5vw' }}>Gender*</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonGroup variant="contained" aria-label="contained primary button group" style={{ fontFamily: 'Rubik-Regular', marginLeft: '4.8vw', marginTop: '-1vh', marginBottom: '4vh' }} >
                            <Button onClick={() => this.setGender(GENDER.MALE)} style={{ backgroundColor: this.props.gender === GENDER.MALE ? '#f38b44' : '#e5e5e5', color: this.props.gender === GENDER.MALE ? 'white' : '#908f8f', textTransform: 'capitalize', width: '9vw' }} >Male</Button>
                            <Button onClick={() => this.setGender(GENDER.FEMALE)} style={{ backgroundColor: this.props.gender === GENDER.FEMALE ? '#f38b44' : '#e5e5e5', color: this.props.gender === GENDER.FEMALE ? 'white' : '#908f8f', textTransform: 'capitalize', width: '9vw' }} >Female</Button>
                            <Button onClick={() => this.setGender(GENDER.OTHER)} style={{ backgroundColor: this.props.gender === GENDER.OTHER ? '#f38b44' : '#e5e5e5', color: this.props.gender === GENDER.OTHER ? 'white' : '#908f8f', textTransform: 'capitalize', width: '9vw' }} >Other</Button>
                        </ButtonGroup>
                        {/* {this.props.newCustomer && this.props.newCustomer.createHeadSubscriber === errors.ERROR_IN_CREATING_PRIMARY_CUSTOMER ? <Typography style={{ marginTop: '2vh', marginLeft: '6vw', color: 'red' }}>Error while creating customer !!</Typography> : <> </>} */}
                    </Grid>
                </Grid>
                {/* <Divider style={{ marginTop: '4vh' }} /> */}
            </div>
        )
    }

    renderExistingPrimaryDetailsTable = () => {
        const { classes, newPlan } = this.props;
        const { customerByPhoneNumber } = newPlan;
        const
            rows = [
                createData('Full Name', customerByPhoneNumber.last_name ? customerByPhoneNumber.first_name + ' ' + customerByPhoneNumber.last_name : customerByPhoneNumber.first_name),
                createData('Date of Birth', customerByPhoneNumber.dob ? getISTFormat2(customerByPhoneNumber.dob) : ''),
                createData('Gender', customerByPhoneNumber.gender ? _.capitalize(customerByPhoneNumber.gender) : ''),
                createData('Preferred Language', customerByPhoneNumber.lop ? _.capitalize(customerByPhoneNumber.lop) : ''),
            ];
        return (
            <>
                <Grid container spacing={10}>
                    <Grid item xs={12}>
                        <Typography className={classes.element} style={{ fontSize: '1.2rem', color: '#172a3a', fontFamily: 'Rubik-Regular', marginTop: '3.5vh', marginBottom: '-10vh', marginLeft: '3.5vw' }}>Confirm the following Customer Details</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ marginLeft: '4vw' }}>
                            <Table style={{
                                minWidth: 100,
                                borderTop: 'none',
                                borderBottom: 'none !important'
                            }} aria-label="simple table">
                                <TableBody >
                                    {rows.map(row => {
                                        return (
                                            <TableRow key={row.name}>
                                                <TableCell style={{ fontSize: '0.9rem', fontFamily: 'Rubik-Medium', border: 'none', width: '20vw' }} component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="left" style={{ fontSize: '0.9rem', border: 'none', fontFamily: 'Rubik-Regular', width: '20vw' }}> {row.value}</TableCell>
                                            </TableRow>
                                        );
                                    }
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                    </Grid>
                </Grid>
            </>
        )
    }

    render() {
        const { classes } = this.props;
        if (this.props.newPlan && this.props.newPlan.customerByPhoneNumber && this.props.newPlan.customerByPhoneNumber.id) {
            return (
                <div style={{ height: '53vh', overflowY: 'scroll' }}>
                    {/* {this.renderExistingPrimaryDetailsTable()} */}
                    {this.renderEnterPrimaryDetails()}
                </div>
            );
        } else if (this.props.newPlan && _.isNil(this.props.newPlan.customerByPhoneNumber)) {
            return (
                <div className={classes.root} style={{ height: '53vh', overflowY: 'scroll' }}>
                    {this.getCircularProgress()}
                </div>
            )
        } else {
            return (
                <div style={{ height: '53vh', overflowY: 'scroll' }}>
                    <div className={classes.root} style={{ marginLeft: '5vw', marginTop: '10vh' }}>
                        Something went wrong !!
               </div>
                </div>
            );

        }
    }
}

AddPrimaryDetails.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error, familyDetails: state.familyDetails, newPlan: state.newPlan, newCustomer: state.newCustomer };
};

export default connect(mapStateToProps, { getCustomerByPhoneNumber, resetCustomerByPhoneNumber, resetCreateHeadSubscriber, findAddressByZipcode, resetAddressByZipcode, resetUpdateMember })(withStyles(styles)(AddPrimaryDetails));