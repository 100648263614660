import config  from '../config';

const axios = require('axios');

export const getCityStateByZipCode = async (ACCESS_TOKEN, zipcode) => {
    const url = config.apiURL + `/v3/zipcodes/${zipcode}`;
    const options = {
        headers: {
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + ACCESS_TOKEN,
    
        },
        timeout: 10000,
        responseType: 'json',
        responseEncoding: 'utf8',
      };
    const response = await axios.get(url, options);
    return response;
  };

export const createNewAddress = async (ACCESS_TOKEN, body) => {
  const url = config.apiURL + `/v3/addresses`;
  const options = {
      headers: {
        'content-type': 'application/json',
        'Authorization': 'Bearer ' + ACCESS_TOKEN,
  
      },
      timeout: 10000,
      responseType: 'json',
      responseEncoding: 'utf8',
    };
  const response = await axios.post(url, body, options);
  return response;
};

export const updateAddress = async (ACCESS_TOKEN, body, familyId, addressId) => {
  const url = config.apiURL + `/v3/family/${familyId}/addresses/${addressId}`;
  const options = {
      headers: {
        'content-type': 'application/json',
        'Authorization': 'Bearer ' + ACCESS_TOKEN,
  
      },
      timeout: 10000,
      responseType: 'json',
      responseEncoding: 'utf8',
    };
  const response = await axios.put(url, body, options);
  return response;
};

export const getFamilyAddresses = async (ACCESS_TOKEN, familyId) => {
  const url = config.apiURL + `/v3/addresses?familyId=${familyId}`;
  const options = {
      headers: {
        'content-type': 'application/json',
        'Authorization': 'Bearer ' + ACCESS_TOKEN,
  
      },
      timeout: 10000,
      responseType: 'json',
      responseEncoding: 'utf8',
    };
  const response = await axios.get(url, options);
  return response;
};

  