// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { fetchAndActivate, getRemoteConfig } from "firebase/remote-config";
import { getValue } from "firebase/remote-config";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCAz6W8xTLzYvSW8A1O9pL2glWMDdLQ0e0",
  authDomain: "clinikk-customer-application.firebaseapp.com",
  databaseURL: "https://clinikk-customer-application.firebaseio.com",
  projectId: "clinikk-customer-application",
  storageBucket: "clinikk-customer-application.appspot.com",
  messagingSenderId: "944125306077",
  appId: "1:944125306077:web:f212bbef8577576833a734",
  measurementId: "G-0VJ97TTRG2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const remoteConfig = getRemoteConfig(app);
// this line sets the interval at which remote configs are fetched
// for this client. We are using the default which is 12 hrs.
// remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

/**
 * default values to be used for the config in case the
 * fetch request fails
 */
const defaultQuickSymptoms = {
    quick_symptoms: ["fever", "headache", "cough"]
};
remoteConfig.defaultConfig = {
    consultation_booking_quick_symptoms: defaultQuickSymptoms,
};

const getQuickSymptoms = () => {
    return JSON.parse(getValue(remoteConfig, "consultation_booking_quick_symptoms")._value)
    .quick_symptoms;
};

const fetchQuickSymptomsFromFirebase = async () => {
    fetchAndActivate(remoteConfig)
    .then(() => {
        return true;
    })
    .catch(() => {
        return false;
    });
}
const firebaseActions = {
    fetchQuickSymptomsFromFirebase,
    getQuickSymptoms,
};
export default firebaseActions;
