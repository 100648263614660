import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as ClinikkFullLogo } from '../images/clinikk_full.svg';
import { ReactComponent as OnboardingIcon } from '../images/Onboarding.svg';

import { verifyOtp, initiateOtp, resetErrors } from '../actions';
import LoginPage from './LoginPage';
class VerifyOtp extends React.Component {

    state = { otp: '', seconds: 30, notificationBarDelay: 10 };

    verifyOtpMethod = () => {
        setTimeout(() => this.props.resetErrors(), 9000);
        this.props.verifyOtp(this.state.otp);
        this.setState({ notificationBarDelay: 10 })
    };

    verifyOtp = () => {
        this.verifyOtpMethod();
    };

    verifyOtpOnEnter = (event) => {
        if (event.key === 'Enter' && this.state.otp.length === 6) {
            this.verifyOtpMethod();
        }
    };

    initiateOtpAgain = () => {
        this.props.initiateOtp(this.props.auth.phoneNumber);
    }

    handleEdit = () => {
        const { history, auth } = this.props;
        history.push(`/family?phoneNumber=${auth.phoneNumber}`);
    }

    tick() {
        this.setState(prevState => ({
            seconds: this.state.seconds > 1 ? prevState.seconds - 1 : ''
        }));
        this.setState(prevState => ({
            notificationBarDelay: this.state.notificationBarDelay > 1 ? prevState.notificationBarDelay - 1 : ''
        }));
    }

    componentDidMount() {
        if (this.state.seconds > 0) {
            this.interval = setInterval(() => this.tick(), 1000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        // const errorInVerifyOtp = (this.props.error && (this.props.error.verifyOtpErrorCode));
        if (this.props.auth.phoneNumber && !this.props.auth.isLoggedIn) {
            return (
                <div className='main' style={{
                    height: "100vh", display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'row'
                }}
                    onKeyPress={(event) => this.verifyOtpOnEnter(event)}
                >
                    <div style={{ backgroundColor: "#f4f4f4", width: "50vh", height: "100%" }}>
                        <div style={{
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            marginTop: '20vh',
                        }} >
                            <OnboardingIcon />
                        </div>
                    </div>
                    <div style={{ backgroundColor: "white", width: "70%", height: "100%", }}>
                        {(this.props.error.verifyOtpErrorCode && this.state.notificationBarDelay > 1) ?
                            <div style={{ backgroundColor: '#f66a6a', height: '5vh', }}>
                                <Typography style={{ marginLeft: '3vh', marginTop: '5px', color: 'white' }}> {this.props.error.verifyOtpErrorCode}</Typography>
                            </div> : <> </>}
                        <div style={{
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            paddingTop: '30vh',
                            paddingLeft: '10vh',
                            margin: "auto"
                        }} >
                            <ClinikkFullLogo />
                            <h3 style={{ fontSize: '30px', fontFamily: 'Rubik' }}>Enter your OTP to verify.</h3>
                            <h3 style={{ fontSize: '16px', fontFamily: 'Rubik' }}>
                                OTP sent to {this.props.auth.phoneNumber}.
                                <span onClick={this.handleEdit} style={{ marginLeft: '6px', color: 'orange', cursor: 'pointer' }}>
                                    Edit Number
                                </span>
                            </h3>
                            <TextField id="otp"
                                autoFocus
                                autoComplete="off"
                                type="text"
                                inputProps={{
                                    maxLength: 6,
                                }}
                                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                onChange={(event) => this.setState({ otp: event.target.value })} label="OTP" style={{ paddingBottom: "3vh", display: "block" }} />
                            <Button
                                style={{ textTransform: 'capitalize', color: this.state.seconds > 0 ? 'grey' : 'orange', display: 'inline-block' }}
                                onClick={this.initiateOtpAgain}
                                // disabled={!errorInVerifyOtp}
                                disabled={this.state.seconds > 0}
                            >
                                Resend OTP
    </Button>
                            <div style={{ display: 'inline-block', color: 'grey' }}>
                                {this.state.seconds} {this.state.seconds > 0 ? 'seconds' : ''}
                            </div>
                            <br />
                            <Button onClick={this.verifyOtp} disabled={!this.state.otp} variant="contained" style={{ backgroundColor: "orange", color: "white", display: 'block', width: '15vh' }}>
                                Verify
</Button>
                        </div>
                    </div>
                </div>
            );

        } else {
            return (
                <LoginPage />
            );
        }
    }
};

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error };
}

export default connect(mapStateToProps, { verifyOtp, initiateOtp, resetErrors })(VerifyOtp);