import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Divider, Typography, Card, CardContent } from "@material-ui/core";
import { getAvailableServicesInProduct } from '../../common/utils';
import { ReactComponent as AccidentalNB } from '../../images/servicesNB/AccidentalNB.svg';
import { ReactComponent as PrimaryCareNB } from '../../images/servicesNB/PrimaryCareNB.svg';
import { ReactComponent as HealthInsuranceNB } from '../../images/servicesNB/HealthInsuranceNB.svg';
import { ReactComponent as HospicashNB } from '../../images/servicesNB/HospicashNB.svg';

import './ProductList.scss';

const styles = theme => ({
    card: {
        padding: theme.spacing(2),
        height: '34vh',
        overflowY: 'scroll',
        color: theme.palette.text.secondary,
    },
});

class ProductCard extends React.Component {

    state = { cordOnFocus: false }

    getServiceIcon = (service) => {
        switch (service) {
            case 'accidental_insurance':
                return <AccidentalNB style={{ height: '2vh', width: '1.5vw', marginTop: '0.5vh' }} />
            case 'health_insurance':
                return <HealthInsuranceNB style={{ height: '2vh', width: '1.5vw', marginTop: '0.5vh' }} />
            case 'hospicash':
                return <HospicashNB style={{ height: '2vh', width: '1.5vw', marginTop: '0.5vh' }} />
            default:
                return <PrimaryCareNB style={{ height: '2vh', width: '1.5vw', marginTop: '0.5vh' }} />
        }
    }

    getNumberOfMembers = (forValue) => {
        switch (forValue) {
            case 'couple':
                return '2 Members';
            case 'family':
                return '4 Members';
            case 'self':
                return '1 Member';
            default:
                return ''
        }
    }

    render() {
        const { classes, product } = this.props;
        const availableServicesList = getAvailableServicesInProduct(product);
        return (
            <Card elevation={3} className={classes.card} style={{ cursor: 'pointer', boxShadow: this.state.cordOnFocus ? '2px 2px 2px 2px grey' : '' }} onMouseOverCapture={() => this.setState({ cordOnFocus: true })} onMouseLeave={() => this.setState({ cordOnFocus: false })}>
                <div>
                    <Typography style={{ fontSize: '1.2rem', fontFamily: 'Rubik-Medium', color: '#172a3a', textAlign: 'center' }}>{_.truncate(product.name, { 'length': 50 })}</Typography>
                    <Divider style={{ height: '2px', backgroundColor: '#FFD07A', margin: '1vh -3vh 1vh -3vh' }} />
                    <CardContent style={{ backgroundColor: '#FFFFFF', marginTop: '-0.5vh' }}>
                        {availableServicesList.map((service) => {
                            return (
                                <div style={{ float: 'left' }} key={service.type}>
                                    <span>{this.getServiceIcon(service.type)}</span>
                                    <div style={{ float: 'right', marginTop: '-0.1vh', marginBottom: '1.3vh', marginLeft: '1vw' }}>
                                        <Typography style={{ width: '12vw', fontSize: '0.95rem', fontFamily: 'Rubik-Regular', color: '#172a3a' }}> {_.capitalize(_.split(service.type, '_').join(' '))} </Typography>
                                        <Typography style={{ fontFamily: 'Rubik-Regular', fontSize: '0.6rem' }}>{this.getNumberOfMembers(service.value.for)}</Typography>
                                    </div>
                                </div>
                            )
                        }
                        )}
                    </CardContent>
                </div>
            </Card>
        )
    }
}

ProductCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error, familyDetails: state.familyDetails, newPlan: state.newPlan };
};

export default connect(mapStateToProps)(withStyles(styles)(ProductCard));