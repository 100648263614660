import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import { createLogger } from 'redux-logger';

import { PersistGate } from "redux-persist/lib/integration/react";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import * as Sentry from "@sentry/react";
import sentryCredentialsDetails from "./config/sentry";

import rootReducer from "./reducers";

import App from "./core/App";
import './index.css';

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-572HZC7'
}

TagManager.initialize(tagManagerArgs)

Sentry.init(sentryCredentialsDetails.sentryCredentials);

const persistConfig = {
    key: "root",
    storage: storage,
    blacklist: ["error"],
};

const pReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(pReducer, composeEnhancers(applyMiddleware(reduxThunk, createLogger())));

const persistor = persistStore(store, {});

ReactDOM.render(<Provider store={store} >
    <PersistGate persistor={persistor}><App /></PersistGate>
</Provider>, document.querySelector("#root"));
