import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { TextField, Typography } from "@material-ui/core";
import { resetCreateHeadSubscriber, resetCustomerByPhoneNumber} from '../../../actions';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    element: {
        padding: theme.spacing(2),
        // marginTop: '5vh',
        // marginBottom: '2.5vh',
        // boxShadow: 'none',
        // textAlign: 'center',
        // color: theme.palette.text.secondary,
    },
    textField: {
        color: 'orange',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
});

class AddMobileNumber extends React.Component {

    componentDidMount() {
        this.props.resetCreateHeadSubscriber();
        this.props.resetCustomerByPhoneNumber();
    }

    state = { mobileNumber: '', retypedMobileNumber: '' }

    handleChange = name => event => {
        this.props.setParentState(name, event.target.value)
        this.setState({ [name]: event.target.value });
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                <div>
                    <div className={classes.root} style={{height: '53vh'}}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Typography className={classes.element} style={{ fontSize: '1.2rem', color: '#172a3a', fontFamily: 'Rubik-Regular', marginTop: '5vh', marginBottom: '3vh', marginLeft: '3.5vw' }}>Please enter the customer’s mobile number:</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    style={{ marginLeft: '5vw', marginTop: '-2vh', width: '35vh' }}
                                    id="customer-mobile-no"
                                    label="Customer Mobile No."
                                    className={classes.textField}
                                    // value={this.state.mobileNumber}
                                    defaultValue = {this.props.mobileNumber}
                                    onChange={this.handleChange('mobileNumber')}
                                    margin="normal"
                                    InputLabelProps={{
                                        style: {
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            width: '100%',
                                            color: '#9d9d9d',
                                        }
                                    }}
                                    autoFocus
                                    type="password"
                                    inputProps={{
                                        maxLength: 10,
                                    }}
                                    onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                 error = {(this.state.mobileNumber.length === 10 && this.state.retypedMobileNumber.length === 10) && this.state.mobileNumber !== this.state.retypedMobileNumber}
                                 helperText = {(this.state.mobileNumber.length === 10 && this.state.retypedMobileNumber.length === 10) && this.state.mobileNumber !== this.state.retypedMobileNumber ? 'Numbers do not match' : ''}
                                    style={{ marginLeft: '5vw', width: '35vh' }}
                                    id="retype-mobile-no"
                                    label="Retype Mobile No."
                                    className={classes.textField}
                                    defaultValue = {this.props.retypedMobileNumber}
                                    // value={this.state.retypedMobileNumber}
                                    onChange={this.handleChange('retypedMobileNumber')}
                                    margin="normal"
                                    InputLabelProps={{
                                        style: {
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            width: '100%',
                                            color: '#9d9d9d'
                                        }
                                    }}
                                    inputProps={{
                                        maxLength: 10,
                                    }}
                                    onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>

            </div>
        )
    }
}

AddMobileNumber.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error, familyDetails: state.familyDetails, newPlan: state.newPlan };
};

export default connect(mapStateToProps, {resetCreateHeadSubscriber, resetCustomerByPhoneNumber})(withStyles(styles)(AddMobileNumber));