import { color } from "../../../constants/colors";
const useStyles = (theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '70vh',
        marginTop: '2.3vh',
        overflow: 'hidden',
    },
    fullPanel: {
        width: '100%',
        height: 'calc(100% - 2vh)',
        background: color.WHITE,
        padding: '2vh',
        border: `1px solid ${color.BORDER_GREY}40`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Rubik',
    },
    placeholder: {
        height: '100px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    placeholderItem: {
        padding: '2vh',
    },
    leftPanel: {
        width: 'calc(100% - 320px - 4vh)',
        height: '100%',
        background: color.WHITE,
        padding: '0px 2vh',
        border: `1px solid ${color.BORDER_GREY}40`,
    },
    rightPanel: {
        width: 'calc(320px + 4vh)',
        height: 'calc(100% - 4vh)',
        background: color.WHITE,
        padding: '2vh',
        border: `1px solid ${color.BORDER_GREY}40`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        overflowX: 'hidden',
        overflowY: 'scroll',
    },
    rightPanelRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    couponWrapper: {
        background: color.ANTI_FLASH_WHITE,
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        width: '100%',
    },
    card: {
        padding: '0.5vh 1vh',
        margin: '1vh 0px 0px 0px',
        border: `1px solid ${color.ROYAL_ORANGE}`,
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 'calc(100% - 2vh)',
    },
    cardRow: {
        minWidth: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontFamily: 'Rubik',
        justifyContent: 'space-between',
        margin: '0.2vh 0px',
        maxWidth: '100%',
    },
    miniCard: {
        marginTop: '1vh',
        border: '0px',
        height: '80px',
        margin: '2vh 0px',
        width: '100%',
    },
    headerRow: {
        display: 'flex',
        justifyContent: 'flex-start'
    },
    logo: {
        marginRight: '4px',
        display: 'flex',
        direction: 'row',
        alignItems: 'center',
    },
    header: {
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        color: color.ROYAL_ORANGE,
    },
    cardCol: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginRight: '0.5vh',
    },
    instrumentColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'space-evenly',
        justifyContent: 'space-between',
    },
    instrumentRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '1vh',
    },
    instrumentRowItem: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'center',
        width: 'calc(0.6 * 5/6 * 100%)',
        '&:first-child': {
            width: 'calc(0.4 * 33%)'
        }
    },
    creditCol: {
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        color: color.DARK_GREY,
        fontSize: '16px',
    },
    card_disabled: {
        padding: '0.5vh 1vh',
        margin: '1vh 0px 0px 0px',
        border: `1px solid ${color.greys[100]}`,
        background: color.ANTI_FLASH_WHITE,
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 'calc(100% - 2vh)',
    },
    card_applied: {
        padding: '0.5vh 1vh',
        margin: '1vh 0px 0px 0px',
        border: `1px solid ${color.ROYAL_ORANGE}`,
        background: color.oranges[100],
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 'calc(100% - 2vh)',
    },
    creditLabel: {
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        color: color.DARK_GREY,
    },
    addPaymentMethodBtn: {
        background: 'none',
        border: 'none',
        outline: 'none',
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        margin: '4px 0px',
        color: color.ROYAL_ORANGE,
        cursor: 'pointer',
        '&:disabled': {
            color: color.LIGHT_GREY,
            '&:hover': {
                cursor: 'not-allowed',
            }
        },
        '&:focus': {
            color: color.ORANGE,
        },
    },
    deleteIcon: {
        cursor: 'pointer',
        padding: '0.5vh',
        '&:focus': {
            color: color.ROYAL_ORANGE,
        },
    },
    instrumentDropdown: {
        maxHeight: '10px',
        padding: '1vh'
    },
    instrumentValueInput: {
        width: '70%',
        height: '5vh',
        border: 'none',
        outline: 'none',
        fontFamily: 'Rubik',
        fontSize: '16px',
        fontWeight: 'bold',
        borderBottom: `2px solid ${color.BORDER_GREY}`,
    },
    savingsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: color.greens[200],
        borderRadius: '4px',
        fontFamily: 'Rubik',
        fontWeight: 'normal',
        width: 'calc(100% - 2vh)',
        height: '50px',
        marginTop: '2vh',
        padding: '0 14px',
        color: color.greens[100],
    },
    generateButton: {
        borderRadius: '4px',
        fontFamily: 'Rubik',
        marginTop: '2vh',
        fontSize: '12px',
        fontWeight: 'bold',
        '&:disabled': {
            background: `${color.ROYAL_ORANGE}30`,
        },
        width: '100%',
        color: color.WHITE,
        background: color.ROYAL_ORANGE,
        '&:hover': {
            background: color.ROYAL_ORANGE,
        },
        height: '48px',
        textTransform: 'capitalize',
    },
    placeholderButton: {
        borderRadius: '4px',
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontWeight: 'bold',
        '&:disabled': {
            background: color.LIGHT_GREY,
        },
        width: '120px',
        color: color.WHITE,
        background: color.ROYAL_ORANGE,
        '&:hover': {
            background: color.ROYAL_ORANGE,
        },
        height: '48px',
        textTransform: 'capitalize',
    },
    ConsentButton: {
        borderRadius: '5px',
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontWeight: 'bold',
        '&:disabled': {
            background: color.LIGHT_GREY,
        },
        width: '132px',
        color: color.WHITE,
        background: color.ROYAL_ORANGE,
        '&:hover': {
            background: color.ROYAL_ORANGE,
        },
        height: '42px',
        textTransform: 'capitalize',
    },
    creditApplicabilityString: {
        fontSize: '12px',
        color: color.reds[100],
    },
});

export default useStyles;