import {
    SET_CONSULTATION_FORM,
    CREATE_CONSULTATION,
    RESET_CONSULTATION_ERROR,
    FETCH_CONSULTATIONS_BY_CUSTOMER_ID,
    LOG_OUT,
    GET_ALL_DOCTORS,
    RESET_CONSULTATIONS_BY_CUSTOMER_ID,
    GET_CONSULTATION_BY_ID,
    RESET_CONSULTATION_BY_ID,
    ADD_VITALS_BY_CONSULTATION_ID,
    RESET_ADD_VITALS_STATUS,
    OPEN_ADD_VITALS_DRAWER,
    CLOSE_ADD_VITALS_DRAWER,
    SET_PRESENTABLE_CONSULTATIONS,
    INCREMENT_CONSULTATION_PAGE,
    DECREMENT_CONSULTATION_PAGE,
    RESET_CONSULTATION_PAGE_NUMBER,
    FETCH_CONSULTATIONS_BY_CUSTOMER_ID_FIRST_PAGE,
    GET_ALL_CLINICS,
    GET_CONSULTATIONS_BY_CLINIC_ID,
    ASSIGN_DOCTOR_BY_CONSULTATION_ID,
    RESET_DOCTOR_BY_CONSULTATION_ID,
    SET_PRESENTABLE_CONSULTATION_QUEUE,
    RESET_CONSULTATION_QUEUE,
    CANCEL_CONSULTATION_BY_ID,
    GET_CONSULTATIONS_BY_CLINIC_ID_FIRST_PAGE,
    INCREMENT_CONSULTATIONQ_PAGE,
    DECREMENT_CONSULTATIONQ_PAGE,
    RESET_CONSULTATIONQ_PAGE_NUMBER,
    CHECKOUT_CONSULTATION,
    RESET_CHECKOUT_CONSULTATION_STATUS,
    GET_CONSULTATION_ORDER,
    RESET_CONSULTATION_ORDER_DETAILS,
    GET_CREDIT_DETAILS_BY_FAMILY_ID,
    RESET_CREDIT_DETAILS,
    CREATE_OFFLINE_PAYMENT,
    RESET_OFFLINE_PAYMENT_STATUS,
    RESET_CANCEL_CONSULTATION_STATUS,
    RE_ASSIGN_DOCTOR_BY_CONSULTATION_ID,
    CREATE_VISIT,
    RESET_VISIT_BOOKING_ERROR,
} from '../actions/types';
import { billingType, consultationActionTypes, itemTypes, orderActionTypes } from '../constants';
import moment from 'moment';

import _, { has } from 'lodash';
import { getDiscountOnLineItem } from '../common/utils';
import { errors } from '../common/errors';
import { v4 } from 'uuid';

const INITIAL_STATE = {
};

const transformLineItem = (item, idToModify, modifications) => {
    if (item.id !== idToModify) {
        return item;
    } else {
        const newItem = { ...item };
        if ('quantity' in modifications) {
            if ((item.available_quantity && modifications.quantity <= item.available_quantity) || (!item.available_quantity)) {
                newItem.quantity = modifications.quantity;
            }
        }

        if ('name' in modifications) {
            if (modifications.name.includes('\n')) {
                modifications.name = String(modifications.name).replace('\n', '');
            }
            newItem.name = modifications.name;
            if (modifications.name.length === 0) {
                newItem.rate = null;
                newItem.type = itemTypes.GENERIC;
                newItem.id = moment().valueOf();
            }
        }

        if ('rate' in modifications) {
            newItem.rate = modifications.rate;
            if (modifications.rate === 0) {
                newItem.rate = modifications.rate;
                newItem.total = 0;
                if (newItem.type !== itemTypes.MEDICINE) {
                    newItem.discount.value = 0;
                } else {
                    newItem.discount.computedValue = 0;
                }
            }
        }

        if ('discount' in modifications) {
            if (!('computedValue' in modifications)) {
                newItem.discount.computedValue = 0;
            }
            newItem.discount = modifications.discount;

        }

        if ('type' in modifications) {
            newItem.type = modifications.type;
        }

        if ('id' in modifications) {
            newItem.id = modifications.id;
        }

        if ('sync' in modifications) {
            newItem.sync = modifications.sync;
        }

        if ('external_id' in modifications) {
            newItem.external_id = modifications.external_id;
        }
        if ('opd_utilised' in modifications) {
            newItem.opd_utilised = modifications.opd_utilised;
        }
        if ('opd_amount' in modifications) {
            newItem.opd_amount = modifications.opd_amount;
        }
        // perform value computations;
        newItem.discount.computedValue = Number((getDiscountOnLineItem(newItem)));
        newItem.total = Number(newItem.quantity * newItem.rate);
        newItem.amount = Number(newItem.total - newItem.discount.computedValue);
        if (newItem.discount.computedValue > newItem.total) {
            newItem.discount.computedValue = 0;
        }
        // set errors to the item if necessary
        if (newItem.name.length === 0) newItem.error.name = "Please enter a product name.";
        else newItem.error.name = null;
        if (Number(newItem.rate) === 0) newItem.error.rate = "Price must be greater than ₹0.";
        else newItem.error.rate = null;
        if (newItem.discount.value === '') newItem.error.discount = "Invalid discount.";
        else newItem.error.discount = null;
        return newItem;
    }
}

const consultationReducer = (state = { INITIAL_STATE }, action) => {
    switch (action.type) {
        case SET_CONSULTATION_FORM:
            return { ...state, consulationForm: action.payload.consulationForm }
            case CREATE_CONSULTATION: {
                // add the new consultation to the existing list;
                if (action.payload !== errors.ERROR_IN_POSTING_CONSULTATION) {
                    // if the value of this property is an existing array
                    // push the new object at position 0
                    if (state.representableConsultations) {
                        const copyOfConsultations = [...state.representableConsultations];
                        const copyOfCustomerConsultations = [...state.customerConsultations.consultations]
                        copyOfConsultations.unshift(action.payload);
                        copyOfCustomerConsultations.push(action.payload);
                        return {
                            ...state,
                            representableConsultations: copyOfConsultations,
                            createConsultationResponse: action.payload,
                            
                            customerConsultations: {
                                ...state.customerConsultations, 
                                consultations: copyOfCustomerConsultations,
                            }
                        }
                    }
                    else {
                        // create a new array and point this property to it;
                        return {
                            ...state,
                            representableConsultations: [action.payload],
                            createConsultationResponse: action.payload,
                        }
                    }
                }
                return {
                    ...state,
                    createConsultationResponse: action.payload,
                }
            }
        case RESET_CONSULTATION_ERROR:
            return { ...state, createConsultationResponse: null }
        case FETCH_CONSULTATIONS_BY_CUSTOMER_ID:
            var updatedConsultation;
            if (state.customerConsultations && state.customerConsultations.consultations) {
                const list = [...state.customerConsultations.consultations, ...action.payload.consultations];
                updatedConsultation = {
                    total_count: action.payload.total_count,
                    page: action.payload.page,
                    consultations: list
                };
            } else {
                updatedConsultation = action.payload;
            }
            return { ...state, customerConsultations: updatedConsultation, vitalsDrawerStatus: false }
        case FETCH_CONSULTATIONS_BY_CUSTOMER_ID_FIRST_PAGE:
            return { ...state, customerConsultations: action.payload, vitalsDrawerStatus: false, currentConsultationPage: 1 }
        case GET_ALL_DOCTORS:
            return { ...state, allDoctors: action.payload }
        case RESET_CONSULTATIONS_BY_CUSTOMER_ID:
            return { ...state, customerConsultations: null, currentConsultationPage: 1 }
        case GET_CONSULTATION_BY_ID:
            return { ...state, focussedConsultation: action.payload }
        case RESET_CONSULTATION_BY_ID:
            return { ...state, focussedConsultation: null }
        case ADD_VITALS_BY_CONSULTATION_ID: {
            const { payload, consultationId, vitals } = action;
            return {
                ...state,
                addingVitalsStatus: payload,
                // iterate over existing consultations and mutate only the required consultation
                // by adding the vitals. Since the consultation is 'pending', it is guaranteed to not
                // have a prescription property.
                representableConsultationQueue: state.representableConsultationQueue
                    ? state.representableConsultationQueue.map(consult => {
                        return consult.id !== consultationId
                            ? consult
                            : _.assignIn(consult, {
                                prescription: {
                                    vitals,
                                }
                            });
                    })
                    : null,
                representableConsultations: state.representableConsultations
                    ? state.representableConsultations.map(consult => {
                        return consult.id !== consultationId
                            ? consult
                            : _.assignIn(consult, {
                                prescription: {
                                    vitals,
                                }
                            });
                    })
                    : null,
            }
        }
        case RESET_ADD_VITALS_STATUS:
            return { ...state, addingVitalsStatus: null }
        case OPEN_ADD_VITALS_DRAWER:
            return { ...state, vitalsDrawerStatus: true }
        case CLOSE_ADD_VITALS_DRAWER:
            return { ...state, vitalsDrawerStatus: false }
        case SET_PRESENTABLE_CONSULTATIONS:
            return { ...state, representableConsultations: action.payload }
        case INCREMENT_CONSULTATION_PAGE:
            return { ...state, currentConsultationPage: state.currentConsultationPage + 1 }
        case DECREMENT_CONSULTATION_PAGE:
            return { ...state, currentConsultationPage: state.currentConsultationPage - 1 }
        case RESET_CONSULTATION_PAGE_NUMBER:
            return { ...state, currentConsultationPage: 1 }
        case GET_ALL_CLINICS:
            return { ...state, allClinics: action.payload }
        case GET_CONSULTATIONS_BY_CLINIC_ID: {
            let updatedConsultationQueue;
            let consultationQPage;
            if (state.consultationQueue && state.consultationQueue.consultations && action?.payload?.consultations) {
                const list = [...state.consultationQueue.consultations, ...action.payload.consultations];
                updatedConsultationQueue = {
                    total_count: action.payload.total_count,
                    page: action.payload.page,
                    consultations: list.length > 0 ? list : [],
                };
                consultationQPage = action.payload.page.number;
            } else {
                updatedConsultationQueue = action.payload;
                consultationQPage = 1;
            }

            return { ...state, consultationQueue: updatedConsultationQueue, currentConsultationQPage: consultationQPage }
        }
        case GET_CONSULTATIONS_BY_CLINIC_ID_FIRST_PAGE:
            return { ...state, consultationQueue: action.payload, currentConsultationQPage: 1 }
        case SET_PRESENTABLE_CONSULTATION_QUEUE: {
            if (state.representableConsultationQueue && state.representableConsultationQueue.length > 0) {
                return { ...state, representableConsultationQueue: [...state.representableConsultationQueue, ...action.payload] }
            } else {
                return { ...state, representableConsultationQueue: action.payload ?? [] }
            }
        }
        case INCREMENT_CONSULTATIONQ_PAGE:
            return { ...state, currentConsultationQPage: state.currentConsultationQPage + 1 }
        case DECREMENT_CONSULTATIONQ_PAGE:
            return { ...state, currentConsultationQPage: state.currentConsultationQPage - 1 }
        case RESET_CONSULTATIONQ_PAGE_NUMBER:
            return { ...state, currentConsultationQPage: 1 }
        case RESET_CONSULTATION_QUEUE:
            return { ...state, representableConsultationQueue: [], consultationQueue: null }
        case ASSIGN_DOCTOR_BY_CONSULTATION_ID:
            return { ...state, assignDoctorStatus: action.payload }
        case RE_ASSIGN_DOCTOR_BY_CONSULTATION_ID: {
            const doctors = state.allDoctors.doctors;
            const { doctorId, consultationId, payload } = action;
            if ((doctorId !== null) && (payload !== errors.ERROR_WHILE_ASSIGNING_DOCTOR)) {
                const assignedDoctor = doctors.filter(doctor => doctor.id === doctorId)[0];
                const consultations = [...state.representableConsultationQueue];
                const updatedConsultations = consultations
                    .map(consultation => {
                        if (consultation.id !== consultationId) {
                            return consultation;
                        }
                        return {
                            ...consultation,
                            doctor: {
                                name: assignedDoctor.first_name + (assignedDoctor.last_name ?? '')},
                                qualification: assignedDoctor.qualifications[0],
                                contact_number: assignedDoctor.primary_number,
                                id: assignedDoctor.id,
                        }
                    });
                return {
                    ...state,
                    representableConsultationQueue: updatedConsultations,
                    assignDoctorStatus: payload,
                }
            }
            return { ...state, assignDoctorStatus: action.payload }
        }
        case RESET_DOCTOR_BY_CONSULTATION_ID:
            return { ...state, assignDoctorStatus: null }
        case LOG_OUT:
            return { consulationForm: null, createConsultationResponse: null, customerConsultations: null }
        case CANCEL_CONSULTATION_BY_ID: {
            const { payload, consultationId } = action;
            return {
                ...state,
                cancelConsultationStatus: payload,
                representableConsultationQueue: state.representableConsultationQueue
                    .filter(consult => {
                        return consult.id !== consultationId;
                    })
            }
        }
        case RESET_CANCEL_CONSULTATION_STATUS:
            return { ...state, cancelConsultationStatus: null }
        case CHECKOUT_CONSULTATION:
            return { ...state, consultationCheckOutStatus: action.payload }
        case RESET_CHECKOUT_CONSULTATION_STATUS:
            return { ...state, consultationCheckOutStatus: null }
        case GET_CONSULTATION_ORDER:
            return { ...state, consultationOrderDetails: action.payload }
        case RESET_CONSULTATION_ORDER_DETAILS:
            return { ...state, consultationOrderDetails: null }
        case GET_CREDIT_DETAILS_BY_FAMILY_ID:
            return { ...state, creditDetails: action.payload }
        case RESET_CREDIT_DETAILS:
            return { ...state, creditDetails: null }
        case CREATE_OFFLINE_PAYMENT:
            return { ...state, offlinePaymentStatus: action.payload }
        case RESET_OFFLINE_PAYMENT_STATUS:
            return { ...state, offlinePaymentStatus: null }
        // set up the redux state for general billing
        case consultationActionTypes.SET_GENERAL_BILLING: {
            return {
                ...state,
                billing: {
                    for: billingType.GENERAL,
                    consultation: null,
                    customer: {
                        id: '',
                    },
                    items: [],
                    invoice: null,
                    order: null,
                }
            }
        }
        case consultationActionTypes.SELECT_CONSULTATION_FOR_BILLING: {
            return {
                ...state,
                billing: {
                    for: billingType.CONSULTATION,
                    consultation: action.payload.consultation.id,
                    customer: {
                        id: action.payload.consultation.customer.id,
                        // customer object in familyDetails.allMembers contain `first_name` and `last_name`
                        // properties, but consultation.customer contains `name`
                        // so the two objects do pass equality;
                        name: action.payload.consultation.customer.name.split(' ')[0],
                    },
                    items: [],
                    invoice: action.payload.consultation.invoice.id,
                    order: action.payload.consultation.invoice?.order ?? null,
                    evital_redirect_url: action.payload.consultation.invoice?.metadata?.evital_redirect_url ?? null,
                    labTests: action.payload.consultation.prescription?.treatment?.lab_tests,
                }
            }
        }
        case consultationActionTypes.MODIFY_BILLED_CUSTOMER: {
            return {
                ...state,
                billing: {
                    ...state.billing,
                    customer: {
                        id: action.payload.customer.id,
                    },
                    items:[],
                    opd_amount_utilised:0,
                    order:null,
                    evital_redirect_url:null,
                }
            }
        }
        case consultationActionTypes.MODIFY_BILLED_SUBSCRIPTION: {
            return {
                ...state,
                billing: {
                    ...state.billing,
                    subscription: {
                        id: action.payload.subscription.subscriptionId,
                    },
                    info: {
                        subscription: action.payload.subscription.subscriptionInfo
                    }
                }
            }
        }
        case consultationActionTypes.MODIFY_LINE_ITEM: {
            const { id: idToModify, modifications } = action.payload;
            if (has(modifications, 'quantity') && (modifications?.quantity === 0)) {
                const updatedItem = {
                    ...state,
                    billing: {
                        ...state.billing,
                        items: state.billing.items.filter(item => item.id !== idToModify),
                    }
                };
                let upatedOPDAmount = 0;
                updatedItem.billing.items.forEach(item => {
                    if (item.opd_utilised && item.opd_amount) {
                        upatedOPDAmount += item.opd_amount;
                    }
                });
                Object.assign(updatedItem.billing, {opd_amount_utilised: upatedOPDAmount});
                return updatedItem;
                // return {
                //     ...state,
                //     billing: {
                //         ...state.billing,
                //         items: state.billing.items.filter(item => item.id !== idToModify),
                //     }
                // }
            } else {
                const updatedItem = {
                    ...state,
                    billing: {
                        ...state.billing,
                        items: state.billing.items.map(item => transformLineItem(item, idToModify, modifications))
                    }
                }
                let upatedOPDAmount = 0;
                updatedItem.billing.items.forEach(item => {
                    if (item.opd_utilised && item.opd_amount) {
                        upatedOPDAmount += item.opd_amount;
                    }
                });
                Object.assign(updatedItem.billing, {opd_amount_utilised: upatedOPDAmount});
                return updatedItem;
            }
        }
        case consultationActionTypes.ADD_LINE_ITEM: {
            const { itemType, item } = action.payload;
            let lineItem = null;
            if (item === null) {
                lineItem = {
                    id: `${moment().valueOf()}_timestamp`,
                    name: ``,
                    quantity: 1,
                    rate: null,
                    amount: 0,
                    type: itemType,
                    error: {
                        name: null,
                        rate: null,
                    },
                    discount: {
                        type: 'flat',
                        value: 0,
                        computedValue: 0,
                    },
                    total: 0,
                    batch_number: 'n/a',
                    external_id: null,
                    sync: false,
                };
            } else {
                const {
                    id, name, quantity, rate, error, discount, total,
                    available_quantity, prescribed_quantity, formulation,
                    batch_number, external_id, sync, opd_utilised, opd_amount
                } = item;
                lineItem = {
                    id,
                    name,
                    quantity,
                    available_quantity,
                    prescribed_quantity,
                    external_id,
                    formulation,
                    rate,
                    amount: item.rate * item.quantity - getDiscountOnLineItem(item),
                    error,
                    opd_utilised,
                    opd_amount,
                    discount,
                    total,
                    type: itemType,
                    batch_number: batch_number ?? 'n/a',
                    sync: sync ?? false,
                }
                lineItem.discount.computedValue = getDiscountOnLineItem(item);
                // set errors to the item if necessary
                if (lineItem.name.length === 0) lineItem.error.name = "Invalid name!";
                if (Number(lineItem.rate) === 0 && lineItem.available_quantity !== 0 && lineItem.type !== itemTypes.EVITAL_MEDICINE) {
                    lineItem.error.rate = "Invalid rate!";
                }
                else lineItem.error = {
                    rate: null,
                    name: null,
                };
            }
            lineItem.uiTrackingId = v4();
            const newItemArray = state.billing.items.slice();
            newItemArray.splice(state.billing.items.length, 0, lineItem);
            let upatedOPDAmount = 0;
            newItemArray.forEach(item => {
                if (item.opd_utilised && item.opd_amount) {
                    upatedOPDAmount += item.opd_amount;
                }
            });
            return {
                ...state,
                billing: {
                    ...state.billing,
                    items: newItemArray,
                    opd_amount_utilised: upatedOPDAmount
                }
            }
        }
        case consultationActionTypes.ADD_MULTIPLE_LINE_ITEMS: {
            const existingItemArray = state.billing.items.slice();
            const newItemArray = [...existingItemArray, ...action.payload.items];
            return {
                ...state,
                billing: {
                    ...state.billing,
                    items: newItemArray,
                }
            }
        }
        case consultationActionTypes.FLUSH_LINE_ITEMS_OF_TYPE: {
            const existingItemArray = state.billing.items.slice();
            const filteredLineItemArray = existingItemArray.filter(item => item.type !== action.payload.type);
            return {
                ...state,
                billing: {
                    ...state.billing,
                    items: filteredLineItemArray,
                }
            }
        }
        case consultationActionTypes.OPD_AMOUNT_UTILISED: {
            return {
                ...state,
                billing: {
                    ...state.billing,
                    // opd_amount_utilised: action.payload.amount
                }
            }
        }
        case orderActionTypes.CREATE_ORDER_FOR_FAMILY_ID_SUCCESS: {
            const orderId = action.payload.order.id;
            const evitalRxUrl = action.payload.evital_redirect_url;
            return {
                ...state,
                billing: {
                    ...state.billing,
                    order: {
                        id: orderId,
                    },
                    evital_redirect_url: evitalRxUrl,
                }
            }
        }
        case orderActionTypes.CREATE_ORDER_FOR_FAMILY_ID_FAILURE: {
            return {
                ...state,
                billing: {
                    ...state.billing,
                    order: null,
                    evital_redirect_url: null,
                    invoice: null,
                }
            }
        }
        case orderActionTypes.ADD_DEFAULT_EVITAL_LINE_ITEM_TO_ORDER_REQUEST: {
            return {
                ...state,
                billing: {
                    ...state.billing,
                    evital_redirect_fetch_call: true,
                }
            }
        }
        case orderActionTypes.ADD_DEFAULT_EVITAL_LINE_ITEM_TO_ORDER_SUCCESS: {
            return {
                ...state,
                billing: {
                    ...state.billing,
                    evital_redirect_url: action.payload.evital_redirect_url,
                    evital_redirect_fetch_call: false,
                }
            }
        }
        case consultationActionTypes.SET_FILTER_DATE_TO: {
            return {
                ...state,
                completed: {
                    ...state.completed,
                    to: action.payload,
                }
            }
        }
        case consultationActionTypes.SET_FILTER_DATE_FROM: {
            return {
                ...state,
                completed: {
                    ...state.completed,
                    from: action.payload,
                }
            }
        }
        case consultationActionTypes.SEARCH_ITEM_FOR_BILLING_REQUEST: {
            return {
                ...state,
                itemSearch: {
                    isLoading: true,
                    items: null,
                    error: false,
                }
            }
        }
        case consultationActionTypes.SEARCH_ITEM_FOR_BILLING_SUCCESS: {
            return {
                ...state,
                itemSearch: {
                    isLoading: false,
                    items: action.payload,
                    error: false,
                }
            }
        }
        case consultationActionTypes.SEARCH_ITEM_FOR_BILLING_FAILURE: {
            return {
                ...state,
                itemSearch: {
                    isLoading: false,
                    items: null,
                    error: true,
                }
            }
        }
        case consultationActionTypes.GENERATE_BILL_REQUEST: {
            return {
                ...state,
                bill: {
                    isLoading: true,
                    url: null,
                }
            }
        }
        case consultationActionTypes.GENERATE_BILL_SUCCESS: {
            return {
                ...state,
                bill: {
                    isLoading: false,
                    url: action.payload.url,
                }
            }
        }
        case consultationActionTypes.GENERATE_BILL_FAILURE: {
            return {
                ...state,
                bill: {
                    isLoading: false,
                    url: null,
                }
            }
        }
        case consultationActionTypes.GET_BILLING_HISTORY_REQUEST: {
            const existingList = state?.history?.list;
            return {
                ...state,
                history: {
                    isLoading: true,
                    list: existingList?.length > 0 ? existingList : [],
                    count: null,
                }
            }
        }
        case consultationActionTypes.GET_BILLING_HISTORY_SUCCESS: {
            const responseData = action.payload;
            if (responseData.data === null) {
                return {
                    ...state,
                    history: {
                        ...state.history,
                    },
                }
            } else {
                const newList = [...state.history.list, ...responseData.data];
                return {
                    ...state,
                    history: {
                        isLoading: false,
                        list: newList,
                        count: responseData.count,
                    }
                }
            }
        }
        case consultationActionTypes.GET_BILLING_HISTORY_FAILURE: {
            const responseData = action.payload;
            return {
                ...state,
                history: {
                    isLoading: false,
                    list: responseData.data,
                    count: responseData.count,
                }
            }
        }
        case consultationActionTypes.GET_PRODUCT_CATEGORIES_REQUEST: {
            return {
                ...state,
                productCategories: {
                    isLoading: true,
                    categories: null,
                    status: null,
                }
            }
        }
        case consultationActionTypes.GET_PRODUCT_CATEGORIES_SUCCESS: {
            const productCategories = action.payload;
            return {
                ...state,
                productCategories: {
                    isLoading: false,
                    categories: [...productCategories, itemTypes.OTHER, itemTypes.GENERIC],
                    status: 'success',
                }
            }
        }
        case consultationActionTypes.GET_PRODUCT_CATEGORIES_FAILURE: {
            return {
                ...state,
                productCategories: {
                    isLoading: false,
                    categories: null,
                    status: 'failure'
                }
            }
        }
        case consultationActionTypes.RESET_BILLING: {
            return {
                ...state,
                billing: null,
                itemSearch: null,
                bill: null,
                // history does not need to be reset here
            }
        }
        case consultationActionTypes.RESET_BILLING_HISTORY: {
            return {
                ...state,
                history: {
                    isLoading: false,
                    customer: null,
                    list: [],
                    count: null,
                },
                // history does not need to be reset here
            }
        }
        case consultationActionTypes.UNLINK_EVITAL_SALE_ORDER: {
            return {
                ...state,
                billing: {
                    ...state.billing,
                    evital_redirect_url: null,
                }
            }
        }
        case CREATE_VISIT: {
            // add the new consultation to the existing list;
            return {
                ...state,
                createVisitResponse: action.payload,
            }
        }
        case RESET_VISIT_BOOKING_ERROR:
            return { ...state, createVisitResponse: null }
        default:
            return state;
    }
};

export default consultationReducer;


