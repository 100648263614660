import { color } from '../../../constants/colors';
const styles = () => ({
    header: {
        display: 'flex',
        padding: '30px 24px 10px',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${color.ROYAL_ORANGE}`
    },
    bold: {
        fontWeight: 500
    },
    closeIcon: {
        cursor: 'pointer'
    },
    clinicContainer: {
        padding: '16px 24px 24px',
    },
    saveChangesButton: {
        backgroundColor: color.ROYAL_ORANGE,
        color: color.WHITE,
        width: '90%',
        position: 'absolute',
        bottom: '28px',
        textTransform: 'capitalize',
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        minHeight: '36px',
        '&:hover': {
            backgroundColor: color.ROYAL_ORANGE,
        },
        '&:disabled': {
            opacity: '70%',
            color: color.WHITE,
        }
    },
    nammaClinicCenters: {
        width: '100%',
    },
    hidden: {
        display: 'none'
    },
    fileContainer: {
        minHeight: '10%',
        margin: '0 24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: `2px dashed ${color.BRIGHT_GRAY}`,
        borderRadius: '12px',
        padding: '24px 0 0',
        marginTop: '16px',
        transition: 'all 0.25s ease'
    },
    uploadText: {
        margin: '16px auto'
    },
    fileUpload: {
        fontWeight: 500,
        margin: '16px 24px 0'
    },
    browse: {
        color: color.ROYAL_ORANGE,
        textDecoration: 'underline',
        fontWeight: 700,
        cursor: 'pointer'
    },
    onlyPDF: {
        color: color.INTERNATIONAL_ORANGE,
        fontWeight: 700
    },
    fileUploadDetailContainer: {
        height: '10%',
        margin: '0 24px',
        display: 'flex',
        alignItems: 'center',
        border: `2px dashed ${color.BRIGHT_GRAY}`,
        borderRadius: '12px',
        padding: '0 16px 0',
        marginTop: '16px'
    },
    fileUploadBox: {
        display: 'flex',
        flexDirection: 'column',
        flex: '0.8'
    },
    fileDetails: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    fileUploadDetails: {
        display: 'flex',
    },
    fileUploadAction: {
        color: color.INTERNATIONAL_ORANGE,
        fontSize: '12px',
        fontWeight: '600'
    },
    pdfIcon: {
        flex: '0.2'
    },
    fileSizeProgress: {
        color: color.GREY,
        fontSize: '12px'
    },
    filePercentageProgress: {
        color: color.ROYAL_ORANGE,
        fontSize: '12px'
    },
    colorPrimary: {
        background: color.oranges[100],
    },
    barColorPrimary: {
        background: color.ROYAL_ORANGE,
    },
    errorInfo: {
        color: color.INTERNATIONAL_ORANGE,
        fontSize: '10px',
        marginLeft: '4px',
        lineHeight: '1.3'
    },
    flex: {
        display: 'flex'
    },
    browseCaption: {
        fontSize: '11px',
        color: color.greys[100],
        fontWeight: 'normal',
        margin: '0px 2vh 2vh 2vh',
        textAlign: 'center',
    },
    buttonGroup_root: {
        margin: '2vh 3vh 0px 3vh',
        width: 'calc(100% - 6vh)',
        padding: 'auto'
    },
    buttonGroup_children: {
        borderRadius: '12px',
    },
    documentRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'calc(100% - 3vh)',
        height: '50%',
    },
    progressSubRow: {
        marginTop: '4px',
    },
    documentSubRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '4px',
    },
    documentName: {
        fontSize: '12px',
        fontFamily: 'Rubik',
        color: color.BLACK,
    },
    // colorPrimary: {
    //     background: color.greys[400],
    // },
    colorPrimary_failure: {
        background: color.reds[100],
    },
    // barColorPrimary: {
    //     background: color.ROYAL_ORANGE,
    // },
    documentColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        flex: '1 auto',
        paddingLeft: '1vh',
        width: '(100% - 1vh)'
    },
    previewColumn: {
        height: '100%',
        width: 'max-content',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '1vh',
    },
    documentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 'calc(100% - 6vh)',
        paddingBottom: '1vh',
        borderRadius: '8px',
        marginTop: '1vh',
        margin: 'auto',
        transition: 'border 1s',
        gap: '1vh',
    },
    selectedFilesContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1vh',
    },
    documentContainer_failed: {
        border: `1px solid ${color.reds[100]}`
    },
    fileSizeLabel: {
        fontFamily: 'Rubik',
        fontSize: '12px',
        color: color.greys[100],
    },
    typeLabel: {
        fontFamily: 'Rubik',
        fontSize: '14px',
        color: color.greys[100],
    },
    typeValue: {
        fontFamily: 'Rubik',
        fontSize: '14px',
        color: color.ROYAL_ORANGE,
    },
    progressLabel: {
        fontFamily: 'Rubik',
        fontSize: '12px',
        color: color.ROYAL_ORANGE,
    },
    closePreviewBtn: {
        height: '20px',
        width: '16px',
        fontFamily: 'Rubik',
        fontSize: '14px',
        color: color.WHITE,
        borderRadius: '16px',
        border: 'none',
        cursor: 'pointer',
        background: color.BLACK
    },
    previewIcon: {
        scale: '1.5',
    }
})

export default styles;