import axios from 'axios';

import config from '../config';
import { consultationStatus } from '../../src/common/constants';

export const postConsultation = async (ACCESS_TOKEN, consultationPayload) => {
  const url = config.apiURL + `/v3/consultations`;
  // Until now the payload passed down is an Object;
  // it must be added to a FormData object to be encoded correctly;
  const consultationFormData = new FormData();
  for (const property in consultationPayload) {
    consultationFormData.append(property, consultationPayload[property]);
  }
  const options = {
    headers: {
      'content-type': 'multipart/form-data',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,
    },
    method: 'POST',
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
    data: consultationFormData,
  };
  const response = await axios(url, options);
  return response;
};

export const postVisit = async (ACCESS_TOKEN, consultationPayload, clinicId) => {
  const url = config.apiURL + `/v1/clinics/${clinicId}/visits`;
  // Until now the payload passed down is an Object;
  // it must be added to a FormData object to be encoded correctly;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,
    },
    method: 'POST',
    timeout: 10000,
    responseType: 'json',
    data: consultationPayload,
  };
  const response = await axios(url, options);
  return response;
};

export const getConsultationDetails = async (ACCESS_TOKEN, customerId, page) => {
  const url = config.apiURL + `/v2/customers/${customerId}/consultations?status=${consultationStatus.ASSIGNED}&status=${consultationStatus.COMPLETED}&status=${consultationStatus.PENDING}&page=${page}`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,
    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.get(url, options);
  return response;
};

/**
 * 
 * @param {String} ACCESS_TOKEN the JWT to authenticate the request
 * @param {Object} documentData the document to be uploaded
 * @param {Function} reportUploadProgress a callback to report upload progress
 * where required
 */
export const uploadSupportingDocument = async (ACCESS_TOKEN, documentData, reportUploadProgress = null) => {
  const url = config.apiURL + '/v1/health-vault';
  const data = new FormData();
  // Until now the payload passed down is an Object;
  // it must be added to a FormData object to be encoded correctly;
  for (const property in documentData) {
    data.append(property, documentData[property]);
  }
  const options = {
    headers: {
      'content-type': 'multipart/form-data',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,
    },
    method: 'POST',
    timeout: 10000,
    onUploadProgress: (progressEvent) => {
      const { loaded } = progressEvent;
      if (reportUploadProgress) {
        reportUploadProgress(Math.min(documentData.health_record_file.size, loaded), false);
      }
    },
    data,
  };
  try {
    const response = axios(url, options);
    return response;
  } catch (e) {
    reportUploadProgress(100, true);
  }
}

export const getDoctorList = async (ACCESS_TOKEN, bool) => {
  let url;
  if (bool) {
    url = config.apiURL + `/v1/doctors?availability=true`;
  } else {
    url = config.apiURL + `/v1/doctors`;
  }
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,

    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.get(url, options);
  return response;
};

export const getConsultationByConsultationId = async (ACCESS_TOKEN, consultationId) => {
  const url = config.apiURL + `/v2/consultations/${consultationId}`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,

    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.get(url, options);
  return response;
};

export const createVitalsByConsultationId = async (ACCESS_TOKEN, body, consultationId) => {
  const url = config.apiURL + `/v1/consultations/${consultationId}/vitals`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,
    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.post(url, body, options);
  return response;
};

export const getClinicList = async (ACCESS_TOKEN) => {
  const url = config.apiURL + `/v1/clinics`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,

    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.get(url, options);
  return response;
};

export const getConsultationsByClinic = async (ACCESS_TOKEN, clinicId, consultationStatus, page, sortDir, fromDate, toDate, sortKeyProp, dateKeyStartValue, dateKeyEndValue) => {
  const url = config.apiURL + `/v1/clinics/${clinicId}/consultations?${dateKeyStartValue}=${fromDate}&${dateKeyEndValue}=${toDate}&status=${consultationStatus}&page=${page}&size=10&sortKey=${sortKeyProp}&sortDir=${sortDir}`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,

    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.get(url, options);
  return response;
}

export const assignDoctor = async (ACCESS_TOKEN, consultationId, doctorId) => {
  const url = config.apiURL + `/v2/consultations/${consultationId}/assign`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,

    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.post(url, { doctor: { id: doctorId } }, options);
  return response;
}

export const reAssignDoctor = async (ACCESS_TOKEN, consultationId, doctorId) => {
  const url = config.apiURL + `/v2/consultations/${consultationId}/reassign`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,

    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.post(url, { doctor: { id: doctorId } }, options);
  return response;
}

export const cancelConsultationByConsultationId = async (ACCESS_TOKEN, consultationId) => {
  const url = config.apiURL + `/v2/consultations/${consultationId}/cancel`
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,

    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
    url,
    method: 'POST',
  };
  const response = await axios(options);
  return response;
}

export const checkoutConsultationById = async (ACCESS_TOKEN, consultationId) => {
  const url = config.apiURL + `/v2/consultations/${consultationId}/pay-invoice`
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,

    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.post(url, null, options);
  return response;
}

export const addWhatsAppConcent = async (ACCESS_TOKEN, consentData, customerId) => {
  const url = `${config.apiURL}/v3/customers/${customerId}/preferences`
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,

    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.post(url, consentData, options);
  return response;
}

export const searchItemsForBilling = async (ACCESS_TOKEN, item, category, clinic) => {
  const base64encodedKeyword = window.btoa(item);
  let url = config.apiURL + `/v1/operations/products?keyword=${base64encodedKeyword}&clinicId=${clinic}`;
  if (category != null) {
    url += `&category=${category}`;
  }
  const options = {
    url,
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,

    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios(options);
  return response.data;
};

export const generateBilling = async (ACCESS_TOKEN, orderId, payload) => {
  const url = config.apiURL + `/v3/orders/${orderId}/billing`;
  const data = payload;
  const options = {
    url,
    data,
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,

    },
    method: 'POST',
    timeout: 60000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios(options);
  return response;
};

export const getBillingHistory = async (ACCESS_TOKEN, familyId, orderId, generatedOn, page, size) => {
  let url = config.apiURL + `/v3/orders/billing?familyId=${familyId}&page=${page}&size=${size}`;
  if (orderId) {
    url = url + `&orderId=${orderId}`;
  }
  if (generatedOn) {
    url = url + `&generatedOn=${generatedOn}`;
  }
  const options = {
    url,
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,

    },
    method: 'GET',
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  try {
    const response = await axios(options);
    return response;
  } catch (e) {
  }
};

export const getProductCategoriesList = async (ACCESS_TOKEN) => {
  const url = config.apiURL + `/v1/operations/products/categories`;
  const options = {
    url,
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,

    },
    method: 'GET',
    timeout: 20000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  try {
    const response = await axios(options);
    return response;
  } catch (e) {
    throw Error("An error occured in fetching product categories!");
  }
};