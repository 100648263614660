import { CREATE_ONBOARDING_RESPONSE, RESET_UPLOAD_ONBOARD_FILE, CREATE_ONBOARDING_REQUEST } from '../actions/types';

const INITIAL_STATE = {
    createOnboardingResponse: null,
    loadingOnboardingResponse: false,
};

const onboardingReducer = (state = { INITIAL_STATE }, action) => {
    switch (action.type) {
        case CREATE_ONBOARDING_REQUEST:
            return { ...state, createOnboardingResponse: null, loadingOnboardingResponse: true }
        case CREATE_ONBOARDING_RESPONSE:
            return { ...state, createOnboardingResponse: action.payload, loadingOnboardingResponse: false }
        case RESET_UPLOAD_ONBOARD_FILE:
            return { ...state, createOnboardingResponse: null, loadingOnboardingResponse: false }
        default:
            return state;
    }
};

export default onboardingReducer;

