import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
const filter = createFilterOptions();

export default function AutoComplete(props) {
    const [value, setValue] = React.useState(null);
    const autoCompleteOptions = props.autoCompleteOptions;

    const { labelTitle, handelInput } = props;

    return (
        <Autocomplete
            value={value}
            onChange={(event, newValue) => {
                handelInput(newValue)
                if (typeof newValue === 'string') {
                    setValue(newValue);
                } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setValue(newValue.inputValue);
                } else {
                    setValue(newValue);
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                    filtered.push(params.inputValue);
                }
                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={autoCompleteOptions}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option;
            }}
            renderOption={(option) => option}
            freeSolo
            renderInput={(params) => (
                <TextField {...params} label={labelTitle} />
            )}
        />
    );
}
