import { HEADQUARTERS_ID } from "../config";

const accessRole = {
	HA: 'ha',
	SALES: 'sales',
	CUSTOMER: 'customer',
	HEALTH_MANAGER: 'health-manager',
	OPS_MANAGER: 'ops-manager',
};

const apiResponse = {
	CONSULTATION_CREATED: 201,
	PRIMARY_CUSTOMER_CREATED: 201,
	FREE_SUBSCRIPTION_CREATED: 201,
	DOCTOR_ASSIGNED: 204,
	CONSULTATION_CANCELLED: 204,
	PAY_CONSULTATION_INVOICE: 200,
	SUBSCRIPTION_CREATED: 201,
}

const businessIds = [
	{
		id: "621f279ae8da7febcf8edc7a",
		name: "Clinikk Smart Diamond"
	},
	{
		id: "5ebe47d27fd6b54d6efed6e9",
		name: "Rapido Bike",
	},
	{
		id: "61c14a57291812994bd6a58b",
		name: "Ola CPWA",
	},
	{
		id: "63b6ea5c6b1803527a752e42",
		name: "Rapido Bike - 2",
	},
	{
		id: "6440ea54f4ecbc7b4c5fc8c6",
		name: "Bangalore Retail Smart Plans",
	},
	{
		id: "6033a3eb922bee77e6eddb85",
		name: "CLK B2C Remote Fullstack"
	},
	{
		id: "6033a41a922bee77e6eddb86",
		name: "CLK B2C Fullstack"
	},
	{
		id: "603f2b9683382e6f47d3de9d",
		name: "Clinikk Android"
	},
	{
		id: "61645e3711b37c85f5809b4b",
		name: "Namma Clinikk"
	},
	{
		id: "61bd737236e5d5686b74a69d",
		name: "Clinikk Health Hub"
	},
	{
		id: "646c6e1ca86721552172c233",
		name: "Bangalore Affiliate Smart Plans",
	},
	{
		id: "64706ee03f4e115da8465742",
		name: "Jarvis Training Plans",
	},
	{
		id: "64819b2fed164f097445a6c2",
		name: "Remote Fullstack Plans",
	},
	{
		id: "64cc91301a3a3f127843c1e3",
		name: "(Website)Website",
	},
	{
		id: "64ec7cd617ea89a82361f2e9",
		name: "Bharatha Transport Association Group",
	},
];

const qabusinessIds = [
	{
		id: "64cc91301a3a3f127843c1e3",
		name: "(Website)Website",
	},
	{
		id: "63b6ea5c6b1803527a752e42",
		name: "Rapido Bike - 2",
	}
];

const errorNotification = {
	SECONDS: 10,
}

const mandateStatus = {
	ACKNOWLEDGED: 'acknowledged',
	ACTIVE: 'active',
	CANCELLED: 'cancelled',
	FAILED: 'failed',
	EXPIRED: 'expired',
	INITIATED: 'initiated',
	PENDING: 'pending',
	PENDING_SUBMISSION: 'pending_submission',
	SUBMITTED: 'submitted',
	WITHDRAWN: 'withdrawn',
	REJECTED: 'rejected',
	CREATED: 'created',
};

const subscriptionStatus = {
	DRAFT: 'draft',
	PENDING: 'pending',
	ACTIVE: 'active',
	CANCELLED: 'cancelled',
	PLAN_CHANGED: 'plan_changed',
	PLAN_CHANGE_PENDING: 'plan_change_pending',
	WITHDRAWN: 'withdrawn',
	ONBOARDING: 'onboarding',
	ENDORSEMENT: 'endorsement',
};

const policyStatus = {
	ACTIVE: 'active',
	PENDING: 'pending',
};

const invoiceStatus = {
	PAID: 'paid',
	UNPAID: 'unpaid',
	PENDING_ON_NACH: 'pending_on_nach',
	EXPIRED: 'expired',
	CANCELLED: 'cancelled',
	WITHDRAWN: 'withdrawn',
};

const consultationStatus = {
	PENDING: 'pending',
	ASSIGNED: 'assigned',
	COMPLETED: 'completed',
	CANCELLED: 'cancelled',
};

const offlinePaymentSources = {
	CASH: 'cash',
	POS: 'card',
	QR_CODE: 'qr_code',
	WALLET: 'wallet',
	OPD: 'opd'
};

const paymentSources = {
	OFFLINE: 'offline',
	CREDIT: 'credit',
	RAZORPAY: 'razorpay',
	PAYTM: 'paytm',
	LOTUSPAY: 'lotuspay',
	PAYTM_SUBSCRIPTION: 'paytm_subscription',
	OPD: 'opd',
};

const paymentMultiplier = {
	MONTHLY: 'monthly',
	QUARTERLY: 'quarterly',
	SEMI_ANNUALLY: 'semi_annually',
	ANNUALLY: 'annually',
};

const discountType = {
	FLAT: 'flat',
	PERCENTAGE: 'percentage',
};

const paymentMultiplierQuantity = {
	MONTHLY: 1,
	QUARTERLY: 3,
	SEMI_ANNUALLY: 6,
	ANNUALLY: 12,
};

const consultationType = {
	PHYSICAL: 'physical',
	TELE: 'tele',
}

const callType = {
	INCOMING: 'incoming',
	OUTGOING: 'outgoing'
}

const insuranceMemberRelationship = {
	PRIMARY: 'primary',
	SPOUSE: 'spouse',
	SON: 'son',
	DAUGHTER: 'daughter',
	FATHER: 'father',
	MOTHER: 'mother',
};

const insuranceMemberRelationshipList = [
	insuranceMemberRelationship.SPOUSE,
	insuranceMemberRelationship.SON,
	insuranceMemberRelationship.DAUGHTER,
	insuranceMemberRelationship.FATHER,
	insuranceMemberRelationship.MOTHER,
]

const paymentActionTypes = {
	CREATE_OFFLINE_PAYMENT_REQUEST: 'CREATE_OFFLINE_PAYMENT_REQUEST',
	CREATE_OFFLINE_PAYMENT_SUCCESS: 'CREATE_OFFLINE_PAYMENT_SUCCESS',
	CREATE_OFFLINE_PAYMENT_FAILURE: 'CREATE_OFFLINE_PAYMENT_FAILURE',
	RESET_OFFLINE_PAYMENT: 'RESET_OFFLINE_PAYMENT',
}


const whatsAppConsent = {
	GRANTED: 'granted',
	DENIED: 'denied',
};

const messageChannel = {
	SMS: 'sms',
	WHATSAPP: 'whatsapp',
};

const tncStatus = {
	SUCCESS: 'success',
	FAILED: 'failed',
	IN_REVIEW: 'in_review',
	PENDING: 'pending',
};

const fullNameRegex = /^[A-Za-z ]+$/;

const endorsementType = {
	FRESH: 'fresh',
	ERROR: 'error',
	RENEWAL: 'renewal',
};

const careSnackbarStatus = {
	SUCCESS: 'success',
	ERROR: 'error',
};

const addonCategories = {
	HEALTH_INSURANCE: 'health_insurance',
	ACCIDENTAL_INSURANCE: 'accidental_insurance',
	SUPER_TOPUP_10L: 'super_topup_10_l',
	SUPER_TOPUP_50L: 'super_topup_50_l',
	PRIMARY_CARE: 'primary_care',
}

export {
	accessRole,
	apiResponse,
	errorNotification,
	businessIds,
	qabusinessIds,
	mandateStatus,
	subscriptionStatus,
	policyStatus,
	invoiceStatus,
	consultationStatus,
	offlinePaymentSources,
	paymentSources,
	paymentMultiplier,
	discountType,
	paymentMultiplierQuantity,
	consultationType,
	callType,
	HEADQUARTERS_ID,
	insuranceMemberRelationship,
	insuranceMemberRelationshipList,
	paymentActionTypes,
	whatsAppConsent,
	messageChannel,
	fullNameRegex,
	endorsementType,
	careSnackbarStatus,
	tncStatus,
	addonCategories
};
