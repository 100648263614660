import React, { Component } from "react";
import { round } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import DateFnsUtils from "@date-io/date-fns";
import { color } from "../../constants/colors";
import MuiAlert from "@material-ui/lab/Alert";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import {
  Box,
  Typography,
  Button,
  MenuItem,
  Select,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";

import style from "./styles";
import { createMuiTheme } from "@material-ui/core";
import { orange } from "@material-ui/core/colors";

import { getCareEndorsementsFilesService } from "../../clients/careUploadDocumentService";
import { getDownloadableFilesService } from "../../clients/careUploadDocumentService";
import { getUploadedByDataService } from "../../clients/careUploadDocumentService";
import { ReactComponent as CareUploadIcon } from "../../assets/icons/careUploadicons/carecsvfileicon.svg";
import { ReactComponent as SendIcon } from "../../assets/icons/careUploadicons/sendIcon.svg";
import { ReactComponent as FilterEllipseIcon } from "../../assets/icons/careUploadicons/filterEllipseIcon.svg";
import { ReactComponent as ChevronDown } from "../../assets/icons/careUploadicons/chevronDown.svg";
import { ReactComponent as NoResultIcon } from "../../assets/icons/careUploadicons/noResultIcon.svg";
import { ReactComponent as AlertIcon } from "../../assets/icons/careUploadicons/alertIcon.svg";
import { ReactComponent as DownloadDisabledIcon } from "../../assets/icons/careUploadicons/downloadDisabledIcon.svg";
import { ReactComponent as DownloadEnabledIcon } from "../../assets/icons/careUploadicons/downloadEnabledIcon.svg";

const materialTheme = createMuiTheme({
  palette: {
    primary: orange,
  },
});

const { careSnackbarStatus } = require("../../common/constants");

class ShowHistoryCard extends Component {
  constructor() {
    super();
    this.state = {
      showFilterSection: false,
      showHistoryDocuments: [],
      uploadedBy: "",
      startDate: null,
      endDate: null,
      uploadedByList: [],
      isLoading: false,
      dateError: false,
    };
  }

  setToDefaultState() {
    this.setState({
      showFilterSection: false,
    });
  }

  componentDidMount() {
    this.getHistoryDocuments();
  }

  getHistoryDocuments(uploadedBy, startDateUnixFormat, endDateUnixFormat) {
    const { auth } = this.props;
    const { showFilterSection } = this.state;
    const ACCESS_TOKEN = auth.authDetails.data.access_token;
    const endorse_type = this.props.case;

    this.setState({ isLoading: true });
    if (uploadedBy || startDateUnixFormat) {
      this.setState({ showFilterSection: !showFilterSection });
    }

    getCareEndorsementsFilesService(
      ACCESS_TOKEN,
      endorse_type,
      uploadedBy,
      startDateUnixFormat,
      endDateUnixFormat
    ).then((response) => {
      if (!response.message) {
        this.setState({
          showHistoryDocuments: response.result,
          isLoading: false,
        });
      } else {
        this.setState({
          showSnackBar: true,
          snackbarStatus: careSnackbarStatus.ERROR,
          snackbarMessage: response.message,
          isLoading: false,
        });
      }
    });
  }

  handleOnDownloadClick = (fileType, userId, fileName) => {
    const { auth } = this.props;
    const ACCESS_TOKEN = auth.authDetails.data.access_token;

    getDownloadableFilesService(ACCESS_TOKEN, fileType, userId).then(
      (response) => {
        if (!response.message) {
          var hiddenElement = document.createElement("a");
          hiddenElement.href =
            "data:text/csv;charset=utf-8," + encodeURI(response);
          hiddenElement.download = `${fileName}.csv`;
          hiddenElement.click();
        } else {
          this.setState({
            showSnackBar: true,
            snackbarStatus: careSnackbarStatus.ERROR,
            snackbarMessage: response.message,
          });
        }
      }
    );
  };

  getCircularProgress = () => {
    return (
      <CircularProgress
        style={{
          color: color.greens[100],
          left: "50%",
          top: "55%",
          position: "absolute",
        }}
      />
    );
  };

  renderHistoryList = () => {
    const { classes } = this.props;
    const { showHistoryDocuments, showSnackBar, snackbarMessage } = this.state;

    if (showSnackBar) {
      return (
        <>
          <Snackbar
            open={showSnackBar}
            style={{
              width: "62%",
              marginLeft: "16px",
            }}
            onClose={() => {
              this.setState((prevState) => ({
                showSnackBar: !prevState.showSnackBar,
                snackbarMessage: "",
                snackbarStatus: "",
              }));
            }}
            autoHideDuration={2400}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <MuiAlert
              style={{
                width: "100%",
                background: "red",
                color: color.WHITE,
                borderRadius: "8px",
                fontFamily: "Rubik",
                marginLeft: "27px",
                marginBottom: "17px",
              }}
              icon={<SendIcon fontSize="inherit" />}
            >
              {snackbarMessage}
            </MuiAlert>
          </Snackbar>
        </>
      );
    }

    if (showHistoryDocuments.length > 0) {
      return showHistoryDocuments?.map((doc) => {
        let uploadedAtDate = "";
        const uploadedAtDateModified = moment(doc.uploaded_at).calendar();
        const uploadedAtTime = moment(doc.uploaded_at).format("LT");

        if (uploadedAtDateModified.includes("Yesterday")) {
          uploadedAtDate = "Yesterday";
        } else if (uploadedAtDateModified.includes("Today")) {
          uploadedAtDate = "Today";
        } else {
          uploadedAtDate = moment(doc.uploaded_at).format("DD/MM/YYYY");
        }

        const statusBackgroundColor =
          doc.status === "in progress" || doc.status === 'in_progress' ? "#FFF1D9" : color.greens[200];
        const statusColor =
          doc.status === "in progress" || doc.status === 'in_progress' ? "#FFA300" : color.greens[100];
        const enableButton = doc.status === "in progress" ? true : false;

        return (
          <div key={doc.id} style={{ padding: "24px 24px 12px 24px" }}>
            <Typography
              style={{
                fontSize: "12px",
                color: color.GREY,
                fontFamily: "Rubik",
                fontStyle: "normal",
                fontWeight: 400,
                marginBottom: "14px",
                lineheight: "14px",
              }}
            >
              {uploadedAtDate} • {uploadedAtTime} • {doc.uploaded_by.name}
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                position: "relative",
              }}
            >
              <CareUploadIcon style={{ alignSelf: "center" }} />
              <div className={classes.fileFrame}>
                <div className={classes.fileNameSuccess}>
                  {doc.uploaded_file.name}
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontfamily: "Rubik",
                      fontStyle: "normal",
                      fontWeight: 400,
                      minWidth: "64px",
                      minHeight: "14px",
                      lineheight: "14px",
                      color: `${statusColor}`,
                      position: "absolute",
                      top: "0px",
                      right: "0px",
                      background: `${statusBackgroundColor}`,
                      padding: "2px 4px",
                      borderRadius: "4px",
                    }}
                  >
                    {doc.status}
                  </Typography>
                </div>
                <div>
                  <span className={classes.fileDetailsSize}>
                    {round(doc.uploaded_file.size / 1024, 2)} MB
                  </span>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                target="_blank"
                className={classes.downloadButton}
                variant="outlined"
                startIcon={<DownloadEnabledIcon />}
                onClick={() =>
                  this.handleOnDownloadClick(
                    "sheet",
                    doc.id,
                    doc.uploaded_file.name
                  )
                }
              >
                Sheet
              </Button>
              <Button
                target="_blank"
                className={classes.downloadButton}
                variant="outlined"
                startIcon={
                  doc.status === "in progress" ? (
                    <DownloadDisabledIcon />
                  ) : (
                    <DownloadEnabledIcon />
                  )
                }
                disabled={enableButton}
                onClick={() =>
                  this.handleOnDownloadClick(
                    "report",
                    doc.id,
                    doc.uploaded_file.name
                  )
                }
              >
                Report
              </Button>
            </div>
            <div
              style={{
                height: "1px",
                width: "100%",
                backgroundColor: "#CCCCCC",
                marginTop: "12px",
              }}
            />
          </div>
        );
      });
    }
    if (!showHistoryDocuments.length) {
      return (
        <>
          <div className={classes.noDataParent}>
            <div className={classes.noDataParentIcon}>
              <NoResultIcon />
            </div>
            <div className={classes.noDataParentText}>No Results Found</div>
          </div>
        </>
      );
    }
  };

  sendFilteredRequest = () => {
    const { uploadedBy, startDate, endDate } = this.state;

    if (endDate && !startDate) {
      this.setState({
        dateError: true,
        snackbarMessage: "Select both Start Date and End Date.",
      });
    } else {
      const startDateUnixFormat = startDate?.setHours(0, 0, 0, 0);
      const endDateUnixFormat = endDate?.setHours(23, 59, 59, 999);
      if (startDateUnixFormat > endDateUnixFormat && endDate) {
        this.setState({
          dateError: true,
          snackbarMessage: "Start Date should be less than End Date.",
        });
      } else {
        this.getHistoryDocuments(
          uploadedBy,
          startDateUnixFormat,
          endDateUnixFormat
        );
      }
    }
  };

  handleUploadedByChange = (e) => {
    this.setState({ uploadedBy: e.target.value });
  };
  handleDateChange = (e, key) => {
    this.setState({ [key]: e });
  };

  renderFilterSection = () => {
    const { classes } = this.props;
    const { uploadedByList, snackbarMessage, dateError } = this.state;

    const uploadedByUser = uploadedByList.filter((option) => {
      return option.id === this.state.uploadedBy;
    })[0];

    if (uploadedByList.length > 0) {
      return (
        <>
          <div className={classes.container}>
            <Select
              fullWidth
              classes={{
                iconOutlined: classes.icon,
              }}
              IconComponent={ChevronDown}
              className={classes.filterDropdown}
              value={this.state.uploadedBy}
              displayEmpty
              onChange={(event) => this.handleUploadedByChange(event)}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return (
                    <span className={classes.menuItem_placeholder}>
                      Uploaded By
                    </span>
                  );
                } else {
                  return (
                    <span className={classes.menuItem_placeholder}>
                      {`${
                        uploadedByUser.first_name
                      } ${uploadedByUser.last_name ?? ""} `}
                    </span>
                  );
                }
              }}
            >
              {uploadedByList.map((option) => {
                return (
                  <MenuItem
                    key={option.id}
                    name={option.id}
                    classes={{
                      root: classes.menuOption,
                    }}
                    value={option.id}
                  >
                    {`${option.first_name} ${option.last_name ?? ""}`}
                  </MenuItem>
                );
              })}
            </Select>

            <div>
              <div className={classes.startDateInput}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <ThemeProvider theme={materialTheme}>
                    <DatePicker
                      variant="inline"
                      autoOk
                      className={classes.datePicker}
                      label="Start Date"
                      format="MM/dd/yyyy"
                      value={this.state.startDate}
                      disableFuture
                      onChange={(event) =>
                        this.handleDateChange(event, "startDate")
                      }
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
              </div>
              <span className={classes.toDateContainer}>to</span>
              <div className={classes.endDateInput}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <ThemeProvider theme={materialTheme}>
                    <DatePicker
                      variant="inline"
                      autoOk
                      label="End Date"
                      format="MM/dd/yyyy"
                      value={this.state.endDate}
                      disableFuture
                      onChange={(event) =>
                        this.handleDateChange(event, "endDate")
                      }
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
              </div>
            </div>
            <div>
              <Snackbar
                open={dateError}
                style={{
                  width: "20%",
                  marginTop: "200px",
                  marginRight: "35px",
                }}
                onClose={() => {
                  this.setState((prevState) => ({
                    dateError: !prevState.dateError,
                    snackbarMessage: "",
                  }));
                }}
                autoHideDuration={2400}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MuiAlert
                  style={{
                    width: "100%",
                    background: "red",
                    color: color.WHITE,
                    borderRadius: "8px",
                    fontFamily: "Rubik",
                    marginLeft: "27px",
                    marginBottom: "17px",
                  }}
                  icon={<AlertIcon fontSize="inherit" />}
                >
                  {snackbarMessage}
                </MuiAlert>
              </Snackbar>
            </div>

            <Button
              variant="contained"
              color="primary"
              className={classes.btnfilter}
              onClick={this.sendFilteredRequest}
              disabled={!this.state.uploadedBy && !this.state.startDate}
            >
              Apply Filters
            </Button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className={classes.noDataParent}>
            <div className={classes.noDataParentIcon}>
              <NoResultIcon />
            </div>
            <div className={classes.noDataParentText}>No Results Found</div>
          </div>
        </>
      );
    }
  };

  handleFilterOnCLick = async () => {
    const { auth } = this.props;
    const ACCESS_TOKEN = auth.authDetails.data.access_token;
    const { showFilterSection } = this.state;

    getUploadedByDataService(ACCESS_TOKEN).then((response) => {
      if (!response.message) {
        this.setState({
          uploadedByList: response.partners,
          showFilterSection: !showFilterSection,
        });
      } else {
        this.setState({
          showSnackBar: true,
          snackbarStatus: careSnackbarStatus.ERROR,
          snackbarMessage: response.message,
          isLoading: false,
          showFilterSection: !showFilterSection,
        });
      }
    });
  };

  render() {
    const { classes } = this.props;
    const {
      showFilterSection,
      isLoading,
      showSnackBar,
      snackbarMessage,
    } = this.state;

    return (
      <>
        <Snackbar
          open={showSnackBar}
          style={{
            width: "62%",
            marginLeft: "16px",
          }}
          onClose={() => {
            this.setState((prevState) => ({
              showSnackBar: !prevState.showSnackBar,
              snackbarMessage: "",
              snackbarStatus: "",
            }));
          }}
          autoHideDuration={2400}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <MuiAlert
            style={{
              width: "100%",
              background: "red",
              color: color.WHITE,
              borderRadius: "8px",
              fontFamily: "Rubik",
              marginLeft: "27px",
              marginBottom: "17px",
            }}
            icon={<SendIcon fontSize="inherit" />}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>

        <div className={classes.parentContainer}>
          <Box className={classes.header}>
            <Typography className={classes.bold}>
              {" "}
              {showFilterSection === false ? this.props.type : "Filters"}{" "}
            </Typography>
            {!showFilterSection ? (
              <>
                <div className={classes.filterFrame}>
                  <Typography
                    onClick={() => this.handleFilterOnCLick()}
                    className={classes.filterText}
                  >
                    Filters
                  </Typography>
                  <div style={{ alignSelf: "center" }}>
                    {/* <Badge
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      overlap="rectangular"
                      badgeContent={<EllipseFilterIcon />}
                      classes={{
                        anchorOriginTopLeftRectangular: classes.closePreviewBtn,
                      }}
                    > */}
                    <FilterEllipseIcon />
                    {/* <FilterIcon /> */}
                    {/* </Badge> */}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </Box>
          {isLoading ? this.getCircularProgress() : <></>}
          {showFilterSection
            ? this.renderFilterSection()
            : this.renderHistoryList()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { familyDetails, newCustomer, auth } = state;
  return {
    familyDetails,
    newCustomer,
    auth,
  };
};

const mapDispatchToProps = (dispatch) => ({});

const ConnectedShowHistoryCard = withStyles(style, { withTheme: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(ShowHistoryCard))
);
export default ConnectedShowHistoryCard;
