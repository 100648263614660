import config from '../config';
import _ from 'lodash';
import moment from 'moment';
import { sortType, dateType } from '../constants';

const axios = require('axios');

export async function getTicketsCountStatus(partnerId, ACCESS_TOKEN) {
    let url = `${config.apiURL}/v1/tickets/stats?partnerId=${partnerId}`;
    const options = {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + ACCESS_TOKEN,
        },
        timeout: config.timeout,
        responseType: 'json',
        responseEncoding: 'utf8',
        url,
    };
    const result = await axios(options);
    return result;
}

export async function getTickets(partnerId, ACCESS_TOKEN,
    deadlineFrom,
    deadLineTo,
    selectedDateType,
    selectedLanguage,
    selectedTicketObjectives,
    pageNo,
    pageSize,
    sortMode,
    mobileNo) {
    let url = `${config.apiURL}/v1/tickets/search`;
    const data = {
        partner: {
            id: partnerId,
        },
        page: pageNo,
        page_size: pageSize,
        deadline_from: deadlineFrom ? moment(deadlineFrom).startOf('d').valueOf() : undefined,
        deadline_to: deadLineTo ? moment(deadLineTo).endOf('d').valueOf() : undefined
    };
    if (!_.isEmpty(selectedTicketObjectives) && selectedTicketObjectives.length === 1) {
        data.objective = selectedTicketObjectives[0];
    }
    if (mobileNo && mobileNo.length === 10) {
        data.user_phone = mobileNo;
    }
    if (sortMode && sortMode === sortType.ASCENDING) {
        data.sort_by = [
            {
                key: "deadline",
                dir: "asc"
            }
        ];
    }
    if (sortMode && sortMode === sortType.DESCENDING) {
        data.sort_by = [
            {
                key: "deadline",
                dir: "desc"
            }
        ];
    }
    if (selectedLanguage) {
        data.lop = selectedLanguage;
    }
    if (selectedDateType === dateType.TODAY) {
        data.deadline_from = moment().startOf('day').valueOf();
        data.deadline_to = moment().endOf('day').valueOf();
    }
    if (selectedDateType === dateType.YESTERDAY) {
        data.deadline_from = moment().subtract(1,'days').startOf('day').valueOf();
        data.deadline_to = moment().subtract(1,'days').endOf('day').valueOf();
    }
    if (selectedDateType === dateType.LAST_WEEK) {
        data.deadline_from = moment().subtract(1, 'week').startOf('d').valueOf();
        data.deadline_to = moment().endOf('day').valueOf();
    }
    if (selectedDateType === dateType.LAST_MONTH) {
        data.deadline_from = moment().subtract(1, 'month').startOf('d').valueOf();
        data.deadline_to = moment().endOf('day').valueOf();
    }
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + ACCESS_TOKEN,
        },
        timeout: 10000,
        responseType: 'json',
        responseEncoding: 'utf8',
        url,
        data,
    };
    const result = await axios(options);
    return result;
}


export async function addCommentsToTicket(ticketId, data, ACCESS_TOKEN) {
    let url = `${config.apiURL}/v1/tickets/${ticketId}/comments`;
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + ACCESS_TOKEN,
        },
        timeout: config.timeout,
        responseType: 'json',
        responseEncoding: 'utf8',
        url,
        data,
    };
    const result = await axios(options);
    return result;
}

// /v1/tickets/5ffc020fa4337da2a28f4f4a/extend-deadline

export async function extendTicketDeadLine(ticketId, deadLineTime, ACCESS_TOKEN) {
    let url = `${config.apiURL}/v1/tickets/${ticketId}/extend-deadline`;
    const data = {
        deadline: deadLineTime,
    };
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + ACCESS_TOKEN,
        },
        timeout: config.timeout,
        responseType: 'json',
        responseEncoding: 'utf8',
        url,
        data,
    };
    const result = await axios(options);
    return result;
}

export async function markTicketResolve(ticketId, ACCESS_TOKEN) {
    let url = `${config.apiURL}/v1/tickets/${ticketId}/mark-resolved`;
    // const data = {
    //     comment: 'Ended',
    // };
    const options = {
        method: 'PUT',
        headers: {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + ACCESS_TOKEN,
        },
        timeout: config.timeout,
        responseType: 'json',
        responseEncoding: 'utf8',
        url,
        // data,
    };
    const result = await axios(options);
    return result;
}

export async function postFeedback(consultationId, ACCESS_TOKEN, data) {
    const  url = `${config.apiURL}/v2/consultations/${consultationId}/ratings`;
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + ACCESS_TOKEN,
        },
        timeout: config.timeout,
        responseType: 'json',
        responseEncoding: 'utf8',
        url,
        data,
    }
    const result = await axios(options);
    return result;
}


export async function getCustomerPreferences(customerId, ACCESS_TOKEN) {
    const  url = `${config.apiURL}/v3/customers/${customerId}/preferences`;
    const options = {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + ACCESS_TOKEN,
        },
        timeout: config.timeout,
        responseType: 'json',
        responseEncoding: 'utf8',
        url,
    }
    const result = await axios(options);
    return result;
}

export async function sendPrescriptionLinkThroughSms(consultationId, ACCESS_TOKEN, phoneNumber, channel = 'sms') {
    const  url = `${config.apiURL}/v2/consultations/${consultationId}/prescriptions/send`;
    const data = {
        to: phoneNumber,
        channels: [channel]
    }
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + ACCESS_TOKEN,
        },
        timeout: config.timeout,
        responseType: 'json',
        responseEncoding: 'utf8',
        url,
        data
    }
    const result = await axios(options);
    return result;
}

export async function sendPrescriptionLinkThroughWhatsapp(consultationId, ACCESS_TOKEN, phoneNumber, channel = 'whatsapp') {
    const  url = `${config.apiURL}/v2/consultations/${consultationId}/prescriptions/send`;
    const data = {
        to: phoneNumber,
        channels: [channel]
    }
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + ACCESS_TOKEN,
        },
        timeout: config.timeout,
        responseType: 'json',
        responseEncoding: 'utf8',
        url,
        data
    }
    const result = await axios(options);
    return result;
}


export async function sendPlaystoreLinkThroughSms(ACCESS_TOKEN, phoneNumber, channel = 'sms') {
    const  url = `${config.apiURL}/v1/operations/app-links/send`;
    const data = {
        to: phoneNumber,
        app_name: "customer_android_app",
        channels: [channel]
    }
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + ACCESS_TOKEN,
        },
        timeout: config.timeout,
        responseType: 'json',
        responseEncoding: 'utf8',
        url,
        data
    }
    const result = await axios(options);
    return result;
}

export async function sendPlaystoreLinkThroughWhatsapp(ACCESS_TOKEN, phoneNumber, channel = 'whatsapp') {
    const  url = `${config.apiURL}/v1/operations/app-links/send`;
    const data = {
        to: phoneNumber,
        app_name: "customer_android_app",
        channels: [channel]
    }
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + ACCESS_TOKEN,
        },
        timeout: config.timeout,
        responseType: 'json',
        responseEncoding: 'utf8',
        url,
        data
    }
    const result = await axios(options);
    return result;
}