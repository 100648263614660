const useStyles = theme => ({
    root: {
        width: '92vw',
        backgroundColor: 'white',
        height: '81vh',
        position: 'relative',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
        backgroundColor: '#ffe6b1',
    },
    iconContainer: {
        transform: 'scale(1.3)',
    },
    connectorActive: {
        '& $connectorLine': {
            borderColor: '#f38b44',
        },
    },
    connectorCompleted: {
        '& $connectorLine': {
            borderColor: '#f38b44',
        },
    },
    connectorDisabled: {
        '& $connectorLine': {
            borderColor: '#ffe6b1',
        },
    },
    connectorLine: {
        borderColor: '#ffe6b1',
        marginRight: '2vh',
        marginLeft: '2vh',
        marginTop: '2vh',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    BillingPrefContainer: {
        minHeight: '320px',
        marginLeft: '55px',
    },
    BillingPrefHeading: {
        marginTop: '44.5px',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '18px',
        color: '#172A3A',
    },
    BillingPrefSubHeading: {
        marginTop: '9px',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 'normal',
        color: '#9D9D9D',
    },
    BillingPrefContainerForm: {
        display: 'flex',
        flexDirection: 'row',
    },
    BillingPrefHeadingDate: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        color: '#172A3A',
        alignSelf: 'center',
        marginRight: '36px',
    },
});

export default useStyles;