import React from 'react';
import { connect } from 'react-redux';
import HomePage from './HomePage';
import LoginPage from './LoginPage';
import CustomErrorPage from './CustomErrorPage';

import { checkIfHA } from '../common/utils';
class LoadingHome extends React.Component {
    render() {
        if (!this.props.details.isLoggedIn) {
            return (
                <LoginPage />
            )
        } else
         if (checkIfHA(this.props.details.authDetails.data.user.roles)) {
            return  <HomePage />;
        } else {
            return (
                <CustomErrorPage message="You are not authorized to view the content" />        
            );
        }
    }
};

const mapStateToProps = (state) => {
    return { details: state.auth };
};

export default connect(mapStateToProps)(LoadingHome);
