import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Box, Typography, Button, TextField, CircularProgress } from '@material-ui/core';
import _ from 'lodash';

import { 
    getFamilyAddressesEvent, updateAddressEvent, addAddressEvent,
    getCityStateByZipCodeEvent, resetAddressByZipcodeEvent,
    resetAddressesEvent
} from '../../../actions/addressAction';
import style from './style';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CheckIcon from '@material-ui/icons/Check';

class AddressDetails extends Component {

    constructor() {
        super();
        this.state = {
            addresses: [],
            pincode: '',
            address: '',
            city: '',
            state: '',
            tag: '',
            tagSelected: null,
            display: 'addressList',
            editAddress: false,
            editAddressId: null,
        }
    }

    setToDefaultState() {
        this.setState({
            addresses: [],
            pincode: '',
            address: '',
            city: '',
            state: '',
            tag: '',
            tagSelected: null,
            display: 'addressList',
            edit: false,
            editAddressId: null,
        })
    }

    componentDidMount() {
        const {
            familyDetails, getAddresses, resetAddressByZipcode
        } = this.props;
        getAddresses(familyDetails.currentCustomer.family.id);
        resetAddressByZipcode();
    }



    componentDidUpdate() {
        const {
            newCustomer, resetAddressByZipcode
        } = this.props;
        if (newCustomer && newCustomer.pincodeDetails) {
            this.setState({
                city: newCustomer.pincodeDetails.city,
                state: newCustomer.pincodeDetails.state,
            })
            resetAddressByZipcode();
        }
    }

    getCircularProgress = () => {
        return <CircularProgress style={{
            color: 'orange',
            left: '50%',
            top: '55%',
            position: 'absolute',
        }} />;
    }

    handleChange = type => event => {
        this.setState({
            [type]: event.target.value
        })
    }

    handelPincode() {
        const { pincode } = this.state;
        if(this.isPincodeValid(pincode)){
            const { findCityState } = this.props;
            findCityState(this.state.pincode);
        }
    }

    handleTagClick(tagName) {
        this.setState({
            tagSelected: tagName,
            tag: tagName !== 'other' ? tagName : ''
        })
    }

    handelAddAddressDetails() {
        const {
            newCustomer
        } = this.props
        const {
            familyDetails, addAddress,
        } = this.props;
        const {
            pincode, address, city, state, tag
        } = this.state;
        const addressDetailsObject = {
            family: {
                id: familyDetails.currentCustomer.family.id
            },
            zipcode: pincode,
            line: address,
            city,
            state,
            tag,
        }
        const oldAddresses = newCustomer.addresses ? newCustomer.addresses : []
        addAddress(addressDetailsObject, oldAddresses);
        this.setToDefaultState();
    }

    addAddress() {
        this.setState({
            display: 'addressForm'
        })
    }

    editAddress(addressDetails) {
        this.setState({
            editAddressId: addressDetails.id,
            pincode: addressDetails.zipcode,
            city: addressDetails.city,
            state: addressDetails.state,
            address: addressDetails.line,
            tag: addressDetails.tag,
            tagSelected: addressDetails.tag !== 'home' && addressDetails.tag !== 'office' ? 'other' : addressDetails.tag,
        })
        this.setState({
            display: 'addressForm',
            edit: true
        })
    }

    handelEditAddressDetails() {
        const {
            familyDetails, updateAddress, newCustomer
        } = this.props;
        const {
            pincode, address, city, state, tag, editAddressId
        } = this.state;
        const addressDetailsObject = {
            zipcode: pincode,
            line: address,
            city,
            state,
            tag,
        }
        const newAddresses = _.map(newCustomer.addresses, (address) => {
            if (address.id === editAddressId) {
                return {
                    ...address,
                    zipcode: pincode,
                    line: addressDetailsObject.line,
                    city,
                    state,
                    tag,
                }
            }
            return address;
        })
        updateAddress(addressDetailsObject, familyDetails.currentCustomer.family.id, editAddressId, newAddresses);
        this.setToDefaultState();
    }

    closeForm() {
        this.setState({ display: 'addressList' });
        this.setToDefaultState();
    }

    isPincodeValid(pincode) {
        return /^((?!(0))[0-9]{6})$/.test(pincode)
    }
    isFormValid() {
        const { tagSelected, city, state, pincode, address, tag } = this.state;
        return (tagSelected && !_.isEmpty(city) && !_.isEmpty(state) && !_.isEmpty(pincode)
            && this.isPincodeValid(pincode) && !_.isEmpty(address) && !_.isEmpty(tag) && tag.length <= 80);
    }

    renderAddressForm() {
        const { classes } = this.props;
        const { tagSelected, city, state, pincode, address, tag, edit } = this.state;
        return (
            <Box className={classes.addressContainer}>
                <Typography className={classes.bold}> {edit ? 'Update' : 'Enter'} Address Details </Typography>
                <form>
                    <Box className={classes.pincodeContainer}>
                        <TextField type="number" onChange={this.handleChange('pincode')} value={pincode} className={classes.formField} label="Pincode" />
                        <Box style={{ opacity: !this.isPincodeValid(pincode) ? '70%' : '100%' }}
                            onClick={() => this.handelPincode()} className={classes.searchIcon}
                        >
                            <SearchIcon className={classes.whiteColor} fontSize="small" />
                        </Box>
                    </Box>
                    <TextField onChange={this.handleChange('address')} className={classes.formField} value={address} label="Address" />
                    <TextField onChange={this.handleChange('city')} className={classes.formField} value={city} label="City" />
                    <TextField onChange={this.handleChange('state')} className={classes.formField} value={state} label="State" />

                    <Box className={classes.locationTagContainer}>
                        <Typography className={classes.locationTagHeading}> Save This Location As </Typography>
                        <Box className={classes.locationTags}>
                            <Button
                                onClick={() => this.handleTagClick('home')}
                                className={`${classes.tagNameButton} ${tagSelected === 'home' ? classes.tagNameButtonSelected : ''}`}
                                variant="outlined"
                            >
                                Home {tagSelected === 'home' && <CheckIcon className={`${classes.royalOrangeColor} ${classes.mL10}`} fontSize="small" />}
                            </Button>
                            <Button
                                onClick={() => this.handleTagClick('office')}
                                className={`${classes.tagNameButton} ${tagSelected === 'office' ? classes.tagNameButtonSelected : ''}`}
                                variant="outlined"
                            >
                                Office {tagSelected === 'office' && <CheckIcon className={`${classes.royalOrangeColor} ${classes.mL10}`} fontSize="small" />}
                            </Button>
                            <Button
                                onClick={() => this.handleTagClick('other')}
                                className={`${classes.tagNameButton} ${tagSelected === 'other' ? classes.tagNameButtonSelected : ''}`}
                                variant="outlined"
                            >
                                Others {tagSelected === 'other' && <CheckIcon className={`${classes.royalOrangeColor} ${classes.mL10}`} fontSize="small" />}
                            </Button>
                        </Box>
                        {tagSelected === 'other' && <TextField onChange={this.handleChange('tag')} value={tag} className={classes.formField} label="Save As" />}
                        {tagSelected === 'other' && tag && tag.length > 80 && <Typography className={classes.redColor}> Cannot be more than 80 characters</Typography>}
                    </Box>
                    <Button disabled={!this.isFormValid()} onClick={() => edit ? this.handelEditAddressDetails() : this.handelAddAddressDetails()} className={classes.addressActionButton}> {edit ? 'Update' : 'Save'} Address </Button>
                </form>
            </Box>
        )
    }

    renderAddressesList() {
        const { classes, newCustomer, } = this.props;
        return (
            <Box className={classes.addressContainer}>
                {newCustomer.addresses && newCustomer.addresses.map((address) => {
                    return (
                        <Box className={classes.addressBox} key={address.id}>
                            <Box className={classes.addressDetails}>
                                <Typography className={classes.tag}> {address.tag} Address </Typography>
                                <Typography className={classes.bold}> {address.line}, {address.city}, {address.state} - {address.zipcode}  </Typography>
                            </Box>
                            <Box onClick={() => this.editAddress(address)} className={classes.edit}>
                                <EditOutlinedIcon className={classes.whiteColor} />
                            </Box>
                        </Box>
                    )
                })}
                <Button onClick={() => this.addAddress()} className={classes.newAddressButton}> Add New Address </Button>
            </Box>
        )
    }

    render() {
        const { classes, closeAddressDrawer, newCustomer } = this.props;
        const { display } = this.state;
        return (
            <>
                <Box className={classes.header}>
                    <Typography className={classes.bold}> Customer's Addresses </Typography>
                    <CloseIcon className={classes.closeIcon} onClick={() => display === 'addressList' ? closeAddressDrawer() : this.closeForm()} />
                </Box>
                {newCustomer.isLoading ? this.getCircularProgress() : <></>}
                {display === 'addressList' && this.renderAddressesList()}
                {display === 'addressForm' && this.renderAddressForm()}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const {
        familyDetails, newCustomer
    } = state;
    return {
        familyDetails,
        newCustomer
    };
};

const mapDispatchToProps = (dispatch) => ({
    getAddresses: (familyId) => { dispatch(getFamilyAddressesEvent(familyId)); },
    addAddress: (addressDetails, oldAddresses) => { dispatch(addAddressEvent(addressDetails, oldAddresses)); },
    updateAddress: (addressDetails, familyId, addressId, updatedAddresses) => {
        dispatch(updateAddressEvent(addressDetails, familyId, addressId, updatedAddresses));
    },
    findCityState: (pincode) => { dispatch(getCityStateByZipCodeEvent(pincode)); },
    resetAddressByZipcode: () => { dispatch(resetAddressByZipcodeEvent()); },
    resetAddresses: () => { dispatch(resetAddressesEvent()); },
});

const ConnectedAddressDetails = withStyles(style,
    { withTheme: true })(connect(mapStateToProps,
        mapDispatchToProps)(withTranslation()(AddressDetails)));
export default ConnectedAddressDetails;
