import React from 'react';
import { connect } from 'react-redux';
import { getCustomerDetails, logOut, getCreditDetailsByFamilyId } from '../actions';
import { getFamilyAddressesEvent } from '../actions/addressAction';
import { resetBilling, resetBillingHistory } from '../actions';
import { couponAction } from '../actions/couponAction';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getQueryParams } from '../common/utils';
import SideMenuBar from '../components/Home/sideMenuBar/SideMenuBar';
import LoginPage from './LoginPage';
import TopBar from '../components/Home/topBar/TopBar';
import { clientRoute } from '../constants';
import history from '../history';
import { resetOfflinePaymentEvent } from '../actions/paymentAction';
const { errors } = require('../common/errors');

class GettingDetails extends React.Component {


  componentDidMount() {
    const {
      consultationDetails, resetBilling, resetCoupon, resetBillingHistory,
      getCustomerDetails, familyDetails, resetOfflinePayment,
    } = this.props;
    // if the user goes back to the family details screen in the middle of
    // generating a bill, reset the billing data because the family may not be the same.
    resetCoupon();
    resetBillingHistory();
    resetOfflinePayment();
    if (consultationDetails.billing !== null) {
      const routeHistory = history.location.state;
      if (routeHistory === undefined || (routeHistory?.from !== clientRoute.CONSULTATIONS_TODAY_PAGE)) {
        resetBilling();
      }
    }

    // fetch data for the phone number/family ID entered;
    const familyId = getQueryParams().get('family');
    if (familyId) {
      getCustomerDetails(familyId);
    } else {
      const isParamSearch = familyDetails.customerPhoneNumber.includes(',');
      if (isParamSearch) {
        const [property, value] = familyDetails.customerPhoneNumber.split(',');
        if (property === 'p' && value.length !== 10) {
          return alert('Please Enter the correct Phone No.');
        }
      }
      getCustomerDetails(familyDetails.customerPhoneNumber);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { familyDetails, getFamilyAddressesEvent, getCreditDetailsByFamilyId } = this.props;
    // this is required for billing;
    if (familyDetails.currentCustomer !== prevProps.familyDetails.currentCustomer) {
      getFamilyAddressesEvent(familyDetails?.currentCustomer?.family?.id);
      getCreditDetailsByFamilyId(familyDetails?.currentCustomer?.family?.id);
    }
  }

  render() {
    if (!this.props.auth.isLoggedIn) {
      return (
        <LoginPage />
      )
    } else if (this.props.error && this.props.error.customerErrorCode === errors.FORBIDDEN) {
      this.props.logOut();
    } else {
      return (
        <div className='main' style={{
          height: "100vh",
          minWidth: '80vw',
          backgroundColor: '#f4f4f4',
        }}>
          <TopBar />
          <div style={{ display: 'flex' }}>
            <SideMenuBar />
            <CircularProgress style={{
              color: 'orange', marginTop: '50vh',
              marginLeft: '45%'
            }} disableShrink />
          </div>
        </div>
      );
    }
  }
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    familyDetails: state.familyDetails,
    error: state.error,
    consultationDetails: state.consultationDetails,
  };
}

const mapDispatchToProps = {
  getCustomerDetails,
  logOut,
  getCreditDetailsByFamilyId,
  getFamilyAddressesEvent,
  resetBilling,
  resetBillingHistory,
  resetCoupon: couponAction.resetCoupon,
  resetOfflinePayment: resetOfflinePaymentEvent,
};

const reduxWrapper = connect(mapStateToProps, mapDispatchToProps);
export default reduxWrapper(GettingDetails);