export const productApis = {
	GET_PRODUCT_BY_BUSINESS_ID: '/v3/products',
	GET_PRODUCT_BY_ID: '/v3/products/',
	SUBSCRIBE_PRODUCT: '/v3/subscriptions',
	PAY_INVOICES: '/v3/pay-invoices',
};

export const productActionTypes = {
	GET_PRODUCT_BY_BUSINESS_ID_REQUEST: 'GET_PRODUCT_BY_BUSINESS_ID_REQUEST',
	GET_PRODUCT_BY_BUSINESS_ID_SUCCESS: 'GET_PRODUCT_BY_BUSINESS_ID_SUCCESS',
	GET_PRODUCT_BY_BUSINESS_ID_FAILURE: 'GET_PRODUCT_BY_BUSINESS_ID_FAILURE',
	GET_PRODUCT_BY_ID_REQUEST: 'GET_PRODUCT_BY_ID_REQUEST',
	GET_PRODUCT_BY_ID_SUCCESS: 'GET_PRODUCT_BY_ID_SUCCESS',
	GET_PRODUCT_BY_ID_FAILURE: 'GET_PRODUCT_BY_ID_FAILURE',
	SUBSCRIBE_PRODUCT_REQUEST: 'SUBSCRIBE_PRODUCT_REQUEST',
	SUBSCRIBE_PRODUCT_SUCCESS: 'SUBSCRIBE_PRODUCT_SUCCESS',
	SUBSCRIBE_PRODUCT_FAILURE: 'SUBSCRIBE_PRODUCT_FAILURE',

	RESET_PRODUCT: 'RESET_PRODUCT',
	RESET_SUBSCRIPTION: 'RESET_SUBSCRIPTION',
	SET_USER_PRODUCT_BUY_CACHE: 'SET_USER_PRODUCT_BUY_CACHE',
	RESET_USER_PRODUCT_BUY_CACHE: 'RESET_USER_PRODUCT_BUY_CACHE',
};

export const discountType = {
	FLAT: 'flat',
	PERCENTAGE: 'percentage',
};

export const paymentMultiplier = {
	MONTHLY: 'monthly',
	QUARTERLY: 'quarterly',
	SEMI_ANNUALLY: 'semi_annually',
	ANNUALLY: 'annually',
};

export const paymentMultiplierQuantity = {
	MONTHLY: 1,
	QUARTERLY: 3,
	SEMI_ANNUALLY: 6,
	ANNUALLY: 12,
};
