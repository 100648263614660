import { color } from "../../../constants/colors";
const styles = () => ({
  container: {
    width: "50vh",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  header: {
    display: "flex",
    padding: "24px",
    justifyContent: "space-between",
    borderBottom: `1px solid ${color.ROYAL_ORANGE}`,
    maxHeight: "10%",
  },
  headerText: {
    fontFamily: "Rubik",
    fontWeight: "bold",
    fontSize: "16px",
  },
  subheader: {
    marginTop: "15px",
  },
  placeholderText: {
    fontFamily: "Rubik",
    fontSize: "14px",
    marginTop: "2vh",
  },
  creditCheckbox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: "-10px",
    marginBottom: "10px",
  },
  creditLabel: {
    fontFamily: "Rubik",
    fontSize: "12px",
    margin: "0px",
    color: color.BLACK,
  },
  creditLabelDisabled: {
    fontFamily: "Rubik",
    fontSize: "12px",
    color: color.greys[100],
  },
  creditCheckboxRoot: {
    padding: "1vh",
    "&$checked": {
      color: color.ORANGE,
    },
  },
  renewalBadge: {
    display: "flex",
    flexDirection: "row",
    background: color.blues[100],
    color: color.blues[200],
    borderRadius: "4px",
    fontSize: "12px",
    fontFamily: "Rubik",
    border: `1px solid ${color.blues[200]}`,
    padding: "4px",
    marginTop: "1vh",
    textTransform: "capitalize",
    fontWeight: "bold",
  },
  newPurchaseBadge: {
    display: "flex",
    flexDirection: "row",
    background: color.reds[400],
    color: color.reds[500],
    borderRadius: "4px",
    fontSize: "12px",
    fontFamily: "Rubik",
    border: `1px solid ${color.reds[500]}`,
    padding: "4px",
    marginTop: "1vh",
    textTransform: "capitalize",
    fontWeight: "bold",
    margin: "0px 4px",
  },
  body: {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    height: "calc(90% - 40px)",
    flex: 1,
  },
  drawerFooter: {
    padding: "24px",
    position: "fixed",
    bottom: "0",
    right: "0",
    width: "(100%)",
  },
  whatsAppConsentInfo: {
    fontSize: "13px",
    fontFamily: "Rubik",
    marginTop: "7px",
  },
  drawerBodyCentered: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "90vh",
  },
  phoneInput: {
    fontFamily: "Rubik",
    fontSize: "16px",
    color: color.BLACK,
    "-moz-appearance": "textfield",
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  bodyHeader: {
    fontFamily: "Rubik",
    size: "16px",
    color: color.DARK_GREY,
  },
  bodyContainer: {
    "&:not(:first-child)": {
      marginTop: "16px",
    },
    "&:nth-child(3)": {
      height: "70%",
    },
  },
  chipContainer: {
    marginTop: "16px",
  },
  chip: {
    border: `1px solid ${color.GREY}`,
    fontFamily: "Rubik",
    color: color.LIGHT_GREY,
    background: color.WHITE,
    fontSize: "16px",
    marginRight: "4px",
  },
  chipSelected: {
    border: `1px solid ${color.ROYAL_ORANGE}`,
    fontFamily: "Rubik",
    background: `${color.ROYAL_ORANGE}30`,
    color: color.ROYAL_ORANGE,
    fontSize: "16px",
    marginRight: "4px",
    "&:hover": {
      background: color.WHITE,
    },
  },
  smallLabel: {
    fontFamily: "Rubik",
    fontWeight: "400",
    color: color.LIGHT_GREY_TEXT,
    fontSize: "13px",
    "&:not(:first-child)": {
      marginTop: "18px",
    },
  },
  invoiceList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    overflow: "auto",
    marginTop: "-10px",
    // flex: 1,
  },
  invoiceContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    "&:not(:first-child)": {
      borderTop: `1px solid ${color.LIGHT_GREY}`,
    },
    paddingBottom: "14px",
  },
  invoiceColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    "&:first-child": {
      width: "10%",
    },
    "&:nth-child(2)": {
      width: "60%",
      flex: 1,
      justifyContent: "center",
    },
    "&:last-child": {
      alignItems: "flex-end",
      width: "30%",
      flexFlow: "column-reverse",
    },
  },
  invoiceHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "1vh",
  },
  invoiceRow: {
    display: "flex",
    flexDirection: "column",
    marginTop: "1vh",
  },
  checkbox: {
    "&$checked": {
      color: color.ORANGE,
    },
  },
  checked: {},
  sendLinkButton: {
    height: "40px",
    borderRadius: "5px",
    background: color.ROYAL_ORANGE,
    color: color.WHITE,
    fontFamily: "Rubik",
    fontSize: "13px",
    fontWeight: "bold",
    textTransform: "capitalize",
    position: "relative",
    "&:hover": {
      background: color.ORANGE,
    },
    "&:disabled": {
      color: color.oranges[100],
      cursor: "not-allowed",
      pointerEvents: "all",
      "&:hover": {
        background: color.ROYAL_ORANGE,
      },
    },
  },
  linkOptions: {
    margin: "0 -20px",
    padding: "20px",
    fontFamily: "Rubik",
    color: color.BLACK,
    fontSize: "14px",
    background: color.oranges[100],
    display: "flex",
    gap: "20px",
    flexDirection: "column",
  },
  invalidInputWarning: {
    color: color.reds[100],
    fontFamily: "Rubik",
  },
  copyLinkContainer: {
    background: color.greys[500],
    margin: "0 -20px",
    padding: "20px",
  },
  copyLinkButton: {
    background: color.greys[450],
    borderRadius: "4px",
    color: "#1F2A37",
    fontFamily: "Rubik",
    textTransform: "capitalize",
    fontWeight: "bold",
    height: "40px",
    fontSize: "13px",
    "&:hover": {
      background: color.greys[450],
    },
    "&:disabled": {
      pointerEvents: "all",
      cursor: 'not-allowed',
      "&:hover": {
        background: color.greys[450],
      },
    },
  },
  tooltip: {
    padding: "1vh",
    fontFamily: "Rubik",
    color: color.WHITE,
    background: color.BLACK,
    fontSize: "0.8rem",
  },
  arrow: {
    background: color.greens[100],
    color: color.greens[200],
  },
  toggle: {
    "&&": {
      textTransform: "capitalize",
      color: color.DARK_GREY + " !important",
      background: color.oranges[100] + " !important",
      fontFamily: "Rubik",
      borderRadius: "8px",
      "&:hover": {
        opacity: "85%",
      },
    },
  },
  toggleSelected: {
    "&&": {
      textTransform: "capitalize",
      color: color.WHITE + " !important",
      background: color.ROYAL_ORANGE + " !important",
      fontFamily: "Rubik",
      borderRadius: "8px",
      "&:hover": {
        opacity: "85%",
      },
    },
  },
});

export default styles;
