import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import 'date-fns';
import { orange } from "@material-ui/core/colors";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { ThemeProvider } from "@material-ui/styles";

import { createMuiTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { TextField, Typography, Divider, Drawer, Paper, ButtonGroup, Button, IconButton, CircularProgress, MenuItem, Tooltip } from "@material-ui/core";
import { ReactComponent as AdultMale } from '../../../../../images/Avatars/AdultMale.svg';
import { ReactComponent as AdultMaleSelected } from '../../../../../images/Avatars/AdultMaleSelected.svg';
import { ReactComponent as AdultFemale } from '../../../../../images/Avatars/AdultFemale.svg';
import { ReactComponent as AdultFemaleSelected } from '../../../../../images/Avatars/AdultFemaleSelected.svg';
import { ReactComponent as ChildMale } from '../../../../../images/Avatars/ChildMale.svg';
import { ReactComponent as ChildMaleSelected } from '../../../../../images/Avatars/ChildMaleSelected.svg';
import { ReactComponent as ChildFemale } from '../../../../../images/Avatars/ChildFemale.svg';
import { ReactComponent as ChildFemaleSelected } from '../../../../../images/Avatars/ChildFemaleSelected.svg';
import { ReactComponent as AddNewMember } from '../../../../../images/Avatars/AddNewMember.svg';
import { ReactComponent as CloseButton } from '../../../../../images/CloseButton.svg';
import { fullNameRegex } from '../../../../../common/constants';
import { getAllMembersByFamilyId, resetGetAllMembersByFamilyId, resetNewMemberAdded, addNewMemberByFamilyId, resetUpdateMember } from '../../../../../actions';
import styles from './styles';
const { CREATED } = require('http-status-codes');


const materialTheme = createMuiTheme({
    palette: {
        primary: orange,
    },
});

function disableNextDates(startDate) {
    const startSeconds = Date.parse(startDate);
    return (date) => {
        return Date.parse(date) > startSeconds;
    }
}

const GENDER = {
    MALE: 'male',
    FEMALE: 'female',
    OTHER: 'other',
}

const motherTongue = [
    {
        value: 'hindi',
        label: 'Hindi'
    },
    {
        value: 'english',
        label: 'English',
    },
    {
        value: 'kannada',
        label: 'Kannada',
    },
    {
        value: 'tamil',
        label: 'Tamil',
    },
    {
        value: 'telugu',
        label: 'Telugu',
    },
    {
        value: 'malayalam',
        label: 'Malayalam',
    },
    {
        value: 'bangla',
        label: 'Bangla',
    },
    {
        value: 'gujarati',
        label: 'Gujarati',
    },
    {
        value: 'marathi',
        label: 'Marathi',
    },
    {
        value: 'punjabi',
        label: 'Punjabi',
    },
    {
        value: 'odia',
        label: 'Odia',
    },
];


const relationships = [
    {
        value: 'spouse',
        label: 'Spouse',
    },
    {
        value: 'father',
        label: 'Father'
    },
    {
        value: 'mother',
        label: 'Mother',
    },
    {
        value: 'son',
        label: 'Son',
    },
    {
        value: 'daughter',
        label: 'Daughter',
    },
    {
        value: 'brother',
        label: 'Brother',
    },
    {
        value: 'sister',
        label: 'Sister',
    },
    {
        value: 'friend',
        label: 'Friend',
    },
    {
        value: 'other',
        label: 'Other',
    },

]

class SelectDependents extends React.Component {

    state = {
        name: '',
        addMemberDrawer: false,
        selectedPrimaryCustomerId: this.props.selectedPrimaryCustomerId,
        newMemberDob: new Date(),
        newMemberName: '',
        newMemberGender: '',
        newMemberLop: '',
        newMemberRelationship: '',
        callAddMemberClicked: false
    }

    componentDidMount() {
        this.props.resetNewMemberAdded();
        this.props.resetGetAllMembersByFamilyId();
        this.callGetAllMembersByFamilyId()
    }

    callGetAllMembersByFamilyId = () => {
        this.props.getAllMembersByFamilyId(this.props.selectedSubscription.family.id)
    }



    handleDateChange = date => {
        this.setState({ newMemberDob: date });
    };

    getCircularProgress = () => {
        return <CircularProgress style={{
            color: 'orange',
            left: '50%',
            top: '55%',
            position: 'absolute',
        }} disableShrink />;
    }

    openAddMemberDrawer = () => {
        this.setState({ addMemberDrawer: true });
    }

    closeAddMemberDrawer = () => {
        this.setState({ addMemberDrawer: false, callAddMemberClicked: false, newMemberName: '', newMemberDob: new Date(), newMemberGender: '', newMemberLop: '', newMemberRelationship: '' });
        this.callGetAllMembersByFamilyId();
        this.props.resetNewMemberAdded();
    }

    handleChange = name => event => {
        const userValue = event.target.value;
        if (fullNameRegex.test(userValue) || userValue === '') {
            this.setState({ [name]: userValue });
        }
    }

    addMember = () => {

        let newMemberObject = {
            first_name: this.state.newMemberName,
            dob: this.state.newMemberDob.getTime(),
            gender: this.state.newMemberGender,
            lop: this.state.newMemberLop,
            relationship: this.state.newMemberRelationship,
        };
        this.setState({ callAddMemberClicked: true }, () => this.props.addNewMemberByFamilyId(newMemberObject, this.props.selectedSubscription.family.id))
    }

    renderAddMemberDrawerContent = () => {
        const { classes } = this.props;
        if (this.props.familyDetails && _.isNil(this.props.familyDetails.newMember) && this.state.callAddMemberClicked) {
            return this.getCircularProgress();
        } else if (this.props.familyDetails && this.props.familyDetails.newMember && this.props.familyDetails.newMember.status === CREATED) {
            return this.closeAddMemberDrawer();
        }
        return (
            <div style={{ position: 'absolute' }}>

                <div>                <Grid container spacing={0}>
                    <Grid item xs={6}>
                        <Typography style={{ fontSize: '1.1rem', color: '#172a3a', fontFamily: 'Rubik-Medium', marginTop: '3vh', marginLeft: '2vw', float: 'left' }}>New Member</Typography>
                    </Grid>
                    <Grid item xs={6} >
                        <IconButton style={{ float: 'right', marginRight: '2vw', marginTop: '2.3vh' }} onClick={this.closeAddMemberDrawer}><CloseButton /></IconButton>
                    </Grid>
                    <Grid item xs={12} >
                        <Divider style={{ marginTop: '2vh' }} />
                    </Grid>
                    <Grid item xs={12}  >
                        <TextField
                            style={{ marginLeft: '3vw', marginTop: '4vh', marginRight: '3vw', width: '80%' }}
                            id="full-name"
                            label="Full Name*"
                            className={classes.textField}
                            value={this.state.newMemberName}
                            onChange={this.handleChange('newMemberName')}
                            margin="normal"
                            InputLabelProps={{
                                style: {
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    width: '100%',
                                    color: '#9d9d9d',
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}  >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={materialTheme}>
                                <KeyboardDatePicker
                                    style={{ marginLeft: '3vw', width: '80%', marginTop: '4vh' }}
                                    id="dob"
                                    label="Date of Birth*"
                                    className={classes.textField}
                                    value={this.state.newMemberDob || ''}
                                    onChange={this.handleDateChange}
                                    margin="normal"
                                    InputLabelProps={{
                                        style: {
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            width: '100%',
                                            color: '#9d9d9d',
                                        }
                                    }}
                                    format="dd-MM-yyyy"
                                    shouldDisableDate={disableNextDates(new Date())}
                                />
                            </ThemeProvider>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            style={{ marginLeft: '3vw', marginTop: '4vh', width: '80%' }}
                            select
                            id="lop"
                            label="Preferred Language"
                            className={classes.textField}
                            value={this.state.newMemberLop}
                            onChange={this.handleChange('newMemberLop')}
                            margin="normal"
                            InputLabelProps={{
                                style: {
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    width: '100%',
                                    color: '#9d9d9d'
                                }
                            }}>
                            {motherTongue.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            style={{ width: '80%', marginTop: '4vh', marginLeft: '3vw' }}
                            select
                            label="Relationship to H.S."
                            value={this.state.newMemberRelationship}
                            onChange={this.handleChange('newMemberRelationship')}
                            margin="normal"
                        >
                            {relationships.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.element} style={{ fontSize: '0.9rem', color: '#908f8f', fontFamily: 'Rubik-Regular', marginTop: '3vh', marginBottom: '1vh', marginLeft: '2.5vw' }}>Gender*</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonGroup variant="contained" aria-label="contained primary button group" style={{ fontFamily: 'Rubik-Regular', marginLeft: '3vw' }} >
                            <Button onClick={() => this.setState({ newMemberGender: GENDER.MALE })} style={{ backgroundColor: this.state.newMemberGender === GENDER.MALE ? '#f38b44' : '#e5e5e5', color: this.state.newMemberGender === GENDER.MALE ? 'white' : '#908f8f', textTransform: 'capitalize', width: '7.5vw' }} >Male</Button>
                            <Button onClick={() => this.setState({ newMemberGender: GENDER.FEMALE })} style={{ backgroundColor: this.state.newMemberGender === GENDER.FEMALE ? '#f38b44' : '#e5e5e5', color: this.state.newMemberGender === GENDER.FEMALE ? 'white' : '#908f8f', textTransform: 'capitalize', width: '7.5vw' }} >Female</Button>
                            <Button onClick={() => this.setState({ newMemberGender: GENDER.OTHER })} style={{ backgroundColor: this.state.newMemberGender === GENDER.OTHER ? '#f38b44' : '#e5e5e5', color: this.state.newMemberGender === GENDER.OTHER ? 'white' : '#908f8f', textTransform: 'capitalize', width: '7.5vw' }} >Other</Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={this.addMember} disabled={!this.state.newMemberName || !this.state.newMemberDob || !this.state.newMemberGender || !this.state.newMemberLop || !this.state.newMemberRelationship} style={{ backgroundColor: !this.state.newMemberName || !this.state.newMemberDob || !this.state.newMemberGender || !this.state.newMemberLop || !this.state.newMemberRelationship ? '#e5e5e5' : '#f38b44', color: !this.state.newMemberName || !this.state.newMemberDob || !this.state.newMemberGender || !this.state.newMemberLop || !this.state.newMemberRelationship ? '#908f8f' : 'white', textTransform: 'capitalize', marginLeft: '3vw', marginTop: '27vh', marginRight: '3vw', width: '80%', height: '7vh', marginBottom: '2vh' }} >
                            <Typography style={{ textAlign: 'center' }}>Add Member</Typography>
                        </Button>
                    </Grid>
                </Grid>
                </div>
                {/* <div style={{
                            position: 'fixed',
                            bottom: '0px',
                            right: '0px', width: '50vh'
                        }}>
                            <Button>ppip</Button>
                        </div> */}

                {/* <div style={{ position: 'fixed', bottom: '2vh', marginLeft: '1.5vw', right: '1.5vw'}}> */}

                {/* </div> */}
            </div>

        )
    };

    renderCustomAvatar = (dob, gender, memberId, dependentType) => {
        const { classes } = this.props;
        const age = moment().diff(dob, 'years');
        if (gender === 'female') {
            if (age < 18) {
                return memberId !== this.props[dependentType] ? <ChildFemale className={classes.avatar} /> : <ChildFemaleSelected className={classes.avatar} />
            } else {
                return memberId !== this.props[dependentType] ? <AdultFemale className={classes.avatar} /> : <AdultFemaleSelected className={classes.avatar} />
            }
        } else {
            if (age < 18) {
                return memberId !== this.props[dependentType] ? <ChildMale className={classes.avatar} /> : <ChildMaleSelected className={classes.avatar} />
            } else {
                return memberId !== this.props[dependentType] ? <AdultMale className={classes.avatar} /> : <AdultMaleSelected className={classes.avatar} />
            }
        }
    }

    selectDependent = (memberId, dependentType) => {
        if (dependentType === 'selectedSpouseCustomerId') {
            this.props.setParentState('selectedSpouseCustomerId', memberId)
        }
        if (dependentType === 'selectedKid1CustomerId') {
            this.props.setParentState('selectedKid1CustomerId', memberId)
        }
        if (dependentType === 'selectedKid2CustomerId') {
            this.props.setParentState('selectedKid2CustomerId', memberId)
        }
        if (dependentType === 'selectedKid3CustomerId') {
            this.props.setParentState('selectedKid3CustomerId', memberId)
        }
    }

    isAvatarDisabled = (dependentType, dob, memberId) => {
        const { selectedSubscription } = this.props;
        // const adultAgeConstraints = selectedSubscription.product.constraint.adult_age;
        const adultAgeConstraints = {
            min : {
                value: 18,
                unit: 'years'
            },
            max : {
                value: selectedSubscription?.age ?? 65,
                unit: 'years'
            }
        }
        const childAgeConstraints = selectedSubscription.product.constraint.child_age;
        // basic validations;
        if (!dob) {
            return true;
        }
        if (memberId === this.props.selectedPrimaryCustomerId) {
            return true;
        }
        // checking if this member has been selected already;
        if (dependentType === 'selectedSpouseCustomerId' && (memberId === this.props.selectedKid1CustomerId || memberId === this.props.selectedKid2CustomerId)) {
            return true;
        }
        if (dependentType === 'selectedKid1CustomerId' && (memberId === this.props.selectedSpouseCustomerId || memberId === this.props.selectedKid2CustomerId)) {
            return true;
        }
        if (dependentType === 'selectedKid2CustomerId' && (memberId === this.props.selectedSpouseCustomerId || memberId === this.props.selectedKid1CustomerId)) {
            return true;
        }
        if (dependentType === 'selectedKid3CustomerId' && (memberId === this.props.selectedSpouseCustomerId || memberId === this.props.selectedKid1CustomerId
            || memberId === this.props.selectedKid2CustomerId)) {
            return true;
        }
        // if not already selected, checking age validations;
        if (dependentType === 'selectedSpouseCustomerId' && (adultAgeConstraints.min.value<=this.getAgeInYears(dob) && this.getAgeInYears(dob)<=adultAgeConstraints.max.value)) {
            return false;
        }
        if (dependentType === 'selectedKid1CustomerId' && (moment().isBetween(
            moment(dob) + moment.duration(childAgeConstraints.min.value, childAgeConstraints.min.unit),
            moment(dob) + moment.duration(childAgeConstraints.max.value, childAgeConstraints.max.unit)
            ))) {
            return false;
        }
        if (dependentType === 'selectedKid2CustomerId' && (moment().isBetween(
            moment(dob) + moment.duration(childAgeConstraints.min.value, childAgeConstraints.min.unit),
            moment(dob) + moment.duration(childAgeConstraints.max.value, childAgeConstraints.max.unit)
            ))) {
            return false;
        }
        if (dependentType === 'selectedKid3CustomerId' && (moment().isBetween(
            moment(dob) + moment.duration(childAgeConstraints.min.value, childAgeConstraints.min.unit),
            moment(dob) + moment.duration(childAgeConstraints.max.value, childAgeConstraints.max.unit)
            ))) {
            return false;
        }
        else {
            return true;
        }
    }

    getAgeInYears =(dobTimestamp)=>{
        const dob = new Date(dobTimestamp);
        const now = new Date();
        let age = now.getFullYear() - dob.getFullYear();

        // Adjust age if birthday hasn't occurred yet this year
        if (now.getMonth() < dob.getMonth() || (now.getMonth() === dob.getMonth() && now.getDate() < dob.getDate())) {
            age--;
        }
        return age;
    }

    avatarDisabledTooltipMessage = (dependentType, dob, memberId) => {
        const { selectedSubscription } = this.props;
        // const adultAgeConstraints = selectedSubscription.product.constraint.adult_age;
        const adultAgeConstraints = {
            min : {
                value: 18,
                unit: 'years'
            },
            max : {
                value: selectedSubscription?.age ?? 65,
                unit: 'years'
            }
        }
        const childAgeConstraints = selectedSubscription.product.constraint.child_age;
        const adultTooltipMessage = `Member age should be between ${adultAgeConstraints.min.value + " " + adultAgeConstraints.min.unit}`
        + " - "
        + `${adultAgeConstraints.max.value + " " + adultAgeConstraints.max.unit}`;
        const childTooltipMessage = `Member age should be between ${childAgeConstraints.min.value + " " + childAgeConstraints.min.unit}`
        + " - "
        + `${childAgeConstraints.max.value + " " + childAgeConstraints.max.unit}`;
        if (!dob) {
            return null;
        }
        if (memberId === this.props.selectedPrimaryCustomerId) {
            return 'Cannot be same as Primary Member'
        }
        if (dependentType === 'selectedSpouseCustomerId' && !(adultAgeConstraints.min.value<=this.getAgeInYears(dob) && this.getAgeInYears(dob)<=adultAgeConstraints.max.value)) {
            return adultTooltipMessage;
        }
        if (dependentType === 'selectedKid1CustomerId' && (!moment().isBetween(
            moment(dob) + moment.duration(childAgeConstraints.min.value, childAgeConstraints.min.unit),
            moment(dob) + moment.duration(childAgeConstraints.max.value, childAgeConstraints.max.unit)
            ))) {
            return childTooltipMessage;
        }
        if (dependentType === 'selectedKid2CustomerId' && (!moment().isBetween(
            moment(dob) + moment.duration(childAgeConstraints.min.value, childAgeConstraints.min.unit),
            moment(dob) + moment.duration(childAgeConstraints.max.value, childAgeConstraints.max.unit)
            ))) {
            return childTooltipMessage;
        }
        if (dependentType === 'selectedKid3CustomerId' && (!moment().isBetween(
            moment(dob) + moment.duration(childAgeConstraints.min.value, childAgeConstraints.min.unit),
            moment(dob) + moment.duration(childAgeConstraints.max.value, childAgeConstraints.max.unit)
            ))) {
            return childTooltipMessage;
        }
        if (dependentType === 'selectedSpouseCustomerId' && (memberId === this.props.selectedKid1CustomerId || memberId === this.props.selectedKid2CustomerId)) {
            return 'Already Selected';
        }
        if (dependentType === 'selectedKid1CustomerId' && (memberId === this.props.selectedSpouseCustomerId || memberId === this.props.selectedKid2CustomerId)) {
            return 'Already Selected';
        }
        if (dependentType === 'selectedKid2CustomerId' && (memberId === this.props.selectedSpouseCustomerId || memberId === this.props.selectedKid1CustomerId)) {
            return 'Already Selected';
        }
        if (dependentType === 'selectedKid3CustomerId' && (memberId === this.props.selectedSpouseCustomerId || memberId === this.props.selectedKid1CustomerId
            || memberId === this.props.selectedKid2CustomerId)) {
            return 'Already Selected';
        } else {
            return '';
        }
    }

    renderAllMembers = (headerText, dependentType) => {
        const { classes } = this.props;
        return (
            <div>
                <div>
                    <div className={classes.root}>
                        <Typography style={{ fontSize: '1.2rem', color: '#172a3a', fontFamily: 'Rubik-Regular', marginTop: '-2vh', marginBottom: '0.5vh', marginLeft: '5vw' }}>{headerText}</Typography>
                        <div >
                            <Grid container spacing={3} style={{
                                marginLeft: '3vw'
                                , width: '80%'
                            }}>
                                {this.props.newPlan.allMembersOfCurrentFamily.members.map((member) => {
                                    return (
                                        <Tooltip title={this.avatarDisabledTooltipMessage(dependentType, member.dob, member.id)} key={member.id}>
                                            <Grid item xs={"auto"} >
                                                <IconButton
                                                    disabled={this.isAvatarDisabled(dependentType, member.dob, member.id)}
                                                    style={{ opacity: this.isAvatarDisabled(dependentType, member.dob, member.id) ? '0.3' : '1' }}
                                                    className={classes.avatarButton} onClick={() => this.selectDependent(member.id, dependentType)}> {this.renderCustomAvatar(member.dob, member.gender, member.id, dependentType)}</IconButton>
                                                <div style={{ textAlign: 'center' }}>
                                                    <Typography style={{ fontSize: '0.7rem', marginTop: '-2vh' }}>{member.first_name}</Typography>
                                                </div>
                                            </Grid>
                                        </Tooltip>
                                    )
                                })}
                                {this.props.newPlan && this.props.newPlan.allMembersOfCurrentFamily && this.props.newPlan.allMembersOfCurrentFamily.total < 6 ? <Grid item xs={1}  >
                                    <IconButton className={classes.avatarButton} onClick={this.openAddMemberDrawer}> <AddNewMember className={classes.avatar} /></IconButton>
                                    <div style={{ textAlign: 'center' }}>
                                        <Typography style={{ fontSize: '0.7rem', marginTop: '-2vh', marginLeft: '0.55vw', width: '5vw' }}>Add New</Typography>
                                    </div>
                                </Grid> : <></>}
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const {
            selectedSubscription
        } = this.props;
        const { product } = selectedSubscription;
        const { constraint } = product;
        if (this.props.newPlan && _.isNil(this.props.newPlan.allMembersOfCurrentFamily)) {
            return (
                <div style={{ height: '53vh', overflowY: 'scroll' }}>
                    <div style={{ marginTop: '10vh' }}>
                        {this.getCircularProgress()}
                    </div>
                </div>
            );
        } else if (this.props.newPlan && this.props.newPlan.allMembersOfCurrentFamily && this.props.newPlan.allMembersOfCurrentFamily.members && !_.isEmpty(this.props.newPlan.allMembersOfCurrentFamily.members)) {
            return (
                <>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        {
                            constraint.allowed_members.adult > 1 && 
                                <div style={{ height: '53vh', overflowY: 'scroll' }}>
                                    <div style={{ marginTop: '10vh' }}>
                                        {this.renderAllMembers('Select the Spouse:', 'selectedSpouseCustomerId')}
                                    </div>
                                </div>
                        }

                        {constraint.allowed_members.child === 1 &&
                            <div style={{ height: '53vh', overflowY: 'scroll' }}>
                                <div style={{ marginTop: '10vh' }}>
                                    {this.renderAllMembers('Select the Kid:', 'selectedKid1CustomerId')}
                                </div>
                            </div>
                        }

                        {constraint.allowed_members.child > 1 &&
                            <>
                                <div style={{ height: '53vh', overflowY: 'scroll' }}>
                                    <div style={{ marginTop: '10vh' }}>
                                        {this.renderAllMembers('Select the 1st Kid:', 'selectedKid1CustomerId')}
                                    </div>
                                </div>
                                <div style={{ height: '53vh', overflowY: 'scroll' }}>
                                    <div style={{ marginTop: '10vh' }}>
                                        {this.renderAllMembers('Select the 2nd Kid:', 'selectedKid2CustomerId')}
                                    </div>
                                </div>
                                {
                                    constraint.allowed_members.child === 3
                                    ? (<div style={{ height: '53vh', overflowY: 'scroll' }}>
                                        <div style={{ marginTop: '10vh' }}>
                                            {this.renderAllMembers('Select the 3rd Kid:', 'selectedKid3CustomerId')}
                                        </div>
                                    </div>)
                                    : null
                                }
                            </>
                        }


                    </div>
                    < Drawer anchor="right" open={this.state.addMemberDrawer} onClose={this.closeAddMemberDrawer}>
                        <Paper style={{ width: '62vh', overflowY: 'hidden' }}>
                            {this.renderAddMemberDrawerContent()}
                        </Paper>
                    </Drawer>

                </>
            );
        } else {
            return (
                <div style={{ height: '53vh', overflowY: 'scroll' }}>
                    <div style={{ marginTop: '5vh' }}>Something went wrong !!</div>)
                </div>
            );
        }
    }
}

SelectDependents.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error, familyDetails: state.familyDetails, newPlan: state.newPlan, newCustomer: state.newCustomer };
};

export default connect(mapStateToProps, { getAllMembersByFamilyId, resetGetAllMembersByFamilyId, resetNewMemberAdded, addNewMemberByFamilyId, resetUpdateMember })(withStyles(styles)(SelectDependents));