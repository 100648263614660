import React from "react";
import { connect } from "react-redux";
import LoginPage from "./LoginPage";
import SideMenuBar from "../components/Home/sideMenuBar/SideMenuBar";
import TopBar from "../components/Home/topBar/TopBar";
import { withStyles } from "@material-ui/core/styles/";
import orange from "@material-ui/core/colors/orange";
import { resetCustomerPhoneNumber } from "../actions";
import CareFreshCaseCard from "../components/CareUploadTask/CareFreshCaseCard";
import CareErrorCaseCard from "../components/CareUploadTask/CareErrorCaseCard";
import CareRenewalCaseCard from "../components/CareUploadTask/CareRenewalCaseCard";

const styles = (theme) => ({
  palette: {
    primary: orange,
  },
  indicator: {
    backgroundColor: "orange",
  },
  default_tabStyle: {
    color: "grey",
  },
  active_tabStyle: {
    fontWeight: "bold",
    color: "black",
  },
});
class CareDocUpload extends React.Component {
  componentDidMount() {
    this.props.resetCustomerPhoneNumber();
  }

  render() {
    if (!this.props.auth.isLoggedIn) {
      return <LoginPage />;
    } else {
      return (
        <div
          className="main"
          style={{
            height: "98vh",
            minWidth: "80vw",

            backgroundColor: "#f4f4f4",
          }}
        >
          <TopBar />
          <div style={{ display: "flex" }}>
            <SideMenuBar />

            <div style={{ margin: "16px", width: "96.5%", marginRight:"40px" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                }}
              >
                <div style={{ marginRight: "10px", width: "50%" }}>
                  <CareFreshCaseCard />
                </div>
                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <CareErrorCaseCard />
                </div>
              </div>
              <div style={{ marginTop: "20px", width: "49.5%" }}>
                  <CareRenewalCaseCard />
                </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth, error: state.error };
};

export default connect(mapStateToProps, { resetCustomerPhoneNumber })(
  withStyles(styles)(CareDocUpload)
);
