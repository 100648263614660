import {
    UPDATE_CUSTOMER_CREDIT,
    UPDATE_CUSTOMER_CREDIT_RESPONSE,
    RESET_UPDATE_CUSTOMER_CREDIT_FILE
} from '../actions/types';

const INITIAL_STATE = {
    updateCustomerCreditResponse: null,
    loadingCustomerCreditResponse: true,
};

const updateCustomerCreditReducer = (state = { INITIAL_STATE }, action) => {
    switch (action.type) {
        case UPDATE_CUSTOMER_CREDIT:
            return { ...state, updateCustomerCreditResponse: null, loadingCustomerCreditResponse: true }
        case UPDATE_CUSTOMER_CREDIT_RESPONSE:
            return { ...state, updateCustomerCreditResponse: action.payload, loadingCustomerCreditResponse: false }
        case RESET_UPDATE_CUSTOMER_CREDIT_FILE:
            return { ...state, updateCustomerCreditResponse: null, loadingCustomerCreditResponse: false }
        default:
            return state;
    }
};

export default updateCustomerCreditReducer;