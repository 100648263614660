import Joi from "joi";

const validateEnvIsString = (input) => {
  const schema = Joi.string().required();
  const result = schema.validate(input);
  if (result.value)
  return result.value;
  else
  throw Error(result.error);
};

export default validateEnvIsString;
