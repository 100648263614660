import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid } from "@material-ui/core";
import BulkOnboardingTaskCard from './BulkOnboardingTaskCard';
import UpdateCustomerCredit from './UpdateCustomerCredit'
import { searchProductsByBusinessId, resetProductsByBusinessId } from '../../actions';
import ExtendSubscription from './ExtendSubscription'

const styles = theme => ({
    root: {
        padding: theme.spacing(2),
        flexGrow: 1,
        height: '85vh',
        overflow: 'scroll'
    },
});

class AdminTaskList extends React.Component {

    getCircularProgress = () => {
        return <CircularProgress style={{
            color: 'orange',
            left: '50%',
            top: '55%',
            position: 'absolute',
        }} disableShrink />;
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <BulkOnboardingTaskCard />
                    </Grid>
                    <Grid item xs={4}>
                        <UpdateCustomerCredit />
                    </Grid>
                    <Grid item xs={4}>
                        <ExtendSubscription />
                    </Grid>
                </Grid>
            </div>
        )

    }
}

AdminTaskList.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error, familyDetails: state.familyDetails, newPlan: state.newPlan };
};

export default connect(mapStateToProps, { searchProductsByBusinessId, resetProductsByBusinessId })(withStyles(styles)(AdminTaskList));