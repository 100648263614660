import { color } from '../../../../constants/colors';

const useStyles = () => ({
	table: {
		margin: '4vh',
		borderRadius: '4px',
		width: 'calc(100% - 8vh)',
        height: 'calc(97% - 8vh)',
        overflow: 'auto',
		borderBottom: '1px solid grey',
		// overflow: 'hidden'
	},
	tableHeader: {
		display: 'flex',
		flexDirection: 'row',
		gap: '0px',
        position: 'sticky',
        top: '0',
        background: color.WHITE,
        width: '100%',
	},
	headerCol: {
		padding: '1vh 2vh',
		color: color.GREY,
		fontFamily: 'Rubik',
		width: '20%',
		fontSize: '14px',
		border: '0.5px solid grey',
		borderTop: '1px solid grey',
		borderBottom: '1px solid grey',
		'&:first-of-type': {
			borderTopLeftRadius: '4px',
			borderLeft: '1px solid grey',
            width: '40%',
            background: color.WHITE,
		},
		'&:last-of-type': {
			borderRight: '1px solid grey',
			borderTopRightRadius: '4px',
		},
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
        width: '100%',
        background: 'white',
		'&:last-child': {
			'.col:first-child': {
				background: 'salmon',
			},
		},
	},
    tableBody: {
        width: '100%',
        height: '50%'
    },
	col: {
		display: 'flex',
		flexDirection: 'column',
		padding: '2vh',
		borderRight: '1px solid grey',
		borderBottom: '1px solid grey',
        width: '20%',
        justifyContent: 'center',
		fontSize: '14px',
		'&:first-of-type': {
            borderLeft: '1px solid grey',
            width: '40%',
		},
        alignItems: 'flex-start'
	},
    colContent: {
        width: '100%',
        textTransform: 'capitalize',
    },
    link: {
        borderRadius: '4px',
        background: color.oranges[100],
        color: color.ROYAL_ORANGE,
        textDecoration: 'none',
        padding: '1vh 2vh',
        width: 'max-content'
    },
    emptyContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export default useStyles;
