import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Card, Button, TextField, CircularProgress } from "@material-ui/core";
import { ReactComponent as GreenTickOutlined } from '../../images/GreenTickOutlined.svg';
import { ReactComponent as RedCrossOutlined } from '../../images/RedCrossOutlined.svg';
import { ReactComponent as Cross } from '../../assets/icons2/common/cross.svg';
import './BulkOnboardingTaskCard.css';
import { emailRegex } from '../../common/utils';
import { errors } from '../../common/errors';
import stylesFile from './styles'
import { updateCustomerCredit, resetUpdateCustomerCreditFile } from '../../actions/updateCustomerCredit';

const styles = theme => {
    const importedStyles = stylesFile(theme);
    return {
        ...importedStyles,
    }
};

class UpdateCustomerCredit extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            emailId: '',
            selectedFile: '',
            fileKey:Date.now()
        }
    }

    componentDidMount() {
        this.props.resetUpdateCustomerCreditFile();
    };

    handleChange = (event) => {
        const emailText = event.target.value;
        this.setState({
            emailId: emailText.replace(/\s/g, '')
        })
        if (emailText.match(emailRegex)) {
            this.setState({ helperText: '', error:false })
        }
        else if(emailText === ''){
            this.setState({ helperText: '', error:false })
        }
        else {
            this.setState({ helperText: 'Enter a Clinikk id: abc@clinikk.com',error:true })
        }
    }

    getCircularProgress = () => {
        return <CircularProgress style={{
            color: 'orange'
        }} disableShrink />;
    }

    onChangeHandler = event => {
        this.setState({ selectedFile: event.target.files[0] });
    }

    deleteUploadedFile = () => {
        this.setState({ selectedFile: '' });
        document.getElementById("updateCustomerCreditFile").value = "";
    }

    onClickSubmit = () => {
        this.props.updateCustomerCredit(
            this.state.emailId,
            this.state.selectedFile,
        );
        this.setState({
            emailId:'',
            selectedFile:null,
            fileKey:Date.now()
        })
    }
    waitingPeriod = () => {
        setTimeout(() => {
            this.props.resetUpdateCustomerCreditFile()
        }, 4000)
    }

    render() {
        const { classes, customerCredit } = this.props;
        const { selectedFile } = this.state;
        return (
            <Card elevation={1} className={classes.card}>
                <h3 className={classes.title}>
                    Update Customer Credit
                </h3>
                {/* <Divider style={{ height: '2px', backgroundColor: '#FFD07A', margin: '1vh -3vh 1vh -3vh' }} /> */}
                <p className={classes.addRecepients}>Add Recipient </p>
                <div className={classes.emailsTextFieldList}>
                    <TextField fullWidth label="Please enter the email ID"
                        placeholder="abc@clinikk.com"
                        className={classes.emailsTextField} variant="outlined"
                        onChange={this.handleChange}
                        error={this.state.error}
                        helperText={this.state.helperText}
                        value={this.state.emailId}
                    />
                </div>
                <div className={classes.inputFile}>
                    <input type="file"
                        name="file"
                        id="updateCustomerCreditFile"
                        accept='application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        onChange={this.onChangeHandler}
                        key={this.state.fileKey}
                    />
                    { selectedFile ? <Cross className={classes.deleteUploadedFileButton} onClick={this.deleteUploadedFile} /> : '' }
                </div>
                <div className={classes.cardDivider} />
                <Button disabled={!this.state.selectedFile || !this.state.emailId}
                    fullWidth variant="contained"
                    style={{ backgroundColor: !this.state.selectedFile || !this.state.emailId ? '#cbcbcb' : '#f38b44' }}
                    className={`${classes.uploadButton} updateCustomerCredit`} onClick={this.onClickSubmit}> Upload
                </Button>

                {customerCredit && customerCredit.loadingCustomerCreditResponse ? <div>{this.getCircularProgress()}</div> :
                    <div className={classes.fileStatusBox}>
                        {!_.isNull(customerCredit.updateCustomerCreditResponse) && customerCredit.updateCustomerCreditResponse === errors.ERROR_IN_UPDATING_CUSTOMER_CREDIT ?
                            <>
                                <RedCrossOutlined />
                                <span className={classes.title} style={{
                                    color: '#d13a24'
                                }}>Upload Failed. Please try again.</span> {this.waitingPeriod()}</>
                            :
                            <>  </>
                        }
                        {!_.isNull(customerCredit.updateCustomerCreditResponse) && customerCredit.updateCustomerCreditResponse === 202 ?
                            <>
                                <GreenTickOutlined />
                                <span
                                    className={classes.title}
                                    style={{ color: '#0d7e51' }}>Upload Successful!
                                </span> {this.waitingPeriod()}</>
                            :
                            <>  </>
                        }
                    </div>}
            </Card>
        )
    }
};

UpdateCustomerCredit.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return { customerCredit: state.customerCredit };
};

export default connect(mapStateToProps, { updateCustomerCredit, resetUpdateCustomerCreditFile })(withStyles(styles)(UpdateCustomerCredit));