import React from 'react';
import { connect } from 'react-redux';

import SideMenuBar from '../components/Home/sideMenuBar/SideMenuBar';
import LoginPage from './LoginPage';
import StepperAddNewCustomer from '../components/AddCustomerTask/StepperAddNewCustomer';
import TopBar from '../components/Home/topBar/TopBar';
import { errors } from '../common/errors';

import { logOut, resetOldFamilyDetails } from '../actions';

import { getQueryParams } from '../common/utils';

class AddNewCustomerPage extends React.Component {
    componentDidMount () {
        this.props.resetOldFamilyDetails();
    };
    
    render() {
        const query = getQueryParams();
        const phoneNumber = query.get('phoneNumber') ?? '' ;
        if (!this.props.details.isLoggedIn ){
            return (
                <LoginPage />
            )
        } else if ((this.props.error && this.props.error.findCustomerByPhoneNumberError === errors.FORBIDDEN)) {
            this.props.logOut();
            return (
                <LoginPage />
            )
        } else {
            return (
                // <div className='main' style={{
                //     height: "98vh",
                //     // width: '5%',
                //     display: 'flex',
                //     // backgroundColor: '#f4f4f4'
                //     backgroundColor: 'white',
                // }}>
                //     <SideMenuBar />
                //     <div 
                //     // style={{ display: 'block'}}
                //     >
                //    <StepperAddNewCustomer />
                //    </div>
                // </div>

<div className='main' style={{
    height: "98vh",
    minWidth: '80vw',
    // marginLeft: '10vh',
    // display: 'flex',
    backgroundColor: '#f4f4f4',
  
  }}>
    <TopBar/>
    <div style= {{  display: 'flex'}}>
    <SideMenuBar />
    <StepperAddNewCustomer phoneNumber={phoneNumber}/>
    </div>
  
  </div>
            );
        }
    }

};



const mapStateToProps = (state) => {
    return { details: state.auth, error: state.error };
};


export default connect(mapStateToProps, { logOut, resetOldFamilyDetails })(AddNewCustomerPage);