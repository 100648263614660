import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography, Divider, Drawer, Paper, Button, Card, CardContent, CircularProgress } from "@material-ui/core";
import { getAvailableServicesInProduct, getProductPriceAfterDiscount } from '../../../common/utils';
import { ReactComponent as AccidentalNB } from '../../../images/servicesNB/AccidentalNB.svg';
import { ReactComponent as PrimaryCareNB } from '../../../images/servicesNB/PrimaryCareNB.svg';
import { ReactComponent as HealthInsuranceNB } from '../../../images/servicesNB/HealthInsuranceNB.svg';
import { ReactComponent as HospicashNB } from '../../../images/servicesNB/HospicashNB.svg';
import { ReactComponent as CloseButtonXActive } from '../../../images/CloseButtonXActive.svg';
import { ReactComponent as GreenTick } from '../../../images/GreenTick.svg';
import { insuranceMemberRelationship } from '../../../common/constants';
import { createSubscription, resetCreateSubscription, getCustomerDetails } from '../../../actions';
import { CREATED } from 'http-status-codes';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    card: {
        padding: theme.spacing(2),
        height: '34vh',
        width: '50vw',
        overflowY: 'scroll',
        color: theme.palette.text.secondary,
    },
    element: {
        padding: theme.spacing(0.5),
    },
    textField: {
        color: 'orange',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: '10vh',
    },
    avatarButton: {
        height: '12vh',
        width: '6vw',
    },
    avatar: {
        height: '46px',
        width: '46px',
    },
    relationButton: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
});
class ReviewOrder extends React.Component {

    state = { clickedYesForSubscribe: false };

    componentDidMount() {
        this.props.resetCreateSubscription();
    };

    getCurrentFamilyId = () => {
        if (this.props.newPlan && this.props.newPlan.customerByPhoneNumber && this.props.newPlan.customerByPhoneNumber.family && this.props.newPlan.customerByPhoneNumber.family.id) {
            return this.props.newPlan.customerByPhoneNumber.family.id;
        } else if (this.props.newCustomer && this.props.newCustomer.createHeadSubscriber && this.props.newCustomer.createHeadSubscriber.newFamily && this.props.newCustomer.createHeadSubscriber.newFamily.data && this.props.newCustomer.createHeadSubscriber.newFamily.data.family && this.props.newCustomer.createHeadSubscriber.newFamily.data.family.id) {
            return this.props.newCustomer.createHeadSubscriber.newFamily.data.family.id;
        } else {
            return '';
        }
    }

    getNewSubscriptionObject = () => {
        let newSubscriptionObject = {
            product: {
                id: this.props.productInCart.id,
            },
            family: {
                id: this.getCurrentFamilyId(),
            },
            primary_care: {
                members: [
                    {
                        customer: {
                            id: this.props.selectedPrimaryCustomerId,
                        },
                        relationship: insuranceMemberRelationship.PRIMARY,
                    }
                ]
            },
            payment_multiplier: this.props.selectedPaymentMultiplier,
        };
        if (_.hasIn(this.props.productInCart, 'hospicash')) {
            _.assignIn(newSubscriptionObject, {
                hospicash: {
                    members: [
                        {
                            customer: {
                                id: this.props.selectedPrimaryCustomerId,
                            },
                            relationship: insuranceMemberRelationship.PRIMARY,
                        }
                    ]
                },
            })
        }
        if ((_.hasIn(this.props.productInCart, 'health_insurance')) && this.props.selectedNomineeCustomerId && this.props.nomineeRelationshipWithPrimary) {
            _.assignIn(newSubscriptionObject, {
                health_insurance: {
                    nominee: {
                        customer: {
                            id: this.props.selectedNomineeCustomerId
                        },
                        relationship: this.props.nomineeRelationshipWithPrimary,
                    },
                    members: [
                        {
                            customer: {
                                id: this.props.selectedPrimaryCustomerId,
                            },
                            relationship: insuranceMemberRelationship.PRIMARY,
                        }
                    ]
                },
            })
        }
        if ((_.hasIn(this.props.productInCart, 'accidental_insurance')) && this.props.selectedNomineeCustomerId && this.props.nomineeRelationshipWithPrimary) {
            _.assignIn(newSubscriptionObject, {
                accidental_insurance: {
                    nominee: {
                        customer: {
                            id: this.props.selectedNomineeCustomerId
                        },
                        relationship: this.props.nomineeRelationshipWithPrimary,
                    },
                    members: [
                        {
                            customer: {
                                id: this.props.selectedPrimaryCustomerId,
                            },
                            relationship: insuranceMemberRelationship.PRIMARY,
                        }
                    ]
                },
            })
        }
        return newSubscriptionObject;
    }

    createPayment = () => {
        this.setState({ clickedYesForSubscribe: true });
        this.props.createSubscription(this.getNewSubscriptionObject());
    }

    getServiceIcon = (service) => {
        switch (service) {
            case 'accidental_insurance':
                return <AccidentalNB style={{ height: '2vh', width: '1.5vw', marginTop: '0.5vh' }} />
            case 'health_insurance':
                return <HealthInsuranceNB style={{ height: '2vh', width: '1.5vw', marginTop: '0.5vh' }} />
            case 'hospicash':
                return <HospicashNB style={{ height: '2vh', width: '1.5vw', marginTop: '0.5vh' }} />
            default:
                return <PrimaryCareNB style={{ height: '2vh', width: '1.5vw', marginTop: '0.5vh' }} />
        }
    }

    productHasAccidentalOrHealth = () => {
        return _.hasIn(this.props.productInCart, 'health_insurance') || _.hasIn(this.props.productInCart, 'accidental_insurance')
    }

    closeSubscribeDrawer = () => {
        this.setState({ clickedYesForSubscribe: false });
        this.props.resetCreateSubscription();
        this.props.closeSubscribeDrawer();
    }

    renderSubscriptionSuccesfullComponent = () => {
        return (
            <>
                <div style={{ marginLeft: '37%', marginRight: '37%' }}>
                    <GreenTick style={{ height: '7vh', width: '7vw' }} />
                </div>
                <Typography style={{ color: ' #172a3a', fontWeight: 'bold', fontSize: '1.3rem', fontFamily: 'Rubik-Medium', marginTop: '1rem', marginLeft: '4vh', marginRight: '4vh', textAlign: 'center' }}>
                    Subscription Successful!
            </Typography>
                <Typography style={{ color: ' #172a3a', fontWeight: 'bold', fontSize: '1rem', fontFamily: 'Rubik', marginTop: '1rem', marginLeft: '4vh', marginRight: '4vh', textAlign: 'center' }}>
                    An SMS with payment link is sent to the customer. Please request them to make a payment using that link.
            </Typography>
                <Button onClick={() => this.props.getCustomerDetails(this.props.newPlan.newPlanMobileNumber)} style={{ backgroundColor: 'orange', marginLeft: '37.5%', marginRight: '37.5%', width: '25%', color: 'white', textTransform: 'capitalize', fontFamily: 'Rubik-Regular', marginTop: '2vh' }}>Done</Button>
            </>
        )
    }

    renderSubscriptionConfirmationComponent = () => {
        return (
            <div>
                <Typography style={{ color: ' #172a3a', fontWeight: 'bold', fontSize: '1rem', fontFamily: 'Rubik', marginTop: '45vh', marginLeft: '35%', marginBottom: '2vh' }}> Are you sure ?</Typography>
                <div style={{ marginBottom: '30%', marginLeft: '23%', marginRight: '20%' }}>
                    <Button onClick={this.closeSubscribeDrawer} variant="outlined" style={{ color: 'orange', borderColor: 'orange', width: '40%' }}>No</Button>
                    <Button onClick={this.createPayment} variant="outlined" style={{ marginLeft: '15%', color: 'orange', borderColor: 'orange', width: '40%' }} >Yes</Button>
                </div>
            </div>
        )
    }

    renderSomethingWentWrong = () => {
        return (
            <>
                <Typography style={{ color: ' #172a3a', fontWeight: 'bold', fontSize: '0.8rem', fontFamily: 'Rubik', marginTop: '1rem', marginLeft: '2vh', marginRight: '2vh', textAlign: 'center' }}>
                    Something went wrong !
            </Typography>
                <Button onClick={this.closeSubscribeDrawer} style={{ backgroundColor: 'orange', marginLeft: '37.5%', marginRight: '37.5%', width: '25%', color: 'white', textTransform: 'capitalize', fontFamily: 'Rubik-Regular', marginTop: '2vh' }}>Cancel</Button>
            </>
        )

    }

    getCircularProgress = () => {
        return <CircularProgress style={{
            color: 'orange',
            left: '50%',
            top: '55%',
            position: 'absolute',
        }} disableShrink />;
    }



    renderSubscriptionDrawer = () => {
        if (this.state.clickedYesForSubscribe && this.props.newPlan && _.isNil(this.props.newPlan.subscribedPlan)) {
            return this.getCircularProgress();
        } else if (this.state.clickedYesForSubscribe && this.props.newPlan && this.props.newPlan.subscribedPlan && this.props.newPlan.subscribedPlan.newSubscriptionStatus === CREATED) {
            return this.renderSubscriptionSuccesfullComponent();
        } else if (!this.state.clickedYesForSubscribe) {
            return this.renderSubscriptionConfirmationComponent();
        } else {
            return this.renderSomethingWentWrong();
        }
    }

    getNumberOfMembers = (forValue) => {
        switch (forValue) {
            case 'couple':
                return '2 Members';
            case 'family':
                return '4 Members';
            case 'self':
                return '1 Member';
            default:
                return ''
        }
    }

    render() {
        const { classes, newPlan, productInCart } = this.props;
        const product = productInCart;
        const availableServicesList = getAvailableServicesInProduct(product);
        return (
            <div style={{ height: '53vh', overflowY: 'auto' }}>
                <Grid container spacing={0}>
                    <Grid item xs={8}>

                        <Card elevation={3} className={classes.card} style={{ width: '50vw', marginLeft: '5vw', marginTop: '3vh', }}>
                            <Grid item xs={4} >
                                <Typography style={{ fontSize: '1rem', fontFamily: 'Rubik-Medium', color: '#172a3a', textAlign: 'center', marginLeft: '-5vw' }}>{_.truncate(product.name, { 'length': 50 })}</Typography>
                                <CardContent style={{ backgroundColor: '#FFFFFF', marginTop: '-0.5vh', width: '15vw' }}>
                                    {availableServicesList.map((service) => {
                                        return (
                                            <div style={{ float: 'left', marginTop: '0.7vh' }} key={service.type}>
                                                <span>{this.getServiceIcon(service.type)}</span>
                                                <div style={{ float: 'right', marginTop: '-0.5vh', marginBottom: '1.3vh', marginLeft: '1vw' }}>
                                                    <Typography style={{ width: '12vw', fontSize: '0.8rem', fontFamily: 'Rubik-Regular', color: '#172a3a' }}> {_.capitalize(_.split(service.type, '_').join(' '))} </Typography>
                                                    <Typography style={{ fontFamily: 'Rubik-Regular', fontSize: '0.6rem' }}>{this.getNumberOfMembers(service.value.for)}</Typography>
                                                </div>
                                            </div>
                                        )
                                    }
                                    )}
                                    <Paper style={{ backgroundColor: 'white', marginLeft: '28vw' }}>
                                        <Typography style={{ width: '12vw', fontSize: '0.8rem', fontFamily: 'Rubik-Regular', color: '#908f8f' }}> Primary Member </Typography>
                                        <Typography style={{ fontFamily: 'Rubik-Medium', fontSize: '1rem', color: '#172a3a', width: '15vw' }}>
                                            {newPlan.allMembersOfCurrentFamily && newPlan.allMembersOfCurrentFamily.members && !_.isEmpty(newPlan.allMembersOfCurrentFamily.members) ? _.find(newPlan.allMembersOfCurrentFamily.members, { id: this.props.selectedPrimaryCustomerId }).first_name : ''}
                                        </Typography>

                                        {this.productHasAccidentalOrHealth() && <><Typography style={{ marginTop: '2.5vh', width: '12vw', fontSize: '0.8rem', fontFamily: 'Rubik-Regular', color: '#908f8f' }}> Nominee </Typography>
                                            <Typography style={{ fontFamily: 'Rubik-Medium', fontSize: '1rem', color: '#172a3a', width: '15vw' }}>
                                                {newPlan.allMembersOfCurrentFamily && newPlan.allMembersOfCurrentFamily.members && !_.isEmpty(newPlan.allMembersOfCurrentFamily.members) ? _.find(newPlan.allMembersOfCurrentFamily.members, { id: this.props.selectedNomineeCustomerId }).first_name : ''}
                                            </Typography></>}
                                        <Typography style={{ marginTop: '1.5vh', width: '12vw', fontSize: '0.8rem', fontFamily: 'Rubik-Regular', color: '#908f8f' }}>
                                            {_.capitalize(this.props.selectedPaymentMultiplier)}
                                        </Typography>
                                        <Typography style={{ fontFamily: 'Rubik-Medium', fontSize: '1rem', color: '#172a3a' }}> {'₹' + getProductPriceAfterDiscount(this.props.productInCart, this.props.selectedPaymentMultiplier)}</Typography>

                                    </Paper>
                                </CardContent>
                            </Grid>
                            <Grid item xs={4}>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
                <Drawer disableEnforceFocus anchor="right" open={this.props.subscribeButtonPressed} onClose={this.closeSubscribeDrawer} >
                    <div style={{ width: '30vw', height: '100vh' }} >
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Typography style={{ color: 'black', fontWeight: 'bold', fontSize: '1.2rem', fontFamily: 'Rubik', marginTop: '1.5rem', marginLeft: '0.8rem', marginBottom: '0.6rem' }}>Confirm</Typography>
                            <CloseButtonXActive style={{ marginTop: '1.9rem', marginRight: '2vh', cursor: 'pointer' }} onClick={this.closeSubscribeDrawer} />
                        </div>
                        <Divider style={{ backgroundColor: 'orange' }} />
                        <div style={{ marginTop: '80%', marginBottom: '30%' }}>
                            {this.renderSubscriptionDrawer()}
                        </div>
                    </div>
                </Drawer>
            </div>
        )
    }
}

ReviewOrder.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error, familyDetails: state.familyDetails, newPlan: state.newPlan, newCustomer: state.newCustomer };
};

export default connect(mapStateToProps, { createSubscription, resetCreateSubscription, getCustomerDetails })(withStyles(styles)(ReviewOrder));
