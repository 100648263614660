const useStyles = (theme) => ({
  subscriptionCard: {
    height: '40px',
    backgroundColor: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '20px',
    '&:hover': {
      boxShadow: "0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22)",
      cursor: "pointer",
      backgroundColor: '#e5e5e5',
    },
  },
  mobileNumber: {
    position: 'absolute',
    left: '20px',
    fontSize: 'calc(0.6em + 0.5vmin)',
    fontFamily: 'Manrope-Bold',
    color: '#000000',
  },
  planName: {
    position: 'absolute',
    left: '160px',
    fontSize: 'calc(0.6em + 0.5vmin)',
    fontFamily: 'Manrope-Regular',
    color: '#908f8f',
  },
  totalMembers: {
    position: 'absolute',
    left: '410px',
    fontSize: 'calc(0.6em + 0.5vmin)',
    fontFamily: 'Manrope-Regular',
    color: '#908f8f',
  },
  dateOfPurchase: {
    position: 'absolute',
    left: '550px',
    fontSize: 'calc(0.6em + 0.5vmin)',
    fontFamily: 'Manrope-Regular',
    color: '#908f8f',
  },
  businessNameBox: {
    position: 'absolute',
    left: '730px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  businessName: {
    fontSize: 'calc(0.6em + 0.5vmin)',
    fontFamily: 'Manrope-Regular',
    color: '#908f8f',
  },
  GhdStatus: {
    position: 'absolute',
    left: '950px',
    fontSize: 'calc(0.6em + 0.5vmin)',
    fontFamily: 'Manrope-Regular',
    color: '#908f8f',
  },
  copyIcon: {
    marginLeft: '10px',
  },
  addDetailsButton: {
    position: 'absolute',
    right: '140px',
    fontSize: 'calc(0.4em + 0.5vmin)',
    textTransform: 'capitalize',
    backgroundColor: '#47b29f',
    color: '#ffffff',
    width: '100px',
  },
  placeCallButton: {
    position: 'absolute',
    right: '20px',
    fontSize: 'calc(0.4em + 0.5vmin)',
    textTransform: 'capitalize',
    backgroundColor: '#0033cc',
    color: '#ffffff',
    width: '100px',
  },
  planCancellationButton: {
    position: 'absolute',
    right: '140px',
    fontSize: 'calc(0.4em + 0.5vmin)',
    textTransform: 'capitalize',
    backgroundColor: '#f66a6a',
    color: '#ffffff',
  },
  root: {
    margin: theme.spacing(-2),
    marginTop: "6rem"
  },
  backButton: {
    height: "48px",
    width: "48px",
  },
  translateButton: {
    height: "48px",
    width: "48px"
  },
  callButton: {
    height: "48px",
    width: "48px",
    marginLeft: theme.spacing(2),
  },
  title: {
    fontSize: "16px",
    fontFamily: "Manrope-Bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000000",
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  form: {
    padding: theme.spacing(3),
    marginTop: '-30px',
  },
  formGroup: {
    marginBottom: theme.spacing(2),
    width: "100%",
    height: "48px",
    borderRadius: "4px",
    backgroundColor: "#f2f5fc",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: 'relative',
    cursor: 'pointer',
  },
  icon: {
    marginLeft: theme.spacing(1),
    borderRadius: "50%",
    width: 21,
    height: 21,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto #0033cc',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#0033cc',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 21,
      height: 21,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#0033cc',
    },
  },
  formControlLabel: {
    height: "22px",
    fontFamily: "Manrope-Bold!important",
    fontSize: "26px!important",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000000",
    borderRadius: "50%",
    width: "29%"
  },
  validity: {
    display: "inline-block",
    marginLeft: "5rem",
    textAlign: "center",
    fontSize: "calc(0.8em + 0.7vmin)",
  },
  phoneNumberBox: {
    position: 'absolute',
    left: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  copyIconPhone: {
    marginLeft: '110px',
    backgroundColor: '#F38B44',
    padding: '3px 6px',
    borderRadius: '3px',
  },
  copyIconSubscription: {
    marginLeft: '10px',
    backgroundColor: '#F38B44',
    padding: '3px 6px',
    borderRadius: '3px',
  },
  placeCallDialogBox: {
    width: '100vw',
  }
});

export default useStyles;