import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import useStyles from './style';
import {
	withStyles,
	Typography,
	Button,
	Box,
	Tooltip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	CircularProgress,
} from '@material-ui/core';
import { getISTFormat6, getFormattedGhdStatusOfSubscription } from '../../../common/utils';
import { strings } from '../../../constants/strings';
import ProductUtil from '../../../common/ProductUtil';
import { ReactComponent as CopyIcon } from '../../../assets/icons/copy-icon.svg';
import { connectTeleCall, resetConnectTeleCall } from '../../../actions/index';
import { tncStatus } from '../../../common/constants';

const productUtil = new ProductUtil();

class SubscriptionCard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			parentHover: false,
		};
	}

	componentDidMount() {
		this.props.resetConnectTeleCall();
	}

	setSelectedSubscriptionInParent = () => {
		this.props.setSelectedSubscriptionInParent(this.props.subscription);
	};

	copyPhoneNumberToClipboard = (subscription) => {
		navigator.clipboard.writeText(subscription.primary_number).then(
			() => {
				this.props.setCopiedText(subscription.primary_number);
			},
			function(err) {
				this.setState({ copiedText: '' });
			}
		);
	};

	connectTeleCall = (phoneNumber) => {
		this.props.connectTeleCall(this.props.auth.authDetails.data.user.id, phoneNumber);
	};

	closeTeleCallDialog = () => {
		this.props.resetConnectTeleCall();
	};

	renderPlaceCallDialog = () => {
		const { classes } = this.props;
		return (
			<Dialog
				open={this.props.pwaDetails && this.props.pwaDetails.showPlaceCallDialog}
				onClose={this.closeTeleCallDialog}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				className={classes.placeCallDialogBox}
			>
				{this.props.pwaDetails &&
				this.props.pwaDetails.placeTeleCall &&
				this.props.pwaDetails.placeTeleCall.success &&
				this.props.pwaDetails.placeTeleCall.success.status === 'success' ? (
					<>
						{' '}
						<DialogTitle id='alert-dialog-title'>{'SUCCESS'}</DialogTitle>
						<DialogContent>
							<DialogContentText id='alert-dialog-description'>
								Wait for at least 2 minutes to receive the call. Please answer to
								speak with the customer.
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.closeTeleCallDialog} color='primary' autoFocus>
								DISMISS
							</Button>
						</DialogActions>{' '}
					</>
				) : (
					<CircularProgress
						style={{
							color: 'orange',
							left: '50%',
							top: '50%',
							// position: 'absolute',
						}}
						disableShrink
					/>
				)}
			</Dialog>
		);
	};

	isPEDAccepted(subscription) {
		const { tnc } = subscription;
		if (tnc) {
			_.forEach(tnc, (item) => {
				if (item.document_type === strings.PED_DOCUMENT && !item.accepted) {
					return !item.accepted;
				} else if (item.document_type === strings.PED_DOCUMENT && item.accepted) {
					return item.accepted;
				}
			});
		}
		return false;
	}

	render() {
		const { classes, subscription, activeTabIndex, position } = this.props;
		return (
			<div
				className={`${classes.subscriptionCard} ${
					activeTabIndex === 0
						? `${'completedSale-' + position}`
						: `${'pendingSale-' + position}`
				}`}
				onMouseOver={() => this.setState({ parentHover: true })}
				onMouseOut={() => this.setState({ parentHover: false })}
			>
				<Box className={classes.phoneNumberBox}>
					<Typography
						ref={(phoneTextarea) => (this.phoneTextarea = phoneTextarea)}
						noWrap
						className={classes.mobileNumber}
					>
						{subscription.primary_number ? subscription.primary_number : '-'}
					</Typography>
					<Tooltip
						title={
							subscription.primary_number === this.props.copiedText
								? `${this.props.copiedText} copied to clipboard`
								: 'Click to copy'
						}
						placement='right-start'
					>
						<div
							className={classes.copyIconPhone}
							style={{ display: this.state.parentHover ? '' : 'none' }}
							onClick={() => this.copyPhoneNumberToClipboard(subscription)}
						>
							<CopyIcon color='primary' />
						</div>
					</Tooltip>
				</Box>
				<Typography noWrap className={classes.planName}>
					{subscription.product
						? _.truncate(subscription.product.name, { length: 32 })
						: '-'}
				</Typography>
				{this.props.activeTabIndex === 0 && (
					<Typography noWrap className={classes.totalMembers}>
						{subscription.product
							? productUtil.getPlanForValueText(subscription.product.for)
							: '-'}
					</Typography>
				)}

				{this.props.activeTabIndex === 1 && (
					<Typography noWrap className={classes.totalMembers}>
						{subscription.product && subscription.product.business.name
							? subscription.product.business.name
							: '-'}
					</Typography>
				)}

				<Typography noWrap className={classes.dateOfPurchase}>
					{subscription.start_date ? getISTFormat6(subscription.start_date) : '-'}
				</Typography>
				{this.props.activeTabIndex === 0 && (
					<Box className={classes.businessNameBox}>
						<Typography
							ref={(textarea) => (this.textArea = textarea)}
							noWrap
							className={classes.businessName}
						>
							{subscription.product.business && subscription.product.business.name
								? subscription.product.business.name
								: '-'}
						</Typography>
					</Box>
				)}
				{this.props.activeTabIndex === 0 && (
					<Typography noWrap className={classes.GhdStatus}>
						{getFormattedGhdStatusOfSubscription(subscription)}
					</Typography>
				)}
				{this.props.activeTabIndex === 0 && (
					<Button
						style={{ display: this.state.parentHover ? '' : 'none' }}
						disabled={
							this.isPEDAccepted(subscription)
							|| subscription.tncStatus === tncStatus.FAILED
						}
						className={classes.addDetailsButton}
						onClick={this.setSelectedSubscriptionInParent}
					>
						Add Details
					</Button>
				)}
				<Button
					style={{ display: this.state.parentHover ? '' : 'none' }}
					className={classes.placeCallButton}
					onClick={() => this.connectTeleCall(subscription.primary_number)}
				>
					Place Call
				</Button>
				{this.props.subscription.id && <>{this.renderPlaceCallDialog()}</>}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const { auth, product, order, pwaDetails } = state;
	return { auth, product, order, pwaDetails };
};

const mapDispatchToProps = (dispatch) => {
	return {
		connectTeleCall: (partnerId, phoneNumber) => {
			dispatch(connectTeleCall(partnerId, phoneNumber));
		},
		resetConnectTeleCall: () => {
			dispatch(resetConnectTeleCall());
		},
	};
};

const ConnectedSubscriptionCard = connect(mapStateToProps, mapDispatchToProps)(SubscriptionCard);
export default withStyles(useStyles, { withTheme: true })(ConnectedSubscriptionCard);
