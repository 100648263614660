/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React from 'react';
import css from './style.module.scss';

function ToggleSwitch({ onChange, active }) {
  const content = (
    <>
      <label className={css.switch} >
        <input type="checkbox" onChange={onChange} checked = {active} />
        <span className={css.slider}></span>
      </label>
    </>
  );
  return content;
}

export default ToggleSwitch;
