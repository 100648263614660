
import React, { useEffect, useState } from "react";
import { Chip, Popover, withStyles, Button, createTheme } from "@material-ui/core";
import useStyles from "./SlotSelectionStyle";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { ThemeProvider } from "@material-ui/styles";
import { color as colorConstants } from "../../../../constants/colors";
import { connect } from "react-redux";
import { ReactComponent as ChevronDown } from "../../../../assets/icons/chevron-down.svg";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const SlotSelection = (props) => {
  
  const [selectedDate, setSelectedDate] = useState(props?.isVisit ? '' : moment()); //Set selectedDate as today's by default

  const selectedDateFormatted = moment(selectedDate).format("YYYY-MM-DD");
  const defaultMaterialTheme = createTheme({
    palette: {
      primary: {
        main: colorConstants.ROYAL_ORANGE,
      },
    },
  });

  //In the auth object, Monday is assigned value 0 in the array, but 
  //the moment.day() function returns 0 as Sunday.
  let dayOfWeek = moment(selectedDate).day()-1 === -1 ? 6 : moment(selectedDate).day()-1;

  //Set time as current time if CHH is open else null
  const chhTimingsofDay = props.auth.currentClinicLocation.timings[dayOfWeek];
  const getCurrentTime = chhTimingsofDay ? moment().format("hh:mm A") : null;
  const [time, setTime] = useState(props?.isVisit ? '' : getCurrentTime);
  const [morningSlots, setMorningSlots] = useState([]);
  const [eveningSlots, setEveningSlots] = useState([]);

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [bookForNowFlag, setBookForNowFlag] = useState(false); //Variable to manage the disabling of time slots when "Book For Now" is selected and color of the chips

  // We need a `ref`erence to the button component
  // in order to anchor the popover component to
  // one of it's corners.
  const [timeRef, setTimeRef] = useState(null);

  
  useEffect(() => {
    const morningSlotsList = [];
    const eveningSlotsList = [];
    props.handleDate(selectedDate);
  
    if (chhTimingsofDay) {
      
      const morningSlotStart = moment(
        chhTimingsofDay.shifts[0].opening_time,
        "hh:mm A"
      );
    
      const morningSlotEnd = moment(
        chhTimingsofDay.shifts[0].closing_time,
        "hh:mm A"
      );
      
      const eveningSlotStart = moment(
        chhTimingsofDay.shifts[1].opening_time,
        "hh:mm A"
      );
      const eveningSlotEnd = moment(
        chhTimingsofDay.shifts[1].closing_time,
        "hh:mm A"
      );
    
      // Creates time slots 30 mins apart 
      const numberOfMorningSlots = morningSlotEnd.diff(morningSlotStart, 'minutes') / 30;
      for (let i = 0; i <= numberOfMorningSlots; i++) {
        morningSlotsList.push(morningSlotStart.clone().add(i * 30, 'minutes').format(props?.isVisit ? "h:mm A" : "hh:mm A"))
        setMorningSlots(morningSlotsList)
      }
    
      const numberOfEveningSlots = eveningSlotEnd.diff(eveningSlotStart, 'minutes') / 30;
      for (let i = 0; i <= numberOfEveningSlots; i++) {
        eveningSlotsList.push(eveningSlotStart.clone().add(i * 30, 'minutes').format(props?.isVisit ? "h:mm A" : "hh:mm A"));
        setEveningSlots(eveningSlotsList);
      }
    }
  }, [props, selectedDate, chhTimingsofDay])

  useEffect(()=>{
    props.handleTime(time);
  }, [props, time]);

  const classes = props.classes;

  const isTimeSelectionDisabled = () => {
    if ( chhTimingsofDay && !props.autoAssignDoctor)
      return false;
    else
      return true;
  }

  const handleTimeConfirmation = (timeSlot) => {
    setTime(timeSlot) 
    props.handleTime(time);  
    setTimeRef(null);
    setBookForNowFlag(false);
  };

  const handleTimeConfirmationForVisit = (timeSlotStart, timeSlotEnd) => {
    setTime(`${timeSlotStart}-${timeSlotEnd}`)
    props.handleTime(time);  
    setTimeRef(null);
    setBookForNowFlag(false);
  };

  const handleDateConfirmation = (date) => {
    setSelectedDate(date);
    props.handleDate(date);
    setTime("");
    props.handleTime("");
    setBookForNowFlag(false);
  };

  const checkIfSlotShouldDisable = (timeSlot) => {
    let timeSlotFormatted = moment(timeSlot, "hh:mm A").format("HH:mm:ss");
    let dateTimeUnixForCurrentTimeSlot = moment(selectedDateFormatted + " " + timeSlotFormatted);
    const isDisabled = ((moment().isBefore(dateTimeUnixForCurrentTimeSlot))) ? false : true ;
    return isDisabled;
  }
 
  //On clicking Book For Now, the bookForNowFlag's state is altered and time is set to current time.
  const handleBookForNow = () => {
      setTime(moment().format("hh:mm A"));
      props.handleTime(time);
      setTimeRef(null);
      setBookForNowFlag(() => !bookForNowFlag);
  }

  const isBookForNowDisabled = () => {
    const isBookNowDisabled = ((moment().isBefore(selectedDate))) ? true : false ;
    return isBookNowDisabled;
  }

  return (
    <>
      <div className={classes.container}>
      <div className={classes.dateTimeContainer}>
          <span className={classes.caption}>Date</span>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Button
              className={classes.__button}
              onClick={() =>
                setOpenDatePicker((openDatePicker) => !openDatePicker)
              
              }
              endIcon={!props.autoAssignDoctor && <ChevronDown/>}
              disabled={props.autoAssignDoctor} 
            >
              {/* If date is selected then button should display the date */}
              {selectedDate
                ? moment(selectedDate).format("MMM Do[,] YYYY")
                : 'Select Date'}
            </Button>
            <ThemeProvider theme={defaultMaterialTheme}>
              <DatePicker
                className={classes.datePicker}
                value={selectedDate}
                open={openDatePicker}
                onClose={() => setOpenDatePicker(false)}
                onChange={handleDateConfirmation}
                disablePast={true}
              ></DatePicker>
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </div>
        <div className={classes.dateTimeContainer}>
          <span className={classes.caption}>Time</span>
          <Button
            className={classes.__button}
            onClick={(event) => setTimeRef(event.currentTarget)}
            endIcon={!props.autoAssignDoctor && <ChevronDown/>}
            disabled={isTimeSelectionDisabled()}
          >     
            {time ? time : "Select Time"}
          </Button>
        </div>

        <Popover
          // Setting setTimeRef as the anchor
          // for our time popover
          anchorEl={timeRef}
          className={classes.timePopover}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={Boolean(timeRef)}
          onClose={() => setTimeRef(null)}
        >
          <div className={classes.popoverContainer}>
            <div className={classes.bookNowChip}>

              { props.isVisit ? <></> : 
              <Chip 
                      className={`${classes.__chip} ${bookForNowFlag? classes.chipSelected : classes.chipUnselected}`}
                      label="Book For Now" 
                      onClick={handleBookForNow}
                      disabled={isBookForNowDisabled()}
              >
              </Chip>}
            </div>
            <div className={classes.slot}>
            <div className={classes.separatorContainer} disabled>
              <span className={classes.separator}>Morning Slots</span>
              <hr className={classes.divider} />
            </div>
              <div>
                {/* Returns time slots and selected slot shows up in orange on the time popover */}
                {morningSlots?.map((timeSlot, index) => {
                  return (
                    <>
                      {
                        props.isVisit 
                        ? 
                        index < (morningSlots.length - 1) 
                        ?
                          <Chip
                            key={index}
                            className={`${classes.__chip} ${time === timeSlot ? classes.chipSelected : classes.chipUnselected}`}
                            label={`${timeSlot} - ${morningSlots[index+1]}`}
                            onClick={()=>handleTimeConfirmationForVisit(timeSlot, morningSlots[index+1])}
                            disabled={checkIfSlotShouldDisable(timeSlot)}
                          />
                          :
                          <></>
                        :
                        <Chip
                          key={index}
                          className={`${classes.__chip} ${time === timeSlot ? classes.chipSelected : classes.chipUnselected}`}
                          label={`${timeSlot}`}
                          onClick={()=>handleTimeConfirmation(timeSlot)}
                          disabled={checkIfSlotShouldDisable(timeSlot)}
                        />
                      }
                    </>
                  );
                })}
              </div>
            </div>
            <div className={classes.slot}>
            <div className={classes.separatorContainer} disabled>
              <span className={classes.separator}>Evening Slots</span>
              <hr className={classes.divider} />
            </div>              
            <div>
                {eveningSlots?.map((timeSlot, index) => {
                  return (
                    <>
                      {
                        props.isVisit 
                        ? 
                        index < (eveningSlots.length - 1) 
                        ?
                          <Chip
                            key={index}
                            className={`${classes.__chip} ${time === timeSlot ? classes.chipSelected : classes.chipUnselected}`}
                            label={`${timeSlot} - ${eveningSlots[index+1]}`}
                            onClick={()=>handleTimeConfirmationForVisit(timeSlot, eveningSlots[index+1])}
                            disabled={checkIfSlotShouldDisable(timeSlot)}
                          />
                          :
                          <></>
                        :
                        <Chip
                          key={index}
                          className={`${classes.__chip} ${time === timeSlot ? classes.chipSelected : classes.chipUnselected}`}
                          label={`${timeSlot}`}
                          onClick={()=>handleTimeConfirmation(timeSlot)}
                          disabled={checkIfSlotShouldDisable(timeSlot)}
                        />
                      }
                    </>
                  );
                })}
              </div>
            </div>    
          </div>
        </Popover>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const styleWrapper = withStyles(useStyles, { withTheme: true });
const reduxWrapper = connect(mapStateToProps, null);
/**
 * 
 * @param {object} props `handleTime, handleDate, selectedTime` accepts Date and Time respectively 
 * and selectedTime gives us the time string of the selected time slot
 * @returns JSX for 1) Date CTA and Date Picker. 2) Time CTA and time slot selection popover 
 */
export default reduxWrapper(styleWrapper(SlotSelection));
