import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
    Box, Typography, Button, TextField, Snackbar,
    Checkbox, FormGroup, FormControlLabel,
    CircularProgress,
} from '@material-ui/core';
import style from './style';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import MuiAlert from '@material-ui/lab/Alert';
import config from '../../../config';
import { sendMandateLinkEvent } from "../../../actions/mandateAction";
import { addonCategories, messageChannel } from '../../../common/constants';
import { color } from '../../../constants/colors';
import { sendCOI } from "../../../clients/customerService";
import { ReactComponent as CopyIcon } from '../../../assets/icons/copy-icon.svg';
import styled from "styled-components";
import { buildCustomError } from "../../../common/utils";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} icon={<CheckIcon style={{width: '20px'}} />}/>;
}

const StyledButton = styled(Button)((props) => ({
    borderRadius: '24px',
    color: props.selected ? color.ROYAL_ORANGE : color.GREY,
    textTransform: 'capitalize',
    marginRight: '8px',
    fontSize: '1rem',
    padding: '0px 8px',
    fontFamily: 'Rubik',
    fontWeight: props.selected ? 'bold' : 'normal',
    border: props.selected ? `1px solid ${color.ORANGE}` : `1px solid ${color.GREY}`,
    ':last-child': {
        marginRight: '0px',
    }
}));

const CoiFormControl = styled(FormControlLabel)({
    color: color.ORANGE,
    '> span.MuiFormControlLabel-label': {
        fontFamily: 'Rubik',
        color: color.GREY,
    },
})

const CoiCheckbox = styled(Checkbox)({
    color: color.ORANGE,
    '&.Mui-checked': {
        color: color.ORANGE,
      },
})

const CopyButton = styled(StyledButton)({
    background: color.ROYAL_ORANGE,
    color: color.WHITE,
    border: `1px solid ${color.ROYAL_ORANGE}`,
    ':hover': {
        background: color.ORANGE,
        border: `1px solid ${color.ORANGE}`

    }
});

class SendLinkDrawer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contactNo: this.props.familyDetails?.currentCustomer?.primary_number ?? '',
            tagSelected: '',
            showSnackBar: false,
            snackbarMessage: '',
            coiToSend: [],
            error: false,
            loading: false,
        }
    }

    handleChange = type => event => {
        this.setState({
            [type]: event.target.value
        })
    }

    handleTagClick(tagName) {
        this.setState({
            tagSelected: tagName,
            tag: tagName !== 'other' ? tagName : ''
        })
        if (tagName === 'copyLink') {
            this.copyLinkToClipboard();
        }
    }

    getCircularProgress = () => {
        return <CircularProgress style={{
            color: color.WHITE,
            height: '20px',
            width: '20px'
        }} />;
    }

    render() {
        const { classes, closeSendLinkDrawer, drawerFor, subscription } = this.props;
        const { contactNo, tagSelected, showSnackBar, snackbarMessage, coiToSend, loading } = this.state;
        const isHealthInsuranceCheckboxDisabled = !(subscription.version === 1
            ? subscription.addons.some((addon) => addon.category === addonCategories.HEALTH_INSURANCE
            && addon.coi_file)
            : subscription.health_insurance && subscription.health_insurance?.coi_file);
        const isAccidentInsuranceCheckboxDisabled = !(subscription.version === 1
            ? subscription.addons.some((addon) => addon.category === addonCategories.ACCIDENTAL_INSURANCE
            && addon.coi_file)
            : subscription.accidental_insurance && subscription.accidental_insurance?.coi_file );
        const isSuperTopUpCheckboxDisabled = !(subscription.version === 1
            ? subscription.addons.some((addon) => [
                addonCategories.SUPER_TOPUP_10L,
                addonCategories.SUPER_TOPUP_50L
              ].includes(addon.category) && addon.coi_file)
            : subscription.addons && subscription.addons?.coi_file);
        let superTopupInsuranceType = null;
        if (subscription?.version === 0) {
            superTopupInsuranceType = "superTopup"
        } else {
            superTopupInsuranceType = subscription.addons
                .filter((addon) => [
                    addonCategories.SUPER_TOPUP_10L,
                    addonCategories.SUPER_TOPUP_50L
                ].includes(addon.category))[0]
                ?.category;
        }

        /**
         * Function to send links for the selected COI's to the customer.
         */
        const handleSendLinkEvent = () => {
            const {
                drawerFor, sendMandateLink, subscription,
                auth
            } = this.props;
            const { contactNo, coiToSend } = this.state;
            if (drawerFor === 'coi') { 
                const access_token = auth.authDetails.data.access_token;
                coiToSend.forEach(coi => {
                    const payload = {
                        family: subscription.family,
                        insurance_type: coi
                    };
                    this.setState({
                        loading: true,
                    });
                    sendCOI(access_token, subscription.id, payload)
                    .then(res => {
                        this.setState({
                            showSnackBar: true,
                            snackbarMessage: 'Link sent successfully!',
                            loading: false,
                        })
                    })
                    .catch(err => {
                        const error = buildCustomError(err);
                        this.setState({
                            showSnackBar: true,
                            snackbarMessage: error.message,
                            error: true,
                            loading: false,
                        })
                    })
                })
            }
            if (drawerFor === 'mandate') { 
                const channel = messageChannel.SMS;
                const subscriptionId = subscription.id;
                sendMandateLink(contactNo, channel, subscriptionId);
            }
        }
    
        /**
         * Function to copy the required link to the clipboard
         */
        const copyLinkToClipboard = () => {
            const { drawerFor, subscription } = this.props;
            if (drawerFor === 'coi') {
                const { subscription } = this.props;
                const { coiToSend } = this.state;
                const coiUrl = `${config.apiURL}/v4/subscriptions/${subscription.id}/coi/download`;
                let stringToCopy = ``;
                if (coiToSend.includes('health')) {
                    stringToCopy += `\r\nHealth Insurance: ${coiUrl + '?insuranceType=health'}`;
                }
                if (coiToSend.includes('accidental')) {
                    stringToCopy += `\r\nAccidental Insurance: ${coiUrl + '?insuranceType=accidental'}`;
                }
                if (coiToSend.includes(superTopupInsuranceType)) {
                    stringToCopy += `\r\nSuper Top Up: ${coiUrl + `?insuranceType=${superTopupInsuranceType}`}`;
                }
                navigator.clipboard.writeText(stringToCopy)
                .then(() => {
                    this.setState({
                        showSnackBar: true,
                        snackbarMessage: 'Link copied successfully!',
                    })
                })
            }
            if (drawerFor === 'mandate') {
                const link = `${config.appURL}/mandates?subscriptionId=${subscription.id}`;
                navigator.clipboard.writeText(link)
                .then(() => {
                    this.setState({
                        showSnackBar: true,
                        snackbarMessage: 'Link copied successfully!',
                    })
                });
            }
        };

        const handleCoiCheckboxOnChange = (coiType) => {
            let updatedCoiToSend = [...coiToSend];
            if (coiToSend.some((coi) => coi === coiType)) {
                updatedCoiToSend = coiToSend.filter(coi => coi !== coiType);
            } else {
                updatedCoiToSend.push(coiType);
            }
            this.setState({
                coiToSend: updatedCoiToSend,
            });
        }

        return (
            <>
                <Snackbar
                    open={showSnackBar}
                    onClose={() => this.setState((prevState) =>({showSnackBar: !prevState.showSnackBar}))}
                    style={{
                        marginBottom: '60px',
                        marginRight: '-1vh',
                        width: '320px',
                        borderRadius: '8px'
                    }}
                    autoHideDuration={800}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <Alert style={{
                            backgroundColor: color.greens[100],
                            width: '300px',
                            fontFamily: 'Rubik',
                            fontWeight: 'bold',
                            borderRadius: '8px'
                        }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>

                <Box className={classes.header}>
                    { drawerFor === 'mandate' && <Typography className={classes.bold}>Send Mandate Link</Typography>}
                    { drawerFor === 'coi' && <Typography className={classes.bold}> Send COI</Typography>}
                    <CloseIcon className={classes.closeIcon} onClick={() => closeSendLinkDrawer()} />
                </Box>
                <Box className={classes.sendLinkContainer}>
                    <TextField disabled={ drawerFor === 'coi' } onChange={this.handleChange('contactNo')} className={classes.formField} value={contactNo} label="Contact Number" />
                    {
                        drawerFor === 'coi' && (
                            <Box className={classes.sendViaTagContainer}>
                                {/* Checkboxes to select COI's to send */}
                                <Typography className={classes.bold}>Choose the COI to Send</Typography>
                                <Box className={classes.sendViaTags}>
                                    <FormGroup>
                                        <CoiFormControl
                                            control={<CoiCheckbox
                                                checked={coiToSend.includes['health']}
                                                onChange={() => handleCoiCheckboxOnChange('health')}
                                                indeterminate={isHealthInsuranceCheckboxDisabled}
                                                disabled={isHealthInsuranceCheckboxDisabled}
                                                />
                                            }
                                            label="Health Insurance"
                                        />
                                        <CoiFormControl
                                            control={<CoiCheckbox
                                                checked={coiToSend.includes[superTopupInsuranceType]}
                                                onChange={() => handleCoiCheckboxOnChange(superTopupInsuranceType)}
                                                indeterminate={isSuperTopUpCheckboxDisabled}
                                                disabled={isSuperTopUpCheckboxDisabled}
                                                />
                                            }
                                            label="Super Top Up"
                                        />
                                        <CoiFormControl
                                            control={<CoiCheckbox
                                                checked={coiToSend.includes['accidental']}
                                                onChange={() => handleCoiCheckboxOnChange('accidental')}
                                                indeterminate={isAccidentInsuranceCheckboxDisabled}
                                                disabled={isAccidentInsuranceCheckboxDisabled}
                                                />
                                            }
                                            label="Accidental Insurance"
                                        />
                                    </FormGroup>
                                </Box>
                            </Box>)
                    }
                    <Box className={classes.sendViaTagContainer}>
                        <Typography className={classes.bold}>Send Via</Typography>
                        <Box className={classes.sendViaTags}>
                            <StyledButton
                                onClick={() => this.handleTagClick('sms')}
                                // className={`${classes.tagNameButton} ${tagSelected === 'sms' ? classes.tagNameButtonSelected : ''}`}
                                variant="outlined"
                                selected={tagSelected === 'sms'}
                            >
                                SMS {tagSelected === 'sms' && <CheckIcon className={`${classes.royalOrangeColor} ${classes.mL5}`} fontSize="small" />}
                            </StyledButton>
                            <StyledButton
                                onClick={() => this.handleTagClick('whatsapp')}
                                selected={tagSelected === 'whatsapp'}
                                // className={`${classes.tagNameButton} ${tagSelected === 'whatsapp' ? classes.tagNameButtonSelected : ''}`}
                                variant="outlined"
                                disabled
                            >
                                WhatsApp {tagSelected === 'whatsapp' && <CheckIcon className={`${classes.royalOrangeColor} ${classes.mL5}`} fontSize="small" />}
                            </StyledButton>
                            <CopyButton
                                onClick={() => {
                                    copyLinkToClipboard();
                                }}
                                // className={`${classes.tagNameButton} ${tagSelected === 'copyLink' ? classes.tagNameButtonSelected : ''}`}
                                variant="outlined"
                            >
                                <CopyIcon />
                                Copy link {tagSelected === 'copyLink' && <CheckIcon className={`${classes.royalOrangeColor} ${classes.mL5}`} fontSize="small" />}
                            </CopyButton>
                        </Box>
                    </Box>
                    <Button
                        onClick={() => handleSendLinkEvent()}
                        className={classes.sendLinkActionButton}
                        disabled={!(this.state.contactNo?.match(/^\d{10}$/))
                        || (tagSelected === 'copyLink')
                        || (tagSelected === '')
                        || (coiToSend.length === 0)}
                    >
                        {loading ? this.getCircularProgress() : 'Send Now'}
                    </Button>
                </Box>
            </>
        )
    }

}

const mapStateToProps = (state) => {
    const {
        familyDetails,
        newCustomer,
        auth
    } = state;
    return {
        familyDetails,
        newCustomer,
        auth
    };
};

const mapDispatchToProps = (dispatch) => ({
    sendMandateLink: (contactNo, channel, subscriptionId ) => { dispatch(sendMandateLinkEvent(contactNo, channel, subscriptionId)) }
});

const ConnectedSendLinkDrawer = withStyles(style,
    { withTheme: true })(connect(mapStateToProps,
        mapDispatchToProps)((SendLinkDrawer)));
export default ConnectedSendLinkDrawer;