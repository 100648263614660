import React from 'react';

import { connect } from 'react-redux';
import { Typography, MenuItem, Menu } from '@material-ui/core';
import { Paper, Drawer } from '@material-ui/core';
import { IconButton, Fade } from '@material-ui/core';
import AddVitalsForm from './AddVitalsForm';
import { resetConsultationById, resetAddVitalsStatus } from '../../../../actions';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import { consultationStatus, consultationType } from '../../../../common/constants';
import config from '../../../../config';
import useStyles from './particularConsultationStyle';
import { withStyles } from '@material-ui/styles';
import moment from 'moment';
const { getISTTimeFormat, checkIfManager, checkIfHA } = require('../../../../common/utils');

class ParticularConsultationCard extends React.Component {
    state = {
        anchorEl: null,
        vitalsDrawer: false, // make it back false
    };

    componentDidMount() {
        this.props.resetAddVitalsStatus();
    };

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
        this.props.resetConsultationById();
    };

    openVitalsDrawer = () => {
        this.setState({ vitalsDrawer: true });
    }

    closeVitalsDrawer = () => {
        this.setState({ vitalsDrawer: false });
        this.props.resetConsultationById();
        this.props.resetAddVitalsStatus();
        this.handleClose();
    }

    handlePrescriptionBtnOnClick = (consultation) => {

    }

   
    renderConsultationCard = () => {
        const { consultation, classes, auth } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const userRoles = auth.authDetails.data.user.roles;
        return (
            <div
                className={`${classes.consultationContainer} ${classes.physicalConsultation}`}
            >
                <div className={classes.consultationBody}>
                    <div className={classes.consultationInfoContainer}>
                        <Typography className={classes.consultationBodyTextHeading}>
                            <span>{ consultation.status === consultationStatus.COMPLETED
                            ?  "Completed At:"
                            :  "Scheduled for:"}
                            </span>
                        </Typography>
                    <Typography
                        className={classes.consultationBodyText}
                    >
                        {consultation.status === consultationStatus.COMPLETED ? 
                        getISTTimeFormat(consultation.completed_at) : 
                        (consultation.scheduled_on ? getISTTimeFormat(consultation.scheduled_on) : getISTTimeFormat(moment().valueOf()))
                        }
                        <Menu
                            //  style = {{ boxShadow: '0',  borderColor: 'blue'}}
                            elevation={1}
                            id="fade-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={this.handleClose}
                            TransitionComponent={Fade}
                        >
                            <MenuItem onClick={this.openVitalsDrawer}>Enter Vitals</MenuItem>
                            {/* {consultation.status === consultationStatus.COMPLETED && !auth?.currentClinicLocation?.name?.includes('Headquarter')  ? 
                                <MenuItem onClick={this.props.openVisitBookDrawer}>Book Appointment</MenuItem> 
                             : 
                                <></>
                            } */}
                        </Menu>
                    </Typography>
                    </div>
                    <div className={classes.consultationInfoContainer}>
                    <Typography className={classes.consultationBodyTextHeading}>
                            <span>Issue:</span>
                        </Typography>
                    <Typography className={`${classes.consultationBodyText} ${classes.consultationSummary}`}>
                        {consultation.summary}
                    </Typography>
                    </div>
                    <div className={classes.consultationInfoContainer}>
                    {
                        (checkIfManager(userRoles) || checkIfHA(userRoles)) && consultation.status === consultationStatus.COMPLETED
                            ? (
                                <a
                                    className={classes.link}
                                    href={`${config.apiURL}/v2/consultations/${consultation.id}/prescriptions/download`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    View Prescription
                                </a>
                            )
                            : null
                    }
                    </div> 
                </div>
                <div
                    className={classes.consultationContext}
                >
                    {
                        consultation.type === consultationType.PHYSICAL
                            ? <IconButton
                                aria-label="More"
                                aria-owns={open ? 'long-menu' : undefined}
                                aria-haspopup="true"
                                disabled={consultation && consultation.type === consultationType.TELE}    /* To hide Enter vitals option when consultation type is 'tele' */
                                onClick={this.handleClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            : <Typography
                                style={{ fontSize: '1.4vh' }}
                            >
                                (Tele-consultation)
                            </Typography>
                    }
                </div>
            </div>
        );
    };

    render() {
        const { consultationDetails } = this.props;
        return (
            <div>
                {this.renderConsultationCard()}
                < Drawer anchor="right" open={this.state.vitalsDrawer} onClose={this.closeVitalsDrawer}>
                    <Paper style={{ width: '50vh', height: '100%' }}>
                        <AddVitalsForm
                            consultationId={this.props.consultation.id}
                            queue={consultationDetails.representableConsultations}
                            closeDrawer={this.closeVitalsDrawer}
                        />
                    </Paper>
                </Drawer>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return { auth: state.auth, familyDetails: state.familyDetails, error: state.error, consultationDetails: state.consultationDetails };
};

const mapDispatchToProps = (dispatch) => ({
    resetConsultationById,
    resetAddVitalsStatus,
});

const styleWrapper = withStyles(useStyles, { withTheme: true });
const reduxWrapper = connect(mapStateToProps, mapDispatchToProps);
export default styleWrapper(reduxWrapper(ParticularConsultationCard));