import React from 'react';
import { connect } from 'react-redux';
import history from '../../history';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { Link } from 'react-router-dom'

import { setCustomerPhoneNumber } from '../../actions';
import { ReactComponent as ClinikkWhiteLogo } from '../../images/ClinikkWhiteLogo.svg';
import {  clientRoute } from '../../constants';
class HomeOptions extends React.Component {
    constructor() {
        super();
        this.state = {
            customerNumber: '',
            insideCustomerNumberField: false,
            notificationBarDelay: 10,
            disableInput: true,
        };
    }

    componentDidMount() {
        history.push(clientRoute.HOME_PAGE);
    }

    setCustomerPhoneNumber = () => {
        this.props.setCustomerPhoneNumber(this.state.customerNumber);
        this.setState({ notificationBarDelay: 10 })
    };

    setCustomerPhoneNumberOnEnter = (event) => {
        if (event.key === 'Enter' && this.state.customerNumber.length > 3) {
            this.props.setCustomerPhoneNumber(this.state.customerNumber);
            history.push('/loadingFamily');
        }
    };

    render() {
        const inputRegex = /^[c,C,p,P,f,F]{1},[0-9+]*$/;
        const { disableInput } = this.state;
        return (
            <div className='main' style={{
                height: "91vh",
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'row'
            }}
                onKeyPress={(event) => this.setCustomerPhoneNumberOnEnter(event)}
            >
                <div style={{ backgroundColor: "#f4f4f4", width: "50%", height: "91vh" }}>
                    <div style={{
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        margin: "auto"
                    }} >
                        {(this.props.error.currentCustomerErrorCode) ?
                            <div style={{ backgroundColor: '#f66a6a', height: '5vh', }}>
                                <Typography style={{ marginLeft: '3vh', marginTop: '5px', color: 'white' }}> {this.props.error ? this.props.error.currentCustomerErrorCode : ''}</Typography> </div>
                            : <> </>}
                        <div style={{ marginTop: '20vh', marginLeft: '3vh' }}>
                            <h3 style={{ fontSize: '30px', fontFamily: 'Rubik' }}>Enter number to find existing member details.</h3>
                            <TextField id="standard-basic"
                                autoFocus
                                autoComplete="off"
                                helperText={this.state.customerNumber.length > 0 && !this.state.insideCustomerNumberField && this.state.customerNumber.length < 4 ? 'Please enter a familyId or 10 digit phone number' : ''}
                                error={!this.state.insideCustomerNumberField && this.state.customerNumber.length > 0 && this.state.customerNumber.length < 4}
                                onMouseOver={() => this.setState({ insideCustomerNumberField: true })}
                                onMouseLeave={() => this.setState({ insideCustomerNumberField: false })}
                                inputProps={{
                                    maxLength: 12,
                                }}
                                onInput={(e) => e.target.value}
                                onChange={(event) => {
                                    this.setState({
                                        customerNumber: event.target.value,
                                        disableInput: event.target.value ? event.target.value.includes(',') &&
                                        !inputRegex.test(event.target.value)
                                        : event.target.value.length > 5,
                                    });
                                }}
                                style={{ paddingBottom: "3vh", width: "35%" }} />
                            <Button variant="outlined"
                                component={Link} to="/loadingFamily"
                                onClick={this.setCustomerPhoneNumber}
                                style={{
                                    textTransform: "capitalize", display: "block", color: "orange", width: '15vh', textAlign: 'center',
                                }}
                                disabled={disableInput}
                            >
                                Search
                            </Button>
                        </div>

                    </div>

                </div>

                <div style={{ backgroundColor: "#ffe6b1", width: "50%", textAlign: 'center', height: '91vh' }}>
                    <ClinikkWhiteLogo style={{ marginTop: '30vh' }} />
                    <div style={{
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        marginTop: '20vh'
                    }} ></div>
                    <Button variant="contained"
                        component={Link} to="/addCustomer"
                        style={{ backgroundColor: "orange", color: "white" }}
                    >
                        +Add New Customer
</Button>

                </div>
            </div>
        )
    }
};

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error, familyDetails: state.familyDetails };
}

export default connect(mapStateToProps, { setCustomerPhoneNumber })(HomeOptions);