import { paymentActionTypes } from '../constants/paymentConstant';;

const INITIAL_STATE = {

};

const paymentReducer = (state = { INITIAL_STATE, shortLink: null }, action) => {
    switch (action.type) {
        case paymentActionTypes.CREATE_OFFLINE_PAYMENT_REQUEST:
            return { ...state, creditDeductionResponseLoading: true }
        case paymentActionTypes.CREATE_OFFLINE_PAYMENT_SUCCESS:
            return { ...state, creditDeductionResponseLoading: false, creditDeductionResponse: action.payload }
        case paymentActionTypes.CREATE_OFFLINE_PAYMENT_FAILURE:
            return { ...state, creditDeductionResponseLoading: false, creditDeductionResponse: 'failed' }
        case paymentActionTypes.RESET_OFFLINE_PAYMENT:
            return { ...state, creditDeductionResponseLoading: null, creditDeductionResponse: null }
        case paymentActionTypes.CREATE_SHORTLINK_REQUEST:
            return {
                ...state, shortLink: {
                    isLoading: true,
                    status: null,
                    data: null,
                }
            }
        case paymentActionTypes.CREATE_SHORTLINK_SUCCESS:
            return {
                ...state, shortLink: {
                    isLoading: false,
                    status: 'success',
                    data: action.payload,
                }
            }
        case paymentActionTypes.CREATE_SHORTLINK_FAILURE:
            return {
                ...state, shortLink: {
                    isLoading: false,
                    status: 'failed',
                    data: null,
                }
            }
        case paymentActionTypes.SEND_NOTIFICATION_REQUEST: {
            return {
                ...state,
                notification: {
                    isLoading: true,
                    status: null,
                },
            }
        }
        case paymentActionTypes.SEND_NOTIFICATION_SUCCESS: {
            return {
                ...state,
                notification: {
                    isLoading: false,
                    status: 'success',
                },
            }
        }
        case paymentActionTypes.SEND_NOTIFICATION_FAILURE: {
            return {
                ...state,
                notification: {
                    isLoading: false,
                    status: 'failure',
                },
            }
        }
        case paymentActionTypes.CREATE_ORDER_REQUEST: {
            return {
                ...state,
                order: {
                    isLoading: true,
                    data: null,
                    status: null,
                }
            }
        }
        case paymentActionTypes.CREATE_ORDER_SUCCESS: {
            return {
                ...state,
                order: {
                    isLoading: false,
                    data: action.payload,
                    status: 'success',
                }
            }
        }case paymentActionTypes.CREATE_ORDER_FAILURE: {
            return {
                ...state,
                order: {
                    isLoading: false,
                    data: null,
                    status: 'failed',
                }
            }
        }
        case paymentActionTypes.RESET_PAYMENT:
            return {
                shortLink: null,
            }
        default:
            return state;
    }
};

export default paymentReducer;
