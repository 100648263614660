import { color } from '../../../constants/colors';

const styles = () => ({
  coiCertificate: {
    display: "flex",
    flexDirection: "column",
    marginTop: "27px",
    border: `1px solid ${color.BRIGHT_GRAY}`,
    borderRadius: "8px",
    padding: "16px",
    width: "100%",
    background: color.GHOST_WHITE,
    boxSizing: "border-box",
  },
  coiCertificateContainer: {
    display: "flex",
    width: "100%",
  },
  coiCertificateContainerIcon: {
    flex: "0.2",
  },
  historyButton: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "14px",
    border: "1px solid #6C2BD9",
    color: "#6C2BD9",
    textTransform: "capitalize",
  },
  opdTransactionsButton: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: 400,
    width: '157px',
    fontSize: "15px",
    lineHeight: "14px",
    border: "1px solid #F38B44",
    color: "#F38B44",
    textTransform: "capitalize",
  },
  eyeIcon: {
    marginRight: "4px",
  },
  coiCertificateTextContainer: {
    flex: "0.8",
    paddingLeft: "8px",
    textAlign: "left",
  },
  uploadButton: {
    color: color.ROYAL_ORANGE,
    border: `1px solid ${color.ROYAL_ORANGE}`,
    marginTop: "16px",
    background: color.WHITE,
    "&:hover": {
      background: color.WHITE,
    },
  },
  coiDocumentActions: {
    marginTop: "16px",
    display: "flex",
    justifyContent: "space-between",
  },
  downloadButton: {
    color: color.WHITE,
    background: color.ROYAL_ORANGE,
    flex: "0.95",
    "&:disabled": {
      background: color.OLD_LACE,
      color: color.ROYAL_ORANGE,
    },
    "&:hover": {
      background: color.ROYAL_ORANGE,
    },
  },
  sendButton: {
    background: color.OLD_LACE,
    border: `1px solid ${color.ROYAL_ORANGE}`,
    padding: "8px",
    minWidth: "auto",
    "&:hover": {
      background: color.OLD_LACE,
    },
  },
  buttonContainer: {
    display: "flex",
    padding: "4px 16px",
    justifyContent: "space-between",
  },
  deleteButton: {
    background: color.INTERNATIONAL_ORANGE,
    padding: "8px",
    minWidth: "auto",
    "&:hover": {
      background: color.INTERNATIONAL_ORANGE,
    },
  },
  cancelButton: {
    color: color.INTERNATIONAL_ORANGE,
    backgroundColor: "#F3D9D9",
    border: `1px solid ${color.INTERNATIONAL_ORANGE}`,
    padding: "4px 16px",
    marginRight: "1vh",
    minWidth: "50%",
    "&:hover": {
      background: "none",
      pointerEvents: "all !important",
    },
    "&:disabled": {
      color: color.INTERNATIONAL_ORANGE,
      cursor: "not-allowed",
      pointerEvents: "all !important",
    },
  },
  withdrawButton: {
    color: color.INTERNATIONAL_ORANGE,
    backgroundColor: "#F3D9D9",
    border: `1px solid ${color.INTERNATIONAL_ORANGE}`,
    padding: "4px 16px",
    marginRight: "1vh",
    minWidth: "50%",
    "&:hover": {
      background: "none",
      pointerEvents: "all !important",
    },
    "&:disabled": {
      color: color.greys[100],
      background: color.greys[500],
      cursor: "not-allowed",
      pointerEvents: "all !important",
    },
  },
  outlineButton: {
    color: color.ROYAL_ORANGE,
    borderColor: color.ROYAL_ORANGE,
    padding: "4px 16px",
    "&:hover": {
      background: "none",
    },
  },
  policyStatusError: {
    display: "flex",
    alignItems: "center",
  },
  ml_4: {
    marginLeft: "4px",
  },
  fullWidth: {
    flex: 1,
  },
  tooltip: {
    padding: "1vh",
    fontFamily: "Rubik",
    color: color.WHITE,
    background: color.BLACK,
    fontSize: "0.8rem",
  },
  toolipArrow: {
    background: color.BLACK,
    color: color.BLACK,
  },
  secondaryButton: {
    marginTop: "1vh",
  },
  dataKey: {
    fontWeight: "700",
    border: "none",
    padding: "12px 12px 12px 24px",
    fontSize: "14px",
    fontFamily: "Rubik",
  },
  dataValue: {
    border: "none",
    padding: "12px",
    fontSize: "14px",
    fontFamily: "Rubik",
    width: "100%",
  },
  ghdStatusPassed: {
    color: color.greens[100],
  },
  ghdStatus: {
    color: color.reds[100],
  },
});
export default styles;


