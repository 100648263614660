import axios from "axios";
import config from "../config";
import { buildCustomError } from "../common/utils";

export const uploadCareSupportingService = async (
  ACCESS_TOKEN,
  fileToUpload,
  endorseType,
  reportUploadProgress = null,
  indexToModify
) => {
  try {
    const url = config.apiURL + "/v1/operations/insurances";
    const data = new FormData();
    data.append("endorse_type", endorseType);
    data.append("files", fileToUpload.file);

    const options = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + ACCESS_TOKEN,
      },
      method: "POST",
      timeout: 10000,
      onUploadProgress: (progressEvent) => {
        const { loaded } = progressEvent;
        if (reportUploadProgress) {
          reportUploadProgress(
            Math.min(fileToUpload.file.size, loaded),
            null,
            indexToModify
          );
        }
      },
      data,
    };

    const response = await axios(url, options);
    return response;
  } catch (e) {
    const error = buildCustomError(e);
    throw error;
  }
};

export const sendCareEndorsementsFilesService = async (
  ACCESS_TOKEN,
  casesEndorsementsPayload,
  endorse_type
) => {
  const url = config.apiURL + "/v1/insurance-batch";
  const data = {
    endorse_type: endorse_type,
    files: casesEndorsementsPayload.files,
  };
  const options = {
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + ACCESS_TOKEN,
    },
    timeout: 10000,

    responseType: "json",
    responseEncoding: "utf8",
  };
  try {
    const response = await axios.post(url, data, options);
    return response;
  } catch (e) {
    const error = buildCustomError(e);
    return error;
  }
};

export const getCareEndorsementsFilesService = async (
  ACCESS_TOKEN,
  endorse_type,
  uploadedBy,
  startDate,
  endDate
) => {
  let url = config.apiURL + `/v1/insurance-batch?endorseType=${endorse_type}`;
  let uploader = uploadedBy;
  let object = { uploader, startDate, endDate };
  object = Object.fromEntries(
    Object.entries(object).filter(([k, v]) => v) // produces { uploader: uploader, startDate: startDate .... }
  );

  for (const property in object) {
    if (property) {
      url += `&${property}=${object[property]}`;
    }
  }

  const options = {
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + ACCESS_TOKEN,
    },
    timeout: 10000,
    responseType: "json",
    responseEncoding: "utf8",
  };
  try {
    const response = await axios.get(url, options);
    return response.data;
  } catch (e) {
    const error = buildCustomError(e);
    return error;
  }
};

export const getDownloadableFilesService = async (
  ACCESS_TOKEN,
  fileType,
  userId
) => {
  const url =
    config.apiURL +
    `/v1/insurance-batch/${userId}/download?fileType=${fileType}`;

  const options = {
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + ACCESS_TOKEN,
    },
    timeout: 10000,
    responseType: "text",
    responseEncoding: "utf8",
  };
  try {
    const response = await axios.get(url, options);
    return response.data;
  } catch (e) {
    const error = buildCustomError(e);
    return error;
  }
};

export const getUploadedByDataService = async (ACCESS_TOKEN) => {
  const url = config.apiURL + `/v1/operations/partners`;

  const options = {
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + ACCESS_TOKEN,
    },
    timeout: 10000,
    responseType: "json",
    responseEncoding: "utf8",
  };
  try {
    const response = await axios.get(url, options);
    return response.data;
  } catch (e) {
    const error = buildCustomError(e);
    return error;
  }
};
