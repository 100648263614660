import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { getInvoicesByFamily } from '../../../actions';
import style from './style';

/**
 * Badge that indicates whether or not there are any renewals pending for a given user.
 */
class RenewalStatusCta extends Component {
    componentDidMount() {
        const { getInvoicesByFamily, familyDetails } = this.props;
        getInvoicesByFamily(familyDetails.currentCustomer.family.id);
    }

    render() {
        const { classes, familyDetails, onClick, invoices } = this.props;
        // todo: add case for data not loading and apiStatus 'failed' 
        if (!familyDetails.invoicesByFamilyId?.isLoading && familyDetails.invoicesByFamilyId?.apiStatus === 'success') {
            if (_.isEmpty(invoices)) {
                return (
                    <div
                        className={`${classes.badge} ${classes.allInvoicesCleared}`}
                        onClick={onClick}
                    >
                        No Payments Pending
                    </div>
                )
            } else {
                return (
                    <div
                        className={`${classes.badge} ${classes.pending}`}
                        onClick={onClick}
                    >
                        Payments Pending
                    </div>
                )
            }
        } else {
            // data loading
            return <div className={classes.badge}>-</div>

        }
    }
}

const mapStateToProps = (state) => {
    const {
        familyDetails,
    } = state;
    return {
        familyDetails,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getInvoicesByFamily: (familyId) => { dispatch(getInvoicesByFamily(familyId)); },
});

const ConnectedRenewalStatusCta = withStyles(style,
    { withTheme: true })(connect(mapStateToProps,
        mapDispatchToProps)(withTranslation()(RenewalStatusCta)));
export default ConnectedRenewalStatusCta;