const styles = () => ({
    header: {
        display: 'flex',
        padding: '30px 32px 10px',
        justifyContent: 'space-between',
        fontFamily: 'rubik',
    },
    opdBox: {
        padding: '30px 24px 10px',
    }
  });
  
  export default styles;
  