import { color } from '../../../constants/colors';
const styles = () => ({
    header: {
        display: 'flex',
        padding: '30px 24px 10px',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${color.ROYAL_ORANGE}`
    },
    bold: {
        fontWeight: 500
    },
    closeIcon: {
        cursor: 'pointer'
    },
    addressContainer: {
        padding: '24px'
    },
    saveChangesButton: {
        backgroundColor: color.ROYAL_ORANGE,
        color: color.WHITE,
        width: '90%',
        position: 'absolute',
        bottom: '28px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: color.ROYAL_ORANGE,
        },
        '&:disabled': {
            opacity: '70%',
            color: color.WHITE,
        }
    },

})

export default styles;