const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    element: {
        padding: theme.spacing(0.5),
    },
    textField: {
        color: 'orange',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: '10vh',
    },
    avatarButton: {
        height: '12vh',
        width: '6vw',
    },
    avatar: {
        height: '46px',
        width: '46px',
    }
});

export default styles;
