import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Divider, Breadcrumbs, Link, Typography, CircularProgress, Button } from "@material-ui/core";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ProductCard from './ProductCard';
import ProductDetail from './ProductDetail';
import CustomerDetails from './CustomerDetails';
import SelectPaymentMultiplier from './SelectPaymentMultiplier';
import { accessRole } from '../../common/constants';
import { searchProductsByBusinessId, resetProductsByBusinessId } from '../../actions';

import './ProductList.scss';

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginRight: '4vw',
        marginTop: '3vh',
        marginLeft: '2vw',
    },
});

const customComponent = {
    CUSTOMER_DETAILS: 'CUSTOMER_DETAILS',
}

class ProductList extends React.Component {


    state = {
        // ************************
        productInCart: {},
        selectedPaymentMultiplier: '',
        currentComponent: '',
        // ************************
    }

    componentDidMount() {
        this.props.resetProductsByBusinessId();
        if (this.props.auth && this.props.auth.authDetails && this.props.auth.authDetails.data && this.props.auth.authDetails.data.user && this.props.auth.authDetails.data.user.metadata && this.props.auth.authDetails.data.user.metadata.businesses && this.props.auth.authDetails.data.user.metadata.businesses && !_.isNil(_.find(this.props.auth.authDetails.data.user.roles, { name: accessRole.HEALTH_MANAGER }))) {
            this.props.searchProductsByBusinessId(this.props.auth.authDetails.data.user.metadata.businesses[0]);  //change it to [0]
        }
    }

    getCircularProgress = () => {
        return <CircularProgress style={{
            color: 'orange',
            left: '50%',
            top: '55%',
            position: 'absolute',
        }} disableShrink />;
    }

    setSelectedPaymentMultiplier = (multiplier) => {
        this.setState({ selectedPaymentMultiplier: multiplier })
    }

    exitAddCustomer = () => {
        this.setState({ selectedPaymentMultiplier: '', currentComponent: '' })
    }

    renderBreadCrumb = () => {
        return (
            <div className='buy-product-bread-crumbs' >
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" style={{ marginTop: '2.5vh' }} />} arial-label="Breadcrumb">
                    <Link color="inherit" onClick={() => this.setState({
                        productInCart: {},
                        selectedPaymentMultiplier: '',
                        currentComponent: ''
                    })}>
                        <Typography style={{
                            color: '#f38b44', marginTop: '2.5vh'
                        }}>PLANS</Typography>
                    </Link>
                    {!_.isEmpty(this.state.productInCart) &&
                        <Link color="inherit" 
                        // onClick={this.exitAddCustomer}
                        >
                            <Typography style={{ marginTop: '2.5vh' }}>{this.state.productInCart.name}</Typography>
                        </Link>
                    }
                    {this.state.currentComponent === customComponent.CUSTOMER_DETAILS && <Link color="inherit">
                        <Typography style={{ marginTop: '2.5vh' }}>Buy Plan</Typography>
                    </Link>
                    }
                </Breadcrumbs>
                <Divider style={{ marginTop: '2vh', marginLeft: '-2vw' }} />
            </div>
        )
    }

    render() {
        const { classes } = this.props;
        if (this.props.newPlan && _.isNil(this.props.newPlan.productList)) {
            if (this.props.auth && this.props.auth.authDetails && this.props.auth.authDetails.data && this.props.auth.authDetails.data.user && (_.isEmpty(this.props.auth.authDetails.data.user.metadata) || _.isEmpty(this.props.auth.authDetails.data.user.metadata.businesses) ||  _.isNil(_.find(this.props.auth.authDetails.data.user.roles, { name: accessRole.HEALTH_MANAGER })))) {
                return <div>Something went wrong !!</div>
            }
            return this.getCircularProgress();
        } else if (this.props.newPlan && this.props.newPlan.productList && this.props.newPlan.productList.products && _.isEmpty(this.props.newPlan.productList.products)) {
            return (
                <div> No products available</div>
            )
        } else if (this.props.newPlan && this.props.newPlan.productList && this.props.newPlan.productList.products && this.props.newPlan.productList.products.length > 0) {
            const productList = this.props.newPlan.productList.products;
            if (_.isEmpty(this.state.productInCart)) {
                return (
                    <div>
                        {this.renderBreadCrumb()}
                        <div>
                            <div className={classes.root}>
                                <Grid container spacing={4} style={{ overflowY: 'auto', height: '78vh' }}>
                                    {productList.map((product) => {
                                        return (
                                            <Grid item xs={6} sm={3} key={product.id}>
                                                <div onClick={() => this.setState({ productInCart: product })}>
                                                    <ProductCard product={product} />
                                                </div>
                                            </Grid>)
                                    })}
                                </Grid>
                            </div>
                        </div>

                    </div>
                )
            } else {
                if (!_.isEmpty(this.state.productInCart) && this.state.currentComponent !== customComponent.CUSTOMER_DETAILS) { //if product is selected]
                    return (
                        <div>
                            {this.renderBreadCrumb()}
                            <ProductDetail product={this.state.productInCart} />
                            <SelectPaymentMultiplier product={this.state.productInCart} setSelectedPaymentMultiplier={this.setSelectedPaymentMultiplier} />
                            <div style={{ marginTop: '3vh' }}>
                                <Button variant="outlined" style={{ float: 'left', color: '#f38b44', borderColor: '#f38b44', textTransform: 'capitalize', width: '9vw', marginLeft: '5vw' }} onClick={() => this.setState({ productInCart: {}, selectedPaymentMultiplier: '', currentComponent: '' })}>Back</Button>
                                <Button disabled={!this.state.selectedPaymentMultiplier} variant="contained" style={{ float: 'right', color: 'white', backgroundColor: !this.state.selectedPaymentMultiplier ? 'grey' : '#f38b44', textTransform: 'capitalize', width: '9vw', marginRight: '5vw' }} onClick={() => this.setState({ currentComponent: customComponent.CUSTOMER_DETAILS })}>Next</Button>
                            </div>
                        </div>
                    )
                } else if ((!_.isEmpty(this.state.productInCart)) && this.state.selectedPaymentMultiplier && this.state.currentComponent === customComponent.CUSTOMER_DETAILS) { //if both product and multiplier are selected]
                    return (
                        <div>
                            {this.renderBreadCrumb()}
                            <CustomerDetails exitAddCustomer={this.exitAddCustomer} productInCart={this.state.productInCart} selectedPaymentMultiplier={this.state.selectedPaymentMultiplier} /></div >
                    )
                } else {
                    return (
                        <div> Something is not right !! Please contact the administrator </div>
                    )
                }
            }
        } else {
            return (
                <div> Error while showing products</div>
            );
        }
    }
}

ProductList.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error, familyDetails: state.familyDetails, newPlan: state.newPlan };
};

export default connect(mapStateToProps, { searchProductsByBusinessId, resetProductsByBusinessId })(withStyles(styles)(ProductList));