import axios from 'axios';

const refreshAuthLogic = (authConfig, refreshToken, authApi, failedRequest) => axios.post(authConfig.accountsURL + authApi, {
  client_id: authConfig.clientID,
  client_secret: authConfig.clientSecret,
  grant_type: 'refresh_token',
  refresh_token: refreshToken,
})
  .then((tokenRefreshResponse) => {
    // eslint-disable-next-line no-param-reassign
    failedRequest.response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.data.access_token}`;
    return Promise.resolve(tokenRefreshResponse);
  })
  .catch((error) => {
    console.log(error);
  });

// eslint-disable-next-line import/prefer-default-export
export const authHelper = {
  refreshAuthLogic,
};
