import React from "react";
import _, { capitalize } from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import css from "./ParticularInvoice.module.scss";
import { HiChevronDown } from "react-icons/hi";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import {
  Button,
  Typography,
  Dialog,
  Modal,
  Paper,
  Box,
  Drawer,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  FormHelperText,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles/";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import orange from "@material-ui/core/colors/orange";
import { errors } from "../../../common/errors";
import {
  cancelSubscription,
  resetCancelWithdrawSubscriptionStatus,
  getCustomerDetails,
  withdrawSubscription,
  updateCohortEvent,
  selectCohort,
  getPlanBillingPrefEvent,
  fetchProductByBusineedId,
  resetProducts,
  resetPlanChange,
  resetOpdTransactions,
  resetCouponValue,
  sendPlanChangeEvent,
  getCouponDiscountEvent,
} from "../../../actions";
import { deleteCOIEvent } from "../../../actions/familyAction";
import {
  subscriptionStatus,
  accessRole,
  paymentMultiplier,
  paymentMultiplierQuantity,
  discountType,
  businessIds,
  qabusinessIds,
  /**
   * Aliasing `tncStatus` as `ghdStatus`
   * to avoid conflicts with `subscription.tncStatus`
   * inadvertently.
   */
  tncStatus as ghdStatus,
  tncStatus,
} from "../../../common/constants";
import styles from "./middleTabStyle";
import ConfirmationModal from "../../common/ConfirmationModal/ConfirmationModal";
import SendLinkDrawer from "../../common/SendLinkDrawer/SendLinkDrawer";
import config from "../../../config/index";
import UploadDrawer from "../../common/UploadDrawer/UploadDrawer";
import { ReactComponent as EyeIcon } from "../../../assets/icons/eye-icon.svg";
import GhdDrawer from "../../common/GhdDrawer/GhdDrawer";
import OpdDrawer from "../../common/OPDDrawer/OpdDrawer"
import { color } from "../../../constants/colors";
import CopyIcon from "../../../assets/icons/copyIcon";
import { ReactComponent as CheckedIcon } from "../../../assets/icons/checkedIcon.svg";

const {
  getISTFormat2,
  getFormattedGhdStatusOfSubscription,
  getSubscriptionProductName,
} = require("../../../common/utils");

function createData(name, value) {
  return { name, value };
}

const materialTheme = createMuiTheme({
  palette: {
    primary: orange,
  },
});

class MiddleTab extends React.Component {
  state = {
    cancelSubscriptionDialog: false,
    yesButtonPressed: false,
    cancelButtonPressed: false,
    withdrawButtonPressed: false,
    copiedSubscriptionId: "",
    availablePaymentCycle: [
      { name: "5th", cohort: 5 },
      { name: "20th", cohort: 20 },
    ],
    selectedCycle: {},
    openDocumentUploadDrawer: false,
    openGhdDrawer: false,
    openOpdTransactionsDrawer: false,
    openDeleteConfirmationModal: false,
    openSendLinkDrawer: false,
    copiedLink: false,
    openModal: false,
    selectedProductValue: "",
    productName: "",
    stopLoading: false,
    selectedBusinessId: "",
    selectedAddon: "",
    selectedPaymentMultiplier: "",
    selectedCoupon: "",
    showUpfrontAmountDropdown: false,
    showRecurringAmountDropdown: false,
    applyCoupon: false,
    showCheckedIcon: false,
    iconColor: color.greys[100],
    bgForCopyOrChecked: "copyIcon",
    planChangeCode: "",
    planChangeReason: "",
  };

  componentDidMount() {
    this.props.resetCancelWithdrawSubscriptionStatus();
    this.props.resetProducts();
    this.props.resetPlanChange();
    this.props.resetCouponValue();
    this.props.resetOpdTransactions();
  }

  handleNo = () => {
    this.props.resetCancelWithdrawSubscriptionStatus();
    this.setState({
      cancelSubscriptionDialog: false,
      yesButtonPressed: false,
      cancelButtonPressed: false,
      withdrawButtonPressed: false,
    });
  };

  handleYesForCancelS = () => {
    this.setState({ yesButtonPressed: true });
    const { subscription } = this.props;
    const { id: subscriptionId } = subscription;
    const cancelSubscriptionPromise = this.props.cancelSubscription(
      subscriptionId
    );
    cancelSubscriptionPromise.then(() => {
      if (
        this.props.familyDetails &&
        this.props.familyDetails.cancelSubscriptionStatus &&
        this.props.familyDetails.cancelSubscriptionStatus.status === 204
      ) {
        this.setState({
          cancelSubscriptionDialog: false,
          yesButtonPressed: false,
          cancelButtonPressed: false,
        });
        this.props.resetCancelWithdrawSubscriptionStatus();
        this.props.getCustomerDetails(
          this.props.familyDetails.currentCustomer.primary_number
        );
      }
    });
  };

  handleCohort = (cohort) => {
    const { auth, familyDetails, subscription } = this.props;
    this.props.selectCohort(cohort);
    const cohortData = {
      product: {
        id: subscription.product.id,
      },
      cohort: cohort,
    };
    const accessToken = auth.authDetails.data.access_token;
    const familyId = familyDetails.currentCustomer.family.id;
    this.props.updateCohortEvent(accessToken, familyId, cohortData);
  };

  handleYesForWithdrawS = () => {
    const { subscription } = this.props;
    this.setState({ yesButtonPressed: true });
    const subscriptionId = subscription ? subscription.id : 0;
    const withdrawSubscriptionPromise = this.props.withdrawSubscription(
      subscriptionId
    );
    withdrawSubscriptionPromise.then(() => {
      if (
        this.props.familyDetails &&
        this.props.familyDetails.withdrawSubscriptionStatus &&
        this.props.familyDetails.withdrawSubscriptionStatus.status === 204
      ) {
        this.setState({
          cancelSubscriptionDialog: false,
          yesButtonPressed: false,
          withdrawButtonPressed: false,
        });
        this.props.resetCancelWithdrawSubscriptionStatus();
        this.props.getCustomerDetails(
          this.props.familyDetails.currentCustomer.primary_number
        );
      }
    });
  };

  handleDismiss = () => {
    this.props.resetCancelWithdrawSubscriptionStatus();
    this.setState({
      cancelSubscriptionDialog: false,
      yesButtonPressed: false,
      cancelButtonPressed: false,
      withdrawButtonPressed: false,
    });
  };

  cancelSubscription = () => {
    this.setState({
      cancelSubscriptionDialog: true,
      cancelButtonPressed: true,
      withdrawButtonPressed: false,
    });
  };

  withdrawSubscription = () => {
    this.setState({
      cancelSubscriptionDialog: true,
      withdrawButtonPressed: true,
      cancelButtonPressed: false,
    });
  };

  renderCancellationFailedMessage = () => {
    if (
      this.props.familyDetails &&
      this.props.familyDetails.cancelSubscriptionStatus &&
      this.props.familyDetails.cancelSubscriptionStatus.status === 422
    ) {
      return (
        <Typography
          style={{
            width: "58%",
            marginLeft: "21%",
            marginRight: "21%",
            fontFamily: "Rubik-Regular",
            fontSize: "1.5vh",
            marginTop: "2vh",
            align: "center",
          }}
        >
          {this.props.familyDetails.cancelSubscriptionStatus.data.message}
        </Typography>
      );
    } else {
      return (
        <Typography
          style={{
            width: "50%",
            marginLeft: "25%",
            marginRight: "25%",
            fontFamily: "Rubik-Regular",
            fontSize: "1.5vh",
            marginTop: "0.3vh",
            align: "center",
          }}
        >
          {" "}
          Error while cancelling subscription.
        </Typography>
      );
    }
  };

  renderWithdrawalFailedMessage = () => {
    if (
      this.props.familyDetails &&
      this.props.familyDetails.withdrawSubscriptionStatus &&
      this.props.familyDetails.withdrawSubscriptionStatus.status === 422
    ) {
      return (
        <Typography
          style={{
            width: "48%",
            marginLeft: "26%",
            marginRight: "26%",
            fontFamily: "Rubik-Regular",
            fontSize: "1.5vh",
            marginTop: "2vh",
            align: "center",
          }}
        >
          {this.props.familyDetails.withdrawSubscriptionStatus.data.message}
        </Typography>
      );
    } else {
      return (
        <Typography
          style={{
            width: "60%",
            marginLeft: "20%",
            marginRight: "20%",
            fontFamily: "Rubik-Regular",
            fontSize: "1.5vh",
            marginTop: "0.3vh",
            align: "center",
          }}
        >
          {" "}
          Error while withdrawing subscription.
        </Typography>
      );
    }
  };

  renderGettingCohortFailedMessage = () => {
    if (
      this.props.familyDetails &&
      this.props.familyDetails.billingPrefState &&
      this.props.familyDetails.billingPrefState.responseStatus === false
    ) {
      return (
        <Typography
          style={{
            width: "60%",
            marginLeft: "20%",
            marginRight: "20%",
            fontFamily: "Rubik-Regular",
            fontSize: "1.5vh",
            marginTop: "0.3vh",
            align: "center",
          }}
        >
          {" "}
          Error while getting preferred_cohort
        </Typography>
      );
    }
  };

  renderUpdatingCohortFailedMessage = () => {
    if (
      this.props.familyDetails &&
      this.props.familyDetails.cohortStatus &&
      this.props.familyDetails.cohortStatus.responseStatus === false
    ) {
      return (
        <Typography
          style={{
            width: "60%",
            marginLeft: "20%",
            marginRight: "20%",
            fontFamily: "Rubik-Regular",
            fontSize: "1.5vh",
            marginTop: "0.3vh",
            align: "center",
          }}
        >
          {" "}
          Error while getting preferred_cohort
        </Typography>
      );
    }
  };

  renderCancelWithdrawSubscriptionDialog = () => {
    if (
      (this.props.familyDetails &&
        this.props.familyDetails.cancelSubscriptionStatus &&
        this.props.familyDetails.cancelSubscriptionStatus.status === 422) ||
      this.props.familyDetails.cancelSubscriptionStatus ===
        errors.ERROR_IN_CANCELLING_SUBSCRIPTION ||
      (this.props.familyDetails &&
        this.props.familyDetails.withdrawSubscriptionStatus &&
        this.props.familyDetails.withdrawSubscriptionStatus.status === 422) ||
      this.props.familyDetails.withdrawSubscriptionStatus ===
        errors.ERROR_IN_WITHDRAWING_SUBSCRIPTION
    ) {
      return (
        <div>
          <Paper
            style={{ position: "relative", height: "20vh", width: "50vh" }}
          >
            <ThemeProvider theme={materialTheme}>
              <Typography
                style={{
                  width: "50%",
                  marginLeft: "25%",
                  marginRight: "25%",
                  fontFamily: "Rubik-Medium",
                  fontSize: "2.5vh",
                  marginTop: "2vh",
                }}
              >
                {" "}
                {this.state.cancelButtonPressed
                  ? "Cancellation Failed"
                  : "Withdrawal Failed"}
              </Typography>
              {this.state.cancelButtonPressed
                ? this.renderCancellationFailedMessage()
                : this.renderWithdrawalFailedMessage()}
            </ThemeProvider>
            <Button
              variant="outlined"
              onClick={this.handleDismiss}
              style={{
                borderColor: "orange",
                color: "orange",
                backgroundColor: "white",
                textTransform: "capitalize",
                marginLeft: "30%",
                marginRight: "30%",
                width: "40%",
                marginBottom: "1vh",
                marginTop: "2vh",
              }}
            >
              Dismiss
            </Button>
          </Paper>
        </div>
      );
    }
    if (
      this.state.yesButtonPressed &&
      (this.props.familyDetails.cancelSubscriptionStatus === null ||
        this.props.familyDetails.withdrawSubscriptionStatus === null)
    ) {
      return (
        <div>
          <Paper
            style={{ position: "relative", height: "22vh", width: "50vh" }}
          >
            <CircularProgress
              style={{
                color: "orange",
                marginLeft: "45%",
                marginRight: "50%",
                marginTop: "15%",
                marginBottom: "10%",
              }}
            />
          </Paper>
        </div>
      );
    } else if (
      (this.props.familyDetails.cancelSubscriptionStatus !==
        errors.ERROR_IN_CANCELLING_SUBSCRIPTION &&
        this.props.familyDetails.cancelSubscriptionStatus === null &&
        this.state.cancelButtonPressed) ||
      (this.props.familyDetails.withdrawSubscriptionStatus !==
        errors.ERROR_IN_WITHDRAWING_SUBSCRIPTION &&
        this.props.familyDetails.withdrawSubscriptionStatus === null &&
        this.state.withdrawButtonPressed)
    ) {
      return (
        <div style={{ position: "relative", height: "22vh", width: "50vh" }}>
          <ThemeProvider theme={materialTheme}>
            <Typography
              style={{
                width: "36%",
                marginLeft: "32%",
                marginRight: "32%",
                fontFamily: "Rubik-Medium",
                fontSize: "2.5vh",
                marginTop: "2vh",
              }}
            >
              {" "}
              Are you sure ?
            </Typography>
            <Typography
              style={{
                width: "70%",
                marginLeft: "15%",
                marginRight: "15%",
                fontFamily: "Rubik-Regular",
                fontSize: "1.8vh",
                marginTop: "2vh",
              }}
            >
              {" "}
              {this.state.cancelButtonPressed
                ? "Cancellation of plan cannot be reversed"
                : "Withdrawal of plan cannot be reversed"}
            </Typography>
          </ThemeProvider>
          <div style={{ height: "2vh", marginTop: "3vh", marginBottom: "3vh" }}>
            <Button
              variant="outlined"
              onClick={() => this.handleNo()}
              style={{
                borderColor: "orange",
                color: "orange",
                backgroundColor: "white",
                textTransform: "capitalize",
                marginLeft: "17.5%",
                width: "30%",
                marginBottom: "2vh",
                marginRight: "5%",
              }}
            >
              No
            </Button>
            <Button
              onClick={
                this.state.cancelButtonPressed
                  ? () => this.handleYesForCancelS()
                  : () => this.handleYesForWithdrawS()
              }
              style={{
                color: "white",
                backgroundColor: "orange",
                textTransform: "capitalize",
                marginRight: "17.5%",
                width: "30%",
                marginBottom: "2vh",
              }}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </div>
        </div>
      );
    }
  };

  getMultiplierQuantity = (multiplier) => {
    let multiplierQuantity = paymentMultiplierQuantity.MONTHLY;
    if (multiplier === paymentMultiplier.QUARTERLY) {
      multiplierQuantity = paymentMultiplierQuantity.QUARTERLY;
    } else if (multiplier === paymentMultiplier.SEMI_ANNUALLY) {
      multiplierQuantity = paymentMultiplierQuantity.SEMI_ANNUALLY;
    } else if (multiplier === paymentMultiplier.ANNUALLY) {
      multiplierQuantity = paymentMultiplierQuantity.ANNUALLY;
    }
    return multiplierQuantity;
  };

  getFormattedMultiplierQuantity = (multiplier) => {
    if (!multiplier) {
      return "";
    }
    const tokens = String(multiplier)
      .split("_")
      .map((token) => token.charAt(0) + token.slice(1));
    return tokens.join("-");
  };

  getRepresentableProductPrice = (subscription) => {
    const multiplierQuantity = this.getMultiplierQuantity(
      subscription.payment_multiplier
    );
    if (subscription.version === 1) {
      return (
        subscription.product.price +
        ", " +
        this.getFormattedMultiplierQuantity(subscription.payment_multiplier)
      );
    }
    let discountAmount = 0;
    let representablePriceWithoutDiscount =
      subscription.product.price * multiplierQuantity;
    if (!_.isEmpty(subscription.product.discount)) {
      if (
        subscription.product.discount[subscription.payment_multiplier]?.type ===
        discountType.FLAT
      ) {
        discountAmount =
          subscription.product.discount[subscription.payment_multiplier]?.value;
      } else if (
        subscription.product.discount[subscription.payment_multiplier]?.type ===
        discountType.PERCENTAGE
      ) {
        discountAmount =
          (subscription.product.discount[subscription.payment_multiplier]
            ?.value /
            100) *
          representablePriceWithoutDiscount;
      } else {
        discountAmount = 0;
      }
    }
    return (
      representablePriceWithoutDiscount -
      discountAmount +
      ", " +
      this.getFormattedMultiplierQuantity(subscription.payment_multiplier)
    );
  };

  copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      this.setState({ copiedSubscriptionId: copyMe });
    } catch (err) {
      this.setState({ copiedSubscriptionId: "" });
    }
  };

  copyActivationLinkToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      this.setState({ copiedLink: true }, () => {
        setTimeout(() => {
          this.setState({
            copiedLink: false,
          });
        }, 800);
      });
    } catch (err) {
      this.setState({ copiedLink: false });
    }
  };

  handelUploadDocumentDrawer = () => {
    const { familyDetails, getCustomerDetails } = this.props;
    this.setState((prevState) => ({
      ...prevState,
      openDocumentUploadDrawer: !prevState.openDocumentUploadDrawer,
    }));
    if (!this.openDocumentUploadDrawer) {
      getCustomerDetails(familyDetails.customerPhoneNumber);
    }
  };

  handelSendLinkDrawer = () => {
    this.setState((prevState) => ({
      ...prevState,
      openSendLinkDrawer: !prevState.openSendLinkDrawer,
    }));
  };

  handleGhdDrawer = () => {
    this.setState((prevState) => ({
      ...prevState,
      openGhdDrawer: !prevState.openGhdDrawer,
    }));
  };

  handleOPDDrawer = () => {
    this.setState((prevState) => ({
      ...prevState,
      openOpdTransactionsDrawer: !prevState.openOpdTransactionsDrawer,
    }));
    this.props.resetOpdTransactions();
  };

  handelCOIDelete = () => {
    const { familyDetails, getCustomerDetails } = this.props;
    this.setState((prevState) => ({
      ...prevState,
      openDeleteConfirmationModal: true,
    }));
    if (!this.openDeleteConfirmationModal) {
      getCustomerDetails(familyDetails.customerPhoneNumber);
    }
  };

  handelDeleteConfirmation = (action) => {
    const {
      subscription,
      deleteCOIEvent,
      getCustomerDetails,
      familyDetails,
    } = this.props;
    if (action === "yes") {
      deleteCOIEvent(subscription.id);
      getCustomerDetails(familyDetails.customerPhoneNumber);
    }
    this.setState((prevState) => ({
      ...prevState,
      openDeleteConfirmationModal: false,
    }));
  };

  isSubscriptionStatusWithdrawnOrCancelled = (subscription) => {
    return (
      subscription.status === subscriptionStatus.WITHDRAWN ||
      subscription.status === subscriptionStatus.CANCELLED
    );
  };

  downloadCOI = () => {
    const { subscription } = this.props;
    window.open(
      `${config.apiURL}/v4/subscriptions/${subscription.id}/coi/download`,
      "_blank"
    );
  };

  /**
   *
   * @param {Object} subscription The Subscription for which the details
   * are being displayed.
   * @returns A human-friendly message for Ops to know why onboarding is disabled;
   */
  getOnboardingTooltipText = (subscription) => {
    if (subscription.tncStatus === ghdStatus.FAILED) {
      return "Onboarding cannot be done for this Subscription, since the customer has rejected the Good Health Declaration.";
    }
    return "";
  };

  render() {
    const {
      openDeleteConfirmationModal,
      openDocumentUploadDrawer,
      openGhdDrawer,
      openOpdTransactionsDrawer,
      openSendLinkDrawer,
      copiedLink,
      openModal,
      selectedProductValue,
      planChangeCode,
      planChangeReason,
      productName,
      stopLoading,
      selectedBusinessId,
      showUpfrontAmountDropdown,
      showRecurringAmountDropdown,
      selectedPaymentMultiplier,
      selectedAddon,
      selectedCoupon,
    } = this.state;
    const {
      classes,
      subscription,
      familyDetails,
      subscriptionMark,
      updatedOPDDetails
    } = this.props;
    const diff = moment(new Date()).diff(
      moment(new Date(subscription?.end_date)),
      "days"
    );
    const isPolicyLapsed = diff >= 0;
    const isRoleManager =
      this.props.auth &&
      this.props.auth.authDetails &&
      !_.isNil(
        _.find(this.props.auth.authDetails.data.user.roles, {
          name: accessRole.OPS_MANAGER,
        })
      );

    if (
      subscription?.plan_change?.business &&
      subscription?.plan_change?.product &&
      !stopLoading
    ) {
      this.props.fetchProductByBusineedId(subscription.plan_change.business);
      // this.setState({ selectedProductValue: subscription.plan_change.product });
      this.setState({ stopLoading: true });
    }

    const handleBusinessChange = (e) => {
      const { fetchProductByBusineedId } = this.props;
      const businessId = e.target.value;
      this.setState({ selectedBusinessId: businessId });
      if (businessId) {
        fetchProductByBusineedId(businessId);
        this.setState({ selectedProductValue: "" });
        this.setState({ selectedAddon: "" });
        this.setState({ selectedPaymentMultiplier: "" });
        this.setState({ showUpfrontAmountDropdown: false });
        this.setState({ showRecurringAmountDropdown: false });
        this.setState({ showRecurringAmountDropdown: false });
        this.setState({ selectedCoupon: "" });
        this.props.resetCouponValue();
        this.props.resetPlanChange();
      }
    };

    const handleProductChange = (e) => {
      const selectedProduct = e.target.value;
      this.setState({ productName: selectedProduct });
      this.setState({ selectedProductValue: selectedProduct });
      this.setState({ selectedAddon: "" });
      this.setState({ selectedPaymentMultiplier: "" });
    };

    const handlePaymentMultiplier = (e) => {
      const selectedMultiplier = e.target.value;
      if (selectedMultiplier === "annually") {
        this.setState({ selectedPaymentMultiplier: "yearly" });
      } else if (selectedMultiplier === "semi_annually") {
        this.setState({ selectedPaymentMultiplier: "half_yearly" });
      } else {
        this.setState({ selectedPaymentMultiplier: selectedMultiplier });
      }
    };

    const handleCoupon = (e) => {
      const selectedCouponValue = e.target.value;
      this.setState({ selectedCoupon: selectedCouponValue });
      this.props.resetCouponValue();
    };

    const handleAddonChange = (e) => {
      const selectedAddon = e.target.value;
      this.setState({ selectedAddon: selectedAddon });
    };

    const getPaymentMultiplier = () => {
      const { subscription } = this.props;
      if (subscription.payment_multiplier === "annually" || subscription.payment_multiplier === "yearly") {
        return "yearly";
      } else if (subscription.payment_multiplier === "semi_annually") {
        return "half_yearly";
      } else if (subscription.payment_multiplier === "quarterly") {
        return "quarterly";
      } else if (subscription.payment_multiplier === "monthly") {
        return "monthly";
      }
    };

    const handleClose = () => {
      this.setState({ openModal: false });
      this.props.resetPlanChange();
      this.props.resetCouponValue();
      this.setState({ selectedAddon: "" });
      this.setState({ selectedPaymentMultiplier: "" });
      this.setState({ selectedCoupon: "" });
      this.setState({ selectedBusinessId: "" });
      this.setState({ planChangeCode: "" })
      this.setState({ planChangeReason: ""})
      if (
        subscription?.plan_change?.business &&
        subscription?.plan_change?.product
      ) {
        this.props.fetchProductByBusineedId(subscription.plan_change.business);
        this.setState({
          selectedProductValue: subscription.plan_change.product,
        });
      }
    };

    const handleSendLinkEvent = () => {
      const {
        selectedAddon,
        selectedCoupon,
        selectedProductValue,
        selectedPaymentMultiplier,
      } = this.state;
      const { sendPlanChangeEvent, subscription } = this.props;
      const subscriptionId = subscription?.id;
      const payload = {};
      if (selectedProductValue !== "") {
        payload["product"] = selectedProductValue;
      }
      if (selectedPaymentMultiplier !== "") {
        payload["payment_multiplier"] = selectedPaymentMultiplier;
      } else {
        payload["payment_multiplier"] = getPaymentMultiplier();
      }
      if (selectedAddon !== "") {
        payload["optional_addons"] = [selectedAddon];
      }
      if (planChangeCode !== "") {
        payload["plan_change_code"] = planChangeCode
      }
      if (planChangeReason !== "") {
        payload["plan_change_reason"] = planChangeReason
      }
      if (
        selectedCoupon !== "" &&
        familyDetails?.couponDiscount?.couponData?.data &&
        familyDetails?.couponDiscount?.couponData?.data?.type !== "referral"
      ) {
        payload["coupon_code"] = selectedCoupon;
      }
      sendPlanChangeEvent(subscriptionId, payload);
    };

    const isSubscriptionLapsed =
      subscription.version !== 1
        ? moment(subscription?.end_date).isBefore(moment().startOf("month"))
        : moment(subscription?.lapsing_date).isBefore(
            moment().startOf("month")
          );

    const filteredProducts = this.props.familyDetails?.productDetails?.products?.filter(
      (product) => {
        return product.id.includes(selectedProductValue);
      }
    );

    const selectedAddonArray = filteredProducts?.map((product) =>
      product?.applicable_addons?.map((item) => {
        return item;
      })
    );

    const getPriceObjectFromPricingArray = (pricing, age) =>{
      const priceObject = pricing?.find((price)=> age >= price?.age_range[0] && age <= price?.age_range[1])?.price ;
      return priceObject;
    };

    const productAmountArray = filteredProducts?.map((product) => {
      return getPriceObjectFromPricingArray(product?.pricing, subscription?.age);
    });

    const getProductAmount = () => {
      if (productAmountArray) {
        let productPaymentMultiplier = this.props.subscription
          ?.payment_multiplier;
        if (productPaymentMultiplier === "annually") {
          productPaymentMultiplier = "yearly";
        } else if (productPaymentMultiplier === "semi_annually") {
          productPaymentMultiplier = "half_yearly";
        }
        if (selectedPaymentMultiplier === "") {
          return productAmountArray[0][productPaymentMultiplier];
        } else if (selectedPaymentMultiplier === "yearly") {
          return productAmountArray[0]?.yearly;
        } else if (selectedPaymentMultiplier === "half_yearly") {
          return productAmountArray[0]?.half_yearly;
        } else if (selectedPaymentMultiplier === "quarterly") {
          return productAmountArray[0]?.quarterly;
        } else if (selectedPaymentMultiplier === "monthly") {
          return productAmountArray[0]?.monthly;
        }
      }
    };

    const getAddonPrice = () => {
      if (selectedAddonArray && selectedAddon !== "") {
        const filteredSelectedAddonArray = selectedAddonArray.flatMap(
          (innerArray) =>
            innerArray.filter((addon) => addon.id === selectedAddon)
        );

        let productPaymentMultiplier = this.props.subscription
          ?.payment_multiplier;
        const addonPriceObject = getPriceObjectFromPricingArray(filteredSelectedAddonArray[0].pricing, subscription?.age);
        if (productPaymentMultiplier === "annually") {
          productPaymentMultiplier = "yearly";
        } else if (productPaymentMultiplier === "semi_annually") {
          productPaymentMultiplier = "half_yearly";
        }
        if (selectedPaymentMultiplier === "") {
          return addonPriceObject[productPaymentMultiplier];
        } else if (selectedPaymentMultiplier === "yearly") {
          return addonPriceObject.yearly;
        } else if (selectedPaymentMultiplier === "half_yearly") {
          return addonPriceObject.half_yearly;
        } else if (selectedPaymentMultiplier === "quarterly") {
          return addonPriceObject.quarterly;
        } else if (selectedPaymentMultiplier === "monthly") {
          return addonPriceObject.monthly;
        }
      }
    };

    const handleApplyCoupon = () => {
      const { getCouponDiscountEvent } = this.props;
      const { selectedCoupon } = this.state;
      if (selectedCoupon) {
        getCouponDiscountEvent(selectedCoupon);
        this.setState({ applyCoupon: true });
      }
    };

    const handleRemoveButton = () => {
      this.setState({ selectedCoupon: "" });
      this.props.resetCouponValue();
    };

    const calculateProductDiscount = (
      type,
      discountValue,
      maxDiscountValue
    ) => {
      let calculatedAmount = 0;
      if (type === discountType.FLAT) {
        calculatedAmount = discountValue;
      } else if (type === discountType.PERCENTAGE) {
        calculatedAmount = selectedPaymentMultiplier
          ? ((getProductAmount() + (selectedAddon ? getAddonPrice() : 0)) *
              discountValue) /
            100
          : 0;
      }
      return Math.min(calculatedAmount, maxDiscountValue);
    };

    let discountAmount =
      this.props.familyDetails?.couponDiscount?.couponData?.data &&
      this.state.applyCoupon &&
      this.props.familyDetails?.couponDiscount?.couponData?.data?.type !==
        "referral" &&
      selectedPaymentMultiplier
        ? calculateProductDiscount(
            this.props.familyDetails?.couponDiscount?.couponData?.data
              ?.discount_type,
            this.props.familyDetails?.couponDiscount?.couponData?.data
              ?.discount_value,
            this.props.familyDetails?.couponDiscount?.couponData?.data
              ?.constraint?.max_discount_value
          )
        : 0;

    const v0SubscriptionDueDateLessThan15 =
      subscription?.version === 0
        ? moment(subscription?.end_date).date() <= 15
        : false;

    const rows = [
      createData(
        "Name",
        subscription && getSubscriptionProductName(subscription)
      ),
      createData(
        "Business A/c",
        subscription && subscription?.product?.business.name
          ? subscription.product?.business.name
          : "-"
      ),
      createData(
        "Payment Plan",
        subscription && subscription.product
          ? "₹" + this.getRepresentableProductPrice(subscription)
          : "-"
      ),
      createData(
        "Purchased On",
        !_.isEmpty(subscription) && subscription.start_date
          ? getISTFormat2(subscription.start_date)
          : "-"
      ),
      subscription?.version === 1 &&
        createData(
          "Due Date",
          <span>
            {subscription &&
              (isSubscriptionLapsed && subscription?.version === 1 ? (
                <>
                  <s>{getISTFormat2(subscription.end_date)}</s>{" "}
                  <span className={classes.ghdStatus}></span>
                </>
              ) : (
                getISTFormat2(subscription.end_date)
              ))}
          </span>
        ),
      subscription?.version === 0 &&
        createData(
          "Due Date",
          <span>
            {subscription &&
              (v0SubscriptionDueDateLessThan15
                ? moment(subscription?.end_date)
                    .date(7)
                    .format("Do MMM YYYY")
                : moment(subscription?.end_date)
                    .date(20)
                    .format("Do MMM YYYY"))}
          </span>
        ),
      createData(
        "GHD Status",
        (
          <span
            className={
              subscription?.tncStatus === tncStatus.SUCCESS
                ? classes.ghdStatusPassed
                : classes.ghdStatus
            }
          >
            {getFormattedGhdStatusOfSubscription(subscription)}
          </span>
        ) ?? "-"
      ),
      createData(
        "Available OPD",
        !_.isEmpty(updatedOPDDetails) && updatedOPDDetails?.opd_account?.current_amount
        && updatedOPDDetails.status === 'active'
          ? updatedOPDDetails?.opd_account?.current_amount?.toLocaleString("en-IN", {
            style: "currency",
            currency: "INR",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })
          : "-"
      ),
      createData(
        "Total OPD Limit",
        !_.isEmpty(updatedOPDDetails) && updatedOPDDetails?.opd_account?.covered_amount
        && updatedOPDDetails.status === 'active'
          ? updatedOPDDetails?.opd_account?.covered_amount?.toLocaleString("en-IN", {
            style: "currency",
            currency: "INR",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })
          : "-"
      ),
      createData(
        "Cancellation Code",
        !_.isEmpty(subscription) && subscription.cancellation_code
          ? subscription.cancellation_code
          : "-"
      ),
      createData(
        "Cancellation Reason",
        !_.isEmpty(subscription) && subscription.cancellation_reason
          ? subscription.cancellation_reason
          : "-"
      ),
      createData(
        "Cancellation Date",
        !_.isEmpty(subscription) && subscription.cancellation_reason
          ? moment(subscription?.cancelled_at).format("Do MMM YYYY")
          : "-"
      ),
      createData(
        "Subscription Version",
        !_.isEmpty(subscription) ? subscription?.version : "-"
      ),
      createData(
        "Payment Url",
        subscription?.payment_url ? subscription?.payment_url : "-"
      ),
    ];
    if (subscription?.plan_change) {
      if(subscription?.plan_change?.code) {
        rows.push(createData(
        "Change Code",
        !_.isEmpty(subscription) ? subscription?.plan_change.code : "-"
      ),
        )
      }
      if(subscription?.plan_change?.reason) {
        rows.push(createData(
          "Change Reason",
          !_.isEmpty(subscription) ? subscription?.plan_change.reason : "-"
        ))
      }
      if(subscription?.plan_change?.prev_subscription) {
        rows.push(createData(
        "Prev Subscription Id",
        !_.isEmpty(subscription) ? subscription?.plan_change.prev_subscription : "-"
      ))
      }
      if(subscription?.plan_change?.new_subscription) {
        rows.push(createData(
          "New Subscription Id",
          !_.isEmpty(subscription) ? subscription?.plan_change.new_subscription : "-"
        ))
    }
    }
    const handleCopyText = (text) => {
      navigator.clipboard.writeText(text);
      this.setState(
        {
          showCheckedIcon: true,
          bgForCopyOrChecked: "checkedIcon",
        },
        () => {
          setTimeout(() => {
            this.setState({
              showCheckedIcon: false,
              bgForCopyOrChecked: "copyIcon",
            });
          }, 4000);
        }
      );
    };

    const allBusinessids =
      process.env.REACT_APP_ENV === "production" ? businessIds : qabusinessIds;
    return (
      <div style={{ position: "relative" }}>
        <ConfirmationModal
          open={openDeleteConfirmationModal}
          handelConfirmation={(action) => this.handelDeleteConfirmation(action)}
        />
        <Drawer
          disableEnforceFocus
          anchor="right"
          open={openDocumentUploadDrawer}
          onClose={() => this.handelUploadDocumentDrawer()}
        >
          <Paper style={{ width: "50vh", height: "100%" }}>
            <UploadDrawer
              subscription={subscription}
              closeCOIUploadDrawer={() => this.handelUploadDocumentDrawer()}
            />
          </Paper>
        </Drawer>
        <Drawer
          disableEnforceFocus
          anchor="right"
          open={openGhdDrawer}
          onClose={() => this.handleGhdDrawer()}
        >
          <Paper style={{ width: "50vh", height: "100%" }}>
            <GhdDrawer
              closeGhdDrawer={() => this.handleGhdDrawer()}
              subscription={subscription}
            />
          </Paper>
        </Drawer>
        <Drawer
          disableEnforceFocus
          anchor="right"
          open={openOpdTransactionsDrawer}
          onClose={() => this.handleOPDDrawer()}
        >
          <Paper style={{ width: "50vh"}}>
            <OpdDrawer
              closeOpdDrawer={() => this.handleOPDDrawer()}
              subscription={subscription}
            />
          </Paper>
        </Drawer>
        <Drawer
          disableEnforceFocus
          anchor="right"
          open={openSendLinkDrawer}
          onClose={() => this.handelSendLinkDrawer()}
        >
          <Paper style={{ width: "50vh", height: "100%" }}>
            <SendLinkDrawer
              contactNo={familyDetails.customerPhoneNumber}
              drawerFor="coi"
              subscription={subscription}
              closeSendLinkDrawer={() => this.handelSendLinkDrawer()}
            />
          </Paper>
        </Drawer>
        <Table
          style={{
            width: "auto",
            borderTop: "none",
            borderBottom: "none !important",
          }}
          aria-label="simple table"
        >
          <TableBody style={{ overflowX: "scroll", overflowY: "scroll" }}>
            {rows.map((row) => (
              <TableRow key={row.name} style={{ padding: "8px 24px" }}>
                <TableCell
                  className={classes.dataKey}
                  component="th"
                  scope="row"
                >
                  {row.name}
                </TableCell>
                {row.name && !row.name.includes('Url') && <TableCell align="left" className={classes.dataValue}>
                  {row.value}
                  {row.name === "GHD Status" ? <Button
                      style={{ marginLeft: '50px'}}
                      disabled={!subscription?.tncLogs}
                      onClick={() => this.handleGhdDrawer()}
                      className={classes.historyButton}
                      variant="outlined"
                    >
                      <EyeIcon className={classes.eyeIcon} />
                      History
                    </Button> : <></>}
                    {row.name === "Total OPD Limit" && subscription.status === 'active' ? <Button
                      style={{ marginLeft: '50px'}}
                      disabled={!subscription?.opd_account || (subscription?.opd_account && subscription?.opd_account?.covered_amount === 0)}
                      onClick={() => this.handleOPDDrawer()}
                      className={classes.opdTransactionsButton}
                      variant="outlined"
                    >
                      See All OPD Transactions
                    </Button> : <></>}
                </TableCell>}
                {row.name && row.name.includes('Url')
                && <TableCell align="left" className={classes.dataValue}>
                  <a
                    target="_blank"
                    onClick={() => { this.copyToClipBoard(row.value); }}
                    href={row.value}
                  >{row.value.substring(0,20)}...</a>
                </TableCell>}
                {/* {row.name === "GHD Status" ? (
                  <TableCell align="right">
                    <Button
                      disabled={!subscription?.tncLogs}
                      onClick={() => this.handleGhdDrawer()}
                      className={classes.historyButton}
                      variant="outlined"
                    >
                      <EyeIcon className={classes.eyeIcon} />
                      History
                    </Button>
                  </TableCell>
                ) : (
                  ""
                )} */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {subscription && (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "5px 16px",
              marginBottom: "5px",
            }}
          >
            <Button
              style={{
                width: "100%",
                backgroundColor: "#F38B44",
                borderRadius: "5px",
                color: "#ffffff",
                textTransform: "capitalize",
                height: "25px",
                fontSize: "11px",
              }}
              onClick={() => {
                this.copyToClipBoard(subscription.id);
              }}
            >
              Copy Subscription ID
            </Button>
            {subscription?.status === subscriptionStatus.ONBOARDING &&
              subscription?.tncStatus === ghdStatus.PENDING && (
                <Button
                  variant="outlined"
                  className={`${classes.outlineButton} rubikBold ${classes.secondaryButton}`}
                  onClick={() => {
                    this.copyActivationLinkToClipBoard(
                      `${config.appURL}/activate-policy?subscriptionId=${subscription.id}`
                    );
                  }}
                  fullWidth
                >
                  <Typography className="rubikBold">
                    {copiedLink
                      ? "Copied Activation link!"
                      : "Copy Activation Link"}
                  </Typography>
                </Button>
              )}
          </Box>
        )}
        {subscription?.status === "active" &&
        subscription?.plan_change &&
        subscription?.plan_change.business &&
        subscription?.plan_change.product
        && (!subscription?.plan_change.code || (subscription?.plan_change.code && (subscription?.plan_change.code === 'price_hike' || subscription?.plan_change.code === 'plan_upgrade'))) ? (
          <Button
            style={{
              width: "90%",
              backgroundColor: "#F38B44",
              borderRadius: "5px",
              color: "#ffffff",
              textTransform: "capitalize",
              height: "25px",
              fontSize: "11px",
            }}
            onClick={() => this.setState({ openModal: true })}
          >
            Start Change
          </Button>
        ) : (
          ""
        )}
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <div style={{ display: "flex" }} className={css.modal_container}>
            <Box position="absolute" top={6} right={16} p={2}>
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => handleClose()}
                className={classes.closeIcon}
              />
            </Box>
            {familyDetails?.planChange === errors.ERROR_IN_PLAN_CHANGE ? (
              <p style={{ color: "red" }}>Error in Plan change</p>
            ) : (
              ""
            )}
            {familyDetails?.planChange?.status === 202 || familyDetails?.planChange?.status === 200 ? (
              <>
                <div
                  style={{
                    color: "green",
                    textAlign: "center",
                    marginBottom: "16px",
                  }}
                >
                  Link Sent Successfully
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() =>
                    handleCopyText(
                      familyDetails?.planChange?.data?.payment_link
                    )
                  }
                >
                  <div
                    style={{
                      color: "green",
                      textAlign: "center",
                      paddingRight: "12px",
                    }}
                  >
                    <Typography>
                      {familyDetails?.planChange?.data?.payment_link}
                    </Typography>
                  </div>
                  <div
                    onMouseOver={() =>
                      this.setState({ iconColor: color.ROYAL_ORANGE })
                    }
                    onMouseOut={() =>
                      this.setState({ iconColor: color.greys[100] })
                    }
                  >
                    {this.state.showCheckedIcon ? (
                      <CheckedIcon />
                    ) : (
                      <CopyIcon color={this.state.iconColor} />
                    )}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <p>Select the business to show Products from.</p>
                <p>The oldest member age in current subscription is : <b>{subscription?.age} years</b></p>
              </div>
            </div>
            <Grid container spacing={2}>
              {/* First row with two dropdowns */}
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Select Business</InputLabel>
                  <Select
                    value={
                      selectedBusinessId
                        ? selectedBusinessId
                        : subscription?.plan_change?.business
                    }
                    onChange={handleBusinessChange}
                    classes={{
                      outlined: classes.select,
                      disabled: classes.disabledSelect,
                    }}
                  >
                    {allBusinessids?.map((business) => {
                      return (
                        <MenuItem value={business.id}>{business.name}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Select Product</InputLabel>
                  <Select
                    value={selectedProductValue}
                    onChange={handleProductChange}
                    classes={{
                      outlined: classes.select,
                      disabled: classes.disabledSelect,
                    }}
                  >
                    {this.props.familyDetails.productDetails &&
                      this.props.familyDetails.productDetails?.products?.map(
                        (product) => {
                          // const allowedAdults =
                          //   product?.constraint?.allowed_members?.adult_max ||
                          //   0;
                          // const allowedChildren =
                          //   product?.constraint?.allowed_members?.child_max ||
                          //   0;

                          // const allowedTotalMax =
                          //   product?.constraint?.allowed_members?.total_max ||
                          //   0;

                          // const allowedTotalMin =
                          //   product?.constraint?.allowed_members?.total_min ||
                          //   0;

                          // const totalSubscriptionAdult =
                          //   subscription?.member_config?.adult;

                          // const totalSubscriptionChild =
                          //   subscription?.member_config?.child || 0;

                          // if (
                          //   totalSubscriptionAdult <= allowedAdults &&
                          //   totalSubscriptionChild <= allowedChildren &&
                          //   totalSubscriptionAdult + totalSubscriptionChild <=
                          //     allowedTotalMax &&
                          //   totalSubscriptionAdult + totalSubscriptionChild >=
                          //     allowedTotalMin
                          // ) {
                            return (
                              <MenuItem key={product.id} value={product.id}>
                                {product.display_name}
                              </MenuItem>
                            );
                          // }
                        }
                      )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Select Addon</InputLabel>
                  <Select
                    value={selectedAddon}
                    onChange={handleAddonChange}
                    classes={{
                      outlined: classes.select,
                      disabled: classes.disabledSelect,
                    }}
                  >
                    {filteredProducts?.map((product) =>
                      product?.applicable_addons?.map((item) => {
                        if (
                          item.optional === true &&
                          selectedProductValue !== ""
                        ) {
                          return (
                            <MenuItem value={item.id}>
                              {item.display_name}
                            </MenuItem>
                          );
                        }
                      })
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Select Payment Multiplier</InputLabel>
                  <Select
                    onChange={handlePaymentMultiplier}
                    value={selectedPaymentMultiplier}
                    classes={{
                      outlined: classes.select,
                      disabled: classes.disabledSelect,
                    }}
                  >
                    {selectedProductValue !== "" &&
                      filteredProducts?.map((item) => {
                        const price = getPriceObjectFromPricingArray(item?.pricing, 24);
                        const keys = Object.keys(price);
                        return (
                          productName !== "" &&
                          keys.map((key) => {
                            // if (
                            //   selectedFrequency === "quarterly" &&
                            //   key !== "monthly"
                            // ) {
                            //   return (
                            //     <MenuItem
                            //       key={key}
                            //       style={{ textTransform: "capitalize" }}
                            //       value={key}
                            //     >
                            //       {key}
                            //     </MenuItem>
                            //   );
                            // } else if (
                            //   selectedFrequency === "half_yearly" &&
                            //   key !== "monthly" &&
                            //   key !== "quarterly"
                            // ) {
                            //   return (
                            //     <MenuItem
                            //       key={key}
                            //       style={{ textTransform: "capitalize" }}
                            //       value={key}
                            //     >
                            //       {key}
                            //     </MenuItem>
                            //   );
                            // } else if (
                            //   selectedFrequency === "yearly" &&
                            //   key !== "monthly" &&
                            //   key !== "quarterly" &&
                            //   key !== "half_yearly"
                            // ) {
                            //   return (
                            //     <MenuItem
                            //       key={key}
                            //       style={{ textTransform: "capitalize" }}
                            //       value={key}
                            //     >
                            //       {key}
                            //     </MenuItem>
                            //   );
                            // } else if (selectedFrequency === 'monthly') {
                              return (
                                <MenuItem
                                  key={key}
                                  style={{ textTransform: "capitalize" }}
                                  value={key}
                                >
                                  {key}
                                </MenuItem>
                              );
                            // }
                          })
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Select Plan Change Code</InputLabel>
                  <Select
                    value={planChangeCode}
                    onChange={(e) => this.setState({ planChangeCode: e.target.value })}
                    classes={{
                      outlined: classes.select,
                      disabled: classes.disabledSelect,
                    }}
                  >
                      <MenuItem value="price_hike">Price Hike</MenuItem>
                      <MenuItem value="plan_upgrade">Plan Upgrade</MenuItem>
                      <MenuItem value="port">Port</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    onChange={(e) => this.setState({ planChangeReason: e.target.value })}
                    placeholder="Enter Plan Reason"
                    inputProps={{
                        style: {
                            fontFamily: 'Rubik',
                        }
                    }}
                    value={planChangeReason}
                    style={{ marginLeft: '5%', marginTop: '5%', fontFamily: 'Rubik', fontWeight: 400 }}
                    >
                    </TextField>
                </FormControl>
              </Grid>
            </Grid>
            <TextField
              error={
                familyDetails?.couponDiscount ===
                errors.ERROR_IN_VALIDATING_COUPON
              }
              helperText={
                <FormHelperText
                  style={{
                    color:
                      familyDetails?.couponDiscount ===
                        errors.ERROR_IN_VALIDATING_COUPON ||
                      familyDetails?.couponDiscount?.couponData?.data?.type ===
                        "referral"
                        ? "red"
                        : familyDetails?.couponDiscount?.couponData?.data
                        ? "green"
                        : "black",
                  }}
                >
                  {familyDetails?.couponDiscount ===
                  errors.ERROR_IN_VALIDATING_COUPON
                    ? "Coupon code is not valid."
                    : familyDetails?.couponDiscount?.couponData?.data &&
                      familyDetails?.couponDiscount?.couponData?.data?.type !==
                        "referral"
                    ? "Coupon Successfully applied."
                    : familyDetails?.couponDiscount?.couponData?.data?.type ===
                      "referral"
                    ? "Referral coupons are not allowed."
                    : ""}
                </FormHelperText>
              }
              value={selectedCoupon}
              onChange={handleCoupon}
              fullWidth
              style={{ marginTop: "12px", marginBottom: "12px" }}
              label="Please enter Coupon"
              placeholder="CLK-6"
              className={classes.emailsTextField}
              variant="outlined"
              onKeyDown={this.onKeyPress}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {selectedPaymentMultiplier !== "" &&
                    !familyDetails?.couponDiscount?.couponData?.data ? (
                      <Button
                        onClick={handleApplyCoupon}
                        style={{ backgroundColor: "white" }}
                        variant="contained"
                        color="inherit"
                      >
                        Apply
                      </Button>
                    ) : (
                      ""
                    )}
                    {selectedPaymentMultiplier !== "" &&
                    familyDetails?.couponDiscount?.couponData?.data ? (
                      <Button
                        onClick={handleRemoveButton}
                        style={{ backgroundColor: "white" }}
                        variant="contained"
                        color="inherit"
                      >
                        Remove
                      </Button>
                    ) : (
                      ""
                    )}
                  </InputAdornment>
                ),
              }}
            ></TextField>
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  component="div"
                  sx={{ fontSize: "1rem", fontWeight: "medium" }}
                >
                  Upfront Amount
                </Typography>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    float: "right",
                  }}
                >
                  <div>
                    ₹
                    {productName
                      ? (selectedPaymentMultiplier ? getProductAmount() : 0) +
                        (selectedAddon && selectedPaymentMultiplier
                          ? getAddonPrice()
                          : 0) -
                        (discountAmount ? discountAmount : 0)
                      : 0}
                  </div>
                  <div
                    onClick={() =>
                      this.setState({
                        showUpfrontAmountDropdown: !showUpfrontAmountDropdown,
                      })
                    }
                  >
                    <IconButton sx={{ color: "green" }}>
                      <HiChevronDown />
                    </IconButton>
                  </div>
                </div>
              </Grid>
            </Grid>
            {showUpfrontAmountDropdown ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p>Product Amount</p>
                  <p style={{ fontWeight: "bold" }}>
                    ₹
                    {selectedPaymentMultiplier && productName
                      ? getProductAmount()
                      : 0}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p>Addons</p>
                  <p style={{ fontWeight: "bold" }}>
                    + ₹{selectedAddon ? getAddonPrice() : 0}
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p>Discount</p>
                  <p style={{ fontWeight: "bold" }}>
                    - ₹{discountAmount ? discountAmount : 0}
                  </p>
                </div>
              </div>
            ) : null}
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  component="div"
                  sx={{ fontSize: "1rem", fontWeight: "medium" }}
                >
                  Recurring Amount
                </Typography>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    float: "right",
                  }}
                >
                  <div>
                    ₹
                    {productName
                      ? (selectedPaymentMultiplier ? getProductAmount() : 0) +
                        (selectedAddon && selectedPaymentMultiplier
                          ? getAddonPrice()
                          : 0)
                      : 0}
                  </div>
                  <div
                    onClick={() =>
                      this.setState({
                        showRecurringAmountDropdown: !showRecurringAmountDropdown,
                      })
                    }
                  >
                    <IconButton sx={{ color: "green" }}>
                      <HiChevronDown />
                    </IconButton>
                  </div>
                </div>
              </Grid>
            </Grid>
            {showRecurringAmountDropdown ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p>Product Amount</p>
                  <p style={{ fontWeight: "bold" }}>
                    ₹{selectedPaymentMultiplier ? getProductAmount() : 0}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p>Addons</p>
                  <p style={{ fontWeight: "bold" }}>
                    + ₹{selectedAddon ? getAddonPrice() : 0}
                  </p>
                </div>
              </div>
            ) : null}
            <div className={css.modal_container_footer}>
              <Button
                disabled={
                  selectedPaymentMultiplier === "" ||
                  productName === "" ||
                  this.props.familyDetails?.couponDiscount?.couponData?.data
                    ?.type === "referral"
                }
                onClick={handleSendLinkEvent}
                style={{
                  backgroundColor:
                    selectedPaymentMultiplier !== "" &&
                    productName !== "" &&
                    this.props.familyDetails?.couponDiscount?.couponData?.data
                      ?.type !== "referral"
                      ? "blueviolet"
                      : "gray",
                  color: "white",
                }}
                variant="outlined"
              >
                {`send Link to ${subscription?.primary_contact?.primary_number}`}
              </Button>
            </div>
          </div>
        </Modal>
        {subscription && this.state.copiedSubscriptionId === subscription.id && (
          <Box
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              width: "100%",
              padding: "5px 50px",
              marginTop: "40px",
            }}
          >
            <Box
              style={{
                alignSelf: "center",
                width: "50%",
                backgroundColor: "#DBECE5",
                borderRadius: "5px",
                color: "#0D7E51",
                textTransform: "capitalize",
                height: "25px",
                fontSize: "11px",
                padding: "5px 10px 0px",
              }}
            >
              ID Copied Successfully!
            </Box>
          </Box>
        )}

        <Box className={classes.buttonContainer}>
          {isRoleManager &&
          subscription &&
          [
            subscriptionStatus.PENDING,
            subscriptionStatus.ONBOARDING,
            subscriptionStatus.ENDORSEMENT,
            subscriptionStatus.ACTIVE,
          ].includes(subscription?.status) ? (
            <Button
              onClick={() => this.cancelSubscription()}
              className={`${classes.cancelButton} ${
                subscription?.status === subscriptionStatus.ONBOARDING
                  ? ""
                  : classes.fullWidth
              }`}
              disabled={subscription.version === 1}
            >
              <Typography className="rubikBold">Cancel Plan</Typography>
            </Button>
          ) : (
            <></>
          )}

          {subscription?.status === subscriptionStatus.ONBOARDING &&
            !isPolicyLapsed && (
              <Tooltip
                title={this.getOnboardingTooltipText(subscription)}
                classes={{
                  arrow: classes.tooltip,
                  tooltip: classes.tooltip,
                }}
              >
                <Button
                  variant="outlined"
                  className={`${classes.outlineButton} rubikBold ${
                    !isRoleManager ? classes.fullWidth : ""
                  }`}
                  onClick={() => {
                    subscriptionMark(subscription);
                  }}
                  disabled={subscription.tncStatus === ghdStatus.FAILED}
                  fullWidth
                >
                  <Typography className="rubikBold">
                    Onboard Customer
                  </Typography>
                </Button>
              </Tooltip>
            )}

          {subscription &&
          isRoleManager &&
          (subscription.status === subscriptionStatus.PLAN_CHANGE_PENDING ||
            subscription.status === subscriptionStatus.PENDING) ? (
            <Button
              onClick={() => this.withdrawSubscription()}
              variant="outlined"
              className={`${classes.withdrawButton} ${classes.fullWidth}`}
              disabled
            >
              <Typography
                className="rubikBold"
                style={{ textTransform: "capitalize" }}
              >
                {" "}
                Withdraw{" "}
              </Typography>
            </Button>
          ) : (
            <> </>
          )}
        </Box>
        <Dialog
          style={{
            height: "100%",
            marginLeft: "30%",
            marginRight: "50%",
            width: "80vh",
            borderRadius: "100px",
          }}
          open={this.state.cancelSubscriptionDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {this.renderCancelWithdrawSubscriptionDialog()}
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    familyDetails: state.familyDetails,
    error: state.error,
  };
};

export default connect(mapStateToProps, {
  cancelSubscription,
  resetCancelWithdrawSubscriptionStatus,
  getCustomerDetails,
  withdrawSubscription,
  updateCohortEvent,
  selectCohort,
  getPlanBillingPrefEvent,
  deleteCOIEvent,
  fetchProductByBusineedId,
  sendPlanChangeEvent,
  getCouponDiscountEvent,
  resetProducts,
  resetPlanChange,
  resetOpdTransactions,
  resetCouponValue,
})(withStyles(styles)(MiddleTab));
