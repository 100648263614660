import { color, color as colorConstants } from '../../../../constants/colors';

const useStyles = (() => ({
    drawerRoot: {
        borderTopLeftRadius: '16px',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        borderRadius: '8px 0px 0px 8px',
        overflow: 'auto',
    },
    editIcon: {
        '&:disabled': {
            '& path': {
                fill: color.DARK_GREY,
            },
        },
    },
    header: {
        padding: '4vh 4vh 1vh 4vh',
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        lineHeight: '17px',
        fontSize: '14px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${colorConstants.yellows[100]}`,
        color: colorConstants.blues[300],
        borderTopLeftRadius: '8px',
        position: 'sticky',
        top: '0',
        background: colorConstants.WHITE,
        height: '2.25%',
        zIndex: '50',
    },
    closeIcon: {
        cursor: 'pointer',
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 100%',
        paddingBottom: '0',
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        height: '100%'
    },
    body_centered: {
        padding: '4vh',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Rubik',
        fontSize: '14px',
    },
    footer: {
        padding: 'auto 4vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: '1',
        height: '10%',
    },
    button: {
        textTransform: 'capitalize',
        background: colorConstants.ROYAL_ORANGE,
        color: colorConstants.WHITE,
        borderRadius: '4px',
        height: '38px',
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        fontSize: '14px',
        width: 'calc(100% - 8vh)',
        margin: '4vh 0px',
        '&:hover': {
            background: colorConstants.ORANGE,
        },
    },
    summary: {
        borderRadius: '8px',
        border: `1px solid ${colorConstants.greys[400]}`,
        fontFamily: 'Rubik',
        fontSize: '15px',
        padding: '1vh',
        lineHeight: '17px',
        resize: 'none',
        width: 'calc(100% - 2vh)',
        height: '100px',
        marginTop: '2vh',
    },
    itemContainer: {
        marginTop: '2vh',
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        padding: '0.5vh 4vh',
        // flexDirection: 'column',
        '&:first-child': {
            marginTop: '0',
            gap: '1vh',
        },
        '&:last-child': {
            // flexBasis: '50%',
        }
    },
    itemContainer_centered: {
        marginTop: '2vh',
        '&:first-child': {
            marginTop: '0',
        },
        '&:last-child': {
            flexBasis: '50%',
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    chip: {
        border: `1px solid ${colorConstants.GREY}`,
        fontFamily: 'Rubik',
        color: colorConstants.greys[100],
        background: colorConstants.WHITE,
        fontSize: '14px',
        zIndex: '0',
    },
    chipSelected: {
        border: `1px solid ${colorConstants.ROYAL_ORANGE}`,
        fontFamily: 'Rubik',
        background: `${colorConstants.WHITE}`,
        color: colorConstants.ROYAL_ORANGE,
        fontSize: '14px',
        padding: '0px 4px',
        '&:hover': {
            background: colorConstants.WHITE,
        },
        '&:focus': {
            background: colorConstants.WHITE,
        }
    },
    dropdown: {
        borderRadius: '8px',
        border: `1px solid ${colorConstants.greys[400]}`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        marginTop: '1vh',
        fontFamily: 'Rubik',
        fontSize: '14px',
        color: colorConstants.greys[100],
    },
    menuItem: {
        fontFamily: 'Rubik',
        color: colorConstants.BLACK,
        fontSize: '14px',
    },
    menuItem_placeholder: {
        fontFamily: 'Rubik',
        color: colorConstants.greys[100],
        fontSize: '14px',
    },
    menuItem_available_doctor: {
        fontFamily: 'Rubik',
        color: colorConstants.greens[100],
        fontSize: '14px',
    },
    menuItem_unavailable_doctor: {
        fontFamily: 'Rubik',
        color: colorConstants.greys[100],
        fontSize: '14px',
    },
    doctorOptionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    doctorLabelContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    qualificationsLabel: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        fontSize: '12px',
        color: colorConstants.greys[150],
    },
    separatorContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '1vh',
    },
    separator: {
        display: 'inline-block',
        color: colorConstants.greys[100],
        fontSize: '12px',
        fontFamily: 'Rubik',
        textTransform: 'uppercase',
        padding: '1vh',
    },
    divider: {
        border: '0',
        backgroundColor: colorConstants.greys[100],
        height: '1px',
        width: '70%',
    },
    icon: {
        height: '50%',
        marginRight: '4px',
    },
    caption: {
        display: 'inline-block',
        color: colorConstants.greys[100],
        fontSize: '12px',
        fontFamily: 'Rubik',
        textTransform: 'uppercase',
    },
    chipContainer: {
        marginTop: '1vh',
    },
    inputField: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        padding: '1vh',
        border: `1px solid ${colorConstants.greys[400]}`,
        borderRadius: '8px',
        marginTop: '1vh',
        width: '100%',
    },
    input: {
        border: 'none',
        fontFamily: 'Rubik',
        fontSize: '14px',
        color: colorConstants.BLACK,
        padding: '4px',
        outline: 'none',
    },
    checkbox: {
        padding: '0px',
        color: 'orange',
        marginRight: '1vh',
        '&.Mui-disabled': {
            pointerEvents: 'auto',
            cursor: 'not-allowed',
        }
    },
    multiRowLabel: {
        display: 'flex',
        flexDirection: 'column',
    },
    labelHeading: {
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        fontSize: '14px',
    },
    labelText: {
        fontFamily: 'Rubik',
        fontSize: '14px',
        marginTop: '4px',
        lineHeight: '16px',
    },
    error: {
        fontSize: '14px',
        color: colorConstants.reds[100],
        fontFamily: 'Rubik',
    },
    fileContainer: {
        background: colorConstants.oranges[100],
        borderRadius: '8px',
        padding: '1.5vh',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    fileContainer_header: {
        fontFamily: 'Rubik',
        fontSize: '14px',
        color: colorConstants.greys[100],
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        flexGrow: '0',
    },
    fileContainer_body: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        background: colorConstants.oranges[100],
        marginTop: '1vh'
    },
    documentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        paddingBottom: '1vh',
        borderRadius: '8px',
        border: `1px solid ${colorConstants.greys[400]}`,
        marginTop: '1vh',
        transition: 'border 1s'
    },
    documentContainer_failed: {
        border: `1px solid ${colorConstants.reds[100]}`
    },
    documentRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'calc(100% - 3vh)',
        height: '50%',
        paddingTop: '1vh',
        '&:nth-child(2)': {
            borderTop: `1px solid ${colorConstants.greys[200]}`,
            marginTop: '1vh',
        },
    },
    progressSubRow: {
        marginTop: '4px',
    },
    documentSubRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '4px',
    },
    colorPrimary: {
        background: colorConstants.greys[400],
    },
    colorPrimary_failure: {
        background: colorConstants.reds[100],
    },
    barColorPrimary: {
        background: colorConstants.ROYAL_ORANGE,
    },
    documentColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        flex: '1 auto',
        paddingLeft: '12px',
        width: '(100% - 12px)'
    },
    previewColumn: {
        height: '100%',
        width: '48px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '1vh',
    },
    preview: {
        height: '48px',
        width: '48px',
        background: colorConstants.WHITE,
        borderRadius: '8px',
        objectFit: 'cover',
        overflow: 'hidden',
    },
    closePreviewBtn: {
        height: '20px',
        width: '16px',
        fontFamily: 'Rubik',
        fontSize: '14px',
        color: colorConstants.WHITE,
        borderRadius: '16px',
        border: 'none',
        cursor: 'pointer',
        background: colorConstants.BLACK
    },
    fileSizeLabel: {
        fontFamily: 'Rubik',
        fontSize: '12px',
        color: colorConstants.greys[100],
    },
    typeLabel: {
        fontFamily: 'Rubik',
        fontSize: '14px',
        color: colorConstants.greys[100],
    },
    typeValue: {
        fontFamily: 'Rubik',
        fontSize: '14px',
        color: colorConstants.ROYAL_ORANGE,
    },
    progressLabel: {
        fontFamily: 'Rubik',
        fontSize: '12px',
        color: colorConstants.greys[100],
    },
    documentName: {
        fontSize: '12px',
        fontFamily: 'Rubik',
        color: colorConstants.BLACK,
    },
    editBtnRoot: {
        height: '32px',
        width: '32px',
    },
    selectDocumentTypeBtn: {
        minHeight: '32px',
        maxHeight: '32px',
        minWidth: '32px',
        maxWidth: '32px',
        padding: '0px',
        border: `1px solid ${colorConstants.ROYAL_ORANGE}`,
        background: colorConstants.WHITE,
        '&:hover': {
            background: colorConstants.WHITE,
        },
        '&:disabled': {
            border: `1px solid ${colorConstants.greys[400]}`,
        },
    },
    uploadBtn: {
        background: colorConstants.WHITE,
        border: `1px solid ${colorConstants.ROYAL_ORANGE}`,
        outline: 'none !important',
        borderRadius: '8px',
        '&:hover': {
            background: colorConstants.WHITE,
        },
        textTransform: 'capitalize',
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        color: colorConstants.ROYAL_ORANGE,
        fontSize: '14px',
    },
    // retryUploadBtn: {
    //     background: colorConstants.WHITE,
    //     border: `1px solid ${colorConstants.ROYAL_ORANGE}`,
    //     outline: 'none !important',
    //     borderRadius: '8px',
    //     '&:hover': {
    //         background: colorConstants.WHITE,
    //     },
    //     textTransform: 'capitalize',
    //     fontFamily: 'Rubik',
    //     fontWeight: 'bold',
    //     color: colorConstants.ROYAL_ORANGE,
    //     fontSize: '14px',
    //     width: '100%'
    // },

    retryUploadBtn: {
        background: colorConstants.WHITE,
        border: `1px solid ${colorConstants.ROYAL_ORANGE}`,
        outline: 'none !important',
        borderRadius: '8px',
        '&:hover': {
            background: colorConstants.WHITE,
        },
        textTransform: 'capitalize',
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        color: colorConstants.ROYAL_ORANGE,
        fontSize: '14px',
        width: '100%'
    },
    whatsAppConsentContainer: {
        display: 'flex',
        width: '100%',
        paddingTop: '1.5vh',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    whatsAppConsentText: {
        color: '#272727',
        fontSize: '0.8rem',
        fontFamily: 'Rubik',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold'
    },
    whatsAppConsentInfo: {
        color: '#272727',
        fontSize: '0.8rem',
        fontFamily: 'Rubik',
        padding: '0 27px 0 54px'
    },
    lottieContainer: {
        height: '60px',
        width: '60px',
    },
    loadingCircle: {
        color: color.ROYAL_ORANGE,
    },
}));

export default useStyles;