import { SEARCH_PRODUCTS_BY_BUSINESS_ID, LOG_OUT, RESET_PRODUCTS_BY_BUSINESS_ID, GET_CUSTOMER_BY_PHONE_NUMBER,
    RESET_CUSTOMER_BY_PHONE_NUMBER, GET_ALL_MEMBERS, RESET_GET_ALL_MEMBERS, CREATE_SUBSCRIPTION, RESET_CREATE_SUBSCRIPTION,
    SET_NEW_PLAN_MOBILE_NUMBER, RESET_NEW_PLAN_MOBILE_NUMBER, PLAN_CANCELATION_REQUEST,
    PLAN_CANCELATION_SUCCESS, PLAN_CANCELATION_FAILURE, UPDATE_WHATSAPP_CONSENT_ONBOARDING_REQUEST, UPDATE_WHATSAPP_CONSENT_ONBOARDING_SUCCESS,
    UPDATE_WHATSAPP_CONSENT_ONBOARDING_FAILURE } from '../actions/types';

const INITIAL_STATE = {
    invoicesOfSubscription: null,
    isLoading: false
};

const addNewPlanReducer = (state = { INITIAL_STATE }, action) => {
    switch (action.type) {
        case SEARCH_PRODUCTS_BY_BUSINESS_ID:
            return { ...state, productList: action.payload }
        case RESET_PRODUCTS_BY_BUSINESS_ID:
            return { ...state, productList: null }
        case RESET_CUSTOMER_BY_PHONE_NUMBER:
            return { ...state, customerByPhoneNumber: null }
        case GET_CUSTOMER_BY_PHONE_NUMBER:
            return { ...state, customerByPhoneNumber: action.payload }
        case GET_ALL_MEMBERS:
            return { ...state, allMembersOfCurrentFamily: action.payload }
        case RESET_GET_ALL_MEMBERS:
            return { ...state, allMembersOfCurrentFamily: action.payload }
        case CREATE_SUBSCRIPTION:
            return { ...state, subscribedPlan: action.payload }
        case RESET_CREATE_SUBSCRIPTION:
            return { ...state, subscribedPlan: null }
        case SET_NEW_PLAN_MOBILE_NUMBER:
            return { ...state, newPlanMobileNumber: action.payload }
        case RESET_NEW_PLAN_MOBILE_NUMBER:
            return { ...state, newPlanMobileNumber: null }
        case PLAN_CANCELATION_REQUEST:
            return {
                ...state,
                customerByPhoneNumber: {
                    ...state.customerByPhoneNumber,
                    cancelSubcriptionPlan: false,
                },
                INITIAL_STATE: {
                    ...state.INITIAL_STATE,
                    isLoading: true
                }
            }
        case PLAN_CANCELATION_SUCCESS:
            return {
                ...state,
                customerByPhoneNumber: {
                    ...state.customerByPhoneNumber,
                    cancelSubcriptionPlan: true,
                },
                INITIAL_STATE: {
                    ...state.INITIAL_STATE,
                    isLoading: false
                }
            }  
        case PLAN_CANCELATION_FAILURE:
            return {
                ...state,
                customerByPhoneNumber: {
                    ...state.customerByPhoneNumber,
                    cancelSubcriptionPlan: false,
                },
                INITIAL_STATE: {
                    ...state.INITIAL_STATE,
                    isLoading: false
                }
            }      
        case UPDATE_WHATSAPP_CONSENT_ONBOARDING_REQUEST:
            return {
                ...state,
                INITIAL_STATE: {
                    ...state.INITIAL_STATE,
                    isLoading: true,
                }
            }
        case UPDATE_WHATSAPP_CONSENT_ONBOARDING_SUCCESS:
            return {
                ...state,
                customerByPhoneNumber: {
                    ...state.customerByPhoneNumber,
                    preferences: {
                        ...state.customerByPhoneNumber.preferences || {},
                        whatsapp: {
                        consent: action.payload.consent,
                        phone: action.payload.phone,
                        }
                    } 
                },
                INITIAL_STATE: {
                    ...state.INITIAL_STATE,
                    isLoading: false,
                }
            };
        case UPDATE_WHATSAPP_CONSENT_ONBOARDING_FAILURE:
            return {
                ...state,
                INITIAL_STATE: {
                    ...state.INITIAL_STATE,
                    isLoading: false,
                }
            }
        case LOG_OUT:
            return { productList: null };
        default:
            return state;
    }
};

export default addNewPlanReducer;

