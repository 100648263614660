import * as Sentry from '@sentry/react';
import {
    postConsultation, addWhatsAppConcent, searchItemsForBilling, getBillingHistory,
    getProductCategoriesList,
} from '../clients';
import {
    UPDATE_WHATSAPP_CONSENT_REQUEST,
    UPDATE_WHATSAPP_CONSENT_SUCCESS,
    UPDATE_WHATSAPP_CONSENT_FAILURE,
    LOG_OUT,
} from '../actions/types'
import {
    errorActionTypes,
    consultationActionTypes,
    orderActionTypes,
    itemTypes,
    discountType,
} from '../constants';
import { buildCustomError } from '../common/utils';
import { addLineItemsToOrder, createOrder } from '../clients/omsService';
import { defaultEvitalLineItem } from '../config';

const { StatusCodes: { UNAUTHORIZED, NOT_FOUND } } = require('http-status-codes');

function bookConsultationEvent(consultationObject) {
    const request = () => ({ type: consultationActionTypes.BOOK_CONSULTATION_REQUEST });
    const success = (payload) => ({ type: consultationActionTypes.BOOK_CONSULTATION_SUCCESS, payload });
    const failure = () => ({ type: consultationActionTypes.BOOK_CONSULTATION_FAILURE });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
    const resetError = () => ({ type: errorActionTypes.RESET_ERROR });

    return async (dispatch, getState) => {
        const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
        dispatch(request());
        try {
            dispatch(resetError());
            const response = await postConsultation(ACCESS_TOKEN, consultationObject);
            dispatch(success(response.data));
        } catch (e) {
            const error = buildCustomError(e);
            Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`), { extra: error.data });
            dispatch(failure());
            dispatch(setError(error));
        }
    };
}

export const updateWhatsAppConsultation = (consent, phone) => {
    const request = () => ({ type: UPDATE_WHATSAPP_CONSENT_REQUEST });
    const success = (payload) => ({ type: UPDATE_WHATSAPP_CONSENT_SUCCESS, payload });
    const failure = () => ({ type: UPDATE_WHATSAPP_CONSENT_FAILURE });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
    const resetError = () => ({ type: errorActionTypes.RESET_ERROR });

    return async (dispatch, getState) => {
        const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
        dispatch(request());
        try {
            const familyDetails = getState().familyDetails;
            const consentData = {
                whatsapp: {
                    consent: consent,
                    phone: phone
                }
            }
            dispatch(resetError());
            await addWhatsAppConcent(ACCESS_TOKEN, consentData, familyDetails.currentCustomer.id);
            dispatch(success(consentData.whatsapp));
        } catch (e) {
            const error = buildCustomError(e);
            Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`), { extra: error.data });
            dispatch(failure());
            dispatch(setError(error));
        }
    };
}

/**
 * Dispatches an event to set relevant details for **general** billing.
 * @param {String} customer object with details of the customer being billed;;
 */
 const setGeneralBilling = (customer) => {
    const billing = (payload) => ({
        type: consultationActionTypes.SET_GENERAL_BILLING,
        payload,
    });
    return async (dispatch, getState) => {
        dispatch(billing({
            customer,
        }));
    };
}

/**
 * Dispatches an event to set relevant details for **consultation** billing.
 * @param {String} consultationId ID of the consultation selected for billing;
 * @param {String} member the name of the family member for whom the bill is being generated;
 */
const selectConsultationForBilling = (consultation) => {
    const billing = (payload) => ({
        type: consultationActionTypes.SELECT_CONSULTATION_FOR_BILLING,
        payload,
    });
    return async (dispatch, getState) => {
        dispatch(billing({
            consultation,
        }));
    };
}

/**
 * Dispatches an event to update the customer being billed - only applicable for general billing!
 * @param {String} member the name of the family member for whom the bill is being generated;
 */
 const updateBilledCustomer = (customer) => {
    const update = (payload) => ({
        type: consultationActionTypes.MODIFY_BILLED_CUSTOMER,
        payload,
    });
    return async (dispatch, getState) => {
        dispatch(update({
            customer,
        }));
    };
}

const updateBilledSubscription = (subscription) => {
    const update = (payload) => ({
        type: consultationActionTypes.MODIFY_BILLED_SUBSCRIPTION,
        payload,
    });
    return async (dispatch, getState) => {
        dispatch(update({
            subscription,
        }));
    };
}

/**
 * @param id is the ID of the object to be modified
 * @param modifications is an Object specifying the medifications to be made.
 *  
 * This function dispatches an event to signal __addition__ of an item.
 * The logic to add an item by default is implemented in the reducer.
 */
const modifyLineItem = (id, modifications) => {
    const modify = (payload) => ({
        type: consultationActionTypes.MODIFY_LINE_ITEM,
        payload,
    })
    return async (dispatch, getState) => {
        dispatch(modify({ id, modifications }));
    }
}

/**
 * This function dispatches an event to signal __addition__ of an item.
 * The logic to add an item by default is implemented in the reducer.
 */
const addLineItem = (itemType, item) => {
    const add = () => ({
        type: consultationActionTypes.ADD_LINE_ITEM,
        payload: {
            itemType,
            item
        }
    });
    return async (dispatch) => {
        dispatch(add());
    }
}

/**
 * This function dispatches an event to signal __addition__ of multiple items.
 * The logic to add an item by default is implemented in the reducer.
 */
const addMultipleLineItems = (items) => {
    const addItems = () => ({
        type: consultationActionTypes.ADD_MULTIPLE_LINE_ITEMS,
        payload: {
            items,
        }
    });
    return async (dispatch) => {
        dispatch(addItems());
    }
}

/**
 * This function dispatches an event to signal __deletion__ of all items of a given type.
 * The logic to add an item by default is implemented in the reducer.
 */
const flushLineItemsOfType = (type) => {
    const flush = () => ({
        type: consultationActionTypes.FLUSH_LINE_ITEMS_OF_TYPE,
        payload: {
            type,
        }
    });
    return async (dispatch) => {
        dispatch(flush());
    }
}

const setFilterDateTo = (date) => {
    const setDate = (payload) => ({
        type: consultationActionTypes.SET_FILTER_DATE_TO,
        payload,
    });
    return async (dispatch) => {
        dispatch(setDate(date));
    }
}

const setFilterDateFrom = (date) => {
    const setDate = (payload) => ({
        type: consultationActionTypes.SET_FILTER_DATE_FROM,
        payload,
    });
    return async (dispatch) => {
        dispatch(setDate(date));
    }
}

export const searchBillingItems = (item, category, clinic) => {
    const request = () => ({ type: consultationActionTypes.SEARCH_ITEM_FOR_BILLING_REQUEST });
    const success = (payload) => (
        { type: consultationActionTypes.SEARCH_ITEM_FOR_BILLING_SUCCESS, payload }
    );
    const failure = () => ({ type: consultationActionTypes.SEARCH_ITEM_FOR_BILLING_FAILURE });
    const resetSession = () => ({ type: LOG_OUT });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
    return async (dispatch, getState) => {
        dispatch(request());
        try {
            const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
            const response = await searchItemsForBilling(
                ACCESS_TOKEN, item, category, clinic,
            );
            dispatch(success(response));
        } catch (e) {
            const error = buildCustomError(e);
            dispatch(setError(error));
            if (error.statusCode === UNAUTHORIZED) {
                dispatch(resetSession());
            } else {
                dispatch(failure());
                Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`), { extra: error.data });
            }
        }
    };
};

export const getBillingHistoryEvent = (familyId, orderId, generatedOn, page, size) => {
    const request = (payload) => ({ type: consultationActionTypes.GET_BILLING_HISTORY_REQUEST, payload });
    const success = (payload) => (
        { type: consultationActionTypes.GET_BILLING_HISTORY_SUCCESS, payload }
    );
    const failure = (payload) => ({ type: consultationActionTypes.GET_BILLING_HISTORY_FAILURE, payload });
    const resetSession = () => ({ type: LOG_OUT });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
    return async (dispatch, getState) => {
        dispatch(request({
            page,
        }));
        try {
            const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
            const response = await getBillingHistory(
                ACCESS_TOKEN, familyId, orderId, generatedOn, page, size,
            );
            dispatch(success({
                data: response.data.hasOwnProperty('billing_history_data') ? response.data.billing_history_data : null,
                count: response.data.count,
            }));
        } catch (e) {
            const error = buildCustomError(e);
            dispatch(setError(error));
            if (error.statusCode === NOT_FOUND) {
                error.message = "No billing history was found.";
                dispatch(failure({
                    data: [],
                    count: null,
                }));
            }
            if (error.statusCode === UNAUTHORIZED) {
                dispatch(resetSession());
                Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`), { extra: error.data });
            }
            dispatch(failure({
                data: null,
                count: null,
            }));
        }
    };
}

export const resetBilling = () => {
    const reset = () => ({
        type: consultationActionTypes.RESET_BILLING,
    });
    return async (dispatch) => {
        dispatch(reset());
    }
};

export const resetBillingHistory = () => {
    const reset = () => ({
        type: consultationActionTypes.RESET_BILLING_HISTORY,
    });
    return async (dispatch) => {
        dispatch(reset());
    }
};

export const getProductCategories = () => {
    const request = () => ({
        type: consultationActionTypes.GET_PRODUCT_CATEGORIES_REQUEST,
    });
    const success = (productCategories) => ({
        type: consultationActionTypes.GET_PRODUCT_CATEGORIES_SUCCESS,
        payload: productCategories,
    });
    const failure = () => ({
        type: consultationActionTypes.GET_PRODUCT_CATEGORIES_FAILURE,
    });
    const resetSession = () => ({ type: LOG_OUT });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });

    return async (dispatch, getState) => {
        const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
        try {
            dispatch(request());
            const productCategories = await getProductCategoriesList(ACCESS_TOKEN);
            if (productCategories?.data) {
                dispatch(success(productCategories?.data?.categories));
            }
        } catch (e) {
            const error = buildCustomError(e);
            dispatch(failure());
            dispatch(setError(e.message));
            if (e.status === UNAUTHORIZED) {
                dispatch(resetSession());
            } else {
                Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`), { extra: error.data });
            }
        }
    }
};

const createOrderForFamilyIdWithLineItem = (familyId, firstLineItem, linkToEvitalRx = false) => {
    const request = () => ({ type: orderActionTypes.CREATE_ORDER_FOR_FAMILY_ID_REQUEST });
    const success = (payload) => ({ type: orderActionTypes.CREATE_ORDER_FOR_FAMILY_ID_SUCCESS, payload });
    const failure = () => ({ type: orderActionTypes.CREATE_ORDER_FOR_FAMILY_ID_FAILURE });
    const resetSession = () => ({ type: LOG_OUT });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
    return async (dispatch, getState) => {
        const consultationId = getState().consultationDetails.billing.consultation;
        const customerId = getState().consultationDetails.billing.customer.id;
        const subscriptionId = getState()?.consultationDetails?.billing?.subscription?.id;
        const clinicId = getState().auth.currentClinicLocation.id;
        const defaultLineItem = {
            external: {
                id: defaultEvitalLineItem,
            },
            metadata: {
                display_name: "DEFAULT_EVITAL_ITEM"
            },
            type: itemTypes.EVITAL_MEDICINE,
            quantity: 1,
            price: 1,
            total_discount: 100,
            total_discount_type: discountType.PERCENTAGE,
        }
        const orderData = {
            items: [],
            metadata: {
                clinic: {
                    id: clinicId,
                },
                ...(Boolean(consultationId) && {
                    consultation: {
                        id: consultationId,
                    }
                }),
                customer: {
                    id: customerId
                },
            },
        };
        if (firstLineItem?.subscription_id !== '') {
            orderData.subscription_id = firstLineItem?.subscription_id
        }
        if (subscriptionId !== '') {
            orderData.subscription_id = subscriptionId;
        }
        if (linkToEvitalRx) {
            orderData.items.push(defaultLineItem);
        } else {
            if (!Array.isArray(firstLineItem)) {
                firstLineItem.external = {
                    id: firstLineItem.external_id,
                }
                delete firstLineItem.external_id;
                orderData.items.push(firstLineItem);
            } else {
                orderData.items = firstLineItem;
            }
        }
        const accessToken = getState().auth.authDetails.data.access_token;
        try {
            dispatch(request());
            const result = await createOrder(accessToken, orderData, familyId);
            const successPayload = {
                order: {
                    id: result.data.id,
                },
            };
            if (linkToEvitalRx) {
                successPayload.evital_redirect_url = result.data.metadata.evital_redirect_url;
                window.open(result.data.metadata.evital_redirect_url, '_blank');
            }
            dispatch(success(successPayload));
        } catch (e) {
            const error = buildCustomError(e);
            dispatch(failure());
            dispatch(setError(e.message));
            if (e.status === UNAUTHORIZED) {
                dispatch(resetSession());
            } else {
                Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`), { extra: error.data });
            }
        }
    }
};

const addDefaulteVitalLineItemToOrder = (orderId) => {
    const request = () => ({ type: orderActionTypes.ADD_DEFAULT_EVITAL_LINE_ITEM_TO_ORDER_REQUEST });
    const success = (payload) => ({ type: orderActionTypes.ADD_DEFAULT_EVITAL_LINE_ITEM_TO_ORDER_SUCCESS, payload });
    const failure = () => ({ type: orderActionTypes.ADD_DEFAULT_EVITAL_LINE_ITEM_TO_ORDER_FAILURE });
    const resetSession = () => ({ type: LOG_OUT });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
    return async (dispatch, getState) => {
        // const consultationId = getState().consultationDetails.billing.consultation;
        const customerId = getState().consultationDetails.billing.customer.id;
        const clinicId = getState().auth.currentClinicLocation.id;
        const defaultLineItem = {
            external_id: defaultEvitalLineItem,
            metadata: {
                display_name: "DEFAULT_EVITAL_ITEM",
                customer_id: customerId,
                },
            clinic_id: clinicId,
            type: itemTypes.EVITAL_MEDICINE,
            quantity: 1,
            price: 1,
            total_discount: 100,
            total_discount_type: discountType.PERCENTAGE,
        }
        const accessToken = getState().auth.authDetails.data.access_token;
        try {
            dispatch(request());
            const result = await addLineItemsToOrder(accessToken, orderId, defaultLineItem);
            window.open(result.data.metadata.evital_redirect_url, '_blank');
            dispatch(success({
                evital_redirect_url: result.data.metadata.evital_redirect_url,
            }));
        } catch (e) {
            const error = buildCustomError(e);
            dispatch(failure());
            dispatch(setError(e.message));
            if (e.status === UNAUTHORIZED) {
                dispatch(resetSession());
            } else {
                Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`), { extra: error.data });
            }
        }
    }
}

const unlinkEvitalSaleOrder = () => {
    const unlink = () => ({ type: consultationActionTypes.UNLINK_EVITAL_SALE_ORDER })
    return async (dispatch, getState) => {
        dispatch(unlink());
    }
}

const updateOPDUtilisedValue = (amount) => {
    const success = (payload) => ({ type: consultationActionTypes.OPD_AMOUNT_UTILISED, payload })
    return async (dispatch) => {
        dispatch(success({ amount }));
    }
}

// eslint-disable-next-line import/prefer-default-export
export const consultationActions = {
    bookConsultationEvent,
    setGeneralBilling,
    selectConsultationForBilling,
    updateBilledCustomer,
    updateBilledSubscription,
    modifyLineItem,
    addLineItem,
    addMultipleLineItems,
    flushLineItemsOfType,
    setFilterDateTo,
    setFilterDateFrom,
    searchBillingItems,
    getBillingHistoryEvent,
    resetBilling,
    resetBillingHistory,
    getProductCategories,
    createOrderForFamilyIdWithLineItem,
    addDefaulteVitalLineItemToOrder,
    unlinkEvitalSaleOrder,
    updateOPDUtilisedValue,
};
