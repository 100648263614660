/* eslint-disable max-len */
import React from 'react';
import {
  Button, AppBar, Toolbar, Box, Typography,
} from '@material-ui/core';
import useStyles from './style';

export default function FloatingActionButton({
  transparentBackground = false, show = false, title, onClick, disabled = false, elevation = 0, showCurvedTop = false, callClinikkOnClick = false, buttonHintText = false, errorMessageAboveButton =false,
}) {
  const classes = useStyles();
  if (!show) {
    return <Box />;
  }
  return (
    // eslint-disable-next-line no-nested-ternary
    <AppBar position="absolute" color="transparent" elevation={elevation} className={showCurvedTop ? classes.appBarCurvedTop : transparentBackground ? classes.appBarTransparent : classes.appBar}>
      <Toolbar className={classes.toolbar}>
        {buttonHintText && <Typography className={errorMessageAboveButton ? classes.buttonHintErrorText : classes.buttonHintText}>{buttonHintText}</Typography>}
        <Button
          href={callClinikkOnClick ? 'tel:+91-7813-811-811' : ''}
          variant="contained"
          disabled={disabled}
          // color="primary"
          className={disabled ? classes.buttonDisabled : classes.button}
          onClick={onClick}
        >
          {title}
        </Button>
      </Toolbar>
    </AppBar>
  );
}
