/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import useStyles from './style'
import { withStyles, Typography, Button } from '@material-ui/core';
import { couponAction } from '../../../actions/couponAction';
import { ReactComponent as PercentageOrange } from '../../../assets/icons2/coupon/percentage-orange.svg';
import { ReactComponent as ArrowRightWhite } from '../../../assets/icons2/common/arrow-right-whiite.svg';
import { ReactComponent as Tick } from '../../../assets/icons2/common/tick.svg';
import { ReactComponent as Close } from '../../../assets/icons2/common/close.svg';
import { getQueryParams, getOrderTotal } from '../../../common/utils';
import _ from 'lodash';
class ApplyCoupon extends Component {

  constructor(props) {
    super(props);
    this.state = {
      couponCode: '',
      isCouponValidated: false,
    }
  }
  handleCouponCode = (event) => {
    const couponString = event.target.value;
    const { couponDetailUpdate, resetCoupon } = this.props;
    resetCoupon();
    if (couponString.length === 0) {
      couponDetailUpdate(false);
    }
    this.setState({ couponCode: couponString, isCouponValidated: false });
  }

  clearApplyCouponTextField = () => {
    const { couponDetailUpdate, resetCoupon } = this.props;
    this.setState({ couponCode: '', isCouponValidated: false });
    couponDetailUpdate(false);
    resetCoupon();
  }

  validateCouponAndReturn = () => {
    const {
      validateCoupon, couponDetailUpdate, familyDetails,
      consultationDetails,
    } = this.props;
    const { couponCode, isCouponValidated } = this.state;
    const { items } = consultationDetails.billing;
    const formattedItems =
      items.filter(item => item.total > 0)
        .map(item => ({
          product: {
            name: item.name,
            id: String(item.id),
            amount: item.rate,
          },
          quantity: item.quantity,
        }))
    const validateCouponObject = {
      family: {
        id: familyDetails.currentCustomer.family.id
      },
      order: {
        amount: getOrderTotal(items.filter((item) => item.total > 0)),
        items: formattedItems,
      }
    };
    if (couponCode !== '' || couponCode !== null) {
      validateCoupon(couponCode, validateCouponObject);
    }
    if (!isCouponValidated) {
      this.setState({ isCouponValidated: true }, () => {
        couponDetailUpdate(true);
      });
    }
  }

  renderCouponUIString = () => {
    const { isCouponValidated } = this.state;
    const { coupon } = this.props;
    if (isCouponValidated && !coupon.isLoading) {
      if (coupon.valid) {
        return 'Coupon successfully applied!'
      }
      else if (!coupon.valid) {
        return 'Invalid coupon. Please retype!';
      }
      if (coupon.applicableTo === 'line_item' && _.isEmpty(coupon.applicable_line_items)) {
        return 'Coupon not applicable.';
      }
    } else {
      return '';
    }
  }

  componentDidMount() {
    const query = getQueryParams();
    const couponCode = query.get('couponCode');
    if (couponCode) {
      // autofill and apply
      this.setState({
        couponCode: couponCode,
        isCouponValidated: false,
      }, () => {
        this.validateCouponAndReturn();
      });
    };
  };

  componentDidUpdate(prevProps) {
    const { consultationDetails } = this.props;
    if (prevProps?.consultationDetails.billing?.items != consultationDetails.billing.items
      && (consultationDetails?.billing?.items.length === 0
        || getOrderTotal(consultationDetails.billing.items) === 0)) {
      this.clearApplyCouponTextField();
    }
    const prevBillTotal = getOrderTotal(prevProps.consultationDetails?.billing?.items);
    const newBillTotal = getOrderTotal(consultationDetails?.billing?.items);
    if (prevBillTotal !== newBillTotal) {
      this.clearApplyCouponTextField();
    }
  }

  componentWillUnmount() {
    const { resetCoupon } = this.props;
    resetCoupon();
  }

  getCouponUiClasses = (element) => {
    const { classes, coupon, creditApplied, consultationDetails } = this.props;
    const itemList = consultationDetails?.billing?.items;
    const itemTotal = getOrderTotal(itemList);
    switch (element) {
      case ('input'): {
        return classes.applyCouponInputField;
      }
      case ('base'): {
        if (creditApplied || itemList.length === 0 || itemTotal === 0) {
          return classes.applyCouponTextField_disabled;
        }
        if (!coupon.code) {
          return classes.applyCouponTextField;
        }
        if (coupon.code) {
          if (coupon.isLoading) return classes.applyCouponTextField;
          if (coupon.valid) return classes.applyCouponTextField_success;
          if (!coupon.valid || coupon.error) return classes.applyCouponTextField_failure;
        }
      }
      case ('label'): {
        if (coupon.code && coupon.valid) {
          return classes.appliedValidCouponStatus
        }
        else return classes.appliedInvalidCouponStatus;
      }
      default:
        return 'testing';
    }
  }

  render() {
    const { classes, coupon, creditApplied, disabledOnClickHandler, consultationDetails } = this.props;
    const { couponCode } = this.state;
    const itemList = consultationDetails?.billing?.items;
    return (
      <div className={classes.applyCouponContainer} onClick={() => {
        if (creditApplied) {
          disabledOnClickHandler();
        }
      }}>
        <div className={classes.applyCouponRow}>
          <div className={classes.applyCouponHeader}>
            <PercentageOrange />
            <Typography className={classes.applyCouponTitleAndIcon}>
              Apply Coupon
            </Typography>
          </div>
          {
            this.state.isCouponValidated
              && coupon
              && !coupon.isLoading
              && coupon?.referral
              ? (<div>
                <div className={classes.referralAppliedSubheading}>
                  {`You will receive Rs. ${coupon.referral.amount} Clinikk Cash after successful payment`}
                </div>
              </div>
              )
              : null
          }
        </div>
        <div className={this.getCouponUiClasses('base')}>
          <input
            // {...this.props}
            disabled={itemList.length === 0 || creditApplied || getOrderTotal(itemList) === 0}
            type="text"
            required
            id="couponCode"
            placeholder="Enter your coupon code"
            className={this.getCouponUiClasses('input')}
            onChange={this.handleCouponCode}
            value={this.state.couponCode}
            name='couponCode'
          // autoFocus
          />
          {
            this.state.isCouponValidated && !coupon.isLoading ? (
              coupon.valid ? <Button disabled={!couponCode || creditApplied || (getOrderTotal(consultationDetails?.billing?.items) === 0)} onClick={this.clearApplyCouponTextField} className={classes.applyCouponButton_success}><Tick className={classes.applyCouponStatusIcon} /></Button>
                : <Button disabled={!couponCode || creditApplied || (getOrderTotal(consultationDetails?.billing?.items) === 0)} onClick={this.clearApplyCouponTextField} className={`${classes.applyCouponButton_failure}`}><Close className={classes.applyCouponStatusIcon} /></Button>)
              : <Button onClick={this.validateCouponAndReturn} disabled={!couponCode || creditApplied || (getOrderTotal(consultationDetails?.billing?.items) === 0)} disableRipple={true} className={`${classes.applyCouponButton}`}><ArrowRightWhite className={classes.applyCouponIcon} /></Button>
          }
        </div>
        <div className={classes.applyCouponRow}>
          {
            this.state.isCouponValidated
              && !coupon.isLoading
              ? (<div className={classes.couponLabel}>
                <Typography className={this.getCouponUiClasses('label')}>
                  {this.renderCouponUIString()}
                </Typography>
              </div>)
              : null
          }
        </div>
      </div>
    )


  }
};

const mapStateToProps = (state) => {
  const { auth, familyDetails, coupon, consultationDetails } = state;
  return { auth, coupon, familyDetails, consultationDetails };
};

const mapDispatchToProps = (dispatch) => {
  return {
    validateCoupon: (couponCode, body) => { dispatch(couponAction.validateCoupon(couponCode, body)); },
    resetCoupon: () => { dispatch(couponAction.resetCoupon()); },
  }
}

const reduxWrapper = connect(mapStateToProps, mapDispatchToProps);
const styleWrapper = withStyles(useStyles, { withTheme: true });
export default styleWrapper(reduxWrapper(ApplyCoupon));

