// Todo: create new for the app
let resendPaymentLinkNotificationTempId = 37;
let whatsAppTemplateName = 'renewal_btn_en_v4';

if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'production') {
  resendPaymentLinkNotificationTempId = 31;
}

const channels = {
  SMS: 'sms',
  WHATSAPP: 'whatsapp',
}

// eslint-disable-next-line import/prefer-default-export
export const notification = {
  resendPaymentLinkNotificationTempId,
  whatsAppTemplateName,
  channels,
};
