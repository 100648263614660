import { Button, Menu, MenuItem, withStyles } from '@material-ui/core'
import React, { useState } from 'react';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit.svg';
import { healthVaultRecordTypes } from '../../../../constants/consultationConstant';
import useStyles from './ConsultationFromStyle';

function SelectRecordMenuButton(props) {
    const { handleSelectRecordOnChange, doc, classes } = props;
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuClose = (e) => {
        setAnchorEl(null);
    };
    const handleMenuItemOnClick = (recordType) => {
        const { doc } = props;
        setAnchorEl(null);
        handleSelectRecordOnChange(recordType, doc);
    }
    return (
        <>
            <Button
                className={classes.selectDocumentTypeBtn}
                variant="outlined"
                color="primary"
                id={`select-doc-type-${doc.id}`}
                onClick={(e) => setAnchorEl(e.currentTarget)}
                disabled={(doc.isUploading)
                    || (!doc.isUploading && doc.status === 'success')
                }
            >
                <EditIcon className={classes.editIcon} />
            </Button>
            <Menu
                aria-labelledby={`select-doc-type-${doc.id}`}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'top'
                }}
                anchorEl={() => {
                    return document.getElementById(`select-doc-type-${doc.id}`)
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                }}
                open={Boolean(anchorEl)}
                // value={doc.type}
                onClose={handleMenuClose}
            >
                {
                    healthVaultRecordTypes.map(record => {
                        return (
                            <MenuItem
                                classes={{
                                    root: classes.menuItem
                                }}
                                onClick={() => { handleMenuItemOnClick(record.value) }}
                            >
                                {record.label}
                            </MenuItem>
                        )
                    })
                }
            </Menu>
        </>
    )
};

const styleWrapper = withStyles(useStyles, { withTheme: true });
export default styleWrapper(SelectRecordMenuButton);