import React from 'react';
import { connect } from 'react-redux';

import {
    Table, Box, TableBody, TableCell, TableRow, CircularProgress,
    Typography, TextField, InputAdornment, FormControl, Select, Input,
} from '@material-ui/core';
import { ReactComponent as CloseButtonXActive } from '../../../images/CloseButtonXActive.svg';
import { getCreditDetailsByFamilyId } from '../../../actions';
import { createOfflinePaymentEvent, resetOfflinePaymentEvent } from '../../../actions/paymentAction';
import FabButton from '../../common/fab';
import { creditDeductionReason, invoiceType } from '../../../constants';


function createData(name, value) {
    return { name, value };
}
class MiddleTab extends React.Component {

    state = { deductCreditDrawer: false, amountToBeDeducted: '', externalId: '', creditDeductionReason: creditDeductionReason.CONSULTATION };

    componentDidMount() {
        const familyId = this.props.familyDetails.currentCustomer ? this.props.familyDetails.currentCustomer.family.id : 0;
        this.props.getCreditDetails(familyId);
        this.props.resetOfflinePayment();
    };

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    getThirdTextFieldHeading = () => {
        if (this.state.creditDeductionReason === creditDeductionReason.CONSULTATION) {
            return 'Enter the Consultation ID';
        } else if (this.state.creditDeductionReason === creditDeductionReason.MEDICINES) {
            return 'Enter the Bill No.';
            // } else if (this.state.creditDeductionReason === creditDeductionReason.RENEWAL_OF_PLAN) {
            //     return 'Enter the Subscription ID';
        } else {
            return '';
        }
    }

    getButtonHintText = () => {
        if (this.props.familyDetails.creditDetail && this.props.familyDetails.creditDetail.amount && (Number(this.state.amountToBeDeducted) > this.props.familyDetails.creditDetail.amount)) {
            return 'No sufficient credit available';
        } else if (this.props && this.props.payment && this.props.payment.creditDeductionResponse === 'failed') {
            return 'Credit Deduction failed';
        } else {
            return '';
        }
    }

    deductButtonState = () => {
        if (this.props.familyDetails.creditDetail && this.props.familyDetails.creditDetail.amount && (Number(this.state.amountToBeDeducted) > this.props.familyDetails.creditDetail.amount)) {
            return true;
        } else if (!this.state.amountToBeDeducted || !this.state.creditDeductionReason || !this.state.externalId) {
            return true;
        } else {
            return false;
        }
    };

    deductCredit = () => {
        const familyId = this.props.familyDetails.currentCustomer ? this.props.familyDetails.currentCustomer.family.id : 0;
        this.props.createOfflinePaymentEvent(this.state.externalId, this.getInvoiceType(), this.state.amountToBeDeducted, familyId);
    };

    getInvoiceType = () => {
        if (this.state.creditDeductionReason === creditDeductionReason.MEDICINES) {
            return invoiceType.MEDICINE_INVOICE;
        } else if (this.state.creditDeductionReason === creditDeductionReason.CONSULTATION) {
            return invoiceType.CONSULTATION_INVOICE;
        }
    }

    renderDrawerContent = () => {
        return (
            <Box style={{ display: 'flex', flexDirection: 'column', position: 'relative', height: '100vh' }}>
                <Box style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #F38B44', width: 'calc(100% - 40px)', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center', padding: '20px' }}>
                    <Typography style={{ fontSize: '14px', fontFamily: 'Manrope-Bold', color: '#172A3A' }} >Deduct from Credit</Typography>
                    <CloseButtonXActive style={{ cursor: 'pointer' }} onClick={this.closeDeductCreditDrawer} />
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center', padding: '20px' }}>
                    <Typography style={{ fontSize: '14px', fontFamily: 'Manrope-Bold', color: '#172A3A' }} >Credit Balance</Typography>
                    <Typography style={{ fontSize: '14px', fontFamily: 'Manrope-Bold', color: '#172A3A' }} >{`₹ ${this.props.familyDetails.creditDetail ? this.props.familyDetails.creditDetail.amount : 0}`}</Typography>
                </Box>
                <Typography style={{ fontSize: '14px', fontFamily: 'Manrope-Bold', color: '#172A3A', margin: '20px' }} >Enter the amount to be deducted</Typography>
                <TextField
                    id="outlined-amount-to-be-deducted"
                    style={{ fontSize: '14px', fontFamily: 'Manrope-Bold', color: '#172A3A', margin: '5px 30px 10px' }}
                    value={this.state.amountToBeDeducted}
                    onChange={this.handleChange('amountToBeDeducted')}
                    margin="normal"
                    onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, ''); }}
                    variant="outlined"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                    }}
                />

                <Typography style={{ fontSize: '13px', fontFamily: 'Manrope-Bold', color: '#908F8F', margin: ' 20px 25px 10px' }} >Reason for deduction</Typography>
                <FormControl variant="outlined" style={{
                    minWidth: 120,
                    margin: '5px 30px 10px',
                }}>
                    <Select
                        native
                        value={this.state.creditDeductionReason}
                        onChange={this.handleChange('creditDeductionReason')}
                        input={
                            <Input
                                name="creditDeductionReason"
                                id="outlined-age-native-simple"
                            />
                        }
                    >
                        {/* <option value={creditDeductionReason.RENEWAL_OF_PLAN}>Renewal of Plan</option> */}
                        <option value={creditDeductionReason.MEDICINES}>Medicines / Lab test</option>
                        <option value={creditDeductionReason.CONSULTATION}>Consultation </option>
                    </Select>
                </FormControl>

                <Typography style={{ fontSize: '14px', fontFamily: 'Manrope-Bold', color: '#172A3A', margin: '20px' }} >{this.getThirdTextFieldHeading()}</Typography>
                <TextField
                    id="outlined-subscription-id"
                    style={{ fontSize: '14px', fontFamily: 'Manrope-Bold', color: '#172A3A', margin: '5px 30px 10px' }}
                    value={this.state.externalId}
                    onChange={this.handleChange('externalId')}
                    margin="normal"
                    variant="outlined"
                />
                <FabButton disabled={this.deductButtonState()} show={true} title={"Deduct Now"} buttonHintText={this.getButtonHintText()} errorMessageAboveButton onClick={this.deductCredit} />

            </Box>
        )
    }

    renderCircularProgress = () => {
        return (
            <CircularProgress style={{
                color: 'orange',
                left: '50%',
                top: '50%',
                position: 'absolute',

            }} disableShrink />
        )
    }

    renderRow = (name, value) => {
        if (this.props.familyDetails && this.props.familyDetails.nachDetail && this.props.familyDetails.nachDetail.status !== 'active' && this.props.familyDetails.nachDetail.status !== 'failed' && name === 'Status') {
            return <div style={{ color: 'orange', fontStyle: 'italic' }}>{value} </div>
        } else
            if (this.props.familyDetails && this.props.familyDetails.nachDetail && this.props.familyDetails.nachDetail.status === 'active' && name === 'Status') {
                return <div style={{ color: 'green', fontStyle: 'italic' }}>{value} </div>
            } else if (this.props.familyDetails.nachDetail.status === 'failed' && name === 'Status') {
                return <div style={{ color: 'red', fontStyle: 'italic' }}>{value} </div>
            } else {
                return value;
            }
    }

    render() {
        let rows = [
            createData('Amount', this.props.familyDetails.creditDetail ? this.props.familyDetails.creditDetail.amount : 0),
        ];

        if (!this.props.familyDetails.creditDetail) {
            return <div> <CircularProgress style={{ color: 'orange', marginRight: '10vh', marginTop: '20vh' }} disableShrink /> </div>;
        } else {
            if (this.props.familyDetails.error && this.props.familyDetails.error.creditErrorCode) {
                return <div> Error while fetching Credit Details.</div>
            } else {
                return (
                    <>
                        <Table style={{
                            minWidth: 100,
                            borderTop: 'none',
                            borderBottom: 'none !important'
                        }} aria-label="simple table">
                            <TableBody style={{ overflowX: 'scroll' }}>
                                {rows.map(row => {
                                    return (
                                        <TableRow key={row.name}>
                                            <TableCell style={{ fontWeight: 'bold', border: 'none', color: 'black' }} component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="left" style={{ border: 'none' }}> {this.renderRow(row.name, row.value)}</TableCell>
                                        </TableRow>
                                    );
                                }
                                )}
                            </TableBody>
                        </Table>
                    </>
                );
            };
        };
    };
};

const mapDispatchToProps = (dispatch) => ({
    getCreditDetails: (familyId) => { dispatch(getCreditDetailsByFamilyId(familyId)); },
    createOfflinePaymentEvent: (externalId, itemType, itemPrice, familyId) => { dispatch(createOfflinePaymentEvent(externalId, itemType, itemPrice, familyId)); },
    resetOfflinePayment: () => { dispatch(resetOfflinePaymentEvent()); },
});

const mapStateToProps = (state) => {
    return { auth: state.auth, familyDetails: state.familyDetails, error: state.error, payment: state.payment };
};


export default connect(mapStateToProps, mapDispatchToProps)(MiddleTab);
