import { Button, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit.svg';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import useStyles from './ConsultationFromStyle';


function SelectCreatedDatePickerButton({ handleCreatedDateOnChange, selectedDate, doc, ...props }) {
	const { classes } = props;
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    function handleDateOnChange(date) {
        const timestamp = moment(date).startOf('day').valueOf();
        handleCreatedDateOnChange(timestamp);
    }
	return (
		<>
			<Button
				className={classes.selectDocumentTypeBtn}
				variant='outlined'
				color='primary'
				id={`select-doc-type-${doc.id}`}
				disabled={doc.isUploading || (!doc.isUploading && doc.status === 'success')}
                onClick={() => setDatePickerOpen(true)}
			>
				<EditIcon className={classes.editIcon} />
			</Button>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<DatePicker
                    defaultValue={moment().valueOf()}
					value={selectedDate}
					onChange={handleDateOnChange}
                    open={datePickerOpen}
                    onClose={() => setDatePickerOpen(false)}
                    style={{
                        display: 'none'
                    }}
                    disableFuture
				/>
			</MuiPickersUtilsProvider>
		</>
	);
}

const styleWrapper = withStyles(useStyles, { withTheme: true });
export default styleWrapper(SelectCreatedDatePickerButton);
