import { color } from "../../../constants/colors";

const useStyles = (theme) => ({
    container: {
        width: '400px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        height: '100%',
        background: color.WHITE,
        fontFamily: 'Rubik',
        fontWeight: 'normal',
        overflow: 'hidden',
    },
    header: {
        padding: '2vh',
        fontWeight: 'bold',
        color: color.DARK_GREY,
        width: 'calc(100% - 4vh)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        borderBottom: `1px solid ${color.ORANGE}`,
    },
    close: {
        fontSize: '18px',
        fontWeight: 'bold',
        cursor: 'pointer',
    },
    subHeader: {
        width: 'calc(100% - 4vh)',
        display: 'flex',
        flexDirection: 'column',
        padding: '2vh',
    },
    explainer: {
        color: color.GREY,
        fontSize: '13px',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: '2vh',
        width: 'calc(100% - 4vh)',
        justifyContent: 'space-between',
        height: '80vh',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '16px',
        marginTop: '2vh',
        width: '100%',
    },
    input: {
        height: '30px',
        fontSize: '16px',
        padding: '1vh',
        border: `1px solid ${color.LIGHT_GREY}`,
        borderRadius: '4px',
        fontFamily: 'Rubik',
        fontWeight: 'normal',
    },
    input_correct: {
        height: '30px',
        fontSize: '16px',
        padding: '1vh',
        background: color.greens[200],
        border: `1px solid ${color.greens[100]}`,
        borderRadius: '4px',
        fontFamily: 'Rubik',
        fontWeight: 'normal',
        textAlign: 'right',
    },
    input_wrong: {
        height: '30px',
        fontSize: '16px',
        padding: '1vh',
        background: color.reds[200],
        border: `1px solid ${color.reds[100]}`,
        borderRadius: '4px',
        fontFamily: 'Rubik',
        fontWeight: 'normal',
    },
    generateButton: {
        height: '48px',
        width: '100%',
        color: color.WHITE,
        background: color.ROYAL_ORANGE,
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        fontSize: '13px',
        textTransform: 'capitalize',
        '&:disabled': {
            background: color.GREY,
        },
        '&:hover': {
            background: color.ROYAL_ORANGE,
        }
    },
    walletManager: {
        padding: '1.5vh',
        margin: '2vh 0px 0px 0px',
        border: `1px solid ${color.ROYAL_ORANGE}`,
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 'calc(100% - 3vh)',
    },
    cardRow: {
        minWidth: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontFamily: 'Rubik',
        justifyContent: 'space-between',
        margin: '0.2vh 0px',
        maxWidth: '100%',
    },
    headerRow: {
        display: 'flex',
        justifyContent: 'flex-start'
    },
    logo: {
        marginRight: '4px',
        display: 'flex',
        direction: 'row',
        alignItems: 'center',
    },
    walletHeader: {
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        color: color.ROYAL_ORANGE,
        width: '90%',
    },
    openCareWalletManagerBtn: {
        height: '40px',
        width: '40px',
        borderRadius: '4px',
        background: color.ROYAL_ORANGE,
        outline: 'none',
        border: `1px solid ${color.ROYAL_ORANGE}`,
        transition: 'all 0.5s ease-in',
        '&:hover': {
            background: color.ORANGE,
            transition: 'all 0.5s ease-out'
        }
    },
    arrow: {
        margin: 0,
        width: '15px',
        height: '10px',
    },
    transactionIdInput: {
        padding: '0.5vh 0px',
        fontFamily: 'Rubik',
        fontSize: '1rem',
        color: color.BLACK,
        textAlign: 'left',
        marginTop: '2vh',
        border: '0px',
        borderBottom: `1px solid ${color.greys[100]}`,
        width: '100%',
        '&:focus': {
            borderBottom: `1px solid ${color.BLACK}`,
            outline: 'none',
        }
    }
});

export default useStyles;