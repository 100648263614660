import React from 'react';
import Svg from '../../components/common/Svg/Svg';
import Path from '../../components/common/Svg/Path';

const Minus = ({ color = 'black' }) => {
    return (
        <Svg width="100%" height="100%" viewBox="0 0 7 3" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Path d="M0.687 2.852C0.569667 2.852 0.473667 2.81467 0.399 2.74C0.324333 2.66533 0.287 2.56933 0.287 2.452V0.948C0.287 0.830667 0.324333 0.734667 0.399 0.66C0.473667 0.585333 0.569667 0.548 0.687 0.548H5.695C5.81233 0.548 5.90833 0.585333 5.983 0.66C6.05767 0.734667 6.095 0.830667 6.095 0.948V2.452C6.095 2.56933 6.05767 2.66533 5.983 2.74C5.90833 2.81467 5.81233 2.852 5.695 2.852H0.687Z" fill={color}/>
        </Svg>
    )
};

export default Minus;

