import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import { ReactComponent as EditPencil } from '../../../images/EditPencil.svg';
import style from './style';
function EditButton (props) {
    const {
        classes, title, color, handleEdit,
    } = props;
    return (
        <>
        <Button onClick={() => handleEdit()} className={`${classes[color]} ${classes.editButton}`} variant="outlined">
          <EditPencil className={classes.pencil} />
            {title}
        </Button>
        </>
    );
}

const ConnectedEditButton = withStyles(style,
    { withTheme: true })(EditButton);

export default ConnectedEditButton;