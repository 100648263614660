import { color } from "../../constants/colors";

const useStyles = (theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: 'calc(100%)',
        padding: '2vh 0vh',
        fontFamily: 'Rubik',
    },
    row: {
        marginTop: '1vh',
        borderRadius: '5px',
        height: '80%',
        '&:first-child': {
            height: '20%',
        }
    },
    filled: {
        background: color.WHITE,
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '55vh',
        padding: '2vh',
        background: color.WHITE,
        borderRadius: '5px',
        overflow: 'scroll',
    },
    consultationRow: {
        border: `1px solid ${color.BORDER_GREY}`,
        width: 'calc(100% - 2vh)',
        // minHeight: 'calc(72px - 4vh)',
        fontFamily: 'Rubik',
        borderRadius: '10px',
        padding: '2vh 0',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        boxShadow: `0px 0px 4px 0px ${color.greys[400]}`,
        alignItems: 'center',
        '&:not(:first-child)': {
            marginTop: '2vh',
        },
        '&:hover': {
            border: `1px solid ${color.reds[300]}`
        },
    },
    marker: {
        width: '8px',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'calc(100% - 8px)',
        minHeight: 'calc(72px - 4vh)',
        '& div': {
            width: '50%',
        }
    },
    patientDetails: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 2vh',
    }
});

export default useStyles;