import React from 'react';
import Svg from '../../components/common/Svg/Svg';
import Path from '../../components/common/Svg/Path';
import { color as colors } from '../../constants/colors';

function DocumentIcon({ color = colors.BLACK, ...props }) {
	return (
		<Svg
			width='14'
			height='16'
			viewBox='0 0 14 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<Path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13.3874 2.66878L11.1979 0.405619C10.9141 0.179834 10.5605 0.0599825 10.1979 0.0666718H1.42741C0.671622 0.0666718 0.0568848 0.550882 0.0568848 1.14667V14.8309C0.0568848 15.4267 0.671622 15.913 1.42741 15.913H12.3916C13.1474 15.913 13.7621 15.4267 13.7621 14.8309V3.40983C13.7546 3.11915 13.617 2.84718 13.3874 2.66878ZM10.5264 1.22878L12.5853 3.35299H10.5411C10.5307 3.34155 10.5253 3.32634 10.5264 3.31088V1.22878ZM12.3916 14.854C12.5048 14.8586 12.6165 14.8261 12.7095 14.7614V4.40562H10.5264C9.935 4.3941 9.46434 3.90649 9.47373 3.31509V1.1172H1.42741C1.31444 1.11215 1.20288 1.14392 1.10952 1.20772V14.7614C1.20256 14.8261 1.31418 14.8586 1.42741 14.854H12.3916Z'
				fill={color}
			/>
			<Path
				d='M7.86732 11.2733H3.19785C2.95082 11.2189 2.7749 11 2.7749 10.747C2.7749 10.4941 2.95082 10.2751 3.19785 10.2207H7.86732C8.11435 10.2751 8.29027 10.4941 8.29027 10.747C8.29027 11 8.11435 11.2189 7.86732 11.2733V11.2733Z'
				fill={color}
			/>
			<Path
				d='M9.01056 9.29287H3.19793C2.93847 9.25993 2.75205 9.02691 2.77688 8.76655C2.75205 8.50619 2.93847 8.27317 3.19793 8.24023H9.01056C9.27002 8.27317 9.45644 8.50619 9.43161 8.76655C9.45644 9.02691 9.27002 9.25993 9.01056 9.29287V9.29287Z'
				fill={color}
			/>
			<Path
				d='M9.01056 7.31044H3.19793C2.93847 7.27751 2.75205 7.04449 2.77688 6.78413C2.75205 6.52377 2.93847 6.29075 3.19793 6.25781H9.01056C9.27002 6.29075 9.45644 6.52377 9.43161 6.78413C9.45644 7.04449 9.27002 7.27751 9.01056 7.31044V7.31044Z'
				fill={color}
			/>
		</Svg>
	);
}

export default DocumentIcon;
