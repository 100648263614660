import * as Sentry from '@sentry/react';
import { leadService } from "../clients/leadService";
import { leadsActionTypes, errorActionTypes } from '../constants';
import { buildCustomError } from "../common/utils";

export const createLeadEvent = (leadObject) => {
    const request = () => ({ type: leadsActionTypes.CREATE_LEAD_REQUEST });
    const success = (payload) => ({ type: leadsActionTypes.CREATE_LEAD_SUCCESS, payload });
    const failure = () => ({ type: leadsActionTypes.CREATE_LEAD_FAILURE });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
    return async (dispatch, getState) => {
        const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
        dispatch(request());
        try {
            const response = await leadService.createLead(ACCESS_TOKEN, leadObject);
            dispatch(success(response.data));
        } catch (e) {
            const error = buildCustomError(e);
            Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`),
              { extra: { errorData: error.data, function: 'createLeadEvent', requestPayload: leadObject } });
            dispatch(failure());
            dispatch(setError(error));
        }
    }
};