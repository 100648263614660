import React from 'react';
import { connect } from 'react-redux';

import orange from '@material-ui/core/colors/orange';
import { withStyles } from "@material-ui/core/styles/";
import SideMenuBar from '../components/Home/sideMenuBar/SideMenuBar';
import TopBar from '../components/Home/topBar/TopBar';

const styles = theme => ({
    palette: {
        primary: orange,
    },
    indicator: {
        backgroundColor: "orange"
    },
    default_tabStyle: {
        color: 'grey',
    },
    active_tabStyle: {
        fontWeight: 'bold',
        color: 'black',
    }
});

function CustomerLensPage(props) {
    return (
        <div className='main' style={{
            height: "100vh",
            minWidth: '80vw',
            backgroundColor: '#f4f4f4',
        }}>
            <TopBar />
            <div className='main' style={{
                height: "100vh",
                width: '100vw',
                backgroundColor: '#f4f4f4',
                display: 'flex'
            }}>

                <SideMenuBar />
                <div style={{
                    flexGrow: 1,
                    height: '100vh',
                    margin: 0,
                    padding: 0,
                    overflow: 'hidden'
                }}>
                    <iframe
                        src={'https://appsmith.clinikk.com/app/customer-lens/interactions-674a0645868e2a6488f1e21e?branch=master'}
                        title="Customer Lens"
                        style={{
                            width: '100%',
                            height: '100%',
                            border: 'none'
                        }}
                    >
                    </iframe>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error };
};

export default connect(mapStateToProps)(withStyles(styles)(CustomerLensPage));