const styles = () => ({
    confirmationContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    headerContainer: {
        display: 'flex',
        padding: '24px 16px',
        justifyContent: 'space-between',
        borderBottom: '1px solid #F38B44'
    },
    preferenceCardHeading: {
        fontSize: '18px',
        fontWeight: '500'
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%'
    },
    textContainer: {
        padding: '0 36px',
        textAlign: 'center',
    },
    ghdQuestionsContainer: {
        padding: '0 36px',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: '40px'
    },
    confirmationTitle: {
        fontWeight: '500',
    },
    confirmationSubTitle: {
        color: '#808080',
        fontSize: '14px',
        marginTop: '27px',
        marginLeft: '-16px',
    },
    circle: {
        position: 'relative',
        width: '50px',
        left: '42%',
        height: '50px',
        backgroundColor: '#F38B44',
        borderRadius: '50%',
    },
    checkIcon: {
        color: '#FFFFFF',
        position: 'absolute',
        top: '25%',
        right: '25%',
    }
});
export default styles;
