import { color } from "../../../../constants/colors";

const useStyles = (() => ({
    consultationContainer: {
        borderWidth: '1px',
        margin: '0.5vw',
        padding: '0.5vw',
        width: 'calc(100% - 2vw)',
        borderStyle: 'solid',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '8vh',
        height: 'max-content',
        boxShadow: '0px 0px 4px #CBCBCB',   
    },
    physicalConsultation: {
        borderColor: color.LIGHT_GREY,
    },
    consultationBody: {
        display: 'flex',
        flexDirection: 'row',
        width: '90%',
        alignItems: 'center',
        height: '100%',
        padding: '0.5vh',
    },
    consultationInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '33.33%',
    },
    consultationBodyText: {
        color: color.BLACK,
        fontFamily: 'Rubik',
        fontSize: '12px',
        overflow: 'auto',
        overflowWrap: 'break-word',
    },
    consultationBodyTextHeading: {
        color: color.LIGHT_GREY_TEXT,
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontWeight: 'bold',
        marginBottom: '5px',
    },
    consultationSummary: {
        width: '60%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    consultationContext: {
        width: '10%',
        color: color.greys[200],
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    link: {
        color: color.ROYAL_ORANGE,
        textDecoration: 'none',
        fontSize: '12px',
        '&:visited': {
            color: color.ROYAL_ORANGE,
            textDecoration: 'none',
        }
    }
}));

export default useStyles;