import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import 'date-fns';
import { orange } from "@material-ui/core/colors";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { ThemeProvider } from "@material-ui/styles";

import { createMuiTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { TextField, Typography, Divider, Drawer, Paper, ButtonGroup, Button, IconButton, CircularProgress, MenuItem, Tooltip } from "@material-ui/core";
import { ReactComponent as AdultMale } from '../../../images/Avatars/AdultMale.svg';
import { ReactComponent as AdultMaleSelected } from '../../../images/Avatars/AdultMaleSelected.svg';
import { ReactComponent as AdultFemale } from '../../../images/Avatars/AdultFemale.svg';
import { ReactComponent as AdultFemaleSelected } from '../../../images/Avatars/AdultFemaleSelected.svg';
import { ReactComponent as ChildMale } from '../../../images/Avatars/ChildMale.svg';
import { ReactComponent as ChildMaleSelected } from '../../../images/Avatars/ChildMaleSelected.svg';
import { ReactComponent as ChildFemale } from '../../../images/Avatars/ChildFemale.svg';
import { ReactComponent as ChildFemaleSelected } from '../../../images/Avatars/ChildFemaleSelected.svg';
import { ReactComponent as AddNewMember } from '../../../images/Avatars/AddNewMember.svg';
import { ReactComponent as CloseButton } from '../../../images/CloseButton.svg';
import { insuranceMemberRelationshipList, fullNameRegex } from '../../../common/constants';
import { getAllMembersByFamilyId, resetGetAllMembersByFamilyId, resetNewMemberAdded, addNewMemberByFamilyId } from '../../../actions';
const { CREATED } = require('http-status-codes');

const materialTheme = createMuiTheme({
    palette: {
        primary: orange,
    },
});

function disableNextDates(startDate) {
    const startSeconds = Date.parse(startDate);
    return (date) => {
        return Date.parse(date) > startSeconds;
    }
}

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    element: {
        padding: theme.spacing(0.5),
        // marginBottom: '2.5vh',
        // boxShadow: 'none',
        // textAlign: 'center',
        // color: theme.palette.text.secondary,
    },
    textField: {
        color: 'orange',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: '10vh',
    },
    avatarButton: {
        height: '12vh',
        width: '6vw',
    },
    avatar: {
        height: '46px',
        width: '46px',
    },
    relationButton: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }

});

const GENDER = {
    MALE: 'male',
    FEMALE: 'female',
    OTHER: 'other',
}

const motherTongue = [
    {
        value: 'hindi',
        label: 'Hindi'
    },
    {
        value: 'english',
        label: 'English',
    },
    {
        value: 'kannada',
        label: 'Kannada',
    },
    {
        value: 'tamil',
        label: 'Tamil',
    },
    {
        value: 'telugu',
        label: 'Telugu',
    },
    {
        value: 'malayalam',
        label: 'Malayalam',
    },
    {
        value: 'bangla',
        label: 'Bangla',
    },
    {
        value: 'gujarati',
        label: 'Gujarati',
    },
    {
        value: 'marathi',
        label: 'Marathi',
    },
    {
        value: 'punjabi',
        label: 'Punjabi',
    },
    {
        value: 'odia',
        label: 'Odia',
    },
];


const relationships = [ // change relationships
    // {
    //     value: 'self',
    //     label: 'Self',
    // },
    {
        value: 'spouse',
        label: 'Spouse',
    },
    {
        value: 'father',
        label: 'Father'
    },
    {
        value: 'mother',
        label: 'Mother',
    },
    {
        value: 'son',
        label: 'Son',
    },
    {
        value: 'daughter',
        label: 'Daughter',
    },
    {
        value: 'brother',
        label: 'Brother',
    },
    {
        value: 'sister',
        label: 'Sister',
    },
    {
        value: 'friend',
        label: 'Friend',
    },
    {
        value: 'other',
        label: 'Other',
    },

]

// const dummyFamilyId = '300524';

class SelectNominee extends React.Component {

    state = { selectedNomineeCustomerId: this.props.selectedNomineeCustomerId, addMemberDrawer: false, nomineeRelationshipWithPrimary: this.props.nomineeRelationshipWithPrimary, newMemberDob: new Date(), newMemberName: '', newMemberGender: '', newMemberLop: '', newMemberRelationship: '', callAddMemberClicked: false }


    componentDidMount() {
        this.props.resetNewMemberAdded();
        this.props.resetGetAllMembersByFamilyId();
        this.callGetAllMembersByFamilyId();
    }

    callGetAllMembersByFamilyId = () => {
        if (this.props.newPlan && this.props.newPlan.customerByPhoneNumber && this.props.newPlan.customerByPhoneNumber.family && this.props.newPlan.customerByPhoneNumber.family.id) {
            this.props.getAllMembersByFamilyId(this.props.newPlan.customerByPhoneNumber.family.id)
        }
        if (this.props.newCustomer && this.props.newCustomer.createHeadSubscriber && this.props.newCustomer.createHeadSubscriber.newFamily && this.props.newCustomer.createHeadSubscriber.newFamily.data && this.props.newCustomer.createHeadSubscriber.newFamily.data.family && this.props.newCustomer.createHeadSubscriber.newFamily.data.family.id) {
            this.props.getAllMembersByFamilyId(this.props.newCustomer.createHeadSubscriber.newFamily.data.family.id);
        }
        //  else {
        //     this.props.getAllMembersByFamilyId(dummyFamilyId)    ////remove this
        // }
    }
    handleDateChange = date => {
        this.setState({ newMemberDob: date });
    };

    getCircularProgress = () => {
        return <CircularProgress style={{
            color: 'orange',
            left: '50%',
            top: '55%',
            position: 'absolute',
        }} disableShrink />;
    }

    openAddMemberDrawer = () => {
        this.setState({ addMemberDrawer: true });
    }

    closeAddMemberDrawer = () => {
        this.setState({ addMemberDrawer: false, callAddMemberClicked: false, newMemberName: '', newMemberDob: new Date(), newMemberGender: '', newMemberLop: '', newMemberRelationship: '' });
        this.callGetAllMembersByFamilyId();
        this.props.resetNewMemberAdded();
    }


    handleChange = name => event => {
        const userValue = event.target.value;
        if (fullNameRegex.test(userValue) || userValue === '') {
            this.setState({ [name]: userValue });
        }
    };

    callAddMember = () => {
        this.setState({ callAddMemberClicked: true })
        const newMemberObject = {
            first_name: this.state.newMemberName,
            dob: this.state.newMemberDob.getTime(),
            gender: this.state.newMemberGender,
            lop: this.state.newMemberLop,
            relationship: this.state.newMemberRelationship,
        };
        if (this.props.newPlan && this.props.newPlan.customerByPhoneNumber && this.props.newPlan.customerByPhoneNumber.family && this.props.newPlan.customerByPhoneNumber.family.id) {
            this.props.addNewMemberByFamilyId(newMemberObject, this.props.newPlan.customerByPhoneNumber.family.id);
        } else if (this.props.newCustomer && this.props.newCustomer.createHeadSubscriber && this.props.newCustomer.createHeadSubscriber.newFamily && this.props.newCustomer.createHeadSubscriber.newFamily.data && this.props.newCustomer.createHeadSubscriber.newFamily.data.family && this.props.newCustomer.createHeadSubscriber.newFamily.data.family.id) {
            this.props.addNewMemberByFamilyId(newMemberObject, this.props.newCustomer.createHeadSubscriber.newFamily.data.family.id);
        }
        // else {
        //     this.props.addNewMemberByFamilyId(newMemberObject, dummyFamilyId)
        // }
    }



    renderAddMemberDrawerContent = () => {
        const { classes } = this.props;
        if (this.props.familyDetails && _.isNil(this.props.familyDetails.newMember) && this.state.callAddMemberClicked) {
            return this.getCircularProgress();
        } else if (this.props.familyDetails && this.props.familyDetails.newMember && this.props.familyDetails.newMember.status === CREATED) {
            return this.closeAddMemberDrawer();
        }
        return (
            <div style={{ position: 'absolute' }}>

                <div>                <Grid container spacing={0}>
                    <Grid item xs={6}>
                        <Typography style={{ fontSize: '1.1rem', color: '#172a3a', fontFamily: 'Rubik-Medium', marginTop: '3vh', marginLeft: '2vw', float: 'left' }}>New Member</Typography>
                    </Grid>
                    <Grid item xs={6} >
                        <IconButton style={{ float: 'right', marginRight: '2vw', marginTop: '2.3vh' }} onClick={this.closeAddMemberDrawer}><CloseButton /></IconButton>
                    </Grid>
                    <Grid item xs={12} >
                        <Divider style={{ marginTop: '2vh' }} />
                    </Grid>
                    <Grid item xs={12}  >
                        <TextField
                            style={{ marginLeft: '3vw', marginTop: '4vh', marginRight: '3vw', width: '80%' }}
                            id="full-name"
                            label="Full Name*"
                            className={classes.textField}
                            value={this.state.newMemberName}
                            onChange={this.handleChange('newMemberName')}
                            margin="normal"
                            InputLabelProps={{
                                style: {
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    width: '100%',
                                    color: '#9d9d9d',
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}  >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={materialTheme}>
                                <KeyboardDatePicker
                                    // onMouseLeave={() => { this.setState({ insideDobField: false, insideFullNameField: false }) }}
                                    // onMouseOver={() => { this.setState({ insideDobField: true, insideFullNameField: false }) }}   //, insideFirstNameField: false, insideMTField: false 
                                    // error={!this.state.dob && !this.state.insideDobField}
                                    // helperText={!this.state.dob && !this.state.insideDobField ? 'This field is mandatory.' : " Ex: 16-12-1950"}
                                    style={{ marginLeft: '3vw', width: '80%', marginTop: '4vh' }}
                                    id="dob"
                                    label="Date of Birth*"
                                    className={classes.textField}
                                    value={this.state.newMemberDob || ''}
                                    // defaultValue = {this.props.dob}
                                    onChange={this.handleDateChange}
                                    margin="normal"
                                    InputLabelProps={{
                                        style: {
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            width: '100%',
                                            color: '#9d9d9d',
                                        }
                                    }}
                                    format="dd-MM-yyyy"
                                    shouldDisableDate={disableNextDates(new Date())}
                                />
                            </ThemeProvider>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            style={{ marginLeft: '3vw', marginTop: '4vh', width: '80%' }}
                            select
                            id="lop"
                            label="Preferred Language"
                            className={classes.textField}
                            value={this.state.newMemberLop}
                            // defaultValue = {this.props.lop}
                            onChange={this.handleChange('newMemberLop')}
                            margin="normal"
                            InputLabelProps={{
                                style: {
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    width: '100%',
                                    color: '#9d9d9d'
                                }
                            }}>
                            {motherTongue.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            style={{ width: '80%', marginTop: '4vh', marginLeft: '3vw' }}
                            select
                            label="Relationship to H.S."
                            value={this.state.newMemberRelationship}
                            onChange={this.handleChange('newMemberRelationship')}
                            margin="normal"
                        >
                            {relationships.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.element} style={{ fontSize: '0.9rem', color: '#908f8f', fontFamily: 'Rubik-Regular', marginTop: '3vh', marginBottom: '1vh', marginLeft: '2.5vw' }}>Gender*</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonGroup variant="contained" aria-label="contained primary button group" style={{ fontFamily: 'Rubik-Regular', marginLeft: '3vw' }} >
                            <Button onClick={() => this.setState({ newMemberGender: GENDER.MALE })} style={{ backgroundColor: this.state.newMemberGender === GENDER.MALE ? '#f38b44' : '#e5e5e5', color: this.state.newMemberGender === GENDER.MALE ? 'white' : '#908f8f', textTransform: 'capitalize', width: '7.5vw' }} >Male</Button>
                            <Button onClick={() => this.setState({ newMemberGender: GENDER.FEMALE })} style={{ backgroundColor: this.state.newMemberGender === GENDER.FEMALE ? '#f38b44' : '#e5e5e5', color: this.state.newMemberGender === GENDER.FEMALE ? 'white' : '#908f8f', textTransform: 'capitalize', width: '7.5vw' }} >Female</Button>
                            <Button onClick={() => this.setState({ newMemberGender: GENDER.OTHER })} style={{ backgroundColor: this.state.newMemberGender === GENDER.OTHER ? '#f38b44' : '#e5e5e5', color: this.state.newMemberGender === GENDER.OTHER ? 'white' : '#908f8f', textTransform: 'capitalize', width: '7.5vw' }} >Other</Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={this.callAddMember} disabled={!this.state.newMemberName || !this.state.newMemberDob || !this.state.newMemberGender || !this.state.newMemberLop || !this.state.newMemberRelationship} style={{ backgroundColor: !this.state.newMemberName || !this.state.newMemberDob || !this.state.newMemberGender || !this.state.newMemberLop || !this.state.newMemberRelationship ? '#e5e5e5' : '#f38b44', color: !this.state.newMemberName || !this.state.newMemberDob || !this.state.newMemberGender || !this.state.newMemberLop || !this.state.newMemberRelationship ? '#908f8f' : 'white', textTransform: 'capitalize', marginLeft: '3vw', marginTop: '27vh', marginRight: '3vw', width: '80%', height: '7vh', marginBottom: '2vh' }} >
                            <Typography style={{ textAlign: 'center' }}>Add Member</Typography>
                        </Button>
                    </Grid>
                </Grid>
                </div>
                {/* <div style={{
                            position: 'fixed',
                            bottom: '0px',
                            right: '0px', width: '50vh'
                        }}>
                            <Button>ppip</Button>
                        </div> */}

                {/* <div style={{ position: 'fixed', bottom: '2vh', marginLeft: '1.5vw', right: '1.5vw'}}> */}

                {/* </div> */}
            </div>

        )
    };


    renderCustomAvatar = (dob, gender, memberId) => {
        const { classes } = this.props;
        const age = moment().diff(dob, 'years');
        if (gender === 'female') {
            if (age < 18) {
                return memberId !== this.state.selectedNomineeCustomerId ? <ChildFemale className={classes.avatar} /> : <ChildFemaleSelected className={classes.avatar} />
            } else {
                return memberId !== this.state.selectedNomineeCustomerId ? <AdultFemale className={classes.avatar} /> : <AdultFemaleSelected className={classes.avatar} />
            }
        } else {
            if (age < 18) {
                return memberId !== this.state.selectedNomineeCustomerId ? <ChildMale className={classes.avatar} /> : <ChildMaleSelected className={classes.avatar} />
            } else {
                return memberId !== this.state.selectedNomineeCustomerId ? <AdultMale className={classes.avatar} /> : <AdultMaleSelected className={classes.avatar} />
            }

        }

    }

    selectNomineeMember = (memberId) => {
        this.setState({ selectedNomineeCustomerId: memberId });
        setTimeout(function () {
            this.props.setParentState('selectedNomineeCustomerId', this.state.selectedNomineeCustomerId);   //This is to make it work in synchronous manner (one after other) (sending the props to parent after state is set)
        }.bind(this), 200);
    }

    setNomineeRelationShipWithPrimary = (relationship) => {
        this.setState({ nomineeRelationshipWithPrimary: relationship });
        setTimeout(function () {
            this.props.setParentState('nomineeRelationshipWithPrimary', this.state.nomineeRelationshipWithPrimary);   //This is to make it work in synchronous manner (one after other) (sending the props to parent after state is set)
        }.bind(this), 200);

    }

    getNomineeName = (nomineeCustomerId) => {
        const { newPlan } = this.props;
        return newPlan.allMembersOfCurrentFamily && newPlan.allMembersOfCurrentFamily.members && !_.isEmpty(newPlan.allMembersOfCurrentFamily.members) ? _.find(newPlan.allMembersOfCurrentFamily.members, { id: nomineeCustomerId }).first_name : '';
    }

    getTooltipText = (member) => {
        const memberAge = moment().diff(member.dob, 'years');
        if (memberAge < 18) {
            return 'The age should be atleast 18 years'
        }
        if (member.id === this.props.selectedPrimaryCustomerId) {
            return 'Primary member cannot be selected as Nominee'
        }
        return ''
    }

    renderAllMembers = () => {
        const { classes, newPlan } = this.props;
        const primaryMemberName = newPlan.allMembersOfCurrentFamily && newPlan.allMembersOfCurrentFamily.members && !_.isEmpty(newPlan.allMembersOfCurrentFamily.members) ? _.find(newPlan.allMembersOfCurrentFamily.members, { id: this.props.selectedPrimaryCustomerId }).first_name : '';
        return (
            <div>
                <div>
                    <div className={classes.root}>
                        <Typography style={{ fontSize: '1.2rem', color: '#172a3a', fontFamily: 'Rubik-Regular', marginTop: '5vh', marginBottom: '0.5vh', marginLeft: '5vw' }}>Select the Nominee:</Typography>
                        <div >
                            <Grid container spacing={3} style={{ marginLeft: '5vw', width: '65vw' }}>
                                {this.props.newPlan.allMembersOfCurrentFamily.members.map((member) => {
                                    const memberAge = moment().diff(member.dob, 'years');
                                    return (
                                        <Tooltip title={this.getTooltipText(member)} key={member.id}>
                                            <Grid item xs={"auto"}>
                                                <IconButton className={classes.avatarButton}
                                                    style={{ opacity: (member.id === this.props.selectedPrimaryCustomerId || (memberAge < 18)) ? '0.3' : '1' }}
                                                    disabled={(member.id === this.props.selectedPrimaryCustomerId) || (memberAge < 18)} onClick={() => this.selectNomineeMember(member.id)}> {this.renderCustomAvatar(member.dob, member.gender, member.id)}
                                                </IconButton>
                                                <div style={{ textAlign: 'center' }}>
                                                    <Typography style={{ fontSize: '0.7rem', marginTop: '-2vh' }}>{member.first_name}</Typography>
                                                </div>
                                            </Grid>
                                        </Tooltip>
                                    )
                                })}
                                {this.props.newPlan && this.props.newPlan.allMembersOfCurrentFamily && this.props.newPlan.allMembersOfCurrentFamily.total < 6 ? <Grid item xs={1}  >
                                    <IconButton className={classes.avatarButton} onClick={this.openAddMemberDrawer}> <AddNewMember className={classes.avatar} /></IconButton>
                                    <div style={{ textAlign: 'center' }}>
                                        <Typography style={{ fontSize: '0.7rem', marginTop: '-2vh', marginLeft: '0.55vw', width: '5vw' }}>Add New</Typography>
                                    </div>
                                </Grid> : <></>}

                                {this.state.selectedNomineeCustomerId ?
                                    <>
                                        <Grid item xs={12}>
                                            <Typography style={{ fontSize: '1.1rem', color: '#172a3a', fontFamily: 'Rubik-Regular', marginTop: '2vh', marginBottom: '0.5vh' }}> {'How is ' + this.getNomineeName(this.state.selectedNomineeCustomerId) + ' related to ' + primaryMemberName + '?'} </Typography>
                                        </Grid><Grid item xs={6} style={{ marginTop: '2vh', marginBottom: '3vh' }}>
                                            <Grid item xs={12}>
                                                {
                                                    insuranceMemberRelationshipList.map((relationship) => {
                                                        return (

                                                            <Button key={relationship} className={classes.relationButton} onClick={() => this.setNomineeRelationShipWithPrimary(relationship)} style={{ backgroundColor: this.state.nomineeRelationshipWithPrimary === relationship ? '#f38b44' : '#e5e5e5', color: this.state.nomineeRelationshipWithPrimary === relationship ? 'white' : '#908f8f', textTransform: 'capitalize', width: '7.5vw' }} >{relationship}</Button>

                                                        )
                                                    })
                                                }
                                            </Grid>
                                        </Grid> </> : <> </>}
                            </Grid>

                        </div>

                    </div>
                </div>
                < Drawer disableEnforceFocus anchor="right" open={this.state.addMemberDrawer} onClose={this.closeAddMemberDrawer}>

                    <Paper style={{ width: '62vh', overflowY: 'hidden' }}>
                        {this.renderAddMemberDrawerContent()}
                    </Paper>
                </Drawer>

            </div>

        )
    }
    render() {
        if (this.props.newPlan && _.isNil(this.props.newPlan.allMembersOfCurrentFamily)) {
            return (
                <div style={{ height: '53vh', overflowY: 'scroll' }}>
                    <div style={{ marginTop: '10vh' }}>
                        {this.getCircularProgress()}
                    </div>
                </div>
            );
        } else if (this.props.newPlan && this.props.newPlan.allMembersOfCurrentFamily && this.props.newPlan.allMembersOfCurrentFamily.members && !_.isEmpty(this.props.newPlan.allMembersOfCurrentFamily.members)) {
            return (
                <div style={{ height: '53vh', overflowY: 'scroll' }}>
                    <div style={{ marginTop: '10vh' }}>
                        {this.renderAllMembers()}
                    </div>
                </div>
            );
        } else {
            return (
                <div style={{ height: '53vh', overflowY: 'scroll' }}>
                    <div style={{ marginTop: '5vh', marginLeft: '5vw' }}>Something went wrong !!</div>
                </div>
            );
        }
    }
}

SelectNominee.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error, familyDetails: state.familyDetails, newPlan: state.newPlan, newCustomer: state.newCustomer };
};

export default connect(mapStateToProps, { getAllMembersByFamilyId, resetGetAllMembersByFamilyId, resetNewMemberAdded, addNewMemberByFamilyId })(withStyles(styles)(SelectNominee));