import React from 'react';
import { connect } from 'react-redux';
import { map, has, isEmpty } from 'lodash';
import { InputLabel, withStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import useStyles from './style';
import { consultationActions } from '../../../actions/consultationAction';
import { itemTypes } from '../../../constants';
import { policyStatus, subscriptionStatus } from '../../../common/constants';
import ProductUtil from '../../../common/ProductUtil';
import ProductTable from './ProductTable';
import { getOpdBalanceEvent, resetOpdAmounts } from '../../../actions';

/**
 * 
 * @param {Object} item - the search result/billing item we need the label for
 * @returns {String} a formatted category label, or an empty string
 */
 function getFormattedItemCategory(item) {
    // items in the billing list are formatted to have the
    // item.type property, but the search results from the
    // backend have the item.category property.
    // Since we use this function to also generate
    // the category label for autocomplete suggestions,
    // both must be supported.
    let itemType = null;
    if (item.hasOwnProperty('category')) {
        if (typeof item.category === 'object') {
            // this property is an array of categories,
            // we need to pick the first element in the array
            itemType = item.category[0];
        } else {
            itemType = item.category;
        }
    } else if (item.hasOwnProperty('type')) {
        if (typeof item.type === 'object') {
            // this property is an array of categories,
            // we need to pick the first element in the array
            itemType = item.type[0];
        } else {
            itemType = item.type;
        }
    }
    const categories = itemTypes;
    if (categories) {
        const itemCategoryToRender = Object.values(categories).filter(category => {
            return category === itemType;
        })[0];
        const itemCategoryTokens = itemCategoryToRender.split('_');
        if (itemCategoryTokens.includes(itemTypes.OTHER)) return 'Other';
        if (itemCategoryTokens.includes(itemTypes.GENERIC)) return '';
        return itemCategoryTokens
            .map(token => (
                token.charAt(0).toUpperCase() + token.slice(1, token.length)
            ))
            .join(' ');
    } else return '';
}
class AddProducts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMember: '',
            selectedSubscription: '',
            selectedSubscriptionId: '',
            isLoading: false
        };
    }

    componentDidMount() {
        const { consultationDetails, familyDetails, updateBilledSubscription, getOpdAmounts } = this.props;
        const activeSubscriptions = familyDetails?.allSubscriptions?.subscriptions?.filter((subscription) => subscription?.status === 'active')
        const selectedCustomer = consultationDetails?.billing?.customer?.id;
        let selectedPlan;
        // let subscriptionMembers = [];
        // let member = [];
        // console.log(subscription.version, '----subscription.version');
        // if (subscription.version === 0) {
        //     if (subscription?.health_insurance?.members?.length > 0)
        //     subscriptionMembers = subscription?.health_insurance?.members || [];
        //     else if (subscription?.primary_care?.members?.length > 0)
        //     subscriptionMembers = subscription?.primary_care?.members || [];
        //     member = subscriptionMembers.find(member => member.customer.id === this.state.selectedMember);
        // } else {
        //     subscriptionMembers = subscription?.members || [];
        //     member = subscriptionMembers.find(member => member.customer === this.state.selectedMember);
        // }
        // activeSubscriptions.forEach(async (sub) => {
            // const member = this.isMemberEligibleForPlan(sub, this.state.selectedMember === '' ? selectedCustomer : this.state.selectedMember )
            // console.log(member, '----member', this.state.selectedMember, selectedCustomer);
            // let subscriptionMembers = [];
            // let member = [];
            // console.log(sub.version, '----subscription.version', selectedCustomer, this.state.selectedMember);
            // if (sub.version === 0) {
            //     if (sub?.health_insurance?.members?.length > 0)
            //     subscriptionMembers = sub?.health_insurance?.members || [];
            //     else if (sub?.primary_care?.members?.length > 0)
            //     subscriptionMembers = sub?.primary_care?.members || [];
            //     member = subscriptionMembers.find(member => member.customer.id === selectedCustomer);
            // } else {
            //     subscriptionMembers = sub?.members || [];
            //     member = subscriptionMembers.find(member => member.customer === selectedCustomer);
            // }
        //     const member = sub.members && sub.members.find(member => member.customer === selectedCustomer);
        //     if (member) {
        //         getOpdAmounts(sub.id);
        //         updateBilledSubscription({
        //             subscriptionId: sub.id,
        //             subscriptionInfo: sub,
        //         })
        //         selectedPlan = sub
        //         return;
        //     }
        // });
        this.setState({
            selectedMember: selectedCustomer,
            ...(selectedPlan && { selectedSubscription: selectedPlan.id }),
        });
    }

    componentDidUpdate() {
        const { consultationDetails, familyDetails, updateBilledSubscription, getOpdAmounts } = this.props;
        const activeSubscriptions = familyDetails?.allSubscriptions?.subscriptions?.filter((subscription) => subscription?.status === 'active')
        const selectedCustomer = consultationDetails?.billing?.customer?.id;
        if (!familyDetails?.opdAmounts && this.state.selectedSubscription) {
            let selectedPlan;
            activeSubscriptions.forEach(async (sub) => {
                let subscriptionMembers = [];
                let member = [];
                console.log(sub.version, '----subscription.version', selectedCustomer, this.state.selectedMember);
                if (sub.version === 0) {
                    if (sub?.health_insurance?.members?.length > 0)
                    subscriptionMembers = sub?.health_insurance?.members || [];
                    else if (sub?.primary_care?.members?.length > 0)
                    subscriptionMembers = sub?.primary_care?.members || [];
                    member = subscriptionMembers.find(member => member.customer.id === selectedCustomer);
                } else {
                    subscriptionMembers = sub?.members || [];
                    member = subscriptionMembers.find(member => member.customer === selectedCustomer);
                }
                // const member = sub.members.find(member => member.customer === selectedCustomer);
                if (!this.state.isLoading && member) {
                    getOpdAmounts(sub.id);
                    updateBilledSubscription({
                        subscriptionId: sub.id,
                        subscriptionInfo: sub,
                    })
                    selectedPlan = sub
                    this.setState({
                        isLoading: true,
                    })
                    return;
                }
                // this.setState({
                //     isLoading: true,
                // })
            });
        }
    }

    handleMemberOnClick = (event) => {
        const { updateBilledCustomer, familyDetails, getOpdAmounts, resetOpdAmounts, updateBilledSubscription } = this.props;
        const activeSubscriptions = familyDetails?.allSubscriptions?.subscriptions?.filter((subscription) => subscription?.status === 'active');
        let selectedPlan;
        activeSubscriptions.forEach(async (sub) => {
            // const member = sub.members.find(member => member.customer === event.target.value);
            const member = this.isMemberEligibleForPlan(sub, event.target.value);
            if (member) {
                // getOpdAmounts(sub.id);
                updateBilledSubscription({
                    subscriptionId: sub.id,
                    subscriptionInfo: sub,
                })
                selectedPlan = sub.id
                return;
            }
        });
        if (!selectedPlan) {
            resetOpdAmounts();
        }
        console.log(event.target.value, 'set state');
        this.setState({
            selectedMember: event.target.value,
            selectedSubscription: selectedPlan,
        }, () => {
            updateBilledCustomer({
                id: event.target.value,
            })
        });
    }

    handleSubscriptionOnClick = (event) => {
        this.setState({
            selectedSubscription: event.target.value,
        },)
    }

    getOpdAmount = () => {
        const { selectedSubscription } = this.state;
        const { getOpdAmounts } = this.props;
        if (selectedSubscription) {
            const opdDetails = getOpdAmounts(selectedSubscription)
            this.setState({ opdBalanceCalled: true })
            return opdDetails;
        }
    }

    getOpdCoverage = () => {
        const { familyDetails } = this.props;
        const formattedCoveredAmount = familyDetails?.opdAmounts?.data?.data?.covered_amount?.toLocaleString("en-IN", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          });
          const formattedCurrentAmount = familyDetails?.opdAmounts?.data?.data?.current_amount?.toLocaleString("en-IN", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          });
        if (familyDetails?.opdAmounts) {
            return (formattedCurrentAmount + '/' + formattedCoveredAmount)
        }
    }

    renderMemberDropdown = () => {
        const { classes, familyDetails } = this.props;
        const { selectedMember } = this.state;
        return (
            <FormControl
                style={{
                    width: '40%',
                }}
            >
                <InputLabel id="demo-simple-select-label">Select a member</InputLabel>
                <Select
                    value={selectedMember}
                    // disabled={consultationDetails.billing.for === billingType.CONSULTATION}
                    onChange={(event) => this.handleMemberOnClick(event)}
                    // color={color.ROYAL_ORANGE}
                    // disabled
                    classes={{
                        outlined: classes.select,
                        // root: classes.root,
                        disabled: classes.disabledSelect,
                    }}
                >
                    <MenuItem
                        key="default"
                        classes={{
                            root: classes.menuOption
                        }}
                        disabled
                    >
                        Select a member
                    </MenuItem>
                    {
                        map(familyDetails.allMembers.members, (member) => (
                            <MenuItem
                                key={member.id}
                                value={member.id}
                                classes={{
                                    root: classes.menuOption
                                }}
                            >
                                {member.first_name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        );
    }

    // renderPlanName = () => {
    //     const { classes, familyDetails, consultationDetails } = this.props;
    //     const getPlanName = () => {
    //         const customerToBeCharged = consultationDetails?.billing?.customer;
    //         // list of subscriptions purchased AND activated under this family ID;
    //         const purchasedSubscriptions = familyDetails?.allSubscriptions?.subscriptions.filter(subscription => {
    //             console.log('sub', subscription)
    //             if (subscription.status === subscriptionStatus.ACTIVE) {
    //                 return true;
    //             }
    //             return false;
    //             // filter the members list of each subscription and check if the user is in it;
    //         });
    //         console.log('pur', purchasedSubscriptions)
    //         const subscriptionsMappedToUser = purchasedSubscriptions.filter(subscription => {
    //             console.log('werwq', subscription)
    //             const memberList = subscription.version === 0 ?
    //             subscription?.primary_care?.members.map(member => member.customer.id)
    //             : subscription.members.map(member => member.customer);
    //             // subscriber details are not added to subscriptions with primary care only products,
    //             // so we skip the check for these and assume that the user is covered under this plan;
    //             if (utils.isPrimaryCareOnlyProduct(subscription.product)) {
    //                 return true;
    //             }
    //             // non primary-care-only cases
    //             if (memberList != null) {
    //                 if (memberList.includes(customerToBeCharged.id)) {
    //                     return true;
    //                 }
    //                 else {
    //                     return false;
    //                 }
    //             }
    //             return false;
    //         });
    //         if (isEmpty(subscriptionsMappedToUser)) {
    //             return "No plan purchased."
    //         }
    //         else {
    //             // check if any subscriptions are HPP Plus;
    //             // If HPP Plus plans exist AND at least one is below threshold,
    //             // present that
    //             const hppPlusSubscriptions = subscriptionsMappedToUser.filter(subscription => {
    //                 return has(subscription, 'is_hpp');
    //             });
    //             if (!isEmpty(hppPlusSubscriptions)) {
    //                 const subscriptionsBelowThreshold = hppPlusSubscriptions.filter(subscription => subscription.is_hpp === true);
    //                 return (!isEmpty(subscriptionsBelowThreshold))
    //                     ? subscriptionsBelowThreshold[0].product.name
    //                     : hppPlusSubscriptions[0].product.name
    //             } else {
    //                 // no HPP Plus plans cover this user - return the first
    //                 // active plan that covers the user;
    //                 return subscriptionsMappedToUser[0].product.name;
    //             }
    //         }
    //     };
    //     return (
    //         <div className={classes.planNameContainer}>
    //             Plan:&nbsp;<strong>{getPlanName()}</strong>
    //         </div>
    //     )
    // }

    // getActiveMember = (sub)

    isMemberEligibleForPlan = (subscription, selectedCustomer) => {
        // if (selectedCustomer) {
            // }
            // console.log('--subscription test', selectedCustomer)
            let subscriptionMembers = [];
        let member = [];
        // console.log(subscription.version, '----subscription.version');
        if (subscription.version === 0) {
            if (subscription?.health_insurance?.members?.length > 0) 
            subscriptionMembers = subscription?.health_insurance?.members || [];
            else if (subscription?.primary_care?.members?.length > 0)
            subscriptionMembers = subscription?.primary_care?.members || [];
            member = subscriptionMembers.find(member => member.customer.id === selectedCustomer);
        } else {
            subscriptionMembers = subscription?.members || [];
            member = subscriptionMembers.find(member => member.customer === selectedCustomer);
        }
        // console.log(member, '----subscriptionMembers');
    
    
        if (member) return true
        return false
    };
    // renderPlansDropdown = () => {
    //     const { classes, familyDetails, updateBilledSubscription } = this.props;
    //     const { selectedSubscription } = this.state;
    //     console.log(selectedSubscription?.id)
    renderPlansDropdown = () => {
        const { classes, familyDetails, updateBilledSubscription } = this.props;
        const { selectedSubscription, selectedSubscriptionId } = this.state;
        const activeSubscriptions = familyDetails?.allSubscriptions?.subscriptions?.filter((subscription) => subscription?.status === 'active');
        // console.log(activeSubscriptions, '-----activeSubscriptions');
        return (
            <FormControl
                style={{
                    width: '312px',
                }}
            >
              <InputLabel id="demo-simple-select-label">Select a Plann</InputLabel>
                <Select
                    value={selectedSubscription}
                    // disabled={consultationDetails.billing.for === billingType.CONSULTATION}
                    onChange={(event) => this.handleSubscriptionOnClick(event)}
                    // color={color.ROYAL_ORANGE}
                    // disabled
                    classes={{
                        outlined: classes.select,
                        // root: classes.root,
                        disabled: classes.disabledSelect,
                    }}
                >
                    <MenuItem
                        key="default"
                        classes={{
                            root: classes.menuOption
                        }}
                        disabled
                    >
                        Select a Plan
                    </MenuItem>
                    {
                        map(activeSubscriptions, (subscription) => {
                            if(this.isMemberEligibleForPlan(subscription, this.state.selectedMember)) {
                                return (<MenuItem
                                key={subscription?.id}
                                value={subscription?.id}
                                onClick={() => {
                                    this.setState({ selectedSubscriptionId: subscription })
                                    updateBilledSubscription({
                                        subscriptionId: subscription?.id,
                                        subscriptionInfo: subscription,
                                    })
                                }}
                                classes={{
                                    root: classes.menuOption
                                }}
                            >
                                {subscription?.product?.display_name}
                            </MenuItem>)
                            } else {
                                return <></>
                            }
                        })
                    }
                </Select>
            </FormControl>
        );
    }

    render() {
        const { classes, discountComputation, familyDetails, consultationDetails } = this.props;
        const { selectedSubscriptionId, opdBalanceCalled } = this.state;
        return (
            <div
                className={classes.container}
            >
                <div
                    className={classes.row}
                >
                    {this.renderMemberDropdown()}
                    {this.renderPlansDropdown()}
                    {familyDetails?.opdAmounts?.data?.data?.covered_amount && familyDetails?.opdAmounts?.data?.data?.expires_at > Date.now() ? <div style={{ display: 'flex', width: '300px', border: '1px solid gray', paddingLeft: '7px', paddingRight: '7px' }}>
                        <p style={{ fontWeight: 400 }}>Available OPD: </p>
                        <p style={{ fontWeight: 'bold', color: familyDetails?.opdAmounts?.data?.data?.current_amount === 0 ? '#EA3939' : '' }}>{this.getOpdCoverage()}</p>
                    </div> : ''}
                </div>
                <ProductTable opdAmountValue={familyDetails?.opdAmounts?.data?.data?.current_amount} selectedSubscription={selectedSubscriptionId} getFormattedItemCategory={getFormattedItemCategory} discountComputation={discountComputation} eVitalRxTab={this.props.eVitalRxTab} syncLineItems={this.props.syncLineItems} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { familyDetails, consultationDetails, auth } = state;
    return {
        familyDetails,
        consultationDetails,
        auth
    }
};

const mapDispatchToProps = (dispatch) => ({
    modifyLineItem: (itemId, modifications) => { dispatch(consultationActions.modifyLineItem(itemId, modifications)); },
    addLineItem: (itemType, item) => { dispatch(consultationActions.addLineItem(itemType, item)); },
    searchBillingItems: (item, clinic) => { dispatch(consultationActions.searchBillingItems(item, clinic)); },
    updateBilledCustomer: (customer) => { dispatch(consultationActions.updateBilledCustomer(customer)); },
    updateBilledSubscription: (subscription) => { dispatch(consultationActions.updateBilledSubscription(subscription)); },
    getOpdAmounts: (subscription) => { dispatch(getOpdBalanceEvent(subscription)); },
    resetOpdAmounts: () => { dispatch(resetOpdAmounts()); },

});

const styleWrapper = withStyles(useStyles, { withTheme: true });
const reduxWrapper = connect(mapStateToProps, mapDispatchToProps);
export default styleWrapper(reduxWrapper(AddProducts));