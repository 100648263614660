import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { orange } from "@material-ui/core/colors";
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography, Button, Paper } from '@material-ui/core';

import { ReactComponent as Nach } from '../../../images/Nach.svg';

import { getNachDetailsByFamilyId, cancelMandate, resetCancelMandateStatus } from '../../../actions';
import { accessRole, mandateStatus } from '../../../common/constants';
import { errors } from '../../../common/errors';

function createData(name, value) {
    return { name, value };
}

const materialTheme = createMuiTheme({
    palette: {
        primary: orange,
    },
});
class MiddleTab extends React.Component {

    state = { cancelNachDialog: false, yesButtonPressed: false };

    componentDidMount() {
        const familyId = this.props.familyDetails.currentCustomer ? this.props.familyDetails.currentCustomer.family.id : 0;
        this.props.getNachDetailsByFamilyId(familyId);
        this.props.resetCancelMandateStatus();
    };

    handleNo = () => {
        this.setState({ cancelNachDialog: false, yesButtonPressed: false });
    };

    handleYes = () => {
        this.setState({ yesButtonPressed: true });
        const mandateId = this.props.familyDetails && this.props.familyDetails.nachDetail ? this.props.familyDetails.nachDetail.id : null;
        const cancelMandatePromise = this.props.cancelMandate(mandateId);
        const familyId = this.props.familyDetails.currentCustomer ? this.props.familyDetails.currentCustomer.family.id : 0;
        cancelMandatePromise.then(() => 
        { if (this.props)
        { this.setState({ cancelNachDialog: false, yesButtonPressed: false }); this.props.getNachDetailsByFamilyId(familyId); this.props.resetCancelMandateStatus(); }
        }
        )
    };

    handleCancelMandateButton = () => {
        this.props.resetCancelMandateStatus();
        this.setState({ cancelNachDialog: true });
    };

    renderRow = (name, value) => {
        if (this.props.familyDetails.nachDetail.status !== 'active' && this.props.familyDetails.nachDetail.status !== 'failed' && name === 'Status') {
            return <div style={{ color: 'orange', fontStyle: 'italic' }}>{value} </div>
        } else
            if (this.props.familyDetails.nachDetail.status === 'active' && name === 'Status') {
                return <div style={{ color: 'green', fontStyle: 'italic' }}>{value} </div>
            } else if (this.props.familyDetails.nachDetail.status === 'failed' && name === 'Status') {
                return <div style={{ color: 'red', fontStyle: 'italic' }}>{value} </div>
            } else {
                return value;
            }
    }

    renderCancelMandateDialog = () => {
        if (this.props.familyDetails.cancelMandateStatus === errors.ERROR_IN_CANCELLING_MANDATE) {
            return (<div> Error while cancelling mandate</div>);
        }
        if (this.state.yesButtonPressed && (this.props.familyDetails.cancelMandateStatus === null)) {
            return (
                <div>
                    <Paper style={{ position: 'relative', height: '18vh', width: '50vh' }}>
                        <CircularProgress style={{ color: 'orange', marginLeft: '45%', marginRight: '50%', marginTop: '15%', marginBottom: '10%' }} />
                    </Paper>
                </div>)
        } else {
            return (
                <div style={{ position: 'relative', height: '18vh', width: '50vh' }}>
                    <ThemeProvider theme={materialTheme}>
                        <Typography style={{ width: '36%', marginLeft: '32%', marginRight: '32%', fontFamily: 'Rubik-Medium', fontSize: '2.5vh', marginTop: '2vh' }}> Are you sure ?</Typography>
                    </ThemeProvider>
                    <div style={{ height: '2vh', marginTop: '4vh', marginBottom: '6vh' }}>
                        <Button
                            variant="outlined"
                            onClick={() => this.handleNo()}
                            style={{ borderColor: 'orange', color: 'orange', backgroundColor: 'white', textTransform: 'capitalize', marginLeft: '17.5%', width: '30%', marginBottom: '2vh', marginRight: '5%' }}
                        >
                            No
                        </Button>
                        <Button
                            onClick={() => this.handleYes()}
                            style={{
                                color: 'white',
                                backgroundColor: 'orange',
                                textTransform: 'capitalize', marginRight: '17.5%', width: '30%', marginBottom: '2vh'
                            }}
                            color="primary"
                            // disabled={this.state.updatedRmn.length !== 10 || this.state.retypedRmn.length !== 10}
                            autoFocus>
                            Yes
                       </Button>
                    </div>
                </div>);
        }
    }

    render() {
        if (!this.props.familyDetails.nachDetail) {
            return <div> <CircularProgress style={{ color: 'orange', marginRight: '10vh', marginTop: '20vh' }} disableShrink /> </div>;
        } else if (this.props.error && this.props.error.nachErrorCode) {
            return (
                <div>
                    <br />
                    <Nach style={{ height: '15vh', width: '20vh' }} />
                    <Typography style={{
                        textAlign: 'left', fontFamily: 'Rubik',
                        fontWeight: 'normal',
                        fontStretch: 'normal',
                        fontStyle: 'italic', fontSize: '12px', marginLeft: '14vh', marginTop: '3vh', color: 'grey'
                    }}>  {this.props.error.nachErrorCode}</Typography>
                </div>);
        } else {
            const
                rows = [
                    createData('Status', this.props.familyDetails.nachDetail.status ? _.capitalize(this.props.familyDetails.nachDetail.status) : '-'),
                    createData('Bank Name', this.props.familyDetails.nachDetail.bank_name ? _.capitalize(this.props.familyDetails.nachDetail.bank_name) : '-'),
                    createData('A/c Holder Name', this.props.familyDetails.nachDetail.account_holder ? _.capitalize(this.props.familyDetails.nachDetail.account_holder) : '-'),
                    createData('IFSC', this.props.familyDetails.nachDetail.ifsc ? _.capitalize(this.props.familyDetails.nachDetail.ifsc) : '-'),
                    createData('Account No.', this.props.familyDetails.nachDetail.account_number ? _.capitalize(this.props.familyDetails.nachDetail.account_number) : '-'),
                    createData('Account Type', this.props.familyDetails.nachDetail.account_type ? _.capitalize(this.props.familyDetails.nachDetail.account_type) : '-'),
                ];
            return (
                <div style={{ position: 'relative' }}>
                    <Table style={{
                        minWidth: 100,
                        borderTop: 'none',
                        borderBottom: 'none !important'
                    }} aria-label="simple table">
                        <TableBody style={{ overflowX: 'scroll' }}>
                            {rows.map(row => {
                                return (
                                    <TableRow key={row.name}>
                                        <TableCell style={{ fontWeight: 'bold', border: 'none', color: 'black' }} component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="left" style={{ border: 'none' }}> {this.renderRow(row.name, row.value)}</TableCell>
                                    </TableRow>
                                );
                            }
                            )}
                        </TableBody>
                    </Table>

                    {(this.props.auth && this.props.auth.authDetails && !_.isNil(_.find(this.props.auth.authDetails.data.user.roles, { name: accessRole.HEALTH_MANAGER })) && (this.props.familyDetails && this.props.familyDetails.nachDetail && this.props.familyDetails.nachDetail.status === mandateStatus.ACTIVE)) ?
                        <Button onClick={this.handleCancelMandateButton} variant="outlined" style={{ borderColor: 'orange', color: 'orange', posiiton: 'absolute', bottom: '-16vh' }}>
                            <Typography style={{ textTransform: 'capitalize' }}> Cancel </Typography>
                        </Button>
                        : <> </>}


                    <Dialog
                        style={{
                            height: '100%', marginLeft: '30%', marginRight: '50%',
                            width: '80vh',
                            borderRadius: '100px'
                        }}
                        open={this.state.cancelNachDialog}
                        // open = {true}
                        // onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        {this.renderCancelMandateDialog()}
                    </Dialog>

                </div>
            );
        };
    };
};

const mapStateToProps = (state) => {
    return { auth: state.auth, familyDetails: state.familyDetails, error: state.error };
};


export default connect(mapStateToProps, { getNachDetailsByFamilyId, cancelMandate, resetCancelMandateStatus })(MiddleTab);
