import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Button, Dialog } from "@material-ui/core";
import { invoiceStatus } from "../../../common/constants";
import { getISTFormat3 } from "../../../common/utils";
import PaymentDetails from "./PaymentDetails";
import {
  getOrder,
  resetOrderDetails,
  openViewDetails,
  closeViewDetails,
} from "../../../actions";
import css from "./ParticularInvoice.module.scss";
import { strings } from "../../../constants";
import { ReactComponent as CopyIcon } from "../../../assets/icons/copy-icon-one.svg";
import { ReactComponent as Vector } from "../../../assets/icons/Vector.svg";
import { ReactComponent as ViewIcon } from "../../../assets/icons/view.svg";
import format from "string-template";
import validateEnvIsString from "../../../config/schema";

class ParticularInvoice extends React.Component {

  
  
  
  
  dismissOrderDetails = () => {
    this.props.closeViewDetails();
    this.props.resetOrderDetails();
  };

  renderInvoiceStatusColor = (status) => {
    switch (status) {
      case invoiceStatus.PAID:
        return "statusColor_PAID";
      case invoiceStatus.UNPAID:
        return "statusColor_UNPAID";
      case invoiceStatus.PENDING_ON_NACH:
        return "statusColor_PENDING_ON_NACH";
      default:
        return "statusColor_DEFAULT";
    }
  };

  viewDetails = () => {
    this.props.openViewDetails(this.props.invoice.order.id);
  };

  render() {
    const invoiceUrlEnvVariable = process.env.REACT_APP_SUBSCRIPTION_INVOICE_URL;
    const validatedInvoiceUrlTemplate = validateEnvIsString(invoiceUrlEnvVariable);
    const invoiceURL = format(validatedInvoiceUrlTemplate, {
      orderId: this.props.invoice?.order?.id,
    });
  
    return (
      <div className={css.invoiceBoxContainer}>
        <table className={css.invoiceBoxItem}>
          <tr className={css.invoiceDetailsRow}>
            <td className={`${css.invoiceDetailsRow_label} rubikRegular`}>
              Order Id
            </td>
            <td className={css.invoiceDetailsRow_value}>
              {this.props?.invoice?.order?.id || "-"}
            </td>
          </tr>
          <tr className={css.invoiceDetailsRow}>
            <td className={`${css.invoiceDetailsRow_label} rubikRegular`}>
              Duration
            </td>
            <td
              className={css.invoiceDetailsRow_value}
              style={{ fontWeight: this.props.invoice.to_date ? "400" : "600" }}
            >
              {this.props.invoice && this.props.invoice.from_date
                ? getISTFormat3(this.props.invoice.from_date) + " -"
                : ""}
              {this.props.invoice && this.props.invoice.to_date
                ? getISTFormat3(this.props.invoice.to_date)
                : "-"}
            </td>
          </tr>
          <tr className={css.invoiceDetailsRow}>
            <td className={`${css.invoiceDetailsRow_label} rubikRegular`}>
              Total Amount
            </td>
            <td className={`${css.invoiceDetailsRow_value} rubikBold`}>
              {this.props.invoice && this.props.invoice.paid_amount
                ? " ₹" + this.props.invoice.paid_amount
                : "-"}
            </td>
          </tr>
          <tr className={css.invoiceDetailsRow}>
            <td className={`${css.invoiceDetailsRow_label} rubikRegular`}>
              Generated On
            </td>
            <td className={css.invoiceDetailsRow_value}>
              {this.props.invoice && this.props.invoice.generated_at
                ? getISTFormat3(this.props.invoice.generated_at)
                : "-"}
            </td>
          </tr>
          <tr className={css.invoiceDetailsRow}>
            <td className={`${css.invoiceDetailsRow_label} rubikRegular`}>
              Paid On
            </td>
            <td className={css.invoiceDetailsRow_value}>
              
              {this.props.invoice.paid_on
                ? getISTFormat3(this.props.invoice.paid_on)
                : "-"}
            </td>
          </tr>
          <tr className={css.invoiceDetailsRow}>
            <td className={`${css.invoiceDetailsRow_label} rubikRegular`}>
              Due Date
            </td>
            <td className={css.invoiceDetailsRow_value}>
              {this.props.invoice && this.props.invoice.due_date
                ? getISTFormat3(this.props.invoice.due_date)
                : "-"}
            </td>
          </tr>
          <tr className={css.invoiceDetailsRow}>
            <td className={`${css.invoiceDetailsRow_label} rubikRegular`}>
              Sale Type
            </td>
            <td className={`${css.invoiceDetailsRow_value} rubikBold`}>
              {this.props.invoice.due_date
                ? strings.RENEWAL
                : strings.NEW_PURCHASE}
            </td>
          </tr>
          {this.props.invoice.status !== 'paid' && this.props.invoice.status !== 'cancelled'
          && this.props.invoice.status !== 'withdrawn' && <tr className={css.invoiceDetailsRow}>
            <td className={`${css.invoiceDetailsRow_label} rubikRegular`}>
              Payment Link
            </td>
            <td className={`${css.invoiceDetailsRow_value} `}>
             <a target="_blank" href={this.props.invoice.rzp_payment_link}> {this.props.invoice.rzp_payment_link ?? '-'} </a>
            </td>
          </tr>}
          <tr className={css.invoiceDetailsRow}>
            <td className={`${css.invoiceDetailsRow_label} rubikRegular`}>
              Status
            </td>
            <td
              className={`${css.invoicePaymentDetailContainer_status} ${
                css[this.renderInvoiceStatusColor(this.props.invoice.status)]
              } rubikBold`}
            >
              {this.props.invoice
                ? this.props.invoice.status.split("_").join(" ")
                : "-"}
            </td>
          </tr>
          {this.props.invoice.status === invoiceStatus.PAID ? (
            <tr className={css.invoiceDetailsRow}>
              <td className={`${css.invoiceDetailsRow_label} rubikRegular`}>
                Payment Method
              </td>
              <td
                className={`${css.invoiceDetailsRow_value} rubikBold ${css.paymentMethods}`}
              >
                {this.props.invoice?.subscription?.mandate?.vpa ? (
                  <div>
                    <div className={css.methodUPI}>
                      <div
                        className={`${css.invoiceDetailsRow_value} rubikBold`}
                      >
                        UPI
                      </div>
                      <div className={css.upiId}>
                        {this.props.invoice.subscription.mandate.vpa}
                      </div>
                      <div className={css.copyIcon}>
                        <CopyIcon
                          onClick={() =>
                            navigator.clipboard.writeText(
                              this.props.invoice.subscription.mandate.vpa
                            )
                          }
                        />
                      </div>
                    </div>
                    <hr className={css.divider} />
                  </div>
                ) : null}
                <div className={css.buttons}>
                  <Button
                    variant="contained"
                    className={css.buttonsStyle}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={invoiceURL}
                    style={{
                      backgroundColor: "#F38B44",
                      textTransform: "capitalize",
                      boxShadow: "none",
                      color: "white",
                      marginRight: "12px",
                      fontWeight: "400",
                      fontSize: "12.57px",
                      lineHeight: "14.9px",
                    }}
                  >
                    <Vector className={css.svgIcon} />
                    Invoice
                  </Button>
                  <Button
                    variant="outlined"
                    className={css.buttonsStyle}
                    onClick={this.viewDetails}
                    disabled={
                      this.props.invoice.status === invoiceStatus.UNPAID ||
                      this.props.invoice.status === invoiceStatus.WITHDRAWN ||
                      this.props.invoice.status ===
                        invoiceStatus.PENDING_ON_NACH ||
                      _.isNil(this.props.invoice.order.id)
                    }
                    style={{
                      backgroundColor: "white",
                      textTransform: "capitalize",
                      color: "#F38B44",
                      borderColor: "#F38B44",
                      marginRight: "12px",
                      fontWeight: "400",
                      fontSize: "12.57px",
                      lineHeight: "14.9px",
                    }}
                  >
                    <ViewIcon className={css.svgIcon} />
                    Details
                  </Button>
                </div>
              </td>
            </tr>
          ) : null}
        </table>
        <Dialog
          open={
            this.props.familyDetails.viewDetailsPressed &&
            this.props.invoice.order.id ===
              this.props.familyDetails.currentOrderId &&
            !_.isNil(this.props.familyDetails.currentOrderId)
          }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <PaymentDetails
            invoice={this.props.invoice}
            dismissOrderDetails={() => this.dismissOrderDetails()}
            orderId={this.props.invoice.order.id}
          />
          {/* <Button onClick={this.dismissOrderDetails} style={{ width: '70%', marginLeft: '15%', marginRight: '15%', marginBottom: '2vh', backgroundColor: '#F38B44', color: 'white', textTransform: 'normal' }}> <Typography> Dismiss</Typography></Button> */}
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    familyDetails: state.familyDetails,
    error: state.error,
  };
};

export default connect(mapStateToProps, {
  getOrder,
  resetOrderDetails,
  openViewDetails,
  closeViewDetails,
})(ParticularInvoice);
