import axios from 'axios';

import config from '../config';

export const cancelMandateByMandateId = async (ACCESS_TOKEN, mandateId) => {
    const url = config.apiURL + `/v3/mandates/${mandateId}/cancel`;
    const options = {
        headers: {
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + ACCESS_TOKEN,
    
        },
        timeout: 10000,
        responseType: 'json',
        responseEncoding: 'utf8',
      };
    const response = await axios.post(url, undefined, options);
    return response;
};

export const sendMandateLink = async (ACCESS_TOKEN, payload) => {
  const url = `${config.apiURL}/v1/notifications/payments`;
  const options = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${ACCESS_TOKEN}`,
    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
    url,
    data: payload,
  };
  const result = await axios(options);
  return result;
};