import axios from 'axios';
import config from '../config';

/**
 * 
 * @param {*} leadInfo lead to be created
 * Function to POST lead data and create a fresh lead
 */
async function createLead(accessToken, leadInfo) {
    const url = config.apiURL + `/v1/leads`;
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        },
        timeout: config.timeout,
        responseType: 'json',
        responseEncoding: 'utf8',
        url,
        data: leadInfo,
    };
    const result = await axios(options);
    return result;
}

/**
 * 
 * @param {*} activityInfo activity to be logged
 * on LSQ for a previously created lead (the lead ID doesn't
 * have to be persisted on the frontend).
 * Function to POST activity data for an existing LSQ lead.
 */
 async function createActivity(accessToken, activityInfo) {
    const url = config.apiURL + `/v1/leads/activities`;
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        },
        timeout: 10000,
        responseType: 'json',
        responseEncoding: 'utf8',
        url,
        data: activityInfo,
    };
    const result = await axios(options);
    return result;
}

export const leadService = {
    createLead,
    createActivity,
}