import config from '../config';
import axios from 'axios';

export const getOtp = async (mobile) => {
    const url = config.accountsURL + `/v1/otp`;
    const data = {
        client_id: config.clientID,
        client_secret: config.clientSecret,
        mobile,
    };
    const options = {
        headers: {
            'content-type': 'application/json',
        },
        timeout: 10000,
        responseType: 'json',
        responseEncoding: 'utf8',
    };
    const response = await axios.post(url, data, options);
    return response;
};

export const checkOtp = async (phoneNumber, otp) => {
    const url = config.accountsURL + `/v1/oauth/token`;
    const data = {
        client_id: config.clientID,
        client_secret: config.clientSecret,
        phone_number: phoneNumber,
        grant_type: 'otp',
        otp
    };
    const options = {
        headers: {
            'content-type': 'application/json',
        },
        timeout: 10000,
        responseType: 'json',
        responseEncoding: 'utf8',
    };
    const response = await axios.post(url, data, options);
    return response;
};

export async function setAvailability(partnerId, ACCESS_TOKEN, AVAILABILITY) {
    let url = `${config.apiURL}/v1/partners/${partnerId}/mark-available`;
    if (AVAILABILITY) {
        url = `${config.apiURL}/v1/partners/${partnerId}/mark-unavailable`;
    }
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + ACCESS_TOKEN,
        },
        timeout: config.timeout,
        responseType: 'json',
        responseEncoding: 'utf8',
        url,
    };
    const result = await axios(options);
    return result;
}

export async function getAvailability(partnerId, ACCESS_TOKEN) {
    let url = `${config.apiURL}/v1/partners/${partnerId}/availability`;
    const options = {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + ACCESS_TOKEN,
        },
        timeout: config.timeout,
        responseType: 'json',
        responseEncoding: 'utf8',
        url,
    };
    const result = await axios(options);
    return result;
}
