import React from 'react';
import { connect } from 'react-redux';
import {getSubscriptionById}from '../../../actions';
import CustomerDetails from './CustomerDetails';
import {CircularProgress}from '@material-ui/core';

class CustomerDetailsRender extends React.Component {
    
    getCircularProgress = () => {
        return <CircularProgress style={{
            color: 'orange',
            left: '50%',
            top: '55%',
            position: 'absolute',
        }} disableShrink />;
    }
    render() {
        const {subscription}=this.props;
        return(
            <CustomerDetails
                selectedSubscription={subscription}
                resetSelectedSubscription={this.props.onClose}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error, familyDetails: state.familyDetails, newPlan: state.newPlan, newCustomer: state.newCustomer };
};
export default connect(mapStateToProps, {getSubscriptionById})(CustomerDetailsRender);