import * as Sentry from '@sentry/react';
import { couponActionTypes } from "../constants/couponConstant";
import { errorActionTypes, authActionTypes } from "../constants/";
import { couponService } from "../clients/couponService";
import { buildCustomError } from "../common/utils";

const validateCoupon = (couponCode, body) => {
  const request = (code) => ({ type: couponActionTypes.VALIDATE_COUPON_REQUEST, payload: code });
  const success = (payload) => ({ type: couponActionTypes.VALIDATE_COUPON_SUCCESS, payload });
  const failure = () => ({ type: couponActionTypes.VALIDATE_COUPON_FAILURE });
  const resetSession = () => ({ type: authActionTypes.RESET_SESSION })
  const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
  return async (dispatch, getState) => {
    // TODO Error handling for session object doesn't exists.
    const accessToken = getState().auth.authDetails.data.access_token;
    dispatch(request(couponCode));
    try {
      const response = await couponService.validateCoupon(accessToken, couponCode, body);
      dispatch(success(response.data));
    } catch (e) {
      const error = buildCustomError(e);
      if (error.statusCode === 401) {
        dispatch(resetSession());
      } else {
        Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`),
        { extra: { errorData: error.data, function: 'validateCoupon', requestPayload: couponCode } });
      }
      dispatch(failure());
      dispatch(setError(error));
    }
  }
};

function resetCoupon() {
  const reset = () => ({ type: couponActionTypes.RESET_COUPON });
  return async dispatch => {
    dispatch(reset());
  }
}

export const couponAction = {
  validateCoupon,
  resetCoupon,
};