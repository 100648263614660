import { color as colorConstants } from "../../../../constants/colors";

const useStyles = () => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "2vh",
    padding: "0 4vh",
    width: 'calc(100% - 8vh)'
  },

  dateTimeContainer: {
    display: "flex",
    flexDirection: "column",
    width: 'calc(50% - 0.5vh)'
  },

  __button: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: "15px",
    textTransform: "capitalize",
    width: "100%",
    height: "53px",
    marginTop: "1vh",
    color: colorConstants.greys[150],
    border: `1px solid ${colorConstants.LIGHT_GREY}`,
    borderRadius: "8px",
    fontFamily: "Rubik",
  },

  caption: {
    fontSize: "12px",
    textTransform: "uppercase",
    color: colorConstants.greys[100],
    fontFamily: "Rubik",
    // display: 'inline-block',
    // width: 'max-content'
  },

  //Classes for Time slot selection popover

  timePopover: {
    width: "100%",
  },

  popoverContainer: {
    display: "flex",
    flexDirection: "column",
    width: "310px",
    border: `1px solid ${colorConstants.BORDER_GREY}`,
    borderRadius: "3px",
    padding: '0px 2vh',
    paddingBottom: '3vh',
    backgroundColor: colorConstants.LIGHT_GREY_BACKGROUND,
  },

  slot: {
    display: "flex",
    flexDirection: "column",
  },

  bookNowChip: {
    marginTop: '20px',
  },

  __chip: {
    minWidth: "90px",
    marginRight: "1vh",
    marginTop: "1vh",
    border: `1px solid ${colorConstants.ROYAL_ORANGE}`,
  },

  chipSelected: {
    backgroundColor: colorConstants.ROYAL_ORANGE,
    color: colorConstants.WHITE,
    borderColor: colorConstants.ROYAL_ORANGE,
    '&:focus': {
      backgroundColor: colorConstants.ROYAL_ORANGE,
    }
  },

  chipUnselected: {
    backgroundColor: colorConstants.WHITE,
    color: colorConstants.GREY,
    borderColor: colorConstants.GREY,
    '&:focus': {
      backgroundColor: colorConstants.WHITE,
    }
  },

  separatorContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '1vh',
},
  separator: {
      color: colorConstants.greys[100],
      fontSize: '13px',
      fontFamily: 'Rubik',
      fontWeight: '700',
      textTransform: 'uppercase',
      paddingTop: '1vh',
      paddingBottom: '1vh',
      width: '35%',
  },
  divider: {
      border: '0',
      backgroundColor: colorConstants.greys[100],
      height: '1px',
      width: '65%',
      margin: '8px',
  },

  // For Date picker text field
  datePicker: {
    display: "none",
  },
});

export default useStyles;
