import React from 'react';
import Svg from '../../components/common/Svg/Svg';
import Path from '../../components/common/Svg/Path';

const CopyIcon = ({ color = "#808080", variant = "outline" }) => {
    if (variant === "filled") {
        return (
          <Svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <Path
              d="M7.2001 2.09961C6.77575 2.09961 6.36878 2.26818 6.06873 2.56824C5.76867 2.8683 5.6001 3.27526 5.6001 3.69961V10.0996C5.6001 10.524 5.76867 10.9309 6.06873 11.231C6.36878 11.531 6.77575 11.6996 7.2001 11.6996H12.0001C12.4244 11.6996 12.8314 11.531 13.1315 11.231C13.4315 10.9309 13.6001 10.524 13.6001 10.0996V5.63081C13.6 5.2065 13.4314 4.7996 13.1313 4.49961L11.2001 2.56841C10.9001 2.26833 10.4932 2.0997 10.0689 2.09961H7.2001Z"
              fill={color}
            />
            <Path
              d="M2.3999 6.90078C2.3999 6.47643 2.56847 6.06947 2.86853 5.76941C3.16859 5.46935 3.57556 5.30078 3.9999 5.30078V13.3008H10.3999C10.3999 13.7251 10.2313 14.1321 9.93127 14.4322C9.63121 14.7322 9.22425 14.9008 8.7999 14.9008H3.9999C3.57556 14.9008 3.16859 14.7322 2.86853 14.4322C2.56847 14.1321 2.3999 13.7251 2.3999 13.3008V6.90078Z"
              fill={color}
            />
          </Svg>
        );
    }
    return (
        <Svg width="14" height="14" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path d="M3 2.5V6.5C3 6.76522 3.10536 7.01957 3.29289 7.20711C3.48043 7.39464 3.73478 7.5 4 7.5H7M3 2.5V1.5C3 1.23478 3.10536 0.98043 3.29289 0.792893C3.48043 0.605357 3.73478 0.5 4 0.5H6.293C6.4256 0.500028 6.55275 0.552725 6.6465 0.6465L8.8535 2.8535C8.94728 2.94725 8.99997 3.0744 9 3.207V6.5C9 6.76522 8.89464 7.01957 8.70711 7.20711C8.51957 7.39464 8.26522 7.5 8 7.5H7M3 2.5H2C1.73478 2.5 1.48043 2.60536 1.29289 2.79289C1.10536 2.98043 1 3.23478 1 3.5V8.5C1 8.76522 1.10536 9.01957 1.29289 9.20711C1.48043 9.39464 1.73478 9.5 2 9.5H6C6.26522 9.5 6.51957 9.39464 6.70711 9.20711C6.89464 9.01957 7 8.76522 7 8.5V7.5" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
        </Svg>

    )
};

export default CopyIcon;