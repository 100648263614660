import * as Sentry from '@sentry/react';
import { setAvailability, getAvailability } from '../clients';
import {
    authActionTypes, errorActionTypes,
} from '../constants';
const { buildCustomError } = require('../common/utils');

function setAvailabilityEvent(partnerId) {
    const request = () => ({ type: authActionTypes.SET_AVAILABILITY_REQUEST });
    const success = (payload) => ({ type: authActionTypes.SET_AVAILABILITY_SUCCESS, payload });
    const failure = () => ({ type: authActionTypes.SET_AVAILABILITY_FAILURE });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
    const resetError = () => ({ type: errorActionTypes.RESET_ERROR });

    return async (dispatch, getState) => {
        const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
        const AVAILABILITY = getState().auth.authDetails.data.user && getState().auth.authDetails.data.user.metadata ? getState().auth.authDetails.data.user.metadata.availability : false;
        dispatch(request());
        try {
            dispatch(resetError());
            const response = await setAvailability(partnerId, ACCESS_TOKEN, AVAILABILITY);
            dispatch(success(response.status));
        } catch (e) {
            const error = buildCustomError(e);
            Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`), { extra: error.data });
            dispatch(failure());
            dispatch(setError(error));
        }
    };
}

function getAvailabilityEvent(partnerId) {
    const request = () => ({ type: authActionTypes.GET_AVAILABILITY_REQUEST });
    const success = (payload) => ({ type: authActionTypes.GET_AVAILABILITY_SUCCESS, payload });
    const failure = () => ({ type: authActionTypes.GET_AVAILABILITY_FAILURE });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
    const resetError = () => ({ type: errorActionTypes.RESET_ERROR });

    return async (dispatch, getState) => {
        const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
        dispatch(request());
        try {
            dispatch(resetError());
            const response = await getAvailability(partnerId, ACCESS_TOKEN);
            dispatch(success(response.data));
        } catch (e) {
            const error = buildCustomError(e);
            dispatch(failure());
            // only dispatching errors in case this is not
            // a timeout. TODO: discuss this case of error handling!
            if (error.statusCode !== "ECONNABORTED") {
                dispatch(setError(error));
            }
        }
    };
}
// eslint-disable-next-line import/prefer-default-export
export const authActions = {
    setAvailabilityEvent,
    getAvailabilityEvent,
};
