import { combineReducers } from 'redux';
import customerReducer from './authReducer';
import errorReducer from './errorReducer';
import familyDetailsReducer from './familyDetailsReducer';
import consultationReducer from './consultationReducer';
import addNewCustomerReducer from './addNewCustomerReducer';
import addNewPlan from './addNewPlan';
import onBoarding from './onboardingReducer';
import updateCustomerCreditReducer from './updateCustomerCreditReducer';
import pwaDetails from './pwaSalesReducer';
import batchExtendSubscriptionReducer from './batchExtendSubscriptionReducer'
import paymentReducer from './paymentReducer';
import ticketingReducer from './ticketingReducer';
import couponReducer from './couponReducer';
import leadsReducer from './leadsReducer';

export default combineReducers({
   auth: customerReducer,
   error: errorReducer,
   familyDetails: familyDetailsReducer,
   consultationDetails: consultationReducer,
   newCustomer: addNewCustomerReducer,
   newPlan: addNewPlan,
   onBoarding: onBoarding,
   customerCredit: updateCustomerCreditReducer,
   pwaDetails: pwaDetails,
   batchExtendSubscription: batchExtendSubscriptionReducer,
   payment: paymentReducer,
   ticketing: ticketingReducer,
   coupon: couponReducer,
   lead: leadsReducer,
});