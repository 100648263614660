import React from 'react';
import Svg from '../../components/common/Svg/Svg';
import Path from '../../components/common/Svg/Path';

const Plus = ({ color = 'black' }) => {
    return (
        <Svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Path d="M4.48925 8.972C4.37192 8.972 4.27592 8.93467 4.20125 8.86C4.12658 8.78533 4.08925 8.68933 4.08925 8.572V5.644H1.24125C1.12392 5.644 1.02258 5.60667 0.93725 5.532C0.862583 5.45733 0.82525 5.36133 0.82525 5.244V4.076C0.82525 3.95867 0.862583 3.86267 0.93725 3.788C1.02258 3.71333 1.12392 3.676 1.24125 3.676H4.08925V0.827999C4.08925 0.710666 4.12658 0.614666 4.20125 0.54C4.27592 0.465333 4.37192 0.427999 4.48925 0.427999H5.80125C5.90792 0.427999 5.99858 0.465333 6.07325 0.54C6.15858 0.614666 6.20125 0.710666 6.20125 0.827999V3.676H9.03325C9.15058 3.676 9.24658 3.71333 9.32125 3.788C9.39592 3.86267 9.43325 3.95867 9.43325 4.076V5.244C9.43325 5.36133 9.39592 5.45733 9.32125 5.532C9.24658 5.60667 9.15058 5.644 9.03325 5.644H6.20125V8.572C6.20125 8.68933 6.15858 8.78533 6.07325 8.86C5.99858 8.93467 5.90792 8.972 5.80125 8.972H4.48925Z" fill={color}/>
        </Svg>

    );
}

export default Plus;
