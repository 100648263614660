import axios from 'axios';
import { URL } from '../constants/cdn';

export const getAllOcupationList = async () => {
    const options = {
      headers: {
        'content-type': 'application/json',
      },
      timeout: 10000,
      responseType: 'json',
      responseEncoding: 'utf8',
    };
    const response = await axios.get(URL.occupations, options);
    return response.data;
  };