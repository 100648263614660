import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Step, StepConnector, Stepper, StepLabel, CircularProgress, Divider, Button, Tooltip } from '@material-ui/core';
import AddMobileNumber from './StepComponents/AddMobileNumber';
import AddPrimaryDetails from './StepComponents/AddPrimaryDetails';
import SelectPrimaryMember from './StepComponents/SelectPrimaryMember';
import SelectNominee from './StepComponents/SelectNominee';
import ReviewOrder from './StepComponents/ReviewOrder';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { createHeadSubscriber, resetCreateSubscription, setNewPlanMobileNumber, resetNewPlanMobileNumber, resetCreateHeadSubscriber } from '../../actions';
const { NOT_FOUND, CREATED } = require('http-status-codes');


const styles = theme => ({
    root: {
        width: '92vw',
        backgroundColor: 'white',
        height: '81vh',
        position: 'relative',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
        backgroundColor: '#ffe6b1',
    },
    iconContainer: {
        transform: 'scale(1.3)',
    },
    connectorActive: {
        '& $connectorLine': {
            borderColor: '#f38b44',
        },
    },
    connectorCompleted: {
        '& $connectorLine': {
            borderColor: '#f38b44',
        },
    },
    connectorDisabled: {
        '& $connectorLine': {
            borderColor: '#ffe6b1',
        },
    },
    connectorLine: {
        borderColor: '#ffe6b1',
        marginRight: '2vh',
        marginLeft: '2vh',
        marginTop: '2vh',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

});

const muiTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiStepIcon: {
            root: {
                marginTop: '2vh',
                marginLeft: '4vh',
                marginRight: '4vh',
                color: '#ffe6b1',
                '& $text': {
                    fill: '#f38b44',
                    fontFamily: 'Rubik-Medium',
                },

                '&$active': {
                    fill: '#f38b44',
                    '& $text': {
                        fill: 'white',
                    },
                },
                '&$completed': {
                    marginLeft: '4vh',
                    marginRight: '4vh',
                    color: '#47B29F',
                },
            },
        },
    }
});



class CustomerDetails extends React.Component {

    componentDidMount() {
        this.props.resetNewPlanMobileNumber();
        this.props.resetCreateHeadSubscriber();
    };

    state = {
        activeStep: 0,
        subscribeButtonPressed: false,
        mobileNumber: '',
        retypedMobileNumber: '',
        name: '',
        dob: '',
        lop: '',
        pincode: '',
        selectedPrimaryCustomerId: '',
        selectedNomineeCustomerId: '',
        nomineeRelationshipWithPrimary: '',
    };

    productHasAccidentalOrHealth = () => {
        return _.hasIn(this.props.productInCart, 'health_insurance') || _.hasIn(this.props.productInCart, 'accidental_insurance')
    }

    getSteps = () => {
        if (this.productHasAccidentalOrHealth()) { return ['Mobile Number', 'Customer Details', 'Primary Member', 'Nominee', 'Review Order']; }
        else { return ['Mobile Number', 'Customer Details', 'Primary Member', 'Review Order']; }
    }

    nextButtonDisabled = () => {
        if ((this.state.activeStep === 0 && this.state.mobileNumber === this.state.retypedMobileNumber) && this.state.mobileNumber.length === 10 && this.state.retypedMobileNumber.length === 10) {
            return false;
        } else if ((this.state.activeStep === 1 && this.state.name && this.state.dob && this.state.gender && this.state.lop) || (this.state.activeStep === 1 && this.props.newPlan && this.props.newPlan.customerByPhoneNumber !== NOT_FOUND)) {
            return false;
        } else if (this.state.activeStep === 2 && this.state.selectedPrimaryCustomerId) {
            return false;
        } else if (this.state.activeStep === 3 && this.state.selectedNomineeCustomerId && this.state.nomineeRelationshipWithPrimary && this.productHasAccidentalOrHealth()) {
            return false;
        } else if (this.state.activeStep === 3 && !this.productHasAccidentalOrHealth()) {
            return false;    //This is for product without accidental/health
        } else if (this.state.activeStep === 4) {
            return false;
        }
        return true;
    }

    getStepComponent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return <AddMobileNumber setParentState={this.setParentState} mobileNumber={this.state.mobileNumber} retypedMobileNumber={this.state.retypedMobileNumber} />;
            case 1:
                return <AddPrimaryDetails setParentState={this.setParentState} mobileNumber={this.state.mobileNumber} name={this.state.name} dob={this.state.dob} gender={this.state.gender} lop={this.state.lop} pincode={this.state.pincode} />;
            case 2:
                return <SelectPrimaryMember setParentState={this.setParentState} selectedPrimaryCustomerId={this.state.selectedPrimaryCustomerId}/>;
            case 3:
                return this.productHasAccidentalOrHealth() ? <SelectNominee setParentState={this.setParentState} selectedPrimaryCustomerId={this.state.selectedPrimaryCustomerId} selectedNomineeCustomerId={this.state.selectedNomineeCustomerId} nomineeRelationshipWithPrimary={this.state.nomineeRelationshipWithPrimary} /> : <ReviewOrder mobileNumber={this.state.mobileNumber} subscribeButtonPressed={this.state.subscribeButtonPressed} closeSubscribeDrawer={this.closeSubscribeDrawer} productInCart={this.props.productInCart} selectedPaymentMultiplier={this.props.selectedPaymentMultiplier} selectedPrimaryCustomerId={this.state.selectedPrimaryCustomerId} />;
            case 4:
                return <ReviewOrder mobileNumber={this.state.mobileNumber} subscribeButtonPressed={this.state.subscribeButtonPressed} closeSubscribeDrawer={this.closeSubscribeDrawer} productInCart={this.props.productInCart} selectedPaymentMultiplier={this.props.selectedPaymentMultiplier} selectedPrimaryCustomerId={this.state.selectedPrimaryCustomerId} selectedNomineeCustomerId={this.state.selectedNomineeCustomerId} nomineeRelationshipWithPrimary={this.state.nomineeRelationshipWithPrimary} />;
            default:
                return <div>Something went wromg !!</div>;
        }
    }

    getCircularProgress = () => {
        return <CircularProgress style={{
            color: 'orange',
            left: '50%',
            top: '55%',
            position: 'absolute',
        }} disableShrink />;
    }

    setParentState = (stateName, stateValue) => {
        if (stateName === 'mobileNumber') { this.setState({ mobileNumber: stateValue }) }
        if (stateName === 'retypedMobileNumber') { this.setState({ retypedMobileNumber: stateValue }) }
        if (stateName === 'name') { this.setState({ name: stateValue }) }
        if (stateName === 'dob') { this.setState({ dob: stateValue }) }
        if (stateName === 'gender') { this.setState({ gender: stateValue }) }
        if (stateName === 'lop') { this.setState({ lop: stateValue }) }
        if (stateName === 'pincode') { this.setState({ pincode: stateValue }) }
        if (stateName === 'selectedPrimaryCustomerId') { this.setState({ selectedPrimaryCustomerId: stateValue }) }
        if (stateName === 'selectedNomineeCustomerId') { this.setState({ selectedNomineeCustomerId: stateValue }) }
        if (stateName === 'nomineeRelationshipWithPrimary') { this.setState({ nomineeRelationshipWithPrimary: stateValue }) }
    }

    closeSubscribeDrawer = () => {
        this.setState({ subscribeButtonPressed: false });
        this.props.resetNewPlanMobileNumber();
        this.props.resetCreateSubscription();
    }

    createHeadSubscriberAndIncrementStepOrJustIncrement = () => {
        if (this.props.newPlan && this.props.newPlan.customerByPhoneNumber && this.props.newPlan.customerByPhoneNumber.id) {
            this.setState({ activeStep: 2 });
        } else {
            const newCustomer = {
                first_name: this.state.name,
                dob: this.state.dob ? this.state.dob.getTime() : '',
                primary_number: this.state.mobileNumber,
                gender: this.state.gender,
                business_contact_number: this.state.mobileNumber,
                lop: this.state.lop,
            }
            const createHeadSubscriberPromise = this.props.createHeadSubscriber(newCustomer);
            createHeadSubscriberPromise.then(() => {
                if (this.props.newCustomer && this.props.newCustomer.createHeadSubscriber && this.props.newCustomer.createHeadSubscriber.newCustomerStatus === CREATED) {
                    this.setState({ activeStep: 2 });
                }
            })
        }
    }

    handleNext = () => {
        if (this.state.activeStep === 0) {
            this.nextButtonDisabled() ? this.setState({ activeStep: 0 }) : this.setState({ activeStep: 1 })
        } else if (this.state.activeStep === 1) {
            this.nextButtonDisabled() ? this.setState({ activeStep: 1 }) : this.createHeadSubscriberAndIncrementStepOrJustIncrement();
        } else if (this.state.activeStep === 2) {
            this.nextButtonDisabled() ? this.setState({ activeStep: 2 }) : this.setState({ activeStep: 3 })
        } else if (this.state.activeStep === 3 && this.productHasAccidentalOrHealth()) {
            this.nextButtonDisabled() ? this.setState({ activeStep: 3 }) : this.setState({ activeStep: 4 })
        } else if (this.state.activeStep === 3 && !this.productHasAccidentalOrHealth()) {
            this.setState({ subscribeButtonPressed: true }, () => this.props.setNewPlanMobileNumber(this.state.mobileNumber))
        } else if (this.state.activeStep === 4) {
            this.setState({ subscribeButtonPressed: true }, () => this.props.setNewPlanMobileNumber(this.state.mobileNumber))
        } else {
            this.setState(state => ({
                activeStep: state.activeStep + 1,
            }));
        };
    };

    handleBack = () => {
        if (this.state.activeStep === 0) {
            this.props.exitAddCustomer();
        } else {
            this.setState(state => ({
                activeStep: state.activeStep - 1,
            }));
        }
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,
        });
    };

    getNextButtonHelperText = () => {
        switch (this.state.activeStep) {
            case 0:
                return 'Please enter valid 10 digit mobile number'
            case 1:
                return 'Please enter name, DOB, Preferred Language and gender before proceeding'
            case 2:
                return 'Please select the Primary member'
            case 3:
                return 'Please select the Nominee'
            default:
                return " "
        }
    }

    render() {
        const { classes } = this.props;
        const steps = this.getSteps();
        const { activeStep } = this.state;
        const connector = (
            <StepConnector
                classes={{
                    active: classes.connectorActive,
                    completed: classes.connectorCompleted,
                    disabled: classes.connectorDisabled,
                    line: classes.connectorLine,
                }}
            />
        );

        return (
            <div className={classes.root}>
                <MuiThemeProvider theme={muiTheme}>
                    <Stepper activeStep={activeStep} alternativeLabel connector={connector}>
                        {steps.map(label => (
                            <Step key={label}>
                                <StepLabel classes={{ // apply this style
                                    iconContainer: classes.iconContainer
                                }}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </MuiThemeProvider>
                <div>
                    <Divider style={{ backgroundColor: '#f38b44', marginLeft: '4vw', marginRight: '4vw', height: '2px' }} />
                    {this.getStepComponent(activeStep)}
                    <Divider style={{ height: '2px' }} />
                    <div style={{ marginTop: '2vh', marginBottom: '2vh' ,height: '5vh', overflowY: 'auto'}}>
                        {/* {this.state.activeStep === steps.length ? (
                            <div>
                                <Typography className={classes.instructions}>All steps completed</Typography>
                                <Button onClick={this.handleReset}>Reset</Button>
                            </div>
                        ) : ( */}
                                    <div >
                                        {this.state.activeStep !== 2 && <Button
                                            variant="outlined"
                                            style={{ left: '5vw', width: '9vw', textTransform: 'capitalize', borderColor: '#f38b44', color: '#f38b44' , position: 'absolute' }}
                                            // disabled={activeStep === 0}
                                            onClick={this.handleBack}
                                            className={classes.backButton}
                                        >
                                            {this.state.activeStep === 0 ? 'Exit' : 'Back'}
                                        </Button>}

                                        <Tooltip title={this.nextButtonDisabled() ? this.getNextButtonHelperText() : ''}>
                                            <Button
                                                style={{ cursor: this.nextButtonDisabled() ? 'help' : 'pointer', right: '5vw', width: '9vw', backgroundColor: this.nextButtonDisabled() ? 'grey' : '#f38b44', textTransform: 'capitalize', position: 'absolute' }}
                                                variant="contained" color="primary"
                                                onClick={this.handleNext}>
                                                {activeStep === steps.length - 1 ? 'Subscribe' : 'Next'}
                                            </Button>
                                        </Tooltip>
                                    </div>
                              
                            {/* )} */}
                    </div>


                </div>
            </div>
        );
    }
}

CustomerDetails.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error, familyDetails: state.familyDetails, newPlan: state.newPlan, newCustomer: state.newCustomer };
};

export default connect(mapStateToProps, { createHeadSubscriber, resetCreateSubscription, setNewPlanMobileNumber, resetNewPlanMobileNumber, resetCreateHeadSubscriber })(withStyles(styles)(CustomerDetails));