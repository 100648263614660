import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Box, Typography, Button, TextField, CircularProgress, Checkbox } from "@material-ui/core";
import { getProductPriceAfterDiscount } from "../../../common/utils";
import { isEmpty, reduce, has } from "lodash";
import style from "./style";
import CloseIcon from "@material-ui/icons/Close";
import {
  sendRenewalReminder,
  resetPayment,
  sendNotificationEvent,
  createShortLink,
  payInvoicesByCredit,
} from "../../../actions/paymentAction";
import {
  getCreditDetailsByFamilyId,
  resetErrors,
  setError,
  getInvoicesByFamily,
} from "../../../actions";
import { color } from "../../../constants/colors";
import { strings } from "../../../constants/strings";
import { notification } from "../../../config/notification";
import moment from "moment";
import { ReactComponent as PlansIcon } from "./../../../assets/icons/plans.svg";
import CopyIcon from "../../../assets/icons/copyIcon";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";

class RenewalsDrawer extends Component {
  constructor() {
    super();
    this.state = {
      invoicesToRenew: [],
      intent: "notify",
      phoneNumberHelperText: "",
      channel: "sms",
      showSnackbar: false,
      snackbarMessage: "",
      useCredit: false,
    };
    this.phoneNumberRef = React.createRef(null);
  }

  componentDidMount() {
    const { familyDetails } = this.props;
    const subscribersWithRelationshipSelf = familyDetails.allMembers.members.filter((member) => {
      return member.relationship === "self";
    });
    const headSubscriber = subscribersWithRelationshipSelf[0];
    this.phoneNumberRef.current.value = headSubscriber.primary_number;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      payment,
      error,
      getCreditDetailsByFamilyId,
      familyDetails,
      getInvoicesByFamily,
    } = this.props;
    const { useCredit } = this.state;
    const oldPaymentState = prevProps.payment;
    if (
      payment.hasOwnProperty("notification") &&
      oldPaymentState.notification !== payment.notification &&
      !payment.notification.isLoading &&
      payment.notification.status === "success"
    ) {
      this.setState({
        showSnackbar: true,
        snackbarMessage: "Link sent successfully!",
      });
    }

    if (
      oldPaymentState.shortLink !== payment.shortLink &&
      !payment.shortLink?.isLoading &&
      payment.shortLink?.status === "success" &&
      this.state.intent === "copy"
    ) {
      const shortlink = payment.shortLink?.data?.payments[0].metadata.short_url;
      navigator.clipboard.writeText(shortlink).then(() => {
        this.setState({
          showSnackbar: true,
          snackbarMessage: "Link copied to clipboard!",
        });
      });
      if (useCredit) {
        getCreditDetailsByFamilyId(familyDetails.currentCustomer.family.id);
      }
    }

    if (
      oldPaymentState.creditDeductionResponse !== payment.creditDeductionResponse &&
      payment.creditDeductionResponse !== "failed" &&
      useCredit
    ) {
      this.setState({
        showSnackbar: true,
        snackbarMessage: "Payment successful!",
        useCredit: false,
      });
      getCreditDetailsByFamilyId(familyDetails.currentCustomer.family.id);
      getInvoicesByFamily(familyDetails.currentCustomer.family.id);
    }

    if (error && error.error && prevProps.error !== error) {
      let errorMessage = "An error occured while sending the message. Please try again.";
      if (error.error) {
        if (typeof error.error === "object") {
          // could be a network error or axios error
          errorMessage = error.error.message;
        } else {
          errorMessage = error.error;
        }
      }
      this.setState({
        showSnackbar: true,
        snackbarMessage: errorMessage,
      });
    }
  }

  componentWillUnmount() {
    const { resetPayment } = this.props;
    resetPayment();
  }

  getCircularProgress = () => {
    return (
      <CircularProgress
        style={{
          color: color.WHITE,
          scale: "0.5",
        }}
      />
    );
  };

  /**
   * Iterate through the array of invoices pending renewal and trigger renewal invoices;
   * If enough Clinikk Cash is available, use it to make the payment;
   */
  sendRenewalReminder = () => {
    const {
      sendRenewalReminder,
      payment,
      sendNotification,
      familyDetails,
      payInvoicesByCredit,
    } = this.props;
    const { invoicesToRenew, channel, useCredit } = this.state;
    const phoneNumber = this.phoneNumberRef.current.value;
    if (!isEmpty(invoicesToRenew)) {
      const availableCredit = familyDetails.creditDetail?.amount ?? 0;
      const payableTotal = this.getTotalAmountPayable(invoicesToRenew);
      const creditToBlock = Math.min(availableCredit, payableTotal);
      this.setState({
        intent: "notify",
      });
      if (creditToBlock === payableTotal) {
        // payment to be made entirely in Clinikk Cash;
        payInvoicesByCredit(invoicesToRenew);
      } else {
        // in any other case we will have to charge the customer
        // using a link;
        if (!payment?.shortLink?.data) {
          if (useCredit) {
            sendRenewalReminder(invoicesToRenew, creditToBlock, phoneNumber, channel);
          } else {
            sendRenewalReminder(invoicesToRenew, 0, phoneNumber, channel);
          }
        } else {
          sendNotification(invoicesToRenew, phoneNumber, channel);
        }
      }
    }
  };

  handleInvoiceCheckboxToggle = (event, invoice) => {
    const { invoicesToRenew, useCredit } = this.state;
    const { resetPayment } = this.props;
    const copyOfInvoices = [...invoicesToRenew] ?? [];
    if (event.target.checked) {
      copyOfInvoices.push(invoice);
      this.setState({
        invoicesToRenew: copyOfInvoices,
        channel: "sms",
      });
    } else {
      let filteredInvoicesToRenew = copyOfInvoices.filter(
        (invoiceObj) => invoiceObj.id !== invoice.id
      );
      this.setState({
        invoicesToRenew: filteredInvoicesToRenew,
        channel: "sms",
      });
      if (filteredInvoicesToRenew.length === 0 && useCredit) {
        this.setState({
          useCredit: !useCredit,
        });
      }
    }
    resetPayment();
  };

  getTotalAmountPayable = (invoices) => {
    if (isEmpty(invoices)) {
      return 0;
    } else
      return reduce(
        invoices,
        (sum, invoice) =>
          sum +
          getProductPriceAfterDiscount(
            invoice?.subscription?.product,
            invoice.subscription.payment_multiplier
          ),
        0
      );
  };

  renderRenewalTypeBadge = (invoice) => {
    const { classes } = this.props;
    if (has(invoice, "due_date")) {
      return <div className={classes.renewalBadge}>{strings.RENEWAL}</div>;
    } else {
      return <div className={classes.newPurchaseBadge}>{strings.NEW_PURCHASE}</div>;
    }
  };

  renderRenewalForm = () => {
    const {
      classes,
      payment,
      closeDrawer,
      invoices,
      createShortLink,
      setError,
      familyDetails,
    } = this.props;
    const { channel, invoicesToRenew, useCredit, intent, showSnackbar } = this.state;

    const getApplicableCredit = () => {
      const availableCredit = familyDetails.creditDetail?.amount ?? 0;
      const payableTotal = this.getTotalAmountPayable(invoicesToRenew);
      return Math.min(payableTotal, availableCredit);
    };

    const getSubmitButtonText = () => {
      const { intent } = this.state;
      const payableTotal = this.getTotalAmountPayable(invoicesToRenew);
      if (payment.shortLink?.isLoading) {
        if (useCredit && payableTotal === getApplicableCredit()) {
          return "Pay using Clinikk Cash";
        } else {
          // no payment link exists
          if (!payment.shortLink?.data?.payments[0].metadata.short_url) {
            return `Send link now: ₹${
              useCredit
                ? this.getTotalAmountPayable(invoicesToRenew) - getApplicableCredit()
                : this.getTotalAmountPayable(invoicesToRenew)
            }`;
            // a payment link exists - derive the amount directly from the link
          }
        }
      }
      if (useCredit && payableTotal === getApplicableCredit()) {
        return "Pay using Clinikk Cash";
      } else {
        // no payment link exists
        if (!payment.shortLink?.data?.payments[0].metadata.short_url || intent !== "notify") {
          return `Send link now: ₹${
            useCredit
              ? this.getTotalAmountPayable(invoicesToRenew) - getApplicableCredit()
              : this.getTotalAmountPayable(invoicesToRenew)
          }`;
          // a payment link exists - derive the amount directly from the link
        } else if (payment.shortLink?.data?.payments[0].metadata.short_url && payment.shortLink?.status === "success") {
          return `Send link again: ₹${payment.shortLink?.data?.payments[0].amount}`;
        }
      }
    };

    const getCopyButtonText = () => {
      const { intent } = this.state;
      const { payment } = this.props;
      if (intent === "copy" && payment.shortLink?.isLoading) return "Copying link...";
      return "Copy link";
    };

    /**
     * onClick handler for the Copy Link chip
     */
    const handleCopyLinkOnClick = () => {
      this.setState({
        intent: "copy",
      });
      if (payment.shortLink === null) {
        const availableCredit = familyDetails.creditDetail?.amount ?? 0;
        const payableTotal = this.getTotalAmountPayable(invoicesToRenew);
        const creditToBlock = Math.min(availableCredit, payableTotal);
        if (useCredit) {
          createShortLink(invoicesToRenew, creditToBlock);
        } else {
          createShortLink(invoicesToRenew, 0);
        }
      }
      if (payment.shortLink?.status === "failed") {
        setError("Nothing to copy!");
      } else {
        const shortlink = payment.shortLink?.data?.payments[0].metadata.short_url;
        if (shortlink) {
          navigator.clipboard.writeText(shortlink).then(() => {
            this.setState({
              showSnackbar: true,
              snackbarMessage: "Link copied to clipboard!",
            });
          });
        }
      }
    };

    const handleCreditCheckboxOnClick = (e) => {
      this.setState(
        {
          useCredit: !useCredit,
        },
        () => {
          // handle the case where a shortlink has already been created
          // and then the checkbox is toggled - in this case a reset is required!
          if (invoicesToRenew.length !== 0 && payment.shortLink?.data !== null) {
            resetPayment();
          }
        }
      );
    };

    if (invoices === null || (invoices && invoices.length === 0)) {
      return (
        <div className={classes.container}>
          <Box className={classes.header}>
            <Typography className={classes.headerText}>Pending payments reminders</Typography>
            <CloseIcon onClick={closeDrawer} />
          </Box>
          <div className={classes.body}>
            <PlansIcon />
            <span className={classes.placeholderText}>No payments pending.</span>
          </div>
        </div>
      );
    }

    const handlePhoneInputChange = (event) => {
      const eventTarget = event.target;
      const updatedValue = event.target.value;
      if (eventTarget.timeout) {
        clearTimeout(eventTarget.timeout);
      }
      eventTarget.timeout = setTimeout(() => {
        if (updatedValue.length !== 10) {
          this.setState({
            phoneNumberHelperText: "Please enter a 10-digit number",
          });
        } else {
          this.setState({
            phoneNumberHelperText: "",
          });
        }
      }, 1500);
    };
    return (
      <div className={classes.container}>
        <Box className={classes.header}>
          <Typography className={classes.headerText}>Pending payments reminders</Typography>
          <CloseIcon onClick={closeDrawer} />
        </Box>
        <div className={classes.body}>
          <Box className={classes.creditCheckbox}>
            <Box className={classes.invoiceColumn}>
              <Checkbox
                checked={useCredit}
                disabled={invoicesToRenew.length === 0 || familyDetails.creditDetail.amount === 0}
                classes={{
                  root: classes.checkbox,
                  checked: classes.checked,
                }}
                onChange={handleCreditCheckboxOnClick}
              />
            </Box>
            <Box className={classes.invoiceColumn}>
              <label
                className={
                  invoicesToRenew.length > 0 ? classes.creditLabel : classes.creditLabelDisabled
                }
              >
                Use <strong>₹{getApplicableCredit()}</strong> Clinikk Cash of &nbsp;
                <strong>₹{familyDetails.creditDetail.amount}</strong> available.
                <strong>
                  This will{" "}
                  {this.getTotalAmountPayable(invoicesToRenew) <= getApplicableCredit()
                    ? "deduct"
                    : "block"}{" "}
                  ₹{getApplicableCredit()} from the customer's Clinikk Cash.
                </strong>
              </label>
            </Box>
          </Box>
          <Box className={classes.invoiceList}>
            {invoices.map((invoice) => {
              return (
                <Box className={classes.invoiceContainer} key={invoice.id}>
                  <Box className={classes.invoiceColumn}>
                    <Checkbox
                      classes={{
                        root: classes.checkbox,
                        checked: classes.checked,
                      }}
                      onChange={(event) => {
                        this.handleInvoiceCheckboxToggle(event, invoice);
                      }}
                      disabled={invoice.v === 1}
                    />
                  </Box>
                  <Box className={classes.invoiceColumn}>
                    <Box className={classes.invoiceHeader}>
                      <Box className={classes.descriptiveContainer}>
                        <Typography className={classes.smallLabel}>Plan name</Typography>
                        <Typography className={classes.bodyHeader}>
                          {invoice.subscription.product.name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className={classes.invoiceRow}>
                      <Typography className={classes.smallLabel}>Amount due</Typography>
                      <Typography className={classes.bodyHeader}>
                        {getProductPriceAfterDiscount(
                          invoice.subscription.product,
                          invoice.subscription.payment_multiplier
                        )}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.invoiceColumn}>
                    {this.renderRenewalTypeBadge(invoice)}
                    {invoice.due_date && (
                      <Box className={classes.invoiceRow}>
                        <Typography className={classes.smallLabel}>Due date</Typography>
                        <Typography className={classes.bodyHeader}>
                          {moment(invoice.due_date).calendar()}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box className={classes.linkOptions}>
            <strong>Send Via</strong>
            <ToggleButtonGroup
              value={channel}
              onChange={(event) => {
                this.setState({
                  channel: event.target.value,
                });
              }}
              variant="filled"
              classes={{
                grouped: classes.toggle,
              }}
              exclusive
              fullWidth
            >
              <ToggleButton
                value="sms"
                classes={{
                  selected: classes.toggleSelected,
                }}
              >
                SMS
              </ToggleButton>
              <ToggleButton
                value="whatsapp"
                classes={{
                  selected: classes.toggleSelected,
                }}
              >
                WhatsApp
              </ToggleButton>
            </ToggleButtonGroup>
            {/* <Box flex={{ gap: '4px' }} flexDirection="column"> */}
            <TextField
              inputRef={this.phoneNumberRef}
              inputProps={{
                className: classes.phoneInput,
                maxLength: 10,
              }}
              type="number"
              onChange={handlePhoneInputChange}
              helperText={this.state.phoneNumberHelperText}
              FormHelperTextProps={{
                className: classes.invalidInputWarning,
              }}
              fullWidth
            />
            <Tooltip
              open={showSnackbar && intent === "notify"}
              title={this.state.snackbarMessage}
              placement="top"
              onClose={this.closeTooltip}
              classes={{
                tooltipArrow: classes.tooltip,
                arrow: classes.arrow,
                tooltip: classes.tooltip,
              }}
              //   arrow
            >
              <Button
                disabled={
                  isEmpty(this.state.invoicesToRenew) ||
                  this.state.phoneNumberHelperText !== "" ||
                  payment.shortLink?.isLoading ||
                  payment.notification?.isLoading
                  //   invoicesToRenewHasV1Invoices === true
                }
                className={classes.sendLinkButton}
                onClick={() => {
                  this.sendRenewalReminder();
                }}
                startIcon={
                  (payment.shortLink?.isLoading || payment.notification?.isLoading) &&
                  this.getCircularProgress()
                }
                fullWidth
              >
                {getSubmitButtonText()}
              </Button>
            </Tooltip>
            {/* </Box> */}
          </Box>
          <Box className={classes.copyLinkContainer}>
            <Tooltip
              open={showSnackbar && intent === "copy"}
              title={this.state.snackbarMessage}
              placement="top"
              onClose={this.closeTooltip}
              classes={{
                tooltipArrow: classes.tooltip,
                arrow: classes.tooltip,
                tooltip: classes.tooltip,
              }}
              //   arrow
            >
              <Button
                disabled={
                  isEmpty(this.state.invoicesToRenew) || this.state.phoneNumberHelperText !== ""
                  //   invoicesToRenewHasV1Invoices === true
                }
                className={classes.copyLinkButton}
                onClick={handleCopyLinkOnClick}
                startIcon={<CopyIcon color="#1F2A37" variant="filled" />}
                fullWidth
              >
                {getCopyButtonText()}
              </Button>
            </Tooltip>
          </Box>
          {channel === notification.channels.WHATSAPP ? (
            <Typography className={classes.whatsAppConsentInfo}>
              The user will be sent their prescriptions, notifications, reminders and any other{" "}
              <b>WhatsApp</b> communication on this number!
            </Typography>
          ) : null}
        </div>
      </div>
    );
  };

  closeTooltip = () => {
    const { resetErrors } = this.props;
    this.setState(
      {
        showSnackbar: false,
      },
      () => {
        // the error should not get reset before the snackbar closes
        // else, the snackbar will change background colors before it is hidden
        setTimeout(() => {
          resetErrors();
        }, 300);
      }
    );
  };

  render() {
    return this.renderRenewalForm();
  }
}

const mapStateToProps = (state) => {
  const { familyDetails, payment, error } = state;
  return {
    familyDetails,
    payment,
    error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendRenewalReminder: (invoices, creditToBlock, phoneNumber, channel) => {
    dispatch(sendRenewalReminder(invoices, creditToBlock, phoneNumber, channel));
  },
  sendNotification: (invoices, phoneNumber, channel) => {
    dispatch(sendNotificationEvent(invoices, phoneNumber, channel));
  },
  resetPayment: () => {
    dispatch(resetPayment());
  },
  resetErrors: () => {
    dispatch(resetErrors());
  },
  setError: (error) => {
    dispatch(setError(error));
  },
  createShortLink: (invoices, creditToBlock) => {
    dispatch(createShortLink(invoices, creditToBlock));
  },
  getCreditDetailsByFamilyId: (familyId) => {
    dispatch(getCreditDetailsByFamilyId(familyId));
  },
  payInvoicesByCredit: (invoices) => {
    dispatch(payInvoicesByCredit(invoices));
  },
  getInvoicesByFamily: (familyId) => {
    dispatch(getInvoicesByFamily(familyId));
  },
});

const reduxWrapper = connect(mapStateToProps, mapDispatchToProps);
const styleWrapper = withStyles(style, { withTheme: true });
export default styleWrapper(reduxWrapper(RenewalsDrawer));
