import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box, Typography, Button, ClickAwayListener } from "@material-ui/core";
import styles from "./style";
import { cancelPlan, updateTNC } from '../../../actions';
import { checkIfManager } from '../../../common/utils';
import { tncStatus } from "../../../common/constants";

class ConfirmationDrawer extends React.Component {
  constructor () {
    super();
    this.state = {
      confirmationText: 'The customer will not be eligible for Clinikk Healthcare Plans due to the selected health conditions.',
      renderMain: true
    }
  }

  componentDidMount() {
    const { confirmationText } = this.props;
    this.setState({
      confirmationText
    })
  }

  renderSubmitRequest() {
    const { classes } = this.props;
    const { confirmationText } = this.state;
    return (
      <Box className={classes.contentContainer}>
            <Box className={classes.textContainer}>
              <Box className={classes.circle}>
              <CheckIcon className={classes.checkIcon} />
              </Box>
          <Typography className={classes.confirmationTitle}>{'Request Sent'}</Typography>

          <Typography className={classes.confirmationSubTitle}>
            {confirmationText}
          </Typography>
          </Box>
        </Box>
    );
  }
  
  renderMain() {
    const {
      classes, title, subTitle, cancelPlan, selectedSubscription,
      handleDrawerClose, auth, updateTNCEvent, familyDetails,
    } = this.props;
    const familyId = familyDetails.currentCustomer.family.id;
    return (
      <Box className={classes.contentContainer}>
            <Box className={classes.ghdQuestionsContainer}>
          <Typography className={classes.confirmationTitle}>{title}</Typography>

          <Typography className={classes.confirmationSubTitle}>
              {
								subTitle?.map((item) => (
									<Box style={{ display: 'flex' }}>
										<ul className={classes.ghdCardSubHeading}>
											<li>{item.title}</li>
										</ul>
									</Box>
							))}
          </Typography>
          </Box>
        <Box className={classes.buttonContainer}>
          <Button
            variant="outlined"
            style={{
              width: "9vw",
              textTransform: "capitalize",
              borderColor: "#f38b44",
              color: "#f38b44",
            }}
            onClick={() => handleDrawerClose()}
            className={classes.backButton}
          >
            No
          </Button>
          <Button
            style={{
              backgroundColor: "#f38b44",
              textTransform: "capitalize",
            }}
            variant="contained"
            color="primary"
            onClick={() => {
              checkIfManager(auth?.authDetails?.data?.user?.roles)
              ? cancelPlan(selectedSubscription.id, familyId)
              : updateTNCEvent(selectedSubscription.id, familyId, tncStatus.FAILED)
              this.updateConfirmationText();
            }} //changed boolean `false` to string 'failed'
          >
            Submit Cancel Request
          </Button>
        </Box>
        </Box>
    );
  }

  updateConfirmationText() {
    this.setState({
      confirmationText: 'The Operations Manager will approve the request based on the PED',
      renderMain: false,
    })
  }

  render() {
    const { classes, heading, handleDrawerClose, newPlan, familyDetails } = this.props;
    const { renderMain }  = this.state;
    return (
      <ClickAwayListener onClickAway={() => handleDrawerClose()}>
        <Box className={`${classes.confirmationContainer}`}>
          <Box className={classes.headerContainer}>
            <Typography className={classes.preferenceCardHeading}>
              {heading}
            </Typography>
            <CloseIcon onClick={() => handleDrawerClose()} style={{ color: '#172A3A', cursor: 'pointer' }}/>
          </Box>
            {renderMain && !newPlan.customerByPhoneNumber.cancelSubcriptionPlan && this.renderMain()}
            { newPlan.customerByPhoneNumber.cancelSubcriptionPlan && this.renderSubmitRequest() }
            { familyDetails?.tncDetails?.accepted === 'failed' && this.renderSubmitRequest() }
        </Box>
      </ClickAwayListener>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  cancelPlan: (subscriptionId, familyId) => {
    dispatch(cancelPlan(subscriptionId, familyId));
  },
  updateTNCEvent: (subscriptionId, familyId, tncStatus) => {
    dispatch(updateTNC(subscriptionId, familyId, tncStatus));
  }
});

const mapStateToProps = (state) => {
  const { newPlan, auth, familyDetails } = state;
  return {
      newPlan,
      auth,
      familyDetails,
  };
};
const ConnectedConfirmationDrawer = withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConfirmationDrawer))
);
export default ConnectedConfirmationDrawer;
