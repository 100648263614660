import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { Box, Typography, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import styles from './style';

class PreferenceCard extends React.Component {
	getCircularProgress = () => {
		return (
			<CircularProgress
				style={{
					color: 'orange',
					left: '50%',
					top: '55%',
					position: 'absolute',
				}}
				disableShrink
			/>
		);
	};
	render() {
		const {
			classes,
			heading,
			subHeading,
			optionSelected,
			prefrenceOptions,
			selectTitle,
			border,
			handleChange,
			newPlan,
			familyDetails,
			cardName,
		} = this.props;
		const comment = familyDetails?.tncDetails?.comment;
		const slicedComment = comment?.length > 100 ? comment?.slice(0,100) + ' ...' : comment;
		return (
			<Box className={`${classes.preferenceCardContainer} ${classes[border]}`}>
				{(familyDetails.cohortStatus && familyDetails.cohortStatus.isLoading) ||
				(newPlan.INITIAL_STATE && newPlan.INITIAL_STATE.isLoading) ? (
					this.getCircularProgress()
				) : (
					<></>
				)}
				{(familyDetails?.healthConditionsTnCData === undefined)
					? this.getCircularProgress() : '' 
				}
				{
					cardName !== 'ghdCard'
					?
						<Box className={classes.textContainer}>
							<Typography className={classes.preferenceCardHeading}>{heading}</Typography>
							<Typography className={classes.preferenceCardSubHeading}>
								{subHeading}
							</Typography>			
						</Box>
					:
						<Box className={classes.ghdTextContainer}>
							<Typography className={classes.ghdCardHeading}>{heading}</Typography>

							{
								subHeading?.map((item) => (
									<Box style={{ display: 'flex' }}>
										<ul className={classes.ghdCardSubHeading}>
											<li>{item.title}</li>
										</ul>
									</Box>
							))}
						</Box>
				}
				{
					cardName !== 'ghdCard'
					?
					<Box className={classes.selectionContainer}>
					{selectTitle ? (
						<Typography className={classes.selectionTitle}>{selectTitle}</Typography>
					) : (
						<></>
					)}
					<RadioGroup className={classes.radioGroup}>
						{prefrenceOptions.map((option) => (
							<Box style={{ display: 'flex' }} key={option.name}>
								<FormControlLabel
									onChange={() => handleChange(option.value, heading)}
									className={classes.radioButton}
									checked={option.value === optionSelected}
									value={option.value}
									control={<Radio color='primary' />}
								/>
								<Typography className={classes.radioButtonLabel}>
									{option.name}
								</Typography>
							</Box>
						))}
					</RadioGroup>
				</Box>
				:
				<>
				<Box className={classes.ghdSelectionContainer}>
					<RadioGroup className={classes.ghdRadioGroup}>
						{prefrenceOptions?.map((option) => (
							<Box style={{ display: 'flex' }} key={option.id}>
								<FormControlLabel
									onChange={() => handleChange(option.id, heading)}
									className={classes.radioButton}
									checked={option.id === optionSelected}
									value={option.id}
									control={<Radio color='primary' />}
								/>
								<Typography className={classes.radioButtonLabel}>
									{option.description}
								</Typography>
							</Box>
						))}
					</RadioGroup>
				</Box>
				{familyDetails?.tncDetails?.comment
				?
					<Typography className={classes.comment}>{slicedComment}</Typography>
				:
					''
				}
				</>
				}
			</Box>
		);
	}
}

const mapStateToProps = (state) => {
	const { newPlan, familyDetails } = state;
	return {
		newPlan,
		familyDetails,
	};
};
const ConnectedPreferenceCard = withStyles(styles, { withTheme: true })(
	connect(mapStateToProps, null)(withTranslation()(PreferenceCard))
);
export default ConnectedPreferenceCard;
