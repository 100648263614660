const styles = () => ({
    header: {
        display: 'flex',
        padding: '30px 24px 10px',
        justifyContent: 'space-between',
        color: '#6B7280',
        fontWeight: 600,
        fontFamily: 'Inter',
    },
    timeline: {
        marginLeft: '-80%',
    },
    rubikBold: {
        fontSize: '20px',
        textTransform: 'uppercase',
    },
    closeIcon: {
        cursor: 'pointer',
    },
    tncStatus: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        fontFamily: 'Rubik',
    }
});
export default styles;
