import React, { useState, createContext, useContext } from 'react';
import { Drawer as MuiDrawer, Button } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { withStyles } from '@material-ui/styles';
import { color } from '../../../constants/colors';

const styles = (theme) => ({
	indicator: {
		backgroundColor: 'orange',
	},
	default_tabStyle: {
		color: 'grey',
		fontFamily: 'Rubik',
	},
	active_tab: {
		fontWeight: 'bold',
		color: 'black',
		fontFamily: 'Rubik',
	},
	flexContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	root: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		background: 'green',
	},
    container: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        width: '100%',
        height: '100%',
    },
    footer: {
        display: 'flex',
        padding: '4vh',
        width: 'calc(100% - 8vh)',
    },
    button: {
        textTransform: 'capitalize',
        background: color.ROYAL_ORANGE,
        color: color.WHITE,
        borderRadius: '4px',
        height: '38px',
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        fontSize: '14px',
        width: '100%',
        '&:hover': {
            background: color.ORANGE,
        },
    },
    header: {
        padding: '4vh 4vh 1vh 4vh',
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        lineHeight: '17px',
        fontSize: '14px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${color.yellows[100]}`,
        color: color.blues[300],
        borderTopLeftRadius: '8px',
        position: 'sticky',
        top: '0',
        background: color.WHITE,
        height: '2.25%',
        zIndex: '50',
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '2vh',
        flex: '1 100%',
    },
});

/**
 * This Context is where the state for the drawer is maintained;
 * From here, we pass down the value of the state and a toggle function
 * to manipulate the state;
 */
const DrawerContext = createContext();
function TriggerDrawer({ children }) {
	const [drawerOpen, setDrawerOpen] = useState(false);

	const toggleDrawer = () => {
		if (drawerOpen) {
			setDrawerOpen(false);
		} else {
			setDrawerOpen(true);
		}
	};
	return (
		<DrawerContext.Provider value={{ drawerOpen, toggleDrawer }}>
			{children}
		</DrawerContext.Provider>
	);
}

function Drawer({ children, anchor = 'right', classes, PaperProps, ...props }) {
	const { drawerOpen, toggleDrawer } = useContext(DrawerContext);
	return (
		<MuiDrawer anchor={anchor} open={drawerOpen} onClose={toggleDrawer} PaperProps={PaperProps}>
			<div className={classes.container}>
				<div className={classes.header}>
					<span className={classes.headerText}>Add New Documents</span>
					<CloseIcon onClick={() => toggleDrawer(false)} className={classes.closeIcon} />
				</div>
				<div className={classes.body}>{children}</div>
				<div className={classes.footer}>
					<Button
						variant='text'
						color='primary'
						className={classes.button}
						onClick={() => toggleDrawer(false)}
					>
						Done
					</Button>
				</div>
			</div>
		</MuiDrawer>
	);
}

/**
 * 
 * @param {String} label - The label for the "Trigger" button. Only used if no
 * `children` are passed to the Trigger.
 * @param {React.Component} children - Component that will act as the trigger for the Drawer.
 * @returns A Trigger element for the Drawer.
 */
function Trigger({ label, children, ...props }) {
	const { toggleDrawer } = useContext(DrawerContext);
	const childrenWithProps = React.Children.map(children, (child) => {
		return React.cloneElement(
			child,
			{
				onClick: toggleDrawer,
				...props,
			},
			children
		);
	});
	if (children) {
		return childrenWithProps;
	} else {
		return (
			<Button {...props} onClick={toggleDrawer}>
				<span {...props}>{label}</span>
			</Button>
		);
	}
}

const styleWrapper = withStyles(styles, { withTheme: true });

/**
 * 
 * @param {String} label - The label for the "Trigger" button. Only used if no
 * `children` are passed to the Trigger.
 * @param {React.Component} children - Component that will act as the trigger for the Drawer.
 * @returns A Trigger element for the Drawer.
 */
TriggerDrawer.Trigger = Trigger;

/**
 * 
 * @param {Object} children - The UI to be rendered inside the Drawer.
 * @param {String} anchor - The edge of the viewport to anchor the drawer to. Defaults to `right`.
 * @param {String} classes - If custom styles need to be passed to the UI inside the drawer, pass the
 * `classes` prop this way.
 * @param {String} PaperProps - The `PaperProps` prop to be passed to the Drawer, to style the surface
 * underneath.
 * @returns Renders a Drawer with the given UI.
 */
TriggerDrawer.Drawer = styleWrapper(Drawer);

export default TriggerDrawer;
