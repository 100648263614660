import React from 'react';
import { find } from 'lodash';
import { connect } from 'react-redux';
import { Chip, withStyles, Select, Snackbar, CircularProgress } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import useStyles from './ConsultationFromStyle';
import { color } from '../../../../constants/colors';
import { Button, MenuItem, Checkbox } from '@material-ui/core';
import 'date-fns';
import { whatsAppConsent, HEADQUARTERS_ID, consultationType } from '../../../../common/constants';

import {
	setConsultationForm,
	createConsultation,
	resetConsultationDetail,
	fetchConsultationsByCustomerId,
	getAllDoctors,
	openBookConsultationDrawer,
	closeBookConsultationDrawer,
	assignDoctorByConsultationId,
	resetDoctorByConsultationId,
	setPresentableConsultations,
	resetErrors,
} from '../../../../actions';
import { updateWhatsAppConsultation } from '../../../../actions/consultationAction';
import { errors } from '../../../../common/errors';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { ReactComponent as ChipSelectedIcon } from '../../../../assets/icons/chip-selected.svg';
import { ReactComponent as ChevronDown } from '../../../../assets/icons/chevron-down.svg';
import SuccessAnimation from './SuccessLottie';
import moment from 'moment';
import firebaseActions from '../../../../config/firebase';
import { isEmpty } from 'lodash';
import SlotSelection from './SlotSelection';
import FileUpload from '../../../common/FileUpload/FileUpload';
class ConsultationForm extends React.Component {
	state = {
		summary: '',
		lop: '',
		type: consultationType.PHYSICAL,
		contactNumber:
			this.props.familyDetails && this.props.familyDetails.allMembers
				? find(
						this.props.familyDetails.allMembers.members,
						(member) => (member.relationship = 'self')
				  ).primary_number
				: '',
		isLoading: false,
		quickSymptoms: [],
		documents: [],
		showSnackBar: false,
		snackbarMessage: '',
		autoAssign: false,
		doctor: '',
		date: '',
		time: '',
	};

	// When the current reference is not null,
	// we can play the lottie animation;
	lottieContainerRef = React.createRef();

	componentDidMount() {
		const {
			resetConsultationDetail,
			updateWhatsAppConsultation,
			auth,
			familyDetails,
			getAllDoctors,
		} = this.props;
		const { contactNumber } = this.state;
		this.setState({
			lop: familyDetails.currentCustomer.lop,
			type:
				auth.currentClinicLocation.id === HEADQUARTERS_ID
					? consultationType.TELE
					: consultationType.PHYSICAL,
		});
		resetConsultationDetail();
		updateWhatsAppConsultation(whatsAppConsent.GRANTED, contactNumber);
		getAllDoctors();
	}

	componentDidUpdate(prevProps, prevState) {
		const { consultationDetails, error } = this.props;

		if (
			prevProps.consultationDetails.createConsultationResponse !==
				consultationDetails.createConsultationResponse &&
			consultationDetails.createConsultationResponse !== null
		) {
			this.setState({
				isLoading: false,
			});
		}

		if (error && error.error && prevProps.error !== error) {
			this.setState({
				showSnackbar: true,
				snackbarMessage: error.error.message,
			});
		}
	}

	handleWhatsAppConsent = () => {
		const { familyDetails, updateWhatsAppConsultation } = this.props;
		const { contactNumber } = this.state;
		let consent;
		// 1. either phone # is different than HS's # or
		// 2. consent is not granted
		if (
			familyDetails?.currentCustomer?.preferences?.whatsapp?.phone !== contactNumber ||
			familyDetails?.currentCustomer?.preferences?.whatsapp?.consent !==
				whatsAppConsent.GRANTED
		) {
			consent = whatsAppConsent.GRANTED;
		}
		// consent is granted for HS's phone number, we are denying consent;
		if (
			familyDetails?.currentCustomer?.preferences?.whatsapp?.phone === contactNumber &&
			familyDetails?.currentCustomer?.preferences?.whatsapp?.consent ===
				whatsAppConsent.GRANTED
		) {
			consent = whatsAppConsent.DENIED;
		}
		updateWhatsAppConsultation(consent, contactNumber);
	};

	resetConsultationDetails = () => {
		const { resetConsultationDetail } = this.props;
		this.setState({ formStatus: 'init' });
		resetConsultationDetail();
	};

	renderSymptomChips = () => {
		const quickSymptomsList = firebaseActions.getQuickSymptoms();
		const { classes } = this.props;
		const { quickSymptoms } = this.state;
		const getFormattedSymptomLabel = (symptom) => {
			return symptom.charAt(0).toUpperCase() + symptom.slice(1);
		};
		const handleChipOnClick = (symptom) => {
			this.setState((prevState) => {
				return {
					quickSymptoms: prevState.quickSymptoms.includes(symptom)
						? prevState.quickSymptoms.filter((elem) => elem !== symptom)
						: [...prevState.quickSymptoms, symptom],
				};
			});
		};
		return (
			<>
				{quickSymptomsList.map((symptom) => {
					return (
						<Chip
							icon={quickSymptoms.includes(symptom) ? <ChipSelectedIcon /> : null}
							label={getFormattedSymptomLabel(symptom)}
							variant='outlined'
							key={symptom}
							className={
								quickSymptoms.includes(symptom)
									? classes.chipSelected
									: classes.chip
							}
							onClick={(event) => handleChipOnClick(symptom)}
						/>
					);
				})}
			</>
		);
	};

	renderLopSelect = (symptoms) => {
		const { classes } = this.props;
		const { lop } = this.state;
		const lopArray = [
			{
				value: 'english',
				label: 'English',
			},
			{
				value: 'hindi',
				label: 'Hindi',
			},
			{
				value: 'kannada',
				label: 'Kannada',
			},
			{
				value: 'tamil',
				label: 'Tamil',
			},
			{
				value: 'telugu',
				label: 'Telugu',
			},
			{
				value: 'malayalam',
				label: 'Malayalam',
			},
			{
				value: 'marathi',
				label: 'Marathi',
			},
			{
				value: 'gujarati',
				label: 'Gujarati',
			},
			{
				value: 'punjabi',
				label: 'Punjabi',
			},
			{
				value: 'odia',
				label: 'Odia',
			},
			{
				value: 'bangla',
				label: 'Bangla',
			},
		];

		const handleLopOnChange = (event) => {
			const lop = event.target.value;
			this.setState({
				lop,
			});
		};

		return (
			<div className={classes.itemContainer}>
				<span className={classes.caption}>LOP</span>
				<Select
					fullWidth
					classes={{
						iconOutlined: classes.icon,
					}}
					renderValue={(selected) => {
						return (
							<span className={classes.menuItem}>
								{lopArray.filter((option) => option.value === selected)[0].label}
							</span>
						);
					}}
					variant='outlined'
					IconComponent={ChevronDown}
					placeholder='LOP'
					className={classes.dropdown}
					value={lop}
					onChange={handleLopOnChange}
				>
					{lopArray.map((option) => {
						return (
							<MenuItem
								key={option.value}
								classes={{
									root: classes.menuItem,
								}}
								value={option.value}
							>
								{option.label}
							</MenuItem>
						);
					})}
				</Select>
			</div>
		);
	};

	renderSymptomContainer = () => {
		const { classes } = this.props;
		const { summary } = this.state;
		const handleSummaryOnChange = (event) => {
			const summary = event.target.value;
			const re = /^[\w,.&\s]*$/g;
			if ((summary.trim().length !== 0 || summary === '') && re.test(summary)) {
				this.setState({
					summary,
				});
			}
		};

		return (
			<div className={classes.itemContainer}>
				<textarea
					className={classes.summary}
					placeholder='Type the symptoms here...'
					value={summary}
					maxLength={1000}
					onChange={handleSummaryOnChange}
				/>
				{this.renderSymptomChips()}
			</div>
		);
	};

	renderDoctorSelectionDropdown = () => {
		const { classes, consultationDetails } = this.props;
		const { autoAssign, doctor, type } = this.state;
		const doctorList = consultationDetails?.allDoctors?.doctors ?? [];
		const availableDoctors = doctorList
			.filter((doctor) => doctor.availability === true)
			.sort((a, b) => {
				return a.first_name.localeCompare(b.first_name);
			});
		const unavailableDoctors = doctorList
			.filter((doctor) => doctor.availability === false)
			.sort((a, b) => {
				return a.first_name.localeCompare(b.first_name);
			});

		/**
		 *
		 * @param {Event} e Object representing the onChange event;
		 * onChange handler to toggle the auto-assign checkbox state;
		 */
		const handleAutoAssignOnChange = (e) => {
			this.setState({
				autoAssign: !autoAssign,
			});
		};

		/**
		 *
		 * @param {Event} event - Event object representing the onChange event
		 * @param {React.ReactNode} child - The triggering child
		 *
		 * ### `onChange` handler for the Select component.
		 * The Event received is a __generic__ `onChange` event
		 * which is triggered when __any__ child of the Select component
		 * is clicked. However, only __MenuItem__ components have a valid value.
		 * Thus, we check the type of the `child`: if it is a `div` node, we do *nothing!*
		 */
		const handleDoctorSelection = (event, child) => {
			if (child.type !== 'div') {
				this.setState({
					doctor: event.target.value,
				});
			}
		};

		/**
		 *
		 * @param {string} doctorId - ID of the doctor selected in the dropdown;
		 * @returns Formatted name of the doctor selected.
		 */
		const getDoctorNameById = (doctorId) => {
			const { consultationDetails } = this.props;
			const doctorList = consultationDetails.allDoctors.doctors ?? [];
			const selectedDoctor = doctorList.filter((doctor) => doctor.id === doctorId)[0];
			return `Dr. ${selectedDoctor.first_name}${
				selectedDoctor.last_name ? ' ' + selectedDoctor.last_name : ''
			}`;
		};

		return (
			<>
				<div className={classes.itemContainer}>
					<div className={classes.autoAssignCheckboxContainer}>
						<Checkbox
							checked={autoAssign}
							onChange={handleAutoAssignOnChange}
							classes={{
								root: classes.checkbox,
							}}
							style={{
								color: color.ORANGE,
							}}
							disabled={type === consultationType.PHYSICAL}
						/>
						<span className={classes.labelHeading}>Auto-assign a Doctor</span>
					</div>
				</div>
				<div className={classes.itemContainer}>
					<span className={classes.caption}>Doctor</span>
					<Select
						fullWidth
						disabled={autoAssign}
						classes={{
							iconOutlined: classes.icon,
						}}
						variant='outlined'
						IconComponent={ChevronDown}
						placeholder='Select a doctor...'
						className={classes.dropdown}
						value={doctor}
						displayEmpty
						onChange={handleDoctorSelection}
						renderValue={(selected) => {
							if (autoAssign) {
								return (
									<span className={classes.menuItem_placeholder}>
										Will be auto-assigned
									</span>
								);
							}
							if (selected.length === 0) {
								return (
									<span className={classes.menuItem_placeholder}>
										Select a doctor
									</span>
								);
							}
							return (
								<span className={classes.menuItem}>
									{getDoctorNameById(selected)}
								</span>
							);
						}}
					>
						{availableDoctors.map((doctor) => {
							return (
								<MenuItem
									key={doctor.id}
									classes={{
										root: classes.menuItem,
									}}
									value={doctor.id}
								>
									<div className={classes.doctorOptionContainer}>
										<div className={classes.doctorLabelContainer}>
											<span className={classes.menuItem_available_doctor}>
												{`Dr. ${doctor.first_name}${
													doctor.last_name ? ' ' + doctor.last_name : ''
												}`}
											</span>
											<svg
												viewBox='0 0 100 100'
												xmlns='http://www.w3.org/2000/svg'
												height='20'
												width='20'
												color={color.greens[300]}
											>
												<circle
													cx='50%'
													cy='50%'
													r='25'
													fill={color.greens[300]}
													stroke={color.greens[100]}
													strokeWidth='10'
												/>
											</svg>
										</div>
										{doctor.qualifications ? (
											<div className={classes.qualificationsLabel}>
												<span>
													{doctor.qualifications?.join(', ') ?? ''}
												</span>
											</div>
										) : null}
									</div>
								</MenuItem>
							);
						})}
						<div className={classes.separatorContainer} disabled>
							<span className={classes.separator}>Not on call</span>
							<hr className={classes.divider} />
						</div>
						{unavailableDoctors.map((doctor) => {
							return (
								<MenuItem
									key={doctor.id}
									classes={{
										root: classes.menuItem,
									}}
									value={doctor.id}
								>
									<div className={classes.doctorOptionContainer}>
										<div className={classes.doctorLabelContainer}>
											<span className={classes.menuItem_unavailable_doctor}>
												{`Dr. ${doctor.first_name}${
													doctor.last_name ? ' ' + doctor.last_name : ''
												}`}
											</span>
											<svg
												viewBox='0 0 100 100'
												xmlns='http://www.w3.org/2000/svg'
												height='20'
												width='20'
												color={color.greys[100]}
											>
												<circle
													cx='50%'
													cy='50%'
													r='25'
													fill={color.greys[200]}
													stroke={color.greys[100]}
													strokeWidth='10'
												/>
											</svg>
										</div>
										{doctor.qualifications ? (
											<div className={classes.qualificationsLabel}>
												<span>
													{doctor.qualifications?.join(', ') ?? ''}
												</span>
											</div>
										) : null}
									</div>
								</MenuItem>
							);
						})}
					</Select>
				</div>
			</>
		);
	};

	/**
	 * onClick handler for the "Add Request" button.
	 * This method creates an object with all relevant data
	 * required to book a consultation and passes it further.
	 */
	handleAddRequestOnClick = () => {
		const {
			quickSymptoms,
			lop,
			summary,
			contactNumber,
			type,
			documents,
			doctor,
			autoAssign,
			date,
			time,
		} = this.state;
		const { auth, createConsultation, customerId } = this.props;
		const health_records = documents
			.map((doc) => {
				if (doc.status === 'success') {
					return doc.id;
				}
				return '';
			})
			.join(',');
      const dateString = moment(date).format("YYYY-MM-DD");
      const timeString = moment(time, "hh:mm A").format("HH:mm:ss");
      const dateTimeUnix = moment(dateString + " " + timeString).valueOf();
      const consultationPayload = {
        customer_id: customerId,
        customer_lop: lop,
        type,
        customer_contact_number: contactNumber,
        summary: summary.length === 0 ? "-" : summary,
        clinic_id: auth.currentClinicLocation.id,
        ...(!autoAssign && {scheduled_on: dateTimeUnix}),
        ...(!autoAssign && doctor.length !== 0 && { doctor_id: doctor }),
        ...(!isEmpty(quickSymptoms) && { symptoms: quickSymptoms.join(",") }),
        ...(health_records.length > 0 && { health_records }),
      };
		this.setState(
			{
				isLoading: true,
			},
			() => {
				createConsultation(consultationPayload);
			}
		);
	};

	renderContactDetailsContainer = () => {
		const { classes, familyDetails } = this.props;
		const { contactNumber } = this.state;
		const headSubscriber = familyDetails.currentCustomer;
		const handleContactNumberOnChange = (e) => {
			const updatedContactNumber = e.target.value.replace(/(^0)|([^0-9])/, '');
			this.setState({
				contactNumber: updatedContactNumber,
			});
		};
		return (
			<div className={classes.itemContainer}>
				<span className={classes.caption}>ALTERNATE MOBILE NUMBER</span>
				<div className={classes.inputField}>
					<input
						type='tel'
						className={classes.input}
						value={contactNumber}
						maxLength={10}
						onChange={handleContactNumberOnChange}
					/>
					{contactNumber.length < 10 && (
						<span className={classes.error}>Need 10 digits.</span>
					)}
				</div>
				<div className={classes.whatsAppConsentContainer}>
					{/*
                        The moment we tick this box,
                        the number entered in the contact number field
                        gets marked as the HS's WhatsApp number.
                    */}
					<Checkbox
						checked={
							headSubscriber.preferences?.whatsapp?.consent ===
								whatsAppConsent.GRANTED &&
							headSubscriber.preferences?.whatsapp.phone === contactNumber
						}
						onChange={this.handleWhatsAppConsent}
						classes={{
							root: classes.checkbox,
						}}
						color='orange'
					/>
					<div className={classes.multiRowLabel}>
						<span className={classes.labelHeading}>
							WhatsApp Consent for communication.
						</span>
						<span className={classes.labelText}>
							Patient would be sent prescription, &nbsp;notifications, reminders and
							any offer updates on WhatsApp.
						</span>
					</div>
				</div>
			</div>
		);
	};

	renderSuccessLottie = () => {
		const { classes, closeDrawer } = this.props;
		window.dispatchEvent(new CustomEvent('lottieRefAttached'));
		return (
			<div className={classes.itemContainer_centered}>
				<SuccessAnimation closeDrawer={closeDrawer} />
				<span>Consultation booked!</span>
			</div>
		);
	};

	renderErrorSnackbar = () => {
		const { error } = this.props;
		const { showSnackbar, snackbarMessage } = this.state;

		const closeSnackbar = () => {
			const { resetErrors } = this.props;
			this.setState(
				{
					showSnackbar: false,
					snackbarMessage: '',
				},
				() => {
					// the error should not get reset before the snackbar closes
					// else, the snackbar will change background colors before it is hidden
					setTimeout(() => {
						resetErrors();
					}, 300);
				}
			);
		};

		return (
			<Snackbar
				open={showSnackbar}
				autoHideDuration={2400}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				onClose={closeSnackbar}
			>
				<MuiAlert
					elevation={6}
					variant='filled'
					closeText=''
					style={{
						backgroundColor: error.error ? color.reds[100] : color.greens[100],
					}}
				>
					{snackbarMessage}
				</MuiAlert>
			</Snackbar>
		);
	};

  handleDateSelection = (date) => {
    this.setState({ date });
  };

  handleTimeSelection = (time) => {
    this.setState({ time });
  };

	render() {
		const { familyDetails, classes, consultationDetails, customerId } = this.props;
		const { lop, contactNumber, isLoading, autoAssign, doctor, summary, date, time } = this.state;

		const handleCloseIconOnClick = () => {
			const { closeDrawer } = this.props;
			closeDrawer();
		};

		const allFilesUploaded = () => {
			const { documents } = this.state;
			// keep track of whether each file is uploaded or not;
			let fileLevelFlag = true;
			for (let i = 0; i < documents.length; i++) {
				if (documents[i].status !== 'success') {
					fileLevelFlag = fileLevelFlag && false;
				}
			}
			return fileLevelFlag;
		};

    const isAddRequestDisabled = () => {
      return ((
         lop.length === 0 ||
           !(
             familyDetails.currentCustomer.preferences?.whatsapp
               ?.consent === whatsAppConsent.GRANTED
           ) ||
           contactNumber.length < 10 ||
           !allFilesUploaded()
       ) ||
       summary.length === 0 ||
       (!autoAssign && doctor.length === 0)
       || (!autoAssign && (!time || !date )))
     }

		const handleSupportingDocumentOnUpload = (uploadedDocument) => {
			const { documents } = this.state;
			const updatedDocuments = [...documents];
			updatedDocuments.push(uploadedDocument);
			this.setState({
				documents: updatedDocuments,
			});
		};

		if (!isLoading) {
			if (consultationDetails.createConsultationResponse === null) {
				// pre-request
				return (
					<>
						<div className={classes.container}>
							<div className={classes.wrapper}>
								<div className={classes.header}>
									<span className={classes.headerText}>Book Consultation</span>
									<CloseIcon
										onClick={handleCloseIconOnClick}
										className={classes.closeIcon}
									/>
								</div>
								<div className={classes.body}>
									{this.renderSymptomContainer()}
                  <SlotSelection
                    handleTime={this.handleTimeSelection}
                    handleDate={this.handleDateSelection}
                    selectedTime={time}
                    autoAssignDoctor={autoAssign}
                  />
									{this.renderDoctorSelectionDropdown()}
									{this.renderLopSelect()}
									{this.renderContactDetailsContainer()}
									<FileUpload
										customerId={customerId}
										onSubmit={handleSupportingDocumentOnUpload}
									/>
								</div>
							</div>
							<div className={classes.footer}>
								<Button
									variant='contained'
									color='primary'
									className={classes.button}
									onClick={this.handleAddRequestOnClick}
                  disabled={isAddRequestDisabled()}
								>
									Add Request
								</Button>
							</div>
						</div>
						{this.renderErrorSnackbar()}
					</>
				);
			}
			if (
				consultationDetails.createConsultationResponse ===
				errors.ERROR_IN_POSTING_CONSULTATION
			) {
				// an error occured
				return (
					<div className={classes.container}>
						<div className={classes.header}>
							<span className={classes.headerText}>Book Consultation</span>
							<CloseIcon
								onClick={handleCloseIconOnClick}
								className={classes.closeIcon}
							/>
						</div>
						<div className={classes.body_centered}>
							{/* TODO: a "try again" button may be required here */}
							An error occured while booking the consultation!
						</div>
						{this.renderErrorSnackbar()}
					</div>
				);
			}
			return (
				// success
				<div className={classes.container}>
					<div className={classes.header}>
						<span className={classes.headerText}>Book Consultation</span>
						<CloseIcon onClick={handleCloseIconOnClick} className={classes.closeIcon} />
					</div>
					<div className={classes.body_centered}>{this.renderSuccessLottie()}</div>
					{this.renderErrorSnackbar()}
				</div>
			);
		} else {
			return (
				<div className={classes.container}>
					<div className={classes.header}>
						<span className={classes.headerText}>Book Consultation</span>
						<CloseIcon onClick={handleCloseIconOnClick} className={classes.closeIcon} />
					</div>
					<div className={classes.body_centered}>
						<CircularProgress
							variant='indeterminate'
							className={classes.loadingCircle}
						/>
					</div>
					{this.renderErrorSnackbar()}
				</div>
			);
		}
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		familyDetails: state.familyDetails,
		error: state.error,
		consultationDetails: state.consultationDetails,
	};
};

const mapDispatchToProps = {
	setConsultationForm,
	setPresentableConsultations,
	createConsultation,
	resetConsultationDetail,
	fetchConsultationsByCustomerId,
	getAllDoctors,
	openBookConsultationDrawer,
	closeBookConsultationDrawer,
	assignDoctorByConsultationId,
	resetDoctorByConsultationId,
	updateWhatsAppConsultation,
	resetErrors,
};

const reduxWrapper = connect(mapStateToProps, mapDispatchToProps);
const styleWrapper = withStyles(useStyles, { withTheme: true });
export default styleWrapper(reduxWrapper(ConsultationForm));
