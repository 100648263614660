export const paymentActionTypes = {
	CREATE_OFFLINE_PAYMENT_REQUEST: 'CREATE_OFFLINE_PAYMENT_REQUEST',
	CREATE_OFFLINE_PAYMENT_SUCCESS: 'CREATE_OFFLINE_PAYMENT_SUCCESS',
	CREATE_OFFLINE_PAYMENT_FAILURE: 'CREATE_OFFLINE_PAYMENT_FAILURE',
	RESET_OFFLINE_PAYMENT: 'RESET_OFFLINE_PAYMENT',
	CREATE_ORDER_REQUEST: 'CREATE_ORDER_REQUEST',
	CREATE_ORDER_SUCCESS: 'CREATE_ORDER_SUCCESS',
	CREATE_ORDER_FAILURE: 'CREATE_ORDER_FAILURE',
	CREATE_SHORTLINK_REQUEST: 'CREATE_SHORTLINK_REQUEST',
	CREATE_SHORTLINK_SUCCESS: 'CREATE_SHORTLINK_SUCCESS',
	CREATE_SHORTLINK_FAILURE: 'CREATE_SHORTLINK_FAILURE',
	SEND_NOTIFICATION_REQUEST: 'SEND_NOTIFICATION_REQUEST',
	SEND_NOTIFICATION_SUCCESS: 'SEND_NOTIFICATION_SUCCESS',
	SEND_NOTIFICATION_FAILURE: 'SEND_NOTIFICATION_FAILURE',
	RESET_PAYMENT: 'RESET_PAYMENT',
};

export const creditDeductionReason = {
	MEDICINES: 'Medicines / Lab test',
	RENEWAL_OF_PLAN: 'Renewal of Plan',
	CONSULTATION: 'Consultation',
};

export const invoiceType = {
	CONSULTATION_INVOICE: 'consultation_invoice',
	MEDICINE_INVOICE: 'medicine_invoice',
	SUBSCRIPTION_INVOICE: 'subscription_invoice',
};

export const billingType = {
	CONSULTATION: 'consultation',
	GENERAL: 'general',
};
