import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  button: {
    color: 'white',
    width: '100%',
    height: '51px',
    borderColor: '#0033cc',
    textTransform: 'capitalize',
    margin: '20px 6px 24px',
    fontFamily: 'Manrope-Bold',
    borderRadius: '8px',
    fontSize: '16px',
    backgroundColor: '#F38B44',
  },
  buttonDisabled: {
    color: 'white',
    width: '100%',
    height: '51px',
    borderColor: '#0033cc',
    textTransform: 'capitalize',
    margin: '20px 6px 24px',
    fontFamily: 'Manrope-Bold',
    borderRadius: '8px',
    fontSize: '16px',
    backgroundColor: '#C1CCEB',
  },
  buttonHintTextButton: {
    color: 'white',
    width: '100%',
    height: '18px',
    borderColor: '#0033cc',
    textTransform: 'capitalize',
    margin: '20px 6px 0px',
    fontFamily: 'Manrope-Regular',
    fontSize: '16px',
    textAlign: 'center',
  },
  buttonHintErrorText : {
    color: 'red',
    width: '100%',
    height: '18px',
    borderColor: '#0033cc',
    textTransform: 'capitalize',
    margin: '5px 6px 0px',
    fontFamily: 'Manrope-Regular',
    fontSize: '16px',
    textAlign: 'center',
  },
  appBarCurvedTop: {
    top: 'auto',
    bottom: 0,
    backgroundColor: 'white',
    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.3)',
    paddingTop: '11px',
    borderRadius: '21px 21px 0 0',
    width: 'clac(100% - 20px)',
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: 'white',
    paddingTop: '11px',
  },
  appBarTransparent: {
    top: 'auto',
    bottom: 0,
    // backgroundColor: "white",
    // paddingTop: '11px',
  },

  termsAndConditionText: {
    marginLeft: '24px',
    marginRight: '24px',
    fontFamily: 'Manrope-Regular',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonHintText: {
    fontFamily: 'Manrope-Regular',
    fontSize: '16px',
    color: '#000000',
    marginTop: '4px',
    marginBottom: '-10px',
  },
});

export default useStyles;
