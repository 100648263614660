
import {
    BATCH_EXTEND_SUBSCRIPTION,
    BATCH_EXTEND_SUBSCRIPTION_RESPONSE,
    RESET_BATCH_EXTENDED_SUBSCRIPTION
} from './types';
import { uploadBatchExtendSubscriptionFile } from '../clients/batchExtentionservice'
import { errors } from '../common/errors'

export const batchExtendSubscriptionFileUpload = (emailId,file) => async (dispatch, getState) => {
    const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
    let response;
    let data;
    dispatch({ type: BATCH_EXTEND_SUBSCRIPTION })
    try {
        response = await uploadBatchExtendSubscriptionFile(ACCESS_TOKEN, emailId,file);
        data = response.status;
        dispatch({ type: BATCH_EXTEND_SUBSCRIPTION_RESPONSE, payload: data })
    } catch (e) {
        dispatch({ type: BATCH_EXTEND_SUBSCRIPTION_RESPONSE, payload: errors.ERROR_IN_BATCH_EXTEND_SUBSCRIPTION })
    }
};

export const resetBatchExtendedSubscription = () => (dispatch) => {
    dispatch({ type: RESET_BATCH_EXTENDED_SUBSCRIPTION })
}