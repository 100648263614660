import * as Sentry from '@sentry/react';
import _ from 'lodash';
import {
    getTicketsCountStatus, getTickets, addCommentsToTicket, extendTicketDeadLine,
    markTicketResolve,
    postFeedback,
    getCustomerPreferences,
    sendPrescriptionLinkThroughSms,
    sendPrescriptionLinkThroughWhatsapp,
    sendPlaystoreLinkThroughSms,
    sendPlaystoreLinkThroughWhatsapp
} from '../clients';
import {
    errorActionTypes, ticketingActionTypes,
} from '../constants';
const { buildCustomError } = require('../common/utils');

function getTicketsCountStatusEvent(partnerId) {
    const request = () => ({ type: ticketingActionTypes.GET_TICKETS_COUNT_STATUS_REQUEST });
    const success = (payload) => ({ type: ticketingActionTypes.GET_TICKETS_COUNT_STATUS_SUCCESS, payload });
    const failure = () => ({ type: ticketingActionTypes.GET_TICKETS_COUNT_STATUS_FAILURE });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
    const resetError = () => ({ type: errorActionTypes.RESET_ERROR });

    return async (dispatch, getState) => {
        const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
        dispatch(request());
        try {
            dispatch(resetError());
            const response = await getTicketsCountStatus(partnerId, ACCESS_TOKEN);
            dispatch(success(response.data));
        } catch (e) {
            const error = buildCustomError(e);
            Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`),
            { extra: { errorData: error.data, function: 'getTicketsCountStatusEvent', requestPayload: partnerId } });
            dispatch(failure());
            dispatch(setError(error));
        }
    };
}

function getTicketsEvent(partnerId, pageNo, pageSize) {
    const request = () => ({ type: ticketingActionTypes.GET_TICKETS_REQUEST });
    const success = (payload) => ({ type: ticketingActionTypes.GET_TICKETS_SUCCESS, payload });
    const failure = () => ({ type: ticketingActionTypes.GET_TICKETS_FAILURE });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
    const resetError = () => ({ type: errorActionTypes.RESET_ERROR });

    return async (dispatch, getState) => {
        const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
        const localTicketFilterOptions = getState().ticketing.localTicketFilterOptions;
        const {

            deadlineFrom,
            deadLineTo,
            selectedDateType,
            selectedLanguage,
            selectedTicketObjectives,
            sortMode,
            mobileNo
        } = localTicketFilterOptions;
        dispatch(request());
        try {
            dispatch(resetError());
            const response = await getTickets(partnerId, ACCESS_TOKEN,
                deadlineFrom,
                deadLineTo,
                selectedDateType,
                selectedLanguage,
                selectedTicketObjectives,
                pageNo,
                pageSize,
                sortMode,
                mobileNo);
            dispatch(success(response.data));
        } catch (e) {
            const error = buildCustomError(e);
            Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`),
                { extra: { errorData: error.data, function: 'getTicketsEvent', requestPayload: localTicketFilterOptions } });
            dispatch(failure());
            dispatch(setError(error));
        }
    };
}

function updateLocalTicketFilterOptionsEvent(sortMode, selectedLanguage, ticketObjective, deadLineFrom, deadLineTo, selectedDateType, mobileNo) {
    const success = (payload) => ({ type: ticketingActionTypes.UPDATE_LOCAL_TICKET_FILTER_OPTIONS_SUCCESS, payload });
    const localTicketFilterOptions = {
        sortMode: sortMode,
        selectedLanguage: selectedLanguage,
        selectedTicketObjectives: ticketObjective,
        deadlineFrom: deadLineFrom,
        deadLineTo: deadLineTo,
        selectedDateType: selectedDateType,
        mobileNo: mobileNo,
    };
    return async (dispatch) => {
        dispatch(success(localTicketFilterOptions));
    }
}

function addCommentsToTicketEvent(ticketId, reason, comment) {
    const request = () => ({ type: ticketingActionTypes.ADD_COMMENTS_TO_TICKET_REQUEST });
    const success = (payload) => ({ type: ticketingActionTypes.ADD_COMMENTS_TO_TICKET_SUCCESS, payload });
    const failure = () => ({ type: ticketingActionTypes.ADD_COMMENTS_TO_TICKET_FAILURE });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
    const resetError = () => ({ type: errorActionTypes.RESET_ERROR });

    return async (dispatch, getState) => {
        const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
        dispatch(request());
        let data;
        try {
            dispatch(resetError());
            data = {
                call_response: reason,
            };
            if (comment && comment.length > 0) {
                _.assign(data, { comment: comment })
            }
            const response = await addCommentsToTicket(ticketId, data, ACCESS_TOKEN);
            dispatch(success(response.data));
        } catch (e) {
            const error = buildCustomError(e);
            Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`),
                { extra: { errorData: error.data, function: 'addCommentsToTicketEvent', requestPayload: data } });
            dispatch(failure());
            dispatch(setError(error));
        }
    };
}

function extendTicketDeadLineEvent(ticketId, deadLineTime) {
    const request = () => ({ type: ticketingActionTypes.EXTEND_TICKET_DEADLINE_REQUEST });
    const success = (payload) => ({ type: ticketingActionTypes.EXTEND_TICKET_DEADLINE_SUCCESS, payload });
    const failure = () => ({ type: ticketingActionTypes.EXTEND_TICKET_DEADLINE_FAILURE });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
    const resetError = () => ({ type: errorActionTypes.RESET_ERROR });
    const select = (payload) => ({ type: ticketingActionTypes.SELECT_CURRENT_TICKET, payload });
    return async (dispatch, getState) => {
        const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
        dispatch(request());
        try {
            dispatch(resetError());
            const response = await extendTicketDeadLine(ticketId, deadLineTime, ACCESS_TOKEN);
            dispatch(success(response.data));
            dispatch(select(null));
            setTimeout(function () {
                window.location.reload();
            });
        } catch (e) {
            const error = buildCustomError(e);
            Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`),
                { extra: { errorData: error.data, function: 'extendTicketDeadLineEvent', requestPayload: ticketId } });
            dispatch(failure());
            dispatch(setError(error));
        }
    };
}

function markTicketResolveEvent(ticketId) {
    const request = () => ({ type: ticketingActionTypes.MARK_RESOLVE_TICKET_REQUEST });
    const success = (payload) => ({ type: ticketingActionTypes.MARK_RESOLVE_TICKET_SUCCESS, payload });
    const failure = () => ({ type: ticketingActionTypes.MARK_RESOLVE_TICKET_FAILURE });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
    const resetError = () => ({ type: errorActionTypes.RESET_ERROR });
    const select = (payload) => ({ type: ticketingActionTypes.SELECT_CURRENT_TICKET, payload });
    return async (dispatch, getState) => {
        const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
        dispatch(request());
        try {
            dispatch(resetError());
            const response = await markTicketResolve(ticketId, ACCESS_TOKEN);
            dispatch(success(response.data));
            dispatch(select(null));
            setTimeout(function () {
                window.location.reload();
            });
        } catch (e) {
            const error = buildCustomError(e);
            Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`),
                { extra: { errorData: error.data, function: 'markTicketResolveEvent', requestPayload: ticketId } });
            dispatch(failure());
            dispatch(setError(error));
        }
    };
}



function resetAddCommentEvent() {
    const select = () => ({ type: ticketingActionTypes.RESET_ADD_COMMENT });
    return async (dispatch) => {
        dispatch(select());
    }
}

function resetBookConsultationEvent() {
    const select = () => ({ type: ticketingActionTypes.REST_BOOK_CONSULTATION });
    return async (dispatch) => {
        dispatch(select());
    }
}

function resetMarkResolveEvent() {
    const select = () => ({ type: ticketingActionTypes.RESET_MARK_RESOLVE });
    return async (dispatch) => {
        dispatch(select());
    }
}

function selectCurrentTicketEvent(ticket) {
    const select = (payload) => ({ type: ticketingActionTypes.SELECT_CURRENT_TICKET, payload });
    return async (dispatch) => {
        dispatch(select(ticket));
    }
}

// function clearLocalTicketFilterOptionsEvent() {
//     const request = () => ({ type: ticketingActionTypes.UPDATE_LOCAL_TICKET_FILTER_OPTIONS_REQUEST });
//     const success = (payload) => ({ type: ticketingActionTypes.UPDATE_LOCAL_TICKET_FILTER_OPTIONS_SUCCESS, payload });
//     const failure = () => ({ type: ticketingActionTypes.UPDATE_LOCAL_TICKET_FILTER_OPTIONS_SUCCESS });
//     const localTicketFilterOptions = {
//         sortMode: sortType.ASCENDING,
//         selectedLanguage: null,
//         ticketObjective: null,
//         deadlineFrom: null,
//         deadLineTo: null,
//     };
//     return async (dispatch, getState) => {
//         dispatch(success(localTicketFilterOptions));
//     }
// }

// eslint-disable-next-line import/prefer-default-export

function setFeedbackRating(rating) {
    const set = (payload) => ({ type: ticketingActionTypes.SET_FEEDBACK_RATING, payload });
    return async (dispatch) => {
        dispatch(set(rating));
    }
}

function setFeedbackComment(comment) {
    const set = (payload) => ({ type: ticketingActionTypes.SET_FEEDBACK_COMMENT, payload });
    return async (dispatch) => {
        dispatch(set(comment));
    }
}

function resetFeedbackRating() {
    const reset = () => ({ type: ticketingActionTypes.RESET_FEEDBACK_RATING });
    return async (dispatch) => {
        dispatch(reset());
    }
}

function resetFeedbackComment() {
    const reset = () => ({ type: ticketingActionTypes.RESET_FEEDBACK_COMMENT });
    return async (dispatch) => {
        dispatch(reset());
    }
}

function resetFeedbackForm() {
    const reset = () => ({ type: ticketingActionTypes.RESET_FEEDBACK_FORM });
    return async (dispatch) => {
        dispatch(reset());
    }
}


function postFeedbackEvent() {
    const request = () => ({ type: ticketingActionTypes.POST_FEEDBACK_REQUEST });
    const success = (payload) => ({ type: ticketingActionTypes.POST_FEEDBACK_SUCCESS, payload });
    const failure = () => ({ type: ticketingActionTypes.POST_FEEDBACK_FAILURE });

    return async (dispatch, getState) => {
        const ACCESS_TOKEN = getState()?.auth?.authDetails?.data?.access_token;
        const consultationId = getState()?.ticketing?.selectedTicket?.metadata?.consultation?.id ?? '';
        const feedbackData = {
            rating: getState()?.ticketing?.feedbackForm?.rating ?? 0,
        }
        if (getState()?.ticketing?.feedbackForm?.comment && getState()?.ticketing?.feedbackForm?.comment.length > 0) {
            _.assign(feedbackData, { reviews: [getState()?.ticketing?.feedbackForm?.comment] })
        }
        dispatch(request());
        try {
            const response = await postFeedback(consultationId, ACCESS_TOKEN, feedbackData);
            dispatch(success(response.data));
        } catch (e) {
            const error = buildCustomError(e);
            Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`),
                { extra: { errorData: error.data, function: 'postFeedbackEvent', requestPayload: feedbackData } });
            dispatch(failure());
        }
    };
}

function resetPostFeedbackApiStatus() {
    const reset = () => ({ type: ticketingActionTypes.RESET_POST_FEEDBACK_API_STATUS });
    return async (dispatch) => {
        dispatch(reset());
    }
}


function getCustomerPreferencesEvent() {
    const request = () => ({ type: ticketingActionTypes.GET_CUSTOMER_PREFERENCES_REQUEST });
    const success = (payload) => ({ type: ticketingActionTypes.GET_CUSTOMER_PREFERENCES_SUCCESS, payload });
    const failure = () => ({ type: ticketingActionTypes.GET_CUSTOMER_PREFERENCES_FAILURE });
    const preferencesNotFound = () => ({ type: ticketingActionTypes.SET_PREFERENCES_NOT_FOUND });

    return async (dispatch, getState) => {
        const ACCESS_TOKEN = getState()?.auth?.authDetails?.data?.access_token;
        const customerId = getState()?.ticketing?.selectedTicket?.metadata?.consultation?.customer?.id ?? '';
        dispatch(request());
        try {
            const response = await getCustomerPreferences(customerId, ACCESS_TOKEN);
            dispatch(success(response.data));
        } catch (e) {
            console.warn(e.response);
            if (e?.response?.status === 404 && e?.response?.data?.message === "Customer Preference not found") {
                dispatch(preferencesNotFound());
            } else {
                const error = buildCustomError(e);
                Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`),
                    { extra: { errorData: error.data, function: 'getCustomerPreferencesEvent', requestPayload: customerId } });
                dispatch(failure());
            }
        }
    };
}

function resetGetCustomerPreferences() {
    const reset = () => ({ type: ticketingActionTypes.RESET_GET_CUSTOMER_PREFRENCES_API_STATUS });
    return async (dispatch) => {
        dispatch(reset());
    }
}

function sendPrescriptionLinkThroughSmsEvent(phoneNumber) {
    const request = () => ({ type: ticketingActionTypes.SEND_PRESCRIPTION_LINK_SMS_REQUEST });
    const success = (payload) => ({ type: ticketingActionTypes.SEND_PRESCRIPTION_LINK_SMS_SUCCESS, payload });
    const failure = () => ({ type: ticketingActionTypes.SEND_PRESCRIPTION_LINK_SMS_FAILURE });

    return async (dispatch, getState) => {
        const ACCESS_TOKEN = getState()?.auth?.authDetails?.data?.access_token;
        const consultationId = getState()?.ticketing?.selectedTicket?.metadata?.consultation?.id ?? '';
        dispatch(request());
        try {
            await sendPrescriptionLinkThroughSms(consultationId, ACCESS_TOKEN, phoneNumber);
            dispatch(success("ok"));
        } catch (e) {
            const error = buildCustomError(e);
            Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`),
                { extra: { errorData: error.data, function: 'sendPrescriptionLinkThroughSmsEvent', requestPayload: phoneNumber } });
            dispatch(failure());
        }
    };
}

function resetSendPrescriptionLinkSmsApiStatus() {
    const reset = () => ({ type: ticketingActionTypes.RESET_SEND_PRESCRIPTION_LINK_SMS_API_STATUS });
    return async (dispatch) => {
        dispatch(reset());
    }
}

function sendPrescriptionLinkThroughWhatsappEvent(phoneNumber) {
    const request = () => ({ type: ticketingActionTypes.SEND_PRESCRIPTION_LINK_WHATSAPP_REQUEST });
    const success = (payload) => ({ type: ticketingActionTypes.SEND_PRESCRIPTION_LINK_WHATSAPP_SUCCESS, payload });
    const failure = () => ({ type: ticketingActionTypes.SEND_PRESCRIPTION_LINK_WHATSAPP_FAILURE });

    return async (dispatch, getState) => {
        const ACCESS_TOKEN = getState()?.auth?.authDetails?.data?.access_token;
        const consultationId = getState()?.ticketing?.selectedTicket?.metadata?.consultation?.id ?? '';
        dispatch(request());
        try {
            const response = await sendPrescriptionLinkThroughWhatsapp(consultationId, ACCESS_TOKEN, phoneNumber);
            dispatch(success(response.data));
        } catch (e) {
            const error = buildCustomError(e);
            Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`),
                { extra: { errorData: error.data, function: 'sendPrescriptionLinkThroughWhatsappEvent', requestPayload: phoneNumber } });
            dispatch(failure());
        }
    };
}

function resetSendPrescriptionLinkWhatsappApiStatus() {
    const reset = () => ({ type: ticketingActionTypes.RESET_SEND_PRESCRIPTION_LINK_WHATSAPP_API_STATUS });
    return async (dispatch) => {
        dispatch(reset());
    }
}

function sendPlaystoreLinkThroughSmsEvent(phoneNumber) {
    const request = () => ({ type: ticketingActionTypes.SEND_PLAYSTORE_LINK_SMS_REQUEST });
    const success = (payload) => ({ type: ticketingActionTypes.SEND_PLAYSTORE_LINK_SMS_SUCCESS, payload });
    const failure = () => ({ type: ticketingActionTypes.SEND_PLAYSTORE_LINK_SMS_FAILURE });

    return async (dispatch, getState) => {
        const ACCESS_TOKEN = getState()?.auth?.authDetails?.data?.access_token;
        dispatch(request());
        try {
            await sendPlaystoreLinkThroughSms(ACCESS_TOKEN, phoneNumber);
            dispatch(success("ok"));
        } catch (e) {
            const error = buildCustomError(e);
            Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`),
                { extra: { errorData: error.data, function: 'sendPlaystoreLinkThroughSmsEvent', requestPayload: phoneNumber } });
            dispatch(failure());
        }
    };
}

function sendPlaystoreLinkThroughWhatsappEvent(phoneNumber) {
    const request = () => ({ type: ticketingActionTypes.SEND_PLAYSTORE_LINK_WHATSAPP_REQUEST });
    const success = (payload) => ({ type: ticketingActionTypes.SEND_PLAYSTORE_LINK_WHATSAPP_SUCCESS, payload });
    const failure = () => ({ type: ticketingActionTypes.SEND_PLAYSTORE_LINK_WHATSAPP_FAILURE });

    return async (dispatch, getState) => {
        const ACCESS_TOKEN = getState()?.auth?.authDetails?.data?.access_token;
        dispatch(request());
        try {
            await sendPlaystoreLinkThroughWhatsapp(ACCESS_TOKEN, phoneNumber);
            dispatch(success("ok"));
        } catch (e) {
            const error = buildCustomError(e);
            Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`),
                { extra: { errorData: error.data, function: 'sendPlaystoreLinkThroughWhatsappEvent', requestPayload: phoneNumber } });
            dispatch(failure());
        }
    };
}

function resetSendPlaystoreLinkApiStatus() {
    const reset = () => ({ type: ticketingActionTypes.RESET_SEND_PLAYSTORE_LINK_SMS_API_STATUS });
    return async (dispatch) => {
        dispatch(reset());
    }
}

export const ticketingActions = {
    getTicketsCountStatusEvent,
    getTicketsEvent,
    updateLocalTicketFilterOptionsEvent,
    selectCurrentTicketEvent,
    addCommentsToTicketEvent,
    resetAddCommentEvent,
    extendTicketDeadLineEvent,
    // clearLocalTicketFilterOptionsEvent,
    markTicketResolveEvent,
    resetBookConsultationEvent,
    resetMarkResolveEvent,
    setFeedbackRating,
    setFeedbackComment,
    resetFeedbackRating,
    resetFeedbackComment,
    resetFeedbackForm,
    postFeedbackEvent,
    resetPostFeedbackApiStatus,
    getCustomerPreferencesEvent,
    resetGetCustomerPreferences,
    sendPrescriptionLinkThroughSmsEvent,
    sendPrescriptionLinkThroughWhatsappEvent,
    resetSendPrescriptionLinkSmsApiStatus,
    resetSendPrescriptionLinkWhatsappApiStatus,
    sendPlaystoreLinkThroughSmsEvent,
    sendPlaystoreLinkThroughWhatsappEvent,
    resetSendPlaystoreLinkApiStatus,
};
