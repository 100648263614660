
export const strings = {
  EDIT_PROFILE: "Edit Details",
  CONFIRM: "Confirm",
  PROCEED: "Proceed",
  FULl_NAME: "Full Name* (As per Govt. ID)",
  DOB: "Date of Birth",
  GENDER: "Gender",
  PLEASE_CONFIRM_YOUR_DETAILS: "Please confirm your details.",
  HEALTH_INSURANCE_DESCRIPTION: "Covers the Hospitalization expenses for the insured members.",
  PRIMARY_CARE_DESCRIPTION: "Includes 24x7 Doctor Support on-call",
  HOSPICASH_DESCRIPTION: "Get a fixed amount for everyday you spend in the hospital when hospitalized.",
  SELECT_PRODUCT_TITLE: "Here are our Health Protection products available for buying.",
  YOUR_PRODUCT_DETAILS: "YOUR PRODUCT DETAILS",
  CHECK_YOUR_PRODUCT_DETAIL: "Please check the product details before you proceed.",
  HOSPICASH: "hospicash",
  COVERAGE_UPTO: "COVERAGE UPTO",
  COVERS: "COVERS",
  INSURANCE_DETAILS: [
    "Valid for 1 year",
    "15-day waiting period for Insurance",
    "24x7 Doctor Support On-Call",
    "20% off on Medicines & Lab tests",
  ],
  PRICING: "PRICING",
  CLINIKK_HEALTHCARE: "Clinikk Healthcare",
  LANDING_PAGE_DETAILS: [
    {title: "24x7 Primary Care", description: "Dedicated care team of doctors and health assistants available anytime providing guidance & care with no limits."},
    {title: "Robust Financial Security", description: "Budget-friendly payment plans with 24x7 claims support preventing financial issues in future."},
    {title: "Local Language Support", description: "Well-trained medical care team to deliver quality service without any language barrier."},
    {title: "Best-in-class Pricing", description: "Affordable offerings, ensuring money isn’t a barrier to securing quality healthcare."},
    {title: "Expanding Fast", description: "Growing all over India with a dream to provide high-quality & affordable healthcare to all."},
  ],
  CUSTOMER_DETAILS: 'Customer Details',
  PRIMARY_MEMBER: 'Primary Member',
  DEPENDENTS: 'Dependents',
  NOMINEE: 'Nominee',
  BILLING_PREFERENCE: 'Billing Preference',
  PREFERENCE: 'Preference',
  PED_DOCUMENT: 'ped',
  CONFIRMATION: 'Confirmation',
  SUBSCRIPTION_MANDATE_STATUS: {
    INIT: 'init',
    ACTIVE: 'active',
    REJECT: 'reject',
    EXPIRED: 'expired',
    IN_AUTHORIZATION: 'in_authorization',
    AUTHORIZED: 'authorized',
    AUTHORIZATION_FAILED: 'authorization_failed',
    CLOSED: 'closed',
    CONFIRMED: 'confirmed',
  },
  NEW_PURCHASE: 'NEW PURCHASE',
  RENEWAL: 'RENEWAL',
  SUBSCRIPTION_MANDATE_TOOLTIP_MSG: {
    SUBSCRIPTION_NOT_ACTIVE: 'You cannot send the link as the subscription is not active.',
    ACTIVE: 'Only one mandate can be active at a time.',
    AUTHORIZED: 'A mandate is already authorised. Waiting for confirmation.',
    IN_AUTHORIZATION: 'A mandate is already in authentication.',
    CONFIRMED: 'A mandate is already confirmed. Waiting for the activation.',
    INIT: 'A mandate is already initiated.',
  },
};
