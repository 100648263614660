import React from 'react';
import { connect } from 'react-redux';

import CustomerStepper from './CustomerStepper';
class StepperAddNewCustomer extends React.Component {

    render() {
        const { phoneNumber } = this.props;
        return (
            <div style={{width: '50vw', marginLeft: '0.8vw', marginTop: '0.5vh', marginBottom: '1vh', height: '70vh'}}>
                <CustomerStepper phoneNumber={phoneNumber} />
            </div>
        )
    }
};

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error, familyDetails: state.familyDetails };
}

export default connect(mapStateToProps)(StepperAddNewCustomer);
