import React from 'react';
import { color } from '../../../constants/colors';
import moment from 'moment';

import { Drawer, Paper, Button, Typography } from '@material-ui/core';
import styled from 'styled-components';
import UploadDrawer from '../../common/UploadDrawer/UploadDrawer';
import { ReactComponent as CareInsuranceIcon } from '../../../images/serviceProviders/CareInsurance.svg'
import { ReactComponent as ProfileIcon } from '../../../assets/icons/Profile.svg';
import DocumentIcon from '../../../assets/icons/DocumentIcon';
import config from '../../../config';
import { checkIfManager, formatNumberAsIndianCurrency, splitAndCapitaliseString } from '../../../common/utils';
import { connect } from 'react-redux';
import { addonCategories } from '../../../common/constants';

const Card = styled.div({
    margin: '2vh',
    border: `1px solid ${color.greys[400]}`,
    borderRadius: '4px',
    maxWidth: '400px',
});

const CardHeader = styled.div({
    padding: '2vh',
    borderBottom: `1px solid ${color.greys[400]}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
});

const CardFooter = styled(CardHeader)({
    borderBottom: '0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const CardHeadingBody = styled.div({
    display: 'flex',
    flexDirection: 'column',
    // marginLeft: '2vh',
});

const CardHeading = styled.p((props) => ({
    display: 'inline-block',
    margin: '0px',
    color: props.color ?? color.BLACK,
    fontWeight: 'bold',
    fontFamily: 'Rubik',
    fontSize: '13px',
    textTransform: 'capitalize'
}));

const CardSubheading = styled(CardHeading)({
    marginTop: '1vh',
    color: color.greys[200],
    fontSize: '12px',
    textTransform: 'capitalize',
});

const FooterSubheading = styled(CardSubheading)({
    fontWeight: 'normal',
})

const CardContent = styled.div({
    background: color.greys[500],
    padding: '1vh 2vh',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '4px'
});

const MemberChip = styled.div({
    background: color.WHITE,
    borderRadius: '4px',
    padding: '4px',
    fontSize: '11px',
    fontWeight: 'bold',
    fontFamily: 'Rubik',
    textTransform: 'uppercase',
    display: 'flex',
    flexDirection: 'row',
});

const StyledP = styled.p({
    display: 'block',
    padding: '0px',
    margin: '0px 4px',
});

const CoiLabelContainer = styled.div(({ coiPresent }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '2px',
    transition: 'background 0.5s ease',
    width: 'max-content',
    borderRadius: '2px',
    ":hover": {
        background: coiPresent
        ? color.oranges[100] : 'transparent',
        cursor: coiPresent
        ? 'pointer' : 'auto',
        transition: 'background 0.5s ease',
    }
}));

const StyledButton = styled(Button)({
    background: color.ROYAL_ORANGE,
    fontFamily: 'Rubik',
    fontWeight: 'normal',
    fontSize: '13px',
    borderRadius: '4px',
    textTransform: 'capitalize',
    color: color.WHITE,
    ':hover': {
        background: color.ORANGE,
    }
})

const CardFooterColumn = styled.div({
    display: 'flex',
    flexDirection: 'column',

})

const MemberPlaceholder = styled(Typography)({
    fontFamily: "Rubik",
    color: color.greys[100],
    fontSize: '0.8rem'
})

class ServiceCard extends React.Component {
    state = {
        expanded: false,
        expandButtonText: 'Show Details',
        openDocumentUploadDrawer: false,
    };

    

    // providerIconMap = () => {
    //     switch (this.props.product[this.props.serviceName].provider.name) {
    //         case ("Aditya Birla Health Insurance"):
    //             return <AdityaBirlaIcon style={{ marginTop: '0.8vh', width: '10vh' }} />;
    //         case ("Magma"):
    //             return <MagmaIcon style={{ marginTop: '0.8vh', width: '10vh' }} />;
    //         case ("Religare"):
    //             return <ReligareIcon style={{ marginTop: '0.8vh', width: '10vh' }} />;
    //         case ("Bharti AXA"):
    //             return <BhartiAxaIcon style={{ marginTop: '0.8vh', width: '10vh' }} />;
    //         case ("Clinikk Healthcare"):
    //             return <ClinikkProviderIcon style={{ width: '10vh' }} />;
    //         default:
    //             return <ClinikkProviderIcon style={{ width: '10vh' }} />;
    //     }
    // };

    serviceNameMap = () => {
        switch (this.props.serviceName) {
            case (addonCategories.PRIMARY_CARE):
                return 'Primary Care';
            case (addonCategories.ACCIDENTAL_INSURANCE):
                return 'Accidental Insurance';
            case ('hospicash'):
                // Probably deprecated addon.
                return 'Hospicash';
            case (addonCategories.HEALTH_INSURANCE):
                return 'Health Insurance';
            case ('addons'):
                // v:0 plans contain an `addons` object for super top-up.
                return 'Super Top Up';
            case (addonCategories.SUPER_TOPUP_10L):
                return "Super Top Up - 10L";
            case (addonCategories.SUPER_TOPUP_50L):
                return "Super Top Up - 50L";
            default:
                return '';
        }
    }

    handleExpandClick = () => {
        this.setState((prevState) => ({
            expanded: !prevState.expanded,
        }));
    };

    getExpandButtonText = () => {
        let expandedButtonText;
        let hideButtonText;
        if (this.state.expanded) {
            expandedButtonText = '';
            hideButtonText = 'HideDetails';
            return hideButtonText;
        } else {
            expandedButtonText = 'Show Details'
        }
        return expandedButtonText;
    };

    createData = (name, value) => {
        return { name, value };
    }

    renderMembers = () => {
        let allMembers = [];
        (this.props.serviceDetail.members).forEach((member) => allMembers.push([member.relationship, member.customer.first_name, member.customer.last_name]));
        return allMembers;
    }

    getCoiLabelString = () => {
        const { lapsed, serviceDetail } = this.props;
        const coiUploadedAt = serviceDetail.coi_uploaded_at
            ? moment(serviceDetail.coi_uploaded_at).format('DD/MM/YYYY; hh:mm A')
            : null;
        if (lapsed) {
            return ' - Lapsed'
        } else {
            if (!lapsed && !coiUploadedAt) {
                return ' Endorsement - In Progress';
            }
            return '';
        }
    }

    getCoiLabelColor = () => {
        const { lapsed, serviceDetail } = this.props;
        const coiUploadedAt = serviceDetail.coi_uploaded_at
            ? moment(serviceDetail.coi_uploaded_at).format('DD/MM/YYYY; hh:mm A')
            : null;
        if (lapsed) {
            return color.reds[100]
        } else {
            if (!lapsed && !coiUploadedAt) {
                return color.ROYAL_ORANGE
            }
            return color.BLACK;
        }
    }

    downloadCOI = () => {
        const { subscriptionId, serviceDetail, lapsed, serviceName, subscriptionVersion } = this.props;
        /**
         * For version:0 subscriptions, insuranceType values are `health`, `accidental`, `superTopup`.
         * 
         * For version:1 subscriptions, insuranceType values are `health`, `accidental`, `super_topup_10_l`,
         * `super_topup_50_l`.
         */
        let insuranceType = null;
        if (subscriptionVersion === 0) {
            insuranceType = (serviceName === "addons" ? "superTopup" : serviceName.split("_")[0])
        } else {
            insuranceType = [addonCategories.SUPER_TOPUP_10L, addonCategories.SUPER_TOPUP_50L].includes(serviceName)
                ? serviceName
                : serviceName.split("_")[0];
        }
        const coiUploadedAt = serviceDetail.coi_uploaded_at
            ? moment(serviceDetail.coi_uploaded_at).format('DD/MM/YYYY; hh:mm A')
            : null;
        if (!lapsed && Boolean(coiUploadedAt)) {
            window.open(`${config.apiURL}/v4/subscriptions/${subscriptionId}/coi/download?insuranceType=${insuranceType}`, "_blank")
        }
    }

    handelUploadDocumentDrawer = () => {
        this.setState((prevState) => ({
            ...prevState,
            openDocumentUploadDrawer: !prevState.openDocumentUploadDrawer
        }));
    }
    
    render() {
        const formattedServiceName = splitAndCapitaliseString(this.props.serviceName);
        const { serviceDetail, product, serviceName, validity, lapsed, auth } = this.props;
        const { openDocumentUploadDrawer } = this.state;
        const coiNumber = serviceDetail.policy_number ?? 'XXXXXX';
        const coiUploadedAt = serviceDetail.coi_uploaded_at
            ? moment(serviceDetail.coi_uploaded_at).format('DD/MM/YYYY; hh:mm A')
            : null;
        const coverageAmount = serviceDetail?.coverage ?? product[serviceName]?.coverage;
        const userRoles = auth.authDetails.data.user.roles;
        return (
            <Card>
                <CardHeader>
                    {/* <CareInsuranceIcon /> */}
                    <CardHeadingBody>
                        <CardHeading>
                            {formattedServiceName}
                        </CardHeading>
                        <CardSubheading>
                            COI: {coiNumber} | Sum: {formatNumberAsIndianCurrency(coverageAmount)} | Validity: {validity}
                        </CardSubheading>
                    </CardHeadingBody>
                </CardHeader>
                <CardContent>
                    {
                        // render v:1 plans -> all addons;
                        // v:0 plans -> all addons except super-topup;
                        serviceDetail.members && serviceDetail.members.length > 0
                        ? serviceDetail?.members.map(member => {
                            return (
                                <MemberChip key={member.id}>
                                    <ProfileIcon style={{height: '15px'}} />
                                    <StyledP>{member.customer.first_name} {member.customer?.last_name ?? ''}</StyledP>
                                </MemberChip>
                            )
                        })
                        : <MemberPlaceholder>Member details will be available after customer onboarding.</MemberPlaceholder>
                    }
                </CardContent>
                <CardFooter>
                    <CardFooterColumn>
                        <CoiLabelContainer
                            coiPresent={Boolean(coiUploadedAt) && !lapsed}
                            onClick={this.downloadCOI}
                        >
                            <DocumentIcon
                                color={this.getCoiLabelColor()}
                            />
                            &nbsp;&nbsp;
                            <CardHeading
                                color={this.getCoiLabelColor()}
                            >
                                COI
                                {
                                    coiUploadedAt
                                    ? ''
                                    : this.getCoiLabelString()
                                }
                            </CardHeading>
                        </CoiLabelContainer>
                        {
                            Boolean(coiUploadedAt && !lapsed) && (
                                <FooterSubheading>
                                    COI Uploaded Date : {coiUploadedAt}
                                </FooterSubheading>
                            )
                        }
                    </CardFooterColumn>
                    {
                        checkIfManager(userRoles)
                        && Boolean(coiUploadedAt)
                        && (!lapsed)
                        && (
                            <CardFooterColumn>
                                <StyledButton onClick={this.handelUploadDocumentDrawer}>Manage</StyledButton>
                            </CardFooterColumn>
                        )
                    }
                    <Drawer disableEnforceFocus anchor="right"
                    open={openDocumentUploadDrawer}
                    onClose={() => this.handelUploadDocumentDrawer()}>
                    <Paper style={{ width: '50vh', height: '100%' }}>
                        <UploadDrawer
                            subscriptionId={this.props.subscriptionId}
                            closeCOIUploadDrawer={() => this.handelUploadDocumentDrawer()}
                            serviceName={this.props.serviceName}
                        />
                    </Paper>
                </Drawer>
                </CardFooter>
            </Card>
        )
    }
}

const mapStateToProps = ({ auth }) => {
    return {
        auth,
    }
};
const reduxWrapper = connect(mapStateToProps, null);

export default reduxWrapper(ServiceCard);
