import { color } from '../../../constants/colors';
const styles = () => ({
    header: {
        display: 'flex',
        padding: '30px 24px 10px',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${color.ROYAL_ORANGE}`
    },
    bold: {
        fontWeight: 'bold',
        fontFamily: 'Rubik'
    },
    closeIcon: {
        cursor: 'pointer'
    },
    sendLinkContainer: {
        padding: '24px'
    },
    tag: {
        textTransform: 'uppercase',
        color: color.GREY,
        marginBottom: '8px'
    },
    royalOrangeColor: {
        color: color.ROYAL_ORANGE
    },
    sendLinkActionButton:  {
        backgroundColor: color.ROYAL_ORANGE,
        color: color.WHITE,
        width: '90%',
        position: 'absolute',
        bottom: '28px',
        textTransform: 'capitalize',
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        minHeight: '36px',
        '&:hover': {
            backgroundColor: color.ROYAL_ORANGE,
        },
        '&:disabled': {
            background: color.GREY,
            color: color.WHITE,
        }
    },
    formField: {
        width: '100%',
    },
    sendViaTagContainer: {
        marginTop: '36px'
    },
    sendViaTags: {
        display: 'flex',
        marginTop: '8px',
    },
    tagNameButton: {
        borderRadius: '24px',
        color: color.GREY,
        textTransform: 'capitalize',
        marginRight: '16px'
    },
    tagNameButtonSelected: {
        backgroundColor: color.SEASHELL,
        borderColor: color.ROYAL_ORANGE,
        color: color.ROYAL_ORANGE,
        fontWeight: '600'
    },
    mL5: {
        marginLeft: '5px'
    }

})

export default styles;