import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { physicalWalkinProductId } from '../../constants';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { orange } from "@material-ui/core/colors";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import CustomerCreationConfirmation from './CustomerCreationConfirmation';
import { color } from '../../constants/colors'
import { findAddressByZipcode, resetAddressByZipcode, findCustomerByPhoneNumber, createHeadSubscriber, createAddress, getCustomerDetails, resetErrors, setNewFamilyId, setCustomerPhoneNumber, resetCreateAddressResponse } from '../../actions';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { ThemeProvider } from "@material-ui/styles";

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { TextField, Typography, Button, MenuItem, ButtonGroup, StepConnector, StepLabel, Step, Stepper, CircularProgress } from '@material-ui/core';

import { errors } from '../../common/errors';
import { errorNotification, whatsAppConsent } from '../../common/constants';
import packageJson from '../../../package.json';
import history from '../../history';

const materialTheme = createMuiTheme({
    palette: {
        primary: orange,
    },
});

const muiTheme = createMuiTheme({

    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiStepIcon: {
            root: {
                marginLeft: '4vh',
                marginRight: '2vh',
                color: 'grey',
                // or 'rgba(0, 0, 0, 1)'
                '&$active': {
                    fill: 'orange',
                    '& $text': {
                        fill: 'white',
                    },
                },
                '&$completed': {
                    marginLeft: '4vh',
                    marginRight: '2vh',
                    color: '#47B29F',
                },
            },
        },
    }
});

const motherTongue = [
    {
        value: 'hindi',
        label: 'Hindi'
    },
    {
        value: 'english',
        label: 'English',
    },
    {
        value: 'kannada',
        label: 'Kannada',
    },
    {
        value: 'tamil',
        label: 'Tamil',
    },
    {
        value: 'telugu',
        label: 'Telugu',
    },
    {
        value: 'malayalam',
        label: 'Malayalam',
    },
    {
        value: 'bangla',
        label: 'Bangla',
    },
    {
        value: 'gujarati',
        label: 'Gujarati',
    },
    {
        value: 'marathi',
        label: 'Marathi',
    },
    {
        value: 'punjabi',
        label: 'Punjabi',
    },
    {
        value: 'odia',
        label: 'Odia',
    },
]

const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: '#ffe6b1',
        height: '90vh',
        position: 'absolute',
        marginBottom: '5vh',
    },
    button: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    actionsContainer: {
        marginBottom: theme.spacing.unit * 2,
    },
    resetContainer: {
        padding: theme.spacing.unit * 3,
        backgroundColor: '#ffe6b1',
    },
    iconContainer: {
        transform: 'scale(1.5)',
    },
    connectorActive: {
        '& $connectorLine': {
            height: '25vh',
            marginLeft: '4.5vh',
        },
    },
    connectorCompleted: {
        '& $connectorLine': {
            height: '25vh',
            marginLeft: '4.5vh',
        },
    },
    connectorDisabled: {
        '& $connectorLine': {
            height: '25vh',
            marginLeft: '4.5vh',
        },
    },
    connectorLine: {
        height: '25vh',
        marginLeft: '5vh',
    },

});

function getSteps() {
    return ['  Mobile Number', '  Enter Primary Details', '  Enter Address'];
}

function disableNextDates(startDate) {
    const startSeconds = Date.parse(startDate);
    return (date) => {
        return Date.parse(date) > startSeconds;
    }
}
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class VerticalLinearStepper extends React.Component {

    constructor() {
        super();
        this.state = {
            activeStep: 0, //change back to 0
            newCustomerPhoneNumber: '',
            dob: moment().valueOf(),
            firstName: '',
            lastName: '',
            gender: 'male',
            motherTongue: '',
            pincode: '',
            city: '',
            state: '',
            areYouSure: false,
            incompleteError: false,
            showAlertForInvalidPhoneNumber: '',
            insidePinCodeField: false,
            insideCityField: false,
            insideStateField: false,
            insideDobField: false,
            insideFirstNameField: false,
            insideLastNameField: false,
            insideMTField: false,
            seconds: errorNotification.SECONDS,
            addressSubmitPressed: false,
            customerSubmitPressed: false,
            showSnackBar: false,
            whatsAppConsent: true,
            pincodeHasError: false,
        };
    }

    componentDidMount() {
        const { phoneNumber } = this.props;
        if (phoneNumber) {
            this.setState({
                newCustomerPhoneNumber: phoneNumber.length === 10 ? phoneNumber : ''
            })
        }
        this.props.resetCreateAddressResponse();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    componentDidUpdate(prevProps, prevState) {
        const oldPinCodeDetails = prevProps.newCustomer.zipcodeDetails;
        const { newCustomer, error } = this.props;
        const { showSnackBar } = this.state;
        const newPinCodeDetails = newCustomer.zipcodeDetails;
        if ((oldPinCodeDetails !== newPinCodeDetails)
        && (newPinCodeDetails.city && newPinCodeDetails.state)) {
            this.setState({
                city: newPinCodeDetails.city,
                state: newPinCodeDetails.state,
                pincodeHasError: false,
            })
        }
        if ((oldPinCodeDetails !== newPinCodeDetails)
        && (newPinCodeDetails.getAddressByZipcodeError)) {
            this.setState({
                city: '',
                state: '',
                pincodeHasError: true,
            })
        }
        if (error && error?.error?.message && !showSnackBar) {
            this.setState({
                showSnackBar: true,
                city: '',
                state: '',
            })
        }
    }

    handleChange = name => event => {
        if (name === 'firstName' || name === 'lastName' || 'city' || 'state') {
            const lettersOnlyRegex = new RegExp(`^[a-zA-Z]+$`);
            const inputValue = event.target.value;
            if (String(inputValue).match(lettersOnlyRegex) || inputValue.length === 0) {
                this.setState({
                    [name]: inputValue,
                });
            }
        } else {
            this.setState({
                [name]: event.target.value,
            });
        }
    };

    createData = (name, calories) => {
        return { name, calories };
    }


    handleDateChange = date => {
        this.setState({ dob: moment(date).valueOf() });
    };

    handleNext = () => {
        const { auth } = this.props;
        if (this.state.activeStep === 0) {
            var promise = this.props.findCustomerByPhoneNumber(this.state.newCustomerPhoneNumber);
            promise.then((error, result) => {
                if (this.props.error && this.props.error.findCustomerByPhoneNumberError === errors.CUSTOMER_NOT_FOUND) {
                    this.setState({
                        areYouSure: true,
                    });
                }
                setTimeout(() => this.props.resetErrors(), 3500);
            }
            );
        } else if (this.state.activeStep === 1 && this.state.firstName && this.state.dob && this.state.gender && this.state.motherTongue) {
            const newCustomer = {
                first_name: this.state.firstName,
                last_name: this.state.lastName ?? '',
                dob: this.state.dob,
                primary_number: this.state.newCustomerPhoneNumber,
                gender: this.state.gender,
                business_contact_number: this.state.newCustomerPhoneNumber,
                lop: this.state.motherTongue,
                whatsapp: {
                    phone: this.state.newCustomerPhoneNumber,
                    consent: this.state.whatsAppConsent ? whatsAppConsent.GRANTED : whatsAppConsent.DENIED,
                },
            };
            const newCustomerNolastname = {
                first_name: this.state.firstName,
                dob: this.state.dob,
                primary_number: this.state.newCustomerPhoneNumber,
                gender: this.state.gender,
                business_contact_number: this.state.newCustomerPhoneNumber,
                lop: this.state.motherTongue,
                whatsapp: {
                    phone: this.state.newCustomerPhoneNumber,
                    consent: this.state.whatsAppConsent ? whatsAppConsent.GRANTED : whatsAppConsent.DENIED,
                },
            };

            var primaryCustomer = this.props.createHeadSubscriber(this.state.lastName ? newCustomer : newCustomerNolastname);
            primaryCustomer.then((error, result) => {
                if (this.props.newCustomer && this.props.newCustomer.createHeadSubscriber.newCustomerStatus === 201) {
                    if (this.props.newCustomer.createHeadSubscriber !== errors.ERROR_IN_CREATING_PRIMARY_CUSTOMER) {
                        this.props.setNewFamilyId(this.props.newCustomer.createHeadSubscriber.newFamily.data.family.id);
                    }
                    this.setState({
                        activeStep: 2,
                        incompleteError: null,
                    })

                } else {
                    this.setState({ seconds: errorNotification.SECONDS })
                }
            }
            );
        } else if (this.state.activeStep === 2 && this.state.pincode.length === 6 && this.state.city && this.state.state) {
            const address = {
                family: {
                    id: this.props.newCustomer ? this.props.newCustomer.newFamilyId : null
                },
                city: this.state.city,
                zipcode: this.state.pincode,
                state: this.state.state,
            };
            const walkInLeadData = {
                lop: this.state.motherTongue,
                customer: {
                    first_name: this.state.firstName,
                    ...(this.state.lastName.length !== 0 && {last_name: this.state.lastName}),
                    phone: this.state.newCustomerPhoneNumber,
                    gender: this.state.gender,
                    lop: this.state.motherTongue,
                    dob: this.state.dob,
                },
                location: {
                    city: this.state.city,
                    state: this.state.state,
                    zipcode: this.state.pincode,
                },
                device: {
                    name: navigator.appVersion,
                    app_version: packageJson.version,
                },
                whatsapp_consent: this.state.whatsAppConsent ? whatsAppConsent.GRANTED : whatsAppConsent.DENIED,
                product: {
                    id: physicalWalkinProductId,
                },
                utm_source: 'physical_booked',
                chh_location: auth.currentClinicLocation.name,
            };

            var addressPromise = this.props.createAddress(address, true, walkInLeadData);
            addressPromise.then(() => {
                if (this.props.newCustomer && this.props.newCustomer.createNewAddress && this.props.newCustomer.createNewAddress.data && this.props.newCustomer.createNewAddress.data.id) {
                    this.props.resetCreateAddressResponse();
                    // this.props.getCustomerDetails(this.state.newCustomerPhoneNumber);
                    this.props.setCustomerPhoneNumber(this.state.newCustomerPhoneNumber);
                    history.push(`/loadingFamily`);
                    setTimeout(function () {
                        this.setState({ addressSubmitPressed: true });
                    }.bind(this), 100);
                } else {
                    this.setState({ seconds: errorNotification.SECONDS })
                }
            }
            );
        } else {
            this.setState({ incompleteError: 'Please fill all the details' })
        }
    };

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
            incompleteError: '',
        }));
        this.props.resetAddressByZipcode();
        this.props.resetErrors();
    };

    handleSearch = () => {
        this.setState(state => ({
            city: '',
            state: '',
        }));
    }

    handleReset = () => {
        this.setState({
            activeStep: 0,
        });
    };

    renderPhoneNumberField = () => {
        const { error, phoneNumber } = this.props;
        const { newCustomerPhoneNumber } = this.state;
        
        const getPhoneHelperText = () => {
            if (error && error.findCustomerByPhoneNumberError === errors.CUSTOMER_ALREADY_EXISTS) {
                return (<em>{errors.CUSTOMER_ALREADY_EXISTS}</em>);
            }
            else {
                if (phoneNumber
                    && newCustomerPhoneNumber === phoneNumber) {
                    return (<em>This customer does not exist. Please collect their details and complete the registration process.</em>)
                }
            }
        };

        return (
            <div>
                <div >
                    <Typography style={{ fontSize: '36px', marginTop: '10vh', marginLeft: '5%', paddingRight: '30%', fontFamily: 'Rubik' }}>
                        Please enter the customer's mobile number.
                    </Typography>
                    <div onMouseLeave={() => this.setState({ showAlertForInvalidPhoneNumber: true })} onMouseOver={() => this.setState({ showAlertForInvalidPhoneNumber: false })}>
                        <TextField
                            type="text"
                            inputProps={{
                                maxLength: 10,
                                style: {
                                    fontFamily: 'Rubik',
                                }
                            }}
                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                            value={this.state.newCustomerPhoneNumber}
                            style={{ marginLeft: '5%', marginTop: '5%', fontFamily: 'Rubik', fontWeight: 400 }}
                            onChange={(event) => this.setState({ newCustomerPhoneNumber: event.target.value })}
                            error={this.props.error.findCustomerByPhoneNumberError === errors.CUSTOMER_ALREADY_EXISTS || phoneNumber === newCustomerPhoneNumber}
                            helperText={getPhoneHelperText()}
                        >
                        </TextField>
                    </div>
                </div>

                <Dialog
                    style={{ height: '100%', width: '100vw', borderRadius: '60vh' }}
                    open={this.state.areYouSure}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <p style={{ textAlign: 'center', fontFamily: 'Rubik', fontWeight: 'bold' }}>Confirm number</p>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography style={{ fontSize: '14px', fontFamily: 'Rubik', color: 'black', textAlign: 'center' }} >{this.state.newCustomerPhoneNumber}</Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ activeStep: 0, areYouSure: false }) }} style={{ color: 'white', backgroundColor: ' #f38b44', textTransform: 'capitalize', width: '18vh', margin: '2vh 1vh 2vh 2vh' }}>
                            Change
                        </Button>
                        <Button onClick={() => { this.setState({ activeStep: 1, areYouSure: false }) }} style={{ color: 'white', backgroundColor: ' #f38b44', textTransform: 'capitalize', width: '18vh', margin: '2vh 2vh 2vh 1vh' }} color="primary" autoFocus>
                            Proceed
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        );

    };


    renderPrimaryDetailsField = () => {
        const { whatsAppConsent } = this.state;
        if (this.state.customerSubmitPressed) {
            return (
                <div >
                    <CircularProgress style={{
                        color: 'orange',
                        left: '50%',
                        top: '50%',
                        position: 'absolute',

                    }} disableShrink />
                </div>
            )
        } else {
            return (
                <div style={{ overflowY: 'scroll' }}>
                    {(this.props.error && this.props.error.creatingPrimaryCustomer === errors.ERROR_IN_CREATING_PRIMARY_CUSTOMER && (this.state.seconds > 1)) ?
                        <div style={{ backgroundColor: '#f66a6a', height: '5vh' }}>
                            <Typography style={{ marginLeft: '3vh', marginTop: '5px', color: 'white' }}> Error while creating a customer !!</Typography>
                        </div>
                        : <> </>}
    
                    <Typography style={{ fontSize: '36px', marginTop: '10vh', marginLeft: '5%', paddingRight: '30%', fontFamily: 'Rubik' }}>
                        Collect the following primary details from the customer.
                    </Typography>
                    <div style={{ overflowY: 'scroll', margin: '5%' }}>
                        <div style={{ display: 'flex', marginTop: '1.5%', flexDirection: 'row' }}>
                            <TextField
                                onMouseLeave={() => { this.setState({ insideFirstNameField: false }) }}
                                onMouseOver={() => { this.setState({ insideFirstNameField: true }) }}
                                helperText={!this.state.firstName && !this.state.insideFirstNameField ? 'Please fill this detail' : ''}
                                error={!this.state.firstName && !this.state.insideFirstNameField}
                                onChange={this.handleChange('firstName')} label="First Name" value={this.state.firstName}
                                style={{ marginRight: '4%', width: '19%', fontFamily: 'Rubik' }}
                                inputProps={{
                                    maxLength: 60,
                                }}
                                required
                            />
                            <TextField
                                onChange={this.handleChange('lastName')}
                                onMouseLeave={() => { this.setState({ insideLastNameField: false }) }}
                                onMouseOver={() => { this.setState({ insideLastNameField: true }) }}
                                label="Last Name" value={this.state.lastName}
                                style={{ width: '20%', fontFamily: 'Rubik' }}
                                inputProps={{
                                    maxLength: 60,
                                }}
                            />
                        </div>
                        <div style={{ display: 'flex', marginTop: '1.5%', flexDirection: 'row' }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <ThemeProvider theme={materialTheme}>
                                    <KeyboardDatePicker
                                        onMouseLeave={() => { this.setState({ insideDobField: false }) }}
                                        onMouseOver={() => { this.setState({ insideDobField: true, insideFirstNameField: false, insideMTField: false }) }}
                                        error={!this.state.dob && !this.state.insideDobField}
                                        style={{ marginRight: '5vh', width: '19%', fontFamily: 'Rubik' }}
                                        label="DOB"
                                        value={this.state.dob || ''}
                                        onChange={this.handleDateChange}
                                        format="dd-MM-yyyy"
                                        helperText={!this.state.dob && !this.state.insideDobField ? 'Please fill this detail' : " Ex: 16-12-1950"}
                                        shouldDisableDate={disableNextDates(new Date())}
                                    />
                                </ThemeProvider>
                            </MuiPickersUtilsProvider>
                            <TextField
                                onMouseLeave={() => { this.setState({ insideMTField: false }) }}
                                onMouseOver={() => { this.setState({ insideMTField: true, insideFirstNameField: false }) }}
                                error={!this.state.insideMTField && !this.state.motherTongue}
                                helperText={!this.state.insideMTField && !this.state.motherTongue ? 'Please select the language' : ''}
                                onChange={this.handleChange('motherTongue')} label="Mother Tongue" select value={this.state.motherTongue}
                                style={{ marginRight: '5%', width: '20%', fontFamily: 'Rubik' }}
                            >       {motherTongue.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
    
                            ))}
                            </TextField>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', margin: '5vh' }}>
                        <ButtonGroup>
                            <Button
                                onClick={() => this.setState({ gender: 'male' })}
                                style={{ width: '10vh', textTransform: 'capitalize', backgroundColor: this.state.gender === 'male' ? '#F38B43' : '#E5E5E5', color: this.state.gender === 'male' ? 'white' : '#908F8F' }}>Male</Button>
                            <Button
                                onClick={() => this.setState({ gender: 'female' })}
                                style={{ width: '10vh', textTransform: 'capitalize', backgroundColor: this.state.gender === 'female' ? '#F38B43' : '#E5E5E5', color: this.state.gender === 'female' ? 'white' : '#908F8F' }} >Female</Button>
                            <Button
                                onClick={() => this.setState({ gender: 'other' })}
                                style={{ width: '10vh', textTransform: 'capitalize', backgroundColor: this.state.gender === 'other' ? '#F38B43' : '#E5E5E5', color: this.state.gender === 'other' ? 'white' : '#908F8F' }} >Other</Button>
                        </ButtonGroup>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', margin: '5vh' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Checkbox
                                onChange={(event) => {
                                    this.setState({
                                        whatsAppConsent: event.target.checked,
                                    });
                                }}
                                checked={whatsAppConsent}
                                style={{ marginLeft: '-1vh', color: color.ROYAL_ORANGE }}
                                color='primary' aria-label="type checkbox" />
                            <Typography style={{ fontWeight: 'bold', fontSize: '16px', fontFamily: 'Rubik' }}>WhatsApp consent for communication.</Typography>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '70%' }}>
                            <Typography style={{ fontWeight: '400', fontSize: '16px', fontFamily: 'Rubik' }}>Patient would be sent prescription, notifications, reminders and any offer updates on Whatsapp.</Typography>
                        </div>
                    </div>
                </div>
            );
        } 
    };



    renderAddressField = () => {
        const { pincodeHasError, pincode } = this.state;

        const getPincodeHelperText = () => {
            if (pincode.length !== 6) {
                return "The pincode must be 6 digits long.";
            }
            else if (pincodeHasError) {
                return "This pincode is invalid";
            }
            return "";
        }

        if (this.state.addressSubmitPressed) {
            return (
                <div >
                    <CircularProgress style={{
                        color: 'orange',
                        left: '50%',
                        top: '50%',
                        position: 'absolute',

                    }} disableShrink />
                </div>
            )
        } else {
            return (
                <div >
                    {(this.props.newCustomer.createNewAddress === errors.ERROR_IN_CREATING_ADDRESS && (this.state.seconds > 1)) ?
                        <div style={{ backgroundColor: '#f66a6a', height: '5vh', }}>
                            <Typography style={{ marginLeft: '3vh', marginTop: '5px', color: 'white' }}> Error while adding address</Typography>
                        </div> : <> </>}
                    <Typography style={{ fontSize: '36px', marginTop: '10vh', marginLeft: '5%', paddingRight: '30%', fontFamily: 'Rubik' }}>
                        Request for pincode and confirm the customer's location.
                    </Typography>
                    <TextField
                        autoFocus
                        value={this.state.pincode}
                        label="Pincode"
                        type="text"
                        error={this.state.pincode.length !== 6 || pincodeHasError}
                        helperText={getPincodeHelperText()}
                        inputProps={{
                            maxLength: 6,
                        }}
                        onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                        onChange={(event) => {
                            const pincodeInput = event.target.value;
                            const { findAddressByZipcode } = this.props;
                            this.setState({
                                pincode: pincodeInput,
                                pincodeHasError: !pincodeHasError,
                            });
                            if (pincodeInput.length === 6) {
                                findAddressByZipcode(pincodeInput);
                            };
                        }}
                        style={{ marginLeft: '5%', marginTop: '4%', width: '20%' }}>
                    </TextField>
                    <div
                        style={{ display: 'block', marginLeft: '5%', width: '100%', height: '100%', color: 'white' }}
                        onMouseOver={() => { this.setState({ insidePinCodeField: false }) }}
                        onMouseLeave={() => { this.setState({ insidePinCodeField: false }) }}>
                        .
                    </div>
                    <>
                        <TextField
                            onChange={this.handleChange('city')}
                            label="City"
                            value={this.state.city}
                            helperText={(this.state.pincode.length !== 6 && this.state.city.length === 0)
                                && "Please enter a valid pin code to fill this field."}
                            style={{ display: 'flex', marginLeft: '5%', width: '20%', marginTop: '6%' }}
                            disabled
                        />
                        <TextField
                            onChange={this.handleChange('state')}
                            label="State"
                            value={this.state.state}
                            helperText={(this.state.pincode.length !== 6 && this.state.city.length === 0)
                                && "Please enter a valid pin code to fill this field."}
                            style={{ display: 'flex', marginLeft: '5%', width: '20%', marginTop: '6%' }}
                            disabled
                        />
                    </>
                </div>
            );
        }
    };

    renderSecondTab = () => {
        switch (this.state.activeStep) {
            case 0:
                return this.renderPhoneNumberField();
            case 1:
                return this.renderPrimaryDetailsField();
            case 2:
                return this.renderAddressField();
            default:
                return this.renderPhoneNumberField();
        }
    }

    render() {
        const { classes, error, resetErrors } = this.props;
        const steps = getSteps();
        const { activeStep, showSnackBar } = this.state;
        const connector = (
            <StepConnector
                classes={{
                    active: classes.connectorActive,
                    completed: classes.connectorCompleted,
                    disabled: classes.connectorDisabled,
                    line: classes.connectorLine,
                }}
            />
        );
        // if (this.state.activeStep === 3) {
        //     return <CustomerCreationConfirmation newCustomerPhoneNumber={this.state.newCustomerPhoneNumber} />
        // } else {
        return (
            <>
                {showSnackBar && Boolean(error?.error?.message) && <Snackbar
                    open={showSnackBar}
                    onClose={() => {
                        this.setState({
                            showSnackBar: !showSnackBar,
                        }, () => {
                        resetErrors();
                        });
                    }}
                    autoHideDuration={2000}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert style={{ backgroundColor: color.ROYAL_ORANGE }} severity="info">
                        {error?.error?.message ?? 'An error occured'}
                    </Alert>
                </Snackbar>}
                <div style={{ minWidth: '50vw', position: 'fixed' }}>
                    <div >
                        <div style={{ backgroundColor: '#ffe6b1', minWidth: '80vh', float: 'left' }}>
                            <div className={classes.root}>
                                <MuiThemeProvider theme={muiTheme}>
                                    <Stepper activeStep={activeStep} orientation="vertical" style={{ backgroundColor: "transparent" }} connector={connector} >
                                        {steps.map((label, index) => (
                                            <Step key={label} classes={{
                                                iconContainer: classes.iconContainer
                                            }} >
                                                <StepLabel classes={{
                                                    iconContainer: classes.iconContainer
                                                }} >{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </MuiThemeProvider>
                            </div>
                        </div>
                        <div style={{ backgroundColor: 'white', float: 'right', minHeight: '90vh', minWidth: '70vw', position: 'fixed', marginLeft: '25%' }}>
                            <div >
                                {this.renderSecondTab()}
                            </div>
                            <div className={classes.actionsContainer}  >

                                <div
                                    style={{ bottom: 0 }}
                                >
                                    {this.state.activeStep !== 0 && this.state.activeStep !== 2 ?
                                        <Button
                                            variant="contained"
                                            disabled={activeStep === 0}
                                            onClick={this.handleBack}
                                            className={classes.button}
                                            style={{
                                                position: 'fixed',
                                                bottom: 0,
                                                marginLeft: '5%',
                                                marginRight: '50%',
                                                marginBottom: '2%',
                                                color: 'white',
                                                backgroundColor: 'orange',
                                                width: '8%',
                                            }}
                                        >
                                            <Typography>Back</Typography>
                                        </Button>
                                        : <> </>}
                                    {this.state.activeStep === 0 ? <Button
                                        disabled={this.state.newCustomerPhoneNumber.length !== 10}
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleNext}
                                        className={classes.button}
                                        style={{
                                            position: 'fixed',
                                            bottom: 0,
                                            right: 0,
                                            marginRight: '5%',
                                            marginBottom: '2%',
                                            backgroundColor: this.state.newCustomerPhoneNumber.length !== 10 ? 'grey' : 'orange',
                                            width: '8%',
                                            color: this.state.newCustomerPhoneNumber.length !== 10 ? '#b5bcc7' : 'white',
                                        }}
                                    >
                                        {activeStep === 2 ? 'Submit' : 'Next'}
                                    </Button> : <> </>}
                                    {this.state.activeStep === 1 ? <Button
                                        disabled={!this.state.firstName
                                            || !this.state.dob
                                            || !this.state.motherTongue
                                            || !this.state.gender}
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleNext}
                                        className={classes.button}
                                        style={{
                                            position: 'fixed',
                                            bottom: 0,
                                            right: 0,
                                            marginBottom: '2%',
                                            marginRight: '5%',
                                            backgroundColor: !this.state.firstName || !this.state.dob || !this.state.motherTongue || !this.state.gender ? 'grey' : 'orange',
                                            width: '8%',
                                            color: !this.state.firstName  || !this.state.dob || !this.state.motherTongue || !this.state.gender ? '#b5bcc7' : 'white',
                                        }}
                                    >
                                        {activeStep === 2 ? 'Submit' : 'Next'}
                                    </Button> : <> </>}
                                    {this.state.activeStep === 2 ? <Button
                                        disabled={this.state.pincode.length !== 6 || !this.state.city || !this.state.state}
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleNext}
                                        className={classes.button}
                                        style={{
                                            position: 'fixed',
                                            bottom: 0,
                                            right: 0,
                                            marginBottom: '2%',
                                            marginRight: '5%',
                                            backgroundColor: this.state.pincode.length !== 6 || !this.state.city || !this.state.state ? 'grey' : 'orange',
                                            width: '8%',
                                            color: this.state.pincode.length !== 6 || !this.state.city || !this.state.state ? '#b5bcc7' : 'white',
                                        }}
                                    >
                                        Submit
                                    </Button> : <> </>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
        // };
    }
}

VerticalLinearStepper.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = (state) => {
    const { auth, familyDetails, newCustomer, error } = state;
    return { auth, familyDetails, newCustomer, error };
};

const mapDispatchToProps = {
    findAddressByZipcode,
    resetAddressByZipcode,
    findCustomerByPhoneNumber,
    createHeadSubscriber,
    createAddress,
    getCustomerDetails,
    resetErrors,
    setNewFamilyId,
    setCustomerPhoneNumber,
    resetCreateAddressResponse,
};

const reduxWrapper = connect(mapStateToProps, mapDispatchToProps);
const styleWrapper = withStyles(styles, { withTheme: true });
export default styleWrapper(reduxWrapper(VerticalLinearStepper));

