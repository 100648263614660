import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Box, Typography, Button, CircularProgress } from '@material-ui/core';
import AutoComplete from "../../common/AutoComplete";

import { addCustomerMetadataEvent } from '../../../actions';
import style from './style';
import CloseIcon from '@material-ui/icons/Close';
import _ from "lodash";
import { getAllOcupationList } from '../../../clients/cdnService';
class AddressDetails extends Component {
    
    constructor() {
        super();
        this.state = {
            occupation: '',
            occupationList: []
        }
    }

    fetchOccupations = async () => {
        const occupationList = await getAllOcupationList()
        return occupationList.occupations;
    }

    async componentDidMount() {
        try {
            const occupationList = await this.fetchOccupations();
            this.setState({
                occupationList
            })
        } catch (error) {
            console.log(error);
        }
    }

    getCircularProgress = () => {
        return <CircularProgress style={{
            color: 'orange',
            left: '50%',
            top: '55%',
            position: 'absolute',
        }} />;
    }

    handelInput(input) {
        this.setState({
            occupation: input
        })
    }

    addOccupation() {
        const { memberId, addCustomerMetedata, familyDetails, closeOccupationDrawer } = this.props;
        const { occupation } = this.state;
        const requestPayload = {
            payload: {
                occupation
            }
        }
        const allMembers = _.map(familyDetails.allMembers.members, (member) => {
            if(member.id === memberId) {
                return {
                    ...member,
                    metadata: {
                        ...member.metadata,
                        occupation: occupation
                    }
                }
            }
            return member;
        })
        addCustomerMetedata(memberId, requestPayload, allMembers);
        closeOccupationDrawer();
    }
 
    render() {
        
        const { classes, closeOccupationDrawer, familyDetails } = this.props;
        const { occupationList, occupation } = this.state;
        return (
            <>
                <Box className={classes.header}>
                    <Typography className={classes.bold}> Add Occupation </Typography>
                    <CloseIcon className={classes.closeIcon} onClick={() => closeOccupationDrawer()} />
                </Box>
                {familyDetails.isLoading ? this.getCircularProgress() : <></>}
                <Box className={classes.addressContainer}>
                    <AutoComplete autoCompleteOptions={occupationList} labelTitle="Occupation" handelInput={(input) => this.handelInput(input)}/>
                    <Button disabled={!occupation || occupation.length < 2} onClick={() => this.addOccupation()} className={classes.saveChangesButton}> Save Changes </Button>
            </Box>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const {
        familyDetails, newCustomer
    } = state;
    return {
        familyDetails,
        newCustomer
    };
};

const mapDispatchToProps = (dispatch) => ({
    addCustomerMetedata: (cutomerId, payload, allMembers) => { dispatch(addCustomerMetadataEvent(cutomerId, payload, allMembers)); },
});

const ConnectedAddressDetails = withStyles(style,
    { withTheme: true })(connect(mapStateToProps,
        mapDispatchToProps)(withTranslation()(AddressDetails)));
export default ConnectedAddressDetails;
