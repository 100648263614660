import { color } from "../../../constants/colors";

const useStyles = (theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        fontFamily: 'Rubik',
    },
    row: {
        '&:first-child': {
            margin: '0vh 1vh',
        },
        marginTop: '1vh',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0vh 2vh',
    },
    filled: {
        background: color.WHITE,
    },
    header: {
        fontWeight: 'bold',
        fontSize: '11px',
        textTransform: 'uppercase',
    },
    filterControls: {
        height: '90px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    control: {
        padding: '2vh',
    },
    searchBtn: {
        background: color.ROYAL_ORANGE,
        color: color.WHITE,
        '&:hover': {
            background: color.ORANGE,
        },
        borderRadius: '4px',
        textTransform: 'capitalize',
        fontFamily: 'Rubik',
        fontWeight: 'bold',
    }
});

export default useStyles;