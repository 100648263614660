import React from 'react';
import { connect } from 'react-redux';
import { withStyles, Drawer, Button, TextField } from '@material-ui/core';
import { settleBill } from '../../../actions/paymentAction';
import useStyles from './drawer_styles';
import { getOrderTotal } from '../../../common/utils';
import { ReactComponent as PaymentsIcon } from '../../../assets/icons2/common/payments.svg';
import { ReactComponent as ArrowRightWhite } from '../../../assets/icons2/common/arrow-right-whiite.svg';
import { offlinePaymentSources } from '../../../common/constants';
import { isEqual } from 'lodash';
import { itemTypes } from '../../../constants';
import { careWalletUrl, careWalletApiKey } from '../../../config';

class ConfirmationDrawer extends React.Component {
    constructor(props) {
        super(props);
        const { paymentBreakup } = this.props;
        this.state = {
            couponCode: null,
            credit: null,
            instruments: paymentBreakup.instruments.map(instrument => ({
                used: Number(instrument.value),
                confirmed: '',
                label: instrument.label,
                type: instrument.type,
            })),
            walletDrawerOpen: false,
            walletTransactionId: '',
        };
        this.walletManagerIframe = React.createRef(null);
    }

    componentDidMount() {
        const { setWalletTransactionId } = this.props;
        window.addEventListener("message", (event) => {
            // Check if the origin of the iframe sending the message is
            // of the care wallet.
            // if (event.origin === careWalletUrl) {
                if (event.data.func === 'closeWidgetDrawer') {
                    // if the message posted specifies to just copy the message,
                    // we don't set the txn ID or close the drawer.
                    if (event.data.copy) {
                        navigator.clipboard.writeText(event.data.transaction_id);
                    } else {
                        event.target.value = event.data.transaction_id;
                        setWalletTransactionId(event);
                        this.setState({
                            walletTransactionId: event.target.value,
                        });
                        this.setState({
                            walletDrawerOpen: false,
                        });
                    }
                }
            // }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const { paymentBreakup, walletTransactionId, cash, pos, qr, wallet, opd } = this.props;
        const oldPaymentBreakupArr = [prevProps.cash, prevProps.pos, prevProps.qr, prevProps.wallet, prevProps.opd];
        const newPaymentBreakupArr = [cash, pos, qr, wallet, opd];
        const oldWalletTransactionId = prevProps.walletTransactionId;
        const updatedWalletTransactionId = walletTransactionId;
        if ((oldWalletTransactionId !== updatedWalletTransactionId)) return;
        if (!isEqual(oldPaymentBreakupArr, newPaymentBreakupArr)) {
            const newInstrumentList = paymentBreakup.instruments.map(instrument => ({
                used: instrument.value,
                confirmed: '',
                label: instrument.label,
                type: instrument.type,
            }));
            this.setState({
                instruments: newInstrumentList,
            })
        }
    }

    shouldConfirmButtonBeDisabled = () => {
        const { instruments, walletTransactionId } = this.state;
        const { consultationDetails, payment, paymentBreakup } = this.props;
        if (consultationDetails.billing.customer.id === '') return true;
        if (consultationDetails?.bill && consultationDetails?.bill?.isLoading) {
            return true;
        }
        if (payment.creditDeductionResponseLoading) {
            return true;
        }
        if (paymentBreakup.credit.applied) {
            const discount = consultationDetails.creditDetails.amount;
            if (getOrderTotal(consultationDetails.billing.items) <= discount) {
                return false;
            }
        }
        if (instruments.some(instrument => Number(instrument.used) !== Number(instrument.confirmed))) {
            return true;
        }
        if (instruments.some(instrument => instrument.type === offlinePaymentSources.WALLET) && walletTransactionId === '') {
            return true;
        }
        
        return false;
    }

    handleInputOnChange = (event, instrumentIndex) => {
        const { instruments } = this.state;
        let input = event.target.value;
        const regExForLeadingZero = RegExp(/^0\d*/g);
        // check if some number is already input;
        // if yes, prevent leading 0's;
        if ((input.length !== 0 && !regExForLeadingZero.test(input)) || input === '') {
            const newInstrumentList = [...instruments];
                newInstrumentList[instrumentIndex].confirmed = input;
            this.setState({
                instruments: newInstrumentList,
            });
        }
    }

    handleSubmitButtonOnClick = () => {
        const { settleBill, paymentBreakup } = this.props;
        const { walletTransactionId } = this.state;
        paymentBreakup.walletTransactionId = walletTransactionId;
        settleBill(paymentBreakup);
    }

    getConfirmButtonText = () => {
        const { consultationDetails, payment } = this.props;
        if ((consultationDetails.bill && consultationDetails.bill.isLoading)
            || (payment && payment.creditDeductionResponseLoading)) {
            return 'Loading'
        } else {
            return 'Confirm'
        }
    }

    getInstrumentInputClassName = (instrument) => {
        const { classes } = this.props;
        if (instrument.confirmed?.length === 0 || instrument.confirmed === null) return classes.input;
        if (instrument.used !== Number(instrument.confirmed)) return classes.input_wrong;
        if (instrument.used === Number(instrument.confirmed)) return classes.input_correct;
        return classes.input;
    }

    render() {
        const { instruments, walletTransactionId } = this.state;
        const {
            classes, open, toggleDrawer, paymentBreakup, familyDetails,
            setWalletTransactionId, consultationDetails, getAvailableCreditAmount
        } = this.props;
        const lineItems = consultationDetails.billing.items;
        const walletInstrumentIndex = instruments.findIndex(instrument => instrument.type === 'wallet');
        let walletInstrument = null;
        if (walletInstrumentIndex !== -1) {
            walletInstrument = instruments[walletInstrumentIndex];
        }
        const nonWalletInstruments = instruments.filter(instrument => instrument.type !== 'wallet' && instrument.type !== 'generic');
        const familyId = Number(familyDetails.currentCustomer.family?.id);
        const customerBeingBilled = consultationDetails.billing.customer.id;
        const payableByWallet = walletInstrument?.used ?? 0;
        let walletManagerUrl = `${careWalletUrl}/widgets/drawer-wallet/${familyId}/${customerBeingBilled}?payable=${payableByWallet}&access_token=${careWalletApiKey}`;
        const orderTotal = getOrderTotal(consultationDetails.billing.items);
        let discount = 0;
        if (paymentBreakup.couponCode.applied) {
            discount = getAvailableCreditAmount('couponCode');
        }
        if (paymentBreakup.credit.applied) {
            discount = getAvailableCreditAmount('credit');
        }
        // this payable amount will be collected by the backend serving the iframe;
        const totalBillAmount = orderTotal - discount;
        walletManagerUrl += `&total_bill_amount=${totalBillAmount}`;
        const consultationLineItemIndex = lineItems.filter(item => item.type === itemTypes.CONSULTATION);
        if (consultationLineItemIndex !== -1) {
            walletManagerUrl += `&consultation_fee=${200}`;
        }
        const disableConfirmButton = this.shouldConfirmButtonBeDisabled();
        const confirmButtonText = this.getConfirmButtonText();
        return (
            <Drawer
                disableEnforceFocus
                anchor="right"
                open={open}
                onClose={toggleDrawer}
            >
                <div className={classes.container}>
                        <div className={classes.header}>
                            <span>Payment Confirmation</span>
                            <span className={classes.close} onClick={toggleDrawer}>&times;</span>
                        </div>
                        <div className={classes.subHeader}>
                            <span>Confirmation</span>
                            <span className={classes.explainer}>
                                Write amount with respect to payment mode to proceed.
                            </span>
                        </div>
                        <div className={classes.content}>
                            <div>
                                {
                                    nonWalletInstruments.map((instrument, index) => {
                                        const instrumentIndex = paymentBreakup.instruments.findIndex(usedInstrument => usedInstrument.type === instrument.type);
                                        return (
                                            <div className={classes.row} key={index}>
                                                <span className={classes.item}>{instrument.label}</span>
                                                <TextField
                                                    inputProps={{
                                                        style: {
                                                            textAlign: 'right',
                                                            padding: '0x 4px',
                                                            fontSize: '0.9rem',
                                                        }
                                                    }}
                                                    InputProps={{
                                                        startAdornment: '₹',
                                                        disableUnderline: true,
                                                    }}
                                                    type="number"
                                                    className={this.getInstrumentInputClassName(instrument)}
                                                    placeholder="Amount"
                                                    value={instrument.confirmed}
                                                    onKeyDown={(evt) => ["e", "E", "+", "-", ","].includes(evt.key) && evt.preventDefault()}
                                                    onChange={(event) => { this.handleInputOnChange(event, instrumentIndex) }}
                                                />
                                            </div>
                                        )
                                    })
                                }
                                {
                                    walletInstrument && (
                                        <div className={classes.walletManager}>
                                            <div className={`${classes.cardRow} ${classes.headerRow}`}>
                                                <div className={classes.logo}>
                                                    <PaymentsIcon />
                                                </div>
                                                    <div className={classes.walletHeader}>
                                                        Open Care Wallet Manager
                                                    </div>
                                                    <button
                                                        className={classes.openCareWalletManagerBtn}
                                                        onClick={() => {
                                                            this.setState({
                                                                walletDrawerOpen: true
                                                            })
                                                        }}
                                                    >
                                                        <ArrowRightWhite className={classes.arrow} />
                                                    </button>
                                            </div>
                                            <div className={classes.row}>
                                                <span className={classes.item}>{walletInstrument.label}</span>
                                                <TextField
                                                    inputProps={{
                                                        style: {
                                                            textAlign: 'right',
                                                            padding: '0x 4px',
                                                            fontSize: '0.9rem',
                                                        }
                                                    }}
                                                    InputProps={{
                                                        startAdornment: '₹',
                                                        disableUnderline: true,
                                                    }}
                                                    type="number"
                                                    className={this.getInstrumentInputClassName(walletInstrument)}
                                                    placeholder="Amount"
                                                    value={walletInstrument.confirmed}
                                                    onKeyDown={(evt) => ["e", "E", "+", "-", ","].includes(evt.key) && evt.preventDefault()}
                                                    onChange={(event) => { this.handleInputOnChange(event, walletInstrumentIndex) }}
                                                />
                                            </div>
                                            <input
                                                type="text"
                                                onChange={(event) => {
                                                    setWalletTransactionId(event);
                                                    this.setState({
                                                        walletTransactionId: event.target.value
                                                    })
                                                }}
                                                value={walletTransactionId}
                                                placeholder="Paste transaction ID for Wallet"
                                                className={classes.transactionIdInput}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                            <div>
                                <Button
                                    className={classes.generateButton}
                                    variant="contained"
                                    disabled={disableConfirmButton}
                                    onClick={() => {
                                        // trigger the order creation waterfall here;
                                        // order > payment > bill generated > mark-bill-generated;
                                        this.handleSubmitButtonOnClick()
                                    }}
                                >
                                    {confirmButtonText}
                                </Button>
                            </div>
                        </div>
                </div>
                {
                    walletInstrument && (
                    <Drawer
                        open={this.state.walletDrawerOpen}
                        anchor="right"
                        onClose={() => {
                            this.setState({
                                walletDrawerOpen: false,
                            })
                    }}>
                        <iframe
                            style={{
                                width: '320px',
                                overflow: 'hidden',
                                outline: '0px',
                                border: '0px',
                                background: 'black',
                                height: '100%',
                            }}
                            id="walletmanager"
                            scrolling="no"
                            title="Care Wallet Manager"
                            loading='eager'
                            src={walletManagerUrl}
                            ref={this.walletManagerIframe}
                            onLoad={(event) => {
                                this.walletManagerIframe.current = event.currentTarget;
                                // const transactionIdTexatArea = event.currentTarget.contentWindow.document.getElementById("transaction_id");
                                // transactionIdTexatArea.onload = () => {
                                //     window.navigator.clipboard.writeText(transactionIdTexatArea?.innerText ?? "Nothing could be copied");
                                // }
                            }}
                        />
                    </Drawer>)
                }
            </Drawer>
        )
    }
};

const mapDispatchToProps = (dispatch) => ({
    settleBill: (paymentBreakup) => { dispatch(settleBill(paymentBreakup)); },
});

const mapStateToProps = (state) => {
    const {
        consultationDetails, payment,
        familyDetails
    } = state;
    return {
        consultationDetails, payment,
        familyDetails
    }
}

const reduxWrapper = connect(mapStateToProps, mapDispatchToProps);

const styleWrapper = withStyles(useStyles, { withTheme: true });
export default reduxWrapper(styleWrapper(ConfirmationDrawer));