import React from 'react';
import { connect } from 'react-redux';
import LoginPage from './LoginPage';
import SideMenuBar from '../components/Home/sideMenuBar/SideMenuBar';
import TopBar from '../components/Home/topBar/TopBar';
import { withStyles } from "@material-ui/core/styles/";
import { Paper } from "@material-ui/core/";
import orange from '@material-ui/core/colors/orange';
import { resetCustomerPhoneNumber } from '../actions';
import AdminTaskList from '../components/AdminTask/AdminTaskList'; 

const styles = theme => ({
    palette: {
        primary: orange,
    },
    indicator: {
        backgroundColor: "orange"
    },
    default_tabStyle: {
        color: 'grey',
    },
    active_tabStyle: {
        fontWeight: 'bold',
        color: 'black',
    }
});
class AdminTaskPage extends React.Component {

    componentDidMount() {
        this.props.resetCustomerPhoneNumber();
    }

    render() {
        if (!this.props.auth.isLoggedIn) {
            return (
                <LoginPage />
            )
        } else {
            return (
                <div className='main' style={{
                    height: "100vh",
                    minWidth: '80vw',
                    // marginLeft: '10vh',
                    // display: 'flex',
                    backgroundColor: '#f4f4f4',

                }}>
                    <TopBar />
                    <div style={{ display: 'flex' }}>
                        <SideMenuBar />
                        <div>
                            <Paper style={{ height: '89vh' , width: '92vw'}}>
                            <AdminTaskList/>
                            </Paper>
                        </div>
                        </div>
                    </div>

            );
        }
    }
}

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error };
};


export default connect(mapStateToProps, {resetCustomerPhoneNumber})(withStyles(styles)(AdminTaskPage));
