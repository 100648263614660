import { color } from "../../../constants/colors";

const styles = () => ({
    preferenceCardContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '44px 24px 0 36px',
        height: '270px',
        marginBottom: '30px',
        flex: '1'
    },
    borderRight: {
        borderRight: '1px solid grey'
    },
    borderRightNone: {
        borderRight: 'none'
    },
    textContainer: {
        height: '60%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    ghdTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    preferenceCardHeading: {
        fontSize: '18px',
    },
    ghdCardHeading: {
        fontSize: '18px',
        marginBottom: '20px',
    },
    comment: {
        padding: '16px 20px',
        backgroundColor: '#F9FAFB',
        borderRadius: '8px',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        color: '#6B7280',
    },
    preferenceCardSubHeading: {
        color: '#808080',
        fontSize: '14px',
    },
    ghdCardSubHeading: {
        fontSize: '14px',
        color: '#808080',
        marginLeft: '-22px',
    },
    ghdRadioGroup: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    selectionContainer: {
        display: 'flex',
        height: '40%'
    },
    ghdSelectionContainer: {
        display: 'flex',
        marginTop: '2%',
    },
    selectionTitle: {
        flex: '0.5',
        fontWeight: 'bold',
        alignSelf: 'center'
    },
    radioGroup: {
        width: '100%',
        flexDirection: 'row',
        flex: '0.5',
    },
    radioButtonListContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    radioButtonLabel: {
        fontSize: '13px',
        alignSelf: 'center'
    },
    radioButton: {
        margin: '0'
    },
    caption: {
        fontFamily: 'Rubik',
        fontSize: '12px',
        color: color.greys[100],
    }
});
export default styles;
