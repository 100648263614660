import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import SideMenuBar from '../components/Home/sideMenuBar/SideMenuBar';
import HomeOptions from '../components/Home/HomeOptions';
import LoginPage from './LoginPage';
import SelectClinicLocationPage from './SelectClinicLocationPage';
import TopBar from '../components/Home/topBar/TopBar';
import { resetErrors, logOut } from '../actions';

const { errors } = require('../common/errors');
class HomePage extends React.Component {

  render() {
    if (!this.props.auth.isLoggedIn) {
      return (
        <LoginPage />
      )
    } else if (this.props.error && this.props.error.currentCustomerErrorCode === errors.FORBIDDEN) {
      return (
        <LoginPage />
      )
    } else if (!_.isEmpty(this.props.auth.currentClinicLocation)) {
      return (
        <div className='main' style={{
          height: "100vh",
          minWidth: '80vw',
          // marginLeft: '10vh',
          // display: 'flex',
          backgroundColor: '#f4f4f4',

        }}>
          <TopBar />
          <div style={{ display: 'flex' }}>
            <SideMenuBar />
            <HomeOptions />
          </div>

        </div>
      );
    } else {
      return (
        <SelectClinicLocationPage />
      )
    }
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth, familyDetails: state.familyDetails, error: state.error };
}

export default connect(mapStateToProps, { resetErrors, logOut })(HomePage);