import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { MenuItem, TextField, Button } from '@material-ui/core';

import { ReactComponent as ClinikkFullLogo } from '../images/clinikk_full.svg';
import { ReactComponent as OnboardingIcon } from '../images/Onboarding.svg';

import { verifyOtp, initiateOtp, resetErrors, setCurrentClinicLocation, getAllClinics } from '../actions';
import LoginPage from './LoginPage';
class SelectClinicLocationPage extends React.Component {

    state = { allLocations: [], currentLocation: '' };

    componentDidMount() {
        const getAllClinincsPromise = this.props.getAllClinics();
        getAllClinincsPromise.then(() => {
            if (this.props.consultationDetails && this.props.consultationDetails.allClinics && !_.isEmpty(this.props.consultationDetails.allClinics.clinics)) {
                this.setState({ allLocations: this.props.consultationDetails.allClinics.clinics })
            }
        }
        )
    }

selectLocation = (location) => {
    this.setState({currentLocation : location })
}

handleChange = name => event => {
    this.setState({
        [name]: event.target.value,
    });
};

render() {
    if (this.props.auth && this.props.auth.phoneNumber && this.props.auth.isLoggedIn && !this.props.auth.currentClinicLocation) {
        return (
            <div className='main' style={{
                height: "100vh", display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'row'
            }}
            >
                <div style={{ backgroundColor: "#f4f4f4", width: "50vh", height: "100%" }}>
                    <div style={{
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        marginTop: '20vh',
                    }} >
                        <OnboardingIcon />
                    </div>
                </div>
                <div style={{ backgroundColor: "white", width: "70%", height: "100%", }}>
                    {/* {(this.props.error.verifyOtpErrorCode && this.state.notificationBarDelay > 1) ?
                            <div style={{ backgroundColor: '#f66a6a', height: '5vh', }}>
                                <Typography style={{ marginLeft: '3vh', marginTop: '5px', color: 'white' }}> {this.props.error.verifyOtpErrorCode}</Typography>
                            </div> : <> </>} */}
                    <div style={{
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        paddingTop: '30vh',
                        paddingLeft: '10vh',
                        margin: "auto"
                    }} >
                        <ClinikkFullLogo />
                        <h3 style={{ fontSize: '30px', fontFamily: 'Rubik' }}>Choose your location.</h3>
                        <TextField
                            style={{ width: '11rem', marginBottom: '1.5rem' }}
                            select
                            label="Location"
                            value={this.state.currentLocation}
                            onChange={this.handleChange('currentLocation')}
                            margin="normal"
                        >
                            {this.state.allLocations.map(option => {
                                return (
                                    <MenuItem
                                        key={option.name}
                                        value={option.name}
                                        onClick={() => this.selectLocation(option.name)}
                                    >
                                        {option.name}
                                    </MenuItem>
                                )
                            }
                            )}
                        </TextField>


                        <br />
                        <Button onClick={() => this.props.setCurrentClinicLocation(_.find(this.state.allLocations, { 'name': this.state.currentLocation }))} disabled={!this.state.currentLocation} variant="contained" style={{ backgroundColor: this.state.currentLocation ? "orange" : "grey", color: "white", display: 'block', width: '15vh' }}>
                            Confirm
</Button>
                    </div>
                </div>
            </div>
        );

    } else {
        return (
            <LoginPage />
        );
    }
}
};

const mapStateToProps = (state) => {
    return { auth: state.auth, familyDetails: state.familyDetails, consultationDetails: state.consultationDetails, error: state.error };
}

export default connect(mapStateToProps, { verifyOtp, initiateOtp, resetErrors, setCurrentClinicLocation, getAllClinics })(SelectClinicLocationPage);