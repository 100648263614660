import config  from '../config';

const axios = require('axios');

export const searchBussinessesByName = async (ACCESS_TOKEN, name) => {
    const url = config.apiURL + `/v1/businesses?name=${name}`;
    const options = {
        headers: {
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + ACCESS_TOKEN,
    
        },
        timeout: 10000,
        responseType: 'json',
        responseEncoding: 'utf8',
      };
    const response = await axios.get(url, options);
    return response.data;
};

export const updateFamilyBusinessMapping = async (ACCESS_TOKEN, familyId, data) => {
    const url = config.apiURL + `/v3/family/${familyId}/businesses`;
    const options = {
        headers: {
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + ACCESS_TOKEN,
    
        },
        timeout: 10000,
        responseType: 'json',
        responseEncoding: 'utf8'
      };
    const response = await axios.put(url, data, options);
    return response.data;
};

export const deleteFamilyBusinessMapping = async (ACCESS_TOKEN, familyId, businessId) => {
    const url = config.apiURL + `/v3/family/${familyId}/businesses/${businessId}/mark-inactive`;
    const options = {
        headers: {
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + ACCESS_TOKEN,
    
        },
        timeout: 10000,
        responseType: 'json',
        responseEncoding: 'utf8'
      };
    const response = await axios.put(url, {}, options);
    return response.data;
};