import createAuthRefreshInterceptor from 'axios-auth-refresh';
import axios from 'axios';
import config from '../config';
import { authHelper } from '../common/authHelper';
import { assignIn } from 'lodash';

export * from './authService';
export * from './ticketingService';
export * from './consultationService';
export * from './addressService';

createAuthRefreshInterceptor(axios, async (failedRequest) => {
    const localData = localStorage.getItem('persist:root');
    const { auth } = JSON.parse(localData);
    const parsedAuthData = JSON.parse(auth);
    const session = parsedAuthData.authDetails.data;
    const response = await authHelper.refreshAuthLogic(config, session.refresh_token,
        '/v1/oauth/token', failedRequest);
    const parsedLocalData = JSON.parse(localData);
    const modifiedLocalData = assignIn(parsedLocalData, {
        auth: {
            ...parsedAuthData,
            authDetails: {
                data: response.data,
            },
        }
    });
    modifiedLocalData.auth = JSON.stringify(modifiedLocalData.auth);
    localStorage.setItem('persist:root', JSON.stringify(modifiedLocalData));
    failedRequest.response.config.headers.Authorization = `Bearer ${response.data.access_token}`;
    window.location.reload();
    return response;
  });