import React from 'react';
import _ from 'lodash';

import { connect } from 'react-redux';
import { TextField, Divider, Typography, Button } from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { orange } from "@material-ui/core/colors";
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as LadyDoctor } from '../../../../images/LadyDoctor.svg';
import { ReactComponent as BookingConfirmed } from '../../../../images/BookingConfirmed.svg';
import { consultationStatus } from '../../../../common/constants';

import { getConsultationById, createVitals, resetAddVitalsStatus, getConsultationsByClinicIdFirstPage, setPresentableConsultationQueue } from '../../../../actions';
import { errors } from '../../../../common/errors';

import moment from 'moment';

const materialTheme = createMuiTheme({
    palette: {
        primary: orange,
    },
});

class AddVitalsForm extends React.Component {

    state = {
        height: '',
        weight: '',
        bpRate: '',
        pulseRate: '',
        spRate: '',
        temperature: '',
        respiratoryRate: '',
        grbs: '',
        submitButtonPressed: false,
        vitalsCreated: false,
        crt: '',
    };

    componentDidMount() {
        // call prescriptions by consultation id
        this.props.getConsultationById(this.props.consultationId);
    }

    componentDidUpdate(prevProps) {
        const { consultationDetails, closeDrawer } = this.props;
        if ((prevProps.consultationDetails.addingVitalsStatus !== consultationDetails.addingVitalsStatus)
            && (consultationDetails?.addingVitalsStatus?.status === 201)) {
            setTimeout(closeDrawer, 2000);
        }
    }

    getUpcomingConsultationsFirstPage = () => {
        const startDate = moment().startOf('day').valueOf();
        const endDate = moment().endOf('day').valueOf();
        // const consultationsPromise = this.props.fetchConsultationsByCustomerId(this.props.customerId, this.state.currentConsultationPage);
        if (this.props.auth && this.props.auth.currentClinicLocation && this.props.auth.currentClinicLocation.id) {
            const consultationQueuePromise = this.props.getConsultationsByClinicIdFirstPage(this.props.auth.currentClinicLocation.id, consultationStatus.ASSIGNED, 1, 'asc', startDate, endDate);
            consultationQueuePromise.then(() => {
                if (this.props.consultationDetails && this.props.consultationDetails.consultationQueue.consultations) {
                    this.props.setPresentableConsultationQueue(this.props.consultationDetails.consultationQueue.consultations)
                }
            })
        }
    }

    handleChange = name => event => {
        if (name === 'crt') {
            const crtValue = event.target.value;
            if (!isNaN(parseFloat(crtValue)) || crtValue === '' || parseFloat(crtValue) === 0) {
                this.setState({
                    [name]: crtValue,
                });
            }
        } else {
            this.setState({
                [name]: event.target.value,
            });
        }
    };

    calculateBmi = () => {
        const bmi = (this.state.weight / ((this.state.height / 100) * (this.state.height / 100))).toFixed(2);
        // return (this.props.consultationDetails && (this.props.consultationDetails.addingVitalsStatus === errors.ERROR_WHILE_ADDING_VITALS)) ? 0 : bmi ;
        return bmi;
    }

    resetAddVitalsForm = () => {
        this.setState({ submitButtonPressed: false })
        this.props.resetAddVitalsStatus();
    }


    clickSaveButton = () => {
        const {
            height, weight, bpRate, spRate, pulseRate,
            temperature, respiratoryRate, grbs, crt,
        } = this.state;
        const vitalsObject = {
            height: { value: height },
            weight: { value: weight },
            bmi: { value: this.calculateBmi() },
            ...(bpRate.length > 0 && {blood_pressure: { value: bpRate }}),
            blood_oxygen_saturation: { value: spRate },
            pulse_rate: { value: pulseRate },
            temperature: { value: temperature },
            respiratory_rate: { value: respiratoryRate },
            ...(grbs.length > 0 && {grbs: { value: grbs }}),
            ...(crt.length > 0 && {crt: { value: crt }}),
        };
        
        this.setState({ submitButtonPressed: true })
        const createVitalsPromise = this.props.createVitals(vitalsObject, this.props.consultationId);
        createVitalsPromise.then(() => {
            if (this.props.consultationDetails && this.props.consultationDetails.addingVitalsStatus && this.props.consultationDetails.addingVitalsStatus.status === 201) {
                this.setState({ vitalsCreated: true })
                // this.getUpcomingConsultationsFirstPage();
            }
        })
    };

    render() {
        const { consultationId, queue } = this.props;
        const { submitButtonPressed, crt } = this.state;
        const currentConsultation = queue?.filter(consultation => consultation.id === consultationId)[0];
        const selectedCustomer = currentConsultation.customer;
        if (submitButtonPressed) {
            if (!currentConsultation?.prescription?.vitals && this.state.submitButtonPressed) {
                return (
                    <div> <CircularProgress style={{ color: 'orange', marginLeft: '48%', marginRight: '48%', marginTop: '50vh' }} disableShrink /> </div>
                )
            } else if (this.props.consultationDetails?.focussedConsultation === errors.ERROR_IN_FETCHING_PARTICULAR_CONSULTATION) {
                return (
                    <div> Error while loading vitals</div>
                )
            } else if (this.props.consultationDetails && this.props.consultationDetails.addingVitalsStatus === errors.ERROR_WHILE_ADDING_VITALS) {
                return (<div><Typography style={{ fontFamily: 'Rubik-Light', marginLeft: '27%', marginRight: '27%', width: '46%', marginTop: '45vh' }}> Error while adding vitals</Typography>
                    <Button style={{ backgroundColor: 'orange', color: 'white', marginLeft: '35%', marginRight: '35%', width: '30%', marginTop: '3vh' }} onClick={() => this.resetAddVitalsForm()}> Retry</Button></div>)

            } else if (this.props.consultationDetails && this.props.consultationDetails.addingVitalsStatus && this.props.consultationDetails.addingVitalsStatus.status === 201) {
                return (<div><Typography style={{ fontFamily: 'Rubik-Light', marginLeft: '25%', marginRight: '25%', width: '50%', marginTop: '45vh' }}> Vitals added successfully</Typography>
                    <BookingConfirmed style={{ marginLeft: '35%', marginRight: '35%', width: '30%', marginTop: '3vh' }} /></div>)
            }
        } else if (!submitButtonPressed) {
            if (!_.isEmpty(currentConsultation?.prescription?.vitals)) {
                return (
                    <div >
                        <Typography style={{ color: 'black', fontWeight: 'bold', fontSize: '1.2rem', fontFamily: 'Rubik', marginLeft: '0.8rem', marginBottom: '0.6rem', marginTop: '1vh' }}>Enter Vitals</Typography>
                        <Divider style={{ backgroundColor: 'orange' }} />
                        <LadyDoctor style={{ marginLeft: '25%', marginRight: '25%', width: '50%', marginTop: '35vh' }} />
                        <Typography style={{ fontFamily: 'Rubik-Light', fontSize: '1.7vh', marginLeft: '26%', marginRight: '26%', width: '48%' }}>The vitals have been recorded</Typography></div>
                )
            }
            return (
                <form style={{ position: 'relative' }} >
                    <Typography style={{ color: 'black', fontWeight: 'bold', fontSize: '1.2rem', fontFamily: 'Rubik', marginLeft: '0.8rem', marginBottom: '0.6rem', marginTop: '1vh' }}>Enter Vitals</Typography>
                    <Divider style={{ backgroundColor: 'orange' }} />
                    <div style={{ position: 'relative' }}>
                        <TextField onChange={this.handleChange('height')} value={this.state.height} label="Height (cm)" style={{ position: 'absolute', display: 'flex', width: '40%', marginLeft: '1rem', marginRight: '1rem', marginTop: '1rem', marginBottom: '1.5rem' }} />
                        <TextField onChange={this.handleChange('weight')} value={this.state.weight} label="Weight (kg)" style={{ position: 'absolute', display: 'flex', width: '40%', marginLeft: '1rem', marginRight: '1rem', marginTop: '1rem', marginBottom: '10vh', left: '50%' }} />
                    </div>
                    <div style={{ marginTop: '12vh' }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={materialTheme}>
                                <TextField onChange={this.handleChange('bmi')} value={this.calculateBmi() > 0 ? this.calculateBmi() : ''} label="BMI" style={{ display: 'flex', marginLeft: '1rem', marginRight: '1rem', marginTop: '2rem', marginBottom: '1.5rem', borderBottom: 'none' }} />
                                <TextField onChange={this.handleChange('bpRate')} value={this.state.bpRate} label="BP Reading (mm of Hg)" style={{ display: 'flex', marginLeft: '1rem', marginRight: '1rem', marginTop: '2rem', marginBottom: '1.5rem' }} />
                                <TextField onChange={this.handleChange('pulseRate')} value={this.state.pulseRate} label="Pulse Rate (beats per min)" style={{ display: 'flex', marginLeft: '1rem', marginRight: '1rem', marginTop: '2rem', marginBottom: '1.5rem' }} />
                                <TextField onChange={this.handleChange('spRate')} value={this.state.spRate} label="SpO2 (%)" style={{ display: 'flex', marginLeft: '1rem', marginRight: '1rem', marginTop: '2rem', marginBottom: '1.5rem' }} />
                                <TextField onChange={this.handleChange('temperature')} value={this.state.temperature} label="Body Temperature (F)" style={{ display: 'flex', marginLeft: '1rem', marginRight: '1rem', marginTop: '2rem', marginBottom: '1.5rem' }} />
                                <TextField onChange={this.handleChange('respiratoryRate')} value={this.state.respiratoryRate} label="Respiratory (per min)" style={{ display: 'flex', marginLeft: '1rem', marginRight: '1rem', marginTop: '2rem', marginBottom: '1.5rem' }} />
                                <TextField onChange={this.handleChange('grbs')} value={this.state.grbs} label="GRBS (mg/dL)" style={{ display: 'flex', marginLeft: '1rem', marginRight: '1rem', marginTop: '2rem', marginBottom: '1.5rem' }} />
                                <TextField onChange={this.handleChange('crt')} value={crt} label="CRT (seconds)" style={{ display: 'flex', marginLeft: '1rem', marginRight: '1rem', marginTop: '2rem', marginBottom: '1.5rem' }} />
                            </ThemeProvider>
                        </MuiPickersUtilsProvider>
                    </div>
                    <div style={{
                        position: 'fixed',
                        bottom: '0px',
                        right: '0px', width: '50vh'
                    }}>
                        <Button onClick={this.clickSaveButton} disabled={
                            !this.state.height ||
                            !this.state.weight ||
                            (selectedCustomer.age >= 10 && !this.state.bpRate) ||
                            !this.state.pulseRate ||
                            !this.state.spRate ||
                            !this.state.temperature ||
                            !this.state.respiratoryRate
                        }
                            style={{
                                backgroundColor: (
                                    !this.state.height ||
                                    !this.state.weight ||
                                    (selectedCustomer.age >= 10 && !this.state.bpRate) ||
                                    !this.state.pulseRate ||
                                    !this.state.spRate ||
                                    !this.state.temperature ||
                                    !this.state.respiratoryRate
                                ) ? 'grey' : 'orange', color: 'white', width: '100%', height: '3rem'
                            }}> Submit</Button>
                    </div>
                </form>);
        }
        return (
            <div> <CircularProgress style={{ position: 'absolute', color: 'orange', left: '45%', right: '50%', top: '50%', bottom: '50%' }} disableShrink />
                {(this.props.familyDetails && this.props.familyDetails.newMember === errors.ERROR_IN_ADDING_NEW_MEMBER) ? <Typography style={{ fontSize: '1.5vh', color: 'red', position: 'absolute', left: '15%', right: '15%', top: '60%', bottom: '30%' }}> Error while adding member. Please try again later !</Typography> : <> </>}</div>
        );
    };
};

const mapStateToProps = (state) => {
    return { auth: state.auth, familyDetails: state.familyDetails, error: state.error, consultationDetails: state.consultationDetails };
};

export default connect(mapStateToProps, { getConsultationById, createVitals, resetAddVitalsStatus, getConsultationsByClinicIdFirstPage, setPresentableConsultationQueue })(AddVitalsForm);