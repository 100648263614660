import { ticketingActionTypes, sortType, dateType, apiStatus, consultationActionTypes } from '../constants';

const INITIAL_STATE = {
    localTicketFilterOptions: {
        sortMode: sortType.ASCENDING,
        dateType: dateType.YESTERDAY,
    },
    selectedTicket: null,
    feedbackForm: {
        rating: 0,
        comment: '',
    },
    postFeedbackApiStatus: {},
    getCustomerPreferencesApiStatus: {},
    sendPrescriptionLinkSmsApiStatus: {},
    sendPrescriptionLinkWhatsappApiStatus: {},
    sendPlaystoreLinkSmsApiStatus: {},
    sendPlaystoreLinkWhatsappApiStatus: {},
};

const ticketingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ticketingActionTypes.GET_TICKETS_COUNT_STATUS_REQUEST:
            return { ...state, ticketCountLoading: true };
        case ticketingActionTypes.GET_TICKETS_COUNT_STATUS_SUCCESS:
            return { ...state, ticketCountLoading: false, ticketCount: action.payload };
        case ticketingActionTypes.GET_TICKETS_COUNT_STATUS_FAILURE:
            return { ...state, ticketCountLoading: false };
        case ticketingActionTypes.GET_TICKETS_REQUEST:
            return { ...state, ticketListLoading: true };
        case ticketingActionTypes.GET_TICKETS_SUCCESS:
            return { 
                ...state, ticketListLoading: false,
                ticketList: action.payload.recentTicketsFetched,
                selectedTicket: action.payload && action.payload.total_count > 0
                ? action.payload.tickets[0] : {}
            };
        case ticketingActionTypes.GET_TICKETS_FAILURE:
            return { ...state, ticketListLoading: false };
        case ticketingActionTypes.UPDATE_LOCAL_TICKET_FILTER_OPTIONS_SUCCESS:
            return { ...state, localTicketFilterOptions: action.payload };
        case ticketingActionTypes.SELECT_CURRENT_TICKET:
            return { ...state, selectedTicket: action.payload }
        case ticketingActionTypes.ADD_COMMENTS_TO_TICKET_REQUEST:
            return { ...state, addCommentLoading: true, addCommentResponse: null }
        case ticketingActionTypes.ADD_COMMENTS_TO_TICKET_SUCCESS:
            return { ...state, addCommentLoading: false, addCommentResponse: apiStatus.SUCCESS }
        case ticketingActionTypes.ADD_COMMENTS_TO_TICKET_FAILURE:
            return { ...state, addCommentLoading: false, addCommentResponse: apiStatus.FAILURE }
        case ticketingActionTypes.RESET_ADD_COMMENT:
            return { ...state, addCommentLoading: null, addCommentResponse: null }
        case consultationActionTypes.BOOK_CONSULTATION_REQUEST:
            return { ...state, bookConsultationLoading: true, bookConsultationResponse: null }
        case consultationActionTypes.BOOK_CONSULTATION_SUCCESS:
            return { ...state, bookConsultationLoading: false, bookConsultationResponse: apiStatus.SUCCESS }
        case consultationActionTypes.BOOK_CONSULTATION_FAILURE:
            return { ...state, bookConsultationLoading: false, bookConsultationResponse: apiStatus.FAILURE }
        case ticketingActionTypes.MARK_RESOLVE_TICKET_REQUEST:
            return { ...state, markResolveLoading: true, markResolveResponse: null }
        case ticketingActionTypes.MARK_RESOLVE_TICKET_SUCCESS:
            return { ...state, markResolveLoading: false, markResolveResponse: apiStatus.SUCCESS }
        case ticketingActionTypes.MARK_RESOLVE_TICKET_FAILURE:
            return { ...state, markResolveLoading: false, markResolveResponse: apiStatus.FAILURE }
        case ticketingActionTypes.RESET_MARK_RESOLVE:
            return { ...state, markResolveLoading: null, markResolveResponse: null }
        case ticketingActionTypes.SET_FEEDBACK_RATING:
            return {
                ...state,
                feedbackForm: {
                    ...state.feedbackForm,
                    rating: action.payload,
                },
            }
        case ticketingActionTypes.SET_FEEDBACK_COMMENT:
            return {
                ...state,
                feedbackForm: {
                    ...state.feedbackForm,
                    comment: action.payload,
                },
            }
        case ticketingActionTypes.RESET_FEEDBACK_COMMENT:
            return {
                ...state,
                feedbackForm: {
                    ...state.feedbackForm,
                    comment: '',
                },
            }
        case ticketingActionTypes.RESET_FEEDBACK_RATING:
            return {
                ...state,
                feedbackForm: {
                    ...state.feedbackForm,
                    rating: 0,
                },
            }
        case ticketingActionTypes.RESET_FEEDBACK_FORM:
            return {
                ...state,
                feedbackForm: {
                    rating: 0,
                    comment: '',
                },
                postFeedbackApiStatus: {},
                getCustomerPreferencesApiStatus: {},
                sendPrescriptionLinkSmsApiStatus: {},
                sendPrescriptionLinkWhatsappApiStatus: {},
                sendPlaystoreLinkSmsApiStatus: {},
                sendPlaystoreLinkWhatsappApiStatus: {},
            }
        case ticketingActionTypes.POST_FEEDBACK_REQUEST:
            return {
                ...state,
                postFeedbackApiStatus: {
                    isLoading: true,
                },
            }
        case ticketingActionTypes.POST_FEEDBACK_SUCCESS:
            return {
                ...state,
                postFeedbackApiStatus: {
                    isLoading: false,
                    status: 'success',
                    data: action.payload,
                },
            }
        case ticketingActionTypes.POST_FEEDBACK_FAILURE:
            return {
                ...state,
                postFeedbackApiStatus: {
                    isLoading: false,
                    status: 'failure',
                },
            }
        case ticketingActionTypes.RESET_POST_FEEDBACK_API_STATUS:
            return {
                ...state,
                postFeedbackApiStatus: {},
            }
        case ticketingActionTypes.GET_CUSTOMER_PREFERENCES_REQUEST:
            return {
                ...state,
                getCustomerPreferencesApiStatus: {
                    isLoading: true,
                }
            }
        case ticketingActionTypes.GET_CUSTOMER_PREFERENCES_SUCCESS:
            return {
                ...state,
                getCustomerPreferencesApiStatus: {
                    isLoading: false,
                    status: 'success',
                    data: action.payload,
                }
            }
        case ticketingActionTypes.GET_CUSTOMER_PREFERENCES_FAILURE:
            return {
                ...state,
                getCustomerPreferencesApiStatus: {
                    isLoading: false,
                    status: 'failure',
                }
            }
        case ticketingActionTypes.RESET_GET_CUSTOMER_PREFRENCES_API_STATUS:
            return {
                ...state,
                getCustomerPreferencesApiStatus: {},
            }
        case ticketingActionTypes.SET_PREFERENCES_NOT_FOUND:
            return {
                ...state,
                getCustomerPreferencesApiStatus: {
                    isLoading: false,
                    status: 'failure',
                    preferencesNotFound: true
                }
            }
        case ticketingActionTypes.SEND_PRESCRIPTION_LINK_SMS_REQUEST:
            return {
                ...state,
                sendPrescriptionLinkSmsApiStatus: {
                    isLoading: true,
                },
            }
        case ticketingActionTypes.SEND_PRESCRIPTION_LINK_SMS_SUCCESS:
            return {
                ...state,
                sendPrescriptionLinkSmsApiStatus: {
                    isLoading: false,
                    status: 'success',
                    data: action.payload,
                },
            }
        case ticketingActionTypes.SEND_PRESCRIPTION_LINK_SMS_FAILURE:
            return {
                ...state,
                sendPrescriptionLinkSmsApiStatus: {
                    isLoading: false,
                    status: 'failure',
                },
            }
        case ticketingActionTypes.RESET_SEND_PRESCRIPTION_LINK_SMS_API_STATUS:
            return {
                ...state,
                sendPrescriptionLinkSmsApiStatus: {},
                sendPrescriptionLinkWhatsappApiStatus: {}
            }
        case ticketingActionTypes.SEND_PRESCRIPTION_LINK_WHATSAPP_REQUEST:
            return {
                ...state,
                sendPrescriptionLinkWhatsappApiStatus: {
                    isLoading: true,
                },
            }
        case ticketingActionTypes.SEND_PRESCRIPTION_LINK_WHATSAPP_SUCCESS:
            return {
                ...state,
                sendPrescriptionLinkWhatsappApiStatus: {
                    isLoading: false,
                    status: 'success',
                    data: action.payload,
                },
            }
        case ticketingActionTypes.SEND_PRESCRIPTION_LINK_WHATSAPP_FAILURE:
            return {
                ...state,
                sendPrescriptionLinkWhatsappApiStatus: {
                    isLoading: false,
                    status: 'failure',
                },
            }
        case ticketingActionTypes.RESET_SEND_PRESCRIPTION_LINK_WHATSAPP_API_STATUS:
            return {
                ...state,
                sendPrescriptionLinkWhatsappApiStatus: {},
            }
        case ticketingActionTypes.SEND_PLAYSTORE_LINK_SMS_REQUEST:
            return {
                ...state,
                sendPlaystoreLinkSmsApiStatus: {
                    isLoading: true,
                },
            }
        case ticketingActionTypes.SEND_PLAYSTORE_LINK_SMS_SUCCESS:
            return {
                ...state,
                sendPlaystoreLinkSmsApiStatus: {
                    isLoading: false,
                    status: 'success',
                    data: action.payload,
                },
            }
        case ticketingActionTypes.SEND_PLAYSTORE_LINK_SMS_FAILURE:
            return {
                ...state,
                sendPlaystoreLinkSmsApiStatus: {
                    isLoading: false,
                    status: 'failure',
                },
            }
        case ticketingActionTypes.RESET_SEND_PLAYSTORE_LINK_SMS_API_STATUS:
            return {
                ...state,
                sendPlaystoreLinkSmsApiStatus: {},
                sendPlaystoreLinkWhatsappApiStatus: {},
            }
        case ticketingActionTypes.SEND_PLAYSTORE_LINK_WHATSAPP_REQUEST:
            return {
                ...state,
                sendPlaystoreLinkWhatsappApiStatus: {
                    isLoading: true,
                },
            }
        case ticketingActionTypes.SEND_PLAYSTORE_LINK_WHATSAPP_SUCCESS:
            return {
                ...state,
                sendPlaystoreLinkWhatsappApiStatus: {
                    isLoading: false,
                    status: 'success',
                    data: action.payload,
                },
            }
        case ticketingActionTypes.SEND_PLAYSTORE_LINK_WHATSAPP_FAILURE:
            return {
                ...state,
                sendPlaystoreLinkWhatsappApiStatus: {
                    isLoading: false,
                    status: 'failure',
                },
            }
        case ticketingActionTypes.RESET_SEND_PLAYSTORE_LINK_WHATSAPP_API_STATUS:
            return {
                ...state,
                sendPlaystoreLinkSmsApiStatus: {},
            }
        default:
            return state;
    }
};

export default ticketingReducer;

