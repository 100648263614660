import React, { Component } from 'react';
import { styled, withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Box, Typography, Button, CircularProgress,
    LinearProgress, Snackbar, ButtonGroup,
    Badge,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import { ReactComponent as PDFIcon } from '../../../assets/icons/pdf-icon.svg';

import axios from 'axios';

import style from './style';
import { addCustomerMetadataEvent, getSubscriptionById } from '../../../actions';
import config from '../../../config';
import { color } from '../../../constants/colors';
import moment from 'moment/moment';
import { round } from 'lodash';
import { buildCustomError } from '../../../common/utils';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} icon={false}/>;
}

const acceptedMimeTypes = 'application/pdf';
const StyledPdfIcon = styled(PDFIcon)({
    scale: 1.5
});

const PersistentButton = styled(Button)(({ selected = false }) => ({
    background: selected ? color.ROYAL_ORANGE : color.oranges[100],
    color: selected ? color.WHITE : color.GREY,
    padding: '1vh',
    borderRadius: '12px',
    textTransform: 'capitalize',
    fontFamily: 'Rubik',
    '&:hover': {
        background: selected ? color.ORANGE : color.oranges[100],
    }
}));

class UploadDrawer extends Component {
    constructor() {
        super();
        this.state = {
            progress: {
                percent: 0,
                fileSizeUploaded: 0,
                fileTotalSize: 0
            },
            fileSelected: false,
            currentFile: null,
            uploading: false,
            error: false,
            errorMsg: null,
            showSnackBar: false,
            snackbarMessage: '',
            documents: [],
            isLoading: false,
            mode: 'merge'
        }
    }

    filePickerRef = React.createRef(null);

    uploadCOI() {
        const { auth, subscriptionId, serviceName, getSubscriptionById } = this.props;
        const { documents, mode } = this.state;
        const coiFormData = new FormData();
        coiFormData.append('file_operation', mode);
        // TODO: This logic will break for Super Top Up COI docs - need to update;
        coiFormData.append('insurance_type', serviceName.split('_')[0]);
        documents.forEach((doc) => {
            coiFormData.append('files', doc.file);
        });
        this.setState({
            uploading: true,
        });
        const options = {
            // onUploadProgress: (progressEvent) => {
            //     const { loaded, total } = progressEvent;
            //     const percent = Math.floor((loaded * 100) / total);
            //     this.setState({
            //         progress: {
            //             percent,
            //             fileSizeUploaded: loaded / 1000,
            //             fileTotalSize: total / 1000
            //         },
            //     })
            // },
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': 'Bearer ' + auth.authDetails.data.access_token,
            },
        }
        let coiUploadUrl = config.apiURL + '/v1/subscriptions/' + subscriptionId + '/update-coi';
        axios.post(coiUploadUrl, coiFormData, options)
            .then(response => {
                const { documents } = this.state;
                const updatedDocuments = documents.map((doc) => ({
                    ...doc,
                    status: 'success',
                    progress: doc.file.size,
                }))
                setTimeout(() => {
                    this.setState({
                        showSnackBar: true,
                        snackbarMessage: `${documents.length} ${documents.length > 1 ? 'files were' : 'file was'} ${mode}d successfully!`,
                        documents: updatedDocuments,
                        error: false,
                        uploading: false,
                    });
                    getSubscriptionById(subscriptionId);
                }, 500);
            })
            .catch(err => {
                const error = buildCustomError(err);
                this.setState({
                    error: true,
                    errorMsg: 'Error occured, Please try again ',
                    documents: [],
                    uploading: false,
                    progress: null,
                    showSnackBar: true,
                    snackbarMessage: error.message,
                });
            });

    }

    getCircularProgress = () => {
        return <CircularProgress style={{
            color: color.WHITE,
            height: '20px',
            width: '20px'
        }} />;
    }

    handleDocumentPickerOnChange = (e) => {
        const { documents } = this.state;
        const selectedFiles = e.target.files;
        const updatedDocuments = documents.slice();
        // selectedFiles is a FileList object;
        // the files contained in this FileList can be
        // accessed using fileListObject.item(indexOfFile);
        let selectedFile = null;
        for (let i = 0; i < selectedFiles.length; i++) {
            selectedFile = selectedFiles.item(i);
            if (!acceptedMimeTypes.split(',').includes(selectedFile.type)) {
                alert(selectedFile.name + " is not a supported file type. Please select only PDF files!");
            } else if (selectedFile.size / 1000000 > 10) {
                alert(selectedFile.name + `  is ${round(selectedFile.size / 1000000, 1)}MB in size. Please select a file smaller than 10MB!`);
            } else {
                updatedDocuments.push({
                    file: selectedFiles.item(i),
                    id: `${moment().valueOf()}${i}`,
                    isUploading: false,
                    progress: Number(0),
                    status: null,
                    type: '',
                });
            }
        }
        this.setState({
            documents: updatedDocuments,
        });
    };
    
    toggleMode = (mode) => {
        this.setState({
            mode,
        })
    }

    handleDeleteOnClick = (docToRemove) => {
        const { documents } = this.state;
        const updatedDocuments = documents.filter(doc => doc.id !== docToRemove.id);
        this.setState({
            documents: updatedDocuments,
        });
        if (updatedDocuments.length === 0) {
            this.filePickerRef.current.value = null;
        }
    };

    render() {
        const { classes, closeCOIUploadDrawer, familyDetails } = this.props;
        const {
            uploading, error, snackbarMessage,
            showSnackBar, mode, documents,
        } = this.state;
        return (
            <>
                <Snackbar
                    open={showSnackBar}
                    onClose={() => {
                        this.setState({
                            showSnackBar: false,
                        });
                    }}
                    autoHideDuration={1200}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    style={{
                        width: '320px',
                        borderRadius: '8px'
                    }}
                >
                    <Alert
                        style={{
                            background: !error
                            ? color.greens[100]
                            : color.reds[100],
                            width: '350px',
                            fontFamily: 'Rubik',
                            fontWeight: 'bold',
                            borderRadius: '8px'
                        }}
                        
                        >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
                <Box className={classes.header}>
                    <Typography className='rubikBold'> Update COI Document </Typography>
                    <CloseIcon className={classes.closeIcon} onClick={() => closeCOIUploadDrawer()} />
                </Box>
                {familyDetails.isLoading ? this.getCircularProgress() : <></>}
                <ButtonGroup
                    variant="outlined"
                    size="large"
                    classes={{
                        root: classes.buttonGroup_root,
                        // grouped: classes.buttonGroup_children
                    }}
                    fullWidth
                >
                    <PersistentButton selected={mode === 'merge'} onClick={() => {this.toggleMode('merge')}}>Merge</PersistentButton>
                    <PersistentButton selected={mode === 'replace'} onClick={() => {this.toggleMode('replace')}}>Replace</PersistentButton>
                </ButtonGroup>
                <Box className={classes.fileContainer}>
                    <label htmlFor="file" className={classes.uploadText}> <span className={classes.browse}>Browse</span> to upload a file</label>
                    <span className={classes.browseCaption}>File format: PDF, should be less than 10MB. {mode === 'replace' ? 'Only 1 file' : 'Up to 10 files'} allowed.</span>
                    <input
                        onChange={(e) => this.handleDocumentPickerOnChange(e)}
                        id="file" className={classes.hidden}
                        type="file"
                        max="10"
                        accept={acceptedMimeTypes}
                        multiple={mode === 'merge'}
                        ref={this.filePickerRef}
                    />
                </Box>
                {
                    documents.length > 0 && 
                    <div className={classes.selectedFilesContainer}>
                        {
                            documents.map((doc) => {
                                return (
                                    <div className={classes.documentContainer} key={doc.name}>
                                        <div className={classes.documentRow}>
                                            {/* file preview + upload progress */}
                                            <div className={classes.previewColumn}>
                                                {/* preview */}
                                                <Badge
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    overlap="rectangular"
                                                    component="span"
                                                    badgeContent="×"
                                                    classes={{
                                                        anchorOriginTopLeftRectangular: classes.closePreviewBtn,
                                                    }}
                                                    onClick={() => { this.handleDeleteOnClick(doc) }}
                                                    invisible={doc.progress / doc.file.size === 1}
                                                >
                                                    <StyledPdfIcon />
                                                </Badge>
                                            </div>
                                            <div className={classes.documentColumn}>
                                                {/* details */}
                                                <div className={classes.documentSubRow}>
                                                    <span className={classes.documentName}>
                                                        {doc.file.name}
                                                    </span>
                                                </div>
                                                <div className={classes.progressSubRow}>
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={(doc.progress / doc.file.size) * 100}
                                                        classes={{
                                                            colorPrimary: doc.status === 'failed'
                                                                ? classes.colorPrimary_failure
                                                                : classes.colorPrimary,
                                                            barColorPrimary: doc.status === 'failed'
                                                                ? classes.colorPrimary_failure
                                                                : classes.barColorPrimary,
                                                        }}
                                                    />
                                                </div>
                                                <div className={classes.documentSubRow}>
                                                    <span className={classes.fileSizeLabel}>
                                                        {round((doc.progress / 1000), 1)}KB of {round(doc.file.size / (1000), 1)}KB
                                                    </span>
                                                    {
                                                        doc.isUploading
                                                            ? (
                                                                <span className={classes.progressLabel}>
                                                                    Uploading... {round(doc.progress / doc.file.size) * 100}%
                                                                </span>
                                                            )
                                                            : (
                                                                <span className={classes.progressLabel}>
                                                                    {doc.status === 'failed' ? 'Failed' : null}
                                                                    {doc.status === 'success' ? <>Uploaded <strong>100%</strong></> : null}
                                                                </span>
                                                            )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                <Box className={classes.clinicContainer}>
                    <Button
                        disabled={documents.length === 0
                            || uploading
                            || ((!uploading) && documents.every(doc => doc.status === 'success'))
                        }
                        onClick={() => this.uploadCOI()}
                        className={classes.saveChangesButton}
                    >
                        {uploading ? this.getCircularProgress() : mode}
                    </Button>
                </Box>
            </>
        )
    }
};

const mapStateToProps = (state) => {
    const { consultationDetails, familyDetails, auth } = state;
    return {
        consultationDetails,
        familyDetails,
        auth,
    }
};

const mapDispatchToProps = (dispatch) => ({
    addCustomerMetadata: (customerId, payload, allMembers) => { dispatch(addCustomerMetadataEvent(customerId, payload, allMembers)); },
    getSubscriptionById: (subscriptionId) => { dispatch(getSubscriptionById(subscriptionId)); },
});
const ConnectedUploadDrawer = withStyles(style,
    { withTheme: true })(connect(mapStateToProps,
        mapDispatchToProps)(withTranslation()(UploadDrawer)));

export default ConnectedUploadDrawer;