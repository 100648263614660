import {
    GET_ALL_SUBSCRIPTIONS_BY_DATE_PAGINATED,
    GET_ALL_SUBSCRIPTIONS_BY_DATE_PAGINATED_RESPONSE,
    RESET_ALL_SUBSCRIPTIONS_BY_DATE_PAGINATED_RESPONSE,
    ADD_PLAN_DETAILS,
    ADD_PLAN_DETAILS_RESPONSE,
    RESET_ADD_PLAN_DETAILS,
    CONNECT_TELECALL_REQUEST,
    CONNECT_TELECALL_RESPONSE,
    RESET_TELECALL,
} from '../actions/types';

const INITIAL_STATE = {
    allSubscriptions: null,
    loadingAllSubscriptions: true,
    showPlaceCallDialog: false,
};

const pwaSalesReducer = (state = { INITIAL_STATE }, action) => {
    switch (action.type) {
        case GET_ALL_SUBSCRIPTIONS_BY_DATE_PAGINATED:
            return { ...state, allSubscriptions: null, loadingAllSubscriptions: true }
        case GET_ALL_SUBSCRIPTIONS_BY_DATE_PAGINATED_RESPONSE:
            return { ...state, allSubscriptions: action.payload, loadingAllSubscriptions: false }
        case RESET_ALL_SUBSCRIPTIONS_BY_DATE_PAGINATED_RESPONSE:
            return { ...state, allSubscriptions: null, loadingAllSubscriptions: true }
        case ADD_PLAN_DETAILS:
            return { ...state, addPlanDetails: null, loadingAddPlanDetails: true }
        case ADD_PLAN_DETAILS_RESPONSE:
            return { ...state, addPlanDetails: action.payload, loadingAddPlanDetails: false }
        case RESET_ADD_PLAN_DETAILS:
            return { ...state, addPlanDetails: null, loadingAddPlanDetails: true }
        case CONNECT_TELECALL_REQUEST:
            return { ...state, placeTeleCall: null, loadingPlaceCall: true, showPlaceCallDialog : true }
        case CONNECT_TELECALL_RESPONSE:
            return { ...state, placeTeleCall: action.payload, loadingPlaceCall: false,  showPlaceCallDialog : true}
        case RESET_TELECALL:
            return { ...state, placeTeleCall: null, loadingPlaceCall: true , showPlaceCallDialog : false }
        default:
            return state;
    }
};

export default pwaSalesReducer;

