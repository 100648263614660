import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Divider, Typography } from "@material-ui/core";

import { ReactComponent as Accidental } from '../../images/services/accidental.svg';
import { ReactComponent as PrimaryCare } from '../../images/services/primary-care.svg';
import { ReactComponent as HealthInsurance } from '../../images/services/health.svg';
import { ReactComponent as Hospicash } from '../../images/services/hospicash.svg';

import { ReactComponent as AdityaBirlaIcon } from '../../images/serviceProviders/aditya-birla.svg';
import { ReactComponent as ClinikkProviderIcon } from '../../images/serviceProviders/clinikk_full.svg';
import { ReactComponent as BhartiAxaIcon } from '../../images/serviceProviders/BhartiAXA.svg';
import { ReactComponent as MagmaIcon } from '../../images/serviceProviders/Magma.svg';
import { ReactComponent as ReligareIcon } from '../../images/serviceProviders/Religare.svg';
class ServiceCard extends React.Component {

    getServiceIcon = (service) => {
        switch (service) {
            case 'accidental_insurance':
                return <Accidental style={{ height: '5.5vh', width: '3.5vw', marginTop: '0.5vh' }} />
            case 'health_insurance':
                return <HealthInsurance style={{ height: '5.5vh', width: '3.5vw', marginTop: '0.5vh' }} />
            case 'hospicash':
                return <Hospicash style={{ height: '5.5vh', width: '3.5vw', marginTop: '0.5vh' }} />
            default:
                return <PrimaryCare style={{ height: '5.5vh', width: '3.5vw', marginTop: '0.5vh' }} />
        }
    }


    getProviderIcon = (provider) => {
        switch (provider) {
            case ("Aditya Birla Health Insurance"):
                return <AdityaBirlaIcon style={{ width: '10vh', height: '4vh' }} />;
            case ("Magma"):
                return <MagmaIcon style={{ width: '10vh', height: '4vh' }} />;
            case ("Religare"):
                return <ReligareIcon style={{ width: '10vh', height: '4vh' }} />;
            case ("Bharti AXA"):
                return <BhartiAxaIcon style={{ width: '10vh', height: '4vh' }} />;
            case ("Clinikk Healthcare"):
                return <ClinikkProviderIcon style={{ width: '10vh', height: '4vh' }} />;
            default:
                return <ClinikkProviderIcon style={{ width: '10vh', height: '6vh' }} />;
        }
    };


    getNumberOfMembers = (forValue) => {
        switch (forValue) {
            case 'couple':
                return '2 Members';
            case 'family':
                return '4 Members';
            case 'self':
                return '1 Member';
            default:
                return ''
        }
    }

    render() {
        const { service } = this.props;
        return (
            <div style={{ border: '1px solid #e5e5e5', height: '50vh'}}>
            <div>
                <div style= {{ marginBottom: '2vh', marginLeft: '3vw', marginTop: '2vh'}}>
                    {this.getProviderIcon(service.value.provider)}
                </div>

                <div style={{ float: 'left', marginLeft: '3vw' }} >
                    {this.getServiceIcon(service.type)}
                </div>
                <div style={{ float: 'right', marginTop: '0.5vh', marginBottom: '1.3vh', marginLeft: '1vw' }}>
                    <Typography style={{ width: '15vw', fontSize: '1rem', fontFamily: 'Rubik-Medium', color: '#172a3a' }}> {_.capitalize(_.split(service.type, '_').join(' '))} </Typography>
                    <Typography style={{ fontFamily: 'Rubik-Regular', fontSize: '0.7rem', color: '#908f8f' }}>{this.getNumberOfMembers(service.value.for)}</Typography>
                </div>
            </div>
            <Divider style={{ backgroundColor: '#e5e5e5', margin: '10vh 2vw 3vh 2vw' }} />
            {service.value.descriptions.map((description) => {
                return (
                    <Typography style={{fontFamily: 'Rubik-Regular',fontSize: '0.85rem', color: '#172a3a', marginLeft: '3vw', marginRight: '3vw'}} key={description}>{description}</Typography>
                )
            })}
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error, familyDetails: state.familyDetails, newPlan: state.newPlan };
};

export default connect(mapStateToProps)(ServiceCard);