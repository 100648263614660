import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { CircularProgress, Typography, Button } from '@material-ui/core';

import { getOrder, resetOrderDetails, openViewDetails, closeViewDetails } from '../../../actions';
import { errors } from '../../../common/errors';
import { getISTFormat4 } from '../../../common/utils';

class PaymentDetails extends React.Component {

    componentDidMount() {
        this.props.resetOrderDetails();
        this.props.getOrder(this.props.familyDetails.currentOrderId);
    };

    render() {
        const { dismissOrderDetails } = this.props;
        if (this.props.familyDetails.orderDetails === null && this.props.familyDetails.viewDetailsPressed) {
            return (
                <div style={{ height: '40vh', width: '60vh' }}> <CircularProgress style={{ color: 'orange', marginLeft: '45%', marginRight: '45%', marginTop: '18vh' }} disableShrink /> </div>
            );
        } else if (this.props.familyDetails.orderDetails === errors.ERROR_WHILE_FETCHING_ORDER) {
            return (
                <div style={{ height: '40vh', width: '60vh' }}> <Typography style={{ marginTop: '21vh', marginLeft: '13vh', marginRight: '13vh' }}>{errors.ERROR_WHILE_FETCHING_ORDER}</Typography> </div>
            )
        } else {

            return (
                <div style={{ overflowY: 'auto', width: '55vh' }}>
                    <Typography style={{ fontStyle: 'Rubik', fontWeight: 500, fontSize: '18px', textAlign: 'center', padding: '24px 0' }}> Payment Details</Typography>
                    <div style={{ display: 'flex', padding: '0 60px', justifyContent: 'space-between', marginBottom: '8px' }}>
                        <Typography className="rubikBold">
                            Order ID
                        </Typography>
                        <Typography className="rubikRegular">
                            {this.props.familyDetails.orderDetails ? this.props.familyDetails.orderDetails.id : ''}
                        </Typography>
                    </div>



                    <div style={{ display: 'flex', padding: '0 60px', justifyContent: 'space-between' }}>
                        <Typography className="rubikBold">
                            Paid On
                        </Typography>
                        <Typography className="rubikRegular">
                            {this.props.familyDetails.orderDetails && this.props.familyDetails.orderDetails.paid_on
                                ? getISTFormat4(this.props.familyDetails.orderDetails.paid_on) : ''}
                        </Typography>
                    </div>
                    <hr style={{ border: '1px solid #CBCBCB' }}></hr>




                    <div style={{ padding: '0 60px' }}>
                        {/* <TableCell align="left" style={{ borderBottom: "none" }}> */}
                        <Typography className="rubikBold" style={{ marginBottom: '12px' }}>Transaction Details</Typography>
                        {/* </TableCell> */}


                        {this.props.familyDetails.orderDetails && this.props.familyDetails.orderDetails.payments ?
                            (this.props.familyDetails.orderDetails.payments).map((payment) => {
                                return (
                                    <div key={payment.source} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                                        {/* <TableCell align="left" style={{ borderBottom: "none" }}> */}
                                        <Typography className="rubikRegular">{_.capitalize(payment.source)}</Typography>
                                        {/* </TableCell> */}
                                        {/* <TableCell align="right" style={{ borderBottom: "none" }}> */}
                                        <Typography className="rubikRegular">
                                            {'₹' + payment.amount}
                                        </Typography>
                                        {/* </TableCell> */}
                                    </div>
                                );
                            }) : <> </>
                        }


                        <hr style={{ border: '1px dashed #cbcbcb', height: '0.001px' }} ></hr>
                        {/* <TableCell align="left" style={{ borderBottom: "none" }}> */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '4px 0' }}>
                            <Typography className="rubikBold">Total Amount</Typography>
                            {/* </TableCell> */}
                            {/* <TableCell align="right" style={{ borderBottom: "none" }}> */}
                            <Typography className="rubikBold">
                                {this.props.familyDetails.orderDetails && this.props.familyDetails.orderDetails.payments ? '₹' +
                                    _.reduce(this.props.familyDetails.orderDetails.payments, (accumulator, payment) => {
                                        return accumulator + payment.amount
                                    }, 0) : <> </>
                                }
                            </Typography>
                        </div>
                        {/* </TableCell> */}
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', borderTop: '1px solid #CBCBCB', padding: '12px 0' }}>
                        <Button onClick={() => dismissOrderDetails()} style={{ backgroundColor: '#F38B44', color: 'white', padding: '6px 120px' }}>
                            Dismiss
                        </Button>
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return { auth: state.auth, familyDetails: state.familyDetails, error: state.error };
};

export default connect(mapStateToProps, { getOrder, resetOrderDetails, openViewDetails, closeViewDetails })(PaymentDetails);