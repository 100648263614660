import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Typography, Button, CircularProgress, withStyles } from '@material-ui/core';
import { getConsultationsByClinicId, setPresentableConsultationQueue, resetConsultationQueue, incrementConsultationQPage, decrementConsultationQPage, resetConsultationQPageNumber } from '../../actions';
import { consultationActions } from '../../actions/consultationAction';
import ParticularCompletedC from './ParticularCompletedC';
import { consultationStatus } from '../../common/constants';
import { errors } from '../../common/errors';
import useStyles from './style';
import Filter from './Filter/Filter';

class CompletedConsultations extends React.Component {

    constructor() {
        super();
        this.state = {
            totalPages: null,
            prevButtonDisabled: false,
            nextButtonDisabled: false,
            isPageDone: false,
            currentConsultationPage: 1,
            fetchingPastConsultation: false,
        }
    }

    componentDidMount() {
        const {
            auth, consultationDetails, resetConsultationQueue, getConsultationsByClinicId,
        } = this.props;
        resetConsultationQueue();
        if (auth && auth.currentClinicLocation && auth.currentClinicLocation.id) {
            getConsultationsByClinicId(auth.currentClinicLocation.id, consultationStatus.COMPLETED, 1, 'desc', consultationDetails.completed.from, consultationDetails.completed.to, "completed_at", "completedStartsAt", "completedEndsAt");
        }
    }

    getUpcomingConsultations = async () => {
        const { auth, consultationDetails } = this.props;
        if (this.props.auth && this.props.auth.currentClinicLocation && this.props.auth.currentClinicLocation.id) {
            this.setState({
                fetchingPastConsultation: true, 
                nextButtonDisabled: true, 
            })
            await this.props.getConsultationsByClinicId(auth.currentClinicLocation.id, consultationStatus.COMPLETED, consultationDetails.currentConsultationQPage, 'desc', consultationDetails.completed.from, consultationDetails.completed.to, "completed_at", "completedStartsAt", "completedEndsAt");
            this.setState({
                fetchingPastConsultation: false,  
                nextButtonDisabled: false,
            })
        }
    }

    loadNext = () => {
        if (this.props.consultationDetails && this.props.consultationDetails.consultationQueue && this.props.consultationDetails.consultationQueue.total_count && (this.props.consultationDetails.currentConsultationQPage < Math.ceil(this.props.consultationDetails.consultationQueue.total_count / 10))) {
            this.setState({ nextButtonDisabled: true });
            //    this.setState({ currentConsultationPage : this.state.currentConsultationPage + 1})
            setTimeout(function () {
                this.props.incrementConsultationQPage();
            }.bind(this), 200);
            setTimeout(function () {
                this.setState({ nextButtonDisabled: false });
            }.bind(this), 1000);
            setTimeout(function () {
                this.getUpcomingConsultations();
            }.bind(this), 400);
        }
    }

    renderConsultations = () => {
        const { consultationDetails, classes } = this.props;
        const { fetchingPastConsultation } = this.state;
        if (!consultationDetails.consultationQueue) {
            return (<div className={classes.container}>
                <div className={classes.row}>
                    <Filter />
                </div>
                <div className={`${classes.row} ${classes.filled}`}>
                    <CircularProgress style={{ color: 'orange', marginLeft: '48%', marginRight: '48%', marginTop: '20vh' }} disableShrink />
                </div>
            </div>
            );
        } else if (consultationDetails.consultationQueue === errors.ERROR_IN_FETCHING_CONSULTATIONS_BY_CLINIC_ID) {
            return (<div className={classes.container}>
                <div className={classes.row}>
                    <Filter />
                </div>
                <div className={`${classes.row} ${classes.filled}`}>
                    <Typography style={{ fontFamily: 'Rubik' }}>Error while loading completed consultations</Typography>
                </div>
            </div>
            );
        } else if (consultationDetails.representableConsultationQueue && _.isEmpty(consultationDetails.representableConsultationQueue)) {
            return (<div className={classes.container}>
                <div className={classes.row}>
                    <Filter />
                </div>
                <div className={`${classes.row} ${classes.filled}`}>
                    <Typography style={{ fontStyle: 'italic', fontSize: '3vh', marginLeft: '40vh', marginTop: '10vh', color: 'grey', fontFamily: 'Rubik' }}>No completed consultations</Typography>
                </div>
            </div>
            );
        } else {
            return (
                <div className={classes.container}>
                    <div className={classes.row}>
                        <Filter />
                    </div>
                    <div className={`${classes.row} ${classes.filled}`}>
                        <div className={classes.contentContainer}>
                            {this.props?.consultationDetails?.representableConsultationQueue.length > 0 && _.map(consultationDetails.representableConsultationQueue, (consultation, index) => {
                                return (<ParticularCompletedC consultation={consultation} position={index + 1} />);
                            })}
                            <div style={{ marginLeft: '2vw', fontSize: '1.5vh' }}>
                                {(consultationDetails?.consultationQueue?.total_count > 0 && (consultationDetails?.currentConsultationQPage < (Math.ceil(consultationDetails?.consultationQueue?.total_count / 10))))
                                    && (consultationDetails?.currentConsultationPage !== Math.ceil(consultationDetails?.consultationQueue?.total_count / 10))
                                    ?
                                    <Button
                                      id="determining_component"
                                      ref={(el) => { this.determining_component = el }}
                                      style={{ marginLeft: '32vw', fontSize: '1.4vh', textTransform: 'capitalize' }} onClick={this.loadNext} disabled={this.state.nextButtonDisabled || fetchingPastConsultation}
                                    >
                                      {!fetchingPastConsultation ? 'Load More'
                                        : (<> <CircularProgress style={{ color: 'orange' }} disableShrink /> <p style={{ marginLeft: '4px'}}> Please Wait... </p></>)}
                                    </Button>
                                    : <> </>}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };


    render() {
        return (
            this.renderConsultations()
        );
    }

}

const mapStateToProps = (state) => {
    return { auth: state.auth, familyDetails: state.familyDetails, error: state.error, consultationDetails: state.consultationDetails };
}

const mapDispatchToProps = ({
    getConsultationsByClinicId,
    setPresentableConsultationQueue,
    resetConsultationQueue,
    incrementConsultationQPage,
    decrementConsultationQPage,
    resetConsultationQPageNumber,
    setFilterDateTo: consultationActions.setFilterDateTo,
    setFilterDateFrom: consultationActions.setFilterDateFrom,
})

const styleWrapper = withStyles(useStyles, { withTheme: true });
const reduxWrapper = connect(mapStateToProps, mapDispatchToProps);
export default styleWrapper(reduxWrapper(CompletedConsultations));