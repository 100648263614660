import React from 'react';
import Svg from '../../components/common/Svg/Svg';
import Path from '../../components/common/Svg/Path';
import { color as colors } from '../../constants/colors';

function Cross({ background = colors.oranges[300], color = colors.WHITE, ...props }) {
	return (
		<Svg
			width='18'
			height='18'
			viewBox='0 0 18 18'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
            {...props}
		>
			<Path
				d='M9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1Z'
				fill={background}
			/>
			<Path
				d='M12.3802 13C12.2153 13.0006 12.057 12.9353 11.9404 12.8186L5.18216 6.06155C4.93928 5.81871 4.93928 5.42498 5.18216 5.18213C5.42505 4.93929 5.81884 4.93929 6.06173 5.18213L12.82 11.9417C12.9963 12.1196 13.0486 12.386 12.9524 12.6172C12.8563 12.8485 12.6307 12.9994 12.3802 13Z'
				fill={color}
			/>
			<Path
				d='M5.61981 13C5.36932 12.9994 5.14367 12.8485 5.04756 12.6172C4.95145 12.386 5.00368 12.1196 5.18003 11.9417L11.9383 5.18215C12.0954 5.02505 12.3244 4.9637 12.539 5.0212C12.7536 5.0787 12.9213 5.24632 12.9788 5.46091C13.0363 5.6755 12.9749 5.90447 12.8178 6.06156L6.05959 12.8186C5.94303 12.9353 5.78472 13.0006 5.61981 13Z'
				fill={color}
			/>
		</Svg>
	);
}

export default Cross;
