import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button, CircularProgress } from "@material-ui/core";
import { activatePolicy, resetAddPlans } from '../../../../../actions';
import { ReactComponent as GreenTickSuccess } from '../../../../../images/green-tick-success.svg';
import styles from './style';

import { errors } from '../../../../../common/errors';

class ConfirmationDrawer extends React.Component {

    state = { showYesNo: true }

    componentDidMount() {
        this.props.resetAddPlans();
    };

    getOffspringRelationship = (gender) => {
        if (gender === 'male') {
            return 'son'
        } else if (gender === 'female') {
            return 'daughter'
        } else {
            return 'other'
        }
    }

    addPlanDetails = () => {
        let object = {
            members: [
                {
                    customer: {
                        id: this.props.selectedPrimaryCustomerId,
                    },
                    relationship: "primary"
                },
            ]
        };

        if (this.props.selectedSpouseCustomerId) {
            let member = {
                customer: {
                    id: this.props.selectedSpouseCustomerId
                },
                relationship: 'spouse',
            };
            object.members.push(member);
        };

        if (this.props.selectedKid1CustomerId) {
            let member = {
                customer: {
                    id: this.props.selectedKid1CustomerId
                },
                relationship: this.getOffspringRelationship(_.find(this.props.newPlan.allMembersOfCurrentFamily.members, { id: this.props.selectedKid1CustomerId }).gender),
            };
            object.members.push(member);
        };

        if (this.props.selectedKid2CustomerId) {
            let member = {
                customer: {
                    id: this.props.selectedKid2CustomerId
                },
                relationship: this.getOffspringRelationship(_.find(this.props.newPlan.allMembersOfCurrentFamily.members, { id: this.props.selectedKid2CustomerId }).gender),
            };
            object.members.push(member);
        };

        if (this.props.selectedKid3CustomerId) {
            let member = {
                customer: {
                    id: this.props.selectedKid3CustomerId
                },
                relationship: this.getOffspringRelationship(_.find(this.props.newPlan.allMembersOfCurrentFamily.members, { id: this.props.selectedKid3CustomerId }).gender),
            };
            object.members.push(member);
        };

        //nominee removed from ops, this function is never called
        if (this.props.selectedNomineeCustomerId) {
            _.assignIn(object, {
                nominee: {
                    customer: {
                        id: this.props.selectedNomineeCustomerId
                    },
                    relationship: this.props.nomineeRelationshipWithPrimary,
                }
            });
        };
        this.props.activatePolicy(this.props.selectedSubscription.id, object);
    }

    getCircularProgress = () => {
        return <CircularProgress style={{
            color: 'orange',
            left: '50%',
            top: '55%',
            position: 'absolute',
        }} disableShrink />;
    }


    renderDetailsAddedSuccessfulComponent = () => {
        return <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center', padding: '30px', marginTop: '-50px' }}>
            <GreenTickSuccess />
            <Typography align="center" style={{ fontFamily: 'Manrope-Bold', fontSixe: '18px', marginTop: '20px' }}>Details Successfully Added!</Typography>
            <Typography align="center" style={{ fontFamily: 'Manrope-Regular', fontSixe: '14px', marginTop: '20px' }}>An SMS is sent to the customer with the confirmation of plan activation with the link to the Clinikk Web App.</Typography>
            <Button variant="contained" style={{ backgroundColor: '#f38b44', color: '#ffffff', marginTop: '20px', width: '120px' }} onClick={() => this.props.resetSelectedSubscription()}>Done</Button>
        </div>
    }


    renderAddPlanConfirmationComponent = () => {
        return (
            <div>
                <Typography style={{ color: ' #172a3a', fontWeight: 'bold', fontSize: '1rem', fontFamily: 'Rubik', marginTop: '45vh', marginLeft: '35%', marginBottom: '2vh' }}> Are you sure ?</Typography>
                <div style={{ marginBottom: '30%', marginLeft: '23%', marginRight: '20%' }}>
                    <Button onClick={this.props.closeAddPlanDetailsDrawer} variant="outlined" style={{ color: 'orange', borderColor: 'orange', width: '40%' }}>No</Button>
                    <Button onClick={() => this.setState({ showYesNo: false }, () => this.addPlanDetails())} variant="outlined" style={{ marginLeft: '15%', color: 'orange', borderColor: 'orange', width: '40%' }} >Yes</Button>
                </div>
            </div>
        )
    }


    renderAddPlanDetailsDrawer = () => {
        if (this.state.showYesNo) {
            return this.renderAddPlanConfirmationComponent();
        }
        else if (this.props.pwaDetails && this.props.pwaDetails.loadingAddPlanDetails) {
            return (<div style={{ marginTop: '10vh' }}>
                {this.getCircularProgress()}
            </div>
            );
        } else if (this.props.pwaDetails && this.props.pwaDetails.addPlanDetails === errors.ERROR_IN_ADDING_PLAN_DETAILS) {
            return (
                <Typography fullWidth align="center">Something went wrong !!</Typography>
            );
        } else {
            return this.renderDetailsAddedSuccessfulComponent();
        }


    }


    render() {

        return this.renderAddPlanDetailsDrawer()
    }
}

ConfirmationDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error, familyDetails: state.familyDetails, newPlan: state.newPlan, newCustomer: state.newCustomer, pwaDetails: state.pwaDetails };
};

export default connect(mapStateToProps, { activatePolicy, resetAddPlans })(withStyles(styles)(ConfirmationDrawer));
