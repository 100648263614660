import React, { Component } from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Box, Typography, Button, MenuItem, TextField, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import style from './style';
import { addCustomerMetadataEvent } from '../../../actions';

class NammaClinicCenter extends Component {
    constructor() {
        super();
        this.state = {
            ncCenterSelectedName: '',
            ncCenterSelectedId: '',
        }
    }

    getCircularProgress = () => {
        return <CircularProgress style={{
            color: 'orange',
            left: '50%',
            top: '55%',
            position: 'absolute',
        }} />;
    }

    selectLocation({name, id}) {
        this.setState({
            ncCenterSelectedName: name,
            ncCenterSelectedId: id
        })
    }

    addNammaClinicCenter() {
        const { addCustomerMetadata, familyDetails, closeNammaClinicDrawer } = this.props;
        const { ncCenterSelectedId } = this.state;
        const { currentCustomer } = familyDetails;
        const requestPayload = {
            payload: {
                nc_center: {
                    id: ncCenterSelectedId
                }
            }
        }
        const allMembers = _.map(familyDetails.allMembers.members, (member) => {
            if(member.id === currentCustomer.id) {
                return {
                    ...member,
                    metadata: {
                        ...member.metadata,
                        nc_center: {
                            id: ncCenterSelectedId
                        }
                    }
                }
            }
            return member;
        })
        addCustomerMetadata(currentCustomer.id, requestPayload, allMembers);
        closeNammaClinicDrawer();
    }

    render() {

        const { classes, closeNammaClinicDrawer, consultationDetails, familyDetails } = this.props;
        const { ncCenterSelectedName } = this.state;

        const { allClinics } = consultationDetails;
        const sortedClinicList = allClinics.clinics.sort((a, b) => a.name.localeCompare(b.name));
        return (
            <>
                <Box className={classes.header}>
                    <Typography className={classes.bold}> Update Center </Typography>
                    <CloseIcon className={classes.closeIcon} onClick={() => closeNammaClinicDrawer()} />
                </Box>
                {familyDetails.isLoading ? this.getCircularProgress() : <></>}
                <Box className={classes.clinicContainer}>
                    <TextField
                        className={classes.nammaClinicCenters}
                        select
                        value={ncCenterSelectedName}
                        label="Namma Clinic Center"
                    >
                        {sortedClinicList.map(clinic => {
                            return (
                                <MenuItem
                                    key={clinic.name}
                                    value={clinic.name}
                                    onClick={() => this.selectLocation({name: clinic.name, id: clinic.id})}
                                >
                                    {clinic.name}
                                </MenuItem>
                            )
                        }
                        )}
                    </TextField>
                </Box>
                <Box className={classes.clinicContainer}>
                    <Button disabled={!ncCenterSelectedName || ncCenterSelectedName.length < 2} onClick={() => this.addNammaClinicCenter()} className={classes.saveChangesButton}> Save Changes </Button>
                </Box>
            </>
        )
    }
};

const mapStateToProps = (state) => {
    const { consultationDetails, familyDetails } = state;
    return {
        consultationDetails,
        familyDetails,
    }
};

const mapDispatchToProps = (dispatch) => ({
    addCustomerMetadata: (customerId, payload, allMembers) => { dispatch(addCustomerMetadataEvent(customerId, payload, allMembers)); },
});
const ConnectedNammaClinicCenter = withStyles(style,
    { withTheme: true })(connect(mapStateToProps,
        mapDispatchToProps)(withTranslation()(NammaClinicCenter)));

export default ConnectedNammaClinicCenter;