import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Chip, Card, Button, TextField, CircularProgress } from "@material-ui/core";
import { ReactComponent as GreenTickOutlined } from '../../images/GreenTickOutlined.svg';
import { ReactComponent as RedCrossOutlined } from '../../images/RedCrossOutlined.svg';
import { ReactComponent as Cross } from '../../assets/icons2/common/cross.svg';
import './BulkOnboardingTaskCard.css';
import { emailRegex } from '../../common/utils';
import { errors } from '../../common/errors';
import stylesFile from './styles'
import { uploadOnboardFile, resetUploadOnboardFile } from '../../actions';

const styles = theme => {
    const importedStyles = stylesFile(theme);
    return {
        ...importedStyles,
    }
};

class BulkOnboardingTaskCard extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            recipients: '',
            selectedFile: '',
            recipientList: [],
            emailError: '',
            fileKey:Date.now()
        }
    }

    componentDidMount() {
        this.props.resetUploadOnboardFile();
    };

    emailCheck = (emailId) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(emailId)) {
            if (this.state.recipientList.indexOf(emailId) === -1) {
                this.setState({
                    recipientList: [...this.state.recipientList, emailId],
                    recipients: ''
                })
            } else {
                this.setState({
                    recipients: '',
                    emailError: "Do not input same email more than once"
                })
            }
        }
        else {
            this.setState({
                emailError: "Please enter email address and continue"
            })
        }
    }

    handleEmailListChange = (event) => {
        const emailText = event.target.value;
        const inputEmail = emailText.replace(/\s/g, '');
        if (inputEmail.endsWith(',')) {
            const emailId = inputEmail.substring(0, inputEmail.length - 1).toLowerCase()
            this.emailCheck(emailId)
        }
        else if (inputEmail.match(emailRegex)){
            this.setState({ helperText: '', error:false, recipients: inputEmail })
        }
        else if (inputEmail === ''){
            this.setState({ helperText: '', error:false, recipients: inputEmail })
        }
        else if(!inputEmail.match(emailRegex)) {
            this.setState({ helperText: 'Enter a Clinikk id: abc@clinikk.com',error:true, recipients: inputEmail })
        }
        else {
            this.setState({
                recipients: inputEmail,
                emailError: ''
            })
        }
    };

    onKeyPress = (event) => {
        if (event.keyCode === 13) {
            this.emailCheck(event.target.value.toLowerCase())
        }
    }

    getCircularProgress = () => {
        return <CircularProgress style={{
            color: 'orange'
        }} disableShrink />;
    }

    onChangeHandler = event => {
        this.setState({ selectedFile: event.target.files[0] });
    }

    deleteUploadedFile = () => {
        this.setState({ selectedFile: '' });
        document.getElementById("BulkOnboardingTaskFile").value = "";
    }

    onClickSubmit = () => {
        this.props.uploadOnboardFile(
            this.state.recipientList,
            this.state.selectedFile,
        );
        this.setState({
            recipients: '',
            selectedFile: '',
            recipientList: [],
            emailError: '',
            fileKey:Date.now()
        })
    }

    infoTextDisappear = () => {
        setTimeout(() => {
            this.props.resetUploadOnboardFile()
        }, 4000)
    }

    handleDelete = index => () => {
        const emails = this.state.recipientList
        this.setState({
            recipientList: emails.filter((email, i) => i !== index)
        })
    }
    render() {
        const { classes, onBoarding } = this.props;
        const { selectedFile } = this.state;
        const emailChips = this.state.recipientList.map((email, i) => {
            return <Chip variant="outlined" id={i} className={classes.emailChip} label={email} onDelete={this.handleDelete(i)} avatar={<Avatar>{email[0]}</Avatar>} />
        })
        return (
            <Card elevation={1} className={classes.card}>
                <h3 className={classes.title}>
                    Bulk Onboarding
                </h3>
                {/* <Divider style={{ height: '2px', backgroundColor: '#FFD07A', margin: '1vh -3vh 1vh -3vh' }} /> */}
                <p className={classes.addRecepients}>Add Recipients </p>
                <div className={classes.emailsTextFieldList}>
                    <TextField fullWidth label="Please enter email IDs"
                        placeholder="abc@clinikk.com, xyz@clinikk.com"
                        className={classes.emailsTextField} variant="outlined"
                        onChange={this.handleEmailListChange}
                        helperText={this.state.emailError ? this.state.emailError : this.state.helperText}
                        onKeyDown={this.onKeyPress}
                        error={this.state.emailError === '' ? this.state.error : true}
                        value={this.state.recipients}>
                    </TextField>
                </div>
                <div className={classes.emailChips} >
                    {emailChips}
                </div>
                <div className={classes.inputFile}>
                    <input type="file"
                        name="file"
                        id="BulkOnboardingTaskFile"
                        accept='application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        onChange={this.onChangeHandler}
                        key={this.state.fileKey}
                    />
                    { selectedFile ? <Cross className={classes.deleteUploadedFileButton} onClick={this.deleteUploadedFile} /> : '' }
                </div>
                <div className={classes.cardDivider} />
                <Button disabled={!this.state.selectedFile || this.state.recipientList.length === 0}
                    fullWidth variant="contained"
                    style={{ backgroundColor: !this.state.selectedFile || this.state.recipientList.length === 0 ? '#cbcbcb' : '#f38b44' }}
                    className={classes.uploadButton} onClick={this.onClickSubmit}> Upload
                </Button>

                {onBoarding && onBoarding.loadingOnboardingResponse ? <div>{this.getCircularProgress()}</div> :
                    <div className={classes.fileStatusBox}>
                        {!_.isNull(onBoarding.createOnboardingResponse) && onBoarding.createOnboardingResponse === errors.ERROR_IN_CREATING_ONBOARDING ?
                            <>
                                <RedCrossOutlined />
                                <span className={classes.title} style={{
                                    color: '#d13a24'
                                }}>Upload Failed. Please try again.</span> {this.infoTextDisappear()}</>
                            :
                            <>  </>
                        }
                        {!_.isNull(onBoarding.createOnboardingResponse) && onBoarding.createOnboardingResponse === 202 ?
                            <>
                                <GreenTickOutlined />
                                <span
                                    className={classes.title}
                                    style={{ color: '#0d7e51' }}>Upload Successful!
                                </span>{this.infoTextDisappear()}
                            </>
                            :
                            <>  </>
                        }
                    </div>}
            </Card>
        )
    }
};

BulkOnboardingTaskCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return { onBoarding: state.onBoarding };
};

export default connect(mapStateToProps, { uploadOnboardFile, resetUploadOnboardFile })(withStyles(styles)(BulkOnboardingTaskCard));