import _ from 'lodash';
import { discountType, paymentMultiplier, paymentMultiplierQuantity, strings } from '../constants';

export default class ProductUtil {
  isPrimaryCareOnlyProduct(product) {
    if (product) {
      return (!_.isUndefined(product.primary_care)
        && _.isUndefined(product.accidental_insurance)
        && _.isUndefined(product.health_insurance)
        && _.isUndefined(product.hospicash)
      )
    }
    return undefined;
  }

  getProviders(product) {
    const providers = [];
    if (!_.isEmpty(product.health_insurance)) {
      providers.push(product.health_insurance.provider.name);
    }
    if (!_.isEmpty(product.accidental_insurance)) {
      providers.push(product.accidental_insurance.provider.name);
    }
    if (!_.isEmpty(product.hospicash)) {
      providers.push(product.hospicash.provider.name);
    }
    if (this.isPrimaryCareOnlyProduct(product)) {
      providers.push(product.primary_care.provider.name);
    }
    return providers;
  }

  getPricePrecision(price) {
    return (price % 10 === 0) ? 0 : 2;
  }

  formatPrice = (price) => {
    const precision = this.getPricePrecision(price)
    var val = Math.abs(price)
    if (val >= 10000000) {
      val = (val / 10000000).toFixed(precision) + ' Cr';
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(precision) + ' Lakh';
    } else if (val >= 1000) val = (val / 1000).toFixed(precision) + ' K';
    return val;
  }

  getProductServices(product) {
    const services = [];
    if (!_.isEmpty(product.health_insurance)) {
      const description = `₹ ${this.formatPrice(product.health_insurance.coverage)}`;
      services.push({ name: 'health_insurance', description });
    }
    if (!_.isEmpty(product.accidental_insurance)) {
      const description = `₹ ${this.formatPrice(product.accidental_insurance.coverage)}`;
      services.push({ name: 'accidental_insurance', description });
    }
    if (!_.isEmpty(product.hospicash)) {
      const description = `₹ ${this.formatPrice(product.hospicash.coverage)}`;
      services.push({ name: 'hospicash', description });
    }
    if (!_.isEmpty(product.primary_care)) {
      services.push({ name: 'primary_care', description: '' });
    }
    return { services };
  }

  getProductCoverage(product) {
    if (!_.isEmpty(product.health_insurance)) {
      return `₹ ${this.formatPrice(product.health_insurance.coverage)}`;
    }
    if (!_.isEmpty(product.accidental_insurance)) {
      return `₹ ${this.formatPrice(product.accidental_insurance.coverage)}`
    }
    if (!_.isEmpty(product.hospicash)) {
      return `₹ ${this.formatPrice(product.hospicash.coverage)}`;
    }
    return '';
  }

  getProductBenefits(product) {
    const benefits = [];
    for (const key of _.keys(product)) {
      const serviceObject = product[`${key}`];
      if (!_.isEmpty(serviceObject)) {
        if (serviceObject.for
          && serviceObject.provider.name !== strings.CLINIKK_HEALTHCARE
        ) {
          benefits.push(...serviceObject.descriptions)
        };
      }
    }
    if (product.primary_care) {
      benefits.push(...product.primary_care.descriptions)
    }
    return benefits;
  }

  getProductCoverageFor(product) {
    let result;
    _.forEach(_.keys(product), (key) => {
      const serviceObject = product[`${key}`];
      if (!_.isEmpty(serviceObject)
        && serviceObject.provider.name !== strings.CLINIKK_HEALTHCARE
        && serviceObject.for) {
        if (serviceObject.for === "family") {
          result = "2 Adults & 2 kids";
          return true;
        } else if (serviceObject.for === "couple") {
          result = "Couple";
          return true;
        } else {
          result = "Individual";
          return true;
        }
      }
    });
    if (_.isEmpty(result) && !_.isEmpty(product.primary_care)) {
      if (product.primary_care.for === "family") {
        result = "Upto 6 members";
      } else if (product.primary_care.for === "couple") {
        result = "Couple";
      } else {
        result = "Individual";
      }
    }
    return result;
  }

  calculateRepresentableProductAmount({ price, multiplier, discount }) {
    const multiplierQuantity = this.getMultiplierQuantity(multiplier);
    const totalAmount = price * multiplierQuantity;
    if (_.isEmpty(discount) || !discount.value) {
      return totalAmount;
    }
    const discountValue = discount.value;
    let calculatedPrice;
    if (discountValue > 0) {
      if (discount.type === discountType.FLAT) {
        calculatedPrice = totalAmount - discountValue;
      } else if (discount.type === discountType.PERCENTAGE) {
        calculatedPrice = totalAmount - (totalAmount * (discountValue / 100));
      }
    } else {
      calculatedPrice = totalAmount;
    }
    return calculatedPrice;
  };

  getRepresentablePlanPrice(calculatedPrice, multiplier) {
    switch (multiplier) {
      case 'annually':
        return `₹ ${calculatedPrice} per year`;
      case 'quarterly':
        return `₹ ${calculatedPrice} per 3 month`;
      case 'semi-annually':
        return `₹ ${calculatedPrice} per 6 month`;
      default:
        return `₹ ${calculatedPrice} per month`;
    }
  };

  getRepresentableProductPrice = (subscription) => {
    const multiplierQuantity = this.getMultiplierQuantity(subscription.payment_multiplier);
    let discountAmount = 0;
    let representablePriceWithoutDiscount = subscription.product.price * multiplierQuantity;
    if (!_.isEmpty(subscription.product.discount)) {
        if ((subscription.product.discount[subscription.payment_multiplier].type) === discountType.FLAT) {
            discountAmount = subscription.product.discount[subscription.payment_multiplier].value;
        } else if ((subscription.product.discount[subscription.payment_multiplier].type) === discountType.PERCENTAGE) {
            discountAmount = ((subscription.product.discount[subscription.payment_multiplier].value) / 100) * representablePriceWithoutDiscount;
        } else {
            discountAmount = 0;
        }
    }
    return (representablePriceWithoutDiscount - discountAmount);
}

  getProductServicesName = (product) => {
    const services = [];
    _.forEach(_.keys(product), (key) => {
      const serviceObject = product[`${key}`];
      if (!_.isEmpty(serviceObject)) {
        if (serviceObject.for) services.push(key);
      }
    });
    return services;
  };

  getMultiplierQuantity(multiplier) {
    let multiplierQuantity = paymentMultiplierQuantity.MONTHLY;
    if (multiplier === paymentMultiplier.QUARTERLY) {
      multiplierQuantity = paymentMultiplierQuantity.QUARTERLY;
    } else if (multiplier === paymentMultiplier.SEMI_ANNUALLY) {
      multiplierQuantity = paymentMultiplierQuantity.SEMI_ANNUALLY;
    } else if (multiplier === paymentMultiplier.ANNUALLY) {
      multiplierQuantity = paymentMultiplierQuantity.ANNUALLY;
    }
    return multiplierQuantity;
  };

  calculateProductAmount = (price, multiplier, discount) => {//in use
    const multiplierQuantity = this.getMultiplierQuantity(multiplier);
    const totalAmount = price * multiplierQuantity;
    if (_.isEmpty(discount) || !discount.value) {
      return totalAmount;
    }
    const discountValue = discount.value;
    let calculatedPrice;
    if (discountValue > 0) {
      if (discount.type === discountType.FLAT) {
        calculatedPrice = totalAmount - discountValue;
      } else if (discount.type === discountType.PERCENTAGE) {
        calculatedPrice = totalAmount - (totalAmount * (discountValue / 100));
      }
    } else {
      calculatedPrice = totalAmount;
    }
    return calculatedPrice;
  };

  roundOffTo(n, digits) {
    if (n % 1 === 0) {
      return n;
    }
    let negative = false;
    if (digits === undefined) {
      digits = 0;
    }
    if (n < 0) {
      negative = true;
      n = n * -1;
    }
    const multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    n = (Math.round(n) / multiplicator).toFixed(2);
    if (negative) {
      n = (n * -1).toFixed(2);
    }
    return n;
  }

  getProductStartingPrice(product) {
    const { supported_payment_multipliers, price, discount } = product;
    let result = [];
    for (const paymentMultiplier of supported_payment_multipliers) {
      const calculatedPrice = this.calculateProductAmount(price, paymentMultiplier, discount[paymentMultiplier])
      switch (paymentMultiplier) {
        case 'monthly':
          result.push({
            price: calculatedPrice,
            multiplier: 'month',
          })
          break;
        case 'quarterly':
          result.push({
            price: calculatedPrice,
            multiplier: 'quarter',
          })
          break;
        case 'semi_annually':
          result.push({
            price: calculatedPrice,
            multiplier: 'semi annual',
          })
          break;
        case 'annually':
          result.push({
            price: calculatedPrice,
            multiplier: 'annum',
          })
          break;
        default:
          break;
      }
    }
    const sortedPrice = _.orderBy(result, ['price'], ['asc']);
    return sortedPrice[0];
  }

  isHospicashProduct(product) {
    const productServices = this.getProductServicesName(product);
    let result = false;
    for (const serviceName of productServices) {
      if (serviceName.toLowerCase().includes(strings.HOSPICASH)) {
        result = true;
        break;
      }
    }
    return result;
  };

  getBillingCycles = (product) => {
    let result = [];
    let id = 1;
    let selected = false;
    const { supported_payment_multipliers, price, discount } = product;
    supported_payment_multipliers.forEach((paymentMultiplier) => {
      const calculatedPrice = this.calculateProductAmount(price, paymentMultiplier, discount[paymentMultiplier])
      switch (paymentMultiplier) {
        case 'monthly':
          result.push({
            id: id,
            price: calculatedPrice,
            priceDescription: "1 month",
            selected: selected,
            paymentMultiplier: "monthly"
          });
          break;
        case 'quarterly':
          result.push({
            id: id,
            price: calculatedPrice,
            priceDescription: "3 months",
            selected: selected,
            paymentMultiplier: "quarterly"
          });
          break;
        case 'semi_annually':
          result.push({
            id: id,
            price: calculatedPrice,
            priceDescription: "6 months",
            selected: selected,
            paymentMultiplier: "semi_annually"
          });
          break;
        case 'annually':
          result.push({
            id: id,
            price: calculatedPrice,
            priceDescription: "1 year",
            selected: selected,
            paymentMultiplier: "annually"
          });
          break;
        default:
          break;
      }
      id++;
    });
    result = _.orderBy(result, ['price'], ['asc']);
    result[0].selected = true;
    return result;
  }


  getPlanForValueText = (forValue) => {
    let formattedForValue;
    if (forValue === 'self') {
      formattedForValue = '1 Adult';
    } else if (forValue === 'couple') {
      formattedForValue = '2 Adults';
    } else if (forValue === 'family') {
      formattedForValue = '2 Adults & 2 Kids';
    }
    return formattedForValue;
  }
}
