import { color } from "../../constants/colors";

const styles = (theme) => ({
  card: {
    padding: "16px",
    paddingTop: "20px",
    borderRadius: "5px",
    boxShadow: "0 0 4px 0 #e5e5e5",
    backgroundColor: color.WHITE,
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  addRecepients: {
    fontFamily: "Rubik",
    fontSize: 13,
    color: color.DARK_GREY,
    marginTop: 20,
    marginBottom: 20,
  },
  cardDivider: {
    height: "2px",
    width: "100%",
    backgroundColor: "#e5e5e5",
    color: color.WHITE,
    alignSelf: "center",
    margin: "10px 0",
  },
  title: {
    fontFamily: "Rubik",
    fontSize: 16,
    fontWeight: "bold",
    color: color.DARK_GREY,
  },

  showHistoryTemplate: {
    minHeight: "22px",
    minWidth: "65px",
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    color: color.ROYAL_ORANGE,
    borderColor: color.ROYAL_ORANGE,
    borderRadius: "4px",
    cursor: "pointer",
    padding: "4px 8px",
    marginTop: "12px",
    marginRight: "12px",
    textTransform: "none",
  },

  uploadButton: {
    marginTop: 20,
    textTransform: "capitalize",
    backgroundColor: color.ROYAL_ORANGE,
    color: color.WHITE,
  },
  inputFile: {
    color: color.ROYAL_ORANGE,
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 6,
    backgroundColor: color.WHITE,
    boxShadow: "none",
    border: "none",
    outline: "none",
    fontSize: 16,
    fontFamily: "Roboto",
  },
  deleteUploadedFileButton: {
    marginTop: "28px",
  },
  fileStatusBox: {
    display: "flex",
    margin: "20px 0",
    flexDirection: "row",
  },
  emailChips: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  emailChip: {
    margin: "5px",
    color: color.DARK_GREY,
    fontFamily: "Rubik",
  },
  radio: {
    "&$checked": {
      color: color.ROYAL_ORANGE,
    },
  },
  checked: {},
  boundaryDiv: {
    borderStyle: "dashed",
    borderWidth: "1px",
    borderColor: color.LIGHT_GREY,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    paddingBottom: "20px",
    position: "relative",
  },
  fileContainer: {
    height: "10%",
    margin: "0 24px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: `2px dashed ${color.BRIGHT_GRAY}`,
    borderRadius: "12px",
    padding: "24px 0 0",
    marginTop: "16px",
  },
  uploadText: {
    marginTop: "16px",
    marginBottom: "8px",
    position: "relative",
    height: "30px",
  },
  uploadTextInput: {
    position: "relative",
    zIndex: 2,
    cursor: "pointer",
    opacity: 0,
  },
  uploadTextCover: {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    zIndex: 1,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    paddingLeft: "50px",
  },

  fileUpload: {
    fontWeight: 500,
    margin: "16px 24px 0",
  },
  browse: {
    color: color.ROYAL_ORANGE,
    textDecoration: "underline",
    fontWeight: 700,
  },
  uploadSubText: {
    fontFamily: "Rubik",
    fontSize: 10,
    fontWeight: 400,
    color: color.GREY,
  },
  uploadedFile: {
    marginTop: "20px",
  },
  fileFrame: {
    marginLeft: "11px",
    width: "100%",
  },
  fileNameSuccess: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    width: "200px",
    overflowWrap: "break-word",
    color: color.BLACK,
  },
  fileNameFailed: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    width: "200px",
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
  },
  colorPrimary_failure: {
    background: color.reds[100],
  },
  colorPrimary: {
    background: color.greys[200],
  },
  barColorPrimary: {
    background: color.ROYAL_ORANGE,
  },
  fileDetails: {
    position: "relative",
    width: "100%",
  },
  fileDetailsSize: {
    top: "0px",
    left: "0px",
    color: color.GREY,
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "10px",
  },
  fileDetailsStatus: {
    position: "absolute",
    bottom: "0px",
    right: "0px",
    color: color.ROYAL_ORANGE,
    fontWeight: "400",
    fontSize: "10px",
  },
  sendUploadedFileButton: {
    background: color.ROYAL_ORANGE,
    borderRadius: "8px",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "17px",
    color: color.WHITE,
    float: "right",
    padding: "5px",
    marginTop: "16px",
    display: "block",
    minWidth: "146px",
    minHeight: "32px",
    textTransform: "none",
    "&:disabled": {
      background: color.GREY,
    },
  },
  header: {
    display: "flex",
    padding: "28px 24px 8px 24px",
    justifyContent: "space-between",
    borderBottom: `1px solid ${color.ROYAL_ORANGE}`,
  },
  bold: {
    fontWeight: 500,
  },
  closeIcon: {
    cursor: "pointer",
  },
  downloadButton: {
    minHeight: "22px",
    minWidth: "65px",
    color: color.ROYAL_ORANGE,
    borderColor: color.ROYAL_ORANGE,
    borderRadius: "4px",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    padding: "4px 8px",
    marginTop: "12px",
    marginRight: "12px",
    textTransform: "none",
    "&:disabled": {
      color: "#F9C5A1",
      borderColor: "#F9C5A1",
    },
  },

  filterFrame: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
  },

  filterText: {
    color: color.ROYAL_ORANGE,
    fontWeight: 400,
    fontSize: "12px",
    fontFamily: "Rubik",
    cursor: "pointer",
    alignSelf: "center",
    marginRight: "6px",
  },

  container: {
    display: "flex",
    flexDirection: "column",
  },

  filterDropdown: {
    position: "absolute",
    width: "87%",
    height: "42px",
    left: "24px",
    right: "24px",
    top: "78px",
  },

  dateContainer: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    width: "312px",
    height: "32px",
    left: "24px",
    top: "144px",
  },

  startDateInput: {
    position: "absolute",
    width: "140px",
    height: "32px",
    left: "26px",
    marginRight: "11px",
    top: "144px",
  },
  datePicker: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
    color: color.greys[100],
  },
  toDateContainer: {
    position: "absolute",
    width: "20.62px",
    height: "17px",
    left: "169.69px",
    top: "152px",
    marginTop: "21px",
    marginLeft: "13px",
    color: color.DARK_GREY,
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "17px",
  },
  endDateInput: {
    position: "absolute",
    width: "144px",
    marginLeft: "18px",
    height: "32px",
    left: "204.05px",
    top: "144px",
  },
  btnfilter: {
    position: "absolute",
    width: "312px",
    height: "36px",
    left: "24px",
    bottom: "19px",
    background: color.ROYAL_ORANGE,
    borderRadius: "5px",
    color: color.WHITE,
    textTransform: "none",
    "&:hover": {
      background: color.ROYAL_ORANGE,
      color: color.WHITE,
    },
  },

  deleteUploadedFile: {
    color: color.WHITE,
    cursor: "pointer",
    height: "12px",
    background: color.BLACK,
  },
  closePreviewBtn: {
    height: "20px",
    width: "16px",
    fontFamily: "Rubik",
    fontSize: "14px",
    color: color.WHITE,
    borderRadius: "16px",
    border: "none",
    cursor: "pointer",
    background: color.BLACK,
  },

  menuItem_placeholder: {
    fontFamily: "Rubik",
    color: color.greys[100],
    fontSize: "14px",
  },
  errorDetails: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "12px",
    color: color.INTERNATIONAL_ORANGE,
    marginLeft: "5px",
  },
  menuOption: {
    fontFamily: "Rubik",
    fontWeight: "normal",
    height: "50px",
  },
  noDataParent: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    justifyContent: "center",
  },
  noDataParentIcon: {
    display: "flex",
    justifyContent: "center",
  },
  noDataParentText: {
    textAlign: "center",
    marginTop: "18px",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#908F8F",
  },

  ".MuiIconButton-root": {
    display: "none",
  },
});

export default styles;
