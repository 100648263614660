export const leadsActionTypes = {
    CREATE_LEAD_REQUEST: 'CREATE_LEAD_REQUEST',
    CREATE_LEAD_SUCCESS: 'CREATE_LEAD_SUCCESS',
    CREATE_LEAD_FAILURE: 'CREATE_LEADFAILUREE',
};

export const leadGenerators = {
    PROMOTER: 'promoter',
    ADVERTISMENT: 'advertisement',
    IVR: 'ivr',
    CUSTOMER: 'customer',
    OTHER: 'other',
};

/**
 * These properties are supplied in `utm_source`
 * when a consultation is **booked**.
 */
export const leadSources = {
    TELECONSULTATION: 'teleconsultation_booked',
    PHYSICAL_BOOKED: 'physical_booked',
}

/**
 * The constant clinic locations to be sent in the `chh_location`
 * property of the leads payload.
 * 
 * **TODO:** as locations increase in number, we will need to define
 * standard logic to extract the CHH location from the CHH address
 */
export const chhLocations = {
    BOMMANAHALLI: 'Bommanahalli',
    JP_NAGAR: 'JP Nagar',
    MALLESHWARAM: 'Malleshwaram',
    BANNERGHATTA_ROAD: 'Bannerghatta Road',
    PEENYA: 'Peenya',
    CHAMRAJPET: 'Chamrajpet',
}

/**
 * A list of activity codes for activities we log
 * against LSQ leads.
 */
export const lsqActivityCode = {
    /**
     * Record the bill amount for a _non-subscriber_,
     * before they are directed to a CHA for cross-selling
     * Clinikk Plans.
     */
    BILLING_ACTIVITY: 252,
    /**
     * Record the purpose of visit for _all_ customers,
     * irrespective of plan status.
     */
    PURPOSE_OF_VISIT_ACTIVITY: 253,
}

export const physicalWalkinProductId = '61e68bdf811fec4df44972ea';
