import { color } from '../../../constants/colors';

const useStyles = (theme) => ({
  applyCouponContainer: {
    background: color.WHITE,
    borderRadius: '8px',
    border: `1px solid ${color.ROYAL_ORANGE}`,
    padding: '1vh',
    display: 'flex',
    width: '95%',
    flexDirection: 'column',
  },
  applyCouponRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  applyCouponHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: color.ROYAL_ORANGE,
  },
  amount: {
    fontSize: "16px",
    fontFamily: "Rubik",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: color.BLACK,
  },
  amountTitle: {
    fontSize: "13px",
    fontFamily: "Rubik",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: color.DARK_GREY,
  },
  covers: {
    fontSize: "13px",
    fontFamily: "Rubik",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: color.DARK_GREY,
  },
  coversTitle: {
    fontSize: "16px",
    fontFamily: "Rubik",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: color.BLACK,
  },
  applyCouponTitleAndIcon: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 'bold',
    marginLeft: '4px',
    fontSize: '16px',
    lineHeight: '22px',
    color: color.ROYAL_ORANGE,
  },
  applyCouponTextField: {
    background: `${color.oranges[100]}`,
    borderRadius: "8px",
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '6px',
    marginTop: '12px',
  },
  applyCouponTextField_success: {
    background: `${color.greens[200]}`,
    borderRadius: "8px",
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '6px',
    marginTop: '12px',
  },
  applyCouponTextField_failure: {
    background: `${color.reds[200]}`,
    borderRadius: "8px",
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '6px',
    marginTop: '12px',
  },
  applyCouponTextField_disabled: {
    background: `${color.BRIGHT_GRAY}`,
    borderRadius: "8px",
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '6px',
    marginTop: '12px',
  },
  appliedValidCouponStatus: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "16px",
    textAlign: "center",
    color: color.greens[100],
    marginTop: '4px',
  },
  appliedInvalidCouponStatus: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    color: "#BB2E2E",
    marginTop: '4px',
  },
  applyCouponInputField: {
    fontFamily: 'Rubik',
    fontWeight: 'bold',
    background: 'none',
    border: 'none',
    outline: 'none',
    fontSize: '16px',
    margin: '0px 6px',
    height: '35px',
    width: 'calc(100% * (7/10))',
  },
  applyCouponButton: {
    height: '44px',
    background: color.ROYAL_ORANGE,
    borderRadius: "8px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 'none',
    width: 'calc(100% * (2/10))',
    '&:hover': {
      background: color.ORANGE,
    },
    '&:disabled': {
      background: color.GREY,
    }
  },
  applyCouponButton_success: {
    height: '44px',
    background: color.greens[100],
    borderRadius: "8px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 'none',
    width: 'calc(100% * (2/10))',
    '&:hover': {
      background: color.greens[100],
    },
  },
  applyCouponButton_failure: {
    height: '44px',
    background: color.reds[100],
    borderRadius: "8px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 'none',
    width: 'calc(100% * (2/10))',
    '&:hover': {
      background: color.reds[100],
    }
  },
  applyCouponStatusIcon: {
    margin: 0,
    width: '17px',
    height: '17px',
    position: 'absolute',
  },
  applyCouponIcon: {
    margin: 0,
    width: '17px',
    height: '17px',
    position: 'absolute',
    top: '37%',
    left: '37%'
  },
  referralAppliedSubheading: {
    fontSize: '12px',
    fontFamily: 'Rubik',
    fontWeight: 'bold',
    color: color.BLACK,
    marginTop: '4px',
    paddingLeft: '16px',
  }
})

export default useStyles;