export const LOG_IN = 'SIGN_IN';
export const LOG_OUT = 'LOG_OUT';
export const INITIATE_OTP = 'INITIATE_OTP';
export const VERIFY_OTP = 'VERIFY_OTP';
export const GET_CUSTOMER_DETAILS = 'GET_CUSTOMER_DETAILS';
export const GET_SUBSCRIPTIONS_BY_FAMILY_ID = 'GET_SUBSCRIPTIONS_BY_FAMILY_ID';
export const GET_ALL_MEMBERS = 'GET_ALL_MEMBERS';
export const FETCH_BENEFIT_CARDS_BY_CUSTOMER_ID = 'FETCH_BENEFIT_CARDS_BY_CUSTOMER_ID';
export const FETCH_PRODUCTS_BY_BUSINESS_ID = 'FETCH_PRODUCTS_BY_BUSINESS_ID';
export const SET_CUSTOMER_PHONE_NUMBER = 'SET_CUSTOMER_PHONE_NUMBER';
export const RESET_CUSTOMER_PHONE_NUMBER = 'RESET_CUSTOMER_PHONE_NUMBER';
export const GET_NACH_DETAILS_BY_FAMILY_ID = 'GET_NACH_DETAILS_BY_FAMILY_ID';
export const GET_CREDIT_DETAILS_BY_FAMILY_ID = 'GET_CREDIT_DETAILS_BY_FAMILY_ID';
export const RESET_ERRORS = 'RESET_ERRORS';
export const SET_CONSULTATION_FORM = 'SET_CONSULTATION_FORM';
export const CREATE_CONSULTATION = 'CREATE_CONSULTATION';
export const CREATE_VISIT = 'CREATE_VISIT';
export const RESET_CONSULTATION_ERROR = 'RESET_CONSULTATION_ERROR';
export const RESET_VISIT_BOOKING_ERROR = 'RESET_VISIT_BOOKING_ERROR';
export const FETCH_CONSULTATIONS_BY_CUSTOMER_ID = 'FETCH_CONSULTATIONS_BY_CUSTOMER_ID';
export const GET_ALL_DOCTORS = 'GET_ALL_DOCTORS';
export const FIND_ADDRESS_BY_ZIPCODE = 'FIND_ADDRESS_BY_ZIPCODE';
export const RESET_ADDRESS_BY_ZIPCODE = 'RESET_ADDRESS_BY_ZIPCODE';
export const FIND_CUSTOMER_BY_PHONE_NUMBER = 'FIND_CUSTOMER_BY_PHONE_NUMBER';
export const CREATE_NEW_CUSTOMER = 'CREATE_NEW_CUSTOMER';
export const GET_COUPON_DISCOUNT = 'GET_COUPON_DISCOUT';
export const GET_OPD_BALANCE = 'GET_OPD_BALANCE';
export const GET_OPD_TRANSACTIONS = 'GET_OPD_TRANSACTIONS';
export const CREATE_NEW_ADDRESS = 'CREATE_NEW_ADDRESS';
export const RESET_CREATE_ADDRESS_RESPONSE = 'RESET_CREATE_ADDRESS_RESPONSE';
export const CREATE_FREE_SUBSCRIPTION = 'CREATE_FREE_SUBSCRIPTION';
export const SEND_PLAN_CHANGE = 'SEND_PLAN_CHANGE';
export const SET_NEW_FAMILY_ID = 'SET_NEW_FAMILY_ID';
export const RESET_OLD_FAMILY_DETAILS = 'RESET_OLD_FAMILY_DETAILS';
export const ADD_NEW_MEMBER_BY_FAMILY_ID = 'ADD_NEW_MEMBER_BY_FAMILY_ID';
export const RESET_NEW_MEMBER_ADDED = 'RESET_NEW_MEMBER_ADDED';
export const UPDATE_PRIMARY_NUMBER = 'UPDATE_PRIMARY_NUMBER';
export const RESET_UPDATE_PRIMARY_NUMBER_STATUS = 'RESET_UPDATE_PRIMARY_NUMBER_STATUS';
export const UPDATE_ALTERNATE_NUMBER = 'UPDATE_ALTERNATE_NUMBER';
export const RESET_UPDATE_ALTERNATE_NUMBER_STATUS = 'RESET_UPDATE_ALTERNATE_NUMBER_STATUS';
export const UPDATE_CUSTOMER_BY_CUSTOMER_ID = 'UPDATE_CUSTOMER_BY_CUSTOMER_ID';
export const RESET_UPDATE_MEMBER = 'RESET_UPDATE_MEMBER';
export const CLOSE_MEMBER_DRAWER = 'CLOSE_MEMBER_DRAWER';
export const OPEN_MEMBER_DRAWER = 'OPEN_MEMBER_DRAWER';
export const CANCEL_MANDATE_BY_MANDATE_ID = 'CANCEL_MANDATE_BY_MANDATE_ID';
export const RESET_CANCEL_MANDATE_STATUS = 'RESET_CANCEL_MANDATE_STATUS';
export const CANCEL_SUBSCRIPTION_BY_ID = 'CANCEL_SUBSCRIPTION_BY_ID';
export const RESET_CANCEL_WITHDRAW_SUBSCRIPTION_STATUS = 'RESET_CANCEL_WITHDRAW_SUBSCRIPTION_STATUS';
export const WITHDRAW_SUBSCRIPTION_BY_ID = 'WITHDRAW_SUBSCRIPTION_BY_ID';
export const GET_INVOICES_BY_SUBSCRIPTION_ID = 'GET_INVOICES_BY_SUBSCRIPTION_ID';
export const RESET_INVOICE_DETAILS = 'RESET_INVOICE_DETAILS';
export const GET_ORDER_BY_ID = 'GET_ORDER_BY_ID';
export const RESET_ORDER_DETAILS = 'RESET_ORDER_DETAILS';
export const OPEN_VIEW_DETAILS = 'OPEN_VIEW_DETAILS';
export const CLOSE_VIEW_DETAILS = 'CLOSE_VIEW_DETAILS';
export const OPEN_BOOK_CONSULTATION_DRAWER = 'OPEN_BOOK_CONSULTATION_DRAWER';
export const CLOSE_BOOK_CONSULTATION_DRAWER = 'CLOSE_BOOK_CONSULTATION_DRAWER';
export const OPEN_VISIT_BOOK_DRAWER = 'OPEN_VISIT_BOOK_DRAWER';
export const CLOSE_VISIT_BOOK_DRAWER = 'CLOSE_VISIT_BOOK_DRAWER';
export const RESET_BENEFIT_CARDS_BY_ID = 'RESET_BENEFIT_CARDS_BY_ID';
export const RESET_PRODUCTS = 'RESET_PRODUCTS';
export const RESET_PLAN_CHANGE = 'RESET_PLAN_CHANGE';
export const RESET_COUPON_VALUE = 'RESET_COUPON_VALUE';
export const RESET_OPD_BALANCE = 'RESET_OPD_BALANCE';
export const RESET_OPD_TRANSACTIONS = 'RESET_OPD_TRANSACTIONS';
export const RESET_CONSULTATIONS_BY_CUSTOMER_ID = 'RESET_CONSULTATIONS_BY_CUSTOMER_ID';
export const GET_CONSULTATION_BY_ID = 'GET_CONSULTATION_BY_ID';
export const RESET_CONSULTATION_BY_ID = 'RESET_CONSULTATION_BY_ID';
export const ADD_VITALS_BY_CONSULTATION_ID = 'ADD_VITALS_BY_CONSULTATION_ID';
export const RESET_ADD_VITALS_STATUS = 'RESET_ADD_VITALS_STATUS';
export const CLOSE_ADD_VITALS_DRAWER = 'CLOSE_ADD_VITALS_DRAWER';
export const OPEN_ADD_VITALS_DRAWER = 'OPEN_ADD_VITALS_DRAWER';
export const SET_PRESENTABLE_CONSULTATIONS = 'SET_PRESENTABLE_CONSULTATIONS';
export const INCREMENT_CONSULTATION_PAGE = 'INCREMENT_CONSULTATION_PAGE';
export const DECREMENT_CONSULTATION_PAGE = 'DECREMENT_CONSULTATION_PAGE';
export const RESET_CONSULTATION_PAGE_NUMBER = 'RESET_CONSULTATION_PAGE_NUMBER';
export const FETCH_CONSULTATIONS_BY_CUSTOMER_ID_FIRST_PAGE = 'FETCH_CONSULTATIONS_BY_CUSTOMER_ID_FIRST_PAGE';
export const SET_CURRENT_CLINIC_LOCATION = 'SET_CURRENT_CLINIC_LOCATION';
export const EDIT_CURRENT_CLINIC_LOCATION = 'EDIT_CURRENT_CLINIC_LOCATION';
export const GET_ALL_CLINICS = 'GET_ALL_CLINICS';
export const GET_CONSULTATIONS_BY_CLINIC_ID = 'GET_CONSULTATIONS_BY_CLINIC_ID';
export const ASSIGN_DOCTOR_BY_CONSULTATION_ID = 'ASSIGN_DOCTOR_BY_CONSULTATION_ID';
export const RESET_DOCTOR_BY_CONSULTATION_ID = 'RESET_DOCTOR_BY_CONSULTATION_ID';
export const SET_PRESENTABLE_CONSULTATION_QUEUE = 'SET_PRESENTABLE_CONSULTATION_QUEUE';
export const RESET_CONSULTATION_QUEUE = 'RESET_CONSULTATION_QUEUE';
export const CANCEL_CONSULTATION_BY_ID = 'CANCEL_CONSULTATION_BY_ID';
export const RESET_CANCEL_CONSULTATION_STATUS = 'RESET_CANCEL_CONSULTATION_STATUS';
export const GET_CONSULTATIONS_BY_CLINIC_ID_FIRST_PAGE = 'GET_CONSULTATIONS_BY_CLINIC_ID_FIRST_PAGE';
export const INCREMENT_CONSULTATIONQ_PAGE = 'INCREMENT_CONSULTATIONQ_PAGE';
export const DECREMENT_CONSULTATIONQ_PAGE = 'DECREMENT_CONSULTATIONQ_PAGE';
export const RESET_CONSULTATIONQ_PAGE_NUMBER = 'RESET_CONSULTATIONQ_PAGE_NUMBER';
export const CHECKOUT_CONSULTATION = 'CHECKOUT_CONSULTATION';
export const RESET_CHECKOUT_CONSULTATION_STATUS = 'RESET_CHECKOUT_CONSULTATION_STATUS';
export const GET_CONSULTATION_ORDER = 'GET_CONSULTATION_ORDER';
export const RESET_CONSULTATION_ORDER_DETAILS = 'RESET_CONSULTATION_ORDER_DETAILS';
export const RESET_CREDIT_DETAILS = 'RESET_CREDIT_DETAILS';
export const CREATE_OFFLINE_PAYMENT = 'CREATE_OFFLINE_PAYMENT';
export const RESET_OFFLINE_PAYMENT_STATUS = 'RESET_OFFLINE_PAYMENT_STATUS';
export const RE_ASSIGN_DOCTOR_BY_CONSULTATION_ID = 'RE_ASSIGN_DOCTOR_BY_CONSULTATION_ID';
export const SEARCH_PRODUCTS_BY_BUSINESS_ID = 'SEARCH_PRODUCTS_BY_BUSINESS_ID';
export const RESET_PRODUCTS_BY_BUSINESS_ID = 'RESET_PRODUCTS_BY_BUSINESS_ID';
export const GET_CUSTOMER_BY_PHONE_NUMBER = 'GET_CUSTOMER_BY_PHONE_NUMBER';
export const GET_SUBSCRIPTION_BY_SUBSCRIPTION_ID_SUCCESS = 'GET_SUBSCRIPTION_BY_SUBSCRIPTION_ID__SUCCESS';
export const GET_SUBSCRIPTION_BY_SUBSCRIPTION_ID_REQUEST='GET_SUBSCRIPTION_BY_SUBSCRIPTION_ID_REQUEST';
export const GET_SUBSCRIPTION_BY_SUBSCRIPTION_ID_FAILED='GET_SUBSCRIPTION_BY_SUBSCRIPTION_ID_FAILED';
export const RESET_CUSTOMER_BY_PHONE_NUMBER = 'RESET_CUSTOMER_BY_PHONE_NUMBER';
export const RESET_CREATE_NEW_CUSTOMER = 'RESET_CREATE_NEW_CUSTOMER';
export const RESET_GET_ALL_MEMBERS = 'RESET_GET_ALL_MEMBERS';
export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const RESET_CREATE_SUBSCRIPTION = 'RESET_CREATE_SUBSCRIPTION';
export const RESET_NEW_PLAN_MOBILE_NUMBER = 'RESET_NEW_PLAN_MOBILE_NUMBER';
export const SET_NEW_PLAN_MOBILE_NUMBER = 'SET_NEW_PLAN_MOBILE_NUMBER';
export const CREATE_ONBOARDING_RESPONSE = 'CREATE_ONBOARDING_RESPONSE';
export const CREATE_ONBOARDING_REQUEST = 'CREATE_ONBOARDING_REQUEST';
export const RESET_UPLOAD_ONBOARD_FILE = 'RESET_UPLOAD_ONBOARD_FILE';
export const UPDATE_CUSTOMER_CREDIT = 'UPDATE_CUSTOMER_CREDIT';
export const UPDATE_CUSTOMER_CREDIT_RESPONSE = 'UPDATE_CUSTOMER_CREDIT_RESPONSE';
export const RESET_UPDATE_CUSTOMER_CREDIT_FILE = 'RESET_UPDATE_CUSTOMER_CREDIT_FILE';
export const GET_ALL_SUBSCRIPTIONS_BY_DATE_PAGINATED = 'GET_ALL_SUBSCRIPTIONS_BY_DATE_PAGINATED';
export const GET_ALL_SUBSCRIPTIONS_BY_DATE_PAGINATED_RESPONSE = 'GET_ALL_SUBSCRIPTIONS_BY_DATE_PAGINATED_RESPONSE';
export const RESET_ALL_SUBSCRIPTIONS_BY_DATE_PAGINATED_RESPONSE = 'RESET_ALL_SUBSCRIPTIONS_BY_DATE_PAGINATED_RESPONSE';
export const ADD_PLAN_DETAILS = 'ADD_PLAN_DETAILS';
export const RESET_ADD_PLAN_DETAILS = 'RESET_ADD_PLAN_DETAILS';
export const ADD_PLAN_DETAILS_RESPONSE = 'ADD_PLAN_DETAILS_RESPONSE';
export const BATCH_EXTEND_SUBSCRIPTION = 'BATCH_EXTEND_SUBSCRIPTION'
export const BATCH_EXTEND_SUBSCRIPTION_RESPONSE = 'BATCH_EXTEND_SUBSCRIPTION_RESPONSE';
export const RESET_BATCH_EXTENDED_SUBSCRIPTION = 'RESET_BATCH_EXTENDED_SUBSCRIPTION';
export const CONNECT_TELECALL_REQUEST = 'CONNECT_TELECALL_REQUEST'
export const CONNECT_TELECALL_RESPONSE = 'CONNECT_TELECALL_RESPONSE'
export const RESET_TELECALL = 'RESET_TELECALL'
export const UPDATE_COHORT_REQUEST = 'UPDATE_COHORT_REQUEST'
export const UPDATE_COHORT_SUCCESS = 'UPDATE_COHORT_SUCCESS'
export const UPDATE_COHORT_FAILURE = 'UPDATE_COHORT_FAILURE'
export const UPDATE_TNC_REQUEST = 'UPDATE_TNC_REQUEST'
export const UPDATE_TNC_SUCCESS = 'UPDATE_TNC_SUCCESS'
export const UPDATE_TNC_FAILURE = 'UPDATE_TNC_FAILURE'
export const RESET_TNC = 'RESET_TNC'
export const SET_ERROR_IN_UPDATE_TNC = 'SET_ERROR_IN_UPDATE_TNC'
export const SET_ERROR_IN_UPDATE_COHORT = 'SET_ERROR_IN_UPDATE_COHORT'
export const SELECT_COHORT = 'SELECT_COHORT'
export const GET_BILLING_PREF_REQUEST = 'GET_BILLING_PREF_REQUEST'
export const GET_BILLING_PREF_SUCCESS = 'GET_BILLING_PREF_SUCCESS'
export const GET_BILLING_PREF_FAILURE = 'GET_BILLING_PREF_FAILURE'
export const SET_ERROR_IN_GETTING_BILLING_PREF = 'SET_ERROR_IN_GETTING_BILLING_PREF'
export const UPDATE_WHATSAPP_CONSENT_REQUEST = 'UPDATE_WHATSAPP_CONSENT_REQUEST'
export const UPDATE_WHATSAPP_CONSENT_SUCCESS = 'UPDATE_WHATSAPP_CONSENT_SUCCESS'
export const UPDATE_WHATSAPP_CONSENT_FAILURE = 'UPDATE_WHATSAPP_CONSENT_FAILURE'
export const UPDATE_WHATSAPP_CONSENT_ONBOARDING_REQUEST = 'UPDATE_WHATSAPP_CONSENT_ONBOARDING_REQUEST'
export const UPDATE_WHATSAPP_CONSENT_ONBOARDING_SUCCESS = 'UPDATE_WHATSAPP_CONSENT_ONBOARDING_SUCCESS'
export const UPDATE_WHATSAPP_CONSENT_ONBOARDING_FAILURE = 'UPDATE_WHATSAPP_CONSENT_ONBOARDING_FAILURE'
export const PLAN_CANCELATION_REQUEST = 'PLAN_CANCELATION_REQUEST'
export const PLAN_CANCELATION_SUCCESS = 'PLAN_CANCELATION_SUCCESS'
export const PLAN_CANCELATION_FAILURE = 'PLAN_CANCELATION_FAILURE'
export const SET_ERROR_PLAN_CANCELATION = 'PLAN_CANCELATION_FAILURE'
export const GET_INVOICES_BY_FAMILY_ID_REQUEST = 'GET_INVOICES_BY_FAMILY_ID_REQUEST'
export const GET_INVOICES_BY_FAMILY_ID_SUCCESS = 'GET_INVOICES_BY_FAMILY_ID_SUCCESS'
export const GET_INVOICES_BY_FAMILY_ID_FAILURE = 'GET_INVOICES_BY_FAMILY_ID_FAILURE'
export const SEND_MANDATE_LINK_REQUEST = 'SEND_MANDATE_LINK_REQUEST'
export const SEND_MANDATE_LINK_SUCCESS = 'SEND_MANDATE_LINK_SUCCESS'
export const SEND_MANDATE_LINK_FAILURE = 'SEND_MANDATE_LINK_FAILURE'
export const FETCH_HEALTH_DECLARATION_TNC_REQUEST = 'FETCH_HEALTH_DECLARATION_TNC_REQUEST'
export const FETCH_HEALTH_DECLARATION_TNC_SUCCESS = 'FETCH_HEALTH_DECLARATION_TNC_SUCCESS'
export const FETCH_HEALTH_DECLARATION_TNC_FAILURE = 'FETCH_HEALTH_DECLARATION_TNC_FAILURE'
