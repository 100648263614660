import React from 'react';
import _ from 'lodash';

import { connect } from 'react-redux';
import { TextField, Divider, Typography, Button, MenuItem } from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { orange } from "@material-ui/core/colors";
import Radio from '@material-ui/core/Radio';
import CircularProgress from '@material-ui/core/CircularProgress';

import { addNewMemberByFamilyId, getCustomerDetails, resetNewMemberAdded } from '../../../actions';
import { errors } from '../../../common/errors';

const materialTheme = createMuiTheme({
    palette: {
        primary: orange,
    },
});

const motherTongue = [
    {
        value: 'hindi',
        label: 'Hindi'
    },
    {
        value: 'english',
        label: 'English',
    },
    {
        value: 'kannada',
        label: 'Kannada',
    },
    {
        value: 'tamil',
        label: 'Tamil',
    },
    {
        value: 'telugu',
        label: 'Telugu',
    },
    {
        value: 'malayalam',
        label: 'Malayalam',
    },
    {
        value: 'bangla',
        label: 'Bangla',
    },
    {
        value: 'gujarati',
        label: 'Gujarati',
    },
    {
        value: 'marathi',
        label: 'Marathi',
    },
    {
        value: 'punjabi',
        label: 'Punjabi',
    },
    {
        value: 'odia',
        label: 'Odia',
    },
]

const relationships = [ // change relationships
    {
        value: 'self',
        label: 'Self',
    },
    {
        value: 'spouse',
        label: 'Spouse',
    },
    {
        value: 'father',
        label: 'Father'
    },
    {
        value: 'mother',
        label: 'Mother',
    },
    {
        value: 'son',
        label: 'Son',
    },
    {
        value: 'daughter',
        label: 'Daughter',
    },
    {
        value: 'brother',
        label: 'Brother',
    },
    {
        value: 'sister',
        label: 'Sister',
    },
    {
        value: 'friend',
        label: 'Friend',
    },
    {
        value: 'other',
        label: 'Other',
    },

]

function disableNextDates(startDate) {
    const startSeconds = Date.parse(startDate);
    return (date) => {
        return Date.parse(date) > startSeconds;
    }
}
class AddMemberForm extends React.Component {

    state = {
        dob: new Date(),
        firstName: '',
        lastName: '',
        gender: 'male',
        motherTongue: this.props.familyDetails && this.props.familyDetails.allMembers ? _.find(this.props.familyDetails.allMembers.members, (member) =>  member.relationship = "self" ).lop : '',
        relationship: '',
        addMemberDone: false,
    };

    componentDidMount() {
        this.props.resetNewMemberAdded();
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleDateChange = date => {
        this.setState({ dob: date });
    };

    clickSaveButton = () => {
        const familyId = this.props.familyDetails.currentCustomer ? this.props.familyDetails.currentCustomer.family.id : null;
        const newMemberObject = {
            first_name: this.state.firstName,
            dob: this.state.dob.getTime(),
            gender: this.state.gender,
            lop: this.state.motherTongue,
            relationship: this.state.relationship
        };
        if (this.state.lastName) {
            newMemberObject.last_name = this.state.lastName
        };
        this.setState({ saveButtonPressed: true })
        this.props.addNewMemberByFamilyId(newMemberObject, familyId);
        this.props.getCustomerDetails(this.props.familyDetails.customerPhoneNumber);
    };

    render() {
        if (!this.state.saveButtonPressed && this.props.familyDetails.newCustomer !== null) {
            return (
                <form style={{ position: 'relative' }}
                >
                    <Typography style={{ color: 'black', fontWeight: 'bold', fontSize: '1.2rem', fontFamily: 'Rubik', marginLeft: '0.8rem', marginBottom: '0.6rem' }}>Add Member</Typography>
                    <Divider style={{ backgroundColor: 'orange' }} />
                    <TextField onChange={this.handleChange('firstName')} label="First Name" style={{ display: 'flex', marginLeft: '1rem', marginRight: '1rem', marginTop: '2rem', marginBottom: '1.5rem' }} />
                    <TextField onChange={this.handleChange('lastName')} label="Last Name" style={{ display: 'flex', marginLeft: '1rem', marginRight: '1rem', marginTop: '2rem', marginBottom: '1.5rem' }} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                    </div>
                    <div style={{ marginLeft: '2vh' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography style={{ marginTop: '1.5vh', color: '#757575' }} > Gender</Typography>
                            <Radio
                                style={{ color: 'orange', marginLeft: '4vh' }}
                                checked={this.state.gender === 'male'}
                                onChange={() => this.setState({ gender: 'male' })}
                                value="male"
                            />
                            <Typography style={{ marginTop: '1.5vh', color: '#757575' }}> Male</Typography>
                            <Radio
                                style={{ color: 'orange' }}
                                checked={this.state.gender === 'female'}
                                onChange={() => this.setState({ gender: 'female' })}
                                value="female"
                            />
                            <Typography style={{ marginTop: '1.5vh', color: '#757575' }} > Female</Typography>
                        </div>

                    </div>
                    <div style={{ backgroundColor: 'white', marginTop: '1.5rem', marginLeft: '1rem' }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={materialTheme}>
                                <KeyboardDatePicker
                                    style={{ width: '15rem', display: 'flex' }}
                                    label="DOB"
                                    value={this.state.dob}
                                    onChange={this.handleDateChange}
                                    format="dd-MM-yyyy "
                                    helperText="Ex: 16-12-1950"
                                    shouldDisableDate={disableNextDates(new Date())}
                                />
                            </ThemeProvider>
                        </MuiPickersUtilsProvider>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <TextField
                                style={{ width: '95%', marginRight: '5%', float: 'left', display: 'flex' }}
                                select
                                label="Mother Tongue"
                                value={this.state.motherTongue}
                                onChange={this.handleChange('motherTongue')}
                                margin="normal"
                            >
                                {motherTongue.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                style={{ width: '95%', marginRight: '5%', float: 'left' }}
                                select
                                label="Relationship to H.S."
                                value={this.state.relationship}
                                onChange={this.handleChange('relationship')}
                                margin="normal"
                            >
                                {relationships.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                    <div style={{
                        position: 'fixed',
                        bottom: '0px',
                        right: '0px', width: '50vh'
                    }}>
                        <Button onClick={this.clickSaveButton} disabled={!this.state.dob ||
                            !this.state.firstName ||
                            !this.state.gender ||
                            !this.state.motherTongue ||
                            // this.state.contactNumber.length !== 10 || //phone number not mandatory
                            !this.state.relationship}
                            style={{ backgroundColor: (!this.state.dob ||
                                !this.state.firstName ||
                                !this.state.gender ||
                                !this.state.motherTongue ||
                                // this.state.contactNumber.length !== 10 ||  //phone number not mandatory
                                !this.state.relationship) ? 'grey' :'orange', color: 'white', width: '100%', height: '3rem' }}> Save</Button>
                    </div>
                </form>);
        } else {
            return (
                <div> <CircularProgress style={{ position: 'absolute', color: 'orange', left: '45%', right: '50%',top: '50%' , bottom: '50%'}} disableShrink /> 
        { (this.props.familyDetails &&  this.props.familyDetails.newMember === errors.ERROR_IN_ADDING_NEW_MEMBER) ? <Typography style={{ fontSize: '1.5vh', color: 'red',position: 'absolute',left: '15%', right: '15%',top: '60%' , bottom: '30%'}}> Error while adding member. Please try again later !</Typography>: <> </>}</div>
            );
        }
    }
};

const mapStateToProps = (state) => {
    return { auth: state.auth, familyDetails: state.familyDetails, error: state.error, consultationDetails: state.consultationDetails };
};

export default connect(mapStateToProps, { addNewMemberByFamilyId, getCustomerDetails, resetNewMemberAdded })(AddMemberForm);