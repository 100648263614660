import { leadsActionTypes } from "../constants";

const INITIAL_STATE = {
    isLoading: false,
    status: null,
    id: null,
};

const leadsReducer =  (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case leadsActionTypes.CREATE_LEAD_REQUEST: {
            return {
                isLoading: true,
                status: null,
                id: null,
            }
        }
        case leadsActionTypes.CREATE_LEAD_SUCCESS: {
            const { id } = action.payload;
            return {
                isLoading: false,
                status: 'success',
                id,
            }
        }
        case leadsActionTypes.CREATE_LEAD_FAILURE: {
            return {
                isLoading: false,
                status: 'failure',
                id: null,
            }
        }
        default:
            return state;
    }
};

export default leadsReducer;


