import { color } from '../../constants/colors';
const styles = () => ({
    royalOrange: {
        color: color.ROYAL_ORANGE,
    },
    indicator: {
        backgroundColor: "orange"
    },
    default_tabStyle: {
        color: 'grey',
    },
    active_tabStyle: {
        fontWeight: 'bold',
        color: 'black',
    },
    customerDetailsBox: {
        display:'flex',
        fontFamily: 'Rubik',
        alignItems: 'center',
        padding: '16px 0'
    },
    customerDetails: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 16px'
    },
    bold: {
        fontWeight: 'bold',
    },
    ml_4: {
        marginLeft: '4px',
        '&:hover': {
            textDecoration: 'underline',
        }
    },
    idContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: '5px',
    },
    copyCheckedIcon: {
        marginLeft: '4px',
        padding: '4px',
        display: 'flex',
        alignItems: 'center',
    },
    copyIcon: {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: color.oranges[100],
        }
    },
    checkedIcon : {
        '&:hover': {
            cursor: 'pointer',
        }
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    appBarColumn: {
        background: color.WHITE,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '80%',
    },
    tabs: {
        justifyContent: 'flex-start',
    },
    badges: {
        padding: '0px 1vw',
        justifyContent: 'flex-end',
    },
    recordActivityCta: {
        borderRadius: '6px',
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        fontSize: '13px',
        color: color.WHITE,
        background: color.ROYAL_ORANGE,
        padding: '0.5vh 2vh',
        height: '28px',
        outline: 'none',
        border: '0px',
        marginRight: '1vh',
        cursor: 'pointer',
        transition: '0.125s ease-in all',
        textTransform: 'none',
        '&:hover': {
            background: color.ORANGE,
        }
    },
    submitButton: {
        height: '40px',
        width: '100%',
        background: color.ROYAL_ORANGE,
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontWeight: 'bold',
        borderRadius: '6px',
        border: '0px',
        outline: 'none',
        transition: '0.125s ease-in all',
        color: color.WHITE,
        cursor: 'pointer',
        '&:disabled': {
            background: color.greys[100],
            color: color.WHITE
        },
        '&:hover': {
            background: color.ROYAL_ORANGE,
        }
    }
})

export default styles;