import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import history from '../history';
import { version } from '../../package.json';

let sentryCredentials = {
  dsn: "https://adc702f95ebc4b27a5f5158754f43bfa@o381166.ingest.sentry.io/6604523",
  integrations: [new Integrations.BrowserTracing({
    routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
  })],
  tracesSampleRate: 1.0,
};

if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'production') {
  sentryCredentials = {
    ...sentryCredentials,
    environment: 'production',
    release: `ops-dashboard@${version} - production`,
  };
} else if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'qa') {
  sentryCredentials = {
    ...sentryCredentials,
    environment: 'qa',
    release: `ops-dashboard@${version} - qa`,
  };
} else {
  sentryCredentials = {
    ...sentryCredentials,
    environment: 'staging',
    release: `ops-dashboard@${version} - staging`,
  };
}

const sentryCredentialsDetails = {
  sentryCredentials,
};

export default sentryCredentialsDetails;
