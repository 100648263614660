export const familyActionTypes = {
    ADD_METADATA_REQUEST: 'ADD_METADATA_REQUEST',
    ADD_METADATA_SUCCESS: 'ADD_METADATA_SUCCESS',
    ADD_METADATA_FAILURE: 'ADD_METADATA_FAILURE',

    UPDATE_FAMILY_BUSINESS_MAPPING_REQUEST: 'UPDATE_FAMILY_BUSINESS_MAPPING_REQUEST',
    UPDATE_FAMILY_BUSINESS_MAPPING_SUCCESS: 'UPDATE_FAMILY_BUSINESS_MAPPING_SUCCESS',
    UPDATE_FAMILY_BUSINESS_MAPPING_FAILURE: 'UPDATE_FAMILY_BUSINESS_MAPPING_FAILURE',

    DELETE_FAMILY_BUSINESS_MAPPING_REQUEST: 'DELETE_FAMILY_BUSINESS_MAPPING_REQUEST',
    DELETE_FAMILY_BUSINESS_MAPPING_SUCCESS: 'DELETE_FAMILY_BUSINESS_MAPPING_SUCCESS',
    DELETE_FAMILY_BUSINESS_MAPPING_FAILURE: 'DELETE_FAMILY_BUSINESS_MAPPING_FAILURE',
    
    SEARCH_BUSINESS_BY_NAME_EVENT_REQUEST: 'SEARCH_BUSINESS_BY_NAME_EVENT_REQUEST',
    SEARCH_BUSINESS_BY_NAME_EVENT_SUCCESS: 'SEARCH_BUSINESS_BY_NAME_EVENT_SUCCESS',
    SEARCH_BUSINESS_BY_NAME_EVENT_FAILURE: 'SEARCH_BUSINESS_BY_NAME_EVENT_FAILURE',

    DELETE_COI_REQUEST: 'DELETE_COI_REQUEST',
    DELETE_COI_SUCCESS: 'DELETE_COI_SUCCESS',
    DELETE_COI_FAILURE: 'DELETE_COI_FAILURE',

    SEND_COI_REQUEST: 'SEND_COI_REQUEST',
    SEND_COI_SUCCESS: 'SEND_COI_SUCCESS',
    SEND_COI_FAILURE: 'SEND_COI_FAILURE',

    UPDATE_FAMILY_SUBSCRIPTIONS: 'UPDATE_FAMILY_SUBSCRIPTIONS'
};

