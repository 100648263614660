import axios from 'axios';

import config from '../config';

export const getInvoicesBySubscriptionId = async (ACCESS_TOKEN, subscriptionId) => {
	const url = config.apiURL + `/v3/invoices?subscriptionId=${subscriptionId}`;
	const options = {
		headers: {
			'content-type': 'application/json',
			'Authorization': 'Bearer ' + ACCESS_TOKEN,
		},
		timeout: 10000,
		responseType: 'json',
		responseEncoding: 'utf8',
	};
	const response = await axios.get(url, options);
	return response;
};

export const getInvoicesByFamilyId = async (accessToken, familyId) => {
	const url = `${config.apiURL}/v1/family/${familyId}/invoices?size=10`;
	const invoiceApiTimeout = () => {
		return (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'production') ? 20000 : 10000;
	}
	const options = {
		method: 'GET',
		headers: {
			'content-type': 'application/json',
			'authorization': `Bearer ${accessToken}`,
		},
		timeout: invoiceApiTimeout,
		responseType: 'json',
		responseEncoding: 'utf8',
		url,
	};
	const result = await axios(options);
	return result;
}

/**
 * 
 * @param {String} accessToken JWT to authenticate this request
 * @param {String} externalId a `String` or `Number` identifier for the Invoice or Order respectively
 * @param {*} isInvoice Boolean flag to indicate whether or not this `externalId` belongs to an Invoice
 * @returns a Promise that resolves to either the API response or an error
 * 
 * This function calls the recon API that consumes an Order ID or Invoice ID, and reconciles the status of
 * any payments associated with them. __Always responds with a `2xx` status code, and the response has no body.
 * This implies that the async reconciliation process has started, but makes no guarantee
 * that the next API call for invoices/orders will return updated data.__
 */
export const reconOrderOrInvoiceById = async (accessToken, externalId, isInvoice = false) => {
	let url = `${config.apiURL}/v3/orders/payments/reconcile`;
	if (isInvoice) {
		url += `?lineItemExternalId=${externalId}`;
	} else {
		url += `?orderId=${externalId}`;
	}
	const options = {
		method: 'GET',
		headers: {
			'content-type': 'application/json',
			'authorization': `Bearer ${accessToken}`,
		},
		timeout: config.timeout,
		responseType: 'json',
		responseEncoding: 'utf8',
		url,
	};
	const result = await axios(options);
	return result;
}

