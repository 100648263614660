import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import useStyles from './style';
import {
	withStyles,
	Divider,
	IconButton,
	Typography,
	CircularProgress,
	Paper,
	OutlinedInput,
	InputAdornment,
	TablePagination,
	Box,
	AppBar,
	Tab,
	Tabs,
} from '@material-ui/core';
import SubscriptionCard from './subscriptionCard';
import { errors } from '../../common/errors';
import { getAllSubscriptionsByDate, resetAllSubscriptionsByDate } from '../../actions/index';
import CloseIcon from '@material-ui/icons/Close';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { getISTFormat5, getTimeStampFromDate } from '../../common/utils';
import CustomerDetails from './addCustomerDetails/CustomerDetails';
import { subscriptionStatus } from '../../common/constants';

class SubscriptionList extends React.Component {
	constructor() {
		super();
		this.state = {
			itemsPerPage: 10,
			currentPage: 1,
			searchPhoneNumber: '',
			selectedStartDate: getISTFormat5(new Date(new Date().getTime() - 86400000)),
			selectedEndDate: getISTFormat5(new Date()),
			selectedSubscription: {},
			copiedText: '',
			activeTabIndex: 0,
		};
	}

	requiredSubscriptionStatus = () => {
		if (this.state.activeTabIndex === 0) {
			return subscriptionStatus.ACTIVE;
		} else if (this.state.activeTabIndex === 1) {
			return subscriptionStatus.PENDING;
		}
	};

	resetSelectedSubscription = () => {
		this.setState({ selectedSubscription: {} }, () =>
			this.props.getAllSubscriptionsByDate(
				this.state.currentPage,
				this.state.itemsPerPage,
				getTimeStampFromDate(this.state.selectedStartDate),
				getTimeStampFromDate(this.state.selectedEndDate),
				this.requiredSubscriptionStatus()
			)
		);
	};

	componentDidMount() {
		this.props.resetAllSubscriptionsByDate();
		if (
			this.props.auth &&
			this.props.auth.currentClinicLocation &&
			this.props.auth.currentClinicLocation.id
		) {
			this.props.getAllSubscriptionsByDate(
				this.state.currentPage,
				this.state.itemsPerPage,
				getTimeStampFromDate(this.state.selectedStartDate),
				getTimeStampFromDate(this.state.selectedEndDate),
				this.requiredSubscriptionStatus()
			);
		}
		document.addEventListener('visibilitychange', () => {
			if (document.hidden) {
				this.setCopiedText('');
			}
		});
	}

	componentDidUpdate(prevProps, prevState) {
        // TODO: This componentDidUpdate logic is faulty
        // changing the active tab causes subscriptions to be requested twice
        // because there is no cutoff condition to
        // prevent new requests if one is already in progress;
        // This needs to be fixed later;
		if (
			prevState.itemsPerPage !== this.state.itemsPerPage ||
			prevState.currentPage !== this.state.currentPage ||
			prevState.selectedStartDate !== this.state.selectedStartDate ||
			prevState.selectedEndDate !== this.state.selectedEndDate ||
			prevState.activeTabIndex !== this.state.activeTabIndex
		) {
			this.props.resetAllSubscriptionsByDate();
			this.props.getAllSubscriptionsByDate(
				this.state.currentPage,
				this.state.itemsPerPage,
				getTimeStampFromDate(this.state.selectedStartDate),
				getTimeStampFromDate(this.state.selectedEndDate),
				this.requiredSubscriptionStatus()
			);
		}
	}

	setSelectedSubscriptionInParent = (subscription) => {
		this.setState({ selectedSubscription: subscription });
	};

	setCopiedText = (text) => {
		this.setState({ copiedText: text });
	};

	renderAllSubscriptions = () => {
		const { classes } = this.props;
		if (
			this.props.pwaDetails.allSubscriptions ===
			errors.ERROR_IN_GETTING_ALL_SUBSCRIPTIONS_BY_DATE_PAGINATED
		) {
			return (
				<div style={{ marginLeft: '15vh' }}>
					{' '}
					<Typography style={{ fontFamily: 'Rubik' }}>
						Error while loading subscriptions
					</Typography>
				</div>
			);
		} else if (
			this.props.pwaDetails.allSubscriptions &&
			_.isEmpty(this.props.pwaDetails.allSubscriptions.subscriptions)
		) {
			return (
				<div>
					<Typography
						style={{
							fontStyle: 'italic',
							fontSize: '3vh',
							marginLeft: '40vh',
							marginTop: '10vh',
							color: 'grey',
							fontFamily: 'Rubik',
						}}
					>
						Subscriptions not found
					</Typography>
				</div>
			);
		} else {
			let subscriptionList = this.props.pwaDetails.allSubscriptions
				? this.props.pwaDetails.allSubscriptions.subscriptions
				: [];
			if (this.state.searchPhoneNumber) {
				subscriptionList = _.filter(subscriptionList, (subscription) => {
					return subscription.primary_number.includes(this.state.searchPhoneNumber);
				});
			}
			if (_.isEmpty(subscriptionList)) {
				return (
					<Typography
						align='center'
						style={{ marginTop: '20px', fontFamily: 'Manrope-Regular' }}
					>
						No Results Found. Please check the number.
					</Typography>
				);
			} else {
				return (
					<div className={classes.subscriptionList}>
						{_.map(subscriptionList, (subscription, index) => {
							return (
								<SubscriptionCard
									key={subscription.id}
									subscription={subscription}
									setSelectedSubscriptionInParent={
										this.setSelectedSubscriptionInParent
									}
									setCopiedText={this.setCopiedText}
									copiedText={this.state.copiedText}
									activeTabIndex={this.state.activeTabIndex}
									selectedSubscription={this.state.selectedSubscription}
									position={index + 1}
								/>
							);
						})}
					</div>
				);
			}
		}
	};

	ProgressIndicator = () => (
		<CircularProgress
			color='primary'
			style={{
				left: '47%',
				top: '50%',
				position: 'absolute',
			}}
			disableShrink
		/>
	);

	setSearchPhone = (event) => {
		this.setState({
			searchPhoneNumber: event.target.value,
		});
	};

	resetPhoneNumber = () => {
		this.setState({
			searchPhoneNumber: '',
		});
	};

	handleStartDateChange = (date) => {
		this.setState({ selectedStartDate: getISTFormat5(date) });
	};

	handleEndDateChange = (date) => {
		this.setState({ selectedEndDate: getISTFormat5(date) });
	};

	renderFilterMember = () => {
		const { classes } = this.props;
		return (
			<div className={classes.searchAndFilter}>
				<div className={classes.searchWithClearText}>
					<OutlinedInput
						color='primary'
						fullWidth
						required
						className={
							this.state.searchPhoneNumber
								? classes.mobileSearchInputWithPhone
								: classes.mobileSearchInput
						}
						variant='outlined'
						onChange={this.setSearchPhone}
						onInput={(e) => {
							e.target.value = e.target.value.replace(/[^0-9]/g, '');
						}}
						value={this.state.searchPhoneNumber}
						inputProps={{
							maxLength: 10,
							style: {
								fontFamily: 'Manrope-Regular',
								fontSize: '12px',
								color: '#000000',
							},
						}}
						inputlabelprops={{
							style: {
								fontSize: '9px',
								fontFamily: 'Manrope-Regular',
								color: '#000000',
							},
						}} // font size of input label
						type='tel'
						placeholder='Filter by phone number on this page'
						endAdornment={
							<InputAdornment position='end'>
								{this.state.searchPhoneNumber && (
									<IconButton
										aria-label='erase typed ifsc'
										disabled={false}
										onClick={this.resetPhoneNumber}
									>
										<CloseIcon
											style={{ color: '#000000' }}
											className={classes.searchButton}
										/>
									</IconButton>
								)}
							</InputAdornment>
						}
					/>
				</div>
				<div className={classes.dateFieldBox}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							label='Start Date'
							value={this.state.selectedStartDate}
							onChange={this.handleStartDateChange}
							format='dd-MM-yyyy'
							className={classes.startDateField}
							disableFuture
						/>
					</MuiPickersUtilsProvider>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							minDate={this.state.selectedStartDate}
							label='End Date'
							value={this.state.selectedEndDate}
							onChange={this.handleEndDateChange}
							format='dd-MM-yyyy'
							className={classes.endDateField}
							disableFuture
						/>
					</MuiPickersUtilsProvider>
				</div>
			</div>
		);
	};

	handleChangeRowsPerPage = (event) => {
		this.setState({ itemsPerPage: Number(event.target.value) });
	};

	handleChangePage = (event, page) => {
		this.setState({ currentPage: page + 1 });
	};

	handleStatusChange = (event, value) => {
		this.setState({ activeTabIndex: value, currentPage: 1 }, () =>
			this.resetSelectedSubscription()
		);
	};

	render() {
		const { classes, pwaDetails } = this.props;
		return (
			<div
				onCopy={() =>
					navigator.clipboard
						.readText()
						.then((text) => this.setState({ copiedText: text }))
				}
			>
				<Typography className={classes.tabHeading}>PWA Customer Sales</Typography>

				<AppBar position='static' elevation={0} color='transparent'>
					<Tabs
						value={this.state.activeTabIndex}
						onChange={this.handleStatusChange}
						className={classes.salesTaskHeader}
						classes={{ indicator: classes.indicator }}
					>
						<Tab
							className={
								this.state.activeTabIndex === 0
									? classes.active_tab
									: classes.default_tabStyle
							}
							label='Completed Sales'
						/>
						<Tab
							className={
								this.state.activeTabIndex === 1
									? classes.active_tab
									: classes.default_tabStyle
							}
							label='Pending Sales'
						/>
					</Tabs>
				</AppBar>

				<Paper className={classes.allSubscriptionsContainer}>
					{_.isEmpty(this.state.selectedSubscription) ? (
						<>
							{this.renderFilterMember()}
							<div className={classes.tableHeading}>
								<Typography
									noWrap
									className={classes.mobileNumberHeading}
								>{`Mobile No.`}</Typography>
								<Typography
									noWrap
									className={classes.planNameHeading}
								>{`Plan Name`}</Typography>
								<Typography noWrap className={classes.totalMembersHeading}>
									{this.state.activeTabIndex === 0 ? `Members` : `Business`}
								</Typography>
								<Typography
									noWrap
									className={classes.dateOfPurchaseHeading}
								>{`Date and Time`}</Typography>
								{this.state.activeTabIndex === 0 && (
									<Box className={classes.businessNameBox}>
										<Typography noWrap className={classes.businessName}>
											{'Business'}
										</Typography>
									</Box>
								)}
								{this.state.activeTabIndex === 0 && (
									<Typography
										noWrap
										className={classes.GhdStatus}
									>{`GHD Status`}</Typography>
								)}
							</div>
							<Divider />

							{!pwaDetails.loadingAllSubscriptions
								? this.renderAllSubscriptions()
								: this.ProgressIndicator()}
							<table className={classes.paginationComponent}>
								<tbody>
									<tr>
										<TablePagination
											rowsPerPageOptions={[5, 10, 25]}
											colSpan={3}
											count={
												this.props.pwaDetails &&
												this.props.pwaDetails.allSubscriptions &&
												this.props.pwaDetails.allSubscriptions
													? this.props.pwaDetails.allSubscriptions
															.total_count
													: 0
											}
											rowsPerPage={this.state.itemsPerPage}
											page={this.state.currentPage - 1}
											SelectProps={{
												native: true,
											}}
											onChangePage={this.handleChangePage}
											onChangeRowsPerPage={this.handleChangeRowsPerPage}
										/>
									</tr>
								</tbody>
							</table>
						</>
					) : (
						<>
							{' '}
							<CustomerDetails
								selectedSubscription={this.state.selectedSubscription}
								resetSelectedSubscription={this.resetSelectedSubscription}
							/>
						</>
					)}
				</Paper>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const { auth, pwaDetails } = state;
	return { auth, pwaDetails };
};

const mapDispatchToProps = (dispatch) => {
	return {
		getAllSubscriptionsByDate: (
			currentPage,
			itemsPerPage,
			startDate,
			endDate,
			subscriptionStatus
		) => {
			dispatch(
				getAllSubscriptionsByDate(
					currentPage,
					itemsPerPage,
					startDate,
					endDate,
					subscriptionStatus
				)
			);
		},
		resetAllSubscriptionsByDate: () => {
			dispatch(resetAllSubscriptionsByDate());
		},
	};
};

const ConnectedSubscriptionList = connect(mapStateToProps, mapDispatchToProps)(SubscriptionList);
export default withStyles(useStyles, { withTheme: true })(ConnectedSubscriptionList);
