import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Step, StepConnector, Stepper, StepLabel, CircularProgress, Divider, Button, Tooltip } from '@material-ui/core';
import AddPrimaryDetails from './StepComponents/addPrimaryDetails';
import SelectPrimaryMember from './StepComponents/selectPrimaryMember';
import ReviewPlan from './StepComponents/reviewPlan';
import SelectDependents from './StepComponents/selectDependents';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { createHeadSubscriber, resetCreateSubscription, setNewPlanMobileNumber, resetNewPlanMobileNumber, resetCreateHeadSubscriber, resetCustomerByPhoneNumber, updateCustomer, resetUpdateMember, updateCohortEvent, selectCohort, getPlanBillingPrefEvent, getSubscriptionById } from '../../../actions';
import useStyles from './style'
import { strings } from '../../../constants/strings';
import { tncStatus as ghdStatus } from '../../../common/constants';
import CustomerPreference from './CustomerPreference';

const muiTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiStepIcon: {
            root: {
                marginTop: '2vh',
                marginLeft: '4vh',
                marginRight: '4vh',
                color: '#ffe6b1',
                '& $text': {
                    fill: '#f38b44',
                    fontFamily: 'Rubik-Medium',
                },

                '&$active': {
                    fill: '#f38b44',
                    '& $text': {
                        fill: 'white',
                    },
                },
                '&$completed': {
                    marginLeft: '4vh',
                    marginRight: '4vh',
                    color: '#47B29F',
                },
            },
        },
    }
});



class CustomerDetails extends React.Component {

    componentDidMount() {
        this.props.resetUpdateMember();
        this.props.resetNewPlanMobileNumber();
        this.props.resetCreateHeadSubscriber();
        this.props.resetCustomerByPhoneNumber();
    };

    state = {
        activeStep: 0,
        subscribeButtonPressed: false,
        mobileNumber: '',
        retypedMobileNumber: '',
        first_name: '',
        last_name: '',
        text: '',
        dob: '',
        lop: '',
        pincode: '',
        selectedPrimaryCustomerId: '',
        selectedSpouseCustomerId: '',
        selectedKid1CustomerId: '',
        selectedKid2CustomerId: '',
        selectedKid3CustomerId: '',
        showConfirmationDrawer: false,
        availablePaymentCycle: [{ name: '5th', cohort: 5 }, { name: '20th', cohort: 20 }],
    };



    productHasAccidentalOrHealthOrHospicash = () => {
        return _.hasIn(this.props.selectedSubscription.product, 'health_insurance') || _.hasIn(this.props.selectedSubscription.product, 'accidental_insurance') || _.hasIn(this.props.selectedSubscription.product, 'hospicash')
    }

    productIsForSelf = () => {
        return this.props.selectedSubscription.product.constraint.allowed_members.adult === 1
        && (this.props.selectedSubscription.product.constraint.allowed_members?.child === 0
            || !this.props.selectedSubscription.product.constraint.allowed_members?.child);
    }

    productIsForCouple = () => {
        return ((this.props.selectedSubscription.product.constraint.allowed_members.adult === 2
        && (this.props.selectedSubscription.product.constraint.allowed_members?.child === 0))
        || (this.props.selectedSubscription.product.constraint.allowed_members.adult === 1
            && this.props.selectedSubscription.product.constraint.allowed_members?.child === 1));
    }

    productIsForFamily = () => {
        // only return true if there is *at least* 1 child in the plan
        // 1A0C = self, 2A0C = couple, 1AnC = family, 2AnC = family;
        if (this.props.selectedSubscription.product.constraint.allowed_members.adult === 1
            || this.props.selectedSubscription.product.constraint.allowed_members.adult === 2) {
            return this.props.selectedSubscription.product.constraint.allowed_members.child >= 1;
        }
        return false;
    }

    getSteps = () => {
       
            if (this.productIsForSelf()) {
                return ['Customer Details', 'Primary Member', 'Preference', 'Confirmation'];
            }
            return ['Customer Details', 'Primary Member', 'Dependents', 'Preference', 'Confirmation'];
        
    }

    isDependentsValid() {
        const {
            selectedSpouseCustomerId,
            selectedKid1CustomerId,
            selectedKid2CustomerId,
            selectedKid3CustomerId,
        } = this.state
        const { selectedSubscription } = this.props;
        const { product } = selectedSubscription;
        const adult = product.constraint && product.constraint.allowed_members.adult;
        const child = product.constraint && product.constraint.allowed_members.child;
        if (adult > 1 && (!child || child === 0) && selectedSpouseCustomerId) {
            return true;
        } else if (adult === 1 && child === 1 && selectedKid1CustomerId) {
            return true;
        } else if (adult === 1 && child === 2 && selectedKid1CustomerId && selectedKid2CustomerId) {
            return true;
        } else if (adult === 1 && child === 3 && selectedKid1CustomerId && selectedKid2CustomerId && selectedKid3CustomerId) {
            return true;
        } else if (adult === 2 && child === 1 && selectedSpouseCustomerId && selectedKid1CustomerId) {
            return true;
        } else if (adult === 2 && child === 2 && selectedSpouseCustomerId && selectedKid1CustomerId && selectedKid2CustomerId) {
            return true;
        } else if (adult === 2 && child === 3 && selectedSpouseCustomerId && selectedKid1CustomerId && selectedKid2CustomerId
            && selectedKid3CustomerId) {
            return true;
        } else {
            return false;
        }
    }

    nextButtonDisabled = () => {
        const steps = this.getSteps();
            switch (steps[this.state.activeStep]) {
                case strings.CUSTOMER_DETAILS:
                    return !(this.state.activeStep === 0 && this.state.first_name && this.state.dob && this.state.gender && this.state.lop);
                case strings.PRIMARY_MEMBER:
                    return !(this.state.activeStep === 1 && this.state.selectedPrimaryCustomerId);
                case strings.DEPENDENTS:
                    return !this.isDependentsValid();
                case strings.PREFERENCE: {
                    return (this.props.newPlan.customerByPhoneNumber?.preferences?.whatsapp
                    && (this.props.selectedSubscription.tncStatus === ghdStatus.IN_REVIEW
                    || !this.props.selectedSubscription.tncStatus
                    || this.props.selectedSubscription.tncStatus === ghdStatus.PENDING));
                }
                case strings.CONFIRMATION:
                    return false;
                default:
                    return true;
            }
    }


    handleCohort = (cohort) => {
        const { auth } = this.props;
        this.props.selectCohort(cohort)
        const cohortData = {
            product: {
                id: this.props.selectedSubscription.product.id,
              },
              cohort: cohort,
        };

        const accessToken = auth.authDetails.data.access_token
        const familyId = this.props.selectedSubscription.family.id;
        this.props.updateCohortEvent(accessToken, familyId, cohortData);
    }
    

    getStepComponent = (stepIndex) => {
        const { selectedSubscription, resetSelectedSubscription, familyDetails } = this.props;
        const headSubscriber = familyDetails.allMembers.members.filter(member => member.relationship === 'self')[0];
        const phoneNumber = headSubscriber.primary_number;
        const steps = this.getSteps();
        switch (steps[stepIndex]) {
            case strings.CUSTOMER_DETAILS:
                return <AddPrimaryDetails handleNameChange={this.handleNameChange} first_name={this.state.first_name} last_name={this.state.last_name} text={this.state.text} dob={this.state.dob}
                    setParentState={this.setParentState} mobileNumber={phoneNumber} pincode={this.state.pincode} lop={this.state.lop} gender={this.state.gender}
                />;
            case strings.PRIMARY_MEMBER:
                return <SelectPrimaryMember setParentState={this.setParentState} selectedPrimaryCustomerId={this.state.selectedPrimaryCustomerId} selectedSubscription={this.props.selectedSubscription} />;
            case strings.DEPENDENTS:
                return <SelectDependents
                setParentState={this.setParentState}
                selectedPrimaryCustomerId={this.state.selectedPrimaryCustomerId}
                selectedSpouseCustomerId={this.state.selectedSpouseCustomerId}
                selectedKid1CustomerId={this.state.selectedKid1CustomerId}
                selectedKid2CustomerId={this.state.selectedKid2CustomerId}
                selectedKid3CustomerId={this.state.selectedKid3CustomerId}
                selectedSubscription={this.props.selectedSubscription}
            />;
            case strings.PREFERENCE:
                return <CustomerPreference resetSelectedSubscription={resetSelectedSubscription} selectedSubscription={selectedSubscription} />
            case strings.CONFIRMATION:
                return <ReviewPlan selectedPrimaryCustomerId={this.state.selectedPrimaryCustomerId}
                    selectedSpouseCustomerId={this.state.selectedSpouseCustomerId}
                    selectedKid1CustomerId={this.state.selectedKid1CustomerId}
                    selectedKid2CustomerId={this.state.selectedKid2CustomerId}
                    selectedKid3CustomerId={this.state.selectedKid3CustomerId}
                    selectedSubscription={this.props.selectedSubscription}
                    showConfirmationDrawer={this.state.showConfirmationDrawer}
                    closeAddPlanDetailsDrawer={this.closeAddPlanDetailsDrawer}
                    resetSelectedSubscription={this.props.resetSelectedSubscription}
                />;
            default:
                return <div>Something went wromg !!</div>;
        }
    }

    getCircularProgress = () => {
        return <CircularProgress style={{
            color: 'orange',
            left: '50%',
            top: '55%',
            position: 'absolute',
        }} disableShrink />;
    }

    setParentState = (stateName, stateValue) => {
        if (stateName === 'mobileNumber') { this.setState({ mobileNumber: stateValue }) }
        if (stateName === 'retypedMobileNumber') { this.setState({ retypedMobileNumber: stateValue }) }
        if (stateName === 'name') { this.setState({ name: stateValue }) }
        if (stateName === 'dob') { this.setState({ dob: stateValue }) }
        if (stateName === 'gender') { this.setState({ gender: stateValue }) }
        if (stateName === 'lop') { this.setState({ lop: stateValue }) }
        if (stateName === 'pincode') { this.setState({ pincode: stateValue }) }
        if (stateName === 'selectedPrimaryCustomerId') { this.setState({ selectedPrimaryCustomerId: stateValue }) }
        if (stateName === 'selectedSpouseCustomerId') { this.setState({ selectedSpouseCustomerId: stateValue }) }
        if (stateName === 'selectedKid1CustomerId') { this.setState({ selectedKid1CustomerId: stateValue }) }
        if (stateName === 'selectedKid2CustomerId') { this.setState({ selectedKid2CustomerId: stateValue }) }
        if (stateName === 'selectedKid3CustomerId') { this.setState({ selectedKid3CustomerId: stateValue }) }
    }


    handleNext = () => {
        if (this.nextButtonDisabled()) return;
        const steps = this.getSteps();
        this.setState({ activeStep: this.state.activeStep + 1 })
        if (this.state.activeStep === steps.length - 1) {
            this.setState({ showConfirmationDrawer: true, activeStep: steps.length - 1 })
        }
        if (this.state.activeStep === 0) {
            const member = {
                first_name: this.state.first_name,
                dob: this.state.dob.getTime(),
                gender: this.state.gender,
                lop: this.state.lop,
                relationship: 'self',
            }
            if (this.state.last_name) {
                let lastNameModified = this.state.last_name.join(' ');
                if (lastNameModified && lastNameModified.length > 0) {
                    member.last_name = lastNameModified;
                }
            };
            this.props.updateCustomer(_.omitBy(member, _.isNull || _.isNil), this.props.newPlan.customerByPhoneNumber.family.id, this.props.newPlan.customerByPhoneNumber.id);
        }
    };

    exitAddCustomer = () => {
        this.props.resetSelectedSubscription();
    }

    handleBack = () => {
        if (this.state.activeStep === 0) {
            this.exitAddCustomer();
        } else {
            this.setState(state => ({
                activeStep: state.activeStep - 1,
            }));
        }
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,
        });
    };

    getNextButtonHelperText = () => {
        if (this.productIsForSelf()) {
            switch (this.state.activeStep) {
                case 0:
                    return 'Please enter name, DOB, Preferred Language and gender before proceeding'
                case 1:
                    return 'Please select the Primary member'
                case 2:
                    return 'Please accept or reject the Good Health Declaration for this customer!'
                default:
                    return ""
            }
        } else {
            switch (this.state.activeStep) {
                case 0:
                    return 'Please enter name, DOB, Preferred Language and gender before proceeding'
                case 1:
                    return 'Please select the Primary member'
                case 3:
                    return 'Please accept or reject the Good Health Declaration for this customer!'
                default:
                    return ""
            }
        }
    }

    handleNameChange = (text) => {
        if (text) {
            this.setState({ text: text.trim() },
            () => this.setState({ first_name: _.split(this.state.text, ' ')[0], last_name: this.state.text.split(" ").slice(1) }))
        }   
    }


    closeAddPlanDetailsDrawer = () => {
        this.setState({ showConfirmationDrawer: false })
    }

    render() {
        const { classes } = this.props;
        const steps = this.getSteps();
        const { activeStep } = this.state;
        const connector = (
            <StepConnector
                classes={{
                    active: classes.connectorActive,
                    completed: classes.connectorCompleted,
                    disabled: classes.connectorDisabled,
                    line: classes.connectorLine,
                }}
            />
        );
        return (
            <div className={classes.root}>
                <MuiThemeProvider theme={muiTheme}>
                    <Stepper activeStep={activeStep} alternativeLabel connector={connector}>
                        {steps.map(label => (
                            <Step key={label}>
                                <StepLabel classes={{
                                    iconContainer: classes.iconContainer
                                }}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </MuiThemeProvider>
                <div>
                    <Divider style={{ backgroundColor: '#f38b44', marginLeft: '4vw', marginRight: '4vw', height: '2px' }} />
                    {this.getStepComponent(activeStep)}
                    <Divider style={{ height: '2px' }} />
                    <div style={{ marginTop: '2vh', marginBottom: '2vh', height: '5vh', overflowY: 'auto' }}>
                        <div >
                            <Button
                                variant="outlined"
                                style={{ left: '5vw', width: '9vw', textTransform: 'capitalize', borderColor: '#f38b44', color: '#f38b44', position: 'absolute' }}
                                onClick={this.handleBack}
                                className={classes.backButton}
                            >
                                {this.state.activeStep === 0 ? 'Exit' : 'Back'}
                            </Button>

                            <Tooltip title={this.nextButtonDisabled() ? this.getNextButtonHelperText() : ''}>
                                <Button
                                    style={{ cursor: this.nextButtonDisabled() ? 'help' : 'pointer', right: '5vw', width: '9vw', backgroundColor: '#f38b44', opacity: this.nextButtonDisabled() ? '70%' : '100%', textTransform: 'capitalize', position: 'absolute' }}
                                    variant="contained" color="primary"
                                    onClick={this.handleNext}
                                >
                                    {activeStep === steps.length - 1 ? 'Confirm' : 'Next'}
                                </Button>
                            </Tooltip>
                        </div>

                        {/* )} */}
                    </div>


                </div>
            </div>
        );
    }
}

CustomerDetails.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error, familyDetails: state.familyDetails, newPlan: state.newPlan, newCustomer: state.newCustomer };
};

export default connect(mapStateToProps, { getSubscriptionById,createHeadSubscriber, resetCreateSubscription, setNewPlanMobileNumber, resetNewPlanMobileNumber, resetCreateHeadSubscriber, resetCustomerByPhoneNumber, updateCustomer, resetUpdateMember, updateCohortEvent, selectCohort, getPlanBillingPrefEvent})(withStyles(useStyles, { withTheme: true })(CustomerDetails));