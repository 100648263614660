import React from "react";
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import NachMiddleTab from './NachMiddleTab';
import CreditMiddleTab from './CreditMiddleTab';
class Members extends React.Component {

    state = { currentPaymentDetailTypeIndex: 0 }; //RE_SET

    render() {

        return (
            <div className='main' style={{
                marginTop: '0.25vh',
                display: 'flex',
                flexFlow: 'row', height: '73vh', width: '100%', posiiton: 'relative' 
            }}>
                <div style={{ border: '0.5px solid #f4f4f4', borderLeft: 'none', borderRight: 'none', borderBottom: 'none', backgroundColor: "white", width: "25%", }}>
                    <div >

                        <Button
                            onClick={() => this.setState({ currentPaymentDetailTypeIndex: 0 })}
                            style={{
                                display: 'inline-block', textAlign: 'left',
                                backgroundColor: this.state.currentPaymentDetailTypeIndex === 0 ? '#f4f4f4' : 'white',
                                height: '6vh', cursor: 'pointer', textTransform: 'capitalize', width: '100%',
                            }}
                        >
                            {this.state.currentPaymentDetailTypeIndex === 0 ? <Typography style={{ fontWeight: 'bold', fontSize: '15px', marginLeft: '3vh' }}>Nach</Typography> : <Typography style={{ fontSize: '15px', marginLeft: '3vh' }}>Nach</Typography>}
                        </Button>
                        <Button
                            onClick={() => this.setState({ currentPaymentDetailTypeIndex: 1 })}
                            style={{
                                display: 'inline-block', textAlign: 'left',
                                backgroundColor: this.state.currentPaymentDetailTypeIndex === 1 ? '#f4f4f4' : 'white',
                                height: '6vh', cursor: 'pointer', textTransform: 'capitalize', width: '100%',
                            }}
                        >
                            {this.state.currentPaymentDetailTypeIndex === 1 ? <Typography style={{ fontWeight: 'bold', fontSize: '15px', marginLeft: '3vh' }}>Credits</Typography> : <Typography style={{ fontSize: '15px', marginLeft: '3vh' }}>Credit</Typography>}
                        </Button>

                    </div>
                </div>
                <div style={{ border: '0.5px solid #f4f4f4', borderBottom: 'none', backgroundColor: "white", width: "30%", textAlign: 'center', posiiton: 'relative' }}>

                    <Typography style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '20px', marginLeft: '3vh', marginTop: '3vh' }}>{this.state.currentPaymentDetailTypeIndex === 0 ? 'Nach Details' : 'Credit Details'}</Typography>
                    {this.state.currentPaymentDetailTypeIndex === 0 ? <NachMiddleTab /> : <CreditMiddleTab />}
                </div>
                <div style={{ overflowY: 'scroll', height: '1000 px', border: '0.5px solid #f4f4f4', borderRight: 'none', borderLeft: 'none', borderBottom: 'none', backgroundColor: "white", width: "45%", textAlign: 'center' }}>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { auth: state.auth, familyDetails: state.familyDetails, error: state.error };
}


export default connect(mapStateToProps)(Members);