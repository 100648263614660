import { color } from '../../../constants/colors';
const styles = () => ({
    pencil: {
        marginRight: '8px',
    },
    orangeColor: {
        color: color.ROYAL_ORANGE,
        borderColor: color.ROYAL_ORANGE,
    },
    editButton: {
        textTransform: 'capitalize'
    }
});

export default styles;