import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box, Button, Typography } from "@material-ui/core";
import styles from "./style";
import {
  getOpdTransactionsEvent,
  resetOpdTransactions,
} from "../../../actions";
import moment from "moment";

class OpdDrawer extends React.Component {
  constructor() {
    super();
    this.state = {
      confirmationText:
        "The customer will not be eligible for Clinikk Healthcare Plans due to the selected health conditions.",
      renderMain: true,
      processingAmountValue: 0,
      page: 1,
    };
  }

  componentDidMount = () => {
    const { subscription, opdTransactions } = this.props;
    opdTransactions(subscription?.id);
  };

  componentDidUpdate(prevProps) {
    // Check if opdState has changed
    if (
      this.props.familyDetails?.opdTransactions?.data?.opdDetails !==
      prevProps.familyDetails?.opdTransactions?.data?.opdDetails
    ) {
      this.processingAmount();
    }
  }

  processingAmount = () => {
    const { familyDetails } = this.props;
    const opdState = familyDetails?.opdTransactions?.data?.opdDetails;
    if (opdState) {
      const amount = opdState?.transaction
        ?.filter(
          (transaction) =>
            transaction?.tags &&
            transaction?.tags?.includes("reimbursement_processing")
            && !transaction?.tags?.includes("renewal")
        )
        ?.reduce(
          (accumulator, transaction) => accumulator + transaction?.amount,
          0
        );
      this.setState({ processingAmountValue: amount });
    }
  };

fetchMoreData = async(subscription) => {
    const { page } = this.state;
    this.setState({ page: page + 1 });
    await this.props.opdTransactions(subscription?.id, page + 1);
    this.processingAmount()
  };

  getOpdStatus = (transaction) => {
    if (transaction?.transaction_type === "credit") {
      return "Recharged";
    } else if (transaction?.transaction_type === "expired") {
      return "Expired";
    } else if (
      transaction?.transaction_type === "debit" &&
      transaction?.tags?.includes("reimbursement_processing")
    ) {
      return "Approved";
    } else if (transaction?.transaction_type === "debit") {
      return "Completed";
    }
  };

  getStyleForTransactions = (transaction) => {
    const transactionStatus = this.getOpdStatus(transaction);
    if (transactionStatus === "Approved") {
      return {
        fontFamily: "rubik",
        fontSize: "12px",
        marginLeft: "12px",
        paddingLeft: "8px",
        fontWeight: "600",
        paddingRight: "8px",
        color: "#808080",
        border: "1px solid #808080",
        borderRadius: "6px",
      };
    } else if (transactionStatus === "Completed") {
      return {
        fontFamily: "rubik",
        fontSize: "12px",
        marginLeft: "12px",
        paddingLeft: "8px",
        fontWeight: "600",
        paddingRight: "8px",
        color: "#0D7E51",
        border: "1px solid #0D7E51",
        borderRadius: "6px",
      };
    } else if (transactionStatus === "Recharged") {
      return {
        fontFamily: "rubik",
        fontSize: "12px",
        marginLeft: "12px",
        paddingLeft: "8px",
        fontWeight: "600",
        paddingRight: "8px",
        color: "#7000FF",
        border: "1px solid #7000FF",
        borderRadius: "6px",
      };
    } else if (transactionStatus === "Expired") {
      return {
        fontFamily: "rubik",
        fontSize: "12px",
        marginLeft: "12px",
        paddingLeft: "8px",
        fontWeight: "600",
        paddingRight: "8px",
        color: "red",
        border: "1px solid red",
        borderRadius: "6px",
      };
    }
  };

  render() {
    const { classes, closeOpdDrawer, subscription, familyDetails } = this.props;
    const { processingAmountValue } = this.state;
    const opdState = familyDetails?.opdTransactions?.data?.opdDetails;
    const formattedTotalOPD = opdState?.total_limit?.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    const formattedUsedAmount = (
      opdState?.used_amount - processingAmountValue
    )?.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    const formattedAvailableAmount = opdState?.available_amount?.toLocaleString(
      "en-IN",
      {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }
    );
    return (
      <>
        <Box style={{ borderBottom: '1px solid #F38B44' }} className={classes.header}>
          <Typography style={{ fontWeight: 'bold' }} className={classes.rubikBold}>
            OPD Transactions
          </Typography>
          <CloseIcon
            className={classes.closeIcon}
            onClick={() => {
              this.props.resetOpdTransactions();
              closeOpdDrawer();
            }}
          />
        </Box>
        <Box
          style={{
            paddingLeft: "24px",
            paddingRight: "24px",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              border: "1px solid #CCC",
              borderRadius: "12px",
              padding: "12px",
              fontFamily: "Rubik",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography>Total Limit</Typography>
              <Typography>{formattedTotalOPD}</Typography>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "0px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography>Used Amount</Typography>
              <Typography>
                -{formattedUsedAmount ? formattedUsedAmount : "-"}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingBottom: "10px",
                borderBottom: "1px dashed gray",
              }}
            >
              <Typography>Payment Processing</Typography>
              <Typography>
                -
                {processingAmountValue
                  ? processingAmountValue?.toLocaleString("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    })
                  : "-"}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography style={{ color: "#F38B44", marginTop: "12px" }}>
                Available OPD Amount
              </Typography>
              <Typography>{formattedAvailableAmount}</Typography>
            </div>
          </div>
          <div>
            {opdState ? (
              <Box style={{ marginBottom: '40px' }}>
                <Typography
                  className="rubik-bold"
                  style={{ marginTop: "20px", fontWeight: "bold", paddingLeft: '12px', paddingRight: '12px' }}
                >
                  All Transactions
                </Typography>
                {opdState?.transaction?.map((transaction, index) => {
                  return (
                    <Box key={index} style={{ marginTop: "10px", paddingLeft: '12px', paddingRight: '12px' }}>
                      <div
                        style={{
                          display: "flex",
                          fontFamily: "rubik",
                          alignItems: "center",
                          paddingBottom: "10px",
                          borderBottom: "1px dashed #CCCCCC",
                        }}
                      >
                        <Typography>
                          {moment(transaction?.transaction_date)?.format(
                            "DD MMM YYYY"
                          )}
                        </Typography>
                        <Typography
                          style={this.getStyleForTransactions(transaction)}
                        >
                          {this.getOpdStatus(transaction)}
                        </Typography>
                        <Typography
                          style={{ position: "absolute", right: "35px" }}
                        >
                          {transaction?.transaction_type === 'credit' ? '+' : '-'}
                          {transaction?.amount?.toLocaleString("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                          })}
                        </Typography>
                      </div>
                    </Box>
                  );
                })}
              </Box>
            ) : (
              ""
            )}
          </div>
          {opdState?.transaction?.length !==
          familyDetails?.opdTransactions?.data?.total_count ? (
            <div style={{ textAlign: 'center' }}>
            <Button onClick={() => this.fetchMoreData(subscription)} style={{ marginBottom: '70px' }}>Load More</Button>
            </div>
          ) : (
            ""
          )}
          <Button
            variant="outlined"
            onClick={() => closeOpdDrawer()}
            style={{
              borderColor: "orange",
              color: "white",
              backgroundColor: "#F38B44",
              position: "fixed",
              bottom: "0px",
              width: "46vh",
              textTransform: "capitalize",
              marginRight: "24px",
              marginBottom: "1vh",
              marginTop: "2vh",
            }}
          >
            Dismiss
          </Button>
        </Box>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  opdTransactions: (subscriptionId, page) => {
    dispatch(getOpdTransactionsEvent(subscriptionId, page));
  },
  resetOpdTransactions: () => {
    dispatch(resetOpdTransactions());
  },
});

const mapStateToProps = (state) => {
  const { newPlan, auth, familyDetails } = state;
  return {
    newPlan,
    auth,
    familyDetails,
  };
};
const ConnectedOpdDrawer = withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OpdDrawer))
);
export default ConnectedOpdDrawer;
