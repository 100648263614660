import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box, Typography, Button, ClickAwayListener } from "@material-ui/core";
import styles from "./style";
import { updateTNC } from '../../../actions';
import { tncStatus } from "../../../common/constants";

class GhdApprovalDrawer extends React.Component {
  constructor () {
    super();
    this.state = {
      renderMain: true,
      summary: '',
    }
  }

  componentDidMount() {
    const { confirmationText } = this.props;
    this.setState({
      confirmationText
    })
  }

  handleSummaryOnChange = (event) => {
    const summary = event.target.value;
        this.setState({
            summary: summary,
        });
   }

  renderMain() {
    const {
      classes, title, selectedSubscription,
      updateTNCEvent, familyDetails, handleDrawerClose
    } = this.props;
    const { summary } = this.state;
    const familyId = familyDetails.currentCustomer.family.id;
    return (
      <Box className={classes.contentContainer}>
        <Box className={classes.ghdQuestionsContainer}>
          <Typography className={classes.confirmationTitle}>{title}</Typography>
        </Box>
        <textarea
            className={classes.summary}
            value={summary}
            placeholder='Eg: Diabetes, High blood pressure...'
            onChange={this.handleSummaryOnChange}
		/>
        <Box className={classes.buttonContainer}>
          <Button
            style={{
              width: "7vw",
              backgroundColor: "#f38b44",
              padding: '3px 7px 3px 7px',
              textTransform: "capitalize",
            }}
            variant="contained"
            color="primary"
            onClick={() => {
              summary === '' ? updateTNCEvent(selectedSubscription.id, familyId, tncStatus.SUCCESS)
              : updateTNCEvent(selectedSubscription.id, familyId, tncStatus.SUCCESS, summary)
              setTimeout(() => {
                handleDrawerClose()
              }, 1000)
            }} //changed boolean `false` to string 'failed'
          >
            Submit
          </Button>
        </Box>
        </Box>
    );
  }

  render() {
    const { classes, heading, handleDrawerClose, newPlan } = this.props;
    const { renderMain } = this.state;
    return (
      <ClickAwayListener onClickAway={() => handleDrawerClose()}>
        <Box className={`${classes.confirmationContainer}`}>
          <Box className={classes.headerContainer}>
            <Typography className={classes.preferenceCardHeading}>
              {heading}
            </Typography>
            <CloseIcon onClick={() => handleDrawerClose()} style={{ color: '#172A3A', cursor: 'pointer' }}/>
          </Box>
          {renderMain && !newPlan.customerByPhoneNumber.cancelSubcriptionPlan && this.renderMain()}
        </Box>
      </ClickAwayListener>

    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateTNCEvent: (subscriptionId, familyId, tncStatus, comments) => {
    dispatch(updateTNC(subscriptionId, familyId, tncStatus, comments));
  }
});

const mapStateToProps = (state) => {
  const { newPlan, auth, familyDetails } = state;
  return {
      newPlan,
      auth,
      familyDetails,
  };
};
const ConnectedGhdApprovalDrawer = withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GhdApprovalDrawer))
);
export default ConnectedGhdApprovalDrawer;
