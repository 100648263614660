import React from 'react';
import { Modal, Button } from '@material-ui/core';
import css from './style.module.scss';
import { ReactComponent as DeleteConfirmation} from '../../../assets/icons/delete-confirmation.svg'

function ConfirmationModal(props) {
    const { handelConfirmation, open } = props;
    return (
        <Modal
        open
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        style={{ visibility: `${open ? 'visible' : 'hidden'}`, opacity: `${open ? 1 : 0}`, transition: 'all .3s ease-in'}}
      >
        <div className={css.modal_container}>
          <div className={css.modal_container_header}>
            <DeleteConfirmation className={css.modal_container_delete_icon} />
            <p className={css.modal_container_heading}>
            Are you sure that you want to <span className={css.bold}> Delete Certificate of Issuance. </span>
            </p>
          </div>    
          
          <div className={css.modal_container_footer}>
              <Button onClick={() => handelConfirmation('yes')} className={css.yes_button} variant="outlined"> Yes </Button>
              <Button onClick={() => handelConfirmation('no')}  className={css.no_button}> No </Button>
          </div>
          
        </div>
      </Modal>
    );
};

export default ConfirmationModal;