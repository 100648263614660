import React from "react";
import { connect } from 'react-redux';

import { Paper, Typography, Drawer, Button, Tooltip } from '@material-ui/core';
import { withStyles } from "@material-ui/styles";

import MiddleTab from './MiddleTab';

import MemberTabDetail from './Tab3/MemberTabDetail';

import { fetchBenefitCardsByCustomerId, logOut, getAllMembersByFamilyId, resetNewMemberAdded, resetConsultationPageNumber } from '../../../actions';
import LoginPage from '../../../Pages/LoginPage';

import AddMemberForm from './AddMemberForm';
import { color } from "../../../constants/colors";
import { capitalize, isEmpty, upperCase } from "lodash";
import moment from "moment";
import { getCalendaredString2 } from "../../../common/utils";
import Info from "../../../assets/icons/info";
import membersStyles from "./MembersStyles";

const { errors } = require('../../../common/errors');
class Members extends React.Component {


    constructor() {
        super();
        this.state = {
            memberIndex: 0,
            addMemberDrawer: false,
            editHistoryDrawer: false,
        };
      }

    toggleDrawer = (drawerKey, open) => () => {
        this.setState({
            [drawerKey]: open,
        });
    };

    componentDidMount() {
        this.props.getAllMembersByFamilyId(this.props.familyDetails.currentCustomer.family.id);
    };

    componentWillReceiveProps(nextProps) {
        // this is to close the drawer once the new member is added
        this.setState(nextProps);
        if (this.props.familyDetails && this.props.familyDetails.newMember && this.props.familyDetails.newMember.status === 201) {
            this.setState({ addMemberDrawer: false });
            this.props.resetNewMemberAdded();
        }
    }

    changeMember = (index) => {
        this.props.resetConsultationPageNumber();
        setTimeout(function () {
            this.setState({ memberIndex: index }); 
          }.bind(this), 200);   
    };

    renderEditHistoryDrawer = () => {
        const { memberIndex } = this.state;
        const { classes, familyDetails } = this.props;
        const membersList = familyDetails.allMembers?.members ?? [];
        let member = !isEmpty(membersList) ? membersList[memberIndex] : null;
        let memberUpdateHistory = null;
        if (member.metadata) {
            memberUpdateHistory = Object.keys(member.metadata)
                .filter(metadataKey => metadataKey.match('update_request')
                    && member.metadata[metadataKey].hasOwnProperty('updates'))
                .map(updateKey => member.metadata[updateKey]);
        }
        if (member !== null) {
            return (
                <div className={classes.editHistoryContainer}>
                    <div className={classes.editHistoryHeader}>
                        <Typography className={classes.headerText}>
                            Edit History
                        </Typography>
                    </div>
                    <hr className={classes.headerHr}/>
                    <div className={classes.editHistoryBody}>
                        {
                            memberUpdateHistory && (
                                memberUpdateHistory.map(update => {
                                    const formattedDate = getCalendaredString2(update.requested_at);
                                    const updaterName = update.requested_by.first_name;
                                    const updatedProperties = Object.keys(update.updates);
                                    const formatPropertyName = (property) => {
                                        if (property === null) return '';
                                        if (property.length <= 3) return upperCase(property);
                                        return property.split('_').map(token => capitalize(token)).join(' ');
                                    };
                                    const isDate = (property) => moment(property).isValid();
                                    const formatDate = (property) => moment(property).format('DD/MM/YY');
                                    const formatPropertyValue = (value) => {
                                        if (isDate(value)) return formatDate(value);
                                        return formatPropertyName(value);
                                    }
                                    return (<div className={classes.updateEntryContainer}>
                                    <div
                                        className={classes.updateEntryHeader}
                                    >
                                        <p>{formattedDate}</p>
                                        <strong><p className={classes.memberId}>Customer ID: {member.id}</p></strong>
                                    </div>
                                    <div className={classes.updateEntryBody}>
                                        <strong className={classes.overrideType}>
                                            <p className={classes.overrideTypeLabel}>EDIT TYPE: {update.sheet_override ? 'SHEET OVERRIDE' : 'MANAGER OVERRIDE'}</p>
                                            &nbsp;&nbsp;
                                            <Tooltip
                                                title={<div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between'
                                                        }}
                                                    >
                                                    <Info color={color.WHITE} style={{
                                                        height: '15px',
                                                        width: '15px',
                                                        paddingRight: '0.5vw',
                                                    }}/>
                                                        <p style={{
                                                            display: 'inline-block',
                                                            margin: '0px',
                                                            width: '90%'
                                                        }}>
                                                        {
                                                            update.sheet_override === true
                                                            ? 'Data was overridden when the Sheet was uploaded by a manager for endorsements'
                                                            : 'Edits made by a manager manually using ‘Edit Details’ button'
                                                        }
                                                        </p>
                                                    </div>}
                                                placement="bottom"
                                                classes={{
                                                    tooltip: classes.tooltip,
                                                    arrow: classes.arrow,
                                                }}
                                                arrow
                                            >
                                                <div>
                                                    <Info color={color.ROYAL_ORANGE}/>
                                                </div>
                                            </Tooltip>
                                        </strong>
                                    </div>
                                    <ul style={{
                                        padding: '0px 2vh',
                                        margin: '0px',
                                    }}>
                                    {
                                        updatedProperties.map(property => {
                                            return (<li style={{paddingTop: '1vh', float: 'left', color: color.greys[100], fontSize: '12px', lineHeight: '20px', fontFamily: 'Rubik'}}>{updaterName} edited {formatPropertyName(property)}&nbsp;
                                            "{formatPropertyValue(update.updates[property].old_member_details)}"&nbsp;to
                                            "{formatPropertyValue(update.updates[property].updated_member_details)}"&nbsp;
                                            </li>)
                                        })
                                    }
                                    </ul>
                                    </div>)
                                })
                            )
                        }
                    </div>
                </div>
            )
        }
    }

    render() {
        const { memberIndex, editHistoryDrawer } = this.state;
        const { familyDetails, classes } = this.props;
        const membersList = familyDetails?.allMembers.members ??  [];
        let member = !isEmpty(membersList) ? membersList[memberIndex] : null;
        let memberUpdateHistory = [];
        if (member.metadata) {
            memberUpdateHistory = Object.keys(member.metadata)
                .filter(metadataKey => metadataKey.match('update_request')
                    && member.metadata[metadataKey].hasOwnProperty('updates'))
                .map(updateKey => member.metadata[updateKey]);
        }
        if (this.props.error && this.props.error.benefitCardErrorCode === errors.FORBIDDEN) {
            this.props.logOut();
            return (
                <LoginPage />
            )
        } else {
            return (
                <div
                    className='main'
                    style={{
                        marginTop: '0.25vh',
                        display: 'flex',
                        flexFlow: 'row', height: '73vh', width: '100%', position : 'relative'
                    }}
                >
                    <div style={{ overflowY: 'scroll', overflowX: 'scroll', borderTop: '0.5px solid #f4f4f4', backgroundColor: "white", width: "25%", position: "relative" }}>
                        {membersList.map((member, index) => {
                            let buttonColor;
                            if (index === this.state.memberIndex) {
                                buttonColor = '#f4f4f4';
                            } else {
                                buttonColor = 'white';
                            }

                            return <Button
                                style={{
                                    display: 'inline-block',
                                    textAlign: 'left',
                                    backgroundColor: buttonColor,
                                    height: 'max-content',
                                    cursor: 'pointer',
                                    textTransform: 'capitalize',
                                    width: '100%',
                                    padding: '1.5vh 0px'
                                }}
                                key={index}
                                onClick={() => this.changeMember(index)}>
                                {index === this.state.memberIndex ? <Typography style={{ fontWeight: 'bold', fontSize: '15px', marginLeft: '3vh', fontFamily: 'Rubik'}}>{member.first_name}</Typography> : <Typography style={{ fontSize: '15px', marginLeft: '3vh', fontFamily: 'Rubik'}}>{member.first_name}</Typography>}
                                {index === this.state.memberIndex ? <Typography style={{ fontSize: '12px', marginLeft: '3vh'}}><span style={{ color: 'grey', fontFamily: 'Rubik'}}>User ID: </span>{member.id}</Typography> : <Typography style={{ fontSize: '12px', marginLeft: '3vh', fontFamily: 'Rubik'}}><span style={{ color: 'grey' }}>User ID: </span>{member.id}</Typography>}
                            </Button>
                        })}
                        <div style={{
                            justifyContent: 'flex-end',
                            flexDirection: 'column',
                            // height: '80%',
                            marginBottom: '10vh',
                            display: 'flex'
                        }}>

                            <div style={{
                                justifyContent: 'flex-end',
                                display: 'flex'
                            }}>
                                {(this.props.familyDetails.allMembers && this.props.familyDetails.allMembers.total >= 6) ? <Typography style={{ position: 'absolute', fontSize: '1.5vh', color: 'red', textTransform: 'capitalize', fontFamily: 'Rubik-regular', left: '25%', right: '25%', bottom: '60px', width: '50%' }}> Maximum 6 members</Typography> : <></>}
                                <Button style={{
                                    backgroundColor: (this.props.familyDetails.allMembers && this.props.familyDetails.allMembers.total >= 6) ? 'grey' : 'orange',
                                    left: '15%', right: '15%', width: '70%', position: 'absolute', bottom: '20px'
                                }}
                                    disabled={(this.props.familyDetails.allMembers && this.props.familyDetails.allMembers.total >= 6)}
                                    onClick={this.toggleDrawer('addMemberDrawer', true)}
                                >
                                    <Typography style={{
                                        fontSize: '13px',
                                        color: 'white',
                                        textTransform: 'capitalize'
                                    }}> + Add member</Typography>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div style={{ border: '0.5px solid #f4f4f4', borderBottom: 'none', backgroundColor: "white", width: "30%", textAlign: 'center', position: 'relative' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'baseline'
                            }}
                        >
                        <Typography
                            style={{
                                textAlign: 'left',
                                fontWeight: 'bold',
                                fontSize: '18px',
                                padding: '30px 24px 0',
                                color: 'black',
                                fontFamily: 'Rubik'
                            }}
                        >
                            Member Details
                        </Typography>
                        <Button
                            className={classes.showEditsCta}
                            disabled={!Boolean(member.metadata)
                                || (memberUpdateHistory.length === 0)}
                            onClick={this.toggleDrawer('editHistoryDrawer', true)}
                            disableRipple
                        >
                            Show Edits
                        </Button>
                        </div>
                        <MiddleTab member={membersList[this.state.memberIndex]} />
                    </div>
                    <MemberTabDetail customerId={membersList[this.state.memberIndex].id} customerName={membersList[this.state.memberIndex].first_name} />
                    <Drawer anchor="right" open={this.state.addMemberDrawer} onClose={this.toggleDrawer('addMemberDrawer', false)}>
                        <Paper style={{ width: '50vh', height: '100%' }}>
                            <br />
                            <AddMemberForm />
                        </Paper>
                    </Drawer>
                    <Drawer
                        anchor="right"
                        open={editHistoryDrawer}
                        onClose={this.toggleDrawer('editHistoryDrawer', false)}
                    >
                        {this.renderEditHistoryDrawer()}
                    </Drawer>
                </div>
            );
        }
    }

};

const mapStateToProps = (state) => {
    return { auth: state.auth, familyDetails: state.familyDetails, error: state.error };
};

const mapDispatchToProps = {
    fetchBenefitCardsByCustomerId,
    logOut,
    getAllMembersByFamilyId,
    resetNewMemberAdded,
    resetConsultationPageNumber
};

const reduxWrapper = connect(mapStateToProps, mapDispatchToProps);
const styleWrapper = withStyles(membersStyles, { withTheme: true });

export default reduxWrapper(styleWrapper(Members));