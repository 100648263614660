import { round } from "lodash";
import { tncStatus as ghdStatus } from "./constants";

const {
  StatusCodes: {
    UNAUTHORIZED,
    INTERNAL_SERVER_ERROR,
    BAD_GATEWAY,
    BAD_REQUEST,
    UNPROCESSABLE_ENTITY,
    FORBIDDEN,
  },
} = require("http-status-codes");
const _ = require("lodash");
const moment = require("moment-timezone");

const {
  accessRole,
  paymentMultiplierQuantity,
  discountType,
  paymentMultiplier,
} = require("./constants");

const getISTFormat1 = (utcTimeStamp) =>
  !_.isNil(utcTimeStamp)
    ? moment(utcTimeStamp)
        .tz("Asia/Kolkata")
        .format("DD/MM/YYYY")
    : undefined;

const getISTFormat2 = (utcTimeStamp) =>
  !_.isNil(utcTimeStamp)
    ? moment(utcTimeStamp)
        .tz("Asia/Kolkata")
        .format("Do MMM YYYY")
    : undefined;

// eslint-disable-next-line no-useless-concat
const getISTFormat3 = (utcTimeStamp) =>
  !_.isNil(utcTimeStamp)
    ? moment(utcTimeStamp)
        .tz("Asia/Kolkata")
        .format('DD MMM YY')
    : undefined;

const getISTFormat4 = (utcTimeStamp) =>
  !_.isNil(utcTimeStamp)
    ? moment(utcTimeStamp)
        .tz("Asia/Kolkata")
        .format("DD MMM YYYY")
    : undefined;

const getISTFormat5 = (utcTimeStamp) =>
  !_.isNil(utcTimeStamp)
    ? moment(utcTimeStamp)
        .tz("Asia/Kolkata")
        .format("YYYY-MM-DD")
    : undefined;

const getISTFormat6 = (utcTimeStamp) =>
  !_.isNil(utcTimeStamp)
    ? moment(utcTimeStamp)
        .tz("Asia/Kolkata")
        .format("hh:mm a • Do MMM")
    : undefined;

const getISTTimeFormat = (utcTimeStamp) =>
  !_.isNil(utcTimeStamp)
    ? moment(utcTimeStamp)
        .tz("Asia/Kolkata")
        .format("DD MMM YYYY | hh:mm a")
    : undefined;

const getISTFormat7 = (utcTimeStamp) =>
  !_.isNil(utcTimeStamp)
    ? moment(utcTimeStamp)
        .tz("Asia/Kolkata")
        .format("Do MMM YYYY • hh:mm A ")
    : undefined;

const getISTFormat8 = (utcTimeStamp) =>
  !_.isNil(utcTimeStamp)
    ? moment(utcTimeStamp)
        .tz("Asia/Kolkata")
        .format("DD MMM")
    : undefined;

const formatNumberAsIndianCurrency = (number) => {
    const Rupee = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        maximumFractionDigits: 0,
    });
    return Rupee.format(number);
}

const emailRegex = String.raw`^[\w.+\-]+@clinikk\.com$`;

const getCalendaredString = (utcTimeStamp) =>
  !_.isNil(utcTimeStamp)
    ? moment(utcTimeStamp)
        .tz("Asia/Kolkata")
        .calendar({
          sameDay: function(now) {
            if (this.isBefore(now)) {
              if (moment(this).diff(now, "hours") === 0) {
                return `${Math.abs(moment(this).diff(now, "minutes"))} [minute${
                  Math.abs(moment(this).diff(now, "minutes")) >= 0 ? "s" : ""
                } ago]`;
              } else
                return `${Math.abs(moment(this).diff(now, "hours"))} [hour${
                  Math.abs(moment(this).diff(now, "hours")) > 1 ? "s" : ""
                } ago]`;
            }
          },
          lastDay: "DD MMM",
          lastWeek: "DD MMM",
          sameElse: "DD MMM",
        })
    : undefined;

const getCalendaredString2 = (utcTimeStamp) =>
  !_.isNil(utcTimeStamp)
    ? moment(utcTimeStamp)
        .tz("Asia/Kolkata")
        .calendar({
          sameDay: `[Today •] hh:mm A`,
          lastDay: "DD/MM/YY, hh:mm A",
          lastWeek: "DD/MM/YY, hh:mm A",
          sameElse: "DD/MM/YY, hh:mm A",
        })
    : undefined;

const getTimeStampFromDate = (date) => (!_.isNil(date) ? moment(date).format("x") : undefined);

const getQueryParams = () => new URLSearchParams(window.location.search);

const checkIfHA = (roles) => {
  let roleNames = [];
  _.forEach(roles, (role) => roleNames.push(role.name));
  return roleNames.includes(accessRole.HA);
};

const checkIfManager = (roles) => {
  let roleNames = [];
  _.forEach(roles, (role) => roleNames.push(role.name));
  return (
    roleNames.includes(accessRole.OPS_MANAGER) || roleNames.includes(accessRole.HEALTH_MANAGER)
  );
};

const getAvailableServicesInProduct = (product) => {
  const availableServices = _.pick(product, [
    "primary_care",
    "health_insurance",
    "accidental_insurance",
    "hospicash",
  ]);
  const availableServicesList = Object.keys(availableServices).map((key) => {
    return { type: key, value: availableServices[key] };
  });
  return availableServicesList;
};

const getSubscriptionProductName = (subscription) => {
  if (!subscription.product) return "-";
  if (subscription.version === 0) return subscription.product.name;
  const memberConstraint = subscription.product.constraint.allowed_members;
  return subscription.product.display_name + ` - ${memberConstraint.adult}A${memberConstraint.child ? `+ ${memberConstraint.child}C` : ''}`;
};

const getMultiplierQuantity = (multiplier) => {
  let multiplierQuantity = paymentMultiplierQuantity.MONTHLY;
  if (multiplier === paymentMultiplier.QUARTERLY) {
    multiplierQuantity = paymentMultiplierQuantity.QUARTERLY;
  } else if (multiplier === paymentMultiplier.SEMI_ANNUALLY) {
    multiplierQuantity = paymentMultiplierQuantity.SEMI_ANNUALLY;
  } else if (multiplier === paymentMultiplier.ANNUALLY) {
    multiplierQuantity = paymentMultiplierQuantity.ANNUALLY;
  }
  return multiplierQuantity;
};

const getProductPriceAfterDiscount = (product, multiplier) => {
  const multiplierQuantity = getMultiplierQuantity(multiplier);
  let discountAmount = 0;
  let productPriceBeforeDiscount = product.price * multiplierQuantity;
  if (!_.isEmpty(product.discount)) {
    if (product.discount[multiplier].type === discountType.FLAT) {
      discountAmount = product.discount[multiplier].value;
    } else if (product.discount[multiplier].type === discountType.PERCENTAGE) {
      discountAmount = (product.discount[multiplier].value / 100) * productPriceBeforeDiscount;
    } else {
      discountAmount = 0;
    }
  }
  return productPriceBeforeDiscount - discountAmount;
};

const getDiscountOnLineItem = (item) => {
  if (item.rate === null) {
    return 0;
  }
  if (!item.discount) {
    return 0;
  }
  if (item.discount) {
    const { type, value } = item.discount;
    if (type === "flat") {
      return Number(value);
    }
    if (type === "percentage") {
      if (!item.rate) return 0;
      else {
        return Number((value / 100) * item.rate * item.quantity);
      }
    }
  }
};

const getDiscountOnUpdatedLineItem = (item) => {
  if (item.price === null) {
    return 0;
  }
  if (!item.total_discount) {
    return 0;
  }
  if (item.total_discount) {
    const { total_discount, total_discount_type } = item;
    if (total_discount_type === "flat") {
      return Number(total_discount);
    }
    if (total_discount_type === "percentage") {
      if (!item.price) return 0;
      else {
        return Number((total_discount / 100) * item.price * item.quantity);
      }
    }
  }
};


/**
 *
 * @param {*} items Array of items for which the total is to be returned
 * @returns {Number} Simple sum of all billable line items (including discounts);
 */
const getOrderTotal = (items) => {
  if (items) {
    const orderTotal = items.reduce((orderTotal, lineItem) => {
      if (lineItem.rate !== null) {
        return Number(orderTotal + lineItem.amount);
      } else {
        return Number(orderTotal + 0);
      }
    }, 0);
    return round(orderTotal, 2);
  }
  return 0;
};

const getTotalOpd = (items) => {
  if (items) {
    const opdItems = items.filter((item) => item.opd_utilised === true)
    const orderTotal = opdItems.reduce((orderTotal, lineItem) => {
      if (lineItem.rate !== null) {
        return Number(orderTotal + lineItem.amount);
      } else {
        return Number(orderTotal + 0);
      }
    }, 0);
    return round(orderTotal, 2);
  }
    return 0;
  }

  const getTotalOpdAmount = (items) => {
    if (items) {
      const opdItems = items.filter((item) => item.opd_utilised === true)
      const orderTotal = opdItems.reduce((orderTotal, lineItem) => {
        if (lineItem.rate !== null) {
          return Number((orderTotal + lineItem.total) - lineItem.amount);
        } else {
          return Number(orderTotal + 0);
        }
      }, 0);
      return round(orderTotal, 2);
    }
      return 0;
    }

/**
 * @returns {Number} The amount that can be charged to Clinikk Cash,
 * either due to coupon applied or due to available credit.
 */
const getAvailableCreditAmount = (
  creditStatus,
  couponStatus,
  billableItems,
  couponDetails,
  creditDetails
) => {
  const totalAvailableCredit = Number(creditDetails.amount);
  if (creditStatus.applied) {
    // iterate over every item in the bill and compute the clinikk cash that can be
    // applied against it;
    if (totalAvailableCredit === 0) return 0;
    else {
      return Math.min(totalAvailableCredit, getOrderTotal(billableItems));
    }
  } else if (couponStatus.applied) {
    // once validated, return the discount value that the coupon enables;
    // TODO: Check for `applicable_to = line_items`
    if (!couponDetails.isLoading && couponDetails.valid) {
      return couponDetails.order.total_discount_amount;
    } else return 0;
  }
  return 0;
};

/**
 * Other utilities
 */

/**
 *
 * @param {*} e The error object returned by Axios in case of errors with API calls
 * @returns __error__: an object with `message` and `statusCode` properties.
 * @throws Error `"Argument is not an object!"` in case the passed param `e` is not `typeof "object"`
 *
 * This function accepts an Axios error object and returns a formatted object with required data.
 * Error messaages for some common responses are already handled - for other contextual errors like
 * `404`, etc. can be handled by setting `errorObj.message` as required.
 *
 * TODO: streamline error object structure and find a way to make the error message more
 * user-fiendly + informative. This will make debugging errors easier.
 */

const buildCustomError = (e) => {
  if (typeof e !== "object") {
    throw Error("Argument is not an object!");
  }
  const errorMessage = e.message ?? "Something went wrong.";
  const statusCode = e.response ? e.response.status : undefined;
  const errorData = e.response ? e.response.data : undefined;
  const obj = { message: errorMessage, statusCode, data: errorData };
  const error = _.pickBy(obj, _.identity);
  if (e.code === "ERR_NETWORK") {
    error.message = "There was a problem getting this request to our system. Please try again!";
    error.statusCode = "ERR_NETWORK";
  }
  if (e.code === "ECONNABORTED") {
    // timeout
    error.message = "Request timed out, please try again in some time!";
    error.statusCode = "ECONNABORTED";
  }
  if ([UNAUTHORIZED, FORBIDDEN].includes(statusCode)) {
    error.message = "Unauthorised action. Please log in with the right credentials and try again!";
  }
  if (statusCode === BAD_REQUEST) {
    error.message = "There's a problem with this request. Please report this:";
  }
  if (statusCode === UNPROCESSABLE_ENTITY) {
    error.message = "Unprocessabe entity; the requested action failed. Please report this:";
  }
  if ([INTERNAL_SERVER_ERROR, BAD_GATEWAY].includes(statusCode)) {
    error.message = "Something went wrong. Please wait for some time and try again!";
  }
  // append the actual error message to the end of the standard error message.
  if (e?.response?.data?.message) {
    error.message += ` ${e.response.data.message}`;
  }
  return error;
};

/**
 * ### This function returns the GHD Status of a given Subscription formatted properly.
 *
 * @param {Object} subscription The Subscription for which we
 * need the GHD Status, which is derived from the
 * `subscription.tncStatus` property.
 */
const getFormattedGhdStatusOfSubscription = (subscription) => {
  if (subscription) {
    const { tncStatus = null } = subscription;
    switch (tncStatus) {
      case ghdStatus.PENDING: {
        return "Pending";
      }
      case ghdStatus.IN_REVIEW: {
        return "In Review";
      }
      case ghdStatus.SUCCESS: {
        return "Success";
      }
      case ghdStatus.FAILED: {
        return "Failed";
      }
      default: {
        return "Pending";
      }
    }
  }
  return null;
};

/**
 * 
 * @param {string} stringToFormat - `a_string_like_this` to format. 
 * @returns a split and capitalised string, `A String Like This`.
 */
const splitAndCapitaliseString = (stringToFormat) => {
  return stringToFormat
    .split("_")
    .map((token) => String(token.charAt(0)).toUpperCase() + String(token).substring(1))
    .join(" ");
};

const getAgeUnit = (unit) => {
        switch(unit) {
            case 'days':
                return 'd';
            case 'months':
            case 'month':
                return 'm';
            default: 
                return 'y';
        }
    }

export {
  getISTFormat1,
  getISTFormat2,
  getISTFormat3,
  getISTFormat4,
  getISTFormat5,
  getISTFormat6,
  getISTFormat7,
  getISTFormat8,
  getCalendaredString,
  getCalendaredString2,
  checkIfHA,
  checkIfManager,
  getISTTimeFormat,
  getTimeStampFromDate,
  getAvailableServicesInProduct,
  getMultiplierQuantity,
  getProductPriceAfterDiscount,
  getQueryParams,
  getDiscountOnLineItem,
  getOrderTotal,
  getAvailableCreditAmount,
  buildCustomError,
  getTotalOpdAmount,
  emailRegex,
  getTotalOpd,
  getFormattedGhdStatusOfSubscription,
  splitAndCapitaliseString,
  formatNumberAsIndianCurrency,
  getSubscriptionProductName,
  getAgeUnit,
  getDiscountOnUpdatedLineItem,
};
