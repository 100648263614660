import { color } from "../../../constants/colors";

const useStyles = (theme) => ({
    filterContainer: {
        width: 'calc(100% - 4vh)',
        fontFamily: 'Rubik',
    },
    container: {
        width: 'calc(100% - 4vh)',
        display: 'flex',
        background: color.WHITE,
        borderRadius: '4px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '70vh',
        marginTop: '1vh',
        padding: '2vh',
    },
    filterRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: color.ANTI_FLASH_WHITE,
        border: `1px solid ${color.LIGHT_GREY}`,
        borderRadius: '8px',
        width: 'calc(100% - 4vh)',
        padding: '2vh',
        marginTop: '2vh',
    },
    itemSet: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        justifyContent: 'space-between',
    },
    item: {
        '&:first-child': {
            paddingRight: '2.5vh',
            borderRight: `1px solid ${color.GREY}`,
        },
        padding: '0px 2.5vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
    },
    filterInput: {
        fontFamily: 'Rubik',
    },
    searchBtn: {
        border: `1px solid ${color.ROYAL_ORANGE}`,
        textTransform: 'capitalize',
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        background: color.WHITE,
        color: color.ROYAL_ORANGE,
        '&:hover': {
            background: color.WHITE,
        }
    },
    historyContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: 'calc(100% - 4vh)',
        marginTop: '2vh',
    },
    header: {
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        fontSize: '18px',
    },
    billingHistoryList: {
        width: 'calc(100%)',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '2vh',
        overflowX: 'hidden',
        overflowY: 'scroll',
        height: '45vh',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    billingHistoryItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '8px',
        border: `1px solid ${color.BORDER_GREY}`,
        padding: '2vh 1vh',
        width: '98%',
        marginTop: '2vh',
    },
    itemColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        width: 'calc(40% / 3)',
        '&:first-child': {
            padding: '0px 2vh',
            width: '20%',
        },
        '&:last-child': {
            paddingRight: '2vh',
            width: '20%',
            alignItems: 'flex-end'
        },
        '&:not(:last-child)': {
            borderRight: `1px solid ${color.DARK_GREY}`,
        },
    },
    columnHeader: {
        fontFamily: 'Rubik',
        fontSize: '13px',
        color: color.LIGHT_GREY_TEXT,
    },
    columnValue: {
        fontFamily: 'Rubik',
        fontSize: '14px',
        color: color.GREY,
    },
    viewReceiptButton: {
        background: color.ROYAL_ORANGE,
        color: color.WHITE,
        textTransform: 'capitalize',
        fontFamily: 'Rubik',
        '&:hover': {
            background: color.ROYAL_ORANGE,
        },
        borderRadius: '4px',
    },
    loadMoreCta: {
        background: color.ROYAL_ORANGE,
        color: color.WHITE,
        textTransform: 'capitalize',
        textSize: '8px',
        fontFamily: 'Rubik',
        '&:hover': {
            background: color.ROYAL_ORANGE,
        },
        '&:disabled': {
            background: color.LIGHT_GREY,
        },
        borderRadius: '4px',
        marginTop: '2vh',
    },
    placeholder: {
        fontFamily: 'Rubik',
        color: color.LIGHT_GREY_TEXT,
    }
});

export default useStyles;