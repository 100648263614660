import React from 'react';
import _ from 'lodash';

import { connect } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import history from '../../history';
import { Divider, Typography, Button, Paper, Drawer, Checkbox, Radio, RadioGroup, withStyles } from '@material-ui/core';
import 'date-fns';
import { color } from '../../constants/colors';
import { offlinePaymentSources, paymentSources } from '../../../src/common/constants';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { orange } from "@material-ui/core/colors";
import { ReactComponent as CloseButtonXActive } from '../../images/CloseButtonXActive.svg';
import { ReactComponent as RoundGreenTick } from '../../images/RoundGreenTick.svg';
import { ReactComponent as RoundRupeeRed } from '../../images/RoundRupeeRed.svg';
import { ReactComponent as BookingConfirmed } from '../../images/BookingConfirmed.svg';
import { getCalendaredString, getAgeUnit } from '../../common/utils';
import config from '../../config';
import { accessRole } from '../../common/constants';
import useStyles from './style';

import {
    resetConsultationById,
    resetAddVitalsStatus,
    getConsultationsByClinicId,
    setPresentableConsultationQueue,
    getConsultationsByClinicIdFirstPage,
    resetConsultationQPageNumber,
    checkoutConsultation,
    resetCheckoutConsultationStatus,
    getConsultationOrder,
    resetConsultationOrderDetails,
    resetCreditDetails,
    getCreditDetailsByFamilyId,
    createOfflinePayment,
    resetOfflinePaymentStatus,
    getConsultationById,
    consultationActions,
} from '../../actions';
import { errors } from '../../common/errors';
import { clientRoute } from '../../constants';

const materialTheme = createMuiTheme({
    palette: {
        primary: orange,
    },
});

class ParticularCompletedC extends React.Component {

    constructor() {
        super();
        this.state = {
            anchorEl: null,
            drawer: false, // make it back false
            drawerType: 'loading',  //change it back to loading
            mousePonterInside: false,// make it back false
            numberOfCharacters: 40,
            paymentCollectedButtonPressed: false,
            creditOptionSelected: false,
            paymentButtonText: '',
            paymentMethod: '',
        };
    }

    toggleCreditCheck = () => {
        this.setState(prevState => ({
            creditOptionSelected: !prevState.creditOptionSelected
        }));
        if (!this.state.creditOptionSelected && (this.props.consultationDetails && this.props.consultationDetails.consultationOrderDetails && this.props.consultationDetails.creditDetails && (this.props.consultationDetails.creditDetails.amount >= this.props.consultationDetails.consultationOrderDetails.amount))) {
            this.setState({ paymentMethod: '' })
        }
    };

    getTotalAmountToBePaid = () => {
        if (this.props.consultationDetails && this.props.consultationDetails.consultationOrderDetails && this.props.consultationDetails.creditDetails && (this.props.consultationDetails.creditDetails.amount >= this.props.consultationDetails.consultationOrderDetails.amount) && this.state.creditOptionSelected) {
            return 0;
        } else if (this.props.consultationDetails && this.props.consultationDetails.consultationOrderDetails && this.props.consultationDetails.creditDetails && this.state.creditOptionSelected) {
            return (this.props.consultationDetails.consultationOrderDetails.amount - this.props.consultationDetails.creditDetails.amount)
        } else if (this.props.consultationDetails && this.props.consultationDetails.consultationOrderDetails && this.props.consultationDetails.creditDetails && !this.state.creditOptionSelected) {
            return this.props.consultationDetails.consultationOrderDetails.amount;
        } else {
            return '-'
        }
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    openVitalsDrawer = () => {
        this.setState({ vitalsDrawer: true });
    }

    closePaymentsDrawer = () => {
        this.setState({ drawer: false, paymentCollectedButtonPressed: false, creditOptionSelected: false, paymentMethod: '' });
        // setTimeout(function () {
        this.props.resetOfflinePaymentStatus();
        //     )
        // }.bind(this), 100);
        this.props.resetCreditDetails();
        this.props.resetConsultationOrderDetails();
        this.props.resetCheckoutConsultationStatus();
        this.props.resetConsultationQPageNumber();
        this.props.resetConsultationById();
        this.props.resetAddVitalsStatus();
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    createPayment = () => {
        const paymentResponse = this.props.createOfflinePayment(this.props.consultationDetails.consultationOrderDetails.id, this.getPaymentList());
        this.setState({ drawerType: 'offlinePaymentPage' })
        paymentResponse.then(() => {
            const verifyPaymentOnInvoiceStatus = () => {
                if (this.props.consultationDetails && this.props.consultationDetails.offlinePaymentStatus && this.props.consultationDetails.offlinePaymentStatus.payments_group_id) {

                    let getConsultationByIdPromise;
                    getConsultationByIdPromise = this.props.getConsultationById(this.props.consultation.id)


                    getConsultationByIdPromise.then(() => {
                        if (this.props.consultationDetails && this.props.consultationDetails.focussedConsultation && this.props.consultationDetails.focussedConsultation.invoice && this.props.consultationDetails.focussedConsultation.invoice.status === 'paid') {
                            this.setState({ drawerType: 'paymentVerifiedSuccessfully' })
                        }
                        if (this.props.consultationDetails && this.props.consultationDetails.focussedConsultation && this.props.consultationDetails.focussedConsultation.invoice && this.props.consultationDetails.focussedConsultation.invoice.status !== 'paid') {
                            this.setState({ drawerType: 'paymentVerificationFailed' })
                        }
                    })
                }
            }
            setTimeout(() => verifyPaymentOnInvoiceStatus(), 4000);  // To call getConsultationById after a certain time //TODO : Exponential backoff
        })
    }

    openCollectPaymentDrawer = () => {
        this.props.resetConsultationById(); // This is to reset focussedConsultation details
        if (this.props.consultationDetails && this.props.consultationDetails.creditDetails && this.props.consultationDetails.creditDetails.amount === 0) {
            this.setState({ creditOptionSelected: false })
        }
        this.setState({ drawerType: 'loading', drawer: true }) // change it back to loading 
        const checkOutConsultationPromise = this.props.checkoutConsultation(this.props.consultation.id);
        checkOutConsultationPromise.then(() => {
            if (this.props.consultationDetails && this.props.consultationDetails.consultationCheckOutStatus && this.props.consultationDetails.consultationCheckOutStatus.order && this.props.consultationDetails.consultationCheckOutStatus.order.id) {
                const orderDetailsPromise = this.props.getConsultationOrder(this.props.consultationDetails.consultationCheckOutStatus.order.id)

                orderDetailsPromise.then(() => {
                    if (this.props.consultationDetails && this.props.consultationDetails.consultationOrderDetails && this.props.consultationDetails.consultationOrderDetails.family && this.props.consultationDetails.consultationOrderDetails.family.id) {
                        const getCreditsPromise = this.props.getCreditDetailsByFamilyId(this.props.consultationDetails.consultationOrderDetails.family.id);
                        getCreditsPromise.then(() => {
                            //To make credit selected by default if credit.amount >= bill.amount
                            if (this.props.consultationDetails && this.props.consultationDetails.consultationOrderDetails && this.props.consultationDetails.creditDetails && (this.props.consultationDetails.creditDetails.amount > 0)) {
                                this.setState({ creditOptionSelected: true })
                            }

                            if (this.props.consultationDetails && this.props.consultationDetails.creditDetails && (this.props.consultationDetails.creditDetails.amount || this.props.consultationDetails.creditDetails.amount === 0)) {
                                this.setState({ drawerType: 'orderSummaryPage' })
                            }
                        })
                    }
                })
            }
        })
    }

    createAndGetOrder = () => {
        // let checkoutConsultationPromise;
        setTimeout(function () {
            this.props.checkoutConsultation(this.props.consultation.id);
        }.bind(this), 1000);
        // checkoutConsultationPromise.then(() => {
        if (this.props.consultationDetails && this.props.consultationDetails.consultationCheckOutStatus && this.props.consultationDetails.consultationCheckOutStatus.order && this.props.consultationDetails.consultationCheckOutStatus.order.id) {
            this.setState({ drawerType: 'loadingOrderDetails' })
        }
        // })
    }

    clickPaymentCollectedButton = () => {
        this.setState({ paymentCollectedButtonPressed: true })
    }

    tryOfflinePaymentAgain = () => {
        this.setState({ paymentCollectedButtonPressed: false, drawerType: 'orderSummaryPage' })
    }

    getPaymentList = () => {
        let paymentList = [];
        if (this.state.creditOptionSelected) {
            paymentList.push({ source: paymentSources.CREDIT, amount: this.getAmountPaidByCredit() })
        }
        if (this.state.paymentMethod === 'cash') {
            paymentList.push({ source: offlinePaymentSources.CASH, amount: this.getTotalAmountToBePaid() })
        } else if (this.state.paymentMethod === 'card') {
            paymentList.push({ source: offlinePaymentSources.CARD, amount: this.getTotalAmountToBePaid() })
        }
        return { payments: paymentList };
    };

    getAmountPaidByCredit = () => {
        if (this.props.consultationDetails && this.props.consultationDetails.consultationOrderDetails && this.props.consultationDetails.creditDetails && (this.props.consultationDetails.creditDetails.amount >= this.props.consultationDetails.consultationOrderDetails.amount)) {
            return this.props.consultationDetails.consultationOrderDetails.amount;
        } else if (this.props.consultationDetails && this.props.consultationDetails.consultationOrderDetails && this.props.consultationDetails.creditDetails && (this.props.consultationDetails.creditDetails.amount < this.props.consultationDetails.consultationOrderDetails.amount)) {
            return this.props.consultationDetails.creditDetails.amount;
        }
    }

    getTotalAmountFromPaymentObject = payments => {
        if (_.isEmpty(payments)) {
            return 0;
        }
        else return (
            _.reduce(payments,
                (initValue, payment) => initValue + payment.amount, 0)
        );

    }

    renderDrawerContent = () => {
        if (this.state.drawerType === 'loading') {
            if (this.props.consultationDetails.consultationOrderDetails === errors.ERROR_WHILE_FETCHING_ORDER || this.props.error.creditErrorCode === 400 || this.props.consultationDetails.consultationCheckOutStatus === errors.ERROR_IN_CHECKING_OUT_CONSULTATION || this.props.consultationDetails.offlinePaymentStatus === errors.ERROR_IN_CREATING_OFFLINE_PAYMENT || this.props.consultationDetails.focussedConsultation === errors.ERROR_IN_FETCHING_PARTICULAR_CONSULTATION) {
                return (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Typography style={{ color: 'black', fontWeight: 'bold', fontSize: '1.2rem', fontFamily: 'Rubik', marginTop: '1.5rem', marginLeft: '0.8rem', marginBottom: '0.6rem' }}>Payment Collection</Typography>
                            <CloseButtonXActive style={{ marginTop: '1.9rem', marginRight: '2vh', cursor: 'pointer' }} onClick={this.closePaymentsDrawer} />
                        </div>
                        <Divider style={{ backgroundColor: 'orange' }} />
                        <div style={{ marginTop: '80%', marginBottom: '30%' }}>
                            <Typography style={{ color: ' #172a3a', fontWeight: 'bold', fontSize: '0.8rem', fontFamily: 'Rubik', marginTop: '1rem', marginLeft: '2vh', marginRight: '2vh', textAlign: 'center' }}>
                                Something went wrong !
                            </Typography>
                            {/* <Button onClick={this.tryOfflinePaymentAgain} variant="outlined" style={{ color: 'orange', borderColor: 'orange', width: '30%', textTransform: 'capitalize', marginLeft: '35%', marginRight: '35%', marginTop: '2rem' }}>Try Again</Button> */}
                        </div>
                    </>
                )
            } else {
                return (
                    <div> <CircularProgress style={{ color: 'orange', marginLeft: '11rem', marginTop: '23.5rem', marginBottom: '23rem' }} disableShrink /> </div>
                );
            }
        }
        if (this.state.drawerType === 'orderSummaryPage' && !this.state.paymentCollectedButtonPressed) {

            return (
                <div style={{ position: 'relative', overflowY: 'scroll', height: '100vh' }}>   <ThemeProvider theme={materialTheme}>

                    <Typography style={{ color: 'black', fontWeight: 'bold', fontSize: '2.2vh', fontFamily: 'Rubik', marginLeft: '2vw', marginBottom: '0.6rem', marginTop: '3vh' }}>Payment Collection</Typography>
                    <Divider style={{ backgroundColor: 'orange' }} />
                    <Typography style={{ color: 'black', fontWeight: 'bold', fontSize: '2.2vh', fontFamily: 'Rubik', marginLeft: '2vw', marginBottom: '0.6rem', marginTop: '2vh' }}>Order Summary</Typography>


                    <div style={{ display: 'flex', marginTop: '1vh', position: 'relative', background: '#f4f4f4', height: '11.5vh' }}>
                        <div style={{ position: 'relative', display: 'flex' }}>
                            <Typography style={{ fontFamily: 'Rubik-Regular', color: '#172a3a', position: 'absolute', left: '2vw', top: '2vh' }}>Consultation</Typography>
                            <Typography style={{ fontFamily: 'Rubik-Regular', position: 'absolute', left: '19vw', top: '2vh', width: '3vw' }} > ₹{this.props.consultationDetails.consultationOrderDetails ? this.props.consultationDetails.consultationOrderDetails.amount : ''} </Typography>
                        </div>

                        <div style={{ position: 'relative', display: 'flex' }}>
                            <Typography style={{ fontFamily: 'Rubik-Regular', color: '#172a3a', position: 'absolute', left: '2vw', top: '6.5vh', width: '10vh ' }}>Item Total</Typography>
                            <Typography style={{ fontFamily: 'Rubik-Regular', position: 'absolute', left: '19vw', top: '6.5vh', width: '3vw' }} > ₹{this.props.consultationDetails && this.props.consultationDetails.consultationOrderDetails && this.props.consultationDetails.consultationOrderDetails.amount ?
                                this.props.consultationDetails.consultationOrderDetails.amount
                                : ''} </Typography>
                        </div>
                    </div>

                    <Typography style={{ color: 'black', fontWeight: 'bold', fontSize: '2.2vh', fontFamily: 'Rubik', marginLeft: '2vw', marginBottom: '1vh', marginTop: '3vh' }}>Payment Method</Typography>





                    {/* <hr style={{ color: 'grey', marginTop: '2vh', borderTop: '1px dashed' }} />
                        <div style={{ position: 'relative', disply: 'flex' }}>
                            <Typography style={{ fontFamily: 'Rubik-Medium', color: '#172a3a', position: 'absolute', left: '3vw', top: '2vh' }}>Total Amount</Typography>
                            <Typography style={{ fontFamily: 'Rubik-Medium', position: 'absolute', right: '3vw', top: '2vh', color: '#47b29f' }} >₹ {this.getTotalAmountToBePaid()} </Typography>
                        </div> */}


                    <div style={{ postion: 'absolute' }}>
                        <div style={{ display: 'flex', marginTop: '1vh', position: 'relative', background: '#f4f4f4', height: '18vh' }}>
                            <div style={{ display: 'flex' }}>
                                <Checkbox
                                    disabled={this.props.consultationDetails && this.props.consultationDetails.creditDetails && this.props.consultationDetails.creditDetails.amount === 0}
                                    style={{ color: 'orange', top: '0.6vh', marginLeft: '1.5vw', position: 'absolute' }}
                                    checked={this.props.consultationDetails && this.props.consultationDetails.creditDetails && this.props.consultationDetails.creditDetails.amount === 0 ? false : this.state.creditOptionSelected}
                                    onChange={this.toggleCreditCheck}
                                    value="primary"
                                />
                                <Typography style={{ fontFamily: 'Rubik-Regular', color: '#172a3a', position: 'absolute', left: '4.8vw', top: '2vh' }}>Credit</Typography>
                                <Typography style={{ fontFamily: 'Rubik-Regular', position: 'absolute', right: '2vw', top: '2vh' }} >₹ {this.props.consultationDetails.creditDetails ? this.props.consultationDetails.creditDetails.amount : ''} </Typography>
                            </div>

                            <RadioGroup value={this.state.paymentMethod} onChange={this.handleChange('paymentMethod')}

                            >
                                <div style={{ display: 'flex' }}>

                                    <Radio
                                        disabled={(this.props.consultationDetails && this.props.consultationDetails.consultationOrderDetails && this.props.consultationDetails.creditDetails && (this.props.consultationDetails.creditDetails.amount >= this.props.consultationDetails.consultationOrderDetails.amount)) && this.state.creditOptionSelected}
                                        value='cash' style={{ color: 'orange', top: '6vh', marginLeft: '1.5vw', position: 'absolute' }} />
                                    <Typography style={{ fontFamily: 'Rubik-Regular', position: 'absolute', left: '5vw', top: '7.3vh', color: '#172a3a' }} >Cash </Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <Radio
                                        disabled={(this.props.consultationDetails && this.props.consultationDetails.consultationOrderDetails && this.props.consultationDetails.creditDetails && (this.props.consultationDetails.creditDetails.amount >= this.props.consultationDetails.consultationOrderDetails.amount)) && this.state.creditOptionSelected}
                                        value='card' style={{ color: 'orange', top: '10.5vh', marginLeft: '1.5vw', position: 'absolute' }} />
                                    <Typography style={{ fontFamily: 'Rubik-Regular', position: 'absolute', left: '5vw', top: '11.7vh', color: '#172a3a' }} > Debit/Credit Card</Typography>
                                </div>
                            </RadioGroup>

                        </div>
                    </div>


                    <div style={{
                        position: 'absolute',
                        bottom: '0vh',
                        display: 'flex',
                        flexDirection: 'column',
                        right: '0vw', width: '50vh', height: '22vh',
                        overflowY: 'scroll',
                    }}>

                        <div style={{ display: 'flex', background: '#f4f4f4', height: '7vh', marginBottom: '3vh', position: 'relative' }}>
                            <Typography style={{ fontFamily: 'Rubik-Medium', color: '#172a3a', left: '2vw', top: '2vh', width: '10vw', position: 'absolute' }}>To be collected</Typography>
                            <Typography style={{ fontFamily: 'Rubik-Regular', left: '23vw', top: '2vh', width: '3vw', position: 'absolute' }} > ₹{this.getTotalAmountToBePaid()} </Typography>
                        </div>


                        <div>
                            <Button onClick={this.clickPaymentCollectedButton}
                                disabled={(this.props.consultationDetails && this.props.consultationDetails.consultationOrderDetails) && (this.getTotalAmountFromPaymentObject(this.getPaymentList().payments) !== this.props.consultationDetails.consultationOrderDetails.amount)}
                                style={{
                                    backgroundColor: (this.props.consultationDetails && this.props.consultationDetails.consultationOrderDetails) && (this.getTotalAmountFromPaymentObject(this.getPaymentList().payments) !== this.props.consultationDetails.consultationOrderDetails.amount) ? '#E5E5E5' : 'orange',

                                    color: (this.props.consultationDetails && this.props.consultationDetails.consultationOrderDetails) && (this.getTotalAmountFromPaymentObject(this.getPaymentList().payments) !== this.props.consultationDetails.consultationOrderDetails.amount) ? '#CBCBCB' : 'white',

                                    width: '46vh', height: '7vh', textTransform: 'capitalize', marginLeft: '2vh', marginRight: '2vh', marginBottom: '2vh',
                                }}> Payment Collected</Button>
                        </div>
                    </div>


                </ThemeProvider>
                </div>
            )

        }
        if (this.state.drawerType === 'orderSummaryPage' && this.state.paymentCollectedButtonPressed) {
            return (
                <div>
                    <Typography style={{ color: ' #172a3a', fontWeight: 'bold', fontSize: '1rem', fontFamily: 'Rubik', marginTop: '45vh', marginLeft: '35%', marginBottom: '2vh' }}> Are you sure ?</Typography>
                    <div style={{ marginBottom: '30%', marginLeft: '23%', marginRight: '20%' }}>
                        <Button onClick={() => { this.setState({ paymentCollectedButtonPressed: false }) }} variant="outlined" style={{ color: 'orange', borderColor: 'orange', width: '40%' }}>No</Button>
                        <Button onClick={this.createPayment} variant="outlined" style={{ marginLeft: '15%', color: 'orange', borderColor: 'orange', width: '40%' }} >Yes</Button>
                    </div>
                </div>
            )
        }
        if (this.state.drawerType === 'offlinePaymentPage') {
            if (this.props.consultationDetails.offlinePaymentStatus === errors.ERROR_IN_CREATING_OFFLINE_PAYMENT || this.props.consultationDetails.focussedConsultation === errors.ERROR_IN_FETCHING_PARTICULAR_CONSULTATION) {
                return (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Typography style={{ color: 'black', fontWeight: 'bold', fontSize: '1.2rem', fontFamily: 'Rubik', marginTop: '1.5rem', marginLeft: '0.8rem', marginBottom: '0.6rem' }}>Payment Collection</Typography>
                            <CloseButtonXActive style={{ marginTop: '1.9rem', marginRight: '2vh', cursor: 'pointer' }} onClick={this.closePaymentsDrawer} />
                        </div>
                        <Divider style={{ backgroundColor: 'orange' }} />
                        <div style={{ marginTop: '80%', marginBottom: '30%' }}>
                            <Typography style={{ color: ' #172a3a', fontWeight: 'bold', fontSize: '0.8rem', fontFamily: 'Rubik', marginTop: '1rem', marginLeft: '2vh', marginRight: '2vh', textAlign: 'center' }}>
                                {this.props.consultationDetails.focussedConsultation === errors.ERROR_IN_FETCHING_PARTICULAR_CONSULTATION ? 'The payment has been accepted. Please be assured that the invoice will be marked as paid in another 2-3 hrs.' : ' Something went wrong !'}
                            </Typography>
                            {/* <Button onClick={this.tryOfflinePaymentAgain} variant="outlined" style={{ color: 'orange', borderColor: 'orange', width: '30%', textTransform: 'capitalize', marginLeft: '35%', marginRight: '35%', marginTop: '2rem' }}>Try Again</Button> */}
                        </div>
                    </>
                )
            }
            if (this.props.consultationDetails && _.isNil(this.props.consultationDetails.focussedConsultation)) {
                return (
                    <div> <CircularProgress style={{ color: 'orange', marginLeft: '11rem', marginTop: '23.5rem', }} disableShrink />
                        <Typography style={{ fontFamily: 'Rubik', fontSize: '1.5vh', marginLeft: '37%', marginRight: '37%', marginTop: '1vh' }}> Verifying payment</Typography> </div>
                );
            }
        }
        //     //this.props.consultationDetails && this.props.consultationDetails.focussedConsultation && this.props.consultationDetails.focussedConsultation.invoice && this.props.consultationDetails.focussedConsultation.invoice.status === 'paid'     
        if (this.state.drawerType === 'paymentVerifiedSuccessfully') {
            return (
                <>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Typography style={{ color: 'black', fontWeight: 'bold', fontSize: '1.2rem', fontFamily: 'Rubik', marginTop: '1.5rem', marginLeft: '0.8rem', marginBottom: '0.6rem' }}>Payment Collection</Typography>
                        <CloseButtonXActive style={{ marginTop: '1.9rem', marginRight: '1rem', cursor: 'pointer' }} onClick={this.closePaymentsDrawer} />
                    </div>
                    <Divider style={{ backgroundColor: 'orange' }} />
                    <div style={{ marginTop: '80%', marginBottom: '30%' }}>
                        <BookingConfirmed style={{ marginLeft: '45%' }} />
                        <Typography style={{ color: ' #172a3a', fontWeight: 'bold', fontSize: '0.9rem', fontFamily: 'Rubik', marginTop: '1rem', marginLeft: '33%' }}> Payment Completed</Typography>
                    </div>
                </>
            )
        }
        if (this.state.drawerType === 'paymentVerificationFailed') {

            return (
                <>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Typography style={{ color: 'black', fontWeight: 'bold', fontSize: '1.2rem', fontFamily: 'Rubik', marginTop: '1.5rem', marginLeft: '0.8rem', marginBottom: '0.6rem' }}>Payment Collection</Typography>
                        <CloseButtonXActive style={{ marginTop: '1.9rem', marginRight: '2vh', cursor: 'pointer' }} onClick={this.closePaymentsDrawer} />
                    </div>
                    <Divider style={{ backgroundColor: 'orange' }} />
                    <div style={{ marginTop: '80%', marginBottom: '30%' }}>
                        <Typography style={{ color: ' #172a3a', fontWeight: 'bold', fontSize: '0.8rem', fontFamily: 'Rubik', marginTop: '1rem', marginLeft: '2vh', marginRight: '2vh', textAlign: 'center' }}>
                            The payment has been accepted. Please be assured that the invoice will be marked as paid in another 2-3 hrs.
                        </Typography>
                        {/* <Button onClick={this.tryOfflinePaymentAgain} variant="outlined" style={{ color: 'orange', borderColor: 'orange', width: '30%', textTransform: 'capitalize', marginLeft: '35%', marginRight: '35%', marginTop: '2rem' }}>Try Again</Button> */}
                    </div>
                </>
            )
        } else {
            return (
                <div> <CircularProgress style={{ color: 'orange', marginLeft: '11rem', marginTop: '23.5rem', marginBottom: '23rem' }} disableShrink /> </div>
            );
        }

    }

    onHover = () => {
        this.setState({ mousePonterInside: true })
    }

    outsideCard = () => {
        this.setState({ mousePonterInside: false })  // make it false
    }


    renderPaymentButton = () => {
        if (this.props.consultation && this.props.consultation.invoice && (this.props.consultation.invoice.status === 'free' || this.props.consultation.invoice.status === 'paid')) {
            return this.renderPaymentText()
        }
        if (this.props.consultation && this.props.consultation.invoice && this.props.consultation.invoice.status === 'unpaid') {
            return <Button
                style={{ height: '3vh', width: '8vw', marginTop: '-2.8vh', marginBottom: '-3vh', position: 'absolute', right: '1vw', backgroundColor: '#FF5500' }}
                onClick={this.openCollectPaymentDrawer}

            >
                <Typography style={{ textTransform: 'capitalize', fontSize: '1.2vh', fontFamily: 'Rubik', color: 'white' }}>Collect Payment</Typography>
            </Button>
        }
    };

    renderPaymentText = () => {
        if (this.props.consultation && this.props.consultation.invoice && this.props.consultation.invoice.status === 'free') {
            return (
                <>
                    <Typography style={{ height: '3vh', width: '10vw', marginTop: '-2vh', marginBottom: '-3vh', position: 'absolute', right: '0.5vw', fontSize: '1.4vh', color: '#47b29f' }} >Free Consultation</Typography>
                    <RoundGreenTick style={{ height: '3vh', width: '2.5vw', marginTop: '-2.8vh', marginBottom: '-3vh', position: 'absolute', right: '-0.1vw' }} />
                </>
            );
        }
        if (this.props.consultation && this.props.consultation.invoice && this.props.consultation.invoice.status === 'paid') {
            return (
                <>
                    <Typography style={{ height: '3vh', width: '10vw', marginTop: '-2vh', marginBottom: '-3vh', position: 'absolute', right: '0.5vw', fontSize: '1.4vh', color: '#47b29f' }} >Payment Done</Typography>
                    <RoundGreenTick style={{ height: '3vh', width: '2.5vw', marginTop: '-2.8vh', marginBottom: '-3vh', position: 'absolute', right: '-0.1vw' }} />
                </>
            );
        }
        if (this.props.consultation && this.props.consultation.invoice && this.props.consultation.invoice.status === 'unpaid') {
            return (
                <>
                    <Typography style={{ height: '3vh', width: '10vw', marginTop: '-2vh', marginBottom: '-3vh', position: 'absolute', right: '0.5vw', fontSize: '1.4vh', color: '#f66a6a' }} >Collect ₹ 100</Typography>
                    <RoundRupeeRed style={{ height: '3vh', width: '2.5vw', marginTop: '-2.8vh', marginBottom: '-3vh', position: 'absolute', right: '-0.1vw' }} />
                </>
            )
        }
    }

    billingPendingMarker = () => {
        const { classes } = this.props;
        return (
            <svg className={classes.marker} height="50px" width="8px">
                <rect x="0" width="8" height="50" rx="4" fill={color.reds[300]} />
            </svg>
        )
    }

    shouldPrescriptionButtonBeDisplayed = () => {
        const { auth } = this.props;
        const userRoles = auth?.authDetails?.data?.user?.roles.map(role => role.name);
        if (userRoles.includes(accessRole.HEALTH_MANAGER)
            || userRoles.includes(accessRole.HA)) {
            return true;
        } else {
            return false;
        }
    }

    renderConsultationCard = () => {
        const { consultation, auth, selectConsultationForBilling, classes } = this.props;
        return (
            <div
                onMouseOver={this.onHover}
                onMouseLeave={this.outsideCard}
                className={classes.consultationRow}
            >
                <div className={classes.marker}>
                    {
                        !consultation?.invoice?.bill_generated
                            ? this.billingPendingMarker()
                            : ' '
                    }
                </div>
                <div className={classes.content}>
                    <div className={classes.patientDetails}>
                        <Typography
                            style={{
                                fontFamily: 'Rubik',
                                fontSize: '13px',
                                color: color.DARK_GREY,
                                textTransform: 'capitalize',
                                width: '40%',
                                wordWrap: 'normal',
                            }}
                        >
                            {
                                (consultation?.customer?.name)
                                    ? <strong>{consultation.customer.name}</strong>
                                    : null

                            }
                            &nbsp;|&nbsp;
                            {
                                (consultation?.customer?.age || consultation?.customer?.age === 0)
                                    ? consultation.customer.age + ' ' + getAgeUnit(consultation.customer.age_unit)
                                    : null
                            }
                            &nbsp;|&nbsp;
                            {
                                (consultation?.customer?.contact_number)
                                    ? consultation.customer.contact_number
                                    : null
                            }
                            &nbsp;|&nbsp;
                            {
                                (consultation.customer.lop)
                                    ? consultation.customer.lop
                                    : null
                            }
                        </Typography>
                        <Typography
                            style={{
                                fontFamily: 'Rubik',
                                fontSize: '13px',
                                color: color.DARK_GREY,
                                textTransform: 'capitalize',
                                width: '10%',
                            }}
                        >
                            {
                                _.truncate(consultation.summary, {
                                    'length': this.state.numberOfCharacters,
                                    'separator': ' '
                                })
                            }
                        </Typography>
                        <Typography
                            style={{
                                fontFamily: 'Rubik',
                                fontSize: '13px',
                                color: color.DARK_GREY,
                                textTransform: 'capitalize',
                                width: '15%',
                            }}
                        >
                            <strong>{'Dr. ' + consultation.doctor?.name}</strong>
                        </Typography>
                        <Typography
                            style={{
                                fontFamily: 'Rubik',
                                fontSize: '13px',
                                color: color.DARK_GREY,
                                textTransform: 'capitalize',
                                textAlign: 'left',
                                width: '10%',
                            }}
                        >
                            {getCalendaredString(consultation.completed_at)}
                        </Typography>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            width: '20%',
                            height: '100%',
                            padding: '0 2vh',
                        }}
                    >

                        {
                            consultation.id
                                && (auth && auth.authDetails && (this.shouldPrescriptionButtonBeDisplayed() === true))
                                ? (<Button
                                    target="_blank"
                                    variant="outlined"
                                    href={`${config.apiURL}/v2/consultations/${consultation.id}/prescriptions/download`}
                                    style={{
                                        fontFamily: 'Rubik',
                                        textTransform: 'capitalize',
                                        color: color.ROYAL_ORANGE,
                                        border: `1px solid ${color.ROYAL_ORANGE}`,
                                        fontWeight: 'bold',
                                        height: '36px',
                                        fontSize: '13px',
                                        marginRight: '1vw',
                                    }}
                                >
                                    Prescription
                                </Button>)
                                : null
                        }
                        {
                            consultation?.invoice?.bill_generated
                                ? (<span style={{
                                    fontFamily: 'Rubik',
                                    textTransform: 'capitalize',
                                    border: `1px solid ${color.ROYAL_ORANGE}`,
                                    borderRadius: '4px',
                                    fontWeight: 'bold',
                                    fontSize: '13px',
                                    width: '100px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '36px',
                                    padding: '0 1vh',
                                    background: color.WHITE,
                                    color: color.ROYAL_ORANGE,
                                }}>
                                    Bill Generated
                                </span>)
                                : (<Button
                                    variant="outlined"
                                    onClick={() => {
                                        selectConsultationForBilling(consultation);
                                        history.push({
                                            pathname: `/loadingFamily`,
                                            search: `family=${consultation.customer.family.id}`,
                                            hash: 'billing',
                                            state: {
                                                from: clientRoute.CONSULTATIONS_TODAY_PAGE,
                                            }
                                        });
                                    }}
                                    style={{
                                        fontFamily: 'Rubik',
                                        textTransform: 'capitalize',
                                        border: '1px solid orange',
                                        fontWeight: 'bold',
                                        fontSize: '13px',
                                        height: '36px',
                                        width: '130px',
                                        background: color.ROYAL_ORANGE,
                                        color: color.WHITE,
                                    }}
                                >
                                    Generate Bill
                                </Button>)
                        }
                    </div>
                </div>
            </div>

            // <div
            //     onMouseOver={this.onHover}
            //     onMouseLeave={this.outsideCard}
            //     style={{
            //         boxSizing: 'border-box',
            //         borderWidth: '1px 1px 1px 1px',
            //         width: 'calc(1/5 * 100%)',
            //         fontFamily: 'Rubik',
            //         borderColor: '#cbcbcb',
            //         borderStyle: 'solid',
            //         borderRadius: '10px',
            //         position: 'relative',
            //     }}>
            //     <div
            //         style={{
            //             padding: '1.5vh',
            //             display: 'flex',
            //             flexDirection: 'column',
            //         }}>
            //         <div style={{
            //             display: 'flex',
            //             flexDirection: 'row',
            //         }}>
            //             {
            //                 (consultation?.customer?.name)
            //                     ? <Typography
            //                         style={{
            //                             float: 'left',
            //                             marginRight: '0.5vw',
            //                             display: 'inline-flex',
            //                             color: '#172a3a',
            //                             fontFamily: 'Rubik',
            //                             fontSize: '13px',
            //                             marginTop: '1.1vh',
            //                             fontWeight: 'bold',
            //                         }}
            //                     >
            //                         {consultation.customer.name}
            //                     </Typography>
            //                     : null
            //             }
            //             {
            //                 (consultation?.customer?.age || consultation?.customer?.age === 0)
            //                     ? <Typography
            //                         style={{
            //                             float: 'left',
            //                             marginRight: '0.5vw',
            //                             marginTop: '1.1vh',
            //                             display: 'inline-flex',
            //                             color: '#172a3a',
            //                             fontFamily: 'Rubik',
            //                             fontSize: '13px',
            //                             marginTop: '1.1vh',
            //                             marginRight: '0.5vw',
            //                         }}
            //                     >
            //                         <span style={{ marginRight: '0.5vw' }}> | </span>
            //                         {this.props.consultation.customer.age + 'y'}
            //                     </Typography>
            //                     : null
            //             }
            //             {
            //                 (consultation?.customer?.contact_number && consultation?.customer?.lop)
            //                 ? <Typography
            //                     style={{
            //                         float: 'left',
            //                         display: 'inline-flex',
            //                         color: '#172a3a',
            //                         fontFamily: 'Rubik',
            //                         fontSize: '13px',
            //                         posiiton: 'absolute',
            //                         textTransform: 'capitalize',
            //                         marginTop: '1.1vh',
            //                         marginRight: '0.5vw',
            //                     }}
            //                 >
            //                     <span style={{ marginRight: '0.5vw' }}> | </span>
            //                     {consultation.customer.contact_number}
            //                     <span style={{ marginLeft: '0.5vw', marginRight: '0.5vw' }}> | </span>
            //                     {consultation.customer.lop}
            //                 </Typography>
            //                 : null
            //             }
            //             {
            //             (consultation.summary)
            //                 ? <Typography
            //                     style={{
            //                         color: '#172a3a',
            //                         fontFamily: 'Rubik',
            //                         fontSize: '13px',
            //                         position: 'absolute',
            //                         left: '25vw',
            //                         top: '2.5vh'
            //                     }}
            //                 >
            //                     <div
            //                         style={{
            //                             display: 'flex',
            //                             flexDirection: 'row'
            //                             }}
            //                     >
            //                         {
            //                             _.truncate(consultation.summary, {
            //                                 'length': this.state.numberOfCharacters,
            //                                 'separator': ' '
            //                             })
            //                         }
            //                     </div>
            //                 </Typography>
            //                 : null
            //             }
            //         {
            //             (consultation.doctor?.name)
            //                 ? <div
            //                     style={{
            //                         color: '#172a3a',
            //                         fontFamily: 'Rubik',
            //                         fontSize: '13px',
            //                         position: 'absolute',
            //                         left: '42vw',
            //                         top: '2.7vh',
            //                     }}
            //                 >
            //                     {'Dr. ' + consultation.doctor.name}
            //                 </div>
            //                 : null
            //         }
            //         {
            //             consultation.completed_at
            //                 ? <div
            //                     style={{
            //                         color: '#172a3a',
            //                         fontFamily: 'Rubik',
            //                         fontSize: '13px',
            //                         position: 'absolute',
            //                         left: '56vw',
            //                         top: '2.7vh'
            //                     }}
            //                 >
            //                     {getISTFormat8(consultation.completed_at)}
            //                 </div>
            //                 : null
            //         }
            //         {
            //             consultation.id
            //             && (auth && auth.authDetails && !_.isNil(_.find(auth.authDetails.data.user.roles, { name: accessRole.HEALTH_MANAGER })))
            //             ? (<Button
            //                 variant="outlined"
            // color={color.ROYAL_ORANGE}
            //                 onClick = {() => window.open(`${config.apiURL}/v1/consultations/${consultation.id}/prescriptions/download`)}
            //                 style={{
            //                     position: 'absolute',
            //                     left: '69vw',
            //                     top: '0.65vh',
            //                     fontFamily: 'Rubik',
            //                     textTransform: 'capitalize',
            //                     color: color.ROYAL_ORANGE,
            //                     border: '1px solid orange',
            //                     fontWeight: 'bold',
            //                     fontSize: '13px',
            //                 }}
            //             >
            //                 Prescription
            //             </Button>)
            //             : null
            //         }
            //         </div>

            //     </div>
            // </div>

        );
    };

    render() {
        return (
            <>
                {this.renderConsultationCard()}
                < Drawer anchor="right" open={this.state.drawer} onClose={this.closePaymentsDrawer}>

                    <Paper style={{ width: '50vh', height: '1000000vh', overflowY: 'hidden' }}>
                        {this.renderDrawerContent()}
                    </Paper>
                </Drawer>
            </>
        )
    }
}


const mapStateToProps = (state) => {
    return { auth: state.auth, familyDetails: state.familyDetails, error: state.error, consultationDetails: state.consultationDetails };
};

const mapDispatchToProps = {
    resetConsultationById,
    resetAddVitalsStatus,
    getConsultationsByClinicId,
    setPresentableConsultationQueue,
    getConsultationsByClinicIdFirstPage,
    resetConsultationQPageNumber,
    checkoutConsultation,
    resetCheckoutConsultationStatus,
    getConsultationOrder,
    resetConsultationOrderDetails,
    resetCreditDetails,
    getCreditDetailsByFamilyId,
    createOfflinePayment,
    resetOfflinePaymentStatus,
    getConsultationById,
    selectConsultationForBilling: consultationActions.selectConsultationForBilling,
};

const reduxWrapper = connect(mapStateToProps, mapDispatchToProps);
const styleWrapper = withStyles(useStyles, { withTheme: true });

export default styleWrapper(reduxWrapper(ParticularCompletedC));