import config from '../config';
const axios = require('axios');

export const  uploadBatchExtendSubscriptionFile= async (ACCESS_TOKEN,emailId,file) => {
    const url = config.apiURL + `/v3/subscriptions/batch-extend`;
     const form = new FormData();
     form.append('subscription_file', file);
     form.append('requester_email', emailId);
    const options = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + ACCESS_TOKEN,
        },
        timeout: 10000,
        responseType: 'json',
        responseEncoding: 'utf8',
    };
    const response = await axios.post(url, form, options);
    return response;
}
