import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { getInvoices, resetInvoiceDetails } from '../../../actions';
import { CircularProgress, Typography } from '@material-ui/core';
import ParticularInvoice from './PaticularInvoice';

class InvoiceList extends React.Component {

    state = { currentSubscriptionId: this.props.subscriptionId }

    componentDidMount() {
        this.props.resetInvoiceDetails();
        this.props.getInvoices(this.state.currentSubscriptionId);
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        // do things with nextProps.someProp and prevState.cachedSomeProp
        if (prevState.currentSubscriptionId !== nextProps.subscriptionId) {
            return {
                currentSubscriptionId: nextProps.subscriptionId,// this does setState
            };
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.currentSubscriptionId !== this.state.currentSubscriptionId) {
            this.props.resetInvoiceDetails();
            this.props.getInvoices(this.state.currentSubscriptionId);
        }
    }

    render() {
        if (this.props.familyDetails && this.props.familyDetails.invoicesOfSubscription && _.isEmpty(this.props.familyDetails.invoicesOfSubscription.invoices)) {
            return (
                <div style={{ marginTop: '10vh' }}>

                    <Typography style={{ fontFamily: 'Rubik-Regular' }}> No Invoice Found</Typography>
                </div>
            )
        } else if (this.props.familyDetails && this.props.familyDetails.invoicesOfSubscription) {
            return (
                <>
                    {(this.props.familyDetails.invoicesOfSubscription.invoices).map((invoice) => {
                        return (
                            <ParticularInvoice
                                invoice={invoice} />

                        );
                    })}
                    <br />
                </>
            )
        } else {
            return (
                <div>
                    <div> <CircularProgress style={{ color: 'orange', marginLeft: '48%', marginRight: '48%', marginTop: '35vh' }} disableShrink /> </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return { auth: state.auth, familyDetails: state.familyDetails, error: state.error };
};


export default connect(mapStateToProps, { getInvoices, resetInvoiceDetails })(InvoiceList);