import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography, Paper } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { getProductPriceAfterDiscount } from '../../common/utils';

import './ProductList.scss';

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginRight: '6vw',
        marginTop: '3vh',
        marginLeft: '6vw',
    },
    paper: {
        border: '1px solid #e5e5e5',
        height: '5.5vh',
        boxShadow: 'none',
    }
});


class SelectPaymentMultiplier extends React.Component {

    state = {
        selectedMultipler: ''
    };

    handleCheckBoxChange = (multiplier) => {
        this.setState({ selectedMultipler: multiplier });
        this.props.setSelectedPaymentMultiplier(multiplier);
    };

    render() {
        const { product, classes } = this.props;

        return (
            <div>
                <div>
                    <div className={classes.root}>
                        <Typography style={{ marginTop: '2vh', marginBottom: '1vh', fontFamily: 'Rubik-Regular' }}> Select a payment frequency:</Typography>
                        <Grid container spacing={4} style={{ overflowY: 'auto', height: '15vh' }}>

                            {product.supported_payment_multipliers.map((multiplier) => {
                                return (
                                    <Grid item xs={6} sm={3} key={multiplier} style={{ marginTop: '2vh' }}>
                                        <Paper className={classes.paper}
                                            style={{ borderColor: this.state.selectedMultipler === multiplier ? '#f38b44' : '#e5e5e5', borderRadius: '5px', cursor: 'pointer' }}
                                            onClick={() => this.handleCheckBoxChange(multiplier)}
                                        >
                                            <Checkbox
                                                style={{ float: 'left' }}
                                                icon={<CircleUnchecked />}
                                                checkedIcon={<CircleCheckedFilled style={{ color: "#f38b44" }} />}
                                                checked={this.state.selectedMultipler === multiplier}
                                            />
                                            <Typography style={{ float: 'left', marginTop: '1.4vh', fontFamily: 'Rubik-Medium', fontSize: '0.85rem' }}>{_.capitalize(multiplier)}</Typography>
                                            <Typography style={{ float: 'right', marginTop: '1.4vh', fontFamily: 'Rubik-Medium', fontSize: '0.85rem', marginRight: '1vw' }}>₹ {getProductPriceAfterDiscount(product, multiplier)}</Typography>
                                        </Paper>
                                    </Grid>)
                            })}
                        </Grid>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error, familyDetails: state.familyDetails, newPlan: state.newPlan };
};

export default connect(mapStateToProps)(withStyles(styles)(SelectPaymentMultiplier));