import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';


export default function AsynchronousAutoComplete(props) {
    const { label, defaultValue, businessOptions, handelInputValue, searchBusinessesEvent } = props;
    const [open, setOpen] = React.useState(false);
    const [inputValue, setInputValue] = React.useState('')
    const [options, setOptions] = React.useState([]);
    const [loading] = React.useState(false);
    
    useEffect(() => {
        setOptions(Object.keys(businessOptions).map((key) => businessOptions[key]));
    }, [businessOptions])

  useEffect(() => {
      setInputValue(defaultValue);
  }, [defaultValue])

  const throttled = useRef(_.throttle((newValue) => {
    handelInputValue(newValue);

    if (newValue.length !== 3) {
      return;
    }

    (async () => {
      await searchBusinessesEvent(newValue);
    })();
  }, 500))

  useEffect(() => throttled.current(inputValue), [inputValue])

  React.useEffect(() => {
    if (!inputValue.length) {
      setOptions([]);
      setOpen(false);
    }
  }, [open, inputValue.length]);

  return (
    <>
        <Autocomplete
        id="asynchronous-search"
        open={open}
        onOpen={() => {
            if (inputValue.length > 1) {
                setOpen(true);
            }
        }}
        onClose={() => {
            setOpen(false);
        }}
        inputValue={inputValue}
        onInputChange={(e, value) => {
            setInputValue(value)
        }}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        options={options}
        loading={loading}
        renderInput={(params) => (
            <TextField
            {...params}
            label={label}
            InputProps={{
                ...params.InputProps,
                endAdornment: (
                <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                </React.Fragment>
                ),
            }}
            />
        )}
        />
        {inputValue.length >= 1 && inputValue.length <= 2 && <Typography style={{ color: 'red', fontSize: '12px'}}>
            Type minimum 3 characters
        </Typography>}
    </>
  );
}
