import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Box, Typography, Button, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import _ from 'lodash';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as ErrorSymbol } from '../../../assets/icons/error-symbol.svg';

import {
    searchBusinessesEvent,
    updateFamilyBusinessMappingEvent,
    deleteFamilyBusinessMappingEvent
} from '../../../actions/familyAction';
import style from './style';
import AsynchronousAutoComplete from "../../common/AsynchronousAutoComplete";

class BusinessDetails extends Component {

    constructor() {
        super();
        this.state = {
            businessSelected: '',
            deleteBusiness: false,
            addBusiness: false,
            deleteBusinessId: null,
        }
    }

    setToDefaultState() {
        this.setState({
            businessSelected: '',
            deleteBusiness: false,
            addBusiness: false,
            deleteBusinessId: null,
        })
    }

    getCircularProgress = () => {
        return <CircularProgress style={{
            color: 'orange',
            left: '50%',
            top: '55%',
            position: 'absolute',
        }} />;
    }

    addNewBusiness() {
        this.setState({
            addBusiness: true,
        })
    }

    deleteBusiness(business) {
        this.setState({
            businessSelected: business.name,
            deleteBusiness: true,
            deleteBusinessId: business.id
        })
    }

    confirmDelete() {
        const { familyDetails, deleteFamilyBusinessMapping } = this.props;
        const { deleteBusinessId } = this.state;
        const index = _.findIndex(familyDetails.currentCustomer.businesses, (business) => business.id === deleteBusinessId);
        familyDetails.currentCustomer.businesses.splice(index, 1);
        deleteFamilyBusinessMapping(familyDetails.currentCustomer.family.id, deleteBusinessId, familyDetails.currentCustomer.businesses);
        this.setToDefaultState();
    }

    addBusiness() {
        const {
            familyDetails, updateFamilyBusinessMapping
        } = this.props;
        const {
            updatedBusinessName,
        } = this.state;
        let selectedBusinessDetails = _.find(familyDetails.searchBusinesses, (business) => business.name === updatedBusinessName)
        selectedBusinessDetails = {
            ...selectedBusinessDetails,
            id: selectedBusinessDetails._id
        }
        const businessDetailsObject = {
            client: {
                id: selectedBusinessDetails._id
            }
        }
        familyDetails.currentCustomer.businesses.push(selectedBusinessDetails);
        updateFamilyBusinessMapping(familyDetails.currentCustomer.family.id, businessDetailsObject, familyDetails.currentCustomer.businesses);
        this.setToDefaultState();
    }

    updateBusinessNameValue(value) {
        this.setState({
            updatedBusinessName: value
        })
    }

    renderBusinessForm() {
        const { classes, auth, searchBusinesses, familyDetails } = this.props;
        const { businessSelected } = this.state;
        const lookUp = _.keyBy(familyDetails.currentCustomer.businesses, (business) => business.name);
        const filteredBusiness = _.filter(familyDetails.searchBusinesses, (business) => {
            return lookUp[business.name] === undefined;
        })
        return (
            <Box className={classes.businessFormContainer}>
                <AsynchronousAutoComplete
                    businessOptions={filteredBusiness}
                    searchBusinessesEvent={(name) => searchBusinesses(name)}
                    handelInputValue={(value) => this.updateBusinessNameValue(value)}
                    auth={auth} defaultValue={businessSelected} label="Business Name"
                />
                <Button className={classes.addBusinessButton} onClick={() => this.addBusiness()}> Add New Business </Button>
            </Box>
        )
    }

    renderBusinessesList() {
        const { classes, familyDetails } = this.props;
        const { addBusiness, deleteBusiness } = this.state;
        return (
            <Box className={classes.businessContainer}>
                {familyDetails.currentCustomer.businesses && familyDetails.currentCustomer.businesses.map((business) => {
                    return (
                        <Box className={classes.businessBox} key={business.id}>
                            <Box className={classes.businessDetails}>
                                <Typography className={classes.businessName}> {business.name} </Typography>
                            </Box>
                            <Box onClick={() => this.deleteBusiness(business)} className={classes.delete}>
                                <DeleteIcon/>
                            </Box>
                        </Box>
                    )
                })}
                {
                    addBusiness && this.renderBusinessForm()
                }
                {
                    !addBusiness && !deleteBusiness && <Button onClick={() => this.addNewBusiness()} className={`${classes.addButton}`}> <AddIcon fontSize="small" /> Add </Button>
                }
            </Box>
        )
    }

    render() {
        const { classes, closeBusinessesDrawer, newCustomer } = this.props;
        const { deleteBusiness } = this.state;
        return (
            <>
                <Box className={`${classes.deleteConfirmationModal} ${deleteBusiness ? classes.visible : classes.visibilityhidden}`}>
                    <Box className={classes.overlay}></Box>
                    <Box className={classes.deleteModalContainer}>
                        <Box className={classes.modalHeader}>
                            <Box className={classes.warningBox}>
                                <ErrorSymbol />
                            </Box>
                            <Box className={classes.modalText}>
                            <Typography>
                                Are you sure you want to
                            </Typography>
                            <Typography className={classes.bold}>
                            delete this business?
                            </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.modalActionButton}>
                            <Button className={classes.greyColor} onClick={() => this.setToDefaultState()}> No </Button>
                            <Button onClick={() => this.confirmDelete()} className={classes.royalOrangeColor}> Yes </Button>
                        </Box>
                    </Box>
                </Box>


                <Box className={classes.header}>
                    <Typography className={classes.bold}> Update Business </Typography>
                    <CloseIcon className={classes.closeIcon} onClick={() => closeBusinessesDrawer()} />
                </Box>
                {newCustomer.isLoading ? this.getCircularProgress() : <></>}
                {this.renderBusinessesList()}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const {
        familyDetails, newCustomer, auth,
    } = state;
    return {
        familyDetails,
        newCustomer,
        auth
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateFamilyBusinessMapping: (familyId, payload, allMembers) => { dispatch(updateFamilyBusinessMappingEvent(familyId, payload, allMembers)); },
    deleteFamilyBusinessMapping: (familyId, businessId, allMembers) => { dispatch(deleteFamilyBusinessMappingEvent(familyId, businessId, allMembers)); },
    searchBusinesses: (name) => { dispatch(searchBusinessesEvent(name)); },
});

const ConnectedBusinessDetails = withStyles(style,
    { withTheme: true })(connect(mapStateToProps,
        mapDispatchToProps)(withTranslation()(BusinessDetails)));
export default ConnectedBusinessDetails;
