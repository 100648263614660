import React, { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { TextField, withStyles } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { connect, useSelector } from 'react-redux';
import { consultationActions } from '../../../actions';
import useStyles from './style';
import { itemTypes } from '../../../constants';
import moment from 'moment';
import { discountType } from '../../../common/constants';

function AutoComplete(props) {
  const {
    handleItemSelection, searchBillingItems, item,
    options, handleInputOnChange, classes, consultationDetails,
    getFormattedItemCategory } = props;
  const filter = createFilterOptions();
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(item.name);
  const { auth } = useSelector(state => ({
    auth: state.auth,
  }));
  const debouncedSearch = useRef(debounce((inputValue) => {
    const clinicId = auth.currentClinicLocation.id;
    if (inputValue.length >= 3) {
      searchBillingItems(inputValue, null, clinicId);
    }
  }, 500));
  useEffect(() => {
    if (open && inputValue.length === 0) {
      setOpen(false);
    }
  }, [open, inputValue]);

  return (
    <>
      <Autocomplete
        classes={{
          fullWidth: classes.autocompleteInput,
          inputRoot: classes.autocompleteInput
        }}
        id="asynchronous-search"
        open={open}
        fullWidth
        onOpen={() => {
          if (inputValue.length >= 0) {
            setOpen(true);
          }
        }}
        onClose={() => {
          setOpen(false);
        }}
        inputValue={inputValue}
        renderOption={(option) => {
          const { display_name } = option;
          return (
            <div className={classes.option}>
              <div className={classes.optionName}>
                {display_name}
              </div>
              <div className={classes.optionCategory}>
                {getFormattedItemCategory(option)}
              </div>
            </div>
          )
        }}
        getOptionDisabled={(option) => {
          const billableItems = consultationDetails?.billing?.items;
          const optionId = option.id;
          const filteredItemList = billableItems.filter(item => item.id === optionId || item.external_id === optionId);
          return filteredItemList.length !== 0
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          // Suggest the creation of a new value
          if (params.inputValue !== '') {
            filtered.push({
              display_name: params.inputValue,
              name: params.inputValue,
              price: 0,
              quantity: 1,
              discount: {
                type: discountType.PERCENTAGE,
                value: 0,
              },
              total: 0,
              amount: 0,
              id: moment().valueOf(),
              category: itemTypes.OTHER,
            });
          }
          return filtered;
        }}
        onKeyDown={(e) => {
          const pressedKey = e.key;
          if (pressedKey === 'Enter') {
            e.preventDefault();
          }
        }}
        onInputChange={(e, value, reason) => {
          if ((reason === "input" || reason === "clear")) {
            setInputValue(value);
            handleInputOnChange(value);
            debouncedSearch.current(value);
          }
        }}
        popupIcon=""
        getOptionSelected={(option, value) => {
          return option.display_name === value.display_name;
        }}
        onChange={(event, value, reason) => {
          if (reason === 'select-option') {
            const { display_name } = value;
            setInputValue(display_name);
            handleItemSelection(value);
            setOpen(false);
          }
        }}
        style={{
          height: '40px',
          marginTop: '1vh',
        }}
        getOptionLabel={(option) => option.display_name}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            // {...props}
            autoFocus
            error={item.error.name}
            helperText={`${getFormattedItemCategory(item) !== '' ? getFormattedItemCategory(item) + '.' : ''} ${item.error.name ? `${item.error.name}` : ''}`}
            InputProps={{
              ...params.InputProps,
              multiline: true,
              placeholder: `Enter item name`,
            }}
            className={classes.textField}
          />
        )}
      />
    </>
  );
}
const mapDispatchToProps = (dispatch) => ({
  modifyLineItem: (itemId, modifications) => { dispatch(consultationActions.modifyLineItem(itemId, modifications)); },
  searchBillingItems: (item, category, clinic) => { dispatch(consultationActions.searchBillingItems(item, category, clinic)); },
});

const mapStateToProps = (state) => {
  const { consultationDetails } = state;
  return {
    consultationDetails,
  };
}

const styleWrapper = withStyles(useStyles, { withTheme: true });
const reduxWrapper = connect(mapStateToProps, mapDispatchToProps);
export default (styleWrapper(reduxWrapper(AutoComplete)));
