import React, { useRef } from "react";
import _ from 'lodash';
import { Box, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import useStyles from "./style";
import { connect } from "react-redux";
import { consultationActions } from "../../../actions/consultationAction";
import { itemTypes } from "../../../constants";
import Plus from "../../../assets/icons/plus";
import Minus from "../../../assets/icons/minus";
import { color } from "../../../constants/colors";
import { modifyLineItemInOrder } from "../../../clients/omsService";

function QuantityPicker(props) {
  const {
    classes,
    item,
    modifyLineItem,
    updateOPDUtilisedValue,
    updateOpdAmountForLineItem,
  } = props;
  const pickerRef = useRef(null);

  const shouldPlusBtnBeDisabled = () => {
    return (
      item.rate === null ||
      item.type === itemTypes.CONSULTATION ||
      item.type === itemTypes.LAB_TEST ||
      item.quantity === (item.available_quantity ?? 100)
    );
  };

  const shouldMinusBtnBeDisabled = () => {
    return item.quantity <= 1;
  };

  return (
    <Box className={classes.pickerContainer}>
      <Box className={classes.picker}>
        <Button
          className={classes.pickerBtn}
          onClick={async () => {
            const updatedLineItem = {
              external_id: String(item.external_id),
              price: String(item.rate),
              total_discount: String(item.discount.value),
              total_discount_type: String(item.discount.type),
              quantity: item.quantity - 1,
              metadata: {
                display_name: item.name,
                batch_id: item.batch_number,
              },
            };
            const { auth } = props;
            let opdDetails;
            if (item?.opd_utilised === true) {
              updatedLineItem.metadata.opd_utilised = true;
              opdDetails = updateOpdAmountForLineItem(item, updatedLineItem);
              updatedLineItem.metadata.opd_amount = opdDetails.currentItemOpdUsed;
            }
            const accessToken = auth.authDetails.data.access_token;
            modifyLineItemInOrder(accessToken, item.id, updatedLineItem).then(
              (res) => {
                const modifications = {
                  quantity: item.quantity - 1
                };
                if (item?.opd_utilised === true) {
                  _.assign(modifications, { opd_amount: opdDetails.currentItemOpdUsed })
                  updateOPDUtilisedValue(opdDetails.currentOPDUtilised);
                }
                modifyLineItem(item.id, modifications);
              }
            );
          }}
          disabled={shouldMinusBtnBeDisabled()}
        >
          <Minus
            color={
              !shouldMinusBtnBeDisabled()
                ? color.ROYAL_ORANGE
                : color.greys[100]
            }
          />
        </Button>
        <div style={{ display: "flex", height: "100%" }}>
          <input
            type="text"
            value={item.quantity}
            min="0"
            max={item.available_quantity ?? "100"}
            id={item.uiTrackingId + "picker"}
            ref={pickerRef}
            disabled={
              item.type === itemTypes.CONSULTATION ||
              item.type === itemTypes.LAB_TEST ||
              item.available_quantity === 0
            }
            className={classes.pickerInput}
            onChange={async (e) => {
              const { auth } = props;
              const updatedValue = e.target.value;
              if (
                parseInt(updatedValue) > 0 &&
                !isNaN(updatedValue) &&
                updatedValue !== ""
              ) {
                const updatedLineItem = {
                  external_id: String(item.external_id),
                  price: String(item.rate),
                  total_discount: String(item.discount.value),
                  total_discount_type: String(item.discount.type),
                  quantity: e.target.value,
                  metadata: {
                    display_name: item.name,
                    batch_id: item.batch_number,
                  },
                };
                let opdDetails;
                if (item?.opd_utilised === true) {
                  updatedLineItem.metadata.opd_utilised = true;
                  opdDetails = updateOpdAmountForLineItem(item, updatedLineItem);
                  updatedLineItem.metadata.opd_amount = opdDetails.currentItemOpdUsed;
                }
                const accessToken = auth.authDetails.data.access_token;
                modifyLineItemInOrder(
                  accessToken,
                  item.id,
                  updatedLineItem
                ).then((res) => {
                  const modifications = {
                    quantity: updatedValue
                  };
                  if (item?.opd_utilised === true) {
                    _.assign(modifications, { opd_amount: opdDetails.currentItemOpdUsed })
                    updateOPDUtilisedValue(opdDetails.currentOPDUtilised);
                  }
                  modifyLineItem(item.id, modifications);
                });
              }
            }}
          />
        </div>
        <Button
          className={classes.pickerBtn}
          onClick={async () => {
            const { auth } = props;
            const updatedLineItem = {
              external_id: String(item.external_id),
              price: String(item.rate),
              total_discount: String(item.discount.value),
              total_discount_type: String(item.discount.type),
              quantity: item.quantity + 1,
              metadata: {
                display_name: item.name,
                batch_id: item.batch_number,
              },
            };
            let opdDetails;
            if (item?.opd_utilised === true) {
              updatedLineItem.metadata.opd_utilised = true;
              opdDetails = updateOpdAmountForLineItem(item, updatedLineItem);
              updatedLineItem.metadata.opd_amount = opdDetails.currentItemOpdUsed;
            }
            const accessToken = auth.authDetails.data.access_token;
            modifyLineItemInOrder(accessToken, item.id, updatedLineItem)
              .then((res) => {
                const modifications = {
                  quantity: item.quantity + 1
                };
                if (item?.opd_utilised === true) {
                  _.assign(modifications, { opd_amount: opdDetails.currentItemOpdUsed })
                  updateOPDUtilisedValue(opdDetails.currentOPDUtilised);
                }
                modifyLineItem(item.id, modifications);
              })
              .catch((err) => {
                console.log(err);
              });
          }}
          disabled={shouldPlusBtnBeDisabled()}
        >
          <Plus
            color={
              !shouldPlusBtnBeDisabled() ? color.ROYAL_ORANGE : color.greys[100]
            }
          />
        </Button>
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => {
  const { consultationDetails, auth, familyDetails } = state;
  return { consultationDetails, auth, familyDetails };
};

const mapDispatchToProps = {
  modifyLineItem: consultationActions.modifyLineItem,
  removeLineItem: consultationActions.removeLineItem,
};
const StyledQuantityPicker = withStyles(useStyles, { withTheme: true })(
  QuantityPicker
);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledQuantityPicker);
