import * as Sentry from '@sentry/react';
import { familyActionTypes, errorActionTypes } from '../constants';
import {
    updateFamilyBusinessMapping, deleteFamilyBusinessMapping, searchBussinessesByName
} from '../clients/businessService';
import { sendCOI, deleteCOI} from '../clients/customerService';
import { LOG_OUT } from './types';
const { buildCustomError } = require('../common/utils');
const { StatusCodes: { UNAUTHORIZED } } = require('http-status-codes');


export const updateFamilyBusinessMappingEvent = (familyId, payload, updatedCustomerBussinesses) => {
    const request = () => ({ type: familyActionTypes.UPDATE_FAMILY_BUSINESS_MAPPING_REQUEST });
    const success = (payload) => (
        { type: familyActionTypes.UPDATE_FAMILY_BUSINESS_MAPPING_SUCCESS, payload }
    );
    const failure = () => ({ type: familyActionTypes.UPDATE_FAMILY_BUSINESS_MAPPING_FAILURE });
    const resetSession = () => ({ type: LOG_OUT });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
    return async (dispatch, getState) => {
        dispatch(request());
        try {
            const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
            await updateFamilyBusinessMapping(
                ACCESS_TOKEN, familyId, payload,
            );
            dispatch(success(updatedCustomerBussinesses));
        } catch (e) {
            const error = buildCustomError(e);
            if (e.response && e.response.status === UNAUTHORIZED) {
                dispatch(resetSession());
            } else {
                Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`), { extra: error.data });
                dispatch(failure());
                dispatch(setError(e));
            }
        }
    };
}

export const deleteFamilyBusinessMappingEvent = (familyId, businessId, updatedCustomerBussinesses) => {
    const request = () => ({ type: familyActionTypes.UPDATE_FAMILY_BUSINESS_MAPPING_REQUEST });
    const success = (payload) => (
        { type: familyActionTypes.UPDATE_FAMILY_BUSINESS_MAPPING_SUCCESS, payload }
    );
    const failure = () => ({ type: familyActionTypes.UPDATE_FAMILY_BUSINESS_MAPPING_FAILURE });
    const resetSession = () => ({ type: LOG_OUT });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
    return async (dispatch, getState) => {
        dispatch(request());
        try {
            const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
            await deleteFamilyBusinessMapping(
                ACCESS_TOKEN, familyId, businessId,
            );
            dispatch(success(updatedCustomerBussinesses));
        } catch (e) {
            const error = buildCustomError(e);
            if (e.response && e.response.status === UNAUTHORIZED) {
                dispatch(resetSession());
            } else {
                Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`), { extra: error.data });
                dispatch(failure());
                dispatch(setError(e));
            }
        }
    };
}

export const searchBusinessesEvent = (name) => {
    const request = () => ({ type: familyActionTypes.SEARCH_BUSINESS_BY_NAME_EVENT_REQUEST });
    const success = (payload) => (
        { type: familyActionTypes.SEARCH_BUSINESS_BY_NAME_EVENT_SUCCESS, payload }
    );
    const failure = () => ({ type: familyActionTypes.SEARCH_BUSINESS_BY_NAME_EVENT_FAILURE });
    const resetSession = () => ({ type: LOG_OUT });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
    return async (dispatch, getState) => {
        dispatch(request());
        try {
            const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
            const response = await searchBussinessesByName(
                ACCESS_TOKEN, name
            );
            dispatch(success(response));
        } catch (e) {
            const error = buildCustomError(e);
            if (e.response && e.response.status === UNAUTHORIZED) {
                dispatch(resetSession());
            } else {
                Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`), { extra: error.data });
                dispatch(failure());
                dispatch(setError(e));
            }
        }
    };
}

export const deleteCOIEvent = (subscriptionId) => {
    const request = () => ({ type: familyActionTypes.DELETE_COI_REQUEST });
    const success = (payload) => (
        { type: familyActionTypes.DELETE_COI_SUCCESS, payload }
    );
    const failure = () => ({ type: familyActionTypes.DELETE_COI_FAILURE });
    const resetSession = () => ({ type: LOG_OUT });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
    return async (dispatch, getState) => {
        dispatch(request());
        try {
            const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
            const response = await deleteCOI(
                ACCESS_TOKEN, subscriptionId
            );
            dispatch(success(response));
        } catch (e) {
            const error = buildCustomError(e);
            if (e.response && e.response.status === UNAUTHORIZED) {
                dispatch(resetSession());
            } else {
                Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`), { extra: error.data });
                dispatch(failure());
                dispatch(setError(e));
            }
        }
    };
}

export const sendCOIEvent = (subscriptionId, payload) => {
    const request = () => ({ type: familyActionTypes.SEND_COI_REQUEST });
    const success = (payload) => (
        { type: familyActionTypes.SEND_COI_SUCCESS, payload }
    );
    const failure = () => ({ type: familyActionTypes.SEND_COI_FAILURE });
    const resetSession = () => ({ type: LOG_OUT });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
    return async (dispatch, getState) => {
        dispatch(request());
        try {
            const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
            const response = await sendCOI(
                ACCESS_TOKEN, subscriptionId, payload
            );
            dispatch(success(response));
        } catch (e) {
            const error = buildCustomError(e);
            if (e.response && e.response.status === UNAUTHORIZED) {
                dispatch(resetSession());
            } else {
                Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`), { extra: error.data });
                dispatch(failure());
                dispatch(setError(e));
            }
        }
    };
}

export const updateSubscription = (subscription) => {
    const success = (payload) => ({
        type: familyActionTypes.UPDATE_FAMILY_SUBSCRIPTIONS,
        payload
    });
    return (dispatch) => {
        try {
            dispatch(success(subscription));
        } catch(err) {
            console.log(err)
        }
    }
}