import _ from 'lodash';
import * as Sentry from '@sentry/react';
import { getCityStateByZipCode, createNewAddress, updateAddress, getFamilyAddresses } from '../clients/addressService';
import { addressActionTypes } from '../constants/addressConstant';
import { authActionTypes } from '../constants/authConstant';
import { errorActionTypes } from '../constants/errorConstant';
const { StatusCodes: { UNAUTHORIZED, NOT_FOUND } } = require('http-status-codes');
const { buildCustomError } = require('../common/utils');

const getCityStateByZipCodeEvent = (zipcode) => {
    const request = () => ({ type: addressActionTypes.FIND_ADDRESS_BY_ZIPCODE_REQUEST });
    const success = (payload) => ({
        type: addressActionTypes.FIND_ADDRESS_BY_ZIPCODE_SUCCESS, payload
    });
    const failure = () => ({ type: addressActionTypes.FIND_ADDRESS_BY_ZIPCODE_FAILURE });
    const resetSession = () => ({ type: authActionTypes.RESET_SESSION });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
    return async (dispatch, getState) => {
        const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
        dispatch(request())
        try {
            const response = await getCityStateByZipCode(ACCESS_TOKEN, zipcode);
            dispatch(success(response.data))
        } catch (e) {
            const error = buildCustomError(e);
            if (e.response && e.response.status === NOT_FOUND) {
                dispatch(success({}))
            } else if (e.response && e.response.status === UNAUTHORIZED) {
                dispatch(resetSession());
            } else {
                Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`),
                    { extra: { errorData: error.data, function: 'getCityStateByZipCodeEvent', requestPayload: zipcode } });
                dispatch(failure());
                dispatch(setError(error.message));
            }
        }
    }
};

const addAddressEvent = (addressBody, oldAddresses) => {
    const request = () => ({ type: addressActionTypes.ADD_ADDRESS_REQUEST });
    const success = (payload) => ({
        type: addressActionTypes.ADD_ADDRESS_SUCCESS, payload
    });
    const failure = () => ({ type: addressActionTypes.ADD_ADDRESS_FAILURE });
    const resetSession = () => ({ type: authActionTypes.RESET_SESSION });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });

    return async (dispatch, getState) => {
        const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
        dispatch(request())
        try {
            const response = await createNewAddress(ACCESS_TOKEN, addressBody);
            _.assign(addressBody, response.data)
            oldAddresses.unshift(addressBody)
            dispatch(success(oldAddresses))
        } catch (e) {
            const error = buildCustomError(e);
            if (e.response && e.response.status === UNAUTHORIZED) {
                dispatch(resetSession());
            } else {
                Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`),
                { extra: { errorData: error.data, function: 'addAddressEvent', requestPayload: addressBody } });
                dispatch(failure())
                dispatch(setError(e))
            }
        }
    }
}

const updateAddressEvent = (addressBody, familyId, addressId, updatedAddresses) => {
    const request = () => ({ type: addressActionTypes.UPDATE_ADDRESS_REQUEST });
    const success = (payload) => ({
        type: addressActionTypes.UPDATE_ADDRESS_SUCCESS, payload
    });
    const failure = () => ({ type: addressActionTypes.UPDATE_ADDRESS_FAILURE });
    const resetSession = () => ({ type: authActionTypes.RESET_SESSION });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
    return async (dispatch, getState) => {
        const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
        dispatch(request())
        try {
            await updateAddress(ACCESS_TOKEN, addressBody, familyId, addressId);
            dispatch(success(updatedAddresses))
        } catch (e) {
            const error = buildCustomError(e);
            if (e.response && e.response.status === UNAUTHORIZED) {
                dispatch(resetSession());
            } else {
                Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`),
                { extra: { errorData: error.data, function: 'updateAddressEvent', requestPayload: addressBody } });
                dispatch(failure());
                dispatch(setError(e))
            }
        }
    }
}

const getFamilyAddressesEvent = (familyId) => {
    const request = () => ({ type: addressActionTypes.GET_ADDRESSES_REQUEST });
    const success = (payload) => ({
        type: addressActionTypes.GET_ADDRESSES_SUCCESS, payload
    });
    const failure = () => ({ type: addressActionTypes.GET_ADDRESSES_FAILURE });
    const resetSession = () => ({ type: authActionTypes.RESET_SESSION });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
    return async (dispatch, getState) => {
        const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
        dispatch(request())
        try {
            const response = await getFamilyAddresses(ACCESS_TOKEN, familyId);
            dispatch(success(response.data.addresses))
        } catch (e) {
            const error = buildCustomError(e);
            if (error.statusCode === UNAUTHORIZED) {
                dispatch(resetSession());
            }
            if (error.statusCode === NOT_FOUND) {
                dispatch(failure());
            } else {
                Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`),
                { extra: { errorData: error.data, function: 'getFamilyAddressesEvent', requestPayload: familyId } });
                dispatch(failure());
                dispatch(setError(error.message));
            }
        }
    };
}

const resetAddressByZipcodeEvent = () => {
    const request = () => ({ type: addressActionTypes.RESET_ZIPCODE });
    return async (dispatch) => {
        dispatch(request())
    }
};

const resetAddressesEvent = () => {
    const request = () => ({ type: addressActionTypes.RESET_ADDRESSES });
    return async (dispatch) => {
        dispatch(request())
    }
};

export {
    getCityStateByZipCodeEvent,
    addAddressEvent,
    resetAddressByZipcodeEvent,
    updateAddressEvent,
    getFamilyAddressesEvent,
    resetAddressesEvent,
}