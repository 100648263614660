export const color = {
    ROYAL_ORANGE: '#F38B44',
    ORANGE: '#ffa500',
    PEACH: '#FFE6B1',
    SUNSET_ORANGE: '#f35757',
    GREY: '#808080',
    WHITE: '#ffffff',
    SEASHELL: '#FFF5EE',
    BLACK: '#000000',
    ANTI_FLASH_WHITE: '#F2F2F2',
    DARK_GREY: '#172A3A',
    LIGHT_GREY: '#CCCCCC',
    LIGHT_GREY_TEXT: '#9D9D9D',
    LIGHT_GREY_BACKGROUND: '#F5F5F5',
    BORDER_GREY: '#cbcbcb',
    yellows: {
        100: '#FFE6B1'
    },
    oranges: {
        100: '#FFF1E8',
        200: '#FFA500',
        300: '#F38B44',
    },
    greens: {
        50: '#0C6A42',
        100: '#0D7E51',
        200: '#DBECE5',
        300: '#86BfA8',
    },
    greys: {
        100: '#808080',
        150: '#909090',
        200: '#7D7D7D',
        300: '#CCCCCC',
        400: '#E5E5E5',
        450: '#E5E7EB',
        500: '#F4F4F4'
    },
    reds: {
        100: "#BB2E2E",
        200: '#F3D9D9',
        300: '#F66A6A',
        400: '#FBD2E0',
        500: '#E91E63',
    },
    INTERNATIONAL_ORANGE: '#BB2E2E',
    SALEM: '#0D7E51',
    GHOST_WHITE: '#F9F9F9',
    BRIGHT_GRAY: '#E9E9E9',
    OLD_LACE: '#FFF1E8',
    CULTURED: '#F4F4F4',
    blues: {
        100: '#D3EAFD',
        200: '#2196F3',
        300: '#172A3A',
    },
}