import * as Sentry from '@sentry/react';
import {
    UPDATE_CUSTOMER_CREDIT,
    UPDATE_CUSTOMER_CREDIT_RESPONSE,
    RESET_UPDATE_CUSTOMER_CREDIT_FILE
} from './types'
import { updateCustomerCreditFile } from '../clients/customerCreditService'
import { errors } from '../common/errors'
const { buildCustomError } = require('../common/utils');

export const updateCustomerCredit = (emailId, file) => async (dispatch, getState) => {
    const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
    let response;
    let data;
    dispatch({ type: UPDATE_CUSTOMER_CREDIT })
    try {
        response = await updateCustomerCreditFile(ACCESS_TOKEN, emailId, file);
        data = response.status;
        dispatch({ type: UPDATE_CUSTOMER_CREDIT_RESPONSE, payload: data })
    } catch (e) {
        const error = buildCustomError(e);
        Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`),
        { extra: { errorData: error.data, function: 'updateCustomerCredit', requestPayload: emailId } });
        dispatch({ type: UPDATE_CUSTOMER_CREDIT_RESPONSE, payload: errors.ERROR_IN_UPDATING_CUSTOMER_CREDIT })
    }
};


export const resetUpdateCustomerCreditFile = () => (dispatch) => {
    dispatch({ type: RESET_UPDATE_CUSTOMER_CREDIT_FILE })
}