import { color } from '../../../constants/colors';
const styles = () => ({
    badge: {
        cursor: 'pointer',
        background: color.WHITE,
        height: '26px',
        width: '170px',
        textTransform: 'uppercase',
        borderRadius: '5px',
        fontSize: '12px',
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        color: color.BLACK,
        border: `1px solid ${color.BLACK}`
    },
    pending: {
        color: '#FF3333',
        border: '1px solid #ff3333',
    },
    allInvoicesCleared: {
        color: '#0D7E51',
        border: '1px solid #0D7E51',
    },
})

export default styles;