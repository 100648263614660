import { VERIFY_OTP, LOG_OUT, INITIATE_OTP, SET_CURRENT_CLINIC_LOCATION, EDIT_CURRENT_CLINIC_LOCATION } from '../actions/types';
import { authActionTypes } from '../constants';

const INITIAL_STATE = {
    isLoggedIn: false,
    phoneNumber: null,
    isNotAuthorizedRole: null,
};

const authReducer = (state = { INITIAL_STATE }, action) => {
    switch (action.type) {
        case INITIATE_OTP:
            return { ...state, phoneNumber: action.payload.mobile };
        case VERIFY_OTP:
            return { ...state, isLoggedIn: action.payload.isLoggedIn, authDetails: action.payload };
        case LOG_OUT:
            return { isLoggedIn: false, phoneNumber: null, invoicesOfSubscription: null };
        case SET_CURRENT_CLINIC_LOCATION:
            return { ...state, currentClinicLocation: action.payload }
        case EDIT_CURRENT_CLINIC_LOCATION:
            return { ...state, currentClinicLocation: action.payload }
        case authActionTypes.SET_AVAILABILITY_SUCCESS:
            return {
                ...state,
                authDetails: {
                    ...state.authDetails,
                    data: {
                        ...state.authDetails.data,
                        user: {
                            ...state.authDetails.data.user,
                            metadata: {
                                availability: !state.authDetails.data.user.metadata.availability,
                            },
                        }
                    }
                }
            }
            case authActionTypes.GET_AVAILABILITY_SUCCESS:
                return {
                    ...state,
                    authDetails: {
                        ...state.authDetails,
                        data: {
                            ...state.authDetails.data,
                            user: {
                                ...state.authDetails.data.user,
                                metadata: {
                                    availability: action.payload,
                                },
                            }
                        }
                    }
                }
        default:
            return state;
    }
};

export default authReducer;
