import { color } from '../../../constants/colors';
const styles = () => ({
    header: {
        display: 'flex',
        padding: '30px 24px 10px',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${color.ROYAL_ORANGE}`
    },
    bold: {
        fontWeight: 500
    },
    closeIcon: {
        cursor: 'pointer'
    },
    addressContainer: {
        padding: '24px'
    },
    addressBox: {
        display: 'flex',
        padding: '16px',
        backgroundColor: color.PEACH,
        borderRadius: '8px',
        justifyContent: 'space-between',
        marginBottom: '16px'
    },
    addressDetails: {
        flex: 0.75,
    },
    edit: {
        flex: 0.08,
        backgroundColor: color.ROYAL_ORANGE,
        textAlign: 'center',
        borderRadius: '5px',
        height: '20%',
        cursor: 'pointer'
    },
    tag: {
        textTransform: 'uppercase',
        color: color.GREY,
        marginBottom: '8px'
    },
    whiteColor: {
        color: color.WHITE
    },
    redColor: {
        color: color.SUNSET_ORANGE
    },
    royalOrangeColor: {
        color: color.ROYAL_ORANGE
    },
    addressActionButton: {
        backgroundColor: color.ROYAL_ORANGE,
        color: color.WHITE,
        width: '90%',
        position: 'absolute',
        bottom: '28px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: color.ROYAL_ORANGE,
        },
        '&:disabled': {
            opacity: '70%',
            color: color.WHITE,
        }
    },
    newAddressButton: {
        backgroundColor: color.ROYAL_ORANGE,
        color: color.WHITE,
        width: '100%',
        position: 'sticky',
        bottom: '28px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: color.ROYAL_ORANGE,
        },
        '&:disabled': {
            opacity: '70%',
            color: color.WHITE,
        }
    },
    formField: {
        width: '100%',
        marginTop: '16px'
    },
    pincodeContainer: {
        display: 'flex',
    },
    searchIcon: {
        display: 'flex',
        alignSelf: 'flex-end',
        padding: '8px',
        borderRadius: '50%',
        backgroundColor: color.ROYAL_ORANGE,
        cursor: 'pointer'
    },
    locationTagContainer: {
        marginTop: '36px'
    },
    locationTagHeading: {
        color: color.GREY
    },
    locationTags: {
        display: 'flex',
        marginTop: '8px',
    },
    tagNameButton: {
        borderRadius: '24px',
        color: color.GREY,
        textTransform: 'capitalize',
        marginRight: '16px'
    },
    tagNameButtonSelected: {
        backgroundColor: color.SEASHELL,
        borderColor: color.ROYAL_ORANGE,
        color: color.ROYAL_ORANGE,
    },
    mL10: {
        marginLeft: '5px'
    }

})

export default styles;