import React from 'react';
import Svg from '../../components/common/Svg/Svg';
import Path from '../../components/common/Svg/Path';

function TopRightArrow({ color = 'white', style }) {
	return (
		<Svg width="7px" height="8px" viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg' style={style}>
			<Path
                strokeWidth="10"
				d='M6.00171 2.207L1.69821 6.5105L0.991211 5.8035L5.29421 1.5H1.50171V0.5H7.00171V6H6.00171V2.207Z'
				fill={color}
			/>
		</Svg>
	);
}

export default TopRightArrow;
