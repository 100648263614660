import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as ClinikkLogo } from '../../../../images/clinikk_logo.svg';
import { ReactComponent as CareIcon } from '../../../../images/serviceProviders/Care_health_insurance_logo 3.svg';
import { ReactComponent as AdityaBirlaIcon } from '../../../../images/serviceProviders/aditya-birla.svg';
import { ReactComponent as ClinikkProviderIcon } from '../../../../images/serviceProviders/clinikk_full.svg';
import { ReactComponent as BhartiAxaIcon } from '../../../../images/serviceProviders/BhartiAXA.svg';
import { ReactComponent as MagmaIcon } from '../../../../images/serviceProviders/Magma.svg';
import { ReactComponent as ReligareIcon } from '../../../../images/serviceProviders/Religare.svg';
import  CardBackgroundGreen  from '../../../../images/serviceProviders/Accidental.svg';
import  CardBackgroundRed  from '../../../../images/serviceProviders/AccidentalRed.svg';
import { ReactComponent as NoActiveECards } from '../../../../images/NoActiveECards.svg';
import { fetchBenefitCardsByCustomerId, resetBenefitCardsByCustomerId } from '../../../../actions';

const { getISTFormat1 } = require('../../../../common/utils');
class ECard extends React.Component {

    state = { benefitCards: [] };


    componentDidMount() {
        this.props.resetBenefitCardsByCustomerId();
        this.props.fetchBenefitCardsByCustomerId(this.props.customerId);
    };
    componentWillReceiveProps(nextProps) {
        if (this.props.customerId !== nextProps.customerId) {
            this.props.resetBenefitCardsByCustomerId();
            this.props.fetchBenefitCardsByCustomerId(nextProps.customerId);
        }
    }

    serviceName = (service) => {
        switch (service) {
            case 'primary_care':
                return 'Primary Care'
            case 'accidental_insurance':
                return 'Accidental Insurance';
            case 'hospicash':
                return 'Hospicash';
            case 'health_insurance':
                return 'Health Insurance';
            default:
                return 'Primary Care'
        }
    }

    providerIconMap = (provider) => {
        switch (provider) {
            case ("Aditya Birla Health Insurance"):
                return <AdityaBirlaIcon style={{ float: 'right', marginRight: '1vh', width: '10vh', height: '4vh' }} />;
            case ("Magma"):
                return <MagmaIcon style={{ float: 'right', marginRight: '1vh', width: '10vh', height: '4vh' }} />;
            case ("Religare"):
                return <ReligareIcon style={{ float: 'right', marginRight: '1vh', width: '10vh', height: '4vh' }} />;
            case ("Bharti AXA"):
                return <BhartiAxaIcon style={{ float: 'right', marginRight: '1vh', width: '10vh', height: '4vh' }} />;
            case ("Clinikk Healthcare"):
                return <ClinikkProviderIcon style={{ marginTop: '0.2px', width: '10vh', height: '25px' }} />;
            default:
                return <CareIcon style={{marginTop: '0.2px', marginLeft: '8vh', width: '20vh', height: '35px'}} />;
        }
    };


    getCardColor = (service) => {
        switch (service) {
            case 'accidental_insurance':
                return `url(${CardBackgroundRed})` 
            case 'hospicash':
                return '#68cbf2';
            case 'health_insurance':
                return `url(${CardBackgroundGreen})`
            default:
                return `url(${CardBackgroundGreen})`
        }
    }


    renderCard(benefitCard) {
        return (
            <>
                <Card elevation={1} style={{ marginLeft: '2vh', marginRight: '25vh', minWidth: '50vh' }}>
                    <CardContent style={{ marginBottom: '2vh', minHeight: '0.7vh' }}>
                        <div style={{ float: 'left', marginLeft: '1vh', display: 'inline-block', alignItems: 'left' }}>
                            <ClinikkLogo />
                        </div>

                        <Typography style={{ float: 'left', display: 'inline-block', marginLeft: '1vh', fontSize: '1.8vh' }} color="textSecondary" gutterBottom>
                            {this.serviceName(benefitCard.type)}
                        </Typography>
                        <div style={{}}>
                            {this.providerIconMap(benefitCard.provider)}
                        </div>
                        </CardContent>

                    <CardContent style={{minHeight: '18vh', position: 'relative', zIndex: 100, backgroundImage: this.getCardColor(benefitCard.type), 
                    backgroundRepeat: "no-repeat", backgroundSize: "cover" }}> 
                        <Typography style={{ float: 'left', display: 'flex', color: 'white', fontWeight: 'bold' }}>Policy Number</Typography>
                        <Typography style={{ float: 'left', display: 'flex', clear: 'left', color: 'white', fontSize: '3vh', fontWeight: 'bold', }}>{benefitCard.policy_number ? benefitCard.policy_number : ''}</Typography>
                        <Typography style={{ marginTop: '2vh', float: 'left', fontWeight: 'bold', display: 'flex', clear: 'left', color: 'white' }}>Coverage*</Typography>
                        <Typography style={{ marginTop: '2vh', fontWeight: 'bold', float: 'right', display: 'flex', clear: 'right', color: 'white' }}>Start Date</Typography>
                        <Typography style={{ float: 'left', display: 'flex', clear: 'left', color: 'white' }}>{benefitCard.coverage ? '₹' + benefitCard.coverage : '-'}</Typography>
                        <Typography style={{ float: 'right', display: 'flex', clear: 'right', color: 'white' }}>{benefitCard.start_date ? getISTFormat1(benefitCard.start_date) : ''}</Typography>
                        <Typography style={{ marginTop: '2vh', float: 'left', display: 'flex', clear: 'left', color: 'white', fontSize: '1.5vh' }}>*Coverages will be distributed across members</Typography>
                    </CardContent>
                
                </Card>
                <br />
                <br />
            </>
        );
    }

    render() {
        if (this.props.error.benefitCardError) {
            return <div>Error while fetching Benefit Cards.</div>;
        } else if (!this.props.familyDetails.benefitCardDetails) {
            return (<div> <CircularProgress style={{ color: 'orange', marginLeft: '49%', marginRight: '49%', marginTop: '20vh' }} disableShrink /> </div>); 
        } else if (this.props.familyDetails.benefitCardDetails && _.isEmpty(this.props.familyDetails.benefitCardDetails.benefit_cards)) {
            return (
                <div>
                    <br />
                    <NoActiveECards style={{ height: '15vh', width: '20vh', marginLeft: '30vh' }} />
                    <Typography style={{
                        textAlign: 'left', fontFamily: 'Rubik',
                        fontWeight: 'normal',
                        fontStretch: 'normal',
                        fontStyle: 'italic', fontSize: '12px', marginLeft: '30vh', marginTop: '3vh', color: 'grey'
                    }}>No active E-cards with Clinikk</Typography>
                </div>
            );
        } else {
            return (
                _.map(this.props.familyDetails.benefitCardDetails.benefit_cards, (benefitCard) => {
                    return <div>{this.renderCard(benefitCard)}</div>
                })
            );
        }
    };
};

const mapStateToProps = (state) => {
    return { auth: state.auth, familyDetails: state.familyDetails, error: state.error };
}

export default connect(mapStateToProps, { fetchBenefitCardsByCustomerId, resetBenefitCardsByCustomerId })(ECard) ;
