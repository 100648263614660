import { color } from "../../../constants/colors";

const useStyles = (theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflow: 'scroll',
    },
    root: {
        '&:hover': {
            outline: 'none',
        },
        padding: '1.5vh',
    },
    outlined: {
        padding: '1.5vh',
    },
    select: {
        background: color.WHITE,
        color: color.ROYAL_ORANGE,
        border: `1px solid ${color.ROYAL_ORANGE}`,
        height: 'calc(34px - 1.5vh)',
        padding: '1.5vh',
        outline: '0px',
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        '&:hover': {
            border: `1px solid ${color.ROYAL_ORANGE}`,
            outline: '0'
        }
    },
    planNameContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '1.5vh',
        height: 'calc(34px - 1.5vh)',
        width: '40%',
        borderRadius: '4px',
        border: `1px solid ${color.LIGHT_GREY}`,
    },
    row: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        marginTop: '15px',
        maxHeight: '100%',
    },
    subRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '1vh',
        '&:first-child': {
            width: '5%',
            alignItems: 'baseline',
            // marginTop: '15px',
        },
        '&:not(:first-child)': {
            width: '95%',
            alignItems: 'baseline',
            // paddingTop: '15px',
        },
    },
    itemTypeHeader: {
        padding: '1vh',
        background: color.oranges[100],
        fontWeight: 'bold',
        color: color.greys[100],
        fontSize: '12px',
        letterSpacing: '0.8px',
        width: 'calc(100% - 7vh)',
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: '6vh',
    },
    deleteIcon: {
        padding: '1vh 0vh',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
    },
    col: {
        display: 'flex',
        flexDirection: 'column',
        '&:first-child': {
            alignItems: 'flex-start',
            width: '25%',
            marginRight: '1vh',
        },
        '&:not(:first-child)': {
            width: 'calc(75% * 1/7)',
            alignItems: 'flex-end',
            justifyContent: 'baseline',
            maxHeight: '110%',
            marginRight: '2vh',
        },
        '&:nth-child(2)': {
            width: 'calc(75% * 1/7)',
            alignItems: 'flex-end',
            justifyContent: 'baseline',
            maxHeight: '110%',
            marginRight: '2vh',
        },
    },
    spl_col: {
        '&:first-child': {
            width: '30%',
            marginRight: '2vh',

        },
        display: 'flex',
        flexDirection: 'row',
        width: 'calc(70% * 1/5)',
        alignItems: 'baseline',
        maxHeight: '110%',
        textAlign: 'right',
        marginRight: '2vh',
        justifyContent: 'flex-end',
    },
    header: {
        borderBottom: `2px solid ${color.LIGHT_GREY}`,
        fontFamily: 'Rubik',
        color: color.LIGHT_GREY_TEXT,
        fontSize: '12px',
        fontWeight: '400',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    menuOption: {
        fontFamily: 'Rubik',
        fontWeight: 'normal',
        height: '50px',
    },
    productTable: {
        marginTop: '2vh',
        fontFamily: 'Rubik',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxHeight: '100%',
        clear: 'both',
        '&:not(:first-child)': {
            marginTop: '15px',
        },
    },
    footerRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '2vh 0px',
        gap: '1vh',
        marginTop: '0px',
    },
    textField: {
        fontFamily: 'Rubik',
    },
    tableRowAlert: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '0.5rem',
        alignItems: 'center',
        maxHeight: '100%',
        height: '60px',
        clear: 'both',
        marginTop: '1rem',
        '&:not(:first-child)': {
            marginTop: '15px',
        },
        background: color.blues[100],
        borderRadius: '12px',
    },
    alertContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        padding: '0px 0.5rem',
        position: 'relative',
        '&:before': {
            content: 'attr(data-tooltip)',
            borderLeft: '20px solid transparent',
            borderBottom: `20px solid ${color.blues[100]}`,
            borderRight: '20px solid transparent',
            position: 'absolute',
            // margin: '-10px',
            bottom: '100%',
            left: '54%',
        }
    },
    alertIconAndTextGroup: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        alignItems: 'center',
        padding: '0.5rem 0.5px 0,5px 0px',
    },
    alertTextGroup: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-around',
        // padding: '1rem 0px',
        color: color.blues[200],
        fontSize: '12px',
        '& p': {
            margin: '0px',
            display: 'block',
        },
        '& span': {
            background: color.WHITE,
            borderRadius: '4px',
            padding: '4px',
        }
    },
    cautionIcon: {
        height: '40px',
        width: '40px',
        marginRight: '0.5rem',
    },
    smallCautionIcon: {
        position: 'absolute',
        left: 'calc(100% + 5px)',
        top: '15%',
        height: '15px',
        width: '15px',
        padding: '4px',
        // marginLeft: '0.8rem',
        cursor: 'pointer',
        borderRadius: '4px',
        background: color.WHITE,
        '&:hover': {
            background: color.blues[100],
        }

    },
    dismissBtn: {
        minHeight: '25px',
        minWidth: '84px',
        // padding: '0.5rem',
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        background: color.blues[200],
        textTransform: 'none',
        fontSize: '11px',
        color: color.WHITE,
        borderRadius: '5px',
        '&:hover': {
            background: color.blues[300],
        }
    },
    pickerBtn: {
        minHeight: '20px',
        maxHeight: '20px',
        minWidth: '18px',
        maxWidth: '18px',
        margin: '2px',
        padding: '4px',
        background: color.oranges[100],
        '&:disabled': {
            background: color.greys[400],
          }
    },
    pickerInput: {
        height: '30px',
        width: '100%',
        padding: '0',
        border: '0px',
        textAlign: 'center',
        fontFamily: 'Rubik',
        borderBottom: `none`,
        '&::-webkit-outer-spin-button': {
            opacity: 1,
        },
        '&::-webkit-inner-spin-button': {
            opacity: 1,
        },
    },
    picker: {
        margin: '0',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0px 2px',
        height: '30px',
        width: '90px',
        border: `1px solid ${color.BORDER_GREY}`,
        borderRadius: '4px',
    },
    pickerContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative',
    },
    addProductBtn: {
        color: color.WHITE,
        textTransform: 'capitalize',
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        background: color.ROYAL_ORANGE,
        height: '25px',
        width: 'max-content',
        fontSize: '11px',
        padding: '1vh 2vh',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
            background: color.oranges[200],
        }
    },
    openEvitalRxBtn: {
        color: color.WHITE,
        textTransform: 'none',
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        background: color.ROYAL_ORANGE,
        height: '25px',
        width: 'max-content',
        fontSize: '11px',
        padding: '1vh 2vh',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
            background: color.oranges[200],
        }
    },
    syncEvitalRxBtn: {
        color: color.WHITE,
        textTransform: 'none',
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        background: color.greens[50],
        height: '25px',
        width: 'max-content',
        fontSize: '11px',
        padding: '1vh 2vh',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
            background: color.greens[100],
        },
        '&:disabled': {
            color: color.WHITE,
            background: color.greens[300],
        }
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '1vh',
        background: `${color.WHITE}00`
    },
    disabledSelect: {
        color: color.LIGHT_GREY_TEXT,
        border: `1px solid ${color.BORDER_GREY}`,
        '&:hover': {
            border: `1px solid ${color.BORDER_GREY}`,
            outline: '0'
        }
    },
    autocompleteInput: {
        width: '100%',
        padding: '0px',
        border: '0px',
        textAlign: 'right',
        fontFamily: 'Rubik !important',
        borderBottom: `none`,
        height: '100% !important',
        '& input': {
            width: '100%',
            height: '100%'
        },
    },
    autoCompleteRoot: {
        padding: '0px',
        height: '100%',
        fontFamily: 'Rubik !important',
    },
    option: {
        fontFamily: 'Rubik',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    optionCategory: {
        fontSize: '12px',
        textTransform: 'capitalize',
        color: color.LIGHT_GREY,
    },
      disabledCheckbox: {
        pointerEvents: 'none',
        '& .MuiCheckbox-root.Mui-disabled': {
          cursor: 'not-allowed',
        },
      },
      checkboxContainer: {
        position: 'relative',
        '&:hover::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          cursor: 'not-allowed',
          backgroundColor: 'transparent',
        }
      },
    
});

export default useStyles;