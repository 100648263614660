import React from "react";
import styled from "styled-components";
const StyledSvg = styled.svg``;
function Svg({ children, height, viewBox, width, fill, ...props }) {
  return (
    <StyledSvg
      height={height}
      width={width}
      viewBox={viewBox}
      fill={fill}
      {...props}
    >
      {children}
    </StyledSvg>
  )   
}

export default Svg;