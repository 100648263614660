import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ Component, isCurrentClinicSet, ...rest }) => {
  return (
  <Route {...rest} render={props => (
    isCurrentClinicSet
      ? <Component {...props} />
      : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    )} 
  />
  );
}
