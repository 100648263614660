import { GET_CUSTOMER_DETAILS, FETCH_BENEFIT_CARDS_BY_CUSTOMER_ID,GET_SUBSCRIPTION_BY_SUBSCRIPTION_ID_SUCCESS,
    GET_SUBSCRIPTION_BY_SUBSCRIPTION_ID_FAILED, SET_CUSTOMER_PHONE_NUMBER, GET_CREDIT_DETAILS_BY_FAMILY_ID,
    GET_NACH_DETAILS_BY_FAMILY_ID, LOG_OUT, RESET_OLD_FAMILY_DETAILS, ADD_NEW_MEMBER_BY_FAMILY_ID, RESET_NEW_MEMBER_ADDED,
    UPDATE_PRIMARY_NUMBER, RESET_UPDATE_PRIMARY_NUMBER_STATUS, UPDATE_CUSTOMER_BY_CUSTOMER_ID, RESET_UPDATE_MEMBER, CLOSE_MEMBER_DRAWER,
    OPEN_MEMBER_DRAWER, CANCEL_MANDATE_BY_MANDATE_ID, RESET_CANCEL_MANDATE_STATUS,GET_SUBSCRIPTION_BY_SUBSCRIPTION_ID_REQUEST, CANCEL_SUBSCRIPTION_BY_ID, 
    RESET_CANCEL_WITHDRAW_SUBSCRIPTION_STATUS, WITHDRAW_SUBSCRIPTION_BY_ID, GET_INVOICES_BY_SUBSCRIPTION_ID, RESET_INVOICE_DETAILS, GET_ORDER_BY_ID, RESET_ORDER_DETAILS,
    OPEN_VIEW_DETAILS, CLOSE_VIEW_DETAILS, OPEN_BOOK_CONSULTATION_DRAWER, CLOSE_BOOK_CONSULTATION_DRAWER, RESET_BENEFIT_CARDS_BY_ID, RESET_CUSTOMER_PHONE_NUMBER,
    UPDATE_COHORT_REQUEST, UPDATE_COHORT_SUCCESS, UPDATE_COHORT_FAILURE, GET_OPD_BALANCE, SELECT_COHORT, GET_BILLING_PREF_REQUEST, GET_BILLING_PREF_SUCCESS, 
    GET_BILLING_PREF_FAILURE, UPDATE_WHATSAPP_CONSENT_REQUEST, UPDATE_WHATSAPP_CONSENT_SUCCESS, UPDATE_WHATSAPP_CONSENT_FAILURE,
    GET_INVOICES_BY_FAMILY_ID_REQUEST, GET_INVOICES_BY_FAMILY_ID_SUCCESS, GET_INVOICES_BY_FAMILY_ID_FAILURE, UPDATE_TNC_REQUEST, UPDATE_TNC_SUCCESS,
    UPDATE_TNC_FAILURE, RESET_TNC, FETCH_HEALTH_DECLARATION_TNC_FAILURE, FETCH_HEALTH_DECLARATION_TNC_REQUEST, FETCH_HEALTH_DECLARATION_TNC_SUCCESS, FETCH_PRODUCTS_BY_BUSINESS_ID, RESET_PRODUCTS, SEND_PLAN_CHANGE, RESET_PLAN_CHANGE, GET_COUPON_DISCOUNT, RESET_COUPON, RESET_COUPON_VALUE, RESET_OPD_BALANCE, GET_OPD_TRANSACTIONS, RESET_OPD_TRANSACTIONS, OPEN_VISIT_BOOK_DRAWER, CLOSE_VISIT_BOOK_DRAWER, UPDATE_ALTERNATE_NUMBER, RESET_UPDATE_ALTERNATE_NUMBER_STATUS,
} from '../actions/types';

import { familyActionTypes } from '../constants'
const INITIAL_STATE = {
    invoicesOfSubscription: null,
    whatsAppConsentApiStatus: {},
    invoicesByFamilyId: null,
};

const familyDetailsReducer = (state = { INITIAL_STATE }, action) => {
    switch (action.type) {
        case GET_CUSTOMER_DETAILS: {
            const { subscriptions } = action.payload?.allSubscriptions;
            if (subscriptions) {
                // iterate over all subs and modify their member_config;
                subscriptions.forEach((subscription) => {
                    const { member_config: memberConfig } = subscription;
                    if ((subscription.version && subscription.version === 1 && memberConfig)) {
                        const newConstraints = {
                            adult: memberConfig.adult,
                            child: 0,
                        };
                        if (memberConfig.hasOwnProperty("child") && memberConfig.child >= 0) {
                            newConstraints.child = memberConfig.child;
                        }
                        newConstraints.total_max = newConstraints.adult + newConstraints.child;
                        subscription.product.constraint.allowed_members = newConstraints;
                    }
                  });
                // modify the action payload;
                action.payload.allSubscriptions.subscriptions = subscriptions;
            }
            return { ...state, currentCustomer: action.payload.currentCustomer, allSubscriptions: action.payload.allSubscriptions, allMembers: action.payload.allMembers, bookConsultationDrawerStatus: false, searchBusinesses: [], visitBookDrawerStatus: false }
        }
        case FETCH_BENEFIT_CARDS_BY_CUSTOMER_ID:
            return { ...state, benefitCardDetails: action.payload };
        case FETCH_PRODUCTS_BY_BUSINESS_ID:
            return { ...state, productDetails: action.payload };
        case RESET_BENEFIT_CARDS_BY_ID:
            return { ...state, benefitCardDetails: null }
        case RESET_PRODUCTS:
            return { ...state, productDetails: null }
        case RESET_PLAN_CHANGE:
            return { ...state, planChange: null }
        case SEND_PLAN_CHANGE:
            return {...state, planChange: action.payload}
        case GET_COUPON_DISCOUNT:
            return {...state, couponDiscount: action.payload}
        case GET_OPD_BALANCE:
            return {...state, opdAmounts: action.payload}
        case GET_OPD_TRANSACTIONS:
            var updatedTransactions;
            if (state.opdTransactions && state.opdTransactions.data) {
                const list = [...state.opdTransactions.data.opdDetails.transaction, ...action.payload.data.opdDetails.transaction];
                updatedTransactions = {
                    data: {
                        opdDetails: {
                            available_amount: action.payload.data.opdDetails.available_amount,
                            total_limit: action.payload.data.opdDetails.total_limit,
                            used_amount: action.payload.data.opdDetails.used_amount,
                            transaction: list
                        },
                        total_count: action.payload.data.total_count,
                    }
                };
            } else {
                updatedTransactions = action.payload;
            }
            return {...state, opdTransactions: updatedTransactions}
        case RESET_OPD_BALANCE:
            return {...state, opdAmounts: null}
        case RESET_OPD_TRANSACTIONS:
            return {...state, opdTransactions: null}
        case RESET_COUPON_VALUE:
            return {...state, couponDiscount: null}
        case SET_CUSTOMER_PHONE_NUMBER:
            return { ...state, customerPhoneNumber: action.payload };
        case RESET_CUSTOMER_PHONE_NUMBER:
            return { ...state, customerPhoneNumber: action.payload };
        case GET_NACH_DETAILS_BY_FAMILY_ID:
            return { ...state, nachDetail: action.payload };
        case GET_CREDIT_DETAILS_BY_FAMILY_ID:
            return { ...state, creditDetail: action.payload };
        case LOG_OUT:
            return { isLoggedIn: false, phoneNumber: null, currentCustomer: null, allSubscriptions: null, allMembers: null, benefitCardDetails: null, customerPhoneNumber: null, nachDetail: null, creditDetail: null };
        case RESET_OLD_FAMILY_DETAILS:
            return { ...state, currentCustomer: null, allSubscriptions: null, allMembers: null, customerPhoneNumber: null, benefitCardDetails: null, nachDetail: null, creditDetail: null }
        case ADD_NEW_MEMBER_BY_FAMILY_ID:
            return { ...state, newMember: action.payload };
        case RESET_NEW_MEMBER_ADDED:
            return { ...state, newMember: null }
        case UPDATE_PRIMARY_NUMBER:
            return { ...state, updateRmnStatus: action.payload }
        case UPDATE_ALTERNATE_NUMBER:
            return { ...state, updateAlternateNumberStatus: action.payload }
        case RESET_UPDATE_PRIMARY_NUMBER_STATUS:
            return { ...state, updateRmnStatus: null }
        case RESET_UPDATE_ALTERNATE_NUMBER_STATUS:
            return { ...state, updateAlternateNumberStatus: null }
        case UPDATE_CUSTOMER_BY_CUSTOMER_ID:
            return { ...state, updateMemberStatus: action.payload }
        case RESET_UPDATE_MEMBER: {
            return { ...state, updateMemberStatus: null }
        }
        case CLOSE_MEMBER_DRAWER: {
            return { ...state, updateMemberDrawerStatus: false }
        }
        case OPEN_MEMBER_DRAWER: {
            return { ...state, updateMemberDrawerStatus: true }
        }
        case CANCEL_MANDATE_BY_MANDATE_ID: {
            return { ...state, cancelMandateStatus: action.payload }
        }
        case RESET_CANCEL_MANDATE_STATUS: {
            return { ...state, cancelMandateStatus: null }
        }
        case CANCEL_SUBSCRIPTION_BY_ID: {
            return { ...state, cancelSubscriptionStatus: action.payload }
        }
        case WITHDRAW_SUBSCRIPTION_BY_ID: {
            return { ...state, withdrawSubscriptionStatus: action.payload }
        }
        case RESET_CANCEL_WITHDRAW_SUBSCRIPTION_STATUS: {
            return { ...state, cancelSubscriptionStatus: null, withdrawSubscriptionStatus: null }
        }
        case GET_INVOICES_BY_SUBSCRIPTION_ID: {
            return { ...state, invoicesOfSubscription: action.payload.data }
        }
        case RESET_INVOICE_DETAILS: {
            return { ...state, invoicesOfSubscription: null };
        }
        case GET_ORDER_BY_ID: {
            return { ...state, orderDetails: action.payload };
        }
        case RESET_ORDER_DETAILS: {
            return { ...state, orderDetails: null };
        }
        case CLOSE_VIEW_DETAILS: {
            return { ...state, viewDetailsPressed: false }
        }
        case OPEN_VIEW_DETAILS: {
            return { ...state, viewDetailsPressed: true, currentOrderId: action.payload }
        }
        case OPEN_BOOK_CONSULTATION_DRAWER: {
            return { ...state, bookConsultationDrawerStatus: true }
        }
        case CLOSE_BOOK_CONSULTATION_DRAWER: {
            return { ...state, bookConsultationDrawerStatus: false }
        }
        case OPEN_VISIT_BOOK_DRAWER: {
            return { ...state, visitBookDrawerStatus: true }
        }
        case CLOSE_VISIT_BOOK_DRAWER: {
            return { ...state, visitBookDrawerStatus: false }
        }
        case UPDATE_COHORT_REQUEST:
            return {
              ...state,
              cohortStatus: {
                isLoading: true,
              },
            };
        case UPDATE_COHORT_SUCCESS:
            return {
              ...state,
              cohortStatus: {
                isLoading: false,
                status: action.payload.subscriptionId,
                responseStatus: true,
              },
              billingPrefState: {
                isLoading: false,
                billingPrefResponse: {
                    ...state.billingPrefState.billingPrefResponse,
                    preferred_cohort: action.payload.cohortValue
                },
                responseStatus: true
            },
              selectedCohort: action.payload.cohortValue
            };
        case UPDATE_COHORT_FAILURE:
            return {
              ...state,
              cohortStatus: {
                isLoading: false,
                responseStatus: false,
              },
            };
        case UPDATE_TNC_REQUEST:
            return {
                ...state,
                tncDetails: {
                  isLoading: true,
                },
              };
        case FETCH_HEALTH_DECLARATION_TNC_REQUEST:
        return {
            ...state,
            isLoading: true,
        }
        case FETCH_HEALTH_DECLARATION_TNC_SUCCESS:
        return {
            ...state,
            isLoading: false,
            healthConditionsTnCData: action.payload,
        }
        case FETCH_HEALTH_DECLARATION_TNC_FAILURE:
        return {
            ...state,
            isLoading: false,
        }
        case UPDATE_TNC_SUCCESS: {
            const { subscriptionId, tncStatus, comments } = action.payload;
            const listWithUpdatedSubscription = [...state.allSubscriptions.subscriptions];
            const indexToUpdate = listWithUpdatedSubscription.findIndex(subscription => subscription.id === subscriptionId);
            listWithUpdatedSubscription[indexToUpdate].tncStatus = tncStatus;
            return {
                ...state,
                allSubscriptions: {
                    ...state.allSubscriptions,
                    subscriptions: listWithUpdatedSubscription,
                },
                tncDetails: {
                    isLoading: false,
                    accepted: tncStatus,
                    comment: comments,
                  },
            };
        }
        case UPDATE_TNC_FAILURE:
            return {
                ...state,
                tncDetails: {
                    isLoading: false,
                },
            };
        case RESET_TNC:
            return {
                ...state,
                tncDetails: null,
            };
        
        case GET_SUBSCRIPTION_BY_SUBSCRIPTION_ID_REQUEST:
            return {
              ...state,
              subscriptionById: {
                isLoading: true,
              },
            };
        case GET_SUBSCRIPTION_BY_SUBSCRIPTION_ID_SUCCESS: {
            const { subscription: updatedSubscription } = action.payload;
            const updatedSubscriptionList = [...state.allSubscriptions.subscriptions];
            const subscriptionIndex = updatedSubscriptionList
                .findIndex(subscription => subscription.id === updatedSubscription.id);
            if (subscriptionIndex !== -1) {
                updatedSubscriptionList[subscriptionIndex] = updatedSubscription;
            }
            return {
              ...state,
              subscriptionById: {
                isLoading: false,
                selectedSubscription: action.payload,
              },
              allSubscriptions: {
                subscriptions: updatedSubscriptionList
              },
            };
        }
        case GET_SUBSCRIPTION_BY_SUBSCRIPTION_ID_FAILED:
            return {
              ...state,
              subscriptionById: {
                isLoading: false,
              },
            };
        case SELECT_COHORT:
            return {
                ...state,
                selectedCohort: action.payload,
            };
        case GET_BILLING_PREF_REQUEST:
            return {
                ...state,
                billingPrefState: {
                    isLoading: true
                }
            };
        case GET_BILLING_PREF_SUCCESS:
            return {
                ...state,
                billingPrefState: {
                    isLoading: false,
                    billingPrefResponse: action.payload,
                    responseStatus: true
                },
                selectedCohort: action.payload.preferred_cohort,
            };
        case GET_BILLING_PREF_FAILURE:
            return {
                ...state,
                billingPrefState: {
                    isLoading: false,
                    responseStatus: false
                },
                selectedCohort: 0,
            };
        case UPDATE_WHATSAPP_CONSENT_REQUEST:
            return {
                ...state,
                INITIAL_STATE: {
                    ...state.INITIAL_STATE,
                    whatsAppConsentApiStatus: {
                        isLoading: true,
                    }
                }
            }
        case UPDATE_WHATSAPP_CONSENT_SUCCESS:
            return {
                ...state,
                currentCustomer: {
                    ...state.currentCustomer,
                    preferences: {
                        ...state.currentCustomer.preferences,
                       whatsapp: {
                        consent: action.payload.consent,
                        phone: action.payload.phone,
                       }
                    } 
                },
                INITIAL_STATE: {
                    ...state.INITIAL_STATE,
                    whatsAppConsentApiStatus: {
                        isLoading: false,
                        apiStatus: 'success',
                    }
                }
            };
        case UPDATE_WHATSAPP_CONSENT_FAILURE:
            return {
                ...state,
                INITIAL_STATE: {
                    ...state.INITIAL_STATE,
                    whatsAppConsentApiStatus: {
                        isLoading: false,
                        apiStatus: 'failed',
                    }
                }
            }
        case GET_INVOICES_BY_FAMILY_ID_REQUEST:
            return {
                ...state,
                invoicesByFamilyId: {
                    isLoading: true,
                    data: null,
                    apiStatus: null,
                },
            }
        case GET_INVOICES_BY_FAMILY_ID_SUCCESS:
            return {
                ...state,
                invoicesByFamilyId: {
                    isLoading: false,
                    data: action.payload,
                    apiStatus: 'success'
                },
            }
        case GET_INVOICES_BY_FAMILY_ID_FAILURE:
            return {
                ...state,
                invoicesByFamilyId: {
                    isLoading: false,
                    data: null,
                    apiStatus: 'failed',
                },
            }
        case familyActionTypes.ADD_METADATA_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case familyActionTypes.ADD_METADATA_SUCCESS:
            return {
                ...state,
                allMembers: {
                    ...state.allMembers,
                    members: action.payload
                },
                isLoading: false
            }
        case familyActionTypes.ADD_METADATA_FAILURE:
            return {
                ...state,
                isLoading: false
            }
        case familyActionTypes.SEARCH_BUSINESS_BY_NAME_EVENT_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case familyActionTypes.SEARCH_BUSINESS_BY_NAME_EVENT_SUCCESS:
            return {
                ...state,
                searchBusinesses: action.payload,
                isLoading: false
            }
        case familyActionTypes.SEARCH_BUSINESS_BY_NAME_EVENT_FAILURE:
            return {
                ...state,
                isLoading: false
            }
        case familyActionTypes.UPDATE_FAMILY_BUSINESS_MAPPING_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case familyActionTypes.UPDATE_FAMILY_BUSINESS_MAPPING_SUCCESS:
            return {
                ...state,
                currentCustomer: {
                    ...state.currentCustomer,
                    businesses: action.payload
                },
                isLoading: false
            }
        case familyActionTypes.UPDATE_FAMILY_BUSINESS_MAPPING_FAILURE:
            return {
                ...state,
                isLoading: false
            }
        case familyActionTypes.DELETE_FAMILY_BUSINESS_MAPPING_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case familyActionTypes.DELETE_FAMILY_BUSINESS_MAPPING_SUCCESS:
            return {
                ...state,
                currentCustomer: {
                    ...state.currentCustomer,
                    businesses: action.payload
                },
                isLoading: false
            }
        case familyActionTypes.DELETE_FAMILY_BUSINESS_MAPPING_FAILURE:
            return {
                ...state,
                isLoading: false
            }
        case familyActionTypes.UPDATE_FAMILY_SUBSCRIPTIONS: {
            return {
                ...state,
                allSubscriptions: {
                    subscriptions: state.allSubscriptions.subscriptions.concat(action.payload.subscriptions),
                    page: action.payload.page,
                }
            }
        }
        default:
            return state;
    }
};

export default familyDetailsReducer;

