
const styles = theme => ({
    card: {
        padding: theme.spacing(2),
        borderRadius: "5px",
        boxShadow: "0 0 4px 0 #e5e5e5",
        backgroundColor: "#ffffff",
        display: "flex",
        width: '25vw',
        flexDirection: "column",
    },
    addRecepients: {
        fontFamily: 'Rubik',
        fontSize: 13,
        color: '#172a3a',
        marginTop: 20,
        marginBottom: 20
    },
    cardDivider: {
        height: '2px', width: '100%',
        backgroundColor: '#e5e5e5',
        color: '#ffffff',
        alignSelf: 'center',
        margin: "10px 0"
    },
    title: {
        fontFamily: 'Rubik',
        fontSize: 16,
        fontWeight: 500,
        color: '#172a3a',
    },
    uploadButton: {
        marginTop: 20,
        textTransform: 'capitalize',
        backgroundColor: '#f38b44',
        color: '#ffffff',
    },
    inputFile: {
        color: '#f38b44',
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: 6,
        backgroundColor: '#ffffff',
        boxShadow: 'none',
        border: 'none',
        outline: 'none',
        fontSize: 16,
        fontFamily: 'Roboto',
    },
    deleteUploadedFileButton: {
        marginTop: '28px',
    },
    fileStatusBox: {
        display: 'flex',
        margin: '20px 0',
        flexDirection: 'row',
    },
    emailChips: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        maxWidth: '100%',
    },
    emailChip: {
        margin: "5px",
        color: '#172a3a',
        fontFamily: 'Rubik',
        maxWidth: '100%',
    },
    radio: {
        '&$checked': {
            color: '#f38b44' 
        }
    },
    checked: {}
})

export default styles;