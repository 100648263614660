import React, { useState, useEffect, useCallback } from 'react';
import { withStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import config from '../../../../config/index';
import axios from 'axios';
import useStyles from './DocumentTableStyles';
import { buildCustomError } from '../../../../common/utils';
import moment from 'moment';
import FileUpload from '../../../common/FileUpload/FileUpload';
import TriggerDrawer from '../../../common/TriggerDrawer/TriggerDrawer';
import { healthVaultRecordTypesMap } from '../../../../constants';


function DocumentTable({ customerId, classes }) {
	const auth = useSelector((state) => state.auth);
	const [documents, setDocuments] = useState([]);
	const [loading, setLoading] = useState(false);
	const accessToken = auth.authDetails.data.access_token;

	const fetchHealthVaultDocs = useCallback(
		(id) => {
			const url = `${config.apiURL}/v1/operations/health-vault?customerId=${id}&status=active`;
			const options = {
				headers: {
					authorization: `Bearer ${accessToken}`,
				},
			};
			setLoading(true);
			axios
				.get(url, options)
				.then((res) => {
					if (res.data) {
						const formattedArray = res.data.map((doc) => ({
							...doc,
							createdAt: moment(doc.created_at).valueOf(),
							documentCreatedAt: doc.document_created_at ? moment(doc.document_created_at).valueOf() : null,
						}));
						setDocuments(formattedArray ?? []);
					}
					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
					const error = buildCustomError(err);
					console.log(error);
				});
		},
		[accessToken]
	);

	useEffect(() => {
		fetchHealthVaultDocs(customerId);
	}, [accessToken, customerId, fetchHealthVaultDocs]);

	return (
		<>
			{loading ? <div className={classes.emptyContainer}>Loading...</div> : null}
			{documents.length === 0 && !loading ? (
				<div className={classes.emptyContainer}>No documents found for this user!</div>
			) : null}
			{documents.length > 0 && !loading ? (
				<div className={classes.table}>
					<div className={classes.tableHeader}>
						<div className={classes.headerCol}>File Type</div>
						<div className={classes.headerCol}>Date Added</div>
						<div className={classes.headerCol}>Date Created</div>
						<div className={classes.headerCol}>Link</div>
					</div>
					<div className={classes.tableBody}>
						{documents
							.sort((a, b) => moment(b.createdAt).diff(a.createdAt))
							.map((doc) => {
								const createdAtDate = moment(doc.createdAt).format('Do MMM YYYY');
								const documentCreatedAtDate = doc?.documentCreatedAt
									? moment(doc?.documentCreatedAt).format('Do MMM YYYY')
									: 'Unknown';
								const category = healthVaultRecordTypesMap.get(doc.category);
								return (
									<div className={classes.row}>
										<div className={classes.col}>
											<p className={classes.colContent}>{category}</p>
										</div>
										<div className={classes.col}>
											<p className={classes.colContent}>
												{createdAtDate}
											</p>
										</div>
										<div className={classes.col}>
											<p className={classes.colContent}>{documentCreatedAtDate}</p>
										</div>
										<div className={classes.col}>
											<a
												href={`${config.apiURL}/v1/health-vault/${doc.id ??
													doc._id}/download`}
												target='_blank'
												rel='noopener noreferrer'
												className={classes.link}
											>
												Open ↗
											</a>
										</div>
									</div>
								);
							})}
					</div>
				</div>
			) : null}
			<TriggerDrawer.Drawer
				anchor='right'
				PaperProps={{
					classes: {
						root: classes.drawerRoot,
						rounded: classes.drawerRoot,
					},
					square: false,
					elevation: 6,
					style: {
						width: '50vh',
						height: '100%',
						borderRadius: '8px 0px 0px 8px',
					},
				}}
				classes={classes}
			>
				<FileUpload
					customerId={customerId}
					onSubmit={(doc) => {
						const updatedDocuments = [...documents];
						updatedDocuments.unshift(doc);
						setDocuments(updatedDocuments);
					}}
				/>
			</TriggerDrawer.Drawer>
		</>
	);
}

const styleWrapper = withStyles(useStyles, { withTheme: true });
export default styleWrapper(DocumentTable);
