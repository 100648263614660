import React from 'react';
import _ from 'lodash';

import { connect } from 'react-redux';
import { TextField, Divider, Typography, Button, MenuItem } from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { orange } from "@material-ui/core/colors";
import Radio from '@material-ui/core/Radio';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as BookingFailed } from '../../../images/BookingFailed.svg';

import { updateCustomer, getCustomerDetails, resetUpdateMember, closeMemberDrawer } from '../../../actions';
import { errors } from '../../../common/errors';
import { fullNameRegex } from '../../../common/constants';

const materialTheme = createMuiTheme({
    palette: {
        primary: orange,
    },
});

const motherTongue = [
    {
        value: 'hindi',
        label: 'Hindi'
    },
    {
        value: 'english',
        label: 'English',
    },
    {
        value: 'kannada',
        label: 'Kannada',
    },
    {
        value: 'tamil',
        label: 'Tamil',
    },
    {
        value: 'telugu',
        label: 'Telugu',
    },
    {
        value: 'malayalam',
        label: 'Malayalam',
    },
    {
        value: 'bangla',
        label: 'Bangla',
    },
    {
        value: 'gujarati',
        label: 'Gujarati',
    },
    {
        value: 'marathi',
        label: 'Marathi',
    },
    {
        value: 'punjabi',
        label: 'Punjabi',
    },
    {
        value: 'odia',
        label: 'Odia',
    },
]

const relationships = [ // change relationships
    {
        value: 'self',
        label: 'Self',
    },
    {
        value: 'spouse',
        label: 'Spouse',
    },
    {
        value: 'father',
        label: 'Father'
    },
    {
        value: 'mother',
        label: 'Mother',
    },
    {
        value: 'son',
        label: 'Son',
    },
    {
        value: 'daughter',
        label: 'Daughter',
    },
    {
        value: 'brother',
        label: 'Brother',
    },
    {
        value: 'sister',
        label: 'Sister',
    },
    {
        value: 'friend',
        label: 'Friend',
    },
    {
        value: 'other',
        label: 'Other',
    },

]

function disableNextDates(startDate) {
    const startSeconds = Date.parse(startDate);
    return (date) => {
        return Date.parse(date) > startSeconds;
    }
}
class EditMemberForm extends React.Component {

    state = {
        dob: this.props.member.dob,
        firstName: this.props.member.first_name,
        lastName: this.props.member.last_name ? this.props.member.last_name : '',
        gender: this.props.member.gender ? this.props.member.gender : '',
        motherTongue: this.props.member.lop ? this.props.member.lop : '',
        relationship: this.props.member.relationship ? this.props.member.relationship : '',
    };

    handleTryAgainButton = () => {
        this.props.resetUpdateMember();
        this.setState({ saveButtonPressed: false });
    };

    componentDidMount() {
        this.props.resetUpdateMember();
    }

    handleChange = name => event => {
        const userValue = event.target.value;
        if (fullNameRegex.test(userValue) || userValue === '') {
            this.setState({
                [name]: userValue,
            });
        }
    };

    handleDateChange = date => {
        if (!_.isNil(date)) {
        this.setState({ dob: date.getTime() });
        } else {
            this.setState({ dob: ''})
        }
    };

    clickSaveButton = () => {
        const newMemberObject = {
            first_name: this.state.firstName,
            dob: this.state.dob,
            gender: this.state.gender,
            lop: this.state.motherTongue,
            relationship: this.state.relationship
        };
        if (this.state.lastName) {
            newMemberObject.last_name = this.state.lastName
        };
        this.setState({ saveButtonPressed: true })
        const familyId = this.props.familyDetails && this.props.familyDetails.currentCustomer && this.props.familyDetails.currentCustomer.family ? this.props.familyDetails.currentCustomer.family.id : null;
        const updateCustomerPromise = this.props.updateCustomer(newMemberObject, familyId, this.props.member.id);
        updateCustomerPromise.then(() => {
            if (this.props.familyDetails && this.props.familyDetails.updateMemberStatus && this.props.familyDetails.updateMemberStatus.status === 204) {
                this.props.getCustomerDetails(this.props.familyDetails.currentCustomer.primary_number);
                this.props.closeMemberDrawer();
                this.props.resetUpdateMember();
            }
        })
    };

    render() {
        if (this.props.familyDetails && this.props.familyDetails.updateMemberStatus === errors.ERROR_IN_UPDATING_MEMBER) {
            return (<div style={{ position: 'relative' }}> <BookingFailed style={{ position: 'absolute', marginTop: '80%', marginLeft: '45%', marginRight: '45%' }} />
                <Button variant="outlined" style={{ position: 'absolute', borderColor: 'orange', color: 'orange', marginTop: '100%', marginLeft: '30%', marginRight: '30%', width: '40%' }} onClick={this.handleTryAgainButton} >Try Again </Button>
            </div>)
        }
        else if (!this.state.saveButtonPressed) {
            return (
                <form style={{ position: 'relative' }}
                >
                    <Typography style={{ color: 'black', fontWeight: 'bold', fontSize: '1.2rem', fontFamily: 'Rubik', marginLeft: '0.8rem', marginBottom: '0.6rem' }}>Edit Member</Typography>
                    <Divider style={{ backgroundColor: 'orange' }} />
                    <TextField value={this.state.firstName} onChange={this.handleChange('firstName')} label="First Name" style={{ display: 'flex', marginLeft: '1rem', marginRight: '1rem', marginTop: '2rem', marginBottom: '1.5rem' }} />
                    <TextField value={this.state.lastName} onChange={this.handleChange('lastName')} label="Last Name" style={{ display: 'flex', marginLeft: '1rem', marginRight: '1rem', marginTop: '2rem', marginBottom: '1.5rem' }} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                    </div>
                    <div style={{ marginLeft: '2vh' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography style={{ marginTop: '1.5vh', color: '#757575' }} > Gender</Typography>
                            <Radio
                                style={{ color: 'orange', marginLeft: '4vh' }}
                                checked={this.state.gender === 'male'}
                                onChange={() => this.setState({ gender: 'male' })}
                                value="male"
                            />
                            <Typography style={{ marginTop: '1.5vh', color: '#757575' }}> Male</Typography>
                            <Radio
                                style={{ color: 'orange' }}
                                checked={this.state.gender === 'female'}
                                onChange={() => this.setState({ gender: 'female' })}
                                value="female"
                            />
                            <Typography style={{ marginTop: '1.5vh', color: '#757575' }} > Female</Typography>
                        </div>

                    </div>
                    <div style={{ backgroundColor: 'white', marginTop: '1.5rem', marginLeft: '1rem' }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={materialTheme}>
                                <KeyboardDatePicker
                                    style={{ width: '15rem', display: 'flex' }}
                                    label="DOB"
                                    value={this.state.dob}
                                    onChange={this.handleDateChange}
                                    format="dd-MM-yyyy "
                                    helperText="Ex: 16-12-1950"
                                    shouldDisableDate={disableNextDates(new Date())}
                                />
                            </ThemeProvider>
                        </MuiPickersUtilsProvider>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <TextField
                                style={{ width: '95%', marginRight: '5%', float: 'left', display: 'flex' }}
                                select
                                label="Mother Tongue"
                                value={this.state.motherTongue}
                                onChange={this.handleChange('motherTongue')}
                                margin="normal"
                            >
                                {motherTongue.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                style={{ width: '95%', marginRight: '5%', float: 'left' }}
                                select
                                label="Relationship to H.S."
                                value={this.state.relationship}
                                onChange={this.handleChange('relationship')}
                                margin="normal"
                            >
                                {relationships.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                    <div style={{
                        position: 'fixed',
                        bottom: '0px',
                        right: '0px', width: '50vh'
                    }}>
                        <Button onClick={this.clickSaveButton} disabled={!this.state.dob ||
                            !this.state.firstName ||
                            !this.state.gender ||
                            !this.state.motherTongue ||
                            // this.state.contactNumber.length !== 10 || //phone number not mandatory
                            !this.state.relationship}
                            style={{
                                backgroundColor: (!this.state.dob ||
                                    !this.state.firstName ||
                                    !this.state.gender ||
                                    !this.state.motherTongue ||
                                    // this.state.contactNumber.length !== 10 ||  //phone number not mandatory
                                    !this.state.relationship) ? 'grey' : 'orange', color: 'white', width: '100%', height: '3rem'
                            }}> Submit</Button>
                    </div>
                </form>);
        } else {
            return (
                <div> <CircularProgress style={{ position: 'absolute', color: 'orange', left: '45%', right: '50%', top: '50%', bottom: '50%' }} disableShrink />
                    {(this.props.familyDetails && this.props.familyDetails.newMember === errors.ERROR_IN_ADDING_NEW_MEMBER) ? <Typography style={{ fontSize: '1.5vh', color: 'red', position: 'absolute', left: '15%', right: '15%', top: '60%', bottom: '30%' }}> Error while adding member. Please try again later !</Typography> : <> </>}</div>
            );
        }
    }
};

const mapStateToProps = (state) => {
    return { auth: state.auth, familyDetails: state.familyDetails, error: state.error, consultationDetails: state.consultationDetails };
};

export default connect(mapStateToProps, { updateCustomer, getCustomerDetails, resetUpdateMember, closeMemberDrawer })(EditMemberForm);