const useStyles = (theme) => ({
  allSubscriptionsContainer: {
    backgroundColor: '#ffffff',
    width: 'calc(100vw - 125px)',
    marginLeft: '20px',
    height: 'calc(100vh - 180px)',
    position: 'relative',
  },
  tabHeading: {
    color: '#f38b44',
    textTransform: 'uppercase',
    marginTop: '5px',
    marginLeft: '20px',
    marginBottom: '10px',
    fontFamily: "Manrope-Bold",
  },
  //search box css
  searchAndFilter: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    marginLeft: '20px',
    height: '100px',
  },
  searchWithClearText: {
    width: '40%',
  },
  mobileSearchInput: {
    alignContent: 'center',
    height: '42px',
    marginTop: '-20px',
  },
  mobileSearchInputWithPhone: {
    alignContent: 'center',
  },
  clearSearch: {
    fontSize: '12px', fontFamily: 'Manrope-Regular', marginTop: '5px', cursor: 'pointer',
  },
  filterButton: {
    width: '30%',
    height: '42px',
    marginTop: '20px',
    textTransform: 'capitalize',
    fontSize: '11px',
    fontFamily: 'Manrope-Bold',
    color: '#0033cc',
    position: 'relative',
  },
  filterButtonWithPhone: {
    width: '30%',
    height: '42px',
    marginTop: '-3px',
    textTransform: 'capitalize',
    fontSize: '11px',
    fontFamily: 'Manrope-Bold',
    color: '#0033cc',
    position: 'relative',
  },
  filterIcon: {
    height: '12px', width: '12px', marginRight: '6px',
  },
  filterIconWithPhone: {
    height: '12px', width: '12px', marginRight: '6px', marginTop: '-1px',
  },
  searchButton: {
    height: '17px',
    width: '17px',
  },
  //subscription list css
  subscriptionList: {
    overflow: 'scroll', position: 'relative',
    height: 'calc(100% - 260px)',
    borderBottom: '1px solid #cbcbcb',
  },
  //table heading
  tableHeading: {
    height: '40px',
    backgroundColor: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '20px',
  },
  mobileNumberHeading: {
    position: 'absolute',
    left: '20px',
    fontSize: 'calc(0.6em + 0.5vmin)',
    fontFamily: 'Manrope-Bold',
    color: '#000000',
  },
  planNameHeading: {
    position: 'absolute',
    left: '160px',
    fontSize: 'calc(0.6em + 0.5vmin)',
    fontFamily: 'Manrope-Bold',
    color: '#000000',
  },
  totalMembersHeading: {
    position: 'absolute',
    left: '410px',
    fontSize: 'calc(0.6em + 0.5vmin)',
    fontFamily: 'Manrope-Bold',
    color: '#000000',
  },
  dateOfPurchaseHeading: {
    position: 'absolute',
    left: '550px',
    fontSize: 'calc(0.6em + 0.5vmin)',
    fontFamily: 'Manrope-Bold',
    color: '#000000',
  },
  businessNameBox: {
    position: 'absolute',
    left: '730px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  businessName: {
    fontSize: 'calc(0.6em + 0.5vmin)',
    fontFamily: 'Manrope-Bold',
    color: '#000000',
  },
  GhdStatus: {
    position: 'absolute',
    left: '950px',
    fontSize: 'calc(0.6em + 0.5vmin)',
    fontFamily: 'Manrope-Bold',
    color: '#000000',
  },
  paginationComponent: {
    position: 'absolute',
    bottom: '10px',
    left: '20px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    border: '1px solid #cbcbcb',
    // boxShadow: '3px -3px 19px 2px rgba(158, 149, 158, 0.45)',
  },
  startDateField: {
    marginRight: '20px'
  },
  endDateField: {
    marginRight: '30px'
  },
  indicator: {
    backgroundColor: "orange"
  },
  default_tabStyle: {
    color: '#172A3A',
    fontFamily: 'Manrope-Regular',
    textTransform: "capitalize",
  },
  active_tab: {
    fontFamily: 'Manrope-Bold',
    color: '#172A3A',
    textTransform: "capitalize"
  },
  salesTaskHeader: {
    backgroundColor: "#F2F2F2",
    width: '100%',
    marginBottom: '20px',
    marginLeft: '20px',
    textTransform: "capitalize",
  }
});

export default useStyles;