import config from '../config';
const axios = require('axios');

export const updateCustomerCreditFile = async (ACCESS_TOKEN, emailId, file) => {
    const url = config.apiURL + `/v1/operations/update-customer-credits`;
    const form = new FormData();
    form.append('initiator_email',emailId)
    form.append('customer_credit_file', file);
    const options = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + ACCESS_TOKEN,
        },
        timeout: 10000,
        responseType: 'json',
        responseEncoding: 'utf8',
    };
    const response = await axios.post(url, form, options);
    return response;
}




