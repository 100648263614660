import React from 'react';
import { connect } from 'react-redux';

import SideMenuBar from '../components/Home/sideMenuBar/SideMenuBar';

import LoginPage from './LoginPage';

class CustomErrorPage extends React.Component {
  render() {
    if (!this.props.details.isLoggedIn) {   
      return (
        <LoginPage />
      )
    } else {
      return (
        <div className='main' style={{
          backgroundColor: "#f4f4f4", width: "100%", height: "100vh", display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'row'
        }}>
          <div style={{ backgroundColor: "white", width: "10vh", height: "100vh" }} >
            <SideMenuBar />
          </div>
        {this.props.message}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return { details: state.auth };
};

export default connect(mapStateToProps)(CustomErrorPage);