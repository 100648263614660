import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Divider, Drawer, Button, CircularProgress } from "@material-ui/core";
// import { ReactComponent as CloseButtonXActive } from '../../../../images/CloseButtonXActive.svg';
import { ReactComponent as GreenTick } from '../../../../../images/GreenTick.svg';
import { getAllMembersByFamilyId, resetGetAllMembersByFamilyId, resetNewMemberAdded, addNewMemberByFamilyId } from '../../../../../actions';
import ConfirmationDrawer from '../planConfirmation';
import styles from './style';

class ReviewPlan extends React.Component {

    state = { clickedYesForSubscribe: false };

    componentDidMount() {
        this.props.resetNewMemberAdded();
        this.props.resetGetAllMembersByFamilyId();
        this.callGetAllMembersByFamilyId();
    };

    callGetAllMembersByFamilyId = () => {
        this.props.getAllMembersByFamilyId(this.props.selectedSubscription.family.id)
    }

    getCurrentFamilyId = () => {
        if (this.props.newPlan && this.props.newPlan.customerByPhoneNumber && this.props.newPlan.customerByPhoneNumber.family && this.props.newPlan.customerByPhoneNumber.family.id) {
            return this.props.newPlan.customerByPhoneNumber.family.id;
        } else if (this.props.newCustomer && this.props.newCustomer.createHeadSubscriber && this.props.newCustomer.createHeadSubscriber.newFamily && this.props.newCustomer.createHeadSubscriber.newFamily.data && this.props.newCustomer.createHeadSubscriber.newFamily.data.family && this.props.newCustomer.createHeadSubscriber.newFamily.data.family.id) {
            return this.props.newCustomer.createHeadSubscriber.newFamily.data.family.id;
        } else {
            return '';
        }
    }


    productHasAccidentalOrHealth = () => {
        return _.hasIn(this.props.productInCart, 'health_insurance') || _.hasIn(this.props.productInCart, 'accidental_insurance')
    }


    renderSubscriptionSuccesfullComponent = () => {
        return (
            <>
                <div style={{ marginLeft: '37%', marginRight: '37%' }}>
                    <GreenTick style={{ height: '7vh', width: '7vw' }} />
                </div>
                <Typography style={{ color: ' #172a3a', fontWeight: 'bold', fontSize: '1.3rem', fontFamily: 'Rubik-Medium', marginTop: '1rem', marginLeft: '4vh', marginRight: '4vh', textAlign: 'center' }}>
                    Subscription Successful!
            </Typography>
                <Typography style={{ color: ' #172a3a', fontWeight: 'bold', fontSize: '1rem', fontFamily: 'Rubik', marginTop: '1rem', marginLeft: '4vh', marginRight: '4vh', textAlign: 'center' }}>
                    An SMS with payment link is sent to the customer. Please request them to make a payment using that link.
            </Typography>
                <Button onClick={() => this.props.getCustomerDetails(this.props.newPlan.newPlanMobileNumber)} style={{ backgroundColor: 'orange', marginLeft: '37.5%', marginRight: '37.5%', width: '25%', color: 'white', textTransform: 'capitalize', fontFamily: 'Rubik-Regular', marginTop: '2vh' }}>Done</Button>
            </>
        )
    }

    renderSomethingWentWrong = () => {
        return (
            <>
                <Typography style={{ color: ' #172a3a', fontWeight: 'bold', fontSize: '0.8rem', fontFamily: 'Rubik', marginTop: '1rem', marginLeft: '2vh', marginRight: '2vh', textAlign: 'center' }}>
                    Something went wrong !
            </Typography>
                <Button onClick={this.closeSubscribeDrawer} style={{ backgroundColor: 'orange', marginLeft: '37.5%', marginRight: '37.5%', width: '25%', color: 'white', textTransform: 'capitalize', fontFamily: 'Rubik-Regular', marginTop: '2vh' }}>Cancel</Button>
            </>
        )

    }

    getCircularProgress = () => {
        return <CircularProgress style={{
            color: 'orange',
            left: '50%',
            top: '55%',
            position: 'absolute',
        }} disableShrink />;
    }


    renderReviewCard = (heading, value) => {
        return (
            <>
                <Typography style={{ fontFamily: 'Manrope-Regular', fontSize: '11px', color: '#908f8f', marginTop: '1vh' }}>
                    {heading}
                </Typography>
                <Typography style={{fontFamily: 'Manrope-Bold', fontSize: '18px', color: '#172a3a', marginTop: '1vh' }}>
                    {value}
                </Typography>
            </>
        )
    }

    renderReviewPlan = () => {
        return (
            <div style={{ height: '53vh', overflowY: 'auto' }}>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '5vw', marginTop: '2.5vh', color: '#172a3a' }}>
                    <Typography style={{ fontSize: '18px', fontFamily: 'Manrope-Regular' }}>
                        Confirm the member details:
                  </Typography>
                    {this.renderReviewCard('Primary Member', _.find(this.props.newPlan.allMembersOfCurrentFamily.members, { id: this.props.selectedPrimaryCustomerId }).last_name ? _.find(this.props.newPlan.allMembersOfCurrentFamily.members, { id: this.props.selectedPrimaryCustomerId }).first_name + ' ' + _.find(this.props.newPlan.allMembersOfCurrentFamily.members, { id: this.props.selectedPrimaryCustomerId }).last_name : _.find(this.props.newPlan.allMembersOfCurrentFamily.members, { id: this.props.selectedPrimaryCustomerId }).first_name)}
                    {this.props.selectedSpouseCustomerId && this.renderReviewCard('Spouse', _.find(this.props.newPlan.allMembersOfCurrentFamily.members, { id: this.props.selectedSpouseCustomerId }).last_name ? _.find(this.props.newPlan.allMembersOfCurrentFamily.members, { id: this.props.selectedSpouseCustomerId }).first_name + ' ' + _.find(this.props.newPlan.allMembersOfCurrentFamily.members, { id: this.props.selectedSpouseCustomerId }).last_name : _.find(this.props.newPlan.allMembersOfCurrentFamily.members, { id: this.props.selectedSpouseCustomerId }).first_name)}
                    {this.props.selectedKid1CustomerId && this.renderReviewCard('Kid 1', _.find(this.props.newPlan.allMembersOfCurrentFamily.members, { id: this.props.selectedKid1CustomerId }).last_name ? _.find(this.props.newPlan.allMembersOfCurrentFamily.members, { id: this.props.selectedKid1CustomerId }).first_name + ' ' + _.find(this.props.newPlan.allMembersOfCurrentFamily.members, { id: this.props.selectedKid1CustomerId }).last_name : _.find(this.props.newPlan.allMembersOfCurrentFamily.members, { id: this.props.selectedKid1CustomerId }).first_name)}
                    {this.props.selectedKid2CustomerId && this.renderReviewCard('Kid 2', _.find(this.props.newPlan.allMembersOfCurrentFamily.members, { id: this.props.selectedKid2CustomerId }).last_name ? _.find(this.props.newPlan.allMembersOfCurrentFamily.members, { id: this.props.selectedKid2CustomerId }).first_name + ' ' + _.find(this.props.newPlan.allMembersOfCurrentFamily.members, { id: this.props.selectedKid2CustomerId }).last_name : _.find(this.props.newPlan.allMembersOfCurrentFamily.members, { id: this.props.selectedKid2CustomerId }).first_name)}
                    {this.props.selectedKid3CustomerId && this.renderReviewCard('Kid 3', _.find(this.props.newPlan.allMembersOfCurrentFamily.members, { id: this.props.selectedKid3CustomerId }).last_name ? _.find(this.props.newPlan.allMembersOfCurrentFamily.members, { id: this.props.selectedKid3CustomerId }).first_name + ' ' + _.find(this.props.newPlan.allMembersOfCurrentFamily.members, { id: this.props.selectedKid3CustomerId }).last_name : _.find(this.props.newPlan.allMembersOfCurrentFamily.members, { id: this.props.selectedKid3CustomerId }).first_name)}
                    <Divider style={{ width: '300px', marginTop: '2vh' }} />
                    {this.props.selectedSubscription && this.renderReviewCard('Plan Name', this.props.selectedSubscription.product.name)}

                </div>
                <Drawer disableEnforceFocus anchor="right" open={this.props.showConfirmationDrawer}
                >
                    <div style={{ width: '30vw', height: '100vh' }} >
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Typography style={{ color: 'black', fontWeight: 'bold', fontSize: '1.2rem', fontFamily: 'Rubik', marginTop: '1.5rem', marginLeft: '0.8rem', marginBottom: '0.6rem' }}>Confirm</Typography>
                            {/* <CloseButtonXActive style={{ marginTop: '1.9rem', marginRight: '2vh', cursor: 'pointer' }} onClick={this.props.closeAddPlanDetailsDrawer} /> */}
                        </div>
                        <Divider style={{ backgroundColor: 'orange' }} />
                        <div style={{ marginTop: '80%', marginBottom: '30%' }}>
                         <ConfirmationDrawer {...this.props} />
                        </div>
                    </div>
                </Drawer>
            </div>

        )
    }


    render() {
        if (this.props.newPlan && _.isNil(this.props.newPlan.allMembersOfCurrentFamily)) {
            return (
                <div style={{ height: '53vh', overflowY: 'scroll' }}>
                    <div style={{ marginTop: '10vh' }}>
                        {this.getCircularProgress()}
                    </div>
                </div>
            );
        } else if (this.props.newPlan && this.props.newPlan.allMembersOfCurrentFamily && this.props.newPlan.allMembersOfCurrentFamily.members && !_.isEmpty(this.props.newPlan.allMembersOfCurrentFamily.members)) {
            return (
                <div style={{ height: '53vh', overflowY: 'scroll' }}>
                    {this.renderReviewPlan()}
                </div>
            );
        } else {
            return (
                <div style={{ height: '53vh', overflowY: 'scroll' }}>
                    <div style={{ marginTop: '5vh', marginLeft: '5vw' }}>Something went wrong !!</div>
                </div>
            );
        }
    }
}

ReviewPlan.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error, familyDetails: state.familyDetails, newPlan: state.newPlan, newCustomer: state.newCustomer };
};

export default connect(mapStateToProps, { getAllMembersByFamilyId, resetGetAllMembersByFamilyId, resetNewMemberAdded, addNewMemberByFamilyId })(withStyles(styles)(ReviewPlan));
