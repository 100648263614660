import { couponActionTypes } from '../constants/couponConstant';

const initState = {
    code: null,
    valid: false,
    isLoading: false,
    order: null,
    referral: null,
    error: false,
    applicableTo: null,
    applicable_line_items: null,
};

const couponReducer = (state = initState, action) => {
    switch (action.type) {
        case couponActionTypes.VALIDATE_COUPON_REQUEST: {
            const code = String(action.payload).trim().length === 0 ? null : String(action.payload).trim();
            return {
                ...state,
                isLoading: true,
                code,
            }
        }
        case couponActionTypes.VALIDATE_COUPON_SUCCESS: {
            const { valid, order, applicableTo, referral, applicable_line_items } = action.payload;
            return {
                ...state,
                isLoading: false,
                valid,
                order,
                applicableTo,
                referral,
                applicable_line_items,
            }
        }
        case couponActionTypes.VALIDATE_COUPON_FAILURE: {
            return {
                ...state,
                isLoading: false,
                valid: false,
                order: null,
                error: true,
            }
        }
        case couponActionTypes.RESET_COUPON: {
            return initState;
        }
        default: {
            return state;
        }
    }
};

export default couponReducer;
