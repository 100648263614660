import React, { Component } from 'react';
import { connect } from 'react-redux';

import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Tabs from '@material-ui/core/Tabs/Tabs';
import Tab from '@material-ui/core/Tab/Tab';
import { withStyles } from '@material-ui/core/styles/';

import CustomerEcardGenerator from './CustomerECardGenerator';
import {
	fetchBenefitCardsByCustomerId,
	openBookConsultationDrawer,
	closeBookConsultationDrawer,
	resetConsultationDetail,
	resetConsultationPageNumber,
	openVisitBookDrawer,
	closeVisitBookDrawer,
	resetVisitBookingDetails
} from '../../../../actions';

import CustomerConsultation from './CustomerConsultation';
import { Button, Typography } from '@material-ui/core';
import { color } from '../../../../constants/colors';
import DocumentTable from './DocumentTable';
import TriggerDrawer from '../../../common/TriggerDrawer/TriggerDrawer';
import { tr } from 'date-fns/locale';

const styles = (theme) => ({
	indicator: {
		backgroundColor: 'orange',
	},
	default_tabStyle: {
		color: 'grey',
		fontFamily: 'Rubik',
	},
	active_tab: {
		fontWeight: 'bold',
		color: 'black',
		fontFamily: 'Rubik',
	},
	flexContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	root: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		background: 'green',
	},
    container: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        width: '100%',
        height: '100%',
    },
    footer: {
        display: 'flex',
        padding: '4vh',
        width: 'calc(100% - 8vh)',
    },
    button: {
        textTransform: 'capitalize',
        background: color.ROYAL_ORANGE,
        color: color.WHITE,
        borderRadius: '4px',
        height: '38px',
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        fontSize: '14px',
        width: '100%',
        '&:hover': {
            background: color.ORANGE,
        },
    },
    header: {
        padding: '4vh 4vh 1vh 4vh',
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        lineHeight: '17px',
        fontSize: '14px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${color.yellows[100]}`,
        color: color.blues[300],
        borderTopLeftRadius: '8px',
        position: 'sticky',
        top: '0',
        background: color.WHITE,
        height: '2.25%',
        zIndex: '50',
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '2vh',
        // minHeight: '80%',
        flex: '1 100%',
    },
});

class DetailPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openHealthVaultFileUploadDrawer: false,
			activeTabIndex: 0, // change to 0
			drawerOpen: false,
		};
	}

	openConsultationDrawer = () => {
		this.props.openBookConsultationDrawer();
		this.props.resetConsultationDetail();
	};

	openBookVisitDrawer = () => {
		this.props.openVisitBookDrawer();
		this.props.resetVisitBookingDetails();
	}

	toggleHealthVaultFileUploadDrawer = (state) => {
		this.setState({
			openHealthVaultFileUploadDrawer: state,
		});
	};

	componentDidMount() {
		if (window.location.hash === '#consultation') {
			this.setState({
				activeTabIndex: 1,
			});
		}
	}

	handleChange = (event, value) => {
		this.setState({ activeTabIndex: value });
		this.props.resetConsultationPageNumber();
	};

	renderAppBar = () => {
		const { classes } = this.props;
		const { activeTabIndex } = this.state;
		return (
			<Paper className={classes.root} elevation={0}>
				<AppBar
					position='static'
					elevation={0}
					style={{
						minWidth: '30vw',
						marginRight: '5vw',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
						paddingRight: '4vh',
						background: 'white',
					}}
				>
					<Tabs
						value={activeTabIndex}
						onChange={this.handleChange}
						style={{
							textTransform: 'capitalize',
							backgroundColor: 'white',
							fontWeight: 'bold',
						}}
						classes={{
							indicator: classes.indicator,
							flexContainer: classes.flexContainer,
							root: classes.root,
						}}
					>
						<Tab
							className={
								this.state.activeTabIndex === 0
									? classes.active_tab
									: classes.default_tabStyle
							}
							style={{
								color: 'black',
								textTransform: 'capitalize',
							}}
							label='E-Cards'
						/>
						<Tab
							className={
								this.state.activeTabIndex === 1
									? classes.active_tab
									: classes.default_tabStyle
							}
							style={{ color: 'black', textTransform: 'capitalize' }}
							label='Consultations'
						/>
						<Tab
							className={
								this.state.activeTabIndex === 2
									? classes.active_tab
									: classes.default_tabStyle
							}
							style={{ color: 'black', textTransform: 'capitalize' }}
							label='Documents'
						/>
					</Tabs>
					{this.state.activeTabIndex === 1 ? (
						<Button
							onClick={this.openConsultationDrawer}
							style={{ backgroundColor: color.ROYAL_ORANGE, padding: '0.8vh 1.6vh', display: 'none' }}
						>
							<Typography
								style={{
									fontFamily: 'Rubik',
									fontSize: '13px',
									textTransform: 'capitalize',
									color: 'white',
									fontWeight: 'bold',
								}}
							>
								Book New
							</Typography>
						</Button>
					) : null}
					{this.state.activeTabIndex === 1 ? (
						<Button
							onClick={this.openBookVisitDrawer}
							style={{ backgroundColor: color.ROYAL_ORANGE, padding: '0.8vh 1.6vh', display: 'none' }}
						>
							<Typography
								style={{
									fontFamily: 'Rubik',
									fontSize: '13px',
									textTransform: 'capitalize',
									color: 'white',
									fontWeight: 'bold',
								}}
							>
								{"Book Appointment"}
							</Typography>
						</Button>
					) : null}
					{this.state.activeTabIndex === 2 ? (
                        <TriggerDrawer.Trigger>
                            <Button
							    // onClick={this.openConsultationDrawer}
							    style={{
                                    backgroundColor: color.ROYAL_ORANGE,
                                    fontFamily: 'Rubik',
                                    fontWeight: 'bold',
                                    fontSize: '13px',
                                    color: 'white',
                                    textTransform: 'capitalize',
                                    padding: '0.25vh 1vh',
                                    borderRadius: '4px',
                                }}
                                variant="text"
						    >
                                Add New
						    </Button>
                        </TriggerDrawer.Trigger>
					) : null}
				</AppBar>
			</Paper>
		);
	};

	render() {
		const { customerId, customerName } = this.props;
		if (this.state.activeTabIndex === 0) {
			return (
				<>
					<div style={{ width: '50%', backgroundColor: 'white', overflowY: 'scroll' }}>
						{this.renderAppBar()}
						<br />
						<br />
						<CustomerEcardGenerator customerId={customerId} />
					</div>
				</>
			);
		} else if (this.state.activeTabIndex === 1) {
			return (
				<div style={{ width: '50%', backgroundColor: 'white' }}>
					{this.renderAppBar()}
					<CustomerConsultation customerId={customerId} customerName={customerName} openVisitBookDrawer={this.openBookVisitDrawer} closeVisitBookDrawer={this.props.closeVisitBookDrawer} />
				</div>
			);
		} else if (this.state.activeTabIndex === 2) {
			return (
				<div style={{ width: '50%', backgroundColor: 'white' }}>
                    <TriggerDrawer>
					    {this.renderAppBar()}
					    <DocumentTable customerId={customerId} />
                    </TriggerDrawer>
				</div>
			);
		} else {
			return <div> Something went wrong</div>;
		}
	}
}

const mapStateToProps = (state) => {
	return { auth: state.auth, error: state.error, familyDetails: state.familyDetails };
};

export default connect(mapStateToProps, {
	fetchBenefitCardsByCustomerId,
	openBookConsultationDrawer,
	closeBookConsultationDrawer,
	resetConsultationDetail,
	resetConsultationPageNumber,
	openVisitBookDrawer,
	closeVisitBookDrawer,
	resetVisitBookingDetails,
})(withStyles(styles)(DetailPage));
