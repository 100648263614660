import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as ClinikkFullLogo } from '../images/clinikk_full.svg';
import { ReactComponent as OnboardingIcon } from '../images/Onboarding.svg';

import { initiateOtp, resetErrors } from '../actions';
import HomePage from './HomePage';

class LoginPage extends React.Component {
    state = { mobileNumber: '', notificationBarDelay: 10 };

    getOtpMethod = () => {
        setTimeout(() => this.props.resetErrors(), 9000);
        this.props.initiateOtp(this.state.mobileNumber);
        this.setState({ notificationBarDelay: 10 })
    };

    getOtp = () => {
        this.getOtpMethod();
    }

    getOtpOnEnter = (event) => {
        if (event.key === 'Enter' && this.state.mobileNumber.length === 10) {
            this.getOtpMethod();
        }
    };

    tick() {
        this.setState(prevState => ({
            notificationBarDelay: this.state.notificationBarDelay > 1 ? prevState.notificationBarDelay - 1 : ''
        }));
    }

    componentDidMount() {
        this.props.resetErrors();
        if (this.state.notificationBarDelay > 0) {
            this.interval = setInterval(() => this.tick(), 1000);
        };
        const params = new URLSearchParams(window.location.search);
        const phoneNumber = params.get('phoneNumber');
        if (phoneNumber) {
            this.setState({ mobileNumber: phoneNumber })
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        if (this.props.auth.isLoggedIn) {
            return <HomePage />
        } else {
            const { mobileNumber } = this.state;
            return (
                <div className='main'
                    style={{
                        height: "100vh", display: 'flex',
                        alignItems: 'flex-start',
                        flexDirection: 'row'
                    }}
                >

                    <div style={{ backgroundColor: "#f4f4f4", width: "50vh", height: "100%" }}>
                        <div style={{
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            marginTop: '20vh',
                        }} >
                            <OnboardingIcon />
                        </div>
                    </div>
                    <div style={{ backgroundColor: "white", width: "70%", height: "100%", }}>

                        {(this.props.error.loginErrorCode && this.state.notificationBarDelay > 1) ? <div style={{ backgroundColor: '#f66a6a', height: '5vh' }}>
                            <Typography style={{ marginLeft: '3vh', marginTop: '5px', color: 'white' }}> {this.props.error.loginErrorCode}</Typography>
                        </div> : <> </>}

                        {(this.props.error.isNotAuthorizedRole && this.state.notificationBarDelay > 1) ?
                            <div style={{ backgroundColor: '#f66a6a', height: '5vh' }}><Typography style={{ marginLeft: '3vh', marginTop: '5px', color: 'white' }}> This number is unauthorized to view the content.</Typography></div> : <> </>
                        }

                        <div style={{
                            height: '50vh',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            paddingTop: '30vh',
                            paddingLeft: '10vh',
                            margin: "auto"
                        }} >
                            <ClinikkFullLogo />
                            <h3 style={{ fontSize: '30px', fontFamily: 'Rubik' }}>Welcome to Clinikk. Enter your mobile number to log in.</h3>
                            <TextField id="mobile_number"
                                onKeyUp={(event) => {
                                    this.getOtpOnEnter(event);
                                }}
                                autoFocus
                                type="text"
                                inputProps={{
                                    maxLength: 10,
                                }}
                                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                onChange={(event) => this.setState({ mobileNumber: event.target.value })} label="Mobile Number" 
                                value={mobileNumber} style={{ paddingBottom: "3vh", display: "block" }} 
                            />
                            <Button
                                type="button"
                                onClick={() => {
                                    this.getOtp();
                                }}
                                disabled={this.state.mobileNumber.length !== 10}
                                variant="contained"
                                style={{ backgroundColor: "orange", color: "white" }}>
                                Login
                            </Button>

                        </div>

                    </div>


                </div>
            )
        }
    };
};

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error };
}

export default connect(mapStateToProps, { initiateOtp, resetErrors })(LoginPage);