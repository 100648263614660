const errors = {
  NUMBER_DOES_NOT_EXIST: 'This number does not exist. Please contact the supervisor.',
  SOMETHING_WENT_WRONG: 'Something went wrong.',
  VERIFY_OTP_WRONG_OTP: 'Wrong OTP',
  VERIFY_OTP_ERROR: 'Something is not right',
  CUSTOMER_NOT_FOUND: 'Customer not found. Kindly check the number & enter again. ',
  ERROR_IN_FETCHING_CUSTOMER: 'Error while fetching Customer. Please refresh to continue.',
  ERROR_IN_FETCHING_SUBSCRIPTIONS: 'Some technical error while loading the plans.Please refresh to continue.',
  NACH_DOES_NOT_EXIST: 'No active NACH with Clinikk',
  ERROR_IN_FETCHING_NACH: 'Error in fetching Nach',
  FORBIDDEN: 'Forbidden: Please Login',
  ERROR_IN_POSTING_CONSULTATION: 'ERROR_IN_POSTING_CONSULTATION',
  ERROR_IN_POSTING_VISIT: 'ERROR_IN_POSTING_VISIT',
  ERROR_IN_OPD_TRANSACTIONS: 'Error in fetching opd transactions',
  ERROR_IN_OPD_BALANCE: 'Error in fetching opd balance',
  ERROR_IN_FETCHING_CONSULTATION: 'Error while fetching consultations',
  ERROR_IN_FETCHING_DOCTORS: 'Error in fetching doctors',
  ZIPCODE_DOES_NOT_EXIST: 'This pincode does not exist ',
  ERROR_IN_FINDING_ADDRESS: 'Error in fetching zipcode',
  CUSTOMER_ALREADY_EXISTS: 'This customer already exists. Please check the number.',
  ERROR_IN_CREATING_PRIMARY_CUSTOMER: 'Error in creating primary customer',
  ERROR_IN_VALIDATING_COUPON: 'Error in validating coupon',
  ERROR_IN_CREATING_ADDRESS: 'Error in creating address',
  ERROR_IN_CREATING_FREE_SUBSCRIPTION: 'Error while creating a subscription',
  ERROR_IN_PLAN_CHANGE: 'Error in Plan change',
  ERROR_IN_FETCHING_ALL_MEMBERS: 'Error in fetching all members',
  ERROR_IN_ADDING_NEW_MEMBER: 'Error in adding new member',
  ERROR_IN_UPDATING_PRIMARY_NUMBER: 'Error in updating primary number',
  ERROR_NUMBER_ALREADY_IN_USE: 'Number already in use',
  ERROR_UPDATE_MEMBER_DETAILS: 'Update member error',
  ERROR_IN_UPDATING_MEMBER: 'Error while updating member',
  ERROR_CANCEL_MANDATE: 'Error cancel mandate',
  ERROR_IN_CANCELLING_MANDATE: 'Error while cancelling mandate/nach',
  ERROR_CANCEL_SUBSCRIPTION: 'Error cancel subscription',
  ERROR_IN_CANCELLING_SUBSCRIPTION: 'Error in cancelling subscription',
  ERROR_WITHDRAW_SUBSCRIPTION: 'Error withdraw subscription',
  ERROR_IN_WITHDRAWING_SUBSCRIPTION: 'Error in withdrawing subscription',
  ERROR_SUBSCRIPTION_ALREADY_CANCELLED: 'Subscription already cancelled',
  ERROR_NACH_PAYMENT_IN_PENDING_STATE: 'The NACH payment is already in pending state',
  ERROR_IN_GETTING_INVOICES: 'Error while fetching invoices',
  ERROR_WHILE_FETCHING_ORDER: 'Error while fetching order details',
  ERROR_IN_FETCHING_PARTICULAR_CONSULTATION: 'Error while fetching consultation by id',
  ERROR_WHILE_ADDING_VITALS: 'Error while adding vitals',
  ERROR_INFETCHING_CLINICS: 'Error while fetching clinics',
  ERROR_IN_FETCHING_CONSULTATIONS_BY_CLINIC_ID: 'Error while fetching consultations by consultation id',
  ERROR_WHILE_ASSIGNING_DOCTOR: 'Error while assigning doctor',
  ERROR_IN_CANCELLING_CONSULTATION: 'Error in cancelling consultation',
  ERROR_IN_CHECKING_OUT_CONSULTATION: 'Error in checkout out consultation',
  ERROR_IN_CREATING_OFFLINE_PAYMENT: 'ERROR_IN_CREATING_OFFLINE_PAYMENT',
  ERROR_IN_FETCHING_PRODUCTS_BY_BUSINESS_ID: 'Error while fetching products by business id',
  ERROR_IN_CREATING_SUBSCRIPTION: 'ERROR_IN_CRETAING_SUBSCRIPTION',
  ERROR_IN_CREATING_ONBOARDING: 'ERROR_IN_CREATING_ONBOARDING',
  ERROR_IN_UPDATING_CUSTOMER_CREDIT: 'ERROR_IN_UPDATING_CUSTOMER_CREDIT',
  ERROR_IN_BATCH_EXTEND_SUBSCRIPTION: 'ERROR_IN_BATCH_EXTEND_SUBSCRIPTION',
  ERROR_IN_GETTING_ALL_SUBSCRIPTIONS_BY_DATE_PAGINATED: 'ERROR_IN_GETTING_ALL_SUBSCRIPTIONS_BY_DATE_PAGINATED',
  ERROR_IN_ADDING_PLAN_DETAILS: 'ERROR_IN_ADDING_PLAN_DETAILS',
  ERROR_IN_PLACING_TELECALL: 'ERROR_IN_PLACING_TELECALL',
  ERROR_IN_UPDATING_COHORT: 'ERROR_IN_UPDATING_COHORT',
  ERROR_IN_GETTING_COHORT: 'ERROR_IN_GETTING_COHORT',
}

module.exports = {
  errors,
};
