export const addressActionTypes = {
    ADD_ADDRESS_REQUEST: 'ADD_ADDRESS_REQUEST',
    ADD_ADDRESS_SUCCESS: 'ADD_ADDRESS_SUCCESS',
    ADD_ADDRESS_FAILURE: 'ADD_ADDRESS_FAILURE',

    FIND_ADDRESS_BY_ZIPCODE_REQUEST: 'FIND_ADDRESS_BY_ZIPCODE_REQUEST',
    FIND_ADDRESS_BY_ZIPCODE_SUCCESS: 'FIND_ADDRESS_BY_ZIPCODE_SUCCESS',
    FIND_ADDRESS_BY_ZIPCODE_FAILURE: 'FIND_ADDRESS_BY_ZIPCODE_FAILURE',

    GET_ADDRESSES_REQUEST: 'GET_ADDRESSES_REQUEST',
    GET_ADDRESSES_SUCCESS: 'GET_ADDRESSES_SUCCESS',
    GET_ADDRESSES_FAILURE: 'GET_ADDRESSES_FAILURE',

    UPDATE_ADDRESS_REQUEST: 'UPDATE_ADDRESS_REQUEST',
    UPDATE_ADDRESS_SUCCESS: 'UPDATE_ADDRESS_SUCCESS',
    UPDATE_ADDRESS_FAILURE: 'UPDATE_ADDRESS_FAILURE',

    RESET_ADDRESSES: 'RESET_ADDRESSES',
    RESET_ZIPCODE: 'RESET_ZIPCODE'
};

