import config from '../config';
const axios = require('axios');

export const uploadOnboardingFile = async (ACCESS_TOKEN, recipients, file) => {
    const url = config.apiURL + `/v1/operations/customer-onboarding`;
    const form = new FormData();
    recipients.forEach((email) => {
        if (recipients.length === 1) {
            form.append('recipients[]', email);
        } else {
            form.append('recipients', email);
        }
    })
    form.append('onboarding_file', file);
    const options = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + ACCESS_TOKEN,
        },
        timeout: 10000,
        responseType: 'json',
        responseEncoding: 'utf8',
    };
    const response = await axios.post(url, form, options);
    return response;
}




