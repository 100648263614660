import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import styles from "./style";
import { cancelPlan, updateTNC } from '../../../actions';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check-icon.svg';
import { ReactComponent as RejectedIcon } from '../../../assets/icons/rejected-icon.svg';
import moment from "moment";

class GhdDrawer extends React.Component {
  constructor () {
    super();
    this.state = {
      confirmationText: 'The customer will not be eligible for Clinikk Healthcare Plans due to the selected health conditions.',
      renderMain: true
    }
  }

  truncateComments = (comment) => {
    return comment?.length > 200 ? comment?.substring(0,199) + ' ...' : comment;
  }

  render() {
    const { classes, closeGhdDrawer, subscription } = this.props;
    return (
      <>
        <Box className={classes.header}>
            <Typography className={classes.rubikBold}> Ghd History </Typography>
            <CloseIcon className={classes.closeIcon} onClick={() => closeGhdDrawer()} />
        </Box>
      <Timeline className={classes.timeline}>
        {subscription?.tncLogs?.map(tncItem => {
          return (
          <TimelineItem>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot style={{ border: 'none', padding: '0px', backgroundColor: 'white' }}>
                {tncItem?.new_status === 'success' || tncItem?.new_status === 'in_review'
                ? <CheckIcon />
                : <RejectedIcon />
                }
              </TimelineDot>
              <TimelineConnector style={{ height: '56px', backgroundColor: '#F3F4F6' }} />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Typography className={classes.tncStatus} variant="h6" component="span">
                {tncItem?.new_status === 'success'
                ? <><span style={{ textTransform: 'uppercase' }}>{tncItem?.updated_by?.user_role[0]}</span> has submitted the GHD</>
                : ''
                }
                {tncItem?.new_status === 'failed'
                ? <><span style={{ textTransform: 'uppercase' }}>{tncItem?.updated_by?.user_role[0]}</span> has rejected the GHD</>
                : ''
                }
                {tncItem?.new_status === 'in_review'
                ? `GHD - In review`
                : ''
                }
              </Typography>
              <Typography style={{ fontSize: 'small' }}>
              {tncItem?.comment !== undefined && tncItem?.comment !== ''
                ? `comment: ${this.truncateComments(tncItem?.comment)}`
                : ''
                }
              </Typography>
              {
                tncItem?.updated_at
                ? <Typography style={{ color: '#6B7280' }}>{moment(tncItem?.updated_at).format("MMMM Do YYYY, h:mmA")}</Typography>
                : ''
              }
            </TimelineContent>
          </TimelineItem>
          )
        })}
      </Timeline>
</>
        
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  cancelPlan: (subscriptionId, familyId) => {
    dispatch(cancelPlan(subscriptionId, familyId));
  },
  updateTNCEvent: (subscriptionId, familyId, tncStatus) => {
    dispatch(updateTNC(subscriptionId, familyId, tncStatus));
  }
});

const mapStateToProps = (state) => {
  const { newPlan, auth, familyDetails } = state;
  return {
      newPlan,
      auth,
      familyDetails,
  };
};
const ConnectedGhdDrawer = withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GhdDrawer))
);
export default ConnectedGhdDrawer;
