import React, { useRef } from "react";
import _ from "lodash";
import { map, round, debounce } from "lodash";
import { Button, Checkbox, TextField } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
// import { ReactComponent as Cross } from '../../../assets/icons2/common/cross.svg';
import Cross from "../../../assets/icons/cross";
import QuantityPicker from "./QuantityPicker";
import AutoComplete from "./AutoComplete";
import { itemTypes } from "../../../constants";
import { connect } from "react-redux";
import { consultationActions } from "../../../actions";
import { withStyles } from "@material-ui/core";
import useStyles from "./style";
import { useEffect, useState } from "react";
import TopRightArrow from "../../../assets/icons/top-right-arrow";
import styled from "styled-components";
import { color } from "../../../constants/colors";
import {
  addLineItemsToOrder,
  deleteLineItemFromOrder,
  modifyLineItemInOrder,
} from "../../../clients/omsService";
import { ReactComponent as SyncIcon } from "../../../assets/icons/loading.svg";
import { discountType } from "../../../common/constants";
import { setError } from "../../../actions";
import { v4 } from "uuid";
import {
  buildCustomError,
  getDiscountOnUpdatedLineItem,
} from "../../../common/utils";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";

const StyledSpan = styled.span`
  font-family: Rubik;
  font-size: 16px;
  color: ${color.BLACK};
  margin: 1vh;
  display: flex;
  flex-direction: column;
  gap: 2px;
  &:after {
    content: "Medicine";
    font-family: Rubik;
    color: ${color.greys[100]};
    font-size: 12px;
  }
`;

function ProductTable(props) {
  const {
    classes,
    consultationDetails,
    modifyLineItem,
    getFormattedItemCategory,
    addLineItem,
    selectedSubscription,
    familyDetails,
    opdAmountValue,
  } = props;

  const items = consultationDetails.billing.items;

  const evitalItems = items.filter(
    (item) => item.type === itemTypes.EVITAL_MEDICINE
  );
  const otherItems = items.filter(
    (item) => item.type !== itemTypes.EVITAL_MEDICINE
  );
  const opdItems = items.filter((item) => item.opd_utilised === true);
  const differences = opdItems.map((item) => item.total - item.amount);
  // Sum up the differences
  const totalDifference = differences.reduce((acc, curr) => acc + curr, 0);
  const searchResults = consultationDetails?.itemSearch?.items?.products ?? [];
  const eVitalRxUrl = consultationDetails.billing.evital_redirect_url;

  // We create a ref for the main Product Table
  // and pass it down to the Quantity Picker
  // component. Ref changes don't trigger state updates,
  // hence the need to maintain a "mounted" state.
  // Once "mounted" is true, the ref to the parent elements
  // won't be null.
  let parentRef = useRef(null);
  const [parentMounted, setParentMounted] = useState(false);

  useEffect(() => {
    if (parentRef.current !== null) {
      setParentMounted(true);
    }
  }, [parentRef]);

  /**
   *
   * @param {String} value
   * @returns Whether or not the input value includes any of these disallowed characters:
   * - negative signs/hyphens
   */
  const validModification = (value) => {
    const rateRegEx = RegExp(/^(\d+\.{0,1}\d{0,2})$/g);
    if (rateRegEx.test(value) || value === "") {
      return true;
    }
    return false;
  };

  /**
   * Workaround for strange behaviour where input does not display item.rate
   * and instead becomes uncontrolled
   */
  const getItemRate = (item) => {
    if (item.rate === null) {
      return "";
    } else return item.rate;
  };

  /**
   *
   * @param {*} selectedValue The selected option, represented as an Object
   * @param {*} item The item in the billing array, being modified with `selectedValue`'s values
   *
   * This method is used to set the selected value to an object in the billing items array.
   * The discount is computed by looking at the subscriptions the user is mapped to
   * (HPP Plus pilot)
   */

  const handleItemSelection = debounce(async (selectedValue, item) => {
    const {
      discountComputation,
      auth,
      familyDetails,
      consultationDetails,
      setErrorSnackbar,
    } = props;
    const computedItemDiscount = discountComputation(selectedValue);
    const modifications = {
      name: selectedValue.display_name,
      rate: selectedValue.price,
      discount: computedItemDiscount,
      type:
        typeof selectedValue.category === "object"
          ? selectedValue.category[0]
          : selectedValue.category,
      id: selectedValue.id,
      external_id: selectedValue.id,
    };
    const lineItemToAddToOrder = {
      external_id: String(selectedValue.id),
      type:
        typeof selectedValue.category === "object"
          ? selectedValue.category[0]
          : selectedValue.category,
      quantity: 1,
      price: selectedValue.price,
      total_discount: computedItemDiscount.value,
      total_discount_type: computedItemDiscount.type,
      name: selectedValue.display_name,
      subscription_id: selectedSubscription?.id,
      metadata: {
        batch_id: "n/a",
        display_name: selectedValue.display_name,
      },
    };
    const createOrder = {
      external_id: String(selectedValue.id),
      type:
        typeof selectedValue.category === "object"
          ? selectedValue.category[0]
          : selectedValue.category,
      quantity: 1,
      price: selectedValue.price,
      total_discount: computedItemDiscount.value,
      total_discount_type: computedItemDiscount.type,
      name: selectedValue.display_name,
      metadata: {
        batch_id: "n/a",
        display_name: selectedValue.display_name,
      },
      subscription_id: selectedSubscription?.id,
    };
    console.log(createOrder, '----createOrder payload', selectedSubscription)
    const accessToken = auth.authDetails.data.access_token;
    const orderId = consultationDetails.billing?.order?.id;
    if (orderId) {
      if (!item.sync) {
        const response = await addLineItemsToOrder(
          accessToken,
          orderId,
          lineItemToAddToOrder
        );
        modifications.id = response.data.id;
        modifications.sync = true;
        modifications.uiTrackingId = v4();
        modifyLineItem(item.id, modifications);
      } else {
        modifyLineItemInOrder(accessToken, item.id, modifications)
          .then((res) => {
            modifications.id = item.id;
            modifyLineItem(item.id, modifications);
          })
          .catch((err) => {
            const error = buildCustomError(err);
            setErrorSnackbar(error);
          });
      }
    } else {
      const familyId = familyDetails.currentCustomer.family.id;
      props.createOrderForFamilyId(familyId, createOrder, false);
    }
  }, 200);

  /**
   *
   * @param {*} inputValue The value to be shown in the autocomplete text input
   * @param {*} item The item in the billing array, being modified with `selectedValue`'s values
   *
   * This method only modifies the value displayed in the AutoComplete text fields;
   */
  const handleInputOnChange = (inputValue, item) => {
    const modifications = {
      name: inputValue,
    };
    modifyLineItem(item.id, modifications);
  };

  /**
   * Dispatches the `addLineItem` redux action,
   * adding a new, blank line item to the bill *locally*.
   * This item will not be synced with the backend until
   * it is meaningfully modified.
   */
  const addItem = () => {
    addLineItem(itemTypes.GENERIC, null);
  };

  const updateOpdAmountForLineItem = (oldItemDetails, updateItem) => {
    const { familyDetails, consultationDetails } = props;
    let currentOPDUtilised = consultationDetails.billing.opd_amount_utilised || 0;
    let opdBalance = parseFloat((familyDetails.opdAmounts.data.data.current_amount - currentOPDUtilised).toFixed(2));
    let currentItemOpdUsed;
    const oldItemOPDUtilisation = oldItemDetails.opd_amount || 0;
    const discountedPrice = parseFloat(getDiscountOnUpdatedLineItem(updateItem).toFixed(2));
    console.log(discountedPrice, currentOPDUtilised, opdBalance, '----discount percentage before update');
    if (discountedPrice >= oldItemOPDUtilisation) {
      const amountDiff = parseFloat(Math.abs(discountedPrice - oldItemOPDUtilisation).toFixed(2));
      if (opdBalance >= amountDiff) {
        currentOPDUtilised = parseFloat((currentOPDUtilised + amountDiff).toFixed(2));
        opdBalance = parseFloat((opdBalance - currentOPDUtilised).toFixed(2))
        currentItemOpdUsed = discountedPrice;
      } else {
        currentOPDUtilised = parseFloat((currentOPDUtilised + opdBalance).toFixed(2));
        currentItemOpdUsed = parseFloat((oldItemOPDUtilisation + opdBalance).toFixed(2));
        opdBalance = 0;
      }
    } else {
      const amountDiff = parseFloat(Math.abs(discountedPrice - oldItemOPDUtilisation).toFixed(2));
      currentOPDUtilised = parseFloat((currentOPDUtilised - amountDiff).toFixed(2));
      opdBalance = parseFloat((opdBalance + amountDiff).toFixed(2));
      

      currentItemOpdUsed = parseFloat((oldItemOPDUtilisation - amountDiff).toFixed(2));
    }
    console.log(discountedPrice, currentItemOpdUsed, currentOPDUtilised, opdBalance, '----discount percentage after update');
    return { currentItemOpdUsed, currentOPDUtilised, opdBalance }
  };

  const updateLineItem = (item, isOPDUtilize) => {
    console.log(item, '------item', isOPDUtilize);
    const { auth, updateOPDUtilisedValue, consultationDetails } = props;
    const accessToken = auth.authDetails.data.access_token;
    const updatedLineItemPayload = {
      external_id: String(item.external_id),
      type: item.type,
      quantity: item.quantity,
      price: String(item.rate),
      total_discount: String(item.discount.value),
      total_discount_type: discountType.PERCENTAGE,
      metadata: {
        batch_id: item.batch_number,
        display_name: item.name,
        opd_utilised: false,
      },
      subscription_id: selectedSubscription?.id,
    };
    console.log(updatedLineItemPayload, '---updatedLineItemPayload');
    if (isOPDUtilize) {
      const opdDetails = updateOpdAmountForLineItem(item, updatedLineItemPayload);
      console.log(opdDetails, '----opdDetails');
      _.assign(updatedLineItemPayload.metadata, { opd_utilised: true, opd_amount: opdDetails.currentItemOpdUsed });
      console.log({ opd_utilised: true, opd_amount: opdDetails.currentItemOpdUsed }, '-----295')
      modifyLineItem(item.id, { opd_utilised: true, opd_amount: opdDetails.currentItemOpdUsed });
      updateOPDUtilisedValue(opdDetails.currentOPDUtilised);
    } else {
      const opdUsedForItem = item.opd_amount;
      modifyLineItem(item.id, { opd_utilised: false, opd_amount: null });
      updateOPDUtilisedValue((consultationDetails.billing.opd_amount_utilised || 0) - opdUsedForItem);
    }
    modifyLineItemInOrder(
      accessToken,
      item.id,
      updatedLineItemPayload
    );
  }

  return (
    <div className={classes.productTable} ref={parentRef}>
      <div className={classes.tableRow}>
        <div className={classes.header}>
          <div className={classes.subRow}>
            <div className={classes.col}>
              <span>&nbsp;</span>
            </div>
          </div>
          <div className={classes.subRow}>
            <div className={classes.col}>
              <span>Items</span>
            </div>
            <div className={classes.col}>
              <span>Batch Number</span>
            </div>
            <div className={classes.col}>
              <span>Rate</span>
            </div>
            <div className={classes.col}>
              <span>Quantity</span>
            </div>
            <div className={classes.col}>
              <span>Total</span>
            </div>
            <div className={classes.col}>
              <span>Discount</span>
            </div>
            {familyDetails?.opdAmounts?.data?.data?.covered_amount
            && familyDetails?.opdAmounts?.data?.data?.expires_at > Date.now() ? (
              <div className={classes.col}>
                <span>OPD Limit</span>
              </div>
            ) : (
              ""
            )}
            <div className={classes.col}>
              <span>Amount</span>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.itemTypeHeader}>
        <span>&nbsp;</span>
        <span>eVitalRx ITEMS</span>
      </div>
      <AnimatePresence exitBeforeEnter>
        {map(evitalItems, (item, index) => {
          return (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
              key={item.uiTrackingId + index}
              style={{ padding: "1vh 0px" }}
              layout
            >
              <div key={item.uiTrackingId} className={classes.tableRow}>
                <div className={classes.subRow}>
                  <div className={classes.deleteIcon}>
                    <Button disabled>
                      <Cross background={color.oranges[100]} />
                    </Button>
                  </div>
                </div>
                <div className={classes.subRow}>
                  <div className={classes.col}>
                    <StyledSpan>{item.name}</StyledSpan>
                  </div>
                  <div className={classes.col}>
                    {item.batch_number ?? "n/a"}
                  </div>
                  <div className={classes.col}>₹{item.rate}</div>
                  <div className={classes.col}>{item.quantity} ghg</div>
                  <div className={classes.col}>₹{round(item.total, 2)}</div>
                  <div className={classes.col}>{item.discount.value}%</div>
                  {familyDetails?.opdAmounts?.data?.data?.covered_amount && familyDetails?.opdAmounts?.data?.data?.expires_at > Date.now() ? (
                    <div className={classes.col}>
                      <Checkbox
                        style={{ color: "#F38B44" }}
                        disabled={opdAmountValue <= 0 || !(item.discount.value > 0)}
                        defaultChecked={item?.opd_utilised === true}
                        onChange={(e) => {
                          updateLineItem(item, e.target.checked);
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className={classes.col}>
                    <strong>₹{round(item.amount, 2)}</strong>
                  </div>
                </div>
              </div>
              <div
                key={item.uiTrackingId + "alert_container"}
                id={item.uiTrackingId + "alert_container"}
              ></div>
            </motion.div>
          );
        })}
      </AnimatePresence>
      <div className={classes.footerRow} key="open_evitalrx_button">
        <Button
          variant="contained"
          className={classes.openEvitalRxBtn}
          onClick={() => {
            /**
             * 3 cases:
             * 1. eVitalRx order already created, just switch to the tab
             * 2. an order exists, but not linked to eVitalRx. Add default line item to the bill
             * 	  and save the link.
             * 3. no order exists (non-consultation billing, PCC clicks on open evital straightaway).
             *    Create an order with a default line item and save the order ID/evital_redirect_url
             *    in the state.
             */
            if (props.consultationDetails.billing.evital_redirect_url) {
              if (
                (props.eVitalRxTab.current &&
                  props.eVitalRxTab.current.closed) ||
                !props.eVitalRxTab.current
              ) {
                props.eVitalRxTab.current = window.open(eVitalRxUrl, "_blank");
              } else {
                props.eVitalRxTab.current.focus();
              }
            } else {
              const familyId = props.familyDetails.currentCustomer.family.id;
              const orderId = props.consultationDetails.billing.order?.id;
              if (orderId) {
                props.addDefaultEvitalLineItemToOrder(orderId);
              } else {
                props.createOrderForFamilyId(familyId, null, true);
              }
            }
          }}
          startIcon={<TopRightArrow />}
          disabled={consultationDetails.billing.customer.id === ""}
        >
          {eVitalRxUrl ? "Open eVitalRx" : "New eVitalRx Order"}
        </Button>
        <Button
          variant="contained"
          className={classes.syncEvitalRxBtn}
          onClick={() => {
            // sync only eVitalRx line items.
            const orderId = consultationDetails.billing.order.id;
            props.syncLineItems(orderId, true);
          }}
          startIcon={<SyncIcon />}
          disabled={
            !consultationDetails.billing.evital_redirect_url ||
            consultationDetails.billing.customer.id === ""
          }
        >
          Sync eVitalRx Order
        </Button>
        <div className={classes.col}></div>
      </div>
      <div className={classes.itemTypeHeader}>
        <span>&nbsp;</span>
        <span>OTHER ITEMS</span>
      </div>
      {map(otherItems, (item, index) => {
        return (
          <div key={item.uiTrackingId + index} style={{ padding: "1vh 0px" }}>
            <div
              key={item.uiTrackingId}
              id={item.uiTrackingId}
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0, transition: { duration: 0.3 } }}
              className={classes.tableRow}
            >
              <div className={classes.subRow}>
                <div className={classes.deleteIcon}>
                  <Cross
                    onClick={() => {
                      if (item.sync && item.type !== itemTypes.GENERIC) {
                        const { auth, setErrorSnackbar, consultationDetails, updateOPDUtilisedValue } = props;
                        const accessToken = auth.authDetails.data.access_token;
                        deleteLineItemFromOrder(accessToken, item.id)
                          .then((res) => {
                            if (item.opd_utilised) {
                              const currentOPDUsed = consultationDetails.billing.opd_amount_utilised;
                              updateOPDUtilisedValue(currentOPDUsed-item.opd_amount);
                            }
                            modifyLineItem(item.id, { quantity: 0 });
                          })
                          .catch((err) => {
                            const error = buildCustomError(err);
                            setErrorSnackbar(error);
                          });
                      } else {
                        modifyLineItem(item.id, { quantity: 0 });
                      }
                    }}
                  />
                </div>
              </div>
              <div className={classes.subRow}>
                <div className={classes.col}>
                  {[
                    itemTypes.OTHER,
                    itemTypes.EVITAL_MEDICINE,
                    itemTypes.CONSULTATION,
                  ].includes(item.type) ? (
                    <TextField
                      placeholder="Enter item name"
                      error={Boolean(item.error.name)}
                      classes={{
                        root: classes.autocompleteRoot,
                        inputRoot: classes.autocompleteRoot,
                      }}
                      className={classes.textField}
                      autoFocus
                      style={{
                        width: "100%",
                        padding: "0px",
                      }}
                      helperText={`${getFormattedItemCategory(item)} ${
                        item.error.name ? `| ${item.error.name}` : ""
                      }`}
                      inputProps={{
                        classes: {
                          root: classes.autocompleteRoot,
                        },
                        className: classes.textField,
                      }}
                      type="search"
                      value={item.name}
                      multiline
                      onChange={(event) => {
                        const modifications = {
                          name: event.target.value,
                        };
                        modifyLineItem(item.id, modifications);
                      }}
                    />
                  ) : (
                    <AutoComplete
                      item={item}
                      options={searchResults}
                      handleInputOnChange={(inputValue) =>
                        handleInputOnChange(inputValue, item)
                      }
                      handleItemSelection={(selectedValue) =>
                        handleItemSelection(selectedValue, item)
                      }
                      getFormattedItemCategory={(item) =>
                        getFormattedItemCategory(item)
                      }
                    />
                  )}
                </div>
                <div className={classes.col}>{item.batch_number}</div>
                <div className={classes.col}>
                  {[
                    itemTypes.GENERIC,
                    itemTypes.CONSULTATION,
                    itemTypes.LAB_TEST,
                    itemTypes.SUBSCRIPTION,
                    itemTypes.MEDICINE,
                    itemTypes.EVITAL_MEDICINE,
                  ].includes(item.type) ? (
                    <>
                      {item.rate >= 0 && item.rate !== null
                        ? `₹${item.rate}`
                        : "-"}
                    </>
                  ) : (
                    <TextField
                      type="number"
                      style={{
                        outline: "none",
                        borderTop: "0px",
                        borderRight: "0px",
                        width: "100%",
                        padding: "0px",
                        fontFamily: "Rubik",
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderLeft: "0px",
                      }}
                      inputProps={{
                        min: 0,
                        style: {
                          textAlign: "end",
                        },
                        className: classes.textField,
                      }}
                      error={Boolean(item.error.rate)}
                      helperText={item.error?.rate}
                      placeholder="Enter ₹"
                      value={getItemRate(item)}
                      disabled={
                        item.name.length === 0 || item.available_quantity === 0
                      }
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-", ","].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onChange={(event) => {
                        const updatedValue = event.target.value;
                        if (validModification(event.target.value)) {
                          const { auth, setErrorSnackbar } = props;
                          const accessToken =
                            auth.authDetails.data.access_token;
                          const updatedLineItem = {
                            external_id: String(item.external_id),
                            type: item.type,
                            quantity: item.quantity,
                            price: String(updatedValue),
                            total_discount: String(item.discount.value),
                            total_discount_type: discountType.PERCENTAGE,
                            metadata: {
                              batch_id: item.batch_number,
                              display_name: item.name,
                            },
                          };
                          if (item?.opd_utilised === true) {
                            updatedLineItem.metadata.opd_utilised = true;
                            updatedLineItem.metadata.opd_amount =
                              totalDifference <= opdAmountValue
                                ? getDiscountOnUpdatedLineItem(updatedLineItem)
                                : round(opdAmountValue, 2);
                          }
                          modifyLineItemInOrder(
                            accessToken,
                            item.id,
                            updatedLineItem
                          )
                            .then((res) => {
                              const modifications = {
                                rate: updatedValue,
                              };
                              modifyLineItem(item.id, modifications);
                            })
                            .catch((err) => {
                              const error = buildCustomError(err);
                              setErrorSnackbar(error);
                            });
                        }
                      }}
                    />
                  )}
                </div>
                <div className={classes.col}>
                  <QuantityPicker
                    parentMounted={parentMounted}
                    item={item}
                    sumOfOpdItems={totalDifference}
                    opdTotalAmount={opdAmountValue}
                    parentRef={parentRef}
                    subscriptionId={selectedSubscription?.id}
                    familyDetails={familyDetails}
                    updateOPDUtilisedValue={((balanceRemaining) => props.updateOPDUtilisedValue(balanceRemaining))}
                    updateOpdAmountForLineItem={updateOpdAmountForLineItem}
                  />
                </div>
                <div className={classes.col}>₹{round(item.total, 2)}</div>
                <div className={classes.col}>
                  <TextField
                    type="text"
                    style={{
                      padding: "0px",
                      outline: "none",
                      borderTop: "0px",
                      borderRight: "0px",
                      width: "100%",
                      fontFamily: "Rubik",
                      fontWeight: "normal",
                      fontSize: "16px",
                      borderLeft: "0px",
                    }}
                    inputProps={{
                      min: 0,
                      style: {
                        textAlign: "end",
                      },
                      className: classes.textField,
                    }}
                    value={item.discount.value}
                    error={Boolean(item.error.discount)}
                    helperText={
                      Boolean(item.error.discount) && item.error.discount
                    }
                    disabled={item.rate === 0 || !item.rate}
                    onChange={(event) => {
                      const updatedValue = event.target.value;
                      if (
                        validModification(updatedValue) &&
                        item.rate !== 0 &&
                        parseFloat(Number(updatedValue)) <= parseFloat(100.0) &&
                        parseFloat(Number(updatedValue)) >= 0
                      ) {
                        const { auth, setErrorSnackbar, updateOPDUtilisedValue } = props;
                        const accessToken = auth.authDetails.data.access_token;
                        const updatedLineItem = {
                          external_id: String(item.external_id),
                          type: item.type,
                          quantity: item.quantity,
                          price: String(item.rate),
                          total_discount: updatedValue,
                          total_discount_type: discountType.PERCENTAGE,
                          metadata: {
                            batch_id: item.batch_number,
                            display_name: item.name,
                          },
                        };
                        let opdDetails;
                        if (item?.opd_utilised === true) {
                          updatedLineItem.metadata.opd_utilised = true;
                          opdDetails = updateOpdAmountForLineItem(item, updatedLineItem);
                          updatedLineItem.metadata.opd_amount = opdDetails.currentItemOpdUsed;
                        }
                        modifyLineItemInOrder(
                          accessToken,
                          item.id,
                          updatedLineItem
                        )
                          .then((res) => {
                            const modifications = {
                              discount: {
                                type: "percentage",
                                value: updatedValue,
                              },
                            };
                            if (item?.opd_utilised === true) {
                              _.assign(modifications, { opd_amount: opdDetails.currentItemOpdUsed })
                              updateOPDUtilisedValue(opdDetails.currentOPDUtilised);
                            }
                            modifyLineItem(item.id, modifications);
                          })
                          .catch((err) => {
                            const error = buildCustomError(err);
                            setErrorSnackbar(error);
                          });
                      }
                    }}
                    InputProps={{
                      endAdornment: "%",
                    }}
                  />
                </div>
                {familyDetails?.opdAmounts?.data?.data?.covered_amount && familyDetails?.opdAmounts?.data?.data?.expires_at > Date.now() ? (
                  <div className={classes.col}>
                    {
                      familyDetails?.opdAmounts?.data?.data?.current_amount === 0
                      ? <div style={{ position: 'relative' }}>
                        <div style={{  position: 'absolute', width: '8px', height: '2px', backgroundColor: 'white', top: '42%', left: '50%', transform: 'translate(-50%,-50%)' }}> </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                      <path d="M13.333 0.152832C14.8059 0.152832 16 1.34689 16 2.81983V13.4858C16 14.9588 14.8059 16.1528 13.333 16.1528H2.667C1.19406 16.1528 0 14.9588 0 13.4858V2.81983C0 1.34689 1.19406 0.152832 2.667 0.152832H13.333Z" fill="#808080"/>
                    </svg>
                        </div>
                      : !(item.discount.value > 0) ? <div className={classes.checkboxContainer} style={{ position: 'relative', left: '-10%' }}>
                      <div style={{  position: 'absolute', width: '8px', height: '2px', backgroundColor: 'white', top: '42%', left: '50%', transform: 'translate(-50%,-50%)' }}> </div>
                      <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path fill="#F38B44" d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path></svg>
                      </div> :<div style={{ position: 'relative'}} className={classes.checkboxContainer}>
                        <Checkbox
                        className={`${(opdAmountValue <= 0 || !(item.discount.value > 0) )? classes.disabledCheckbox : ''}`}
                      // style={{ color: "#F38B44", cursor: `${opdAmountValue === 0 || !item.discount.value ? 'not-allowed': 'pointer'}` }}
                      style={{ color: "#F38B44" }}
                      disabled={opdAmountValue <= 0}
                      defaultChecked={item?.opd_utilised === true}
                      onChange={(e) => {
                        // if (opdAmountValue === 0 || !item.discount.value) return;
                        updateLineItem(item, e.target.checked);
                      }}
                    />
                    {/* {(opdAmountValue === 0 || !item.discount.value) && <CancelIcon className={classes.banIcon} />} */}
                        </div>
                    }
                    
                  </div>
                ) : (
                  ""
                )}
                <div className={classes.col}>
                  <strong>₹{round(item.amount, 2)}</strong>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div className={classes.footerRow} key="add_button">
        <div className={classes.col}>
          <Button
            className={classes.addProductBtn}
            variant="contained"
            onClick={addItem}
            startIcon={"+"}
            disabled={consultationDetails.billing.customer.id === ""}
          >
            Add Item
          </Button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { familyDetails, consultationDetails, auth } = state;
  return {
    familyDetails,
    consultationDetails,
    auth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  modifyLineItem: (itemId, modifications) => {
    dispatch(consultationActions.modifyLineItem(itemId, modifications));
  },
  addLineItem: (itemType, item) => {
    dispatch(consultationActions.addLineItem(itemType, item));
  },
  searchBillingItems: (item, clinic) => {
    dispatch(consultationActions.searchBillingItems(item, clinic));
  },
  updateBilledCustomer: (customer) => {
    dispatch(consultationActions.updateBilledCustomer(customer));
  },
  createOrderForFamilyId: (familyId, orderData, linkToEvitalRx = false) => {
    dispatch(
      consultationActions.createOrderForFamilyIdWithLineItem(
        familyId,
        orderData,
        linkToEvitalRx
      )
  );
  },
  addDefaultEvitalLineItemToOrder: (orderId) => {
    dispatch(consultationActions.addDefaulteVitalLineItemToOrder(orderId));
  },
  setErrorSnackbar: (error) => {
    dispatch(setError(error));
  },
  updateOPDUtilisedValue: (balanceRemaining) => {
    dispatch(consultationActions.updateOPDUtilisedValue(balanceRemaining));
  },
});

const styleWrapper = withStyles(useStyles, { withTheme: true });
const reduxWrapper = connect(mapStateToProps, mapDispatchToProps);

export default styleWrapper(reduxWrapper(ProductTable));
