export const clientRoute = {
	HOME_PAGE: "/homePage",
	CONSULTATIONS_TODAY_PAGE: "/consultations-today",
	PRODUCTS_PAGE: "/products",
	ADMIN_PAGE: "/admin",
	SALES_PAGE: "/sales",
	// TICKETING_PAGE: "/ticketing",
	CARE_DOC_UPLOAD_PAGE: "/care-upload",
	VISITS_PAGE: "/visits",
	DOCTORS_PAGE: "/doctors",
	CUSTOMER_LENS_PAGE: "/customerLens",
	MEMBERSHIP_PAGE: "/membership",
};
