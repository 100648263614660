import React from "react";
import PropTypes from "prop-types";

function Path({
  clipPath,
  clipRule,
  d,
  fill,
  fillOpacity,
  fillRule,
  stroke,
  strokeLinecap,
  strokeLinejoin,
  strokeWidth,
}) {
  return (
    <path
      d={d}
      clipPath={clipPath}
      clipRule={clipRule}
      fill={fill}
      fillRule={fillRule}
      fillOpacity={fillOpacity}
      stroke={stroke}
      strokeLinecap={strokeLinecap}
      strokeLinejoin={strokeLinejoin}
      strokeWidth={strokeWidth}
    />
  )
}

export default Path;

Path.propTypes = {
  clipPath: PropTypes.string,
  clipRule: PropTypes.oneOf(["evenodd", "nozero"]),
  d: PropTypes.string,
  fill: PropTypes.string,
  fillOpacity: PropTypes.number,
  fillRule: PropTypes.oneOf(["evenodd", "nozero"]),
  stroke: PropTypes.string,
  strokeLinecap: PropTypes.oneOf(["butt", "round", "square"]),
  strokeLinejoin: PropTypes.oneOf(["bevel", "miter", "round"]),
  strokeWidth: PropTypes.string,
};
