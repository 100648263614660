import validateEnvIsString from "./schema";

const care_template_url = validateEnvIsString(
	process.env.REACT_APP_CARE_TEMPLATE_URL
);

const care_error_template_url = validateEnvIsString(
	process.env.REACT_APP_CARE_ERROR_TEMPLATE_URL
);

const care_renewal_template_url = validateEnvIsString(
	process.env.REACT_APP_CARE_RENEWAL_TEMPLATE_URL
);

let config;
let HEADQUARTERS_ID;
let defaultEvitalLineItem = "bgeMDFOz+9qwgUp9C8/Rvw==";
let careWalletUrl = "https://alpha.carefirst.clinikk.com";
let careWalletApiKey = "e9Q2CWuLUSFGE6Rm9JcsUGMe21lL7sfW8puDf1QmbOM=";
let medblocksToken = '';
if (process.env.REACT_APP_ENV === "production") {
	config = {
		accountsURL: "https://accounts.clinikk.com",
		apiURL: "https://api.clinikk.com",
		appURL: "https://app.clinikk.com",
		clientID: "jMFCEkZ7ats8A2Ce",
		clientSecret: "9HmY3Fsr2ykc9jbvgEJBtr9ExZ53KLaT",
		timeout: 5000,
		care_template_url,
		care_error_template_url,
		care_renewal_template_url,
	};
	HEADQUARTERS_ID = "5e7b9bb0b4c95b1fa7f8fe04";
	careWalletUrl = "https://beta.carefirst.clinikk.com";
	careWalletApiKey = "oZuIt1xWBNkvcJYtlLINn7jGbcEFcFjalqs1VXyhSXg=";
	medblocksToken = 'QnRpSvnCacD31tDGHWPbatrUdc5jY9ZRRBoMjogT58YRqSD73GRQzQSVS2i9djSPy';
} else if (process.env.REACT_APP_ENV === "preview") {
	config = {
		accountsURL: "https://accounts.clinikk.com",
		apiURL: "https://api.clinikk.com",
		appURL: "https://preview.app.clinikk.com",
		clientID: "jMFCEkZ7ats8A2Ce",
		clientSecret: "9HmY3Fsr2ykc9jbvgEJBtr9ExZ53KLaT",
		timeout: 5000,
		care_template_url,
		care_error_template_url,
		care_renewal_template_url
	};
	HEADQUARTERS_ID = "5e7b9bb0b4c95b1fa7f8fe04";
	careWalletUrl = "https://beta.carefirst.clinikk.com";
	careWalletApiKey = "oZuIt1xWBNkvcJYtlLINn7jGbcEFcFjalqs1VXyhSXg=";
} else if (process.env.REACT_APP_ENV === "qa") {
	config = {
		accountsURL: "https://accounts.qa.clinikk.com",
		apiURL: "https://api.qa.clinikk.com",
		appURL: "https://app.qa.clinikk.com",
		clientID: "d27d8892d040cb9b",
		clientSecret: "e29ee1b70bf40d275d3fcab338cb713d",
		timeout: 5000,
		care_template_url,
		care_error_template_url,
		care_renewal_template_url
	};
	HEADQUARTERS_ID = "62c7d69523f817ee4dc5833b";
	defaultEvitalLineItem = "gv0GokYn9w4zFL51eouS2g==";
} else {
	config = {
		accountsURL: "https://accounts.stage.clinikk.com",
		apiURL: "https://api.stage.clinikk.com",
		appURL: "https://app.stage.clinikk.com",
		clientID: "d27d8892d040cb9b",
		clientSecret: "e29ee1b70bf40d275d3fcab338cb713d",
		timeout: 5000,
		care_template_url,
		care_error_template_url,
		care_renewal_template_url
	};
	HEADQUARTERS_ID = "62c7d69523f817ee4dc5833b";
	defaultEvitalLineItem = "gv0GokYn9w4zFL51eouS2g==";
}

export default config;
export {
	HEADQUARTERS_ID,
	defaultEvitalLineItem,
	careWalletUrl,
	careWalletApiKey,
	medblocksToken,
};
