import axios from 'axios';
import axiosRetry from 'axios-retry';

import config from '../config';

const axiosClient = axios.create({ baseURL: config.apiURL });
axiosRetry(axiosClient, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 2000; // time interval between retries
  },
  retryCondition: (error) => {
    // retrying only when getting 500 (Internal Server Error) as response code
    return error.response.status === 500;
  },
});

export const getFamilyDetails = async (ACCESS_TOKEN, query) => {
  let url = `${config.apiURL}/v3/customers${query}`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,
    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.get(url, options);
  return response;
};

export const getOpdBalance = async (ACCESS_TOKEN, subscriptionId) => {
  let url = `${config.apiURL}/v1/subscriptions/${subscriptionId}/opd-account`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,
    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.get(url, options);
  return response;
};

export const getOpdTransactions = async (ACCESS_TOKEN, subscriptionId, page) => {
  let url = `${config.apiURL}/v1/subscription/opd-transactions?subscriptionId=${subscriptionId}`;
  if (page !== undefined) {
    url += `&page=${page}`;
  }
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,
    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.get(url, options);
  return response;
};

export const getCouponDiscount = async (coupon) => {
  let url = `${config.apiURL}/v1/coupons/${coupon}`;
  const options = {
    headers: {
      'content-type': 'application/json',
    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.get(url, options);
  return response;
};

export const getSubscriptionDetails = async (ACCESS_TOKEN, familyId, payload) => {
  const url = `${config.apiURL}/v2/operations/family/${familyId}/subscriptions?page=${payload.page}&size=${payload.size}`
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,
    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.get(url, options);
  return response;
};

export const getHealthDeclarationTnC = async (accessToken, version, documentType, subscriptionId) => {
  const url = `${config.apiURL}/v1/tnc?version=${version}&documentType=${documentType}&subscriptionId=${subscriptionId}`;
  const apiTimeOut = () => {
    return (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'production') ? 20000 : 5000;
  }
  const options = {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${accessToken}`,
    },
    timeout: apiTimeOut,
    responseType: 'json',
    responseEncoding: 'utf8',
    url,
  };
  const result = await axios(options);
  return result;
}

export const getBenefitCardDetails = async (ACCESS_TOKEN, customerId) => {
  const url = config.apiURL + `/v3/customers/${customerId}/benefit-cards`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,
    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.get(url, options);
  return response;
};

export const getProductsByBusinessId = async (ACCESS_TOKEN, businessId) => {
  const url = config.apiURL + `/v2/products?businessId=${businessId}`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,
    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.get(url, options);
  return response;
};

export const createTrialSubscription = async (ACCESS_TOKEN, familyId) => {
  const url = config.apiURL + `/v3/family/${familyId}/subscriptions/trial`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,
    },
    timeout: 10000,
  };
  const response = await axios.post(url, undefined, options);
  return response;
}

export const sendPlanChangeLink = async (ACCESS_TOKEN, subscriptionId, payload,) => {
  const url = config.apiURL + `/v1/internal/subscriptions/${subscriptionId}/plan-change`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,
    },
    timeout: 10000,
  };
  const response = await axios.post(url, payload, options);
  return response;
}

export const cancelSubscriptionById = async (ACCESS_TOKEN, subscriptionId, payload) => {
  const url = config.apiURL + `/v3/subscriptions/${subscriptionId}/cancel`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,
    },
    timeout: 10000,
  };
  const response = await axiosClient.post(url, payload, options);
  return response;
}

export const withdrawSubscriptionById = async (ACCESS_TOKEN, subscriptionId) => {
  const url = config.apiURL + `/v3/subscriptions/${subscriptionId}/withdraw`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,
    },
    timeout: 10000,
  };
  const response = await axios.post(url, undefined, options);
  return response;
}

export const searchAllProductsByBusinessId = async (ACCESS_TOKEN, businessId) => {
  const url = config.apiURL + `/v3/products/search`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,
    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.post(url, { business: { id: businessId } }, options);
  return response;
};

export const createNewSubscription = async (ACCESS_TOKEN, newSubscription) => {
  const url = config.apiURL + `/v3/subscriptions`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,
    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.post(url, newSubscription, options);
  return response;
}

export const getAllSubscriptionsByDatePaginated = async (ACCESS_TOKEN, currentPage, itemsPerPage, startDate, endDate, subscriptionStatus) => {
  const url = config.apiURL + `/v4/subscriptions`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,
    },
    params: {
      policyStatus: 'pending',
      subscriptionStatus: subscriptionStatus,
      subscriptionStartAfter: startDate,
      subscriptionStartBefore: endDate,
      page: Number(currentPage),
      pageSize: Number(itemsPerPage),
      sortKey: 'start_date',
    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.get(url, options
  );
  return response;
}

export const activatePolicyBySubscriptionId = async (ACCESS_TOKEN, subscriptionId, body) => {
  const url = config.apiURL + `/v1/subscriptions/${subscriptionId}/onboarding`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,

    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.post(url, body, options);
  return response;
}

export const connectTeleCallByPartnerId = async (ACCESS_TOKEN, partnerId, phoneNumber) => {
  const url = config.apiURL + `/v1/operations/ivr/connect-telecall`;
  let body = {
    partner_id: partnerId,
    customer_phone: phoneNumber,
  }
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,

    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.post(url, body, options);
  return response;
}

export const updateSubscriptionTnC = async (ACCESS_TOKEN, subscriptionId, familyId, tncStatus, comments) => {
  const url = config.apiURL + `/v1/tnc/response`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,
    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
    method: 'PUT',
    data: {
      subscriptionId,
      familyId,
      tncStatus,
      comments,
    },
    url,
  };
  const response = await axios(options);
  return response;
}