import axios from 'axios';

import config from '../config';

const validateCoupon = async (accessToken, couponCode, data) => {
  const url =`${config.apiURL}/v1/coupons/${couponCode}/validate`;
  const options = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${accessToken}`,
    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
    data,
    url,
  };
  
  const result = await axios(options);
  return result;
};

const applyCouponToOrder = async (accessToken, orderId, coupon) => {
  const url =`${config.apiURL}/v3/orders/${orderId}/apply-coupon`;
  const options = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${accessToken}`,
    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
    data: {
      discount: {
        code: coupon,
      }
    },
    url,
  };
  
  const result = await axios(options);
  return result;
};

export const couponService = {
    validateCoupon, 
    applyCouponToOrder,  
};
