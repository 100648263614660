import { FIND_ADDRESS_BY_ZIPCODE, LOG_OUT, RESET_ADDRESS_BY_ZIPCODE, FIND_CUSTOMER_BY_PHONE_NUMBER, CREATE_NEW_CUSTOMER, CREATE_NEW_ADDRESS, CREATE_FREE_SUBSCRIPTION, SET_NEW_FAMILY_ID, RESET_CREATE_ADDRESS_RESPONSE, RESET_CREATE_NEW_CUSTOMER } from '../actions/types';
import { addressActionTypes } from '../constants/addressConstant';
const INITIAL_STATE = {

};

const addNewCustomerReducer = (state = { INITIAL_STATE }, action) => {
    switch (action.type) {
        case FIND_ADDRESS_BY_ZIPCODE:
            return { ...state, zipcodeDetails: action.payload }
        case RESET_ADDRESS_BY_ZIPCODE:
            return { ...state, zipcodeDetails: null }
        case FIND_CUSTOMER_BY_PHONE_NUMBER:
            return { ...state, currentCustomer: action.payload.currentCustomer, newFamilyId: null }
        case LOG_OUT:
            return { consulationForm: null, createConsultationResponse: null, customerConsultations: null } //change
        case CREATE_NEW_CUSTOMER:
            return { ...state, createHeadSubscriber: action.payload }
        case RESET_CREATE_NEW_CUSTOMER:
                return { ...state, createHeadSubscriber: null }
        case CREATE_NEW_ADDRESS:
            return { ...state, createNewAddress: action.payload }
        case RESET_CREATE_ADDRESS_RESPONSE:
            return { ...state, createNewAddress: null }
        case CREATE_FREE_SUBSCRIPTION:
            return { ...state, createFreeSubscription: action.payload }
        case SET_NEW_FAMILY_ID:
            return { ...state, newFamilyId: action.payload }
        case addressActionTypes.GET_ADDRESSES_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case addressActionTypes.GET_ADDRESSES_SUCCESS:
            return {
                ...state,
                addresses: action.payload,
                isLoading: false
            }
        case addressActionTypes.GET_ADDRESSES_FAILURE:
            return {
                ...state,
                isLoading: false,
                addresses: [],
            }
        case addressActionTypes.ADD_ADDRESS_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case addressActionTypes.ADD_ADDRESS_SUCCESS:
            return {
                ...state,
                addresses: action.payload,
                isLoading: false
            }
        case addressActionTypes.ADD_ADDRESS_FAILURE:
            return {
                ...state,
                isLoading: false
            }
        case addressActionTypes.UPDATE_ADDRESS_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case addressActionTypes.UPDATE_ADDRESS_SUCCESS:
            return {
                ...state,
                addresses: action.payload,
                isLoading: false
            }
        case addressActionTypes.UPDATE_ADDRESS_FAILURE:
            return {
                ...state,
                isLoading: false
            }
        case addressActionTypes.FIND_ADDRESS_BY_ZIPCODE_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case addressActionTypes.FIND_ADDRESS_BY_ZIPCODE_SUCCESS:
            return {
                ...state,
                pincodeDetails: action.payload,
                isLoading: false
            }
        case addressActionTypes.FIND_ADDRESS_BY_ZIPCODE_FAILURE:
            return {
                ...state,
                isLoading: false
            }
        case addressActionTypes.RESET_ZIPCODE:
            return {
                ...state,
                pincodeDetails: null
            }
        case addressActionTypes.RESET_ADDRESSES:
            return {
                ...state,
                addresses: []
            }
        default:
            return state;
    }
};

export default addNewCustomerReducer;
