import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Button, Typography, Paper, Drawer } from '@material-ui/core';
import EditMember from './EditMemberForm';
import { openMemberDrawer, closeMemberDrawer } from '../../../actions';
import { color } from '../../../constants/colors';
import Occupation from '../Occupation/Occupation'

const { getISTFormat2 } = require('../../../common/utils');
const { accessRole } = require('../../../common/constants');

function createData(name, calories) {
    return { name, calories };
}
class MiddleTab extends React.Component {

    state = { right: false, openOccupationDrawer: false};  // change to false

    toggleDrawer = (side, open) => () => {
        this.setState({
          [side]: open,
        });
      };


    handleOccupationEdit() {
        this.setState({
            openOccupationDrawer: true,
        })
    }

    closeOccupationDrawer() {
        this.setState({
            openOccupationDrawer: false,
        })
    }

    render() {
        let name = this.props.member.first_name;
        let memberId = this.props.member.id;
        if (this.props.member.last_name) { name = name + ' ' + this.props.member.last_name }
        const { openOccupationDrawer } =this.state;
        const rows = [
            createData('Name', name),
            createData('DOB', this.props.member.dob ? getISTFormat2(this.props.member.dob) : '-'),
            createData('Gender', this.props.member.gender ? _.capitalize(this.props.member.gender) : '-'),
            createData('Mother Tongue', this.props.member.lop ? _.capitalize(this.props.member.lop) : '-'),
            createData('Relationship to H.S.', this.props.member.relationship ? _.capitalize(this.props.member.relationship) : '-'),
            createData('Occupation', this.props.member.metadata && this.props.member.metadata.occupation ? _.capitalize(this.props.member.metadata.occupation) : null),
            createData('Contact Number', this.props.member.primary_number ? this.props.member.primary_number : '-'),
        ];
        return (
            
            <div style= {{ position: 'relative'}}>
                        <Drawer disableEnforceFocus anchor="right"
                            open={openOccupationDrawer}
                            onClose={() => this.closeOccupationDrawer()}>
                            <Paper style={{ width: '50vh', height: '100%' }}>
                                <Occupation memberId={memberId} closeOccupationDrawer={() => this.closeOccupationDrawer() } />
                            </Paper>
                        </Drawer>
                <Table style={{
                    position: 'absolute',
                    minWidth: 100,
                    borderTop: 'none',
                    borderBottom: 'none !important',
                }} aria-label="simple table">
                    <TableBody style={{ overflowX: 'scroll' }}>
                        {rows.map(row => (
                            <TableRow key={row.name}>
                                <TableCell style={{ fontWeight: '700', border: 'none', color: '#172a3a', padding: '12px 24px', fontSize: '14px', fontFamily: 'Rubik' }} component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                {
                                    row.name === 'Occupation' && (!this.props.member.metadata || !this.props.member.metadata.occupation) &&
                                    <TableCell style={{ border: 'none' }}><Typography onClick={() => this.handleOccupationEdit()} style={{color: color.ROYAL_ORANGE, cursor: 'pointer', padding: '0px', fontWeight: 'normal', fontFamily: 'Rubik', fontSize: '14px'}}>Enter Occupation</Typography></TableCell>
                                }
                                {
                                    (row.name !== 'Occupation' || row.calories) && <TableCell style={{ fontWeight: 'normal', border: 'none', color: '#172a3a', padding: '12px', fontSize: '14px', fontFamily: 'Rubik' }} component="th" scope="row">{row.calories}</TableCell>
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
               
                {this.props.auth && this.props.auth.authDetails && !_.isNil(_.find(this.props.auth.authDetails.data.user.roles, { name: accessRole.HEALTH_MANAGER })) ? 
                <Button  variant= 'outlined' style={{
                   
                    borderColor: 'orange', 
                    left: '20%', right: '20%', width: '60%', position: 'absolute', top: '55vh',
                }}
                    onClick={() => this.props.openMemberDrawer()}
                >
                    <Typography style={{
                        fontSize: '13px',
                        color: 'orange',
                        textTransform: 'capitalize'
                    }}> Edit Details</Typography>
                </Button> : <> </>}

                < Drawer anchor="right" open={this.props.familyDetails.updateMemberDrawerStatus} onClose={() => this.props.closeMemberDrawer()}>
          <Paper style={{ width: '50vh', height: '100%'}}>
            {/* <ConsultationForm customerId={this.props.customerId} /> */}
            <br/>
            <br/>
            <EditMember member = {this.props.member} />
          </Paper>
        </Drawer>
            </div>
        );
    };
};

const mapStateToProps = (state) => {
    return { auth: state.auth, familyDetails: state.familyDetails, error: state.error };
};


export default connect(mapStateToProps, { openMemberDrawer, closeMemberDrawer })(MiddleTab);
