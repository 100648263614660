import { color } from "../../../constants/colors";

const styles = (theme) => ({
  indicator: {
    backgroundColor: "orange",
    fontFamily: "Rubik",
    width: "30px!important",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginLeft: "60px",
  },
  default_tabStyle: {
    color: "grey",
    fontFamily: "Rubik",
    width: "30px",
  },
  appBarColumn: {
    background: color.WHITE,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "80%",
  },
  tabs: {
    justifyContent: "flex-start",
  },
  badges: {
    padding: "0px 1vw",
    justifyContent: "flex-end",
  },
  active_tabStyle: {
    fontWeight: "bold",
    color: "black",
    fontFamily: "Rubik",
    width: "30px",
  },
  OnboardPendingStatus: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "14px",
    letterSpacing: "0.8px",
    textTransform: "uppercase",

    /* StatusYellow1 */

    color: "#FFA300",
    // marginLeft: '24px',
  },
  endorsementStatus: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "14px",
    letterSpacing: "0.8px",
    textTransform: "uppercase",
    color: "#FFA300",
  },
  activeStatus: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "14px",
    letterSpacing: "0.8px",
    textTransform: "uppercase",

    color: "#0D7E51",
  },
  tabContentContainer: {
    position: "relative",
    overflowY: "scroll",
    border: "0.5px solid #f4f4f4",
    borderRight: "none",
    borderLeft: "none",
    borderBottom: "none",
    backgroundColor: "white",
    width: "49%",
    overflowX: "hidden",
  },
  productName: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "17px",

    marginBottom: "4px",
  },
  subscriptionId: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "17px",

    marginBottom: "4px",
    color: 'gray'
  },
  productSubscriptionStatus: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "14px",
    letterSpacing: "0.8px",
    textTransform: "uppercase",
  },
  sectionTitle: {
    fontFamily: "Rubik",
    fontWeight: "bold",
    fontSize: "18px",
  },
  mandateCard: {
    padding: "16px",
    margin: "16px 0px",
    width: "100%",
    border: `1px solid ${color.LIGHT_GREY}`,
    borderRadius: "16px",
  },
  mandateCardRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "&:not(:first-child)": {
      marginTop: "16px",
    },
  },
  mandateCardRowItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
  },
  date: {
    fontFamily: "Rubik",
    fontWeight: "400",
    fontSize: "16px",
  },
  divider: {
    marginTop: "16px",
  },
  mandateCardHeaderTitle: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
  },
  mandateCardSubContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginLeft: "16px",
    marginBottom: "17px",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    color: "#808080",
  },
  mandateCardInfo: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    color: color.GREY,
  },
  mandateCardSubInfo: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    color: color.GREY,
    marginRight: "8px",
  },
  mandateCardUpiText: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: color.BLACK,
    marginRight: "8px",
    background: "#FFF1E8",
    padding: "4px 6px",
    borderRadius: "4px",
  },
  mandateStatusInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 12px",
    height: "22px",
    borderRadius: "16px",
  },
  mandateStatusInfoText: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    // color: '#FFA300',
    textTransform: "uppercase",
  },
  notAvailableText: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "17px",
  },
  mandateCardDateContainer: {
    marginLeft: "16px",
    marginRight: "16px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mandateAmountContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "16px",
  },
  mandateAmountText: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    color: color.GREY,
    lineHeight: "19px",
    // marginBottom: '12px'
  },
  mandateAmountTextInfo: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    color: color.BLACK,
    lineHeight: "19px",
    // marginBottom: '12px'
  },
  btnContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  circleBtnOrange: {
    width: "43px",
    height: "43px",
    borderRadius: "50%",
    background: color.ROYAL_ORANGE,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  btnTitle: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "14px",
    textAlign: "center",
    textTransform: "capitalize",
    color: color.BLACK,
    marginTop: "12px",
  },
  sendRequestOrangeBtn: {
    background: "#F38B44",
    color: "#FFFFFF",
    padding: "8px 16px 8px 16px",
    height: "36px",
    borderRadius: "5px",
    marginLeft: "24px",
    marginRight: "24px",
    textTransform: "none",
    "&:hover": {
      background: "#F38B44",
      color: "#FFFFFF",
    },
  },
  plansListContainer: {
    border: `0.5px solid ${color.CULTURED}`,
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "none",
    backgroundColor: color.WHITE,
    width: "23%",
    paddingBottom: "40px",
    textAlign: "center",
    overflowY: "scroll",
    "&::-webkit-scrollbar-thumb": {
      "background-color": "transparent",
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        "background-color": color.LIGHT_GREY_TEXT,
      },
    },
  },
  planDetailsContainer: {
    border: `0.5px solid ${color.CULTURED}`,
    borderBottom: "none",
    backgroundColor: color.WHITE,
    width: "28%",
    textAlign: "center",
    overflowY: "scroll",
    "&::-webkit-scrollbar-thumb": {
      "background-color": "transparent",
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        "background-color": color.LIGHT_GREY_TEXT,
      },
    },
  },
  tooltip: {
    color: "#FFFFFF",
    fontSize: "12px",
    fontWeight: "400",
  },
  noMandateImage: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  mandateNotPresentText: {
    fontFamily: "Rubik",
    fontSize: "12px",
    color: color.GREY,
  },
  showPointer: {
    cursor: "pointer",
  },
  copiedChip: {
    height: "20px",
    color: "green",
    borderColor: "green",
    marginLeft: "6px",
  },
  recordActivityCta: {
    borderRadius: "6px",
    fontFamily: "Rubik",
    fontWeight: "bold",
    fontSize: "13px",
    color: color.WHITE,
    background: color.ROYAL_ORANGE,
    padding: "0.5vh 2vh",
    height: "28px",
    outline: "none",
    border: "0px",
    marginRight: "1vh",
    cursor: "pointer",
    transition: "0.125s ease-in all",
    textTransform: "none",
    "&:hover": {
      background: color.ORANGE,
    },
  },
  reconButton: {
    color: color.WHITE,
    background: color.ROYAL_ORANGE,
    borderRadius: "4px",
    padding: "0.5vh 1vh",
    textTransform: "capitalize",
    fontSize: "13px",
    fontFamily: "Rubik",
    fontWeight: "bold",
    margin: "0px 2vh",
    height: "28px",
    "&:hover": {
      background: color.ORANGE,
    },
  },
  optionalAddonDescription: {
    fontFamily: 'Rubik',
    fontSize: '0.8rem',
    color: color.greys[100],
    paddingBottom: '0.5rem'
  },
  noServicesContainer: {
    height: '85%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  noMandateCaption: {
    margin: '20px',
    display: 'inline-block',
    fontFamily: 'Rubik',
    color: color.greys[100],
    fontSize: '0.8rem',
  }
});

export default styles;
