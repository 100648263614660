import React from 'react';
import {
    Box,
    withStyles,
    Drawer,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PreferenceCard from '../../common/PreferenceCard/PreferenceCard';
import ConfirmationDrawer from '../../common/ConfirmationDrawer/ConfirmationDrawer';
import GhdApprovalDrawer from '../../common/GhdApprovalDrawer/GhdApprovalDrawer';
import useStyles from '../style';
import { updateCohortEvent, updateWhatsAppConsent, updateTNC, resetTNC, getHealthDeclarationTnCData } from '../../../actions';
import { tncStatus } from '../../../common/constants';

class CustomerPreference extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCancelPlanDrawer: false,
            showGhdApprovalDrawer: false,
            preferenceList: [
                {
                    heading: 'Does any plan member:',
                    optionSelected: ''
                },
                {
                    heading: 'Is the customer open to communication via Whatsapp?',
                    subHeading: `Communication includes sending prescriptions, notifications, offers and updates related to
                    Clinikk healthcare plan and support.`,
                    prefrenceOptions: [
                        {
                            name: 'Yes',
                            value: 'granted'
                        },
                        {
                            name: 'No',
                            value: 'denied'
                        }
                    ],
                    optionSelected: ''
                },
            ]
        }
    }

    componentDidMount() {
        const { newPlan, resetTNCEvent, selectedSubscription, getHealthDeclarationTnCDataEvent } = this.props;
        const {
            customerByPhoneNumber
        } = newPlan;
        const preferenceList = this.state.preferenceList;
        // only if tnc is already accepted or failed, pre-populate the radio buttons
        // which will be disabled
        if (![tncStatus.IN_REVIEW, tncStatus.PENDING].includes(selectedSubscription?.tncStatus)) {
            preferenceList[0].optionSelected = selectedSubscription?.tncStatus === tncStatus.SUCCESS ? 'no' : '';
        } else if (tncStatus.IN_REVIEW) {
            preferenceList[0].optionSelected = selectedSubscription?.tncStatus === tncStatus.IN_REVIEW ? 'yes' : '';
        }
        getHealthDeclarationTnCDataEvent(selectedSubscription?.id);
        preferenceList[1].optionSelected = customerByPhoneNumber && customerByPhoneNumber.preferences && customerByPhoneNumber.preferences.whatsapp
        && customerByPhoneNumber.preferences.whatsapp.consent ? customerByPhoneNumber.preferences.whatsapp.consent : 'None';
        resetTNCEvent();
        this.setState({
            preferenceList: preferenceList,
        });
    }

    handleChange = (value, heading) => {
        const { updateWhatsAppConsent } = this.props;
        if (heading === this.state.preferenceList[0].heading) {
            if (value === 'no') {
                let preferenceList = this.state.preferenceList;
                preferenceList[0].optionSelected = value;
                this.setState({
                    preferenceList: preferenceList,
                    showGhdApprovalDrawer: true,
                });
            } else {
                let preferenceList = this.state.preferenceList;
                preferenceList[0].optionSelected = 'yes';
                this.setState({
                    showCancelPlanDrawer: true,
                });
            }
        } else if (heading === this.state.preferenceList[1].heading) {
            updateWhatsAppConsent(value)
        }
    }

    handleDrawerClose = () => {
        const { resetSelectedSubscription, newPlan, familyDetails } = this.props;
        const preferenceList = this.state.preferenceList;
        if(newPlan.customerByPhoneNumber.cancelSubcriptionPlan
            || familyDetails?.tncDetails?.accepted === 'failed') {
                alert("Onboarding cannot be completed for this Plan since GHD has been rejected.");
            return resetSelectedSubscription();
        }
        this.setState({
            showCancelPlanDrawer: false,
            preferenceList: preferenceList,
        });
    }

    handleGhdDrawerClose = () => {
        this.setState({
            showGhdApprovalDrawer: false
        });
    }

    render() {
        const { selectedSubscription, newPlan, familyDetails } = this.props;
        const whatsappConsentCard = this.state.preferenceList[1];
        const GhdDeclarationCard = this.state.preferenceList[0];
        const subHeadingGhd = familyDetails?.healthConditionsTnCData?.subDescription;
        const bindPropToPrefCard = (index) => {
            if (index === 0) {
                return this.state.preferenceList[0].optionSelected;
            } else if (index === 1) {
                return newPlan && newPlan.customerByPhoneNumber && newPlan.customerByPhoneNumber.preferences
                && newPlan.customerByPhoneNumber.preferences.whatsapp && newPlan.customerByPhoneNumber.preferences.whatsapp.consent;
            }
        }
        return (
            <>
                <Box style={{ display: 'flex', padding: '27px 38px 0px 18px' }}>
                    <PreferenceCard selectedSubscription={selectedSubscription} heading={familyDetails?.healthConditionsTnCData?.translations?.en?.description} subHeading={subHeadingGhd} selectTitle={GhdDeclarationCard.optionTitle}
                        prefrenceOptions={familyDetails?.healthConditionsTnCData?.answerOptions} cardName="ghdCard" handleChange={(value, heading) => this.handleChange(value, heading)} optionSelected={bindPropToPrefCard(0)}
                    />
                    <PreferenceCard selectedSubscription={selectedSubscription} heading={whatsappConsentCard.heading} subHeading={whatsappConsentCard.subHeading} selectTitle={whatsappConsentCard.optionTitle}
                        prefrenceOptions={whatsappConsentCard.prefrenceOptions} cardName="consentCard" optionSelected={bindPropToPrefCard(1)} handleChange={(value, heading) => this.handleChange(value, heading)}
                    />
                </Box>
                <Drawer disableEnforceFocus anchor="right" open={this.state.showGhdApprovalDrawer} onClose={this.handleGhdDrawerClose}>
                    <div style={{ width: '50vh', height: '100vh' }} >
                        <GhdApprovalDrawer
                            handleDrawerClose={() => this.handleGhdDrawerClose()}
                            selectedSubscription={selectedSubscription}
                            heading={'Ghd Conditional Approval'}
                            title={"Enter the patient's medical conditions below"}
                            confirmationText={'The customer would not be eligible for the plans of Clinikk healthcare due to the selected health conditions.'}
                        />
                    </div>
                </Drawer>
                <Drawer disableEnforceFocus anchor="right" open={this.state.showCancelPlanDrawer} onClose={this.handleDrawerClose}>
                    <div style={{ width: '50vh', height: '100vh' }} >
                        <ConfirmationDrawer
                            handleDrawerClose={() => this.handleDrawerClose()}
                            selectedSubscription={selectedSubscription}
                            heading={'Medical conditions confirmation'}
                            title={'Are you sure that the customer has any of the following medical conditions?'}
                            subTitle={subHeadingGhd}
                            confirmationText={'The customer would not be eligible for the plans of Clinikk healthcare due to the selected health conditions.'}
                        />
                    </div>
                </Drawer>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { newPlan, familyDetails, auth } = state;
    return {
        newPlan,
        familyDetails,
        auth,
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateCohortEvent: (accessToken, familyId, cohortData) => {
      dispatch(updateCohortEvent(accessToken, familyId, cohortData));
    },
    updateWhatsAppConsent: (consent) => {
        dispatch(updateWhatsAppConsent(consent));
    },
    updateTNCEvent: (subscriptionId, familyId, tncStatus) => {
        dispatch(updateTNC(subscriptionId, familyId, tncStatus));
    },
    resetTNCEvent: () => {
        dispatch(resetTNC())
    },
    getHealthDeclarationTnCDataEvent: (subscriptionId, version, documentType) => {
        dispatch(getHealthDeclarationTnCData(subscriptionId, version, documentType))
    },
  });

const ConnectedManageLocation = (
    withStyles(useStyles, { withTheme: true })(connect(
        mapStateToProps, mapDispatchToProps,
    )(withTranslation()(CustomerPreference)))
);

export default ConnectedManageLocation;
