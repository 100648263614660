import {
    BATCH_EXTEND_SUBSCRIPTION,
    BATCH_EXTEND_SUBSCRIPTION_RESPONSE,
    RESET_BATCH_EXTENDED_SUBSCRIPTION
} from '../actions/types';

const INITIAL_STATE = {
    batchExtendSubscriptionResponse: null,
    loadingBatchExtendSubscriptionResponse: true,
};

const batchExtendSubscriptionReducer = (state = { INITIAL_STATE }, action) => {
    switch (action.type) {
        case BATCH_EXTEND_SUBSCRIPTION:
            return { ...state, batchExtendSubscriptionResponse: null, loadingBatchExtendSubscriptionResponse: true }
        case BATCH_EXTEND_SUBSCRIPTION_RESPONSE:
            return { ...state, batchExtendSubscriptionResponse: action.payload, loadingBatchExtendSubscriptionResponse: false }
        case RESET_BATCH_EXTENDED_SUBSCRIPTION:
            return { ...state, batchExtendSubscriptionResponse: null, loadingBatchExtendSubscriptionResponse: false }
        default:
            return state;
    }
};

export default batchExtendSubscriptionReducer;
