import axios from 'axios';

import config from '../config/index';
import { productApis } from '../constants';

export const getCreditDetails = async (ACCESS_TOKEN, familyId) => {
  const url = config.apiURL + `/v3/credits?familyId=${familyId}`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,
    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.get(url, options);
  return response;
};


export const getOrderById = async (ACCESS_TOKEN, orderId) => {
  const url = config.apiURL + `/v3/orders/${orderId}`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,
    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.get(url, options);
  return response;
};

export const createOfflinePaymentByOrderId = async (ACCESS_TOKEN, orderId, payments) => {
  const url = config.apiURL + `/v3/orders/${orderId}/offline-payments`
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,

    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.post(url, payments, options);
  return response;
}

export const createOrder = async (ACCESS_TOKEN, orderObject, familyId) => {
  const url = config.apiURL + `/v1/family/${familyId}/orders`
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,

    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.post(url, orderObject, options);
  return response;
}

export const createPaymentSMSLink = async (accessToken, orderId, paymentPayload) => {
  const url = `${config.apiURL}/v3/orders/${orderId}/payments-links`;
  const options = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${accessToken}`,
    },
    timeout: config.timeout,
    responseType: 'json',
    responseEncoding: 'utf8',
    url,
    data: paymentPayload,
  };
  const result = await axios(options);
  return result;
}

export const sendNotification = async (accessToken, payload) => {
  const url = `${config.apiURL}/v1/notifications`;
  const options = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${accessToken}`,
    },
    timeout: config.timeout,
    responseType: 'json',
    responseEncoding: 'utf8',
    url,
    data: payload,
  };
  const result = await axios(options);
  return result;
}

export const payInvoices = async (accessToken, payload) => {
  const url = `${config.apiURL}${productApis.PAY_INVOICES}`;
  const options = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${accessToken}`,
    },
    timeout: config.timeout,
    responseType: 'json',
    responseEncoding: 'utf8',
    url,
    data: payload,
  };
  const result = await axios(options);
  return result;
}

/**
 * @param {String} accessToken - JWT to be used to authenticate the request
 * @param {Number} orderId Clinikk Order ID for which to get line items
 * @param {Boolean} syncNonEvitalRxItems Flag that indicates whether or not the entire
 * order is to be synced.
 * @returns a Promise that resolves to either an error, or data wit the line items
 * for the specified Clinikk Order ID.
 * 
 * Get line items for a given Clinikk Order ID.
 */
export const getOrderLineItems = async (accessToken, orderId, syncOnlyEvitalRxItems) => {
  const url = `${config.apiURL}/v3/line-items?orderId=${orderId}`
  + `${syncOnlyEvitalRxItems ? '&type=evital_medicine' : ''}`;
  const options = {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${accessToken}`,
    },
    timeout: config.timeout,
    responseType: 'json',
    responseEncoding: 'utf8',
    url,
  };
  const result = await axios(options);
  return result;
};

/**
 * @param {String} accessToken - JWT to be used to authenticate the request
 * @param {Number} orderId Clinikk Order ID to which line items are to be added
 * @param {Boolean} lineItem The line item to add to the order in the backend
 * @returns a Promise that resolves to either an error, or data wit the line items
 * for the specified Clinikk Order ID.
 * 
 * Get line items for a given Clinikk Order ID.
 */
export const addLineItemsToOrder = async (accessToken, orderId, lineItem) => {
  const url = `${config.apiURL}/v3/orders/${orderId}/line-items`
  const options = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${accessToken}`,
    },
    timeout: config.timeout,
    responseType: 'json',
    responseEncoding: 'utf8',
    url,
    data: lineItem,
  };
  const result = await axios(options);
  return result;
};

/**
 * @param {String} accessToken - JWT to be used to authenticate the request
 * @param {Boolean} lineItemId The line item to add to the order in the backend
 * @returns a Promise that resolves to either an error, or data wit the line items
 * for the specified Clinikk Order ID.
 * 
 * Get line items for a given Clinikk Order ID.
 */
export const deleteLineItemFromOrder = async (accessToken, lineItemId) => {
  const url = `${config.apiURL}/v3/line-items/${lineItemId}`;
  const options = {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${accessToken}`,
    },
    timeout: config.timeout,
    responseType: 'json',
    responseEncoding: 'utf8',
    url,
  };
  const result = await axios(options);
  return result;
};

/**
 * @param {String} accessToken - JWT to be used to authenticate the request
 * @param {Boolean} lineItemId The line item to add to the order in the backend
 * @param {Boolean} updatedLineItem The line item to replace the old one with in the backend
 * @returns a Promise that resolves to either an error, or data wit the line items
 * for the specified Clinikk Order ID.
 * 
 * Get line items for a given Clinikk Order ID.
 */
 export const modifyLineItemInOrder = async (accessToken, lineItemId, updatedLineItem) => {
  const url = `${config.apiURL}/v3/line-items/${lineItemId}`;
  const options = {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${accessToken}`,
    },
    timeout: config.timeout,
    responseType: 'json',
    responseEncoding: 'utf8',
    url,
    data: updatedLineItem,
  };
  const result = await axios(options);
  return result;
};