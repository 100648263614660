import * as Sentry from '@sentry/react';
import _ from 'lodash';
import { createNewAddress, getCityStateByZipCode } from '../clients/addressService';
import { checkOtp, getOtp } from '../clients/authService';
import {
  addWhatsAppConcent,
  assignDoctor, cancelConsultationByConsultationId, checkoutConsultationById,
  createVitalsByConsultationId,
  getClinicList,
  getConsultationByConsultationId,
  getConsultationDetails,
  getConsultationsByClinic,
  getDoctorList,
  postConsultation,
  postVisit,
  reAssignDoctor
} from '../clients/consultationService';
import {
  addCustomerMetadata,
  addMember,
  createNewCustomer,
  getCustomerDetailsByPhoneNumber,
  getMandateDetails,
  getMemberDetails,
  getPlanBillingPref, getSubscriptionBySubscriptionId,
  updateCohort,
  updateCustomerAlternateNumber,
  updateCustomerByCustomerId,
  updateCustomerPrimaryNumber
} from '../clients/customerService';
import { getInvoicesByFamilyId, getInvoicesBySubscriptionId } from '../clients/invoiceService';
import { cancelMandateByMandateId } from '../clients/mandateService';
import { createOfflinePaymentByOrderId, getCreditDetails, getOrderById } from '../clients/omsService';
import { uploadOnboardingFile } from '../clients/onBoardingService';
import {
  activatePolicyBySubscriptionId,
  cancelSubscriptionById,
  connectTeleCallByPartnerId,
  createNewSubscription,
  createTrialSubscription,
  getAllSubscriptionsByDatePaginated,
  getBenefitCardDetails,
  getCouponDiscount,
  getFamilyDetails,
  getHealthDeclarationTnC,
  getOpdBalance, getOpdTransactions,
  getProductsByBusinessId,
  getSubscriptionDetails,
  searchAllProductsByBusinessId,
  sendPlanChangeLink,
  updateSubscriptionTnC,
  withdrawSubscriptionById,
} from '../clients/subscriptionService';
import { buildCustomError, checkIfHA } from '../common/utils';
import { errorActionTypes, familyActionTypes } from '../constants';
import history from '../history';
import { createLeadEvent } from './leadsAction';
import {
  ADD_NEW_MEMBER_BY_FAMILY_ID,
  ADD_PLAN_DETAILS,
  ADD_PLAN_DETAILS_RESPONSE,
  ADD_VITALS_BY_CONSULTATION_ID,
  ASSIGN_DOCTOR_BY_CONSULTATION_ID,
  CANCEL_CONSULTATION_BY_ID,
  CANCEL_MANDATE_BY_MANDATE_ID,
  CANCEL_SUBSCRIPTION_BY_ID,
  CHECKOUT_CONSULTATION,
  CLOSE_ADD_VITALS_DRAWER,
  CLOSE_BOOK_CONSULTATION_DRAWER,
  CLOSE_MEMBER_DRAWER,
  CLOSE_VIEW_DETAILS,
  CLOSE_VISIT_BOOK_DRAWER,
  CONNECT_TELECALL_REQUEST, CONNECT_TELECALL_RESPONSE,
  CREATE_CONSULTATION,
  CREATE_FREE_SUBSCRIPTION,
  CREATE_NEW_ADDRESS,
  CREATE_NEW_CUSTOMER,
  CREATE_OFFLINE_PAYMENT,
  CREATE_ONBOARDING_REQUEST,
  CREATE_ONBOARDING_RESPONSE,
  CREATE_SUBSCRIPTION,
  CREATE_VISIT,
  DECREMENT_CONSULTATIONQ_PAGE,
  DECREMENT_CONSULTATION_PAGE,
  EDIT_CURRENT_CLINIC_LOCATION,
  FETCH_BENEFIT_CARDS_BY_CUSTOMER_ID,
  FETCH_CONSULTATIONS_BY_CUSTOMER_ID,
  FETCH_CONSULTATIONS_BY_CUSTOMER_ID_FIRST_PAGE,
  FETCH_HEALTH_DECLARATION_TNC_FAILURE, FETCH_HEALTH_DECLARATION_TNC_REQUEST, FETCH_HEALTH_DECLARATION_TNC_SUCCESS, FETCH_PRODUCTS_BY_BUSINESS_ID,
  FIND_ADDRESS_BY_ZIPCODE,
  FIND_CUSTOMER_BY_PHONE_NUMBER,
  GET_ALL_CLINICS,
  GET_ALL_DOCTORS,
  GET_ALL_MEMBERS,
  GET_ALL_SUBSCRIPTIONS_BY_DATE_PAGINATED, GET_ALL_SUBSCRIPTIONS_BY_DATE_PAGINATED_RESPONSE,
  GET_BILLING_PREF_FAILURE,
  GET_BILLING_PREF_REQUEST, GET_BILLING_PREF_SUCCESS,
  GET_CONSULTATIONS_BY_CLINIC_ID,
  GET_CONSULTATIONS_BY_CLINIC_ID_FIRST_PAGE,
  GET_CONSULTATION_BY_ID,
  GET_CONSULTATION_ORDER,
  GET_COUPON_DISCOUNT,
  GET_CREDIT_DETAILS_BY_FAMILY_ID,
  GET_CUSTOMER_BY_PHONE_NUMBER,
  GET_CUSTOMER_DETAILS,
  GET_INVOICES_BY_FAMILY_ID_FAILURE,
  GET_INVOICES_BY_FAMILY_ID_REQUEST,
  GET_INVOICES_BY_FAMILY_ID_SUCCESS,
  GET_INVOICES_BY_SUBSCRIPTION_ID,
  GET_NACH_DETAILS_BY_FAMILY_ID,
  GET_OPD_BALANCE,
  GET_OPD_TRANSACTIONS,
  GET_ORDER_BY_ID,
  GET_SUBSCRIPTION_BY_SUBSCRIPTION_ID_FAILED,
  GET_SUBSCRIPTION_BY_SUBSCRIPTION_ID_REQUEST,
  GET_SUBSCRIPTION_BY_SUBSCRIPTION_ID_SUCCESS,
  INCREMENT_CONSULTATIONQ_PAGE,
  INCREMENT_CONSULTATION_PAGE,
  INITIATE_OTP,
  LOG_IN,
  LOG_OUT,
  OPEN_ADD_VITALS_DRAWER,
  OPEN_BOOK_CONSULTATION_DRAWER,
  OPEN_MEMBER_DRAWER,
  OPEN_VIEW_DETAILS,
  OPEN_VISIT_BOOK_DRAWER,
  PLAN_CANCELATION_FAILURE,
  PLAN_CANCELATION_REQUEST, PLAN_CANCELATION_SUCCESS,
  RESET_ADDRESS_BY_ZIPCODE,
  RESET_ADD_PLAN_DETAILS,
  RESET_ADD_VITALS_STATUS,
  RESET_ALL_SUBSCRIPTIONS_BY_DATE_PAGINATED_RESPONSE,
  RESET_BENEFIT_CARDS_BY_ID,
  RESET_CANCEL_CONSULTATION_STATUS,
  RESET_CANCEL_MANDATE_STATUS,
  RESET_CANCEL_WITHDRAW_SUBSCRIPTION_STATUS,
  RESET_CHECKOUT_CONSULTATION_STATUS,
  RESET_CONSULTATIONQ_PAGE_NUMBER,
  RESET_CONSULTATIONS_BY_CUSTOMER_ID,
  RESET_CONSULTATION_BY_ID,
  RESET_CONSULTATION_ERROR,
  RESET_CONSULTATION_ORDER_DETAILS,
  RESET_CONSULTATION_PAGE_NUMBER,
  RESET_CONSULTATION_QUEUE,
  RESET_COUPON_VALUE,
  RESET_CREATE_ADDRESS_RESPONSE,
  RESET_CREATE_NEW_CUSTOMER,
  RESET_CREATE_SUBSCRIPTION,
  RESET_CREDIT_DETAILS,
  RESET_CUSTOMER_BY_PHONE_NUMBER,
  RESET_CUSTOMER_PHONE_NUMBER,
  RESET_DOCTOR_BY_CONSULTATION_ID,
  RESET_ERRORS,
  RESET_GET_ALL_MEMBERS,
  RESET_INVOICE_DETAILS,
  RESET_NEW_MEMBER_ADDED,
  RESET_NEW_PLAN_MOBILE_NUMBER,
  RESET_OFFLINE_PAYMENT_STATUS,
  RESET_OLD_FAMILY_DETAILS,
  RESET_OPD_BALANCE,
  RESET_OPD_TRANSACTIONS,
  RESET_ORDER_DETAILS,
  RESET_PLAN_CHANGE,
  RESET_PRODUCTS,
  RESET_PRODUCTS_BY_BUSINESS_ID,
  RESET_TELECALL,
  RESET_TNC,
  RESET_UPDATE_ALTERNATE_NUMBER_STATUS,
  RESET_UPDATE_MEMBER,
  RESET_UPDATE_PRIMARY_NUMBER_STATUS,
  RESET_UPLOAD_ONBOARD_FILE,
  RESET_VISIT_BOOKING_ERROR,
  RE_ASSIGN_DOCTOR_BY_CONSULTATION_ID, SEARCH_PRODUCTS_BY_BUSINESS_ID,
  SELECT_COHORT,
  SEND_PLAN_CHANGE,
  SET_CONSULTATION_FORM,
  SET_CURRENT_CLINIC_LOCATION,
  SET_CUSTOMER_PHONE_NUMBER,
  SET_ERROR_IN_GETTING_BILLING_PREF,
  SET_ERROR_IN_UPDATE_COHORT,
  SET_ERROR_IN_UPDATE_TNC,
  SET_NEW_FAMILY_ID,
  SET_NEW_PLAN_MOBILE_NUMBER,
  SET_PRESENTABLE_CONSULTATIONS,
  SET_PRESENTABLE_CONSULTATION_QUEUE,
  UPDATE_ALTERNATE_NUMBER,
  UPDATE_COHORT_FAILURE,
  UPDATE_COHORT_REQUEST, UPDATE_COHORT_SUCCESS,
  UPDATE_CUSTOMER_BY_CUSTOMER_ID,
  UPDATE_PRIMARY_NUMBER,
  UPDATE_TNC_FAILURE,
  UPDATE_TNC_REQUEST, UPDATE_TNC_SUCCESS,
  UPDATE_WHATSAPP_CONSENT_ONBOARDING_FAILURE,
  UPDATE_WHATSAPP_CONSENT_ONBOARDING_REQUEST,
  UPDATE_WHATSAPP_CONSENT_ONBOARDING_SUCCESS,
  VERIFY_OTP,
  WITHDRAW_SUBSCRIPTION_BY_ID
} from './types';


const { UNAUTHORIZED, NOT_FOUND, UNPROCESSABLE_ENTITY } = require('http-status-codes');
const { errors } = require('../common/errors');
const { apiResponse, tncStatus } = require('../common/constants');
const ADD_ONE_DAY = 86399000;

export * from './authAction';
export * from './consultationAction';
export * from './errorAction';
export * from './ticketingAction';

export const logIn = (phoneNumber) => {
  return {
    type: LOG_IN,
    payload: phoneNumber
  };
};

export const logOut = () => {
  history.push('/');
  return {
    type: LOG_OUT,
  }
}

export const resetErrors = () => {
  return {
    type: RESET_ERRORS,
  }
}

export const resetConsultationDetail = () => {
  return {
    type: RESET_CONSULTATION_ERROR,
  }
}

export const resetVisitBookingDetails = () => {
  return {
    type: RESET_VISIT_BOOKING_ERROR,
  }
}

export const setCustomerPhoneNumber = (customerPhoneNumber) => {
  return {
    type: SET_CUSTOMER_PHONE_NUMBER,
    payload: customerPhoneNumber
  };
};

export const resetCustomerPhoneNumber = () => {
  return {
    type: RESET_CUSTOMER_PHONE_NUMBER,
  };
};




export const initiateOtp = (mobile) => async (dispatch, getState) => {
  try {
    const response = await getOtp(mobile);
    const data = response.data
    dispatch({ type: INITIATE_OTP, payload: { data, mobile: mobile } });
    history.push('/verifyOtp');
  } catch (e) {
    if (e.response && e.response.status === UNPROCESSABLE_ENTITY) {
      dispatch({ type: INITIATE_OTP, payload: { mobile: mobile, initateOtpErrorCode: errors.NUMBER_DOES_NOT_EXIST } });
    } else {
      dispatch({ type: INITIATE_OTP, payload: { mobile: mobile, initateOtpErrorCode: errors.SOMETHING_WENT_WRONG } })
    }
  }
}

export const verifyOtp = (password) => async (dispatch, getState) => {
  const username = getState().auth.phoneNumber;
  let response;
  let data;
  try {
    response = await checkOtp(username, password);
    data = response.data;
    if (data && !checkIfHA(data.user.roles)) {
      dispatch({ type: VERIFY_OTP, payload: { data, isLoggedIn: false, isNotAuthorizedRole: true } });
    } else {
      dispatch({ type: VERIFY_OTP, payload: { data, isLoggedIn: true } });
    }
    history.push('/select-location');
  } catch (e) {
    if (e) {
      dispatch({ type: VERIFY_OTP, payload: { mobile: username, verifyOtpErrorCode: errors.VERIFY_OTP_WRONG_OTP } });
    } else {
      dispatch({ type: VERIFY_OTP, payload: { mobile: username, verifyOtpErrorCode: errors.VERIFY_OTP_ERROR } })
    }
  }
}

export const getCustomerDetails = (value) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  let currentCustomerResponse;
  let subscriptionsResponse;
  let allMembersResponse;
  let familyId;
  let currentCustomerErrorCode;
  let allMembersErrorCode;
  let subscriptionDetailsErrorCode;
  let currentCustomerError;
  let subscriptionsError;
  let allMembersError;
  try {
    let query;
    if (value.includes(',')) {
      query = `?searchParam=${value}`;
    } else if (value.length === 10) {
      query = `?phoneNumber=${value}`;
    } else {
      query = `?familyId=${value}`;
    }
    currentCustomerResponse = await getFamilyDetails(ACCESS_TOKEN, query);
    familyId = currentCustomerResponse.data.family.id;
  } catch (e) {
    currentCustomerErrorCode = e.response ? e.response.status : e;
    if (currentCustomerErrorCode === NOT_FOUND) {
      currentCustomerError = errors.CUSTOMER_NOT_FOUND;
      return history.push(`/addCustomer?phoneNumber=${value}`);
    } else if (currentCustomerErrorCode === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT }); // need to logout if UNAUTHORIZED while searching customers by phone number
      currentCustomerError = errors.FORBIDDEN;
    } else {
      currentCustomerError = errors.ERROR_IN_FETCHING_CUSTOMER;
    }
  }
  try {
    const payload = {
      page: 1,
      size: 10
    }
    subscriptionsResponse = await getSubscriptionDetails(ACCESS_TOKEN, familyId, payload);
  } catch (e) {
    subscriptionsError = errors.ERROR_IN_FETCHING_SUBSCRIPTIONS;
  }
  try {
    allMembersResponse = await getMemberDetails(ACCESS_TOKEN, familyId);
  } catch (e) {
    allMembersErrorCode = e.response ? e.response.status : e;
  }
  if (currentCustomerError || subscriptionsError || allMembersError) {
    dispatch({ type: GET_CUSTOMER_DETAILS, payload: { currentCustomerErrorCode: currentCustomerError, subscriptionDetailsErrorCode: subscriptionsError, allMembersErrorCode: allMembersError } });
    if (subscriptionsError && currentCustomerError) { history.push('/'); }
    else if (subscriptionsError) {
      history.push({
        pathname: '/family',
        hash: window.location.hash,
      });
    }
  } else {
    dispatch({ type: GET_CUSTOMER_DETAILS, payload: { currentCustomer: currentCustomerResponse?.data, allSubscriptions: subscriptionsResponse?.data, allMembers: allMembersResponse?.data, currentCustomerErrorCode: null, subscriptionDetailsErrorCode, allMembersErrorCode } });
    history.push({
      pathname: '/family',
      hash: window.location.hash,
    });
  }
};

export const getNachDetailsByFamilyId = (familyId) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  let response;
  let data;
  let nachResponseErrorCode;
  let nachError;
  try {
    response = await getMandateDetails(ACCESS_TOKEN, familyId);
    data = response.data;
  } catch (e) {
    nachResponseErrorCode = e.response ? e.response.status : e;
    if (nachResponseErrorCode === NOT_FOUND) {
      nachError = errors.NACH_DOES_NOT_EXIST;
    } else if (nachResponseErrorCode === UNAUTHORIZED) {
      nachError = errors.FORBIDDEN;
      dispatch({ type: LOG_OUT }); // need to logoout if UNAUTHORIZED
    } else {
      nachError = errors.ERROR_IN_FETCHING_NACH;
    }
  }
  if (nachResponseErrorCode) {
    dispatch({ type: GET_NACH_DETAILS_BY_FAMILY_ID, payload: { nachErrorDetails: nachError } });
  } else {
    dispatch({ type: GET_NACH_DETAILS_BY_FAMILY_ID, payload: data })
  }
}

export const getCreditDetailsByFamilyId = (familyId) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  let response;
  let data;
  let creditResponseErrorCode;
  try {
    response = await getCreditDetails(ACCESS_TOKEN, familyId);
    data = response.data;
  } catch (e) {
    creditResponseErrorCode = e.response ? e.response.status : e;
    if (creditResponseErrorCode === UNAUTHORIZED) {
      creditResponseErrorCode = errors.FORBIDDEN;
      dispatch({ type: LOG_OUT }); // need to logoout if UNAUTHORIZED
    }
  }
  if (creditResponseErrorCode) {
    dispatch({ type: GET_CREDIT_DETAILS_BY_FAMILY_ID, payload: { creditErrorDetails: creditResponseErrorCode } });
  } else {
    dispatch({ type: GET_CREDIT_DETAILS_BY_FAMILY_ID, payload: data })
  }
}

export const fetchBenefitCardsByCustomerId = (customerId) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  let response;
  let data;
  let benefitCardErrorCode;
  try {
    response = await getBenefitCardDetails(ACCESS_TOKEN, customerId);
    data = response.data;
  } catch (e) {
    benefitCardErrorCode = e.reponse ? e.reponse.status : e;
    if (e.response && (e.response.status === UNAUTHORIZED)) {
      benefitCardErrorCode = errors.FORBIDDEN;
      dispatch({ type: LOG_OUT }); // need to logoout if UNAUTHORIZED
    }
  }
  if (benefitCardErrorCode) {
    dispatch({ type: FETCH_BENEFIT_CARDS_BY_CUSTOMER_ID, payload: { benefitCardErrorDetails: benefitCardErrorCode } });
  } else {
    dispatch({ type: FETCH_BENEFIT_CARDS_BY_CUSTOMER_ID, payload: data });
  }
}

export const fetchProductByBusineedId = (businessId) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  let response;
  let data;
  let businessErrorCode;
  try {
    response = await getProductsByBusinessId(ACCESS_TOKEN, businessId);
    data = response.data;
  } catch (e) {
    businessErrorCode = e.reponse ? e.reponse.status : e;
    if (e.response && (e.response.status === UNAUTHORIZED)) {
      businessErrorCode = errors.FORBIDDEN;
      dispatch({ type: LOG_OUT }); // need to logoout if UNAUTHORIZED
    }
  }
  if (businessErrorCode) {
    dispatch({ type: FETCH_PRODUCTS_BY_BUSINESS_ID, payload: { errorDetails: businessErrorCode } });
  } else {
    dispatch({ type: FETCH_PRODUCTS_BY_BUSINESS_ID, payload: data });
  }
}

export const resetBenefitCardsByCustomerId = () => {
  return {
    type: RESET_BENEFIT_CARDS_BY_ID,
  }
}

export const resetProducts = () => {
  return {
    type: RESET_PRODUCTS,
  }
}

export const resetCouponValue = () => {
  return {
    type: RESET_COUPON_VALUE
  }
}

export const resetOpdAmounts = () => {
  return {
    type: RESET_OPD_BALANCE
  }
}

export const resetOpdTransactions = () => {
  return {
    type: RESET_OPD_TRANSACTIONS
  }
}

export const resetPlanChange = () => {
  return {
    type: RESET_PLAN_CHANGE,
  }
}

export const fetchConsultationsByCustomerId = (customerId, page) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  let response;
  let data;
  try {
    response = await getConsultationDetails(ACCESS_TOKEN, customerId, page);
    data = response.data;
    dispatch({ type: FETCH_CONSULTATIONS_BY_CUSTOMER_ID, payload: data });
  } catch (e) {
    // const consultationError = e.reponse ? e.reponse.status : e;
    dispatch({ type: FETCH_CONSULTATIONS_BY_CUSTOMER_ID, payload: { getConsultationsErrorDetails: errors.ERROR_IN_FETCHING_CONSULTATION } });
  }
}

export const fetchConsultationsByCustomerIdFirstPage = (customerId, page) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  let response;
  let data;
  try {
    response = await getConsultationDetails(ACCESS_TOKEN, customerId, page);
    data = response.data;
    dispatch({ type: FETCH_CONSULTATIONS_BY_CUSTOMER_ID_FIRST_PAGE, payload: data });
  } catch (e) {
    // const consultationError = e.reponse ? e.reponse.status : e;
    dispatch({ type: FETCH_CONSULTATIONS_BY_CUSTOMER_ID_FIRST_PAGE, payload: { getConsultationsErrorDetails: errors.ERROR_IN_FETCHING_CONSULTATION } });
  }
}

export const resetConsultationsByCustomerId = () => {
  return {
    type: RESET_CONSULTATIONS_BY_CUSTOMER_ID,
  }
}

export const setConsultationForm = (formObject) => {
  return {
    type: SET_CONSULTATION_FORM,
    payload: formObject
  };
};

export const createConsultation = (consultationPayload) => async (dispatch, getState) => {
  const createConsultation = (payload) => ({ type: CREATE_CONSULTATION, payload });
  const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  let response;
  let data;
  try {
    response = await postConsultation(ACCESS_TOKEN, consultationPayload);
    data = response.data;
    response.data.scheduled_on = consultationPayload.scheduled_on;
    dispatch(createConsultation(data));
  } catch (e) {
    console.log(e);
    const error = buildCustomError(e);
    dispatch(createConsultation(errors.ERROR_IN_POSTING_CONSULTATION));
    dispatch(setError(error));
  }
};

export const createVisit = (visitPayload, clinicId) => async (dispatch, getState) => {
  const createVisit = (payload) => ({ type: CREATE_VISIT, payload });
  const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  let response;
  let data;
  try {
    response = await postVisit(ACCESS_TOKEN, visitPayload, clinicId);
    data = response.data;
    dispatch(createVisit(data));
  } catch (e) {
    console.log(e);
    const error = buildCustomError(e);
    dispatch(createVisit(errors.ERROR_IN_POSTING_VISIT));
    dispatch(setError(error));
  }
}

export const getAllDoctors = (bool) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  let response;
  let data;
  try {
    response = await getDoctorList(ACCESS_TOKEN, bool);
    data = response.data;
    dispatch({ type: GET_ALL_DOCTORS, payload: data })
  } catch (e) {
    dispatch({ type: GET_ALL_DOCTORS, payload: errors.ERROR_IN_FETCHING_DOCTORS })
  }
};

export const getAllMembersByFamilyId = (familyId) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  let response;
  let data;
  try {
    response = await getMemberDetails(ACCESS_TOKEN, familyId);
    data = response.data;
    dispatch({ type: GET_ALL_MEMBERS, payload: data })
  } catch (e) {
    dispatch({ type: GET_ALL_MEMBERS, payload: errors.ERROR_IN_FETCHING_ALL_MEMBERS })
  }
};

export const resetGetAllMembersByFamilyId = () => {
  return {
    type: RESET_GET_ALL_MEMBERS,
  }
}

export const findAddressByZipcode = (zipcode) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  let response;
  let data;
  try {
    response = await getCityStateByZipCode(ACCESS_TOKEN, zipcode);
    data = response.data;
    dispatch({ type: FIND_ADDRESS_BY_ZIPCODE, payload: data })
  } catch (e) {
    if (e.response && e.response.status === NOT_FOUND) {
      dispatch({ type: FIND_ADDRESS_BY_ZIPCODE, payload: { getAddressByZipcodeError: errors.ZIPCODE_DOES_NOT_EXIST } })
    } else {
      dispatch({ type: FIND_ADDRESS_BY_ZIPCODE, payload: { getAddressByZipcodeError: errors.ERROR_IN_FINDING_ADDRESS } })
    }
  }
};


export const resetAddressByZipcode = () => {
  return {
    type: RESET_ADDRESS_BY_ZIPCODE,
  }
};

export const findCustomerByPhoneNumber = (phoneNumber) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  let currentCustomerResponse;
  let currentCustomerErrorCode;
  let currentCustomerError;
  try {
    currentCustomerResponse = await getFamilyDetails(ACCESS_TOKEN, `?phoneNumber=${phoneNumber}`);
  } catch (e) {
    currentCustomerErrorCode = e.response ? e.response.status : e;
    if (currentCustomerErrorCode === UNAUTHORIZED) {
      currentCustomerError = errors.FORBIDDEN;
    } else if (currentCustomerErrorCode === NOT_FOUND) {
      currentCustomerError = errors.CUSTOMER_NOT_FOUND;
    } else {
      currentCustomerError = errors.ERROR_IN_FETCHING_CUSTOMER;
    }
  }
  if (currentCustomerError === UNAUTHORIZED) {
    dispatch({ type: LOG_OUT });
    // dispatch({ type: FIND_CUSTOMER_BY_PHONE_NUMBER, payload: { currentCustomerErrorCode: errors.FORBIDDEN } });
  } else if (currentCustomerError) {
    dispatch({ type: FIND_CUSTOMER_BY_PHONE_NUMBER, payload: { currentCustomerErrorCode: currentCustomerError } });
  } else if (currentCustomerResponse) {
    dispatch({ type: FIND_CUSTOMER_BY_PHONE_NUMBER, payload: { currentCustomerErrorCode: errors.CUSTOMER_ALREADY_EXISTS } });
  } else {
    dispatch({ type: FIND_CUSTOMER_BY_PHONE_NUMBER, payload: { currentCustomerErrorCode: currentCustomerErrorCode } });
  }
};

export const createHeadSubscriber = (newCustomer, leadData) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  try {
    const response = await createNewCustomer(ACCESS_TOKEN, newCustomer);
    dispatch({ type: CREATE_NEW_CUSTOMER, payload: { newCustomerStatus: apiResponse.PRIMARY_CUSTOMER_CREATED, newFamily: response } })
  } catch (e) {
    if (e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    }
    dispatch({ type: CREATE_NEW_CUSTOMER, payload: errors.ERROR_IN_CREATING_PRIMARY_CUSTOMER })
  }
};

export const getCouponDiscountEvent = (coupon) => async (dispatch) => {
  try {
    const response = await getCouponDiscount(coupon);
    dispatch({ type: GET_COUPON_DISCOUNT, payload: { couponData: response } })
  } catch (e) {
    if (e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    }
    dispatch({ type: GET_COUPON_DISCOUNT, payload: errors.ERROR_IN_VALIDATING_COUPON })
  }
};

export const getOpdBalanceEvent = (subscriptionId) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  try {
    const response = await getOpdBalance(ACCESS_TOKEN, subscriptionId);
    dispatch({ type: GET_OPD_BALANCE, payload: { data: response } })
  } catch (e) {
    if (e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    }
    dispatch({ type: GET_OPD_BALANCE, payload: errors.ERROR_IN_OPD_BALANCE })
  }
};

export const getOpdTransactionsEvent = (subscriptionId, page) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  try {
    const response = await getOpdTransactions(ACCESS_TOKEN, subscriptionId, page);
    dispatch({ type: GET_OPD_TRANSACTIONS, payload: { data: response.data } })
  } catch (e) {
    if (e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    }
    dispatch({ type: GET_OPD_TRANSACTIONS, payload: errors.ERROR_IN_OPD_TRANSACTIONS })
  }
};

export const createAddress = (addressBody, createLead = false, walkInLeadData = null) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  try {
    const response = await createNewAddress(ACCESS_TOKEN, addressBody);
    dispatch({ type: CREATE_NEW_ADDRESS, payload: response })
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else {
      dispatch({ type: CREATE_NEW_ADDRESS, payload: errors.ERROR_IN_CREATING_ADDRESS })
    }
  }

  // create a lead if required;
  try {
    ;
    if (walkInLeadData && createLead) {
      dispatch(createLeadEvent(walkInLeadData));
    }
  } catch (e) {
    const error = buildCustomError(e);
    dispatch(setError(error));
  }
}

export const resetCreateAddressResponse = () => {
  return {
    type: RESET_CREATE_ADDRESS_RESPONSE,
  }
}


export const createFreeSubscription = (familyId) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  try {
    await createTrialSubscription(ACCESS_TOKEN, familyId);
    dispatch({ type: CREATE_FREE_SUBSCRIPTION, payload: apiResponse.FREE_SUBSCRIPTION_CREATED })
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else {
      dispatch({ type: CREATE_FREE_SUBSCRIPTION, payload: errors.ERROR_IN_CREATING_FREE_SUBSCRIPTION })
    }
  }
}

export const sendPlanChangeEvent = (subscriptionId, payload) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  try {
    const response = await sendPlanChangeLink(ACCESS_TOKEN, subscriptionId, payload);
    dispatch({ type: SEND_PLAN_CHANGE, payload: response })
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else {
      dispatch({ type: SEND_PLAN_CHANGE, payload: errors.ERROR_IN_PLAN_CHANGE })
    }
  }
}

export const setNewFamilyId = (familyId) => {
  return {
    type: SET_NEW_FAMILY_ID,
    payload: familyId
  };
};

export const resetOldFamilyDetails = () => {
  return {
    type: RESET_OLD_FAMILY_DETAILS,
  }
}

export const addNewMemberByFamilyId = (newMember, familyId) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  try {
    const response = await addMember(ACCESS_TOKEN, newMember, familyId);
    dispatch({ type: ADD_NEW_MEMBER_BY_FAMILY_ID, payload: response })
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else {
      dispatch({ type: ADD_NEW_MEMBER_BY_FAMILY_ID, payload: errors.ERROR_IN_ADDING_NEW_MEMBER })
    }
  }
}

export const resetNewMemberAdded = () => {
  return {
    type: RESET_NEW_MEMBER_ADDED,
  }
}

export const updatePrimaryNumber = (familyId, body) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  try {
    const response = await updateCustomerPrimaryNumber(ACCESS_TOKEN, familyId, body);
    dispatch({ type: UPDATE_PRIMARY_NUMBER, payload: response })
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else if (e.response && e.response.status === UNPROCESSABLE_ENTITY) {
      dispatch({ type: UPDATE_PRIMARY_NUMBER, payload: errors.ERROR_NUMBER_ALREADY_IN_USE })
    } else {
      dispatch({ type: UPDATE_PRIMARY_NUMBER, payload: errors.ERROR_IN_UPDATING_PRIMARY_NUMBER })
    }
  }
};

export const resetUpdatePrimaryNumberStatus = () => {
  return {
    type: RESET_UPDATE_PRIMARY_NUMBER_STATUS,
  }
}

export const updateAlternateNumber = (customerId, body) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  try {
    const response = await updateCustomerAlternateNumber(ACCESS_TOKEN, customerId, body);
    dispatch({ type: UPDATE_ALTERNATE_NUMBER, payload: response })
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else if (e.response && e.response.status === UNPROCESSABLE_ENTITY) {
      dispatch({ type: UPDATE_ALTERNATE_NUMBER, payload: errors.ERROR_NUMBER_ALREADY_IN_USE })
    } else {
      dispatch({ type: UPDATE_ALTERNATE_NUMBER, payload: errors.ERROR_IN_UPDATING_PRIMARY_NUMBER })
    }
  }
};

export const resetUpdateAlternateNumberStatus = () => {
  return {
    type: RESET_UPDATE_ALTERNATE_NUMBER_STATUS,
  }
}

export const updateCustomer = (member, familyId, customerId) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  try {
    const response = await updateCustomerByCustomerId(ACCESS_TOKEN, member, familyId, customerId);
    dispatch({ type: UPDATE_CUSTOMER_BY_CUSTOMER_ID, payload: response })
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else if (e.response && e.response.status === UNPROCESSABLE_ENTITY) {
      dispatch({ type: UPDATE_CUSTOMER_BY_CUSTOMER_ID, payload: errors.ERROR_UPDATE_MEMBER_DETAILS })
    } else {
      dispatch({ type: UPDATE_CUSTOMER_BY_CUSTOMER_ID, payload: errors.ERROR_IN_UPDATING_MEMBER })
    }
  }
}

export const resetUpdateMember = () => {
  return {
    type: RESET_UPDATE_MEMBER,
  }
};

export const closeMemberDrawer = () => {
  return {
    type: CLOSE_MEMBER_DRAWER,
  }
}

export const openMemberDrawer = () => {
  return {
    type: OPEN_MEMBER_DRAWER,
  }
}

export const cancelMandate = (mandateId) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  try {
    const response = await cancelMandateByMandateId(ACCESS_TOKEN, mandateId);
    dispatch({ type: CANCEL_MANDATE_BY_MANDATE_ID, payload: response })
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else if (e.response && e.response.status === UNPROCESSABLE_ENTITY) {
      dispatch({ type: CANCEL_MANDATE_BY_MANDATE_ID, payload: errors.ERROR_CANCEL_MANDATE })
    } else {
      dispatch({ type: CANCEL_MANDATE_BY_MANDATE_ID, payload: errors.ERROR_IN_CANCELLING_MANDATE })
    }
  }
}

export const resetCancelMandateStatus = () => {
  return {
    type: RESET_CANCEL_MANDATE_STATUS,
  }
}

export const cancelSubscription = (subscriptionId) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  try {
    const response = await cancelSubscriptionById(ACCESS_TOKEN, subscriptionId);
    dispatch({ type: CANCEL_SUBSCRIPTION_BY_ID, payload: response })
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else if (e.response && e.response.status === UNPROCESSABLE_ENTITY && e.response.data) {
      dispatch({ type: CANCEL_SUBSCRIPTION_BY_ID, payload: e.response })
    } else {
      dispatch({ type: CANCEL_SUBSCRIPTION_BY_ID, payload: errors.ERROR_IN_CANCELLING_SUBSCRIPTION })
    }
  }
}

export const resetCancelWithdrawSubscriptionStatus = () => {
  return {
    type: RESET_CANCEL_WITHDRAW_SUBSCRIPTION_STATUS,
  }
}

export const withdrawSubscription = (subscriptionId) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  try {
    const response = await withdrawSubscriptionById(ACCESS_TOKEN, subscriptionId);
    dispatch({ type: WITHDRAW_SUBSCRIPTION_BY_ID, payload: response })
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else if (e.response && e.response.status === UNPROCESSABLE_ENTITY) {
      dispatch({ type: WITHDRAW_SUBSCRIPTION_BY_ID, payload: e.response })
    } else {
      dispatch({ type: WITHDRAW_SUBSCRIPTION_BY_ID, payload: errors.ERROR_IN_WITHDRAWING_SUBSCRIPTION })
    }
  }
}

export const getInvoices = (subscriptionId) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  try {
    const response = await getInvoicesBySubscriptionId(ACCESS_TOKEN, subscriptionId);
    dispatch({ type: GET_INVOICES_BY_SUBSCRIPTION_ID, payload: response })
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else {
      dispatch({ type: GET_INVOICES_BY_SUBSCRIPTION_ID, payload: errors.ERROR_IN_GETTING_INVOICES })
    }
  }
}

export const getInvoicesByFamily = (familyId) => async (dispatch, getState) => {
  const accessToken = getState().auth.authDetails.data.access_token;
  const resetSession = () => ({ type: LOG_OUT });
  try {
    dispatch({ type: GET_INVOICES_BY_FAMILY_ID_REQUEST });
    const response = await getInvoicesByFamilyId(accessToken, familyId);
    dispatch({ type: GET_INVOICES_BY_FAMILY_ID_SUCCESS, payload: response.data });
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch(resetSession());
    } else {
      dispatch({ type: GET_INVOICES_BY_FAMILY_ID_FAILURE, payload: "Invoices could not be fetched." });
    }
  }
}

export const resetInvoiceDetails = () => {
  return {
    type: RESET_INVOICE_DETAILS,
  }
}

export const getOrder = (orderId) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  try {
    const response = await getOrderById(ACCESS_TOKEN, orderId);
    dispatch({ type: GET_ORDER_BY_ID, payload: response.data })
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else {
      dispatch({ type: GET_ORDER_BY_ID, payload: errors.ERROR_WHILE_FETCHING_ORDER })
    }
  }
}

export const resetOrderDetails = () => {
  return {
    type: RESET_ORDER_DETAILS,
  }
}

export const openViewDetails = (orderId) => {
  return {
    type: OPEN_VIEW_DETAILS,
    payload: orderId,
  }
}

export const closeViewDetails = () => {
  return {
    type: CLOSE_VIEW_DETAILS,
    payload: null,
  }
}

export const closeBookConsultationDrawer = () => {
  return {
    type: CLOSE_BOOK_CONSULTATION_DRAWER,
  }
}

export const openBookConsultationDrawer = () => {
  return {
    type: OPEN_BOOK_CONSULTATION_DRAWER,
  }
}

export const openVisitBookDrawer = () => {
  return {
    type: OPEN_VISIT_BOOK_DRAWER,
  }
}

export const closeVisitBookDrawer = () => {
  return {
    type: CLOSE_VISIT_BOOK_DRAWER,
  }
}

export const getConsultationById = (consultationId) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  let response;
  let data;
  try {
    response = await getConsultationByConsultationId(ACCESS_TOKEN, consultationId);
    data = response.data;
    dispatch({ type: GET_CONSULTATION_BY_ID, payload: data });
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else {
      dispatch({ type: GET_CONSULTATION_BY_ID, payload: errors.ERROR_IN_FETCHING_PARTICULAR_CONSULTATION });
    }
  }
}

export const resetConsultationById = () => {
  return {
    type: RESET_CONSULTATION_BY_ID,
  }
}

export const createVitals = (body, consultationId) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  const success = (id, response, vitals) => ({
    type: ADD_VITALS_BY_CONSULTATION_ID,
    payload: response,
    consultationId: id,
    vitals,
  });
  const failure = () => ({
    type: ADD_VITALS_BY_CONSULTATION_ID,
    payload: errors.ERROR_WHILE_ADDING_VITALS,
  });
  try {
    const response = await createVitalsByConsultationId(ACCESS_TOKEN, body, consultationId);
    dispatch(success(consultationId, response, body));
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else {
      dispatch(failure());
    }
  }
}

export const resetAddVitalsStatus = () => {
  return {
    type: RESET_ADD_VITALS_STATUS,
  }
}

export const closeAddVitalsDrawer = () => {
  return {
    type: CLOSE_ADD_VITALS_DRAWER,
  }
}

export const openAddVitalsDrawer = () => {
  return {
    type: OPEN_ADD_VITALS_DRAWER,
  }
}

export const setPresentableConsultations = (consultations) => {
  return {
    type: SET_PRESENTABLE_CONSULTATIONS,
    payload: consultations,
  }
}

export const incrementConsultationPage = () => {
  return {
    type: INCREMENT_CONSULTATION_PAGE,
  }
}

export const decrementConsultationPage = () => {
  return {
    type: DECREMENT_CONSULTATION_PAGE,
  }
}

export const resetConsultationPageNumber = () => {
  return {
    type: RESET_CONSULTATION_PAGE_NUMBER,
  }
}

export const setCurrentClinicLocation = (clinic) => {
  history.push('/homePage');
  return {
    type: SET_CURRENT_CLINIC_LOCATION,
    payload: clinic
  };
};

export const editCurrentClinicLocation = (clinic) => {
  return {
    type: EDIT_CURRENT_CLINIC_LOCATION,
    payload: clinic
  };
};

export const getAllClinics = () => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  let response;
  let data;
  try {
    response = await getClinicList(ACCESS_TOKEN);
    data = response.data;
    dispatch({ type: GET_ALL_CLINICS, payload: data })
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else {
      dispatch({ type: GET_ALL_CLINICS, payload: errors.ERROR_INFETCHING_CLINICS })
    }
  }
};

export const getConsultationsByClinicId = (
  clinicId, consultationStatus, page, sortDir, fromDate, toDate, sortKeyProp, dateKeyStartValue = "scheduleStartsAt", dateKeyEndValue = "scheduleEndsAt"
) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;

  let response;
  let data;
  try {
    response = await getConsultationsByClinic(ACCESS_TOKEN, clinicId, consultationStatus, page, sortDir, fromDate, toDate, sortKeyProp, dateKeyStartValue, dateKeyEndValue);
    data = response.data;
    dispatch({ type: GET_CONSULTATIONS_BY_CLINIC_ID, payload: data })
    dispatch({
      type: SET_PRESENTABLE_CONSULTATION_QUEUE,
      payload: data.consultations,
    })
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else {
      // dispatch({ type: GET_CONSULTATIONS_BY_CLINIC_ID, payload: errors.ERROR_IN_FETCHING_CONSULTATIONS_BY_CLINIC_ID })
    }
  }
}

export const getConsultationsByClinicIdFirstPage = (clinicId, consultationStatus, page, sortDir, fromDate, toDate) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  let response;
  let data;
  try {
    response = await getConsultationsByClinic(ACCESS_TOKEN, clinicId, consultationStatus, page, sortDir, fromDate, toDate);
    data = response.data;
    dispatch({ type: GET_CONSULTATIONS_BY_CLINIC_ID_FIRST_PAGE, payload: data })
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else {
      dispatch({ type: GET_CONSULTATIONS_BY_CLINIC_ID_FIRST_PAGE, payload: errors.ERROR_IN_FETCHING_CONSULTATIONS_BY_CLINIC_ID })
    }
  }
}

export const resetConsultationQueue = () => {
  return {
    type: RESET_CONSULTATION_QUEUE,
  }
}

export const assignDoctorByConsultationId = (consultationId, doctorId) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  try {
    await assignDoctor(ACCESS_TOKEN, consultationId, doctorId);
    dispatch({ type: ASSIGN_DOCTOR_BY_CONSULTATION_ID, payload: apiResponse.DOCTOR_ASSIGNED })
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else {
      dispatch({ type: ASSIGN_DOCTOR_BY_CONSULTATION_ID, payload: errors.ERROR_WHILE_ASSIGNING_DOCTOR })
    }
  }
}

export const resetDoctorByConsultationId = () => {
  return {
    type: RESET_DOCTOR_BY_CONSULTATION_ID,
  }
}

export const reAssignDoctorByConsultationId = (consultationId, doctorId) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  try {
    await reAssignDoctor(ACCESS_TOKEN, consultationId, doctorId);
    dispatch({
      type: RE_ASSIGN_DOCTOR_BY_CONSULTATION_ID,
      payload: apiResponse.DOCTOR_ASSIGNED,
      doctorId,
      consultationId,
    })
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else {
      dispatch({ type: RE_ASSIGN_DOCTOR_BY_CONSULTATION_ID, payload: errors.ERROR_WHILE_ASSIGNING_DOCTOR })
    }
  }
}

export const setPresentableConsultationQueue = (consultations) => {
  return {
    type: SET_PRESENTABLE_CONSULTATION_QUEUE,
    payload: consultations,
  }
}

export const cancelConsultationById = (consultationId) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  const success = (id) => ({
    type: CANCEL_CONSULTATION_BY_ID,
    payload: apiResponse.CONSULTATION_CANCELLED,
    consultationId: id,
  });
  const failure = () => ({
    type: CANCEL_CONSULTATION_BY_ID,
    payload: errors.ERROR_IN_CANCELLING_CONSULTATION,
  });
  try {
    await cancelConsultationByConsultationId(ACCESS_TOKEN, consultationId);
    dispatch(success(consultationId));
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else {
      dispatch(failure());
    }
  }
};

export const resetCancelConsultationStatus = () => {
  return {
    type: RESET_CANCEL_CONSULTATION_STATUS,
  }
}

export const incrementConsultationQPage = () => {
  return {
    type: INCREMENT_CONSULTATIONQ_PAGE,
  }
}

export const decrementConsultationQPage = () => {
  return {
    type: DECREMENT_CONSULTATIONQ_PAGE,
  }
}

export const resetConsultationQPageNumber = () => {
  return {
    type: RESET_CONSULTATIONQ_PAGE_NUMBER,
  }
}

export const checkoutConsultation = (consultationId) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  let response;
  let data;
  try {
    response = await checkoutConsultationById(ACCESS_TOKEN, consultationId);
    data = response.data;
    dispatch({ type: CHECKOUT_CONSULTATION, payload: data })
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else {
      dispatch({ type: CHECKOUT_CONSULTATION, payload: errors.ERROR_IN_CHECKING_OUT_CONSULTATION })
    }
  }
};


export const resetCheckoutConsultationStatus = () => {
  return {
    type: RESET_CHECKOUT_CONSULTATION_STATUS,
  }
}

export const getConsultationOrder = (orderId) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  try {
    const response = await getOrderById(ACCESS_TOKEN, orderId);
    dispatch({ type: GET_CONSULTATION_ORDER, payload: response.data })
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else {
      dispatch({ type: GET_CONSULTATION_ORDER, payload: errors.ERROR_WHILE_FETCHING_ORDER })
    }
  }
}

export const resetConsultationOrderDetails = () => {
  return {
    type: RESET_CONSULTATION_ORDER_DETAILS,
  }
}

export const resetCreditDetails = () => {
  return {
    type: RESET_CREDIT_DETAILS,
  }
};

export const createOfflinePayment = (orderId, payments) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  let response;
  let data;
  try {
    response = await createOfflinePaymentByOrderId(ACCESS_TOKEN, orderId, payments);
    data = response.data;
    dispatch({ type: CREATE_OFFLINE_PAYMENT, payload: data })
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else {
      dispatch({ type: CREATE_OFFLINE_PAYMENT, payload: errors.ERROR_IN_CREATING_OFFLINE_PAYMENT })
    }
  }
};

export const resetOfflinePaymentStatus = () => {
  return {
    type: RESET_OFFLINE_PAYMENT_STATUS,
  }
}

export const searchProductsByBusinessId = (businessId) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  let response;
  let data;
  try {
    response = await searchAllProductsByBusinessId(ACCESS_TOKEN, businessId);
    data = response.data;
    dispatch({ type: SEARCH_PRODUCTS_BY_BUSINESS_ID, payload: data })
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else {
      dispatch({ type: SEARCH_PRODUCTS_BY_BUSINESS_ID, payload: errors.ERROR_IN_FETCHING_PRODUCTS_BY_BUSINESS_ID })
    }
  }
}

export const resetProductsByBusinessId = () => {
  return {
    type: RESET_PRODUCTS_BY_BUSINESS_ID,
  }
}

export const getCustomerByPhoneNumber = (phoneNumber) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  try {
    const response = await getCustomerDetailsByPhoneNumber(ACCESS_TOKEN, phoneNumber);
    dispatch({ type: GET_CUSTOMER_BY_PHONE_NUMBER, payload: response.data })
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else if (e.response && e.response.status) {
      dispatch({ type: GET_CUSTOMER_BY_PHONE_NUMBER, payload: e.response.status })
    } else {
      dispatch({ type: GET_CUSTOMER_BY_PHONE_NUMBER, payload: e })
    }
  }
};

export const getSubscriptionById = (id) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  const request = () => ({ type: GET_SUBSCRIPTION_BY_SUBSCRIPTION_ID_REQUEST });
  dispatch(request());
  try {
    const response = await getSubscriptionBySubscriptionId(ACCESS_TOKEN, id);
    dispatch({ type: GET_SUBSCRIPTION_BY_SUBSCRIPTION_ID_SUCCESS, payload: response.data });
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else if (e.response && e.response.status) {
      dispatch({ type: GET_SUBSCRIPTION_BY_SUBSCRIPTION_ID_FAILED, payload: e.response.status });
    } else {
      dispatch({ type: GET_SUBSCRIPTION_BY_SUBSCRIPTION_ID_FAILED, payload: e });
    }
  }
};

export const resetCustomerByPhoneNumber = () => {
  return {
    type: RESET_CUSTOMER_BY_PHONE_NUMBER,
  }
}

export const resetCreateHeadSubscriber = () => {
  return {
    type: RESET_CREATE_NEW_CUSTOMER,
  }
}

export const createSubscription = (newSubscription) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  try {
    const response = await createNewSubscription(ACCESS_TOKEN, newSubscription);
    dispatch({ type: CREATE_SUBSCRIPTION, payload: { newSubscriptionStatus: apiResponse.SUBSCRIPTION_CREATED, newSubscription: response } })
  } catch (e) {
    if (e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    }
    dispatch({ type: CREATE_SUBSCRIPTION, payload: errors.ERROR_IN_CREATING_SUBSCRIPTION })
  }
};

export const resetCreateSubscription = () => {
  return {
    type: RESET_CREATE_SUBSCRIPTION,
  }
}

export const setNewPlanMobileNumber = (mobileNumber) => {
  return {
    type: SET_NEW_PLAN_MOBILE_NUMBER,
    payload: mobileNumber
  };
};

export const resetNewPlanMobileNumber = () => {
  return {
    type: RESET_NEW_PLAN_MOBILE_NUMBER,
  };
};


// ** New features - Onboarding
export const uploadOnboardFile = (recipients, file) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  let response;
  let data;
  dispatch({ type: CREATE_ONBOARDING_REQUEST })
  try {
    response = await uploadOnboardingFile(ACCESS_TOKEN, recipients, file);
    data = response.status;
    dispatch({ type: CREATE_ONBOARDING_RESPONSE, payload: data })
  } catch (e) {
    dispatch({ type: CREATE_ONBOARDING_RESPONSE, payload: errors.ERROR_IN_CREATING_ONBOARDING })
  }
};


export const resetUploadOnboardFile = () => {
  return {
    type: RESET_UPLOAD_ONBOARD_FILE,
  };
};

export const getAllSubscriptionsByDate = (currentPage, itemsPerPage, startDate, endDate, subscriptionStatus) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  let response;
  let data;
  dispatch({ type: GET_ALL_SUBSCRIPTIONS_BY_DATE_PAGINATED })
  try {
    response = await getAllSubscriptionsByDatePaginated(ACCESS_TOKEN, currentPage, itemsPerPage, startDate, (Number(endDate) + ADD_ONE_DAY).toString(), subscriptionStatus);
    data = response.data;
    dispatch({ type: GET_ALL_SUBSCRIPTIONS_BY_DATE_PAGINATED_RESPONSE, payload: data });
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else {
      dispatch({ type: GET_ALL_SUBSCRIPTIONS_BY_DATE_PAGINATED_RESPONSE, payload: errors.ERROR_IN_GETTING_ALL_SUBSCRIPTIONS_BY_DATE_PAGINATED });
    }
  }
};

export const resetAllSubscriptionsByDate = () => {
  return {
    type: RESET_ALL_SUBSCRIPTIONS_BY_DATE_PAGINATED_RESPONSE,
  }
}

export const activatePolicy = (subscriptionId, object) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  let response;
  let data;
  dispatch({ type: ADD_PLAN_DETAILS })
  try {
    response = await activatePolicyBySubscriptionId(ACCESS_TOKEN, subscriptionId, object);
    data = response.data;
    dispatch({ type: ADD_PLAN_DETAILS_RESPONSE, payload: data })
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else {
      dispatch({ type: ADD_PLAN_DETAILS_RESPONSE, payload: errors.ERROR_IN_ADDING_PLAN_DETAILS })
    }
  }
};

export const resetAddPlans = () => {
  return {
    type: RESET_ADD_PLAN_DETAILS,
  }
}

export const cancelPlan = (subscriptionId, familyId) => {
  const request = () => ({ type: PLAN_CANCELATION_REQUEST });
  const success = (payload) => (
    { type: PLAN_CANCELATION_SUCCESS, payload }
  );
  const failure = () => ({ type: PLAN_CANCELATION_FAILURE });
  const resetSession = () => ({ type: LOG_OUT });
  const setError = (error) => ({ type: SET_ERROR_IN_UPDATE_TNC, error });
  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
      await updateSubscriptionTnC(
        ACCESS_TOKEN, subscriptionId, familyId, tncStatus.FAILED
      );
      const payload = {
        cancellation_code: 'CN_USER_REQUEST',
      };
      await cancelSubscriptionById(
        ACCESS_TOKEN, subscriptionId,
        payload,
      );

      dispatch(success(subscriptionId));
    } catch (e) {
      if (e.response && e.response.status === UNAUTHORIZED) {
        dispatch(resetSession());
      } else {
        dispatch(failure());
        dispatch(setError(JSON.stringify(e)));
      }
    }
  };
}

export const updateTNC = (subscriptionId, familyId, tncStatus, comments) => {
  const request = () => ({ type: UPDATE_TNC_REQUEST });
  const success = (payload) => (
    { type: UPDATE_TNC_SUCCESS, payload }
  );
  const failure = () => ({ type: UPDATE_TNC_FAILURE });
  const resetSession = () => ({ type: LOG_OUT });
  const setError = (error) => ({ type: SET_ERROR_IN_UPDATE_TNC, error });
  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
      await updateSubscriptionTnC(
        ACCESS_TOKEN, subscriptionId, familyId,
        tncStatus, comments
      );
      dispatch(success({ tncStatus, subscriptionId, comments }));
    } catch (e) {
      if (e.response && e.response.status === UNAUTHORIZED) {
        dispatch(resetSession());
      } else {
        dispatch(failure());
        dispatch(setError(JSON.stringify(e)));
      }
    }
  };
}

export const getHealthDeclarationTnCData = (subscriptionId, version = '1.1.0', documentType = "ghd") => {
  const request = () => ({ type: FETCH_HEALTH_DECLARATION_TNC_REQUEST });
  const success = (payload) => ({ type: FETCH_HEALTH_DECLARATION_TNC_SUCCESS, payload });
  const failure = () => ({ type: FETCH_HEALTH_DECLARATION_TNC_FAILURE });
  const resetSession = () => ({ type: LOG_OUT });
  const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });

  return async (dispatch, getState) => {
    const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
    dispatch(request());
    try {
      const response = await getHealthDeclarationTnC(ACCESS_TOKEN, version, documentType, subscriptionId);
      dispatch(success(response.data));
    } catch (e) {
      const error = buildCustomError(e);
      if (e.response && e.response.status === 401) {
        dispatch(resetSession())
      } else if (e.response && e.response.status === 404) {
        const error = _.assignIn({}, { message: "Couldn't fetch Good Health Declaration questions.", statusCode: e.response ? e.response.status : e });
        dispatch(failure());
        dispatch(setError(error));
      } else {
        Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`),
          { extra: { errorData: JSON.stringify(error.data), function: 'getHealthDeclarationTnCData' } });
        dispatch(failure());
        dispatch(setError(error));
      }
    }
  }
};

export const resetTNC = () => {
  return {
    type: RESET_TNC,
  }
}

export const connectTeleCall = (partnerId, phoneNumber) => async (dispatch, getState) => {
  const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
  let response;
  let data;
  dispatch({ type: CONNECT_TELECALL_REQUEST })
  try {
    response = await connectTeleCallByPartnerId(ACCESS_TOKEN, partnerId, phoneNumber);
    data = response.data;
    dispatch({ type: CONNECT_TELECALL_RESPONSE, payload: data })
  } catch (e) {
    if (e.response && e.response.status === UNAUTHORIZED) {
      dispatch({ type: LOG_OUT });
    } else {
      dispatch({ type: CONNECT_TELECALL_RESPONSE, payload: errors.ERROR_IN_PLACING_TELECALL })
    }
  }
};


export const resetConnectTeleCall = () => {
  return {
    type: RESET_TELECALL,
  }
}

export const updateWhatsAppConsent = (consent) => {
  const request = () => ({ type: UPDATE_WHATSAPP_CONSENT_ONBOARDING_REQUEST });
  const success = (payload) => ({ type: UPDATE_WHATSAPP_CONSENT_ONBOARDING_SUCCESS, payload });
  const failure = () => ({ type: UPDATE_WHATSAPP_CONSENT_ONBOARDING_FAILURE });
  const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
  const resetError = () => ({ type: errorActionTypes.RESET_ERROR });

  return async (dispatch, getState) => {
    const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
    dispatch(request());
    try {
      const { customerByPhoneNumber } = getState().newPlan;
      const consentData = {
        whatsapp: {
          consent: consent,
          phone: customerByPhoneNumber.primary_number
        }
      }
      dispatch(resetError());
      await addWhatsAppConcent(ACCESS_TOKEN, consentData, customerByPhoneNumber.id);
      dispatch(success(consentData.whatsapp));
    } catch (e) {
      const error = {};
      dispatch(failure());
      dispatch(setError(error));
    }
  };
}

export const updateCohortEvent = (accessToken, familyId, cohortData) => {
  const request = () => ({ type: UPDATE_COHORT_REQUEST });
  const success = (payload) => (
    { type: UPDATE_COHORT_SUCCESS, payload }
  );
  const failure = () => ({ type: UPDATE_COHORT_FAILURE });
  const resetSession = () => ({ type: LOG_OUT });
  const setError = (error) => ({ type: SET_ERROR_IN_UPDATE_COHORT, error });
  return async (dispatch) => {
    dispatch(request());
    try {
      const response = await updateCohort(
        accessToken, familyId, cohortData,
      );
      dispatch(success({ subscriptionId: response.data, cohortValue: cohortData.cohort }));
    } catch (e) {
      if (e.response && e.response.status === UNAUTHORIZED) {
        dispatch(resetSession());
      } else {
        dispatch(failure());
        dispatch(setError(errors.ERROR_IN_UPDATING_COHORT));
      }
    }
  };
}

export const getPlanBillingPrefEvent = (accessToken, familyId, productId) => {
  const request = () => ({ type: GET_BILLING_PREF_REQUEST });
  const success = (payload) => (
    { type: GET_BILLING_PREF_SUCCESS, payload }
  );
  const failure = () => ({ type: GET_BILLING_PREF_FAILURE });
  const resetSession = () => ({ type: LOG_OUT });
  const setError = (error) => ({ type: SET_ERROR_IN_GETTING_BILLING_PREF, error });
  return async (dispatch) => {
    dispatch(request());
    try {
      const response = await getPlanBillingPref(
        accessToken, familyId, productId,
      );
      dispatch(success(response.data));
    } catch (e) {
      if (e.response && e.response.status === UNAUTHORIZED) {
        dispatch(resetSession());
      } else {
        dispatch(failure());
        dispatch(setError(errors.ERROR_IN_GETTING_COHORT));
      }
    }
  };
}

export const selectCohort = (cohortSelected) => {
  const set = () => ({ type: SELECT_COHORT, payload: cohortSelected });
  return async (dispatch) => {
    dispatch(set());
  };
}

export const addCustomerMetadataEvent = (customerId, payload, allMembers) => {
  const request = () => ({ type: familyActionTypes.ADD_METADATA_REQUEST });
  const success = (payload) => (
    { type: familyActionTypes.ADD_METADATA_SUCCESS, payload }
  );
  const failure = () => ({ type: familyActionTypes.ADD_METADATA_FAILURE });
  const resetSession = () => ({ type: LOG_OUT });
  const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
      await addCustomerMetadata(
        ACCESS_TOKEN, customerId, payload,
      );
      dispatch(success(allMembers));
    } catch (e) {
      if (e.response && e.response.status === UNAUTHORIZED) {
        dispatch(resetSession());
      } else {
        dispatch(failure());
        dispatch(setError(errors));
      }
    }
  };
}

/**
 * 
 * @param {object | string} errorToSet The error object or string to be set; 
 * @returns action dispatcher to set errors;
 * This method can be used whenever an Error needs to be shown in a snackbar on any component
 * and sets the `error.error` property of the redux state.
 */
export const setError = (errorToSet) => {
  const error = (errorToSet) => ({ type: errorActionTypes.SET_ERROR, error: errorToSet });
  return async (dispatch) => {
    dispatch(error(errorToSet));
  }
};
