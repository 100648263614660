import axios from 'axios';

import config from '../config';

export const getMemberDetails = async (ACCESS_TOKEN, familyId) => {
  const url = config.apiURL + `/v3/family/${familyId}/members`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,

    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.get(url, options);
  return response;
};

export const getMandateDetails = async (ACCESS_TOKEN, familyId) => {
  const url = config.apiURL + `/v3/mandates?familyId=${familyId}`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,

    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.get(url, options);
  return response;
};

export const createNewCustomer = async (ACCESS_TOKEN, newCustomer) => {
  const url = config.apiURL + `/v3/customers`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,
    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.post(url, newCustomer, options);
  return response;
}

export const addMember = async (ACCESS_TOKEN, newMember, familyId) => {
  const url = config.apiURL + `/v3/family/${familyId}/members`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,
    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.post(url, newMember, options);
  return response;
}

export const updateCustomerPrimaryNumber = async (ACCESS_TOKEN, familyId, body) => {
  const url = config.apiURL + `/v3/family/${familyId}/change-rmn`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,
    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.post(url, body, options);
  return response;
}

export const updateCustomerAlternateNumber = async (ACCESS_TOKEN, customerId, body) => {
  const url = config.apiURL + `/v3/customers/${customerId}/alternate-number`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,
    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.put(url, body, options);
  return response;
}

export const updateCustomerByCustomerId = async (ACCESS_TOKEN, member, familyId, customerId) => {
  const url = config.apiURL + `/v3/family/${familyId}/members/${customerId}`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,
    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.put(url, member, options);
  return response;
}

export const getCustomerDetailsByPhoneNumber = async (ACCESS_TOKEN, phoneNumber) => {
  const url = config.apiURL + `/v3/customers?phoneNumber=${phoneNumber}`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,

    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.get(url, options);
  return response;
};

export const getSubscriptionBySubscriptionId = async (ACCESS_TOKEN, subscriptionId) => {
  const url = config.apiURL + `/v3/subscriptions/${subscriptionId}`;
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + ACCESS_TOKEN,

    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.get(url, options);
  return response;
};

export const updateCohort = async (ACCESS_TOKEN, familyId, cohortData) => {
  const url = `${config.apiURL}/v3/family/${familyId}/billing-preferences`;
  const options = {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${ACCESS_TOKEN}`,
    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
    url,
    data: cohortData,
  };
  const result = await axios(options);
  return result;
}

export const getPlanBillingPref = async (ACCESS_TOKEN, familyId, productId) => {
  const url = `${config.apiURL}/v3/family/${familyId}/billing-preferences?productId=${productId}`;
  const options = {
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${ACCESS_TOKEN}`,
    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.get(url, options);
  return response;
};

export const addCustomerMetadata = async (ACCESS_TOKEN, customerId, payload) => {
  const url = `${config.apiURL}/v3/customers/${customerId}/metadata`;
  const options = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${ACCESS_TOKEN}`,
    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
    url,
    data: payload,
  };
  const result = await axios(options);
  return result;
}

export const sendCOI = async (ACCESS_TOKEN, subscriptionId, payload) => {
const url = `${config.apiURL}/v1/notifications/subscriptions/${subscriptionId}/download-coi`;
  const options = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${ACCESS_TOKEN}`,
    },
    timeout: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
    url,
    data: payload,
  };
  const result = await axios(options);
  return result;
}


export const deleteCOI = async (ACCESS_TOKEN, subscriptionId) => {
  const url = `${config.apiURL}/v4/subscriptions/${subscriptionId}/coi/delete`;
  const options = {
      headers: {
        'content-type': 'application/json',
        'Authorization': 'Bearer ' + ACCESS_TOKEN,
  
      },
      timeout: 10000,
      responseType: 'json',
      responseEncoding: 'utf8',
    };
  const response = await axios.put(url, {}, options);
  return response;
};