import React from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import SideMenuBar from '../components/Home/sideMenuBar/SideMenuBar';
import TopBar from '../components/Home/topBar/TopBar';
import TabDetails from '../components/CustomerDetailsTask/TabDetails';
import LoginPage from './LoginPage';

import { getCreditDetailsByFamilyId } from '../actions/index';
import { consultationActions } from '../actions/consultationAction';
class CustomerDetailsPage extends React.Component {

    render() {
        if (!this.props.auth.isLoggedIn) {
            return (
                <LoginPage />
            )
        } else if (this.props.auth.isLoggedIn && !_.isEmpty(this.props.familyDetails.currentCustomer)) {
            return (
                <div className='main' style={{
                    height: "100vh",
                    minWidth: '80vw',
                    backgroundColor: '#f4f4f4',

                }}>
                    <TopBar />
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <SideMenuBar />
                        <TabDetails />
                    </div>
                </div>
            );
        } else {
            return (<div> Something is not right !!</div>);
        }
    }
}

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error, familyDetails: state.familyDetails };
};

const mapDispatchToProps = {
    getCreditDetailsByFamilyId,
    setGeneralBilling: consultationActions.setGeneralBilling,
};

const reduxWrapper = connect(mapStateToProps, mapDispatchToProps);
export default reduxWrapper(CustomerDetailsPage);



