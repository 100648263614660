import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, withStyles, TextField, IconButton } from '@material-ui/core';
import useStyles from './style';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from '@material-ui/pickers';
import { getBillingHistoryEvent, resetBillingHistory } from '../../../actions';

class History extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            selectedDate: null,
            orderId: null,
        };
    }

    componentDidMount() {
        const { getBillingHistory, familyDetails, resetBillingHistory } = this.props;
        const { page, orderId, selectedDate } = this.state;
        const familyId = familyDetails.currentCustomer.family.id;
        resetBillingHistory();
        setTimeout(() => {
            // size = 10 for now;
            getBillingHistory(familyId, orderId, selectedDate, page, 10);
            this.setState({
                page: 2,
            });
        }, 500);

        window.addEventListener('beforeunload', () => {
            resetBillingHistory();
        })
    }

    handleDateOnChange = (date) => {
        this.setState({
            selectedDate: moment(date).startOf('day').valueOf(),
            page: 1,
        });
    };

    handleIconButtonOnClick = () => {
        this.setState({
            selectedDate: null,
            page: 1,
        });
    };

    handleOrderIdOnChange = (event) => {
        const orderId = event.target.value.replace(/[^0-9]/g, "");
        this.setState({
            orderId: orderId !== null ? orderId : null,
            page: 1,
        })
    };

    searchButtonOnClick = () => {
        const { getBillingHistory, familyDetails, resetBillingHistory } = this.props;
        const { page, selectedDate, orderId } = this.state;
        const familyId = familyDetails.currentCustomer.family.id;
        // any time the search button is clicked, reset the billing history
        // and issue a fresh request;
        this.setState({
            page: 1,
        }, () => {
            resetBillingHistory();
            setTimeout(() => {
                // size = 10 for now;
                getBillingHistory(familyId, orderId, selectedDate, page, 10);
            }, 500);
        })
    }

    renderFilterUi = () => {
        const { classes } = this.props;
        const { selectedDate, orderId } = this.state;
        return (
            <div className={classes.filterContainer}>
                <span><strong>Filter By</strong></span>
                <div className={classes.filterRow}>
                    <div className={classes.itemSet}>
                        <div className={classes.item}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    value={selectedDate}
                                    placeholder="Search by date"
                                    onChange={this.handleDateOnChange}
                                    renderInput={(params) => <TextField
                                        placeholder="Enter date"
                                        style={{ fontFamily: 'Rubik' }}
                                        className={classes.filterInput}
                                        {...params}
                                    />}
                                />
                            </MuiPickersUtilsProvider>
                            <IconButton onClick={this.handleIconButtonOnClick}>
                                ×
                            </IconButton>
                        </div>
                        <div className={classes.item}>
                            <TextField
                                value={orderId}
                                placeholder="Search by Receipt no."
                                className={classes.filterInput}
                                onChange={this.handleOrderIdOnChange}
                                inputProps={{ maxLength: 20 }}
                            />
                        </div>
                    </div>
                    <Button
                        variant="outlined"
                        className={classes.searchBtn}
                        onClick={this.searchButtonOnClick}
                    >
                        Search
                    </Button>
                </div>
            </div>
        )
    }

    handleLoadMoreCtaOnClick = () => {
        const { page, selectedDate, orderId } = this.state;
        const { getBillingHistory, familyDetails, consultationDetails } = this.props;
        const scrollingListElem = document.getElementById('history-list-elem');
        // offset of 50 to fire the search request before the user reaches the end of the list;
        if ((scrollingListElem.scrollHeight - scrollingListElem.scrollTop <= scrollingListElem.clientHeight + 50)
            // 10 below is the assumed page *size* in the formula -> count/size = no. of pages;
            && (page <= Math.ceil(consultationDetails?.history?.count / 10))) {
            this.setState({
                page: page + 1,
            }, () => {
                const familyId = familyDetails.currentCustomer.family.id;
                // size = 10 for now;
                getBillingHistory(familyId, orderId, selectedDate, page, 10);
            });
        }
    }

    renderBillingHistoryUi = () => {
        const { classes, consultationDetails } = this.props;
        if (consultationDetails?.history?.list === null
            || (consultationDetails?.history?.list.length === 0
                && !consultationDetails.history.isLoading)) {
            return (
                <div className={classes.historyContainer}>
                    <span className={classes.header}>Billing History</span>
                    <div className={classes.billingHistoryList}>
                        <span className={classes.placeholder}>
                            No billing history found
                        </span>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={classes.historyContainer}>
                    <span className={classes.header}>Billing History</span>
                    <div className={classes.billingHistoryList} id="history-list-elem" onScroll={(event) => this.handleLoadMoreCtaOnClick()}>
                        {
                            (consultationDetails?.history?.list?.length === 0)
                                && consultationDetails.history.isLoading
                                ? <span className={classes.placeholder}>Loading</span>
                                : null
                        }
                        {
                            consultationDetails?.history?.list?.map(item => {
                                return (
                                    <div className={classes.billingHistoryItem}>
                                        <div className={classes.itemColumn}>
                                            <span className={classes.columnHeader}>
                                                Customer name
                                            </span>
                                            <span className={classes.columnValue}>
                                                {item.customer_name}
                                            </span>
                                        </div>
                                        <div className={classes.itemColumn}>
                                            <span className={classes.columnHeader}>
                                                Date
                                            </span>
                                            <span className={classes.columnValue}>
                                                {moment(item.generated_on).format('YYYY-MM-DD')}
                                            </span>
                                        </div>
                                        <div className={classes.itemColumn}>
                                            <span className={classes.columnHeader}>
                                                Receipt no.
                                            </span>
                                            <span className={classes.columnValue}>
                                                {item.order_id}
                                            </span>
                                        </div>
                                        <div className={classes.itemColumn}>
                                            <span className={classes.columnHeader}>
                                                Amount paid
                                            </span>
                                            <span className={classes.columnValue}>
                                                {item.paid_amount}
                                            </span>
                                        </div>
                                        <div className={classes.itemColumn}>
                                            <Button
                                                variant="contained"
                                                href={item.download_url}
                                                target="_blank"
                                                className={classes.viewReceiptButton}
                                            >
                                                View Receipt
                                            </Button>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {/* <Button
                            className={classes.loadMoreCta}
                            disabled={(page < Math.ceil(consultationDetails?.history?.count / 10)
                                || (consultationDetails?.history?.data?.length === 0)
                                || consultationDetails?.history?.isLoading)}
                            onClick={this.handleLoadMoreCtaOnClick}
                        >
                            Load more
                        </Button> */}
                    </div>
                </div>
            );
        }
    }

    componentWillUnmount() {
        const { resetBillingHistory } = this.props;
        resetBillingHistory();
        window.removeEventListener('beforeunload');
    }

    render() {
        const { classes } = this.props;
        return (
            <div
                className={classes.container}
            >
                {this.renderFilterUi()}
                {this.renderBillingHistoryUi()}
            </div>
        )
    };
};

const mapStateToProps = (state) => {
    const { consultationDetails, familyDetails } = state;
    return {
        consultationDetails,
        familyDetails,
    }
}

const mapDispatchToProps = (dispatch) => ({
    getBillingHistory: (familyId, orderId, generatedOn, page, size) => {
        dispatch(getBillingHistoryEvent(familyId, orderId, generatedOn, page, size));
    },
    resetBillingHistory: () => { dispatch(resetBillingHistory()); },
});

const reduxWrapper = connect(mapStateToProps, mapDispatchToProps);
const styleWrapper = withStyles(useStyles, { withTheme: true });
export default styleWrapper(reduxWrapper(History));