import React, { useEffect } from 'react';
import successAnimation from '../../../../assets/animations/success-animation.json';
import lottie from 'lottie-web';
import { withStyles } from '@material-ui/core';
import useStyles from './ConsultationFromStyle';

function SuccessAnimation(props) {
    useEffect(() => {
        const animation = lottie.loadAnimation({
            container: document.querySelector("#lottie-container"),
            animationData: JSON.parse(JSON.stringify(successAnimation)),
            autoplay: true,
            loop: false,
        });
        animation.addEventListener('complete', () => {
            const { closeDrawer } = props;
            closeDrawer();
            animation.destroy();
        })
    });
    const { classes }= props;
    return (
        <div id="lottie-container" className={classes.lottieContainer} />
    )
};

const styleWrapper = withStyles(useStyles, { withTheme: true });
export default styleWrapper(SuccessAnimation);