
import * as Sentry from '@sentry/react';
import { paymentActionTypes, errorActionTypes, consultationActionTypes } from '../constants';
import { notification } from '../config/notification';
import _, { reduce } from 'lodash';
import {
  createOrder, createOfflinePaymentByOrderId, createPaymentSMSLink, payInvoices,
  sendNotification,
} from '../clients/omsService';
import { buildCustomError, getProductPriceAfterDiscount } from '../common/utils';
import { LOG_OUT, UPDATE_WHATSAPP_CONSENT_REQUEST, UPDATE_WHATSAPP_CONSENT_SUCCESS, UPDATE_WHATSAPP_CONSENT_FAILURE } from './types';
import { addWhatsAppConcent } from '../clients';
import { getOrderTotal, getAvailableCreditAmount } from '../common/utils';
import { offlinePaymentSources, paymentSources, whatsAppConsent } from '../common/constants';
import { generateBilling } from '../clients';
import history from '../history';
import { billingType } from '../constants';
import { couponService } from '../clients/couponService';
const { StatusCodes: { UNAUTHORIZED, FORBIDDEN, SERVICE_UNAVAILABLE } } = require('http-status-codes');

const CLINIKK_PHONE = '7813811811';

function createOfflinePaymentEvent(externalId, itemType, itemPrice, familyId) {
  const request = () => ({ type: paymentActionTypes.CREATE_OFFLINE_PAYMENT_REQUEST });
  return async (dispatch, getState) => {
    const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
    dispatch(request());
    let orderDetails;
    try {
      orderDetails = {
        items: [
          {
            external: {
              id: externalId,
            },
            type: itemType,
            quantity: 1,
            price: itemPrice,
            total_discount: 0,
            total_discount_type: "flat"
          }
        ]
      };
      const createOrderResponse = await createOrder(ACCESS_TOKEN, orderDetails, familyId);
      const orderId = createOrderResponse.data.id;
      const creditDeductResponse = await createOfflinePaymentByOrderId(
        ACCESS_TOKEN, orderId, {
        payments: [
          {
            source: 'credit',
            amount: Number(itemPrice),
          },
        ],
      },
      );
      dispatch({ type: paymentActionTypes.CREATE_OFFLINE_PAYMENT_SUCCESS, payload: creditDeductResponse })
    } catch (e) {
      const error = buildCustomError(e);
      Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`),
                    { extra: { errorData: error.data, function: 'createOfflinePaymentEvent', requestPayload: orderDetails } });
      dispatch({ type: paymentActionTypes.CREATE_OFFLINE_PAYMENT_FAILURE });
    }
  };
}

function resetOfflinePaymentEvent() {
  return {
    type: paymentActionTypes.RESET_OFFLINE_PAYMENT,
  }
}

function resetPayment() {
  return {
    type: paymentActionTypes.RESET_PAYMENT,
  }
}

const createShortLink = (invoices, creditToBlock) => {
  return async (dispatch, getState) => {
    const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
    const familyDetails = getState().familyDetails;
    const createShortLinkRequest = () => ({ type: paymentActionTypes.CREATE_SHORTLINK_REQUEST });
    const createShortLinkSuccess = (payload) => ({
      type: paymentActionTypes.CREATE_SHORTLINK_SUCCESS,
      payload,
    });
    const createShortLinkFailure = () => ({ type: paymentActionTypes.CREATE_SHORTLINK_FAILURE });
    const resetSession = () => ({ type: LOG_OUT });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
    try {
      const invoiceData = [];
      invoices.forEach((invoice) => {
        invoiceData.push({
          invoice: {
            id: invoice.id,
          },
          payment_multiplier: invoice.subscription.payment_multiplier,
        });
      });
      const createOrderResponse = await payInvoices(ACCESS_TOKEN, { invoices: invoiceData }, familyDetails.allMembers.members[0].family.id);
      const orderId = createOrderResponse.data.order.id;
      dispatch(createShortLinkRequest());
      const productNamesFromInvoices = invoices.map((invoice) => invoice.subscription.product.name);
      const descriptionString = 'Payment for ' + productNamesFromInvoices.join(', ') + '.';
      const shortLinkResponse = await createPaymentSMSLink(ACCESS_TOKEN, orderId, {
        description: descriptionString,
        notify_user: {
          sms: false,
        },
        ...(creditToBlock && creditToBlock > 0 && {
          clinikk_cash: creditToBlock,
        }),
      });
      dispatch(createShortLinkSuccess(shortLinkResponse?.data));
    } catch (e) {
      console.log(e);
      const error = buildCustomError(e);
      dispatch(setError(error));
      if (error.statusCode === UNAUTHORIZED) {
        dispatch(resetSession());
      } else {
        Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`),
        { extra: { errorData: error.data, function: 'createShortLink', requestPayload: invoices } });
      }
      dispatch(createShortLinkFailure());
    }
  }
}

const constructNotificationPayload = (customerName, planName, phoneNumber, channel, paymentLink) => {
  const params = [
    customerName,
    planName,
    '-',
    'renewal',
    paymentLink,
    'call',
    CLINIKK_PHONE,
  ];
  const notifications = [];
  notifications.push({
    channel: channel,
    template: {
      ...(channel === notification.channels.SMS ? {
        id: notification.resendPaymentLinkNotificationTempId
      }
        : null
      ),
      ...(channel === notification.channels.WHATSAPP ? {
        name: notification.whatsAppTemplateName,
      }
        : null
      )
    },
    to: phoneNumber,
    data: {
      ...(channel === notification.channels.SMS
        ? {
          customer_name: customerName,
          payment_link: paymentLink,
        }
        : null
      ),
      ...(channel === notification.channels.WHATSAPP ?
        {
          redirect_url: paymentLink,
          params,
        }
        : null
      )
    },
  });
  return {
    notifications,
  };
}

const sendNotificationEvent = (invoices, phoneNumber, channel) => {
  return async (dispatch, getState) => {
    const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
    const payment = getState().payment;
    const familyDetails = getState().familyDetails;
    const resetSession = () => ({ type: LOG_OUT });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
    const updateWhatsAppConsentRequest = () => ({ type: UPDATE_WHATSAPP_CONSENT_REQUEST });
    const updateWhatsAppConsentSuccess = (payload) => ({
      type: UPDATE_WHATSAPP_CONSENT_SUCCESS,
      payload,
    });
    const updateWhatsAppConsentFailure = () => ({ type: UPDATE_WHATSAPP_CONSENT_FAILURE });
    const sendNotificationRequest = () => ({ type: paymentActionTypes.SEND_NOTIFICATION_REQUEST });
    const sendNotificationSuccess = (payload) => ({
      type: paymentActionTypes.SEND_NOTIFICATION_SUCCESS,
      payload,
    });
    const sendNotificationFailure = () => ({ type: paymentActionTypes.SEND_NOTIFICATION_FAILURE });
    const headSubscriber = familyDetails.allMembers.members.filter(member => member.relationship === 'self')[0];
    const planName = invoices.length === 1
      ? invoices[0].subscription.product.name
      : 'multiple plans';
    const shortLink = payment.shortLink.data.payments[0].metadata.short_url;
    // payloads
    const consentPayload = {
      whatsapp: {
        consent: 'granted',
        phone: phoneNumber,
      }
    };
    const notificationPayload = constructNotificationPayload(
      headSubscriber.first_name,
      planName,
      phoneNumber,
      channel,
      shortLink,
    );
    try {
      // if consent is missing, update the head subscriber's
      // whatsApp number to the one entered in the field
      // and mark consent as granted, then send the notification.
      // Else, just send the notification.
      if (((headSubscriber.preferences?.whatsapp.phone !== phoneNumber)
        || (headSubscriber.preferences?.whatsapp.consent !== whatsAppConsent.GRANTED))
        && (channel === notification.channels.WHATSAPP)) {
        dispatch(updateWhatsAppConsentRequest());
        await addWhatsAppConcent(ACCESS_TOKEN, consentPayload, headSubscriber.id);
        dispatch(updateWhatsAppConsentSuccess({
          phone: phoneNumber,
          consent: 'granted'
        }));
        dispatch(sendNotificationRequest());
        await sendNotification(ACCESS_TOKEN, notificationPayload);
        dispatch(sendNotificationSuccess());
      } else {
        // all in order, just send the notification;
        dispatch(sendNotificationRequest());
        await sendNotification(ACCESS_TOKEN, notificationPayload);
        dispatch(sendNotificationSuccess());
      }
    } catch (e) {
      const error = buildCustomError(e);
      dispatch(setError(error));
      if (e.response && e.response.status === UNAUTHORIZED) {
        dispatch(resetSession());
      } else {
        Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`),
        { extra: { errorData: error.data, function: 'sendNotificationEvent', requestPayload: notificationPayload } });
      }
      if ((headSubscriber?.preferences.whatsapp.phone !== phoneNumber)
        || (headSubscriber?.preferences.whatsapp.consent !== whatsAppConsent.GRANTED)) {
        dispatch(updateWhatsAppConsentFailure());
      }
      dispatch(sendNotificationFailure());
    }
  }
}


const sendRenewalReminder = (invoices, creditToBlock, phoneNumber, channel) => {
  return async (dispatch, getState) => {
    const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
    const familyDetails = getState().familyDetails;
    const headSubscriber = familyDetails.allMembers.members.filter(member => member.relationship === 'self')[0];
    const createShortLinkRequest = () => ({ type: paymentActionTypes.CREATE_SHORTLINK_REQUEST });
    const createShortLinkSuccess = (payload) => ({
      type: paymentActionTypes.CREATE_SHORTLINK_SUCCESS,
      payload,
    });
    const createShortLinkFailure = () => ({ type: paymentActionTypes.CREATE_SHORTLINK_FAILURE });
    const updateWhatsAppConsentRequest = () => ({ type: UPDATE_WHATSAPP_CONSENT_REQUEST });
    const updateWhatsAppConsentSuccess = (payload) => ({
      type: UPDATE_WHATSAPP_CONSENT_SUCCESS,
      payload,
    });
    const updateWhatsAppConsentFailure = () => ({ type: UPDATE_WHATSAPP_CONSENT_FAILURE });
    const sendNotificationRequest = () => ({ type: paymentActionTypes.SEND_NOTIFICATION_REQUEST });
    const sendNotificationSuccess = (payload) => ({
      type: paymentActionTypes.SEND_NOTIFICATION_SUCCESS,
      payload,
    });
    const sendNotificationFailure = () => ({ type: paymentActionTypes.SEND_NOTIFICATION_FAILURE });
    const resetSession = () => ({ type: LOG_OUT });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
    try {
      const invoiceData = [];
      invoices.forEach((invoice) => {
        invoiceData.push({
          invoice: {
            id: invoice.id,
          },
          payment_multiplier: invoice.subscription.payment_multiplier,
        });
      });
      const planName = invoices.length === 1
        ? invoices[0].subscription.product.name
        : "Plans'";
      const createOrderResponse = await payInvoices(ACCESS_TOKEN, { invoices: invoiceData }, familyDetails.allMembers.members[0].family.id);
      const orderId = createOrderResponse.data.order.id;
      dispatch(createShortLinkRequest());
      const productNamesFromInvoices = invoices.map((invoice) => invoice.subscription.product.name);
      const descriptionString = 'Payment for ' + productNamesFromInvoices.join(', ') + '.';
      const shortLinkResponse = await createPaymentSMSLink(ACCESS_TOKEN, orderId, {
        description: descriptionString,
        notify_user: {
          sms: false,
        },
        ...(creditToBlock > 0 && {
          clinikk_cash: creditToBlock,
        })
      });
      dispatch(createShortLinkSuccess(shortLinkResponse?.data));
      const shortLink = shortLinkResponse?.data?.payments[0]?.metadata?.short_url;
      // payloads;
      const consentPayload = {
        whatsapp: {
          consent: 'granted',
          phone: phoneNumber,
        }
      };
      const notificationPayload = constructNotificationPayload(
        headSubscriber.first_name,
        planName,
        phoneNumber,
        channel,
        shortLink,
      );

      // if consent is missing, update the head subscriber's
      // whatsApp number to the one entered in the field
      // and mark consent as granted, then send the notification.
      if (((headSubscriber?.preferences?.whatsapp.phone !== phoneNumber)
        || (headSubscriber?.preferences?.whatsapp.consent !== whatsAppConsent.GRANTED))
        && channel === notification.channels.WHATSAPP) {
        dispatch(updateWhatsAppConsentRequest());
        await addWhatsAppConcent(ACCESS_TOKEN, consentPayload, headSubscriber.id);
        dispatch(updateWhatsAppConsentSuccess({
          phone: phoneNumber,
          consent: whatsAppConsent.GRANTED,
        }));
        dispatch(sendNotificationRequest());
        await sendNotification(ACCESS_TOKEN, notificationPayload);
        dispatch(sendNotificationSuccess());
      } else {
        // all in order, just send the notification;
        dispatch(sendNotificationRequest());
        await sendNotification(ACCESS_TOKEN, notificationPayload);
        dispatch(sendNotificationSuccess());
      }
    } catch (e) {
      const error = buildCustomError(e);
      dispatch(setError(error));
      if (error.statusCode === UNAUTHORIZED) {
        dispatch(resetSession());
      }
      dispatch(createShortLinkFailure());
      if ((headSubscriber?.preferences.whatsapp.phone !== phoneNumber)
        || (headSubscriber?.preferences.whatsapp.consent !== whatsAppConsent.GRANTED)) {
        dispatch(updateWhatsAppConsentFailure());
      }
      dispatch(sendNotificationFailure());
    }
  }
}

/**
 * Dispatch a series of events to create an (internal) order and offline payment for a given
 * order, then generate the bill and mark the bill generated against the invoice.
 * @param {*} paymentBreakup Object containing the breakup created on the billing page,
 * @returns void
 */
const settleBill = (paymentBreakup) => {
  return async (dispatch, getState) => {
    // values we need from the redux state;
    const access_token = getState().auth.authDetails.data.access_token;
    const orderedItems = getState().consultationDetails.billing.items;
    // const familyId = getState().familyDetails.currentCustomer.family.id;
    const billingFor = getState().consultationDetails.billing.for;
    // const clinicId = getState().auth.currentClinicLocation.id;
    const orderId = getState().consultationDetails.billing.order.id;
    // action types that we will dispatch;
    const resetSession = () => ({ type: LOG_OUT });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
    const createOfflinePaymentRequest = () => ({ type: paymentActionTypes.CREATE_OFFLINE_PAYMENT_REQUEST });
    const createOfflinePaymentSuccess = (payload) => ({ type: paymentActionTypes.CREATE_OFFLINE_PAYMENT_SUCCESS, payload });
    const createOfflinePaymentFailure = () => ({ type: paymentActionTypes.CREATE_OFFLINE_PAYMENT_FAILURE });
    const generateBillRequest = () => ({ type: consultationActionTypes.GENERATE_BILL_REQUEST });
    const generateBillSuccess = (payload) => ({ type: consultationActionTypes.GENERATE_BILL_SUCCESS, payload });
    const generateBillFailure = () => ({ type: consultationActionTypes.GENERATE_BILL_FAILURE });
    const resetOfflinePayment = () => ({ type: paymentActionTypes.RESET_OFFLINE_PAYMENT });
    const billableItems = getState().consultationDetails.billing.items;
    const orderTotal = getOrderTotal(billableItems);
    const couponDetails = getState().coupon;
    const creditDetails = getState().consultationDetails.creditDetails;
    const customer = getState().consultationDetails.billing.customer;
    const applicableCredit = getAvailableCreditAmount(paymentBreakup.credit, paymentBreakup.couponCode, billableItems, couponDetails, creditDetails);
    const paymentStatus = getState().payment.creditDeductionResponse;
    const generatedBill = getState().consultationDetails.billing.bill;
    try {
      if (paymentBreakup.couponCode.applied) {
        await couponService.applyCouponToOrder(access_token, orderId, couponDetails.code);
      }
    } catch (e) {
      const error = buildCustomError(e);
      console.log(error);
    }
    // generate payment, but skip if already done
    if (!paymentStatus || (paymentStatus && paymentStatus === 'failed')) {
      try {
        /*
        To construct the payment object, we need to check if payment is possible only
        through Clinikk Cash. If yes, only one payment is added to the payload, else the breakup
        is considered.
  
        If the order total is 0 (100% discount on all items),
        only one cash payment is recorded with value 0.
        */
        let paymentPayload = {
          payments: paymentBreakup.instruments.map(instrument => ({
            amount: parseFloat(Number(instrument.value).toFixed(2)),
            source: instrument.type,
          })),
        };
        const walletIndex = paymentBreakup.instruments.findIndex(instrument => instrument.type === offlinePaymentSources.WALLET);
        if (walletIndex !== -1) {
          paymentPayload.payments[walletIndex].transaction_id = paymentBreakup.walletTransactionId;
        }
        if (billableItems.length > 0 && paymentBreakup.instruments.length === 0) {
          paymentPayload = {
            payments: [
              {
                amount: 0,
                source: offlinePaymentSources.CASH,
              }
            ]
          }
        }
        if (paymentBreakup.credit.applied) {
          if (applicableCredit >= orderTotal) {
            if(paymentPayload?.payments?.length>0 && paymentPayload?.payments?.filter((payment)=> payment?.source === 'opd')?.length > 0){
              paymentPayload.payments.push({
                source: paymentSources.CREDIT,
                amount: Number(applicableCredit),
              });
            }
            else{
            paymentPayload = {
              payments: [
                {
                  source: paymentSources.CREDIT,
                  amount: Number(applicableCredit),
                }
              ]
            }
            }
          }
          else if (applicableCredit > 0) {
            paymentPayload.payments.push({
              source: paymentSources.CREDIT,
              amount: Number(applicableCredit),
            });
          }
        }
        if (paymentBreakup.couponCode.applied) {
          _.assignIn(paymentPayload, {
            discount: {
              code: couponDetails.code,
            }
          });
        }
  
        // create offline payment request;
        dispatch(createOfflinePaymentRequest());
        const createOfflinePaymentResponse = await createOfflinePaymentByOrderId(access_token, orderId, paymentPayload);
        dispatch(createOfflinePaymentSuccess(createOfflinePaymentResponse.data));
      } catch (e) {
        const error = buildCustomError(e);
        if (error.statusCode === UNAUTHORIZED || error.statusCode === FORBIDDEN) {
          dispatch(resetSession());
        } else {
          Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`),
          { extra: { errorData: error.data, function: 'settleBill', requestPayload: orderedItems } });
        }
        if (error.statusCode === SERVICE_UNAVAILABLE) {
          error.message += "Please check the customer's billing history before retrying!";
        }
        dispatch(createOfflinePaymentFailure());
      }
    }
    if (!generatedBill || (generatedBill && generatedBill.url === null)) {
      // generate bill request;
      try {
        dispatch(generateBillRequest());
      const customerAddress = getState().newCustomer?.addresses[0] ?? null;
      let customerAddressString = '-';
      if (customerAddress !== null) {
        const requiredFields = [customerAddress.line, customerAddress.city, customerAddress.state, customerAddress.zipode];
        customerAddressString = requiredFields.join(', ');
      }
      const payload = {
        address: customerAddressString,
        customer_id: customer.id,
      }
      const generatedBillResponse = await generateBilling(access_token, orderId, payload)
      const billData = await (generatedBillResponse).data;
      if (billData) {
        if (billingFor === billingType.CONSULTATION) {
          try {
            history.push({
              pathname: '/consultations-today',
              hash: 'completed',
            });
          }
          catch (e) {
            console.log(e);
          }
        }
        else {
          history.push({
            pathname: '/family',
            hash: 'plans',
          });
        }
        window.open(billData.url, '_blank');
      }
      dispatch(resetOfflinePayment());
      dispatch(generateBillSuccess(billData));
      } catch (e) {
      const error = buildCustomError(e);
      if (error.statusCode === UNAUTHORIZED || error.statusCode === FORBIDDEN) {
        dispatch(resetSession());
      } else {
        Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`),
        { extra: { errorData: error.data, function: 'settleBill', requestPayload: orderedItems } });
      }
      if (error.statusCode === SERVICE_UNAVAILABLE) {
        error.message += "Please check the customer's billing history before retrying!";
      }
      dispatch(createOfflinePaymentFailure());
      dispatch(generateBillFailure());
      dispatch(setError(error));
      }
    }
  }
}

const payInvoicesByCredit = (invoices) => {
  return async (dispatch, getState) => {
    // values we need from the redux state;
    const access_token = getState().auth.authDetails.data.access_token;
    const { familyDetails } = getState();

    // action types that we will dispatch;
    const resetSession = () => ({ type: LOG_OUT });
    const setError = (error) => ({ type: errorActionTypes.SET_ERROR, error });
    const createOrderRequest = () => ({ type: paymentActionTypes.CREATE_ORDER_REQUEST });
    const createOrderSuccess = (payload) => ({ type: paymentActionTypes.CREATE_ORDER_SUCCESS, payload });
    const createOrderFailure = () => ({ type: paymentActionTypes.CREATE_ORDER_FAILURE });
    const createOfflinePaymentRequest = () => ({ type: paymentActionTypes.CREATE_OFFLINE_PAYMENT_REQUEST });
    const createOfflinePaymentSuccess = (payload) => ({ type: paymentActionTypes.CREATE_OFFLINE_PAYMENT_SUCCESS, payload });
    const createOfflinePaymentFailure = () => ({ type: paymentActionTypes.CREATE_OFFLINE_PAYMENT_FAILURE });

    try {
      // create order using pay-invoices;
      const invoiceData = [];
      invoices.forEach((invoice) => {
        invoiceData.push({
          invoice: {
            id: invoice.id,
          },
          payment_multiplier: invoice.subscription.payment_multiplier,
        });
      });
      dispatch(createOrderRequest());
      const createOrderResponse = await payInvoices(access_token, { invoices: invoiceData }, familyDetails.allMembers.members[0].family.id);
      dispatch(createOrderSuccess());
      const orderId = createOrderResponse.data.order.id;

      // create offline payment request;
      const payableAmount = reduce(invoices,
            (sum, invoice) => sum + getProductPriceAfterDiscount(invoice?.subscription?.product, invoice.subscription.payment_multiplier), 0);
      const paymentPayload = {
        payments: [
          {
            source: paymentSources.CREDIT,
            amount: Number(payableAmount),
          }
        ]
      }
      dispatch(createOfflinePaymentRequest());
      const createOfflinePaymentResponse = await createOfflinePaymentByOrderId(access_token, orderId, paymentPayload);
      dispatch(createOfflinePaymentSuccess(createOfflinePaymentResponse.data));
    }
    catch (e) {
      const error = buildCustomError(e);
      if (error.statusCode === UNAUTHORIZED || error.statusCode === FORBIDDEN) {
        dispatch(resetSession());
      } else {
        Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`),
        { extra: { errorData: error.data, function: 'payInvoicesByCredit', requestPayload: invoices } });
      }
      dispatch(createOrderFailure());
      dispatch(createOfflinePaymentFailure());
      dispatch(setError(error));
    }
  }
};

export {
  createOfflinePaymentEvent,
  resetOfflinePaymentEvent,
  sendRenewalReminder,
  sendNotificationEvent,
  resetPayment,
  settleBill,
  createShortLink,
  payInvoicesByCredit,
}
