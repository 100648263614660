import React, { useReducer } from 'react';
import { uploadSupportingDocument } from '../../../clients';
import { round } from 'lodash';
import axios from 'axios';
import moment from 'moment';
import { healthVaultRecordTypes } from '../../../constants';
import { Button, Badge, LinearProgress } from '@material-ui/core';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import SelectRecordMenuButton from '../../CustomerDetailsTask/BenefitCards/Tab3/SelectRecordMenuButton';
import { withStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import useStyles from '../../CustomerDetailsTask/BenefitCards/Tab3/ConsultationFromStyle';
import SelectCreatedDatePickerButton from '../../CustomerDetailsTask/BenefitCards/Tab3/SelectCreatedDatePickerButton';
import { medblocksToken } from '../../../config';

function FileUpload(props) {
	const { classes, customerId, onSubmit } = props;
	const acceptedMimeTypes = 'image/jpeg,image/png,application/pdf';

	const auth = useSelector((state) => state.auth);

	const [documents, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'add': {
				const { selectedFiles } = action;
				const selectedDocuments = [];
				selectedFiles.forEach((file, index) => {
					selectedDocuments.push({
						file,
						id: null,
						isUploading: false,
						progress: Number(0),
						status: null,
						type: '',
						documentCreatedAt: '',
					});
				});
				const updatedDocuments = [...state].concat(selectedDocuments);
				return updatedDocuments;
			}
			case 'delete': {
				const { index: indexToDelete } = action;
				const updatedDocuments = state.filter((doc, index) => index !== indexToDelete);
				return updatedDocuments;
			}
			case 'modify': {
				const { index: indexToModify, modifications } = action;
				const updatedDocuments = [...state];
				if ('type' in modifications) {
					updatedDocuments[indexToModify].type = modifications.type;
				}
				if ('progress' in modifications) {
					updatedDocuments[indexToModify].progress = modifications.progress;
				}
				if ('isLoading' in modifications) {
					updatedDocuments[indexToModify].isLoading = modifications.isLoading;
				}
				if ('status' in modifications) {
					updatedDocuments[indexToModify].status = modifications.status;
				}
				if ('id' in modifications) {
					updatedDocuments[indexToModify].id = modifications.id;
				}
				if ('documentCreatedAt' in modifications) {
					updatedDocuments[indexToModify].documentCreatedAt =
						modifications.documentCreatedAt;
				}
				return updatedDocuments;
			}
			default: {
				return state;
			}
		}
	}, []);

	/**
	 * 
	 * @param {Object} doc - The `document` to be uploaded.
	 * 
	 * This function accepts a document that has been selected
	 * to upload, and uploads it to the Health Vault. It also
	 * handles updation of the Progress Bar for the upload, etc.
	 */
	function uploadDocument(doc) {
		const clinicId = auth.currentClinicLocation.id;
		const indexToModify = documents.findIndex((item) => item.file.name === doc.file.name);
		const access_token = auth.authDetails.data.access_token;
		const reportUploadProgress = (progress, error) => {
			const modifications = {
				progress: error ? doc.file.size : progress,
				isUploading: progress >= doc.file.size ? false : true,
				status: progress >= doc.file.size ? 'success' : null,
			};
			if (error) {
				modifications.status = 'failed';
			}
			dispatch({
				type: 'modify',
				index: indexToModify,
				modifications,
			});
		};

		/**
		 * An object representing the payload for the request;
		 * This object is parsed and key-value pairs are
		 * appended to form-data in the API call method.
		 */
		let updatedFile;
		const fr = new FileReader();
		fr.readAsArrayBuffer(doc.file)
		fr.onload =  function() {
			const blob = new Blob([fr.result]);
				updatedFile = new File([blob], doc.file.name?.replaceAll(' ','_'),{type : doc.file.type}); //Empty spaces removed from the file name before upload
			const documentData = {
				health_record_file: updatedFile,
				category: doc.type,
				customer_id: customerId,
				creation_date: doc.documentCreatedAt,
				chh_id: clinicId
			};
			
		uploadSupportingDocument(access_token, documentData, reportUploadProgress)
			.then((response) => {
				const uploadedFileId = response.data.id;
				dispatch({
					type: 'modify',
					modifications: {
						id: uploadedFileId,
					},
					index: indexToModify,
				});
				const docToUpdate = { ...documents[indexToModify] };
				docToUpdate.id = uploadedFileId;
				docToUpdate.category = docToUpdate.type;
				delete docToUpdate.type;
				return { docToUpdate, uploadFileDetails: response.data };
			})
			.then(async (data) => {
				if (process.env.REACT_APP_ENV === "production") {
					let url = `https://fhir.default.mb.clinikk.com/fhir/Patient?identifier_text=${customerId}`;
					let options = {
					  headers: {
						'content-type': 'application/json',
						'Authorization': 'Bearer ' + medblocksToken,
					  },
					  timeout: 10000,
					  responseType: 'json',
					  responseEncoding: 'utf8',
					};
					const response = await axios.get(url, options);
					const medPatientId = response.data.entry[0].resource.id;
					const payload = {
						"clinikk.attachment.v0/composer|id": "73ef613d-fddf-41b5-a994-81b020cec604",
						"clinikk.attachment.v0/composer|id_namespace": "Clinikk",
						"clinikk.attachment.v0/composer|name": "Clinikk",
  					"clinikk.attachment.v0/composer|id_scheme": "Clinikk",
						"clinikk.attachment.v0/category|value": "event",
						"clinikk.attachment.v0/category|code": "433",
						"clinikk.attachment.v0/category|terminology": "openehr",
						"clinikk.attachment.v0/context/start_time": new Date().toISOString(),
						"clinikk.attachment.v0/context/setting|code": "238",
						"clinikk.attachment.v0/context/setting|terminology": "openehr",
						"clinikk.attachment.v0/context/setting|value": "other care",
						"clinikk.attachment.v0/context/_health_care_facility|id_scheme": "http://hl7.org/fhir/r4/encounter.html",
						"clinikk.attachment.v0/context/_health_care_facility|name": "Migration Agent",
						"clinikk.attachment.v0/context/_health_care_facility|id_namespace": "http://hl7.org/fhir/r4/encounter.html",
						"clinikk.attachment.v0/context/_health_care_facility|id": "",
						"clinikk.attachment.v0/clinical_synopsis/synopsis": "Doc dashboard",
						"clinikk.attachment.v0/clinical_synopsis/media_file:0/content": data.uploadFileDetails.destFilePath,
						"clinikk.attachment.v0/clinical_synopsis/media_file:0/content|mediatype": data.uploadFileDetails.fileType,
						"clinikk.attachment.v0/clinical_synopsis/media_file:0/content|size": data.docToUpdate.file.size,
						"clinikk.attachment.v0/clinical_synopsis/media_file:0/content_name": data.uploadFileDetails.fileName,
						"clinikk.attachment.v0/clinical_synopsis/media_file:0/created": new Date(data.docToUpdate.documentCreatedAt).toISOString(),
						"clinikk.attachment.v0/clinical_synopsis/media_file:0/identifier:0/identifier_value|id": "63b4023ec7a0b85dbf25526c",
						"clinikk.attachment.v0/clinical_synopsis/media_file:0/description": data.docToUpdate.category,
						"clinikk.attachment.v0/clinical_synopsis/language|code": "en",
						"clinikk.attachment.v0/clinical_synopsis/language|terminology": "ISO_639-1",
						"clinikk.attachment.v0/clinical_synopsis/encoding|code": "UTF-8",
						"clinikk.attachment.v0/clinical_synopsis/encoding|terminology": "IANA_character-sets",
						"clinikk.attachment.v0/language|terminology": "ISO_639-1",
						"clinikk.attachment.v0/language|code": "en",
						"clinikk.attachment.v0/territory|code": "IN",
						"clinikk.attachment.v0/territory|terminology": "ISO_3166-1",
					};
					url = `https://ehrbase.default.mb.clinikk.com/ehrbase/rest/ecis/v1/composition?format=FLAT&templateId=clinikk.attachment.v0&ehrId=${medPatientId}`;
					await axios.post(url, payload, options);
				}
				if (onSubmit) {
					onSubmit(data.docToUpdate);
				}
			})
			.catch((e) => {
				reportUploadProgress(100, true);
			});
		}
	}

	/**
	 *
	 * @param {String} updatedRecordType - the `category` of the record being uploaded
	 * @param {Object} doc - the document to update
	 * ### Update record `category`.
	 *
	 * ### This method is a little complex!
	 * 1. The first step is to copy the documents object from the state
	 * and mutate only the required document.
	 * 2. As soon as the category is selected, the upload should begin,
	 * so we pass a callback to the first `setState`.
	 * 3. In this callback we create call the upload API. However, since
	 * the documents are not tracked by redux state, we need to update the progress
	 * of the upload in component state, which is done by the `reportUploadProgress` method.
	 * 4. In the `reportUploadProgress` method we create another copy of the state
	 * and mutate the current document's object with progress + status.
	 * 5. Finally, on success, the API call returns an `id` which is set to state
	 * similarly.
	 */
	const handleSelectRecordOnChange = (updatedRecordType, doc) => {
		const indexToModify = documents.findIndex((item) => item.file.name === doc.file.name);
		const modifications = {
			type: updatedRecordType,
		};
		if (doc.documentCreatedAt) {
			modifications.isUploading = true;
		}
		dispatch({
			type: 'modify',
			index: indexToModify,
			modifications,
		});
		if (modifications.isUploading) {
			uploadDocument(doc);
		}
	};

	const handleCreatedDateOnChange = (index) => (createdDate) => {
		const modifications = {
			documentCreatedAt: createdDate,
		};
		if (documents[index].type) {
			modifications.isUploading = true;
		}
		dispatch({
			type: 'modify',
			modifications,
			index,
		});
		if (modifications.isUploading) {
			uploadDocument(documents[index]);
		}
	};

	const getRecordTypeLabel = (recordType) => {
		const filteredRecordType = healthVaultRecordTypes.filter(
			(item) => item.value === recordType
		)[0];
		return filteredRecordType.label;
	};

	const handleDocumentPreviewOnClick = (doc, index) => {
		dispatch({
			type: 'delete',
			index,
		});
	};

	const handleDocumentPickerOnChange = (e) => {
		const selectedFiles = e.target.files;
		const filesToUpload = [];
		// selectedFiles is a FileList object;
		// the files contained in this FileList can be
		// accessed using fileListObject.item(indexOfFile);
		let selectedFile = null;
		for (let i = 0; i < selectedFiles.length; i++) {
			selectedFile = selectedFiles.item(i);
			if (!acceptedMimeTypes.split(',').includes(selectedFile.type)) {
				alert(
					selectedFile.name +
						' is not a supported file type. Please select only JPEG, PNG, or PDF files!'
				);
			} else {
				filesToUpload.push(selectedFile);
			}
		}
		dispatch({
			type: 'add',
			selectedFiles: filesToUpload,
		});
	};

	return (
		<div className={classes.itemContainer}>
			<div className={classes.fileContainer}>
				<div className={classes.fileContainer_header}>
					<span>Reports, Documents &amp; Photos</span>
					<Button
						variant='outlined'
						color='primary'
						component='label'
						className={classes.uploadBtn}
					>
						<UploadIcon className={classes.icon} />
						Add File
						<input
							type='file'
							accept={acceptedMimeTypes}
							multiple
							hidden
							id='document-picker'
							onChange={handleDocumentPickerOnChange}
						/>
					</Button>
				</div>
				{documents.length !== 0 ? (
					<div className={classes.fileContainer_body}>
						{// using `doc` to avoid clashes with `document` interface
						documents.map((doc, index) => {
							return (
								<div
									className={`${classes.documentContainer} ${
										doc.status === 'failed'
											? `${classes.documentContainer_failed}`
											: null
									}`}
									key={index}
								>
									<div className={classes.documentRow}>
										{/* file preview + upload progress */}
										<div className={classes.previewColumn}>
											{/* preview */}
											<Badge
												anchorOrigin={{
													vertical: 'top',
													horizontal: 'right',
												}}
												overlap='rectangular'
												component='span'
												badgeContent='×'
												classes={{
													anchorOriginTopRightRectangular:
														classes.closePreviewBtn,
												}}
												onClick={() => {
													handleDocumentPreviewOnClick(doc, index);
												}}
											>
												{doc.file.type === 'application/pdf' ? (
													<embed
														src={URL.createObjectURL(doc.file)}
														className={classes.preview}
													/>
												) : (
													<img
														alt='A preview of the uploaded file'
														src={URL.createObjectURL(doc.file)}
														className={classes.preview}
													/>
												)}
											</Badge>
										</div>
										<div className={classes.documentColumn}>
											{/* details */}
											<div className={classes.documentSubRow}>
												<span className={classes.documentName}>
													{doc.file.name}
												</span>
											</div>
											<div className={classes.progressSubRow}>
												<LinearProgress
													variant='determinate'
													value={(doc.progress / doc.file.size) * 100}
													classes={{
														colorPrimary:
															doc.status === 'failed'
																? classes.colorPrimary_failure
																: classes.colorPrimary,
														barColorPrimary:
															doc.status === 'failed'
																? classes.colorPrimary_failure
																: classes.barColorPrimary,
													}}
												/>
											</div>
											<div className={classes.documentSubRow}>
												<span className={classes.fileSizeLabel}>
													{round(doc.progress / 1000000, 1)}MB of{' '}
													{round(doc.file.size / 1000000, 1)}MB
												</span>
												{doc.isUploading ? (
													<span className={classes.progressLabel}>
														Uploading...{' '}
														{round(doc.progress / doc.file.size) * 100}%
													</span>
												) : (
													<span className={classes.progressLabel}>
														{doc.status === 'failed' ? 'Failed' : null}
														{doc.status === 'success'
															? 'Uploaded 100%'
															: null}
													</span>
												)}
											</div>
										</div>
									</div>
									<div className={classes.documentRow}>
										{/* document type dropdown */}
										{/* type label */}
										<div>
											<span className={classes.typeLabel}>File Type:</span>
											&nbsp;
											<span className={classes.typeValue}>
												{doc.type ? getRecordTypeLabel(doc.type) : '...'}
											</span>
										</div>
										{/* dropdown + button*/}
										<SelectRecordMenuButton
											handleSelectRecordOnChange={handleSelectRecordOnChange}
											doc={doc}
										/>
									</div>
									<div className={classes.documentRow}>
										{/* document type dropdown */}
										{/* type label */}
										<div>
											<span className={classes.typeLabel}>Date Created:</span>
											&nbsp;
											<span className={classes.typeValue}>
												{doc.documentCreatedAt
													? moment(doc.documentCreatedAt).format(
															'DD MMM YYYY'
													  )
													: '...'}
											</span>
										</div>
										{/* dropdown + button*/}
										<SelectCreatedDatePickerButton
											handleCreatedDateOnChange={handleCreatedDateOnChange(
												index
											)}
											doc={doc}
											variant='text'
										>
											<EditIcon />
										</SelectCreatedDatePickerButton>
									</div>
									{doc.status === 'failed' && (
										<div className={classes.documentRow}>
											<Button
												fullWidth
												className={classes.uploadBtn}
												variant='outlined'
												onClick={() =>
													handleSelectRecordOnChange(doc.type, doc)
												}
											>
												<UploadIcon className={classes.icon} />
												Upload Again
											</Button>
										</div>
									)}
								</div>
							);
						})}
					</div>
				) : null}
			</div>
		</div>
	);
}

const styleWrapper = withStyles(useStyles, { withTheme: true });
export default styleWrapper(FileUpload);
