import React from "react";
import { round } from "lodash";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import config from "../../config";
import {
  Card,
  Drawer,
  Paper,
  Button,
  Snackbar,
  CircularProgress,
  Badge,
  LinearProgress,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { ReactComponent as CareUploadIcon } from "../../assets/icons/careUploadicons/carecsvfileicon.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/careUploadicons/infoIcon.svg";
import { ReactComponent as SendIcon } from "../../assets/icons/careUploadicons/sendIcon.svg";
import { ReactComponent as NoResultIcon } from "../../assets/icons/careUploadicons/noResultIcon.svg";
import { ReactComponent as EyeIcon } from "../../assets/icons/careUploadicons/eyeIcon.svg";
import { ReactComponent as CloudDownloadIcon } from "../../assets/icons/careUploadicons/cloudDownloadIcon.svg";
import {
  uploadCareSupportingService,
  sendCareEndorsementsFilesService,
} from "../../clients/careUploadDocumentService";

import stylesFile from "./styles";
import { color } from "../../constants/colors";
import { uploadOnboardFile, resetUploadOnboardFile } from "../../actions";
import ShowHistoryCard from "./ShowHistoryCard";

const styles = (theme) => {
  const importedStyles = stylesFile(theme);
  return {
    ...importedStyles,
  };
};

const {
  endorsementType,
  careSnackbarStatus,
} = require("../../common/constants");

class CareRenewalCaseCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      selectedFile: "",
      openShowHistoryDrawer: false,
      showSnackBar: false,
      snackbarMessage: "",
      snackbarStatus: "",
      isLoading: false,
    };
  }

  handleUploadCsv = async () => {
    const { auth } = this.props;
    const ACCESS_TOKEN = auth.authDetails.data.access_token;
    const { documents } = this.state;
    const updatedDocuments = window.structuredClone(documents);

    for (let i = 0; i < updatedDocuments.length; i++) {
      let fileToUpload = null;
      let indexToModify = i;
      fileToUpload = updatedDocuments[i];

      const reportUploadProgress = (progress, error, indexToModify) => {
        const { documents } = this.state;
        const documentsCopy = [...documents];
        const docToUpdate = documentsCopy[indexToModify];

        if (error) {
          docToUpdate.progress = round(
            Number((progress / docToUpdate.size) * 100),
            0
          );
          docToUpdate.status = "failed";
          docToUpdate.isUploading = false;
          docToUpdate.name = docToUpdate.file.name;
        } else {
          docToUpdate.progress =
            round(progress / docToUpdate.file.size, 0) * 100;
          docToUpdate.isUploading =
            progress >= docToUpdate.file.size ? false : true;
          docToUpdate.status =
            progress >= docToUpdate.file.size ? "success" : null;
          docToUpdate.name = docToUpdate.file.name;
        }
        this.setState({
          documents: documentsCopy,
        });
      };
      if (!fileToUpload.status) {
        await uploadCareSupportingService(
          ACCESS_TOKEN,
          fileToUpload,
          endorsementType.RENEWAL,
          reportUploadProgress,
          indexToModify
        )
          .then((res) => {
            fileToUpload.name = res.data[0].name;
            fileToUpload.status = res.data[0].status;
            fileToUpload.file_path = res.data[0].file_path ?? "";
            fileToUpload.progress =
              res.data[0].status === "failed"
                ? 0
                : this.state.documents[indexToModify].progress;
            this.setState({ documents: updatedDocuments });
          })
          .catch((e) => {
            fileToUpload.status = "failed";
            this.setState({
              showSnackBar: true,
              snackbarStatus: careSnackbarStatus.ERROR,
              snackbarMessage: e.message,
              documents: updatedDocuments,
            });
          });
      }
    }
    this.setState({ isLoading: false });
  };

  handleCsvDocumentPickerOnChange = async (e) => {
    const { documents } = this.state;
    const updatedDocuments = window.structuredClone(documents);
    const selectedFiles = e.target.files;

    for (let i = 0; i < selectedFiles.length; i++) {
      let selectedFile = selectedFiles.item(i);
      const fSize = Math.round(selectedFile.size / (1024 * 1024));
      const duplicateFile = updatedDocuments.filter(
        (doc) => doc.file.name === selectedFile.name
      );

      if (fSize > 10) {
        alert(
          selectedFile.name +
            " File too Big, Please select a file less than 10MB! "
        );
      } else if (duplicateFile.length > 0) {
        alert(
          "Same File Detected, " + selectedFile.name + " is already uploaded! "
        );
      } else {
        updatedDocuments.push({
          file: selectedFiles.item(i),
          isUploading: false,
          progress: Number(0),
          status: null,
          name: "",
          file_path: "",
        });
      }
    }
    this.setState({ documents: updatedDocuments, isLoading: true }, () => {
      this.handleUploadCsv();
    });
  };

  deleteCareUploadedFile = (doc) => {
    const { documents } = this.state;
    const updatedDocuments = documents.filter(
      (item) => item.file.name !== doc.file.name
    );
    this.setState({
      documents: updatedDocuments,
    });
  };

  renderUploadedFiles = () => {
    const { documents } = this.state;
    if (documents.length !== 0) {
      return documents.map((document, index) => {
        const { classes } = this.props;
        return (
          <div key={index} className={classes.uploadedFile}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                position: "relative",
              }}
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                overlap="rectangular"
                component="span"
                badgeContent="×"
                classes={{
                  anchorOriginTopLeftRectangular: classes.deleteUploadedFile,
                }}
                onClick={() => {
                  this.deleteCareUploadedFile(document);
                }}
              >
                <CareUploadIcon />
              </Badge>

              <div className={classes.fileFrame}>
                {document?.status === "failed" ? (
                  <>
                    <div className={classes.fileNameFailed}>
                      {document?.file.name}
                    </div>
                    <div>
                      <InfoIcon />
                      <span className={classes.errorDetails}>
                        Error occured, Please try again
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={classes.fileNameSuccess}>
                      {document?.name}
                    </div>
                    <LinearProgress
                      variant="determinate"
                      value={Number(document.progress)}
                      classes={{
                        colorPrimary: classes.colorPrimary,
                        barColorPrimary: classes.barColorPrimary,
                      }}
                    />
                    <div className={classes.fileDetails}>
                      <span className={classes.fileDetailsSize}>
                        {" "}
                        {round(
                          (document?.progress / 100) *
                            (document?.file.size / 1000000),
                          2
                        )}
                        MB of {round(document?.file.size / 1000000, 2)}MB
                      </span>
                      <span className={classes.fileDetailsStatus}>
                        Uploaded {document?.progress || 0}%
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        );
      });
    } else {
      return "";
    }
  };

  handleShowHistoryOnClick = (key) => {
    this.setState({
      [key]: true,
    });
  };

  closeShowHistoryDrawer = (key) => {
    this.setState({
      [key]: false,
    });
  };

  handleSendDataOnClick = () => {
    const { documents } = this.state;
    const { auth } = this.props;
    const ACCESS_TOKEN = auth.authDetails.data.access_token;
    const endorse_type = endorsementType.RENEWAL;
    const case_records = documents.map((doc) => {
      if (doc.status === "success") {
        return {
          file_name: doc.file.name,
          file_path: doc.file_path,
          file_size: (doc.file.size / 1024).toFixed(2),
        };
      }
      return "";
    });
    const casesEndorsementsPayload = {
      files: case_records,
    };
    sendCareEndorsementsFilesService(
      ACCESS_TOKEN,
      casesEndorsementsPayload,
      endorse_type
    ).then((response) => {
      if (!response.message) {
        this.setState({
          showSnackBar: true,
          snackbarStatus: careSnackbarStatus.SUCCESS,
          snackbarMessage: `All the ${documents.length} Files Were Sent Successfully`,
          documents: [],
        });
      } else {
        this.setState({
          showSnackBar: true,
          snackbarStatus: careSnackbarStatus.ERROR,
          snackbarMessage: response.message,
        });
      }
    });
  };

  getCircularProgress = () => {
    return (
      <CircularProgress
        style={{
          color: color.greens[100],
          left: "50%",
          top: "55%",
          position: "absolute",
        }}
      />
    );
  };

  render() {
    const { classes } = this.props;
    const {
      documents,
      showSnackBar,
      snackbarMessage,
      snackbarStatus,
      isLoading,
    } = this.state;
    const snackbarColor =
      snackbarStatus === careSnackbarStatus.SUCCESS
        ? color.greens[100]
        : color.reds[100];
    const snackbarIcon =
      snackbarStatus === careSnackbarStatus.SUCCESS ? (
        <SendIcon fontSize="inherit" />
      ) : (
        <NoResultIcon
          style={{
            height: "15px",
            width: "15px",
            marginTop: "2px",
            marginRight: "-5px",
            fontSize: "inherit",
          }}
        />
      );

    return (
      <Card elevation={1} className={classes.card}>
        <Drawer
          anchor="right"
          disableEnforceFocus
          open={this.state.openShowHistoryDrawer}
          onClose={() => this.closeShowHistoryDrawer("openShowHistoryDrawer")}
        >
          <Paper style={{ width: "50vh", boxShadow: "none" }}>
            <ShowHistoryCard
              type="Renewal History"
              case={endorsementType.RENEWAL}
            />
          </Paper>
        </Drawer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <p className={classes.title}>Renewal Case Endorsements</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "19px",
            }}
          >
            <Button
              target="_blank"
              href={config.care_renewal_template_url}
              className={classes.showHistoryTemplate}
              variant="outlined"
              startIcon={<CloudDownloadIcon style={{ marginRight: "-3px" }} />}
            >
              Template
            </Button>

            <Button
              target="_blank"
              style={{ marginRight: "0px" }}
              className={classes.showHistoryTemplate}
              variant="outlined"
              startIcon={<EyeIcon style={{ marginRight: "-3px" }} />}
              onClick={() =>
                this.handleShowHistoryOnClick("openShowHistoryDrawer")
              }
            >
              History
            </Button>
          </div>
        </div>

        <div className={classes.boundaryDiv}>
          <CareUploadIcon />
          <div htmlFor="file" className={classes.uploadText}>
            <input
              type="file"
              title="Browse"
              name="file"
              className={classes.uploadTextInput}
              multiple
              id="BulkOnboardingTaskFile"
              accept=" text/csv "
              onClick={(e) => {
                e.target.value = "";
              }}
              onChange={this.handleCsvDocumentPickerOnChange}
              key={this.state.fileKey}
            />
            <div className={classes.uploadTextCover}>
              <span className={classes.browse}> Browse</span> to upload a file
            </div>
          </div>

          <span className={classes.uploadSubText}>
            File format: .CSV should be less than 10 MB
          </span>
        </div>
        <div>{isLoading ? this.getCircularProgress() : <></>}</div>

        {this.renderUploadedFiles()}
        <div>
          {documents.length !== 0 ? (
            <Button
              onClick={this.handleSendDataOnClick}
              className={classes.sendUploadedFileButton}
              disabled={isLoading ? true : false}
            >
              Send Data
            </Button>
          ) : (
            ""
          )}
        </div>
        <div>
          {showSnackBar ? (
            <Snackbar
              open={showSnackBar}
              style={{
                width: "62%",
                marginLeft: "16px",
              }}
              onClose={() => {
                this.setState((prevState) => ({
                  showSnackBar: !prevState.showSnackBar,
                  snackbarMessage: "",
                  snackbarStatus: "",
                }));
              }}
              autoHideDuration={2400}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
              <MuiAlert
                style={{
                  width: "100%",
                  background: snackbarColor,
                  color: color.WHITE,
                  borderRadius: "8px",
                  fontFamily: "Rubik",
                  marginLeft: "27px",
                  marginBottom: "17px",
                }}
                icon={snackbarIcon}
              >
                {snackbarMessage}
              </MuiAlert>
            </Snackbar>
          ) : null}
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return { onBoarding: state.onBoarding, auth: state.auth };
};

export default connect(mapStateToProps, {
  uploadOnboardFile,
  resetUploadOnboardFile,
})(withStyles(styles)(CareRenewalCaseCard));
