import { color } from "../../../constants/colors";

const membersStyles = (theme) => ({
    editHistoryContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '360px',
        height: '100%',
    },
    editHistoryHeader: {
        display: 'flex',
        flexDirection: 'row',
        padding: '3vh 2vh 1vh 2vh',
        width: 'calc(100% - 4vh)',
        position: 'sticky',
        top: '0',
    },
    headerText: {
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontWeight: 'bold',
    },
    headerHr: {
        height: '1px',
        outline: 'none',
        border: '0px',
        background: color.ROYAL_ORANGE,
        width: '100%',
        margin: '0px'
    },
    editHistoryBody: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        height: 'max-content',
    },
    updateEntryContainer: {
        padding: '2vh 0px',
        margin: '0px 2vh',
        borderBottom: `1px solid ${color.greys[200]}`,
    },
    updateEntryHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        color: color.greys[100],
        fontSize: '12px',
        fontFamily: 'Rubik',
    },
    memberId: {
        color: color.ROYAL_ORANGE
    },
    updateEntryBody: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        color: color.greys[100],
        fontSize: '12px',
        fontFamily: 'Rubik',
    },
    overrideType: {
        display: 'inline-flex',
        alignItems: 'center'
    },
    overrideTypeLabel: {
        color: color.BLACK,
        margin: '0px'
    },
    tooltip: {
        background: `${color.BLACK}CC`,
        padding: '1vh',
        color: color.WHITE,
        borderRadius: '4px',
        fontFamily: 'Rubik',
        display: 'flex',
        maxWidth: '188px',
        flexDirection: 'row',
        alignItem: 'center',
        justifyContent: 'space-between',
        fontSize: '12px',
        lineHeight: '14px',
    },
    arrow: {
        background: `transparent`,
        color: `${color.BLACK}CC`,
    },
    showEditsCta: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '0.8rem',
        color: color.ROYAL_ORANGE,
        fontFamily: 'Rubik',
        margin: '3vh',
        background: 'none',
        border: 'none',
        textTransform: 'capitalize',
        '&:disabled': {
            color: color.LIGHT_GREY,
        }
    }
});

export default membersStyles;