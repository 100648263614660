import React from 'react';
import { connect } from 'react-redux';
import ParticularConsultationCard from './ParticularConsultationCard';
import { isEmpty, map } from 'lodash';
import { Typography, Drawer, Button } from '@material-ui/core';
import { fetchConsultationsByCustomerId, resetConsultationDetail, openBookConsultationDrawer, closeBookConsultationDrawer, resetConsultationsByCustomerId, resetConsultationById, setPresentableConsultations, incrementConsultationPage, decrementConsultationPage, resetConsultationPageNumber, resetVisitBookingDetails } from '../../../../actions';
import ConsultationForm from './ConsultationForm';
import useStyles from './ConsultationFromStyle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/styles';
import VisitBookingForm from './VisitBookingForm';
class CustomerConsultation extends React.Component {

  myref;
  // intersectionObserver;
  componentDidMount() {
    this.props.resetConsultationDetail();
    this.props.resetConsultationsByCustomerId();
    this.props.resetConsultationPageNumber();
    this.getConsultations();
    this.props.resetConsultationById();
    this.props.resetVisitBookingDetails();
  };

  constructor() {
    super();
    // this.myref = React.createRef();
    this.state = { totalPages: null, prevButtonDisabled: false, nextButtonDisabled: false, isPageDone: false }
  }

  isInViewport(offset = 0) {
    if (!this.determining_component) return false;
    const top = this.determining_component.getBoundingClientRect().top;
    return this.setState({ isPageDone: (top + offset) >= 0 && (top - offset) <= window.innerHeight });
  }

  getConsultations = () => {
    // const consultationsPromise = this.props.fetchConsultationsByCustomerId(this.props.customerId, this.state.currentConsultationPage);
    const consultationsPromise = this.props.fetchConsultationsByCustomerId(this.props.customerId, this.props.consultationDetails?.currentConsultationPage);
    consultationsPromise.then(() => {
      if (this.props.consultationDetails && this.props.consultationDetails?.customerConsultations?.consultations) {
        this.props.setPresentableConsultations(this.props.consultationDetails?.customerConsultations?.consultations)
      }
    }
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.customerId !== nextProps.customerId) {
      this.props.resetConsultationPageNumber();
      this.props.resetConsultationsByCustomerId();
      const consultationsPromise = this.props.fetchConsultationsByCustomerId(nextProps.customerId, this.props.consultationDetails.currentConsultationPage);
      consultationsPromise.then(() => this.props.setPresentableConsultations(this.props.consultationDetails.customerConsultations.consultations));
    }
  }

  loadNext = () => {
    if (this.props.consultationDetails && this.props.consultationDetails.customerConsultations && this.props.consultationDetails.customerConsultations.total_count && (this.props.consultationDetails.currentConsultationPage < Math.ceil(this.props.consultationDetails.customerConsultations.total_count / 5))) {
      this.setState({ nextButtonDisabled: true });
      this.props.incrementConsultationPage();
      setTimeout(function () {
        this.setState({ nextButtonDisabled: false });
      }.bind(this), 1000);
      setTimeout(function () {
        this.getConsultations();
      }.bind(this), 200);
    }
  }
  // loadPrev = () => {
  //   if (this.props.consultationDetails.currentConsultationPage > 1) {
  //     this.setState({prevButtonDisabled: true });
  //     this.props.decrementConsultationPage();
  //     setTimeout(function () {
  //       this.setState({ prevButtonDisabled: false });
  //     }.bind(this), 1000);
  //     this.getConsultations();
  //   }
  // }

  // componentDidUpdate(prevProps, prevState) {
  // if (this.props.consultationDetails.currentConsultationPage !== prevProps.consultationDetails.currentConsultationPage) {
  //   this.getConsultations();
  // }
  // if ((this.props.consultationDetails &&  this.props.consultationDetails.customerConsultations && prevProps.consultationDetails &&  prevProps.consultationDetails.customerConsultations ) && this.props.consultationDetails.customerConsultations.total_count !== prevProps.consultationDetails.customerConsultations.total_count) {
  //   this.getConsultations();
  // }
  // }

  // shouldComponentUpdate(nextProps, nextState) {
  //   return ( this.props.consultationDetails &&  this.props.consultationDetails.customerConsultations && nextProps.consultationDetails &&  nextProps.consultationDetails.customerConsultations ) && this.props.consultationDetails.customerConsultations.total_count !== nextProps.consultationDetails.customerConsultations.total_count
  // }
  // shouldComponentUpdate(nextProps, nextState) {
  //   if (this.props.consultationDetails && this.props.consultationDetails.customerConsultations && this.props.consultationDetails.customerConsultations.consultations && nextProps.consultationDetails && nextProps.consultationDetails.customerConsultations && nextProps.consultationDetails.customerConsultations.consultations) {
  //     return this.props.consultationDetails.customerConsultations.consultations.length !== nextProps.consultationDetails.customerConsultations.consultations.length;
  //   }
  // };

  // componentWillpdate(nextProps, nextState) {
  //   if (this.state.currentConsultationPage !== nextState.currentConsultationPage) {
  //     this.props.fetchConsultationsByCustomerId(this.props.customerId, this.state.currentConsultationPage + 1);
  //   }
  // }

  closeConsultationDrawer = () => {
    this.props.closeBookConsultationDrawer();
    this.props.resetConsultationDetail();
  };

  closeVisitBookDrawer = () => {
    this.props.closeVisitBookDrawer();
    this.props.resetConsultationDetail();
  }

  renderConsultations = () => {
    if (!this.props.consultationDetails.customerConsultations) {
      return (<div> <CircularProgress style={{ color: 'orange', marginLeft: '48%', marginRight: '48%', marginTop: '20vh' }} disableShrink /> </div>);
    } else if (this.props.error.consultationsErrorCode) {
      return (<div style={{ marginLeft: '15vh' }}> <Typography style={{ fontFamily: 'Rubik' }}>{this.props.error.consultationsErrorCode}</Typography></div>);
    } else if (this.props.consultationDetails.customerConsultations && isEmpty(this.props.consultationDetails.customerConsultations.consultations)) {
      return (<div><Typography style={{ fontStyle: 'italic', fontSize: '1.5vh', marginLeft: '20vh', color: 'grey', fontFamily: 'Rubik' }}>No active consultations</Typography></div>);
    } else {
      return (
        <div style={{ overflow: 'scroll', position: 'relative', height: '64vh' }} >
          {/* this.props.consultationDetails.representableConsultations */}
          {map(this.props.consultationDetails.representableConsultations, (consultation) => {
            return (<ParticularConsultationCard key={consultation.id} consultation={consultation} openVisitBookDrawer={this.props.openVisitBookDrawer} />);
          })}
          <div style={{ marginLeft: '2vw', fontSize: '1.5vh' }}>

            {/* {this.props.consultationDetails && this.props.consultationDetails.customerConsultations && (this.props.consultationDetails.customerConsultations.total_count > 10 && this.props.consultationDetails.currentConsultationPage !== 1) ?
            <Button onClick={this.loadPrev} disabled={this.state.prevButtonDisabled} > <KeyboardArrowLeftIcon/> </Button> : <> </>} */}

            {/* {this.props.consultationDetails && this.props.consultationDetails.customerConsultations && (this.props.consultationDetails.customerConsultations.total_count > 10) ? '-' + this.props.consultationDetails.currentConsultationPage + '-' : ''} */}
            {(this.props.consultationDetails && this.props.consultationDetails.customerConsultations && this.props.consultationDetails.customerConsultations.total_count && this.props.consultationDetails.customerConsultations.total_count > 5 && (this.props.consultationDetails.currentConsultationPage <= Math.ceil(this.props.consultationDetails.customerConsultations.total_count / 5)))
              && (this.props.consultationDetails.currentConsultationPage !== Math.ceil(this.props.consultationDetails.customerConsultations.total_count / 5)) ?
              <Button id="determining_component" ref={(el) => { this.determining_component = el }} style={{ marginLeft: '16vw', fontSize: '1.4vh', textTransform: 'capitalize' }} onClick={this.loadNext} disabled={this.state.nextButtonDisabled} >Load more </Button> : <> </>}
          </div>
        </div>
      );
    }
  };


  render() {
    const { classes } = this.props;
    return (
      <div >
        <br />
        {this.renderConsultations()}
        <div>
        </div>
        < Drawer
          PaperProps={{
            classes: {
              root: classes.drawerRoot,
              rounded: classes.drawerRoot
            },
            square: false,
            elevation: 6,
            style: {
              width: '50vh',
              height: '100%',
              borderRadius: '8px 0px 0px 8px'
            }
          }}
          anchor="right"
          open={this.props.familyDetails.bookConsultationDrawerStatus}
          // open={true}
          onClose={this.closeConsultationDrawer}
        >
          <ConsultationForm
            customerId={this.props.customerId}
            closeDrawer={this.closeConsultationDrawer}
          />
        </Drawer>
        < Drawer
          PaperProps={{
            classes: {
              root: classes.drawerRoot,
              rounded: classes.drawerRoot
            },
            square: false,
            elevation: 6,
            style: {
              width: '50vh',
              height: '100%',
              borderRadius: '8px 0px 0px 8px'
            }
          }}
          anchor="right"
          open={this.props.familyDetails.visitBookDrawerStatus}
          // open={true}
          onClose={this.closeVisitBookDrawer}
        >
          <VisitBookingForm
            customerId={this.props.customerId}
            customerName={this.props.customerName}
            closeDrawer={this.closeVisitBookDrawer}
          />
        </Drawer>
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth, familyDetails: state.familyDetails, error: state.error, consultationDetails: state.consultationDetails };
}

const mapDispatchToProps = {
  fetchConsultationsByCustomerId,
  resetConsultationDetail,
  openBookConsultationDrawer,
  closeBookConsultationDrawer,
  resetConsultationsByCustomerId,
  resetConsultationById,
  setPresentableConsultations,
  incrementConsultationPage,
  decrementConsultationPage,
  resetConsultationPageNumber,
  resetVisitBookingDetails,
}

const reduxWrapper = connect(mapStateToProps, mapDispatchToProps);
const styleWrapper = withStyles(useStyles, { withTheme: true });
export default reduxWrapper(styleWrapper(CustomerConsultation));