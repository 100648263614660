import * as Sentry from '@sentry/react';
import { sendMandateLink } from "../clients/mandateService";
import { paymentSources } from "../common/constants"
import { SEND_MANDATE_LINK_FAILURE, SEND_MANDATE_LINK_REQUEST, SEND_MANDATE_LINK_SUCCESS } from "./types";
const { buildCustomError } = require('../common/utils');

export const sendMandateLinkEvent = (toData, channelData, subscriptionIdData) => {
    let response;
    const request = () => ({ type: SEND_MANDATE_LINK_REQUEST });
    const success = (payload) => ({ type: SEND_MANDATE_LINK_SUCCESS, payload });
    const failure = () => ({ type: SEND_MANDATE_LINK_FAILURE });
    return async (dispatch, getState) => {
      dispatch(request());
      let mandateLinkData;
      try {
        mandateLinkData = {
            template:{
                id:49
            },
            channel:channelData,
            payment_sources:[paymentSources.PAYTM_SUBSCRIPTION],
            to:toData,
            subscription:{
                id:subscriptionIdData
            }
        }; 
        const ACCESS_TOKEN = getState().auth.authDetails.data.access_token;
        response = await sendMandateLink(ACCESS_TOKEN, mandateLinkData);
        dispatch(success(response.status));
      } catch (e) {
        const error = buildCustomError(e);
        Sentry.captureException(new Error(`${error.statusCode} - ${(error.message)})`),
          { extra: { errorData: error.data, function: 'sendMandateLinkEvent', requestPayload: mandateLinkData } });
          dispatch(failure());
        }
      }
    };
